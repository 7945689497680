import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../Store/Redux/store";
import Popup from "reactjs-popup";
import { Trans, t } from "@lingui/macro";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "../../../../../Components/Box";
import Text from "../../../../../Components/Text";
import Button from "../../../../../Components/Button";
import { setMeetingReservationData } from "../../../../Store/Meetings/meetingReservations";
import { clearCreateNewMeetingDataIfBookingCreated } from "../../../../Store/Meetings/createNewMeetingDuck";

export default function MeetingCreatedModal() {
  const dispatch = useDispatch();
  const { config, createNewMeeting } = useTypedSelector(state => state);
  const { reservationCreated } = createNewMeeting;
  
  const bookingPath = 'create-new-meeting';
  
  const handleClosePopup = () => {
    dispatch(setMeetingReservationData({ reservationCreated: false }));
  };

  // Closes popup everytime the page render or close
  useEffect(() => {
    handleClosePopup();
  }, []);

  // Clear booking data if booking was successfully created on cleanup
  useEffect(() => {
    return () => {
      dispatch(clearCreateNewMeetingDataIfBookingCreated());
      handleClosePopup();
    };
  }, []);

  return (
    <Popup
      className="modal"
      contentStyle={{
        maxWidth: 570,
        width: '100%',
      }}
      modal
      onClose={() => {
        handleClosePopup();
      }}
      open={reservationCreated}
    >
      <Box padding="2px 1px">
        <Box alignItems="center" display="flex" justifyContent="between">
          <Text size="xlg" weight="semi-bold">
            <Trans>Room was successfully booked</Trans>
          </Text>

          <IconButton
            onClick={handleClosePopup}
            size="small"
            style={{
              backgroundColor: config.theme.primaryLight,
              borderRadius: 8,
              height: 30,
              width: 30,
            }}
          >
            <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
          </IconButton>
        </Box>

        <Box margin="9px 0 18px">
          <Text color="gray" size="md">
            <Trans>You can find all booking details on the booking list page.</Trans>
          </Text>
        </Box>

        <Box alignItems="center" display="flex" justifyContent="end">
          <Button
            aria-label={t`make a new booking`}
            name={t`make a new booking`}
            size="sm"
            to={`/${bookingPath}/select-location`}
            type="clear"
            withShadow={false}
          >
            <Trans>Make a new booking</Trans>
          </Button>

          <Button
            aria-label={t`back to booked list`}
            name="back to booked list"
            size="sm"
            to="/"
          >
            <Trans>Back to booked list</Trans>
          </Button>
        </Box>
      </Box>
    </Popup>
  );
}