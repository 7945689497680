import Checkbox from '@material-ui/core/Checkbox';
import { useTypedSelector } from 'Store/Redux/store';

interface Props {
  checked: boolean;
  className?: string;
  classes?: {
    container?: string;
    checkbox?: string;
    checked?: string;
  };
  onChange: any;
  name: string;
}

type PropsA = Props;

export default function CheckboxCustom({
  checked,
  className,
  classes,
  name,
  onChange,
}: React.PropsWithChildren<PropsA>) {
  const { config } = useTypedSelector(state => state);

  return (
    <Checkbox
      checked={checked}
      checkedIcon={
        <svg height="16px" version="1.1" viewBox="0 0 16 16" width="16px">
          <g fill="none" fillRule="evenodd" id="A-reservations" stroke="none" strokeWidth="1">
            <g fill={config.theme.primary} fillRule="nonzero" id="A-Reservations-massaction-selected" transform="translate(-240.000000, -312.000000)">
              <g id="Group" transform="translate(230.000000, 308.000000)">
                <g id="checkboxselected" transform="translate(10.000000, 4.000000)">
                  <path d="M14,0 C15.1045695,0 16,0.8954305 16,2 L16,14 C16,15.1045695 15.1045695,16 14,16 L2,16 C0.8954305,16 0,15.1045695 0,14 L0,2 C0,0.8954305 0.8954305,0 2,0 L14,0 Z M14,2 L2,2 L2,14 L14,14 L14,2 Z M12.1819805,5.43933983 C12.5725048,5.82986412 12.5725048,6.4630291 12.1819805,6.85355339 L7.93933983,11.0961941 C7.57885587,11.456678 7.01162481,11.4844076 6.6193336,11.1793827 L6.52512627,11.0961941 L4.40380592,8.97487373 C4.01328163,8.58434944 4.01328163,7.95118446 4.40380592,7.56066017 C4.76428988,7.20017621 5.33152094,7.17244667 5.72381215,7.47747157 L5.81801948,7.56066017 L7.23223305,8.97487373 L10.767767,5.43933983 C11.1582912,5.04881554 11.7914562,5.04881554 12.1819805,5.43933983 Z" id="Combined-Shape"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      }
      className={`checkbox-custom ${className}`}
      classes={classes}
      icon={
        <svg height="16px" version="1.1" viewBox="0 0 16 16" width="16px">
          <g fill="none" fillRule="evenodd" id="A-reservations" stroke="none" strokeWidth="1">
            <g fill="#FFFFFF" fillRule="nonzero" id="A-Reservations-massaction-selected" stroke="#ABABAB" transform="translate(-240.000000, -261.000000)">
              <g id="Group" transform="translate(230.000000, 257.000000)">
                <g id="Rectangle" transform="translate(10.000000, 4.000000)">
                  <path d="M14,0.5 C14.4142136,0.5 14.7892136,0.667893219 15.0606602,0.939339828 C15.3321068,1.21078644 15.5,1.58578644 15.5,2 L15.5,2 L15.5,14 C15.5,14.4142136 15.3321068,14.7892136 15.0606602,15.0606602 C14.7892136,15.3321068 14.4142136,15.5 14,15.5 L14,15.5 L2,15.5 C1.58578644,15.5 1.21078644,15.3321068 0.939339828,15.0606602 C0.667893219,14.7892136 0.5,14.4142136 0.5,14 L0.5,14 L0.5,2 C0.5,1.58578644 0.667893219,1.21078644 0.939339828,0.939339828 C1.21078644,0.667893219 1.58578644,0.5 2,0.5 L2,0.5 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      }
      name={name}
      onChange={onChange}
    />
  );
}
