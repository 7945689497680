import React from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

export default function Tooltip2({ children, placement = 'top', title }: React.PropsWithChildren<TooltipProps>) {
  return (
    <Tooltip
      classes={{ tooltip: 'overflow-tooltip' }}
      placement={placement}
      title={title}
    >
      {children}
    </Tooltip>
  );
}
