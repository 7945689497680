import Api from 'Store/Services/Api';
import { GetAttendeesResponse } from './models';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_ATTENDEES,
  GET_ATTENDEES_FAIL,
  GET_ATTENDEES_SUCCESS,
  GetAttendees,        
} from '.';

function* getAttendees(action: GetAttendees): any {
  try {
    const payload: GetAttendeesResponse = yield call(Api, action, false, true);

    yield put({ type: GET_ATTENDEES_SUCCESS, payload });
  } catch (e) {    
    yield put({ type: GET_ATTENDEES_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_ATTENDEES, getAttendees),
];