import Box from 'Components/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'Store/Redux/store';

export default function TimeSlots() {
  const dispatch = useDispatch();
  const { timeSlots } = useTypedSelector(state => state.createNewBooking);

  // Update redux with new selected week days
  const handleSelectWeekDay = (slot: number) => {
    const newTimeSlots = { ...timeSlots };

    Object.values({...timeSlots}).forEach((item, index) => {
      newTimeSlots[index].selected = item.value === slot ? !newTimeSlots[slot].selected : false;
    });

    dispatch(setCreateNewBookingData({ timeSlots: newTimeSlots }));
  };

  // @ts-ignore
  const timeSlotsArray = Object.values(timeSlots);

  return (
    <Box paddingBottom={20} paddingTop={20}>
      <Box className={styles.weekDaysContainer}>
        {timeSlotsArray.map((slot, index) => {
          const isSelected = slot.selected;
          const classes = clsx(styles.hourContainer, isSelected && styles.selected);

          return (
            <Box key={`${slot.label}-${index}`}>
              <ButtonBase className={styles.slotButton} disableRipple onClick={() => handleSelectWeekDay(slot.value)}>
                <Box className={classes} width={'100%'}>
                  <Text align="center" color={isSelected ? 'blue' : 'black'} weight="semi-bold">
                    {slot.label}
                  </Text>
                </Box>
              </ButtonBase>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
