import Box from 'Components/Box';
import Button from 'Components/Button';
import Divider from 'Components/Divider';
import Text from 'Components/Text';
import { setReservationsData } from '../../../Store/reservations';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import {
  Calendar as CalendarDateRange,
  DateRange,
  Range,
  RangeKeyDict,
} from 'react-date-range';

export function ReservationListViewCalendar() {
  const dispatch = useDispatch();
  const { filters } = useTypedSelector(state => state.adminReservations);
  const { selectedStartDate, selectedEndDate } = filters;

  const initialRange = {
    startDate: selectedStartDate ? new Date(selectedStartDate) : new Date(),
    endDate: selectedEndDate ? new Date(selectedEndDate) : new Date(),
    key: 'selection',
  };
  const [selectionRange, setSelectionRange] = useState<Range>(initialRange);

  const onResetDate = () => {
    const resetData = {
      selection: {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    };
    onChangeDate(resetData);
  };

  const onChangeDate = (ranges: RangeKeyDict) => {
    if (ranges.selection.startDate && ranges.selection.endDate) {
      dispatch(setReservationsData({
        filters: {
          ...filters,
          selectedStartDate: ranges.selection.startDate,
          selectedEndDate: ranges.selection.endDate,
        },
      }));
    }
  };

  // setSelectionRange by useEffect for all calendars on the page
  useEffect(() => {
    const rangesData = {
      startDate: new Date(selectedStartDate),
      endDate: new Date(selectedEndDate),
      key: 'selection',
    };

    setSelectionRange(rangesData);
  }, [selectedStartDate, selectedEndDate]);

  return (
    <Box>
      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={15} marginTop={-3}>
        <Box alignItems="center" display="grid" gap={10} gridTemplateColumns="auto auto">
          <svg height="30px" version="1.1" viewBox="0 0 30 30" width="30px">
            <g fill="none" fillRule="evenodd" id="A-reservations" stroke="none" strokeWidth="1">
              <g id="A-Reservations" transform="translate(-1030.000000, -112.000000)">
                <g id="icons/calendarsmall" transform="translate(1030.000000, 112.000000)">
                  <rect fill="#F4F4F4" height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                  <path d="M20,7 C20.5522847,7 21,7.44771525 21,8 L21,9 L23,9 L23,20 C23,21.6568542 21.6568542,23 20,23 L20,23 L10,23 C8.34314575,23 7,21.6568542 7,20 L7,20 L7,9 L9,9 L9,9 L9,8 C9,7.44771525 9.44771525,7 10,7 C10.5522847,7 11,7.44771525 11,8 L11,9 L19,9 L19,9 L19,8 C19,7.44771525 19.4477153,7 20,7 Z M21,14 L9,14 L9,20 C9,20.5128358 9.38604019,20.9355072 9.88337887,20.9932723 L9.88337887,20.9932723 L10,21 L20,21 C20.5522847,21 21,20.5522847 21,20 L21,20 L21,14 L21,14 Z M14,15 C14.5522847,15 15,15.4477153 15,16 L15,18 C15,18.5522847 14.5522847,19 14,19 L12,19 C11.4477153,19 11,18.5522847 11,18 L11,16 C11,15.4477153 11.4477153,15 12,15 L14,15 Z M21,11 L9,11 L9,12 L9,12 L21,12 L21,11 Z" fill="#5E5E5E" fillRule="nonzero" id="Combined-Shape"></path>
                </g>
              </g>
            </g>
          </svg>

          <Text weight="semi-bold">
            <Trans>Calendar</Trans>
          </Text>
        </Box>

        <Box alignItems="center" display="flex">
          <Box marginRight={20}>
            <Button noPaddingX onClick={() => onResetDate()} type="clear">
              <Trans>Reset</Trans>
            </Button>
          </Box>
          <Button noPaddingX onClick={() => onResetDate()} type="clear">
            <Trans>Today</Trans>
          </Button>
        </Box>
      </Box>

      <Divider />

      <DateRange
        className="custom-calendar"
        onChange={onChangeDate}
        rangeColors={['#efeff8']}
        ranges={[selectionRange]}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
      />
    </Box>
  );
}

export function ReservationMapViewCalendar() {
  const dispatch = useDispatch();
  const { filters } = useTypedSelector(state => state.adminReservations);
  const { selectedStartDate } = filters;

  const onResetDate = () => {
    onChangeDate(new Date());
  };

  const onChangeDate = (date: any) => {
    dispatch(setReservationsData({
      filters: {
        ...filters,
        selectedStartDate: new Date(date),
        selectedEndDate: new Date(date),
      },
    }));
  };

  return (
    <Box>
      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={15} marginTop={-3}>
        <Box alignItems="center" display="grid" gap={10} gridTemplateColumns="auto auto">
          <svg height="30px" version="1.1" viewBox="0 0 30 30" width="30px">
            <g fill="none" fillRule="evenodd" id="A-reservations" stroke="none" strokeWidth="1">
              <g id="A-Reservations" transform="translate(-1030.000000, -112.000000)">
                <g id="icons/calendarsmall" transform="translate(1030.000000, 112.000000)">
                  <rect fill="#F4F4F4" height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                  <path d="M20,7 C20.5522847,7 21,7.44771525 21,8 L21,9 L23,9 L23,20 C23,21.6568542 21.6568542,23 20,23 L20,23 L10,23 C8.34314575,23 7,21.6568542 7,20 L7,20 L7,9 L9,9 L9,9 L9,8 C9,7.44771525 9.44771525,7 10,7 C10.5522847,7 11,7.44771525 11,8 L11,9 L19,9 L19,9 L19,8 C19,7.44771525 19.4477153,7 20,7 Z M21,14 L9,14 L9,20 C9,20.5128358 9.38604019,20.9355072 9.88337887,20.9932723 L9.88337887,20.9932723 L10,21 L20,21 C20.5522847,21 21,20.5522847 21,20 L21,20 L21,14 L21,14 Z M14,15 C14.5522847,15 15,15.4477153 15,16 L15,18 C15,18.5522847 14.5522847,19 14,19 L12,19 C11.4477153,19 11,18.5522847 11,18 L11,16 C11,15.4477153 11.4477153,15 12,15 L14,15 Z M21,11 L9,11 L9,12 L9,12 L21,12 L21,11 Z" fill="#5E5E5E" fillRule="nonzero" id="Combined-Shape"></path>
                </g>
              </g>
            </g>
          </svg>

          <Text weight="semi-bold">
            <Trans>Calendar</Trans>
          </Text>
        </Box>

        <Box alignItems="center" display="flex">
          <Button noPaddingX onClick={() => onResetDate()} type="clear">
            <Trans>Today</Trans>
          </Button>
        </Box>
      </Box>

      <Divider />

      <CalendarDateRange
        // @ts-ignore package didn't update it's types
        className="custom-calendar"
        date={new Date(selectedStartDate)}
        onChange={onChangeDate}
        // @ts-ignore package didn't update it's types
        showMonthAndYearPickers={false}
      />
    </Box>
  );
}
