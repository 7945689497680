import {useTypedSelector} from "../../../../Store/Redux/store";
import {Trans} from "@lingui/macro";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";

import Box from "../../../../Components/Box";
import styles from "./styles.module.scss";
import Text from "../../../../Components/Text";
import Button from "../../../../Components/Button";
import {editMapFile, saveMapSectionsAndDesks, setFloorMapApiRequestsData} from "../../../Store/floorMapApiRequestsDuck";
import {setFloorMapData} from "../../../Store/floorMapDuck";
import {transformDeskSections} from "../../FloorMap/Helpers";

export default function FloorMapEditHeader() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [editActionProcessed, setEditActionProcessed] = useState(false);
  const { floorId, locationId } = useParams<{ floorId: string; locationId: string; }>();
  const { adminFloorMap, adminFloorMapApiRequests, adminAttributes } = useTypedSelector(state => state);
  const { fileWithMetaToEdit, mapSizeToEdit, mapImageBoundsToEdit, desks, sections, meetings } = adminFloorMap;
  const { mapFileUpdated } = adminFloorMapApiRequests;

  const onRedirectToPrevPage = () => {
    history.push(`/admin/location/${locationId}/add-floor/${floorId}/floor-map`);
  };

  const onCancelEditing = () => {
    onRedirectToPrevPage();
  };

  const onSaveMapImage = () => {
    if (fileWithMetaToEdit) {
      setEditActionProcessed(true); // disable edit button while api calling

      dispatch(editMapFile({
        floorId,
        data: {
          map: fileWithMetaToEdit.file,
          mapSize: {
            height: mapSizeToEdit.height ?? 1000,
            width: mapSizeToEdit.width ?? 1000,
          },
          mapImageBounds: mapImageBoundsToEdit,
        },
      }));
    }
  };

  const onSaveDeskSections = () => {
    const { saveMapDesksArray, saveMapSectionsArray, saveMapMeetingsArray } = transformDeskSections({
      desks,
      sections,
      meetings,
      attributesDesk: adminAttributes.attributesDesk,
      attributesRoom: adminAttributes.attributesRoom,
    });

    dispatch(saveMapSectionsAndDesks({
      floorId,
      data: {
        desks: saveMapDesksArray,
        sections: saveMapSectionsArray,
        rooms: saveMapMeetingsArray,
      },
    }));
  };

  const onSaveNewFloorMap = () => {
    onSaveMapImage();
    onSaveDeskSections();
  };

  useEffect(() => {
    setEditActionProcessed(false); // enable edit button on success/error

    if (mapFileUpdated) {
      onRedirectToPrevPage();
      dispatch(setFloorMapData({
        mapSize: mapSizeToEdit,
        mapImageBounds: mapImageBoundsToEdit,
      })); // remove after bounds BE completed, add to reducer
      dispatch(setFloorMapApiRequestsData({ mapFileUpdated: false }));
    }
  }, [mapFileUpdated]);

  return (
    <Box alignItems="center" display="flex" justifyContent="between">
      <Box alignItems="center" display="flex" justifyContent="between">
        <h1 className={styles.editFloorMapTitle}><Trans>Adjust Map</Trans></h1>
        <Text className={styles.editFloorMapSubTitle} color="gray" size="md" weight="regular">
          <Trans>Scale and place floor map</Trans>
        </Text>
      </Box>
      <Box alignItems="center" display="flex" justifyContent="end">
        <Button
          onClick={onCancelEditing}
          size="sm"
          type="clear"
          withShadow={false}
        >
          <Trans>
            Cancel
          </Trans>
        </Button>

        <Button
          className={styles.lastButton}
          disabled={editActionProcessed}
          onClick={onSaveNewFloorMap}
          size="sm"
        >
          <Trans>
            Save changes
          </Trans>
        </Button>
      </Box>
    </Box>
  );
}
