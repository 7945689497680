import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../../Store/Redux/store";
import styles from "./styles.module.scss";

import Box from "../../../../../../Components/Box";
import { clearUserSelectionFromSearchData, setGroupDetailsData } from "../../../../../Store/groupDetails";

export default function UsersSelectAll() {
  const dispatch = useDispatch();
  const { infoDataToEdit, limit, totalCount } = useTypedSelector(state => state.groupDetails);
  const { addAll, addUserSearch } = infoDataToEdit;

  const onToggleAddAll = (addAll: boolean) => {
    // select all
    if (addAll) {
      return dispatch(setGroupDetailsData({
        usersToAdd: [],
        infoDataToEdit: {
          ...infoDataToEdit,
          addAll,
        },
      }));
    }

    // unselect
    dispatch(clearUserSelectionFromSearchData());
    dispatch(setGroupDetailsData({
      infoDataToEdit: {
        ...infoDataToEdit,
        addUserSearch: '',
        addAll,
      },
    }));
  };

  return (
    <Box>
      {
        totalCount > limit && addUserSearch ?
          <Box className={styles.allSelect}>
            {
              addAll ?
                <div>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <span>You selected all users from search result "{addUserSearch}"</span>
                  <span
                    className={styles.allSelectActivate}
                    onClick={() => onToggleAddAll(false)}
                  >
                    Unselect all users
                  </span>
                </div> :
                <div>
                  <span
                    className={styles.allSelectActivate}
                    onClick={() => onToggleAddAll(true)}
                  >
                    Select all {totalCount} users
                  </span>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <span>from the search result "{addUserSearch}"</span>
                </div>
            }
          </Box> : null
      }
    </Box>
  );
}
