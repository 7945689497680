import React, { useEffect, useMemo, useRef, useState } from "react";
import { CalendarViewSelection, DailyCalendarViewProps } from "./types";
import { resolveCalendarViewScheduleInterval, resolveCalendarViewStepDates } from "./utils";
import { Box, Fade, Skeleton,Typography, useTheme } from "@mui/material";
import { addDays, addMinutes, differenceInMinutes, eachMinuteOfInterval, isAfter, isBefore, isEqual, isSameDay, isToday, set, startOfDay } from "date-fns";
import { format } from "date-fns-tz";
import { useReservationsAvailabilityQuery } from "store";
import { t } from "@lingui/macro";
import { useLazyLoadScrollHandler } from "hooks";
import { DaySelector, DaySelectorProps } from "../day-selector";
import { CircularProgress } from "@material-ui/core";
import { DailyCalendarViewRow } from "./daily-calendar-view-row";
import { DailyCalendarViewItemButton } from "./daily-calendar-view-item-button";
import { isOverlapping } from "utils";

// * Right now this expects rooms only, but changes can be made to support other types of reservation resources (desk, etc)

export const DailyCalendarView: React.FC<DailyCalendarViewProps> = (props) => {
  const {
    selectedDay,
    step = 15,
    height,
    items,
    headerHeight = 40,
    stepWidth = 50,
    stepHeight = 70,
    hasMore,
    isLoading,
    isFirstLoad,
    defaultSelection,
    maxDate,
    onChange,
    onLoadMore,
    onSelectedDayChange,
  } = props;
  const theme = useTheme();
  const viewRef = useRef<HTMLDivElement>(null);
  const debouncedHandleScroll = useLazyLoadScrollHandler(viewRef, onLoadMore, 300);
  const [selection, setSelection] = useState<CalendarViewSelection>();
  const [isOnHighLoad, setIsOnHighLoad] = useState(false);
  const { startDate } = useReservationsAvailabilityQuery();
  const stepDates = useMemo(() => resolveCalendarViewStepDates(selectedDay, step), [selectedDay, step]);
  const initialScrollTime = set(new Date(), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 });
  const initialScrollLeft = useMemo(() => {
    const minutes = eachMinuteOfInterval({ start: startOfDay(new Date()), end: initialScrollTime }, { step });
    
    return minutes.slice(0, -1).length * stepWidth;
  }, []);
  const todayInitialScrollLeft = useMemo(() => {
    const now = new Date();
    const start = startOfDay(now);
    const end = set(now, { minutes: 0, seconds: 0, milliseconds: 0 });

    if (isEqual(start, end)) {
      return 0;
    }

    const minutes = eachMinuteOfInterval({ start, end }, { step });
    
    return minutes.slice(0, -1).length * stepWidth;
  }, []);
  const firstStepDate = stepDates[0];
  const lastStepDate = stepDates[stepDates.length - 1];

  useEffect(() => {
    const now = new Date();

    if (viewRef.current) {
      if (viewRef.current.scrollLeft !== initialScrollLeft && (!isToday(selectedDay) || isBefore(now, initialScrollTime))) {
        viewRef.current.scrollLeft = initialScrollLeft;
      } else if (viewRef.current.scrollLeft !== todayInitialScrollLeft && isToday(selectedDay) && isAfter(now, initialScrollTime)) {
        viewRef.current.scrollLeft = todayInitialScrollLeft;
      } 
    }
  }, [viewRef, startDate]);

  useEffect(() => {
    if (defaultSelection) {
      const { id, start, end, summary } = defaultSelection;

      if (start && end && isSameDay(start, selectedDay) && isSameDay(end, selectedDay)) {
        setSelection({ id, start, end, summary });
      } else if (defaultSelection?.id) {
        setSelection({ id: defaultSelection?.id });
      }
    } else if (selection) {
      setSelection(undefined);
    }
  }, [selectedDay?.getTime(), JSON.stringify(defaultSelection)]);

  useEffect(() => {
    if (selection?.id) {
      const item = items?.find(({ id }) => id === selection?.id);
      
      if (item) {
        const maxDate = addDays(startOfDay(new Date()), item.reservationDayLimit || 180);

        if (isSameDay(selectedDay, addDays(maxDate, 1)) || isAfter(selectedDay, maxDate)) {
          onChange?.(undefined);
        }
      }
    }
  }, [selectedDay?.getTime(), selection?.id]);

  useEffect(() => {
    const timeout = isLoading ? setTimeout(() => setIsOnHighLoad(true), 5000) : undefined;

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      setIsOnHighLoad(false);
    };
  }, [isLoading, setIsOnHighLoad]);

  const handleStepClick = (itemId: string, stepDate: Date) => {
    const isNewSelection = !selection
      || itemId !== selection.id
      || (selection.id && !selection.start)
      || (selection.start && selection.end && differenceInMinutes(selection.end, selection.start) > step)
      || (selection.start && (isEqual(stepDate, selection.start) || isBefore(stepDate, selection.start)));

    if (isNewSelection) {
      setSelection({ id: itemId, start: stepDate });
      onChange?.({ id: itemId, start: stepDate, end: addMinutes(stepDate, step) });
    } else if (selection?.start) {
      const { id, start } = selection;
      const end = addMinutes(stepDate, step);
      const item = items?.find((item) => item.id === id);
      const overlaps = item?.schedules?.some(({ startDate, endDate }) => isOverlapping([start, end], [startDate, endDate]));

      if (overlaps) {
        setSelection({ id, start: stepDate });
        onChange?.({ id, start: stepDate, end: addMinutes(stepDate, step) });

        return;
      }

      const newSelection = { id, start, end: addMinutes(stepDate, step) };

      setSelection(newSelection);
      onChange?.(newSelection);
    }
  };
 
  const handleDaySelectorChange: DaySelectorProps["onChange"] = (date) => {
    setSelection(undefined);
    onSelectedDayChange?.(date);
  };

  const handleSelectionClick = () => {
    if (selection?.start) {
      setSelection(undefined);
      onChange?.({ id: selection.id, start: undefined, end: undefined });
    }
  };

  const handleRoomSelection = (roomId: string) => {
    setSelection({ id: roomId });
    onChange?.({ id: roomId });
  };

  const labels: JSX.Element[] = [];
  const stepsByHour: { [hour: string]: number } = {};

  for (const stepDate of stepDates) {
    const hour = format(stepDate, "HH");

    stepsByHour[hour] = stepsByHour[hour] ? stepsByHour[hour] + 1 : 1;
  }

  for (let index = 0; index < 24; index++) {
    const hour = `${index}`.padStart(2, "0");
    const label = `${hour}:00`;
    const hourSteps = stepsByHour[hour];

    if (hourSteps > 0) {
      labels.push((
        <Box
          alignItems="center"
          bgcolor="white"
          display="flex"
          height={headerHeight}
          key={label}
          minWidth={hourSteps * stepWidth}
        >
          {hourSteps === 60 / step ? <Typography>{label}</Typography> : <></>}
        </Box>
      ));
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={height}
      onScroll={debouncedHandleScroll}
      overflow="auto"
      paddingBottom={2}
      position="relative"
      ref={viewRef}
    >
      <Box bgcolor="#FFF" display="flex" minWidth="100%" position="sticky" top={0} width="max-content" zIndex={30}>
        <Box
          bgcolor="#FFF"
          flex="0 0 260px"
          height={headerHeight}
          left={0}
          position="sticky"
          top={0}
          width={260}
          zIndex={10}
        >
          <DaySelector
            day={selectedDay}
            disabled={isLoading && isFirstLoad}
            maxDate={maxDate}
            onChange={handleDaySelectorChange}
          />
        </Box>
        <Box
          alignItems="center"
          bgcolor="white"
          display="flex"
          height={headerHeight}
          top={0}
        >
          {labels}
        </Box>
      </Box>
      {isLoading && isFirstLoad ? (
        <Box display="flex" width="max-content">
          <Box
            bgcolor="#FFF"
            display="flex"
            flex="0 0 260px"
            height={stepHeight}
            left={0}
            padding={0.5}
            position="sticky"
            top={0}
            width={260}
            zIndex={10}
          >
            <Skeleton
              sx={{ bgcolor: theme.palette.grey[100], borderRadius: 2, flex: "1 0 auto", height: "100%" }}
              variant="rectangular"
            />
          </Box>
          <Box position="relative">
            <Skeleton
              sx={({ palette }) => {
                const interval = resolveCalendarViewScheduleInterval(firstStepDate, lastStepDate, step, firstStepDate, lastStepDate) + 1;

                return {
                  bgcolor: palette.grey[100],
                  width: interval * stepWidth,
                  height: stepHeight,
                };
              }}
              variant="rectangular"
            />
            <Fade in={isOnHighLoad}>
              <Box alignItems="center" bottom={0} display="flex" left={0} pl={2} position="absolute" right={0} top={0}>
                <CircularProgress size={24} />
                <Typography color={theme.palette.grey[700]} fontSize={14} ml={2}>
                  {t`Please, wait while we fetch rooms schedules from Outlook`}
                </Typography>
              </Box>
            </Fade>
          </Box>
        </Box>
      ) : (
        <Box bgcolor={theme.palette.grey[100]} display="flex" minWidth="100%" position="relative" width="max-content">
          <Box bgcolor="#FFF" flex="0 0 260px" left={0} position="sticky" top={0} width={260} zIndex={20}>
            {items?.map((item) => (
              <DailyCalendarViewItemButton
                handleRoomSelection={handleRoomSelection}
                item={item}
                key={item.id}
                selectedDay={selectedDay}
                selection={selection}
                stepHeight={stepHeight}
              />
            ))}
            {hasMore ? (
              <Box display="flex" height={stepHeight} padding={0.5}>
                <Skeleton
                  sx={{ bgcolor: theme.palette.grey[100], borderRadius: 2, flex: "1 0 auto", height: "100%" }}
                  variant="rectangular"
                />
              </Box>
            ) : undefined}
          </Box>
          <Box>
            {items?.map((item, yIndex) => (
              <DailyCalendarViewRow
                firstStepDate={firstStepDate}
                handleSelectionClick={handleSelectionClick}
                handleStepClick={handleStepClick}
                item={item}
                key={item.id}
                lastStepDate={lastStepDate}
                selection={selection}
                step={step}
                stepDates={stepDates}
                stepHeight={stepHeight}
                stepWidth={stepWidth}
                yIndex={yIndex}
              />
            ))}
            {hasMore ? (
              <Box position="relative">
                <Skeleton
                  sx={{ bgcolor: theme.palette.grey[100], flex: "1 0 auto", width: "100%", height: stepHeight }}
                  variant="rectangular"
                />
                <Fade in={isOnHighLoad}>
                  <Box alignItems="center" bottom={0} display="flex" left={0} pl={2} position="absolute" right={0} top={0}>
                    <CircularProgress size={24} />
                    <Typography color={theme.palette.grey[700]} fontSize={14} ml={2}>
                      {t`Please, wait while we fetch rooms schedules from Outlook`}
                    </Typography>
                  </Box>
                </Fade>
              </Box>
            ) : undefined}
          </Box>
        </Box>
      )}
    </Box>
  );
};
