import styles from "./styles.module.scss";
import Box from "../../../../../Components/Box";
import ActiveDirectory from "../../../../../Components/Icons/ActiveDirectory";
import Tooltip from "Components/Tooltip";

export default function AdNotification() {
  return (
    <Box alignItems="center" display="flex">
      <Tooltip
        placement="bottom"
        title={<div className={styles.tooltipContent}>You are not allowed to add new user manually</div>}
      >
        <div className={styles.tooltipContentBox}>
          <ActiveDirectory/>
          <div>Linked to Active Directory</div>
        </div>
      </Tooltip>
    </Box>
  );
}