import React from "react";
import { TabPanelProps } from "./types";

export const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, index, value } = props;

  return (
    <div hidden={value !== index} role="tabpanel">
      {value === index ? children : undefined}
    </div>
  );
};
