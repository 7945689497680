import Box from 'Components/Box';
import Button from 'Components/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Heading from 'Components/Heading';
import IconButton from '@material-ui/core/IconButton';
import styles from './styles.module.scss';
import TextField from 'Components/TextField';
import useSnackbar from 'Components/Snackbar/useSnackbar';
import { addLocationParkingSpot, editParkingSpot, selectLocationParkingSpotByName } from 'Admin/Store/parking';
import { ParkingSpot } from 'Admin/Store/parking/models';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'Store/Redux/store';

interface Props {
  parkingSpot?: ParkingSpot | null;
  open: boolean;
  toggleOpen(): any;
}

export default function AddEditParkingSpotModal({
  open,
  parkingSpot,
  toggleOpen,
}: Props) {
  const dispatch = useDispatch();
  const [openSnackbar] = useSnackbar();
  const { locationId } = useParams<{ locationId: string }>();
  const { config, parking } = useTypedSelector(state => state);

  const [errors, setErrors] = useState({
    name: '',
  });
  const [firstLoad, setFirstLoad] = useState(true);

  const [name, setName] = useState(parkingSpot?.name ?? '');
  const [floor, setFloor] = useState(parkingSpot?.floor ?? '');

  const handleConfirm = () => {
    setErrors({
      name: !name ? t`Field required` : '',
    });

    if (!name) {
      return;
    }

    const nameAlreadyExists = Boolean(selectLocationParkingSpotByName({
      locationId,
      parkingSpotName: name,
      state: parking,
    }));

    if (nameAlreadyExists) {
      setErrors({
        name: t`Name already exists`,
      });

      return;
    }

    if (parkingSpot) {
      dispatch(editParkingSpot({
        parkingSpotId: parkingSpot.id,
        data: {
          name,
          floor,
          isAvailable: parkingSpot.isAvailable,
        },
      }));
    } else {
      dispatch(addLocationParkingSpot({
        locationId,
        data: {
          name,
          floor: floor,
        },
      }));
    }
  };

  useEffect(() => {
    setName(parkingSpot?.name ?? '');
    setFloor(parkingSpot?.floor ?? '');
  }, [parkingSpot]);

  useEffect(() => {
    if (!firstLoad) {
      if (parking.addMessages.error) {
        openSnackbar({
          text: parking.addMessages.error,
          type: 'error',
        });
      } else if (parking.addMessages.success) {
        openSnackbar({
          text: parking.addMessages.success,
          type: 'success',
        });

        if (open) {
          toggleOpen();
        }
      }
    }

    setFirstLoad(false);
  }, [parking.addMessages]);

  useEffect(() => {
    if (!firstLoad) {
      if (parking.editMessages.error) {
        openSnackbar({
          text: parking.editMessages.error,
          type: 'error',
        });
      } else if (parking.editMessages.success) {
        openSnackbar({
          text: parking.editMessages.success,
          type: 'success',
        });

        if (open) {
          toggleOpen();
        }
      }
    }

    setFirstLoad(false);
  }, [parking.editMessages]);

  return (
    <Dialog
      aria-labelledby="add parking spot dialog"
      className={styles.dialogContainer}
      classes={{ paper: styles.dialog }}
      fullWidth
      maxWidth="xs"
      onClose={toggleOpen}
      open={open}
    >
      <Box dataTestId="parking-spots-add-edit-modal">
        <Box alignItems="center" display="flex" justifyContent="between">
          <Heading size="sm">
            {parkingSpot ? (
              <Trans>
                Edit parking spot
              </Trans>
            ) : (
              <Trans>
                Add parking spot
              </Trans>
            )}
          </Heading>

          <IconButton
            onClick={toggleOpen}
            size="small"
            style={{
              backgroundColor: config.theme.primaryLight,
              borderRadius: 8,
              height: 30,
              width: 30,
            }}
          >
            <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
          </IconButton>
        </Box>

        <Box dataTestId="parking-spots-add-edit-modal-name" marginTop={12}>
          <TextField
            error={Boolean(errors.name)}
            helperText={errors.name}
            id="parkingSpotName"
            label={t`Parking spot name`}
            onChange={(event) => setName(event.target.value)}
            placeholder={t`Type here`}
            required
            value={name}
          />

          <Box dataTestId="parking-spots-add-edit-modal-floor" marginTop={20}>
            <TextField
              id="parkingSpotFloorNumber"
              label={t`Floor number`}
              onChange={(event) => setFloor(event.target.value)}
              placeholder={t`Type here`}
              value={floor}
            />
          </Box>
        </Box>

        <Box
          alignItems="center"
          display="flex"
          justifyContent="end"
          marginTop={15}
        >
          <Button
            aria-label={t`Cancel Add Parking Spot`}
            data-testid="parking-spots-add-edit-modal-cancel"
            name={t`Cancel Add Parking Spot`}
            onClick={toggleOpen}
            size="sm"
            type="clear"
            withShadow={false}
          >
            <Trans>
              Cancel
            </Trans>
          </Button>

          <Button
            aria-label={t`Add Parking Spot`}
            buttonType="submit"
            data-testid="parking-spots-add-edit-modal-confirm"
            disabled={!name}
            name={t`Add Parking Spot`}
            onClick={handleConfirm}
            size="sm"
          >
            {parkingSpot ? t`Edit Parking Spot` : t`Add Parking Spot`}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}