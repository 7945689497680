import { Autocomplete, AutocompleteInputChangeReason } from "@material-ui/lab";
import { Trans, t } from "@lingui/macro";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete/Autocomplete";
import styles from "../../../Locations/LocationForm/styles.module.scss";
import Box from "../../../../../Components/Box";
import arrowIcon from "../../../Locations/LocationForm/assets/arrow-down.svg";
import { TextField } from "@material-ui/core";
import { useTypedSelector } from "../../../../../Store/Redux/store";
import { IAvailableRoom } from "../../../../Store/roomsManagement/models";
import { useEffect } from "react";
import { getAvailableAdminFloorRooms, setAdminFloorRoomsData } from "../../../../Store/roomsManagement";
import { useDispatch } from "react-redux";
import _ from "lodash";
import Text from "Components/Text";

interface Props {
  setErrorClass: Function;
  setSelectedValue: Function;
  errorClass: string;
}

export default function EmailsList(props: Props) {
  const dispatch = useDispatch();
  const { availableFloorRooms } = useTypedSelector(state => state.roomsManagement);
  const { setSelectedValue, errorClass, setErrorClass } = props;

  const onResetSearchState = () => {
    dispatch(setAdminFloorRoomsData({
      availableFloorRooms: [],
    }));

    setSelectedValue('');
  };

  const onEmailChange = (event: any, availableRoom: IAvailableRoom) => {
    if (availableRoom) {
      setSelectedValue(availableRoom.id);
      setErrorClass(false);
    } else {
      setSelectedValue("");
    }
  };

  // const onFilterBySearch = (search: string) => {
  //   const fieldsToSearch = ['id', 'name'];
  //
  //   const filteredData = availableFloorRooms
  //     .filter(item => {
  //       // @ts-ignore
  //       return Object.keys(item).some(key => fieldsToSearch.includes(key) && item[key].toLowerCase().includes(search.toLowerCase()));
  //     });
  //
  //   setFilteredAvailableRooms(filteredData);
  // };

  const onSearchChange = _.debounce((event: any, value: string, reason: AutocompleteInputChangeReason) => {
    // if (reason === 'clear') { // click on "cross" icon
    //   onResetSearchState();
    // }

    if (reason !== 'reset' && reason !== 'clear') { // 'reset' fires on select from list, no need to handle api
      const search = event.target.value;

      dispatch(getAvailableAdminFloorRooms({
        search,
        limit: 10,
      }));
    }
  }, 300);

  useEffect(() => {
    onResetSearchState();
  }, []);

  return (
    <>
      <span className={styles.requiredSign}>*</span><label htmlFor="room-email"><Trans>Select room</Trans></label>
      <Box display="flex" justifyContent="between">
        <Box direction="column" display="flex" width="100%">
          <Autocomplete
            className={`input input--default input--inline`}
            filterOptions={(x) => x}
            getOptionDisabled={({ isAssigned }) => !!isAssigned}
            getOptionLabel={({ name }) => name}
            id="graphRoom"
            onChange={(event: any, room: any) => onEmailChange(event, room)}
            onInputChange={(event, value, reason) => onSearchChange(event, value, reason)}
            options={availableFloorRooms}
            popupIcon={
              <img height={10} src={arrowIcon} width={14} />
            }
            renderInput={(params: AutocompleteRenderInputParams) => {
              return (
                <TextField
                  {...params}
                  className={`input input--default ${errorClass}`}
                  placeholder={t`Not selected`}
                  variant="outlined"
                />
              );
            }}
            renderOption={(option) => (
              <Box direction="column" display="flex">
                <Text size="md" weight="regular">{option.name}</Text>
                <Text color="gray" size="sm" weight="regular">{option.id}</Text>
              </Box>
            )}
          />
        </Box>
      </Box>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      {errorClass ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
    </>
  );
}