import { ActionPayload, BaseErrorResponse, BaseResponse } from 'Store/Models/ReduxModels';
import {
  GetAttendeesRequest,
  GetAttendeesResponse,      
  IAttendees,
} from './models';
import { t } from '@lingui/macro';

export const GET_ATTENDEES = 'GET_ATTENDEES';

export const GET_ATTENDEES_SUCCESS = 'GET_ATTENDEES_SUCCESS';

export const GET_ATTENDEES_FAIL = 'GET_ATTENDEES_FAIL';

export interface GetAttendees {
  type: typeof GET_ATTENDEES;
  payload: ActionPayload<GetAttendeesRequest>;
}

export interface GetAttendeesSuccess {
  type: typeof GET_ATTENDEES_SUCCESS;
  payload: BaseResponse<GetAttendeesResponse>;
}

export interface GetAttendeesFail {
  type: typeof GET_ATTENDEES_FAIL;
  payload: BaseErrorResponse;
}

export type Actions =
  | GetAttendees
  | GetAttendeesSuccess
  | GetAttendeesFail;

export interface State {
  error: string;
  loading: boolean;
  attendees: IAttendees[],
  nextLink?: string;
}

const initialState: State = {
  error: '',
  loading: false,
  attendees: [],
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case GET_ATTENDEES:
      if (action.payload.additionalData?.firstRequest) {
        return { ...state, loading: true, attendees: [], nextLink: undefined };
      }

      return state;
    case GET_ATTENDEES_SUCCESS: {      
      const attendees = action.payload.data.result.data.items;
      const nextLink = action.payload.data.result.data.next;

      return {
        ...state,
        nextLink,
        attendees: [...state.attendees, ...attendees],
        error: '',
        loading: false,
      };
    }
    case GET_ATTENDEES_FAIL: 
      return {
        ...state,
        error: t`There was an error loading attendees. Please try again.`,
        loading: false,        
      };

    default:
      return state;
  }
}

export function getAttendees(url?: string, data?: GetAttendeesRequest): GetAttendees {
  return {
    type: GET_ATTENDEES,
    payload: {
      request: {
        method: 'GET',
        url: url || "/api/v2/users/attendees",
        data,
      },
      additionalData: {
        firstRequest: !url,
      },
    },
  };
}