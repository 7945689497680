interface Props {
  color?: string;
  size?: number;
}

export default function DeniedCircle({ color = '#4A4A4A', size = 16 }: React.PropsWithChildren<Props>) {
  return (
    <svg height={size} version="1.1" viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g fill="none" fillRule="evenodd" id="A-users" stroke="none" strokeWidth="1">
        <g fill={color} fillRule="nonzero" id="p-certificate-preview" transform="translate(-60.000000, -655.000000)">
          <g id="icons/plus" transform="translate(60.000000, 655.000000)">
            <path d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z M6.58578644,5.17157288 L8,6.586 L9.41421356,5.17157288 C9.80473785,4.78104858 10.4379028,4.78104858 10.8284271,5.17157288 C11.2189514,5.56209717 11.2189514,6.19526215 10.8284271,6.58578644 L9.414,8 L10.8284271,9.41421356 C11.2189514,9.80473785 11.2189514,10.4379028 10.8284271,10.8284271 C10.4379028,11.2189514 9.80473785,11.2189514 9.41421356,10.8284271 L8,9.414 L6.58578644,10.8284271 C6.19526215,11.2189514 5.56209717,11.2189514 5.17157288,10.8284271 C4.78104858,10.4379028 4.78104858,9.80473785 5.17157288,9.41421356 L6.586,8 L5.17157288,6.58578644 C4.78104858,6.19526215 4.78104858,5.56209717 5.17157288,5.17157288 C5.56209717,4.78104858 6.19526215,4.78104858 6.58578644,5.17157288 Z" id="Combined-Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
