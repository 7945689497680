import { isAfter, parse } from "date-fns";
import { ReservationsAvailabilityQuery } from "../types";
import { DeepNonNullable } from "types";
import { useSelector } from "react-redux";
import { selectReservationsAvailabilityQueryEndDate, selectReservationsAvailabilityQueryEndTime, selectReservationsAvailabilityQueryStartDate, selectReservationsAvailabilityQueryStartTime } from "../selectors";
import { getDefaultReservationsAvailabilityQuery } from "../utils";

export const useReservationsAvailabilityQuery = (): DeepNonNullable<ReservationsAvailabilityQuery> => {
  const startDate = useSelector(selectReservationsAvailabilityQueryStartDate);
  const endDate = useSelector(selectReservationsAvailabilityQueryEndDate);
  const startTime = useSelector(selectReservationsAvailabilityQueryStartTime);
  const endTime = useSelector(selectReservationsAvailabilityQueryEndTime);
  const today = new Date();
  const {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    startTime: defaultStartTime,
    endTime: defaultEndTime,
  } = getDefaultReservationsAvailabilityQuery();

  return {
    startDate: startDate && isAfter(parse(startDate, "yyyy-MM-dd", new Date()), today) ? startDate : defaultStartDate,
    endDate: endDate && isAfter(parse(endDate, "yyyy-MM-dd", new Date()), today) ? endDate : defaultEndDate,
    startTime: startTime || defaultStartTime,
    endTime: endTime || defaultEndTime,
    rrule: "",
  };
};