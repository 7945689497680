import { isAfter, isBefore, isEqual } from "date-fns";
import { CalendarViewSchedule, CalendarViewSelection } from "../types";

export const isCalendarViewStepDisabled = (
  stepDate: Date,
  itemId: string,
  schedules?: CalendarViewSchedule[],
  selection?: CalendarViewSelection,
): boolean => {
  if (schedules?.length) {
    for (const { startDate, endDate } of schedules) {
      if ((isEqual(stepDate, startDate) || isAfter(stepDate, startDate)) && isBefore(stepDate, endDate)) {
        return true;
      }
    }
  }

  if (selection) {
    const { id, start, end } = selection;

    if (itemId === id) {
      if (start) {
        if (isEqual(stepDate, start)) {
          return true;
        }
  
        if (end && isAfter(stepDate, start) && isBefore(stepDate, end)) {
          return true;
        }
      }
    }
  }

  return false;
};
