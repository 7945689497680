import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../../../Store/Redux/store";
import Box from "../../../../../../Components/Box";
import {Trans} from "@lingui/macro";
import ButtonBase from "@material-ui/core/ButtonBase";
import Text from "../../../../../../Components/Text";
import ToggleOffIcon from "../../../../../../Components/Icons/TurnOff";
import ToggleOnIcon from "../../../../../../Components/Icons/TurnOn";
// import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// import {deleteFloor, editFloor} from "../../../../../App/Store/Locations/locationsDuck";
import {editFloor} from "../../../../../../App/Store/Locations/locationsDuck";
import {useParams} from "react-router-dom";
import TableSubmenuButton from "../../../../Buttons/TableSubmenuButton";

export default function AddFloorActionMenu() {
  const dispatch = useDispatch();
  const { floorId } = useParams<{ floorId: string; locationId: string; }>();
  const { adminFloorMapApiRequests, config, locations } = useTypedSelector(state => state);
  const actionLoaded = adminFloorMapApiRequests.loading && !adminFloorMapApiRequests.mapSectionSaved;
  const floor = locations.floorsAdmin.find(floor => floor.id === floorId);

  const onToggleFloor = () => {
    if (floor) {
      dispatch(editFloor(floor.id, {
        active: !floor.active,
      }));
    }
  };

  {/* temp prevent delete floors due to task https://zira.zstream.io/app/tasks/task/IPG-1859 */}
  // const onDeleteFloor = () => {
  //   dispatch(deleteFloor(floorId));
  // };

  return (
    <TableSubmenuButton
      disabled={actionLoaded}
      iconButtonColor={'#ffffff'}
      iconButtonStyle={{ backgroundColor: config.theme.primary, borderRadius: 6, padding: 6, cursor: actionLoaded ? 'default' : 'pointer' }}
      iconButtonTestId="floor-map-details-header-rightMenu"
    >
      <Box borderStyle="shadow" className="popover-content">
        <ButtonBase className="popover-item-button" data-testid="floor-map-details-header-rightMenu-toggle" onClick={onToggleFloor}>
          {floor?.active ? (
            <ToggleOffIcon color={config.theme.primary} size={20} />
          ) : (
            <ToggleOnIcon color={config.theme.primary} size={20} />
          )}

          <Box marginStart={5}>
            <Text color="blue" size="md">
              {floor?.active ? (
                <Trans>Turn off Floor</Trans>
              ) : (
                <Trans>Turn on Floor</Trans>
              )}
            </Text>
          </Box>
        </ButtonBase>

        {/* temp prevent delete floors due to task https://zira.zstream.io/app/tasks/task/IPG-1859 */}
        {/*<ButtonBase className="popover-item-button" data-testid="floor-map-details-header-rightMenu-delete" onClick={onDeleteFloor}>*/}
        {/*  <DeleteOutlineIcon style={{ color: config.theme.primary, fontSize: 20 }} />*/}

        {/*  <Box marginStart={5}>*/}
        {/*    <Text color="blue" size="md">*/}
        {/*      <Trans>*/}
        {/*        Delete Floor*/}
        {/*      </Trans>*/}
        {/*    </Text>*/}
        {/*  </Box>*/}
        {/*</ButtonBase>*/}
      </Box>
    </TableSubmenuButton>
  );
}