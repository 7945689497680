import Box from 'Components/Box';
import format from 'date-fns/format';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { Trans } from '@lingui/macro';

export default function FromToTimeUI({ timeFrom }: { timeFrom: number }) {
  // These dates are arbitrary, the only parameter needed is the hours, so we can format properly like this: 09:00
  const hours = Math.trunc(timeFrom); // returns 3
  const minutes = Number((timeFrom - hours).toFixed(2)) * 100 * 0.6;
  const dateFrom = new Date(2021, 3, 14, hours, minutes);

  return (
    <Box alignItems="center" className={styles.fromToTimeUI} display="flex">
      <Box alignItems="center" display="flex">
        <Text color="lightGray" size="md">
          <Trans>
            At
          </Trans>
        </Text>

        <Box className={styles.hourContainer}>
          <Text>
            {format(dateFrom, 'hh:mm')}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
