import { ActionPayload, BaseErrorResponse, BaseResponse } from 'Store/Models/ReduxModels';
import { t } from '@lingui/macro';
import {
  ConfirmApproverEmailRequest,
  ConfirmApproverEmailResponse,
  ConfirmOrDenyBookingRequest,
  ConfirmOrDenyBookingResponse,
  SetBookingApprovalDataRequest,
} from './models';

export const CONFIRM_APPROVE_BOOKING = 'CONFIRM_APPROVE_BOOKING';
export const CONFIRM_APPROVE_BOOKING_FAILURE = 'CONFIRM_APPROVE_BOOKING_FAILURE';
export const CONFIRM_APPROVE_BOOKING_SUCCESS = 'CONFIRM_APPROVE_BOOKING_SUCCESS';

export const CONFIRM_OR_DENY_BOOKING = 'CONFIRM_OR_DENY_BOOKING';
export const CONFIRM_OR_DENY_BOOKING_FAILURE = 'CONFIRM_OR_DENY_BOOKING_FAILURE';
export const CONFIRM_OR_DENY_BOOKING_SUCCESS = 'CONFIRM_OR_DENY_BOOKING_SUCCESS';

export const SET_BOOKING_APPROVAL_DATA = 'SET_BOOKING_APPROVAL_DATA';

export interface ConfirmApproveBooking {
  type: typeof CONFIRM_APPROVE_BOOKING;
  payload: ActionPayload<ConfirmApproverEmailRequest>;
}
export interface ConfirmApproveBookingFailure {
  type: typeof CONFIRM_APPROVE_BOOKING_FAILURE;
  payload: BaseErrorResponse;
}
export interface ConfirmApproveBookingSuccess {
  type: typeof CONFIRM_APPROVE_BOOKING_SUCCESS;
  payload: BaseResponse<ConfirmApproverEmailResponse>;
}

export interface ConfirmOrDenyBooking {
  type: typeof CONFIRM_OR_DENY_BOOKING;
  payload: ActionPayload<ConfirmOrDenyBookingRequest>;
}
export interface ConfirmOrDenyBookingFailure {
  type: typeof CONFIRM_OR_DENY_BOOKING_FAILURE;
  payload: BaseErrorResponse;
}
export interface ConfirmOrDenyBookingSuccess {
  type: typeof CONFIRM_OR_DENY_BOOKING_SUCCESS;
  payload: BaseResponse<ConfirmOrDenyBookingResponse>;
}

export interface SetBookingApprovalData {
  type: typeof SET_BOOKING_APPROVAL_DATA;
  payload: SetBookingApprovalDataRequest;
}

export type Actions =
  | ConfirmApproveBooking
  | ConfirmApproveBookingFailure
  | ConfirmApproveBookingSuccess
  | ConfirmOrDenyBooking
  | ConfirmOrDenyBookingFailure
  | ConfirmOrDenyBookingSuccess
  | SetBookingApprovalData;

export interface State {
  error: string;
  loading: boolean;
  deskId: string;
  email: string;
  confirmed: boolean;
  sort: number;
}

const initialState: State = {
  error: '',
  loading: false,
  deskId: '',
  email: '',
  confirmed: false,
  sort: 0,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case CONFIRM_APPROVE_BOOKING: {
      return {
        ...state,
        error: '',
        loading: true,
        confirmed: false,
      };
    }
    case CONFIRM_APPROVE_BOOKING_FAILURE: {
      return {
        ...state,
        error: t`An error ocurred while confirming you as an approver. Please try again`,
        loading: false,
      };
    }
    case CONFIRM_APPROVE_BOOKING_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        confirmed: true,
      };
    }
    
    case CONFIRM_OR_DENY_BOOKING: {
      return {
        ...state,
        error: '',
        loading: true,
        confirmed: false,
      };
    }
    case CONFIRM_OR_DENY_BOOKING_FAILURE: {
      return {
        ...state,
        error: 'An error ocurred while approving/denying the booking. Please try again',
        loading: false,
      };
    }
    case CONFIRM_OR_DENY_BOOKING_SUCCESS: {
      const { id } = action.payload.data.result.data;

      return {
        ...state,
        error: '',
        loading: false,
        confirmed: true,
        deskId: id,
      };
    }

    case SET_BOOKING_APPROVAL_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function confirmApproverEmail(data: ConfirmApproverEmailRequest): ConfirmApproveBooking {
  return {
    type: CONFIRM_APPROVE_BOOKING,
    payload: {
      request: {
        method: 'GET',
        url: `/api/desks/confirmApproverEmail`,
        data,
      },
    },
  };
}

export function confirmOrDenyBooking(data: ConfirmApproverEmailRequest): ConfirmApproveBooking {
  return {
    type: CONFIRM_APPROVE_BOOKING,
    payload: {
      request: {
        method: 'GET',
        url: `/api/bookings/confirm`,
        data,
      },
    },
  };
}

export function setBookingApprovalData(data: SetBookingApprovalDataRequest): SetBookingApprovalData {
  return {
    type: SET_BOOKING_APPROVAL_DATA,
    payload: data,
  };
}
