import Checkbox from "Components/Checkbox";
import styles from './styles.module.scss';

interface Props {
  checked: boolean;
  label: string;
  name: string;
  onChange(checked: boolean): void;
}

export default function UploadCovidPrivacyStatement({
  checked,
  label,
  name,
  onChange,
}: Props) {
  return (
    <Checkbox
      checked={checked}
      classes={{ container: styles.container }}
      label={label}
      name={name}
      onChange={onChange}
    />
  );
}