import React from "react";
import { PageHeaderProps } from "./types";
import { Box, Typography } from "@mui/material";
import { ArrowBackRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { NavigationButton } from "../button";

export const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const { title, href, ...rest } = props;

  return (
    <Box {...rest} paddingBottom={4} paddingTop={6}>
      <Box position="relative">
        <Box left={-40} position="absolute" top={-4}>
          <Link to={href}>
            <NavigationButton><ArrowBackRounded fontSize="small" /></NavigationButton>
          </Link>
        </Box>
        <Typography component="h1" fontWeight="600" lineHeight="100%" variant="h5">{title}</Typography>
      </Box>
    </Box>
  );
};
