import { DndContext, DndContextProps } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { t } from "@lingui/macro";
import { DeleteOutline } from "@mui/icons-material";
import { Box, Button, Skeleton, Typography, useTheme } from "@mui/material";
import { FileUploadButton, ProgressOverlay, UploadBox, useToast } from "components";
import { useCustomDndSensors, useDebouncedCallback, useExceptionTracker, useStorageFileObjectURL } from "hooks";
import React, { useEffect, useState } from "react";
import { Image, isSuccessAPIResponse, useCreateRoomImageMutation, useDeleteRoomImageMutation, useLazyGetRoomImagesQuery, useUpdateRoomImagesMutation } from "store";
import { moveArrayItem, resolveDragEndEventIndexes } from "utils";

type ImageState = "loading" | "loaded" | "deleting";

const RoomImage: React.FC<{
  image: (Image & { state: ImageState });
  index: number;
  onDelete: (id: string) => void;
}> = (props) => {
  const { image, index, onDelete } = props;
  const theme = useTheme();
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({ id: image.id });
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const objectURL = useStorageFileObjectURL(image.url);

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isDragging ? "grabbing" : "grab",
  };
  
  return (
    <Box
      display="flex"
      flexBasis="33.33%"
      height={102}
      justifyContent={index % 3 === 0 ? "flex-start" : index % 3 === 1 ? "center" : "flex-end"}
      width="33.33%"
    >
      {image.state === "loading" ? (
        <Skeleton height={90} sx={{ borderRadius: 1 }} variant="rectangular" width={160} />
      ) : (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
          <Box
            alignItems="center"
            borderRadius={1}
            display="flex"
            height={90}
            justifyContent="center"
            onMouseEnter={() => setMenuIsVisible(true)}
            onMouseLeave={() => image.state !== "deleting" ? setMenuIsVisible(false) : undefined}
            position="relative"
            sx={{ backgroundColor: theme.palette.grey[100], backgroundImage: objectURL ? `url('${objectURL}')` : undefined, backgroundSize: "cover" }}
            width={160}
          >
            {image.state === "deleting" ? (
              <Skeleton height={90} sx={{ position: "absolute", top: 0, left: 0, zIndex: 1, opacity: 0.8, borderRadius: 1 }} variant="rectangular" width={160} />
            ) : undefined}
            {menuIsVisible ? (
              <Button
                data-no-dnd="true"
                onClick={() => onDelete?.(image.id)}
                size="small"
                sx={{
                  borderRadius: 1,
                  minWidth: 0,
                  bgcolor: "#fff",
                  boxShadow: theme.shadows[8],
                  ":hover": { bgcolor: "#eee" },
                }}
                variant="text"
              >
                <DeleteOutline fontSize="small" />
              </Button>
            ) : undefined}
          </Box>
        </div>
      )}
    </Box>
  );
};

export const RoomImages: React.FC<{ locationId: string; floorId: string; roomId: string }> = (props) => {
  const { locationId, floorId, roomId } = props;
  const theme = useTheme();
  const toast = useToast();
  const trackException = useExceptionTracker();
  const dndSensors = useCustomDndSensors();
  const [isUploading, setIsUploading] = useState(false);
  const [imagesToList, setImagesToList] = useState<Array<Image & { state: ImageState }>>([]);
  const [deleteRoomImage] = useDeleteRoomImageMutation();
  const [createRoomImage] = useCreateRoomImageMutation();
  const [updateRoomImages] = useUpdateRoomImagesMutation();
  const [triggerImagesQuery, imagesQuery] = useLazyGetRoomImagesQuery();
  const { data: imagesResponse, isLoading: imagesAreLoading, isFetching: imagesAreFetching } = imagesQuery;
  const { items: images } = imagesResponse?.result?.data || {};

  useEffect(() => {
    triggerImagesQuery({ locationId, floorId, roomId, limit: -1 });
  }, [triggerImagesQuery]);

  useEffect(() => {
    if (images?.length) {
      setImagesToList(images.filter(({ id }) => !imagesToList.some((image) => image.id === id)).map((image) => ({ ...image, state: "loaded" })));
    }
  }, [images]);

  const handleDelete = async (id: string) => {
    setImagesToList(imagesToList.map((image) => image.id === id ? ({ ...image, state: "deleting" }) : image));

    const deleteRoomImageResponse = await deleteRoomImage({ locationId, floorId, roomId, roomImageId: id });

    if (isSuccessAPIResponse(deleteRoomImageResponse)) {
      setImagesToList(imagesToList.filter((image) => image.id !== id));
    } else {
      toast.showToast({ severity: "error", message: t`Failed to delete image` });
      trackException(deleteRoomImageResponse.error, { endpointName: deleteRoomImage.name });
    }
  };

  const handleUpload = async (files: File[]) => {
    setIsUploading(true);
    
    const newImagesToList = files.map<Image & { state: ImageState }>((_, index) => ({ id: `image:${index}`, state: "loading", url: "" }));

    setImagesToList([...imagesToList, ...newImagesToList]);

    for (const [index, file] of files.entries()) {
      const createRoomImageResponse = await createRoomImage({ locationId, floorId, roomId, file });

      if (isSuccessAPIResponse(createRoomImageResponse)) {
        const { data: { result: { data } } } = createRoomImageResponse;

        setImagesToList((imagesToList) => imagesToList.map((image) => {
          if (image.id === `image:${index}`) {
            return { ...data, state: "loaded" };
          }

          return image;
        }));
      } else {
        toast.showToast({ severity: "error", message: t`Failed to upload image` });
        trackException(createRoomImageResponse.error, { endpointName: createRoomImage.name });
      }
    }

    setIsUploading(false);
  };

  const debouncedUpdateRoomImages = useDebouncedCallback(async (images: Image[]) => {
    const orderedImages = images.map(({ id }, sortIndex) => ({ id, sortIndex }));
    const response = await updateRoomImages({ locationId, floorId, roomId, images: orderedImages });

    if (isSuccessAPIResponse(response)) {
      toast.showToast({ severity: "success", message: t`Room's images sorting updated` });
    } else {
      toast.showToast({ severity: "error", message: t`Failed to updated room images` });
      trackException(response.error, { endpointName: updateRoomImages.name });
    }
  }, [updateRoomImages], 700);

  const handleDragEnd: DndContextProps["onDragEnd"] = (event) => {
    const indexes = resolveDragEndEventIndexes(event, imagesToList);
    
    if (indexes) {
      const [current, next] = indexes;
      const newImagesToList = moveArrayItem(imagesToList, current, next);
      
      setImagesToList(newImagesToList);
      debouncedUpdateRoomImages(newImagesToList);
    }
  };

  return (
    <Box paddingBottom={4}>
      <Typography mb={1}>{t`Upload room pictures`}</Typography>
      <UploadBox
        alignItems="center"
        bgcolor={theme.palette.grey[100]}
        border="1px dashed"
        borderColor={theme.palette.grey[400]}
        borderRadius={1}
        display="flex"
        flexDirection="column"
        mb={2}
        padding={2}
        position="relative"
        uploadOptions={{
          accept: ["image/png", "image/jpeg", "image/webp"],
          onChange: (files) => void handleUpload(files),
        }}
      > 
        {isUploading ? <ProgressOverlay bgcolor={theme.palette.grey[100]} borderRadius={1} /> : undefined}
        <Typography color={theme.palette.grey[700]} fontSize={14} mb={1} mt={1}>{t`Drop files here or click "Browse Files"`}</Typography>
        <FileUploadButton
          uploadOptions={{
            accept: ["image/png", "image/jpeg", "image/webp"],
            multiple: true,
            onChange: (files) => void handleUpload(files),
          }}
          variant="text"
        >
          {t`Browse files`}
        </FileUploadButton>
      </UploadBox>
      {(imagesAreLoading || imagesAreFetching) && !imagesToList?.length ? (
        <Box display="flex" flexWrap="wrap">
          <Box display="flex" flex="1 0 33.33%" height={102} justifyContent="flex-start" width="33.33%">
            <Skeleton height={90} sx={{ borderRadius: 1 }} variant="rectangular" width={160} />
          </Box>
          <Box display="flex" flex="1 0 33.33%" height={102} justifyContent="center" width="33.33%">
            <Skeleton height={90} sx={{ borderRadius: 1 }} variant="rectangular" width={160} />
          </Box>
          <Box display="flex" flex="1 0 33.33%" height={102} justifyContent="flex-end" width="33.33%">
            <Skeleton height={90} sx={{ borderRadius: 1 }} variant="rectangular" width={160} />
          </Box>
        </Box>
      ) : undefined}
      {!imagesToList?.length && !imagesAreLoading && !imagesAreFetching ? (
        <Typography color={theme.palette.grey[900]} fontSize={14} mt={4} textAlign="center">{t`There are no images for this room yet.`}</Typography>
      ) : undefined}
      <DndContext onDragEnd={handleDragEnd} sensors={dndSensors}>
        <SortableContext items={imagesToList} strategy={rectSortingStrategy}>
          <Box display="flex" flexWrap="wrap" style={{ "--col-count": 3 } as React.CSSProperties}>
            {imagesToList?.map((image, index) => <RoomImage image={image} index={index} key={image.id} onDelete={(id) => void handleDelete(id)} />)}
          </Box>
        </SortableContext>
      </DndContext>
    </Box>
  );
};