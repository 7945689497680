import {SetExecutiveAssistantDataRequest} from "./models";
import {UserManagementUser} from "../../../../Admin/Store/userManagement/models";
import {GetActualDocumentRequest, GetActualDocumentResponse} from "../profileDuck/models";
import {ActionPayload, BaseErrorResponse, BaseResponse} from "../../../../Store/Models/ReduxModels";
import {t} from "@lingui/macro";

export const SET_EXECUTIVE_ASSISTANT_DATA = 'SET_EXECUTIVE_ASSISTANT_DATA';

export const GET_SELECTED_USER_ACTUAL_DOCUMENT = 'GET_SELECTED_USER_ACTUAL_DOCUMENT';
export const GET_SELECTED_USER_ACTUAL_DOCUMENT_FAIL = 'GET_SELECTED_USER_ACTUAL_DOCUMENT_FAIL';
export const GET_SELECTED_USER_ACTUAL_DOCUMENT_SUCCESS = 'GET_SELECTED_USER_ACTUAL_DOCUMENT_SUCCESS';

export interface SetExecutiveAssistantData {
  type: typeof SET_EXECUTIVE_ASSISTANT_DATA;
  payload: SetExecutiveAssistantDataRequest;
}

// get selected user document
export interface GetSelectedUserActualDocument {
  type: typeof GET_SELECTED_USER_ACTUAL_DOCUMENT;
  payload: ActionPayload<GetActualDocumentRequest>;
}
export interface GetSelectedUserActualDocumentFail {
  type: typeof GET_SELECTED_USER_ACTUAL_DOCUMENT_FAIL;
  payload: BaseErrorResponse;
}
export interface GetSelectedUserActualDocumentSuccess {
  type: typeof GET_SELECTED_USER_ACTUAL_DOCUMENT_SUCCESS;
  payload: BaseResponse<GetActualDocumentResponse>;
}

export type Actions =
  | SetExecutiveAssistantData
  | GetSelectedUserActualDocument
  | GetSelectedUserActualDocumentFail
  | GetSelectedUserActualDocumentSuccess

export interface State {
  error: string;
  actualCovidDocumentSelectedUserLoaded: boolean;
  selectedUser: UserManagementUser | null;
}

const initialState: State = {
  error: '',
  actualCovidDocumentSelectedUserLoaded: false,
  selectedUser: null,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SET_EXECUTIVE_ASSISTANT_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case GET_SELECTED_USER_ACTUAL_DOCUMENT:
      return {
        ...state,
        actualCovidDocumentSelectedUserLoaded: false,
      };
    case GET_SELECTED_USER_ACTUAL_DOCUMENT_FAIL:
      return {
        ...state,
        actualCovidDocumentSelectedUserLoaded: false,
        error: t`There was an error adding user actual document. Please try again.`,
      };
    case GET_SELECTED_USER_ACTUAL_DOCUMENT_SUCCESS: {
      const updatedState: State = {
        ...state,
        actualCovidDocumentSelectedUserLoaded: true,
      };

      if (updatedState.selectedUser) {
        updatedState.selectedUser.userDocument = action.payload.data.result.data;
      }

      return updatedState;
    }

    default:
      return state;
  }
}

// Actions
export function setExecutiveAssistantData(data: SetExecutiveAssistantDataRequest): SetExecutiveAssistantData {
  return {
    type: SET_EXECUTIVE_ASSISTANT_DATA,
    payload: data,
  };
}

export function getSelectedUserActualDocument(data: GetActualDocumentRequest): GetSelectedUserActualDocument {
  return {
    type: GET_SELECTED_USER_ACTUAL_DOCUMENT,
    payload: {
      request: {
        method: 'GET',
        url: `/api/users/${data.userId}/actualDocument`,
      },
    },
  };
}
