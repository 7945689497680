import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../../../Store/Redux/store";
import Box from "../../../../../../Components/Box";
import Text from "../../../../../../Components/Text";
import {Trans} from "@lingui/macro";
import moment from "moment";
import {setJustInTimeReservationData} from "../../../../../Store/reservationJustInTime";
import { WeeklySlotInput, WeeklySlotInputProps } from "App/Pages/CreateNewBooking/Calendar/WeeklyBooking";

export default function WeeklyBooking() {
  const dispatch = useDispatch();
  const locationDisabledDays = useTypedSelector(state => state.locations.locationDisabledDays);
  const { weeklySlots } = useTypedSelector(state => state.adminReservationJustInTime);

  const isDisabled = (index: number): boolean => {
    const start = moment().utc().add(1, 'day').startOf('day');
    const end = moment().utc().add(1, 'day').add(2, 'week').endOf('day');
    const availableDays: string[] = [];

    for (let d = start; d <= end; ) {
      availableDays.push(moment(d).utc().startOf('day').format());
      d = moment(d).utc().startOf('day').add(1, 'day');
    }

    const uniqueAvailableDays: { [key: number]: number } = {};
    const uniqueDisabledDays: { [key: number]: number } = {};

    availableDays
      .map(d => moment(d).day())
      .forEach(el => uniqueAvailableDays[el] = 1  + (uniqueAvailableDays[el] || 0));
    locationDisabledDays
      // @ts-ignore
      .filter((d: moment.MomentInput) => availableDays.includes(moment(d).utc().startOf('day').format()))
      .map((d: moment.MomentInput) => moment(d).utc().startOf('day').day())
      .forEach((el: number) => uniqueDisabledDays[el] = 1  + (uniqueDisabledDays[el] || 0));

    return uniqueDisabledDays[index] >= uniqueAvailableDays[index];
  };

  const handleWeeklySlotInputChange: WeeklySlotInputProps["onChange"] = (weeklySlot) => {
    const newWeeklySlots = weeklySlots?.map((currentWeeklySlot) => {
      return currentWeeklySlot.day !== weeklySlot.day ? currentWeeklySlot : weeklySlot;
    });

    dispatch(setJustInTimeReservationData({ weeklySlots: newWeeklySlots }));
  };

  return (
    <Box paddingBottom={20} paddingTop={20}>
      <Box marginBottom={8}>
        <Text size="md" weight="semi-bold">
          <Trans>Select days</Trans>
        </Text>
        <Text color="gray" size="md">
          <Trans>Repeated weekly schedule. You can book only for two weeks.</Trans>
        </Text>
      </Box>
      <Box>
        {weeklySlots?.map((weeklySlot) => (
          <WeeklySlotInput
            disabled={isDisabled(weeklySlot.index)}
            key={weeklySlot.day}
            onChange={handleWeeklySlotInputChange}
            weeklySlot={weeklySlot}
          />
        ))}
      </Box>
    </Box>
  );
}
