import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherParkingSpot: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M12.571 0A3.429 3.429 0 0 1 16 3.429v9.142A3.429 3.429 0 0 1 12.571 16H3.43A3.429 3.429 0 0 1 0 12.571V3.43A3.429 3.429 0 0 1 3.429 0Zm0 2.286H3.43c-.632 0-1.143.511-1.143 1.143v9.142c0 .632.511 1.143 1.143 1.143h9.142c.632 0 1.143-.511 1.143-1.143V3.43c0-.632-.511-1.143-1.143-1.143zM9.143 3.429a3.429 3.429 0 0 1 0 6.857H6.857v2.285H4.571V3.43Zm0 2.285H6.857V8h2.286a1.143 1.143 0 0 0 0-2.286z"
        fill={props.fill}
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeOpacity="1"
        strokeWidth="1.14286"
      />
    </SvgIcon>
  );
};
