import { t } from "@lingui/macro";
import { Box, BoxProps } from "@mui/material";
import React from "react";

export const DefaultChip: React.FC<BoxProps> = (props) => {
  return (
    <Box bgcolor="#5FB3DF" borderRadius={2} color="#FFF" fontSize={14} paddingX={1} paddingY={0.25} {...props}>
      {t`Default`}
    </Box>
  );
};
