import Box from 'Components/Box';
import SendNotificationHeader from './SendNotificationHeader';
import GlobalNotificationsList from './GlobalNotificationsList';

export default function SendNotification() {
  return (
    <>
      <SendNotificationHeader />

      <Box marginTop={20}>
        <GlobalNotificationsList />
      </Box>
    </>
  );
}
