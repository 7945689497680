export const resolveCalendarViewScheduleSummary = (summary?: string, scheduleWidth?: number): string | undefined => {
  if (!summary) {
    return undefined;
  }

  const words = summary.split(" ");

  if (scheduleWidth && scheduleWidth < 180 && words.length > 0 && words.length < 5) {
    const [firstWord, secondWord] = words;

    if (firstWord && secondWord) {
      return `${firstWord.charAt(0).toUpperCase()}${secondWord.charAt(0).toUpperCase()}`; 
    } else if (firstWord) {
      return `${firstWord.charAt(0).toUpperCase()}`;
    }
  }

  return summary;
};
