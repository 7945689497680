import Box from 'Components/Box';
import InputLabel from '@material-ui/core/InputLabel';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { setFloorMapData } from 'Admin/Store/floorMapDuck';
import TextField from '@material-ui/core/TextField';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'Store/Redux/store';

export default function DefaultDeskSize() {
  const dispatch = useDispatch();
  const { deskSize } = useTypedSelector(state => state.adminFloorMap);

  const onDeskHeightChange = (e: any) => {
    dispatch(setFloorMapData({
      deskSize: {
        ...deskSize,
        height: parseInt(e.target.value),
      },
    }));
  };

  const onDeskWidthChange = (e: any) => {
    dispatch(setFloorMapData({
      deskSize: {
        ...deskSize,
        width: parseInt(e.target.value),
      },
    }));
  };

  return (
    <Box alignItems="center" display="flex">
      <Text size="md" weight="regular">
        <Trans>
          Desk size:
        </Trans>
      </Text>

      <Box alignItems="center" dataTestId="floor-map-details-desk-size-w" display="flex" marginEnd={10} marginStart={7}>
        <InputLabel className={styles.inputLabel} htmlFor="desk-width">
          <Text color="gray" size="md" weight="regular">
            <Trans comment="w = Width">
              w
            </Trans>
          </Text>
        </InputLabel>

        <TextField
          InputProps={{ inputProps: { min: 1 } }}
          className={`input input--default ${styles.numberInput}`}
          id="desk-width"
          label=""
          onChange={onDeskWidthChange}
          type="number"
          value={deskSize.width}
        />
      </Box>

      <Box alignItems="center" dataTestId="floor-map-details-desk-size-h" display="flex">
        <InputLabel className={styles.inputLabel} htmlFor="desk-height">
          <Text color="gray" size="md" weight="regular">
            <Trans comment="h = Height">
              h
            </Trans>
          </Text>
        </InputLabel>

        <TextField
          InputProps={{ inputProps: { min: 1 } }}
          className={`input input--default ${styles.numberInput}`}
          id="desk-height"
          label=""
          onChange={onDeskHeightChange}
          type="number"
          value={deskSize.height}
        />
      </Box>
    </Box>
  );
}
