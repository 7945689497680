import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RoomsState, RoomsStateCurrent } from "./types";

const initialState: RoomsState = {};

const resetCurrent: CaseReducer<RoomsState, PayloadAction<Partial<RoomsStateCurrent> | undefined>> = (state, action) => ({
  ...state,
  current: action.payload,
});

const setCurrent: CaseReducer<RoomsState, PayloadAction<Partial<RoomsStateCurrent> | undefined>> = (state, action) => ({
  ...state,
  current: { ...state.current, ...action.payload },
});

export const roomsSlice = createSlice({
  initialState,
  name: "rooms",
  reducers: {
    resetCurrent,
    setCurrent,
  },
});
