import { setupWorker } from 'msw';
import { handlers } from './handlers';

// This configures a Service Worker with the given request handlers.
export const worker = setupWorker(...handlers);

// necessary for history-based routing
const options = { serviceWorker: { url: '/mockServiceWorker.js' } };
weakStart(worker, options);

/////////////////////////////////

const FLAG = 'msw-suppressed';
type StartOptions = Parameters<typeof worker.start>[0]

/**
 * Configures the worker and add it to object window
 * To start or stop the mocks, run on browswer console:
 * 
 * window.mocks.start()
 * window.mocks.stop()
 */
export function weakStart(
  worker: ReturnType<typeof setupWorker>,
  defaultOptions: StartOptions = {},
) {
  const start = (options?: StartOptions): ReturnType<typeof worker.start> => {
    // Check the current mocking state in the localStorage
    const shouldSuppress = localStorage.getItem(FLAG);

    if (shouldSuppress) {
      return Promise.resolve(undefined);
    }

    return worker.start(options ?? defaultOptions);
  };

  // Make controller methods globally available to call during runtime.
  // Chain the localStorage update to preserve the state.
  const mocks = {
    start: (options?: StartOptions) =>
      start(options ?? defaultOptions).then(() =>
        localStorage.removeItem(FLAG),
      ),
    stop: () => {
      worker.stop();
      localStorage.setItem(FLAG, 'true');
    },
  };
  
  (window as any).mocks = mocks;
}