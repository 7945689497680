import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import React from "react";

export const SidebarSectionTitle: React.FC<BoxProps> = (props) => {
  const { children, ...rest } = props;
  const { palette } = useTheme();

  return (
    <Box alignItems="center" display="flex" justifyContent="space-between" {...rest}>
      <Typography fontSize={18} fontWeight="600">{children}</Typography>
      <Box bgcolor={palette.primary.main} height="1px" width="20px" />
    </Box>
  );
};
