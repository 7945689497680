import AppointmentConfirmed from './AppointmentConfirmed';
import ApprovalRequired from './ApprovalRequired';
import Error404 from 'App/Pages/Error404';
import { Route, Switch, useLocation } from 'react-router-dom';

export default function EmailLayoutsRoutes() {
  const location = useLocation();

  return (
    <Switch key={location.pathname} location={location}>
      <Route exact path='/emails/appointment-confirmed' render={() => <AppointmentConfirmed />} />
      <Route exact path='/emails/approval-required' render={() => <ApprovalRequired />} />
      <Route path='*' render={() => <Error404 />} />
    </Switch>
  );
}