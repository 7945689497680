import Box from 'Components/Box';
import CheckboxCustom from '../CheckBoxCustom';
import clsx from 'clsx';
import InputLabel from '@material-ui/core/InputLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import SelectMaterialUI, { SelectProps } from '@material-ui/core/Select';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import OverflowToolTip from "../OverflowTooltip";
import { Color } from 'Components/Styles/models';
import { components } from 'react-select';
import { useTypedSelector } from '../../Store/Redux/store';
import translateDynamicVariable from "../../Functions/translateDynamicVariable";

interface Props {
  classes?: {
    input?: string;
    materialSelect?: string;
    text?: string;
    wrapper?: string;
    selectWrapper?: string;
    materialLabel?: string;
    icon?: string;
  },
  fullWidth?: boolean;
  overflowText?: boolean;
  iconColor?: string;
  onClick?: Function;
  id: string;
  items: Array<{
    name: string;
    value: string | number;
  }>;
  label?: string;
  labelColor?: Color;
  showIconArrow?: boolean;
  value: string | number;
}

type PropsA = Props & SelectProps;

export default function Select({
  classes,
  fullWidth,
  overflowText,
  iconColor,
  id,
  items,
  label,
  labelColor = 'gray',
  onClick = () => null,
  name,
  showIconArrow = true,
  value,
  ...props
}: React.PropsWithChildren<PropsA>) {
  const wrapperClasses = clsx(
    styles.wrapper,
    classes?.wrapper,
    fullWidth && styles.fullWidth,
  );
  const selectWrapperClasses = clsx(
    styles.selectWrapper,
    classes?.selectWrapper,
  );
  const materialSelectClasses = clsx(
    classes?.materialSelect,
    styles.selectContainer,
    props.disabled && styles.selectContainerDisabled,
  );
  const labelClasses = clsx(
    classes?.materialLabel,
  );
  const inputClasses = clsx(
    styles.input,
    classes?.input,
    !value && styles.empty,
  );
  const iconClasses = clsx(
    styles.icon,
    classes?.icon,
  );
  const textClasses = clsx(classes?.text);

  // fix bug with non rendered translation "t" inside select, because react didn't detect it
  const defaultSelectText = items.find(item => item.value === value)?.name || '';
  const selectText = translateDynamicVariable(defaultSelectText);

  return (
    <div className={wrapperClasses} onClick={onClick}>
      {label && (
        <Box alignItems="center" display="flex" marginEnd={10}>
          <InputLabel className={labelClasses} htmlFor={id + 'label'}>
            <Text color={labelColor} size="md" truncate>
              {label}
            </Text>
          </InputLabel>
        </Box>)
      }
      <Box alignItems="center" className={selectWrapperClasses} display="flex">
        <SelectMaterialUI
          {...props}
          IconComponent={() => null}
          MenuProps={{
            classes: {
              paper: styles.menuPaper,
            },
          }}
          className={materialSelectClasses}
          classes={{
            disabled: styles.disabled,
            root: styles.select,
          }}
          id={id}
          inputProps={{
            className: inputClasses,
            "aria-label": name,
          }}
          labelId={id + 'label'}
          renderValue={() => overflowText ?
            <div className={styles.renderValueClass}><OverflowToolTip text={selectText} /></div>
            : <span className={styles.defaultValueClass}>{selectText}</span>
          }
          value={value}
        >
          {items.map((item) => {
            // fix bug with non rendered translation "t" inside select, because react didn't detect it
            const name = translateDynamicVariable(item.name);

            return (
              <MenuItem
                className={styles.menuItemMaterial}
                classes={{
                  root: styles.menuItem,
                  selected: styles.menuItemSelect,
                }}
                key={item.name}
                value={item.value}
              >
                <Text color={value === item.value ? 'blue' : 'black'} size="md">
                <span className={textClasses}>
                  {name}
                </span>
                </Text>
              </MenuItem>
            );
          })}
        </SelectMaterialUI>

        {showIconArrow ? (
          <div className={iconClasses}>
            <KeyboardArrowDownIcon style={{ color: iconColor }} />
          </div>
        ) : null}
      </Box>
    </div>
  );
}

export function DropdownIndicator(props: any) {
  const { config } = useTypedSelector(state => state);

  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <svg height="10px" version="1.1" viewBox="0 0 14 10" width="14px">
          <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
            <g id="A-Location-notifications-createnew" transform="translate(-478.000000, -316.000000)">
              <rect fill="transparent" height="900" width="1440" x="0" y="0"></rect>
              <g id="Fields/dd-r" transform="translate(230.000000, 269.000000)">
                <rect fill="transparent" height="34" id="Rectangle" rx="6" width="270" x="0" y="35"></rect>
                <g fill={config.theme.primary} id="Group" transform="translate(248.000000, 47.000000)">
                  <g id="icons/arrow-down-w" transform="translate(7.000000, 5.000000) rotate(-90.000000) translate(-7.000000, -5.000000) translate(2.000000, -2.000000)">
                    <path d="M7.15685425,0.692893219 C7.54737854,0.302368927 8.18054352,0.302368927 8.57106781,0.692893219 C8.9615921,1.08341751 8.9615921,1.71658249 8.57106781,2.10710678 L3.62132034,7.05685425 L8.57106781,12.0066017 C8.9615921,12.397126 8.9615921,13.030291 8.57106781,13.4208153 C8.18054352,13.8113396 7.54737854,13.8113396 7.15685425,13.4208153 L1.5,7.76396103 C1.10947571,7.37343674 1.10947571,6.74027176 1.5,6.34974747 L7.15685425,0.692893219 Z" id="icons/arrow-down" transform="translate(5.035534, 7.056854) rotate(-360.000000) translate(-5.035534, -7.056854) "></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </components.DropdownIndicator>
    )
  );
}


export function MultiSelectOption(props: any) {
  return (
    <div>
      <components.Option {...props}>
        <CheckboxCustom
          checked={props.isSelected}
          name={props.label}
          onChange={() => null}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
}
