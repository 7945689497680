import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../../../Store/Redux/store";
import { Trans } from "@lingui/macro";
import styles from "../../../../../Locations/LocationForm/styles.module.scss";

import Box from "../../../../../../../Components/Box";
import ManageLocationsModal from "../../../../../../Components/ManageLocationsForm";
import { Location } from "../../../../../../../App/Store/Locations/locationsDuck/models";
import { GroupCreateEditFormActionStateEnum } from "../../../../../../Store/groups/forms/createEditForm/models";
import { setGroupsCreateEditFormData } from "../../../../../../Store/groups/forms/createEditForm";

export default function Locations() {
  const dispatch = useDispatch();
  const { actionState, group } = useTypedSelector(state => state.groupCreateEditForm);
  const groupLocationIds: string[] = actionState === GroupCreateEditFormActionStateEnum.edit && group && group.locationIds ? group.locationIds : [];
  const [groupLocationIdsValue, setGroupLocationIdsValue] = useState<string[]>(groupLocationIds);

  // set actual redux data after load group data
  useEffect(() => {
    dispatch(setGroupsCreateEditFormData({
      formDataLocationIds: groupLocationIdsValue,
    }));
  }, [group]);

  const onLocationIdsChange = (selectedLocations: Location[]) => {
    const updatedLocationsIds = selectedLocations.map(l => l.id);

    setGroupLocationIdsValue(updatedLocationsIds);
    dispatch(setGroupsCreateEditFormData({
      formDataLocationIds: updatedLocationsIds,
    }));
  };

  return (
    <Box>
      <label htmlFor="location-name"><Trans>Locations</Trans></label>

      <ManageLocationsModal
        contentStyle={{width: '370px'}}
        locationIds={groupLocationIdsValue}
        onSave={onLocationIdsChange}
        openButtonClassName={`${styles.openButtonClassName}`}
      />
    </Box>
  );
}
