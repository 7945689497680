import Box from 'Components/Box';
import Button from 'Components/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Popup from 'reactjs-popup';
import useSnackbar from 'Components/Snackbar/useSnackbar';
import { deleteGlobalNotification, setGlobalNotificationsReduxData } from 'Admin/Store/globalNotifications';
import { GlobalNotificationModel } from 'Admin/Store/globalNotifications/models';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTypedSelector } from 'Store/Redux/store';

interface GlobalNotificationDeleteModalProps {
  notification: GlobalNotificationModel | null;
  closeModal: () => any;
  open: boolean;
}

export default function GlobalNotificationDeleteModal({
  closeModal,
  notification,
  open,
}: GlobalNotificationDeleteModalProps) {
  const dispatch = useDispatch();
  const [openSnackbar] = useSnackbar();
  const { config, globalNotifications } = useTypedSelector(state => state);

  const { successDeleteMessage } = globalNotifications;

  useEffect(() => {
    if (successDeleteMessage) {
      openSnackbar({
        onClose: () => { dispatch(setGlobalNotificationsReduxData({ successDeleteMessage: '' })); },
        text: successDeleteMessage,
        type: 'success',
      });
    }
  }, [successDeleteMessage]);

  if (!notification) {
    return null;
  }

  const handleDeleteGlobalNotification = () => {
    dispatch(deleteGlobalNotification({ notificationId: notification.id }));
    closeModal();
  };

  return (
    <Popup
      aria-label={t`Global notification delete form`}
      className='modal'
      closeOnDocumentClick
      onClose={closeModal}
      open={open}
    >
      <Box className="modal-inner">
        <Box className="modal-header">
          <h2>
            <Trans>Delete Notification</Trans>
          </h2>

          <IconButton
            onClick={() => closeModal()}
            size="small"
            style={{
              backgroundColor: config.theme.primaryLight,
              borderRadius: '50%',
              height: 30,
              width: 30,
            }}
          >
            <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
          </IconButton>
        </Box>

        <Box className={'modal-inner-content'}>
          <Box marginBottom={20}>
            <Trans>
              Are you sure to delete the global notification?
            </Trans>
          </Box>

          <Box display="flex" justifyContent="end">
            <Button
              aria-label={t`Cancel delete global notification`}
              name={t`Cancel delete global notification`}
              onClick={() => closeModal()}
              size="sm"
              type="clear"
            >
              <Trans>
                Cancel
              </Trans>
            </Button>

            <Button
              aria-label={t`confirm delete global notification`}
              name={t`confirm delete global notification`}
              onClick={handleDeleteGlobalNotification}
              size="sm"
            >
              <Trans>
                Delete
              </Trans>
            </Button>
          </Box>
        </Box>
      </Box>
    </Popup>
  );
}
