import Box from "../../../../../../Components/Box";
import Text from "../../../../../../Components/Text";
import { Trans } from "@lingui/macro";
import styles from "./styles.module.scss";
import Room from "Components/Icons/Room";

export default function InstructionDetails() {
  return (
    <Box paddingBottom={8} paddingTop={8}>
      <Text size="xlg" weight="semi-bold">
        <Trans>Tutorial</Trans>
      </Text>

      <Box marginTop={16}>
        <Text size="md" weight="semi-bold">
          <Trans>Define section (optional) and desks.</Trans>
        </Text>

        <Box marginTop={14}>
          <Text color="gray" size="md" weight="regular">
            <Box className={styles.instructionBlock}>
              <div className={styles.instructionBlockImage}>
                <svg height="16px" viewBox="0 0 16 16" width="16px">
                  <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
                    <g id="A-Location-Floor-addsection1" transform="translate(-1030.000000, -193.000000)">
                      <rect fill="#FFFFFF" height="943" width="1440" x="0" y="0"></rect>
                      <g
                        fill="#5E5E5E"
                        fillRule="nonzero"
                        id="icons/section"
                        transform="translate(1030.000000, 193.000000)"
                      >
                        <g id="Combined-Shape" transform="translate(0.000000, 0.000000)">
                          <path
                            d="M12,1 C13.6568542,1 15,2.34314575 15,4 L15,4 L15,12 C15,13.6568542 13.6568542,15 12,15 L12,15 L4,15 C2.34314575,15 1,13.6568542 1,12 L1,12 L1,4 C1,2.34314575 2.34314575,1 4,1 L4,1 Z M12,3 L4,3 C3.44771525,3 3,3.44771525 3,4 L3,4 L3,12 C3,12.5522847 3.44771525,13 4,13 L4,13 L12,13 C12.5522847,13 13,12.5522847 13,12 L13,12 L13,4 C13,3.44771525 12.5522847,3 12,3 L12,3 Z M11,8 C11.5522847,8 12,8.44771525 12,9 L12,11 C12,11.5522847 11.5522847,12 11,12 L9,12 C8.44771525,12 8,11.5522847 8,11 L8,9 C8,8.44771525 8.44771525,8 9,8 L11,8 Z"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <Trans>
                For adding a section, click on Add Section, click on the map to define a name, and draw it on the floor plan.
              </Trans>
            </Box>
            <Box className={styles.instructionBlock}>
              <div className={styles.instructionBlockImage}>
                <svg height="16px" viewBox="0 0 16 16" width="16px">
                  <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
                    <g id="A-Location-Floor-addsection1" transform="translate(-1030.000000, -247.000000)">
                      <rect fill="#FFFFFF" height="943" width="1440" x="0" y="0"></rect>
                      <g id="icons/sit" transform="translate(1030.000000, 247.000000)">
                        <g transform="translate(0.000000, 0.000000)"></g>
                        <rect fill="#5E5E5E" height="5" id="Rectangle" rx="1" width="2" x="7" y="10"></rect>
                        <path d="M10,0 C11.6568542,0 13,1.34314575 13,3 L13,3 L13.0004345,7.05009729 C14.1413382,7.28190033 15,8.29067455 15,9.5 C15,10.8807119 13.8807119,12 12.5,12 L12.5,12 L3.5,12 C2.11928813,12 1,10.8807119 1,9.5 C1,8.29031824 1.85916789,7.2813059 3.00057405,7.0498926 L3,3 C3,1.34314575 4.34314575,0 6,0 L6,0 Z M12.5,9 L3.5,9 C3.22385763,9 3,9.22385763 3,9.5 C3,9.77614237 3.22385763,10 3.5,10 L3.5,10 L12.5,10 C12.7761424,10 13,9.77614237 13,9.5 C13,9.22385763 12.7761424,9 12.5,9 L12.5,9 Z M10,2 L6,2 C5.44771525,2 5,2.44771525 5,3 L5,3 L5,7 L11,7 L11,3 C11,2.48716416 10.6139598,2.06449284 10.1166211,2.00672773 L10.1166211,2.00672773 L10,2 Z M10,13 C11.5976809,13 12.9036609,14.24892 12.9949073,15.8237272 L13,16 L11,16 C11,15.4871642 10.6139598,15.0644928 10.1166211,15.0067277 L10,15 L6,15 C5.48716416,15 5.06449284,15.3860402 5.00672773,15.8833789 L5,16 L3,16 C3,14.4023191 4.24891996,13.0963391 5.82372721,13.0050927 L6,13 L10,13 Z" fill="#5E5E5E" fillRule="nonzero" id="Combined-Shape"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <Trans>
                For adding a desk, click on Add Desk and draw the desk area on the floor plan. To add a desk to a section draw the section first, then click on the section and select the + button.
              </Trans>
            </Box>
            <Box className={styles.instructionBlock}>
              <div className={styles.instructionBlockImage}>
                <Room />
              </div>
              <Trans>
                For adding a room, click on Add Room and draw the room area on the floor plan.
              </Trans>
            </Box>
          </Text>
        </Box>
      </Box>
    </Box >
  );
}
