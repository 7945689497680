import {useDispatch} from "react-redux";
import styles from "../styles.module.scss";
import {Trans, t} from "@lingui/macro";
import Box from "../../../../Components/Box";
import Select from "../../../../Components/Select";
import { AdminLocation, AllowedBookingDayType } from "../../../../App/Store/Locations/locationsDuck/models";
import {editLocation} from "../../../../App/Store/Locations/locationsDuck";

interface Props {
  selectedLocation: AdminLocation;
}

export default function LocationStartDayList(props: Props) {
  const dispatch = useDispatch();
  const { selectedLocation } = props;

  const startDayListData: Array<{ name: string; value: AllowedBookingDayType }> = [
    {
      name: t`Same day`,
      value: 0,
    },
    {
      name: t`1 day in advance`,
      value: 1,
    },
    {
      name: t`2 days in advance`,
      value: 2,
    },
  ];
  const startDayValue = selectedLocation ? startDayListData[selectedLocation.allowedBookingDayType] : startDayListData[1];

  const setListData = (event: any) => {
    const allowedBookingDayType = event.target.value;
    if (selectedLocation) {
      const locationData = {
        active: selectedLocation.active,
        locationName: selectedLocation.locationName,
        locationAddress: selectedLocation.locationAddress,
        country: selectedLocation.country,
        city: selectedLocation.city,
        region: selectedLocation.region,
        uploadDocNotification: selectedLocation.uploadDocNotification,
        timezone: selectedLocation.timezone,
        allowedBookingDayType,
      };

      dispatch(editLocation({ location: locationData, locationId: selectedLocation.id }));
    }
  };

  return (
    <Box className={styles.dayListContainer}>
      <Box alignItems='center' className={styles.dayListContainerLabel} display='flex' gap={10}>
        <div>
          <Trans>
            Reservation settings:
          </Trans>
        </div>
      </Box>

      <Select
        classes={{
          input: styles.input,
          wrapper: styles.select,
        }}
        data-testid="location-settings-startReservationDay"
        iconColor="#000000"
        id="status"
        items={startDayListData}
        labelColor="gray"
        onChange={(event: any) => setListData(event)}
        value={startDayValue.value}
      />
    </Box>
  );
}
