import moment from "moment";
import styles from "../styles.module.scss";
import TableRow from "@material-ui/core/TableRow";
import {Trans} from "@lingui/macro";
import TableCell from "@material-ui/core/TableCell";
import Box from "../../../../../Components/Box";
import OverflowToolTip from "../../../../../Components/OverflowTooltip";
import {UserDocument, UserDocumentStatus} from "../../../../../Admin/Store/userManagement/models";

interface ProfileDocumentBodyItemProps {
  document: UserDocument;
}

function getDocumentStatus(status: UserDocumentStatus) {
  switch (status) {
    case "approved":
      return (
        <>
          <svg height="16px" viewBox="0 0 16 16" width="16px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g fill="#5BC535" fillRule="nonzero" id="Profile" transform="translate(-410.000000, -167.000000)">
                <g id="icons/plus" transform="translate(410.000000, 167.000000)">
                  <path d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z M11.9308681,5.25619392 C12.3100614,5.59694281 12.3679981,6.16188823 12.0843255,6.56988627 L12.0062787,6.66839548 L8.1788919,10.9276008 C7.82472657,11.3217242 7.23148909,11.3669028 6.82336105,11.0504194 L6.7326567,10.970959 L3.97680215,8.25120852 C3.58371143,7.86326763 3.57953667,7.23011641 3.96747756,6.83702569 C4.32557685,6.47417273 4.89261274,6.44270377 5.28690659,6.74513547 L5.38166039,6.82770111 L7.391,8.811 L10.5186665,5.33160452 C10.8878111,4.92081177 11.5200753,4.88704929 11.9308681,5.25619392 Z" id="Combined-Shape"></path>
                </g>
              </g>
            </g>
          </svg>
          <Trans>Approved</Trans>
        </>
      );
    case "needsApproval":
      return (
        <>
          <svg height="16px" viewBox="0 0 16 16" width="16px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g fill="#FFBD0E" fillRule="nonzero" id="Profile" transform="translate(-410.000000, -200.000000)">
                <g id="icons/approval" transform="translate(410.000000, 200.000000)">
                  <path d="M8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 C12.418278,16 16,12.418278 16,8 C16,3.581722 12.418278,0 8,0 Z M8,2 C11.3137085,2 14,4.6862915 14,8 C14,11.3137085 11.3137085,14 8,14 C4.6862915,14 2,11.3137085 2,8 C2,4.6862915 4.6862915,2 8,2 Z M8,3 C8.55228475,3 9,3.44771525 9,4 L9,8 C9,8.55228475 8.55228475,9 8,9 C7.44771525,9 7,8.55228475 7,8 L7,4 C7,3.44771525 7.44771525,3 8,3 Z M8,7 L12,7 C12.5522847,7 13,7.44771525 13,8 C13,8.55228475 12.5522847,9 12,9 L8,9 C7.44771525,9 7,8.55228475 7,8 C7,7.44771525 7.44771525,7 8,7 Z" id="Combined-Shape"></path>
                </g>
              </g>
            </g>
          </svg>
          <Trans>Needs approval</Trans>
        </>
      );
    case "expired":
      return (
        <>
          <svg height="16px" viewBox="0 0 16 16" width="16px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g fill="#FF4C0E" fillRule="nonzero" id="Profile" transform="translate(-410.000000, -236.000000)">
                <g id="Group" transform="translate(410.000000, 232.000000)">
                  <g id="icons/expired" transform="translate(0.000000, 4.000000)">
                    <path d="M8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 C12.418278,16 16,12.418278 16,8 C16,3.581722 12.418278,0 8,0 Z M8,2 C11.3137085,2 14,4.6862915 14,8 C14,11.3137085 11.3137085,14 8,14 C4.6862915,14 2,11.3137085 2,8 C2,4.6862915 4.6862915,2 8,2 Z M8,3 C8.55228475,3 9,3.44771525 9,4 L9,8 C9,8.55228475 8.55228475,9 8,9 C7.44771525,9 7,8.55228475 7,8 L7,4 C7,3.44771525 7.44771525,3 8,3 Z M8,7 L12,7 C12.5522847,7 13,7.44771525 13,8 C13,8.55228475 12.5522847,9 12,9 L8,9 C7.44771525,9 7,8.55228475 7,8 C7,7.44771525 7.44771525,7 8,7 Z" id="Combined-Shape"></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <Trans>Expired</Trans>
        </>
      );
    case "denied":
      return (
        <>
          <svg height="16px" viewBox="0 0 16 16" width="16px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g fill="#ABABAB" fillRule="nonzero" id="Profile" transform="translate(-410.000000, -270.000000)">
                <g id="icons/plus" transform="translate(410.000000, 270.000000)">
                  <path d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z M6.58578644,5.17157288 L8,6.586 L9.41421356,5.17157288 C9.80473785,4.78104858 10.4379028,4.78104858 10.8284271,5.17157288 C11.2189514,5.56209717 11.2189514,6.19526215 10.8284271,6.58578644 L9.414,8 L10.8284271,9.41421356 C11.2189514,9.80473785 11.2189514,10.4379028 10.8284271,10.8284271 C10.4379028,11.2189514 9.80473785,11.2189514 9.41421356,10.8284271 L8,9.414 L6.58578644,10.8284271 C6.19526215,11.2189514 5.56209717,11.2189514 5.17157288,10.8284271 C4.78104858,10.4379028 4.78104858,9.80473785 5.17157288,9.41421356 L6.586,8 L5.17157288,6.58578644 C4.78104858,6.19526215 4.78104858,5.56209717 5.17157288,5.17157288 C5.56209717,4.78104858 6.19526215,4.78104858 6.58578644,5.17157288 Z" id="Combined-Shape"></path>
                </g>
              </g>
            </g>
          </svg>
          <Trans>Denied</Trans>
        </>
      );
    default:
      return '';
  }
}

function getDateRangeFormat(startDate: Date | string, endDate: Date | string) {
  return `${moment(startDate).format('D MMM YYYY')}-${moment(endDate).format('D MMM YYYY')}`;
}

function getShortDateFormat(date: Date | string | null) {
  if (date) {
    return moment(date).format('DD/MM/YYYY');
  }

  return '-';
}

export default function ProfileDocumentBodyItem({ document }: ProfileDocumentBodyItemProps) {
  const status = getDocumentStatus(document.status);
  const vaxTestDate = getDateRangeFormat(document.vaccinationDate, document.vaccinationExpirationDate);
  const verifiedDate = getShortDateFormat(document.verificationDate);
  const uploadedDate = getShortDateFormat(document.createdAt);

  return (
    <TableRow
      hover
      key={document.id}
      role="row"
      tabIndex={-1}
    >
      <TableCell classes={{ root: styles.tableCell }} data-testid="user-profile-documents-table-status">
        <Box alignItems="center" className={styles.documentStatus} display="flex">
          {status}
        </Box>
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }} data-testid="user-profile-documents-table-vaxTestDate">
        <OverflowToolTip text={vaxTestDate} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }} data-testid="user-profile-documents-table-verifiedDate">
        <OverflowToolTip text={verifiedDate} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }} data-testid="user-profile-documents-table-uploadedDate">
        <OverflowToolTip text={uploadedDate} />
      </TableCell>
    </TableRow>
  );
}
