export default function CustomDot(props: any) {
  const { cx, cy, dataKey, fill, payload } = props;

  const showDot = () => {
    switch (dataKey) {
      case 'booked': {
        if (payload.booked > 0) {
          return true;
        }

        break;
      }

      case 'cancelled': {
        if (payload.cancelled > 0) {
          return true;
        }

        break;
      }

      default:
        return false;
    }
  };

  return (
    <circle cx={cx} cy={cy} fill={showDot() ? fill : 'transparent'} r={5} />
  );
}