import { ActionPayload, BaseErrorResponse, BaseResponse } from "../../../../Store/Models/ReduxModels";
import {
  CancelMeetingReservationRequest,
  CancelMeetingReservationResponse,
  CreateMeetingReservationRequest,
  CreateMeetingReservationResponse,
  GetMeetingReservationsRequest,
  GetMeetingReservationsResponse,
  IMeetingReservation,  
  IMeetingReservationLocation,
} from "./models";
import { t } from "@lingui/macro";
import moment, { Moment } from "moment";
import { format, formatInTimeZone } from "date-fns-tz";
import { isSameDay } from "date-fns";
import { getBookingDate } from "App/Store/Bookings/bookingDuck";
import { BookingModel } from "App/Store/Bookings/bookingDuck/models";

export const GET_MEETING_RESERVATIONS = 'GET_MEETING_RESERVATIONS';
export const GET_MEETING_RESERVATIONS_FAIL = 'GET_MEETING_RESERVATIONS_FAIL';
export const GET_MEETING_RESERVATIONS_SUCCESS = 'GET_MEETING_RESERVATIONS_SUCCESS';

export const GET_PREVIOUS_MEETING_RESERVATIONS = 'GET_PREVIOUS_MEETING_RESERVATIONS';
export const GET_PREVIOUS_MEETING_RESERVATIONS_FAIL = 'GET_PREVIOUS_MEETING_RESERVATIONS_FAIL';
export const GET_PREVIOUS_MEETING_RESERVATIONS_SUCCESS = 'GET_PREVIOUS_MEETING_RESERVATIONS_SUCCESS';

export const CREATE_MEETING_RESERVATION = 'CREATE_MEETING_RESERVATION';
export const CREATE_MEETING_RESERVATION_FAIL = 'CREATE_MEETING_RESERVATION_FAIL';
export const CREATE_MEETING_RESERVATION_SUCCESS = 'CREATE_MEETING_RESERVATION_SUCCESS';

export const CANCEL_MEETING_RESERVATION = 'CANCEL_MEETING_RESERVATION';
export const CANCEL_MEETING_RESERVATION_FAIL = 'CANCEL_MEETING_RESERVATION_FAIL';
export const CANCEL_MEETING_RESERVATION_SUCCESS = 'CANCEL_MEETING_RESERVATION_SUCCESS';

export const SET_MEETING_RESERVATION_DATA = 'SET_MEETING_RESERVATION_DATA';
export const SET_MEETING_RESERVATION = 'SET_MEETING_RESERVATION';

// get meeting reservations list
export interface GetMeetingReservations {
  type: typeof GET_MEETING_RESERVATIONS;
  payload: ActionPayload<GetMeetingReservationsRequest>;
  clearData?: boolean;
}
export interface GetMeetingReservationsFail {
  type: typeof GET_MEETING_RESERVATIONS_FAIL;
  payload: BaseErrorResponse;
}
export interface GetMeetingReservationsSuccess {
  type: typeof GET_MEETING_RESERVATIONS_SUCCESS;
  payload: BaseResponse<GetMeetingReservationsResponse>;
}

// get previous meeting reservations list
export interface GetPreviousMeetingReservations {
  type: typeof GET_PREVIOUS_MEETING_RESERVATIONS;
  payload: ActionPayload<GetMeetingReservationsRequest>;
  clearData?: boolean;
}
export interface GetPreviousMeetingReservationsFail {
  type: typeof GET_PREVIOUS_MEETING_RESERVATIONS_FAIL;
  payload: BaseErrorResponse;
}
export interface GetPreviousMeetingReservationsSuccess {
  type: typeof GET_PREVIOUS_MEETING_RESERVATIONS_SUCCESS;
  payload: BaseResponse<GetMeetingReservationsResponse>;  
}

// create meeting reservation
export interface CreateMeetingReservation {
  type: typeof CREATE_MEETING_RESERVATION;
  payload: ActionPayload<CreateMeetingReservationRequest>;
}
export interface CreateMeetingReservationFail {
  type: typeof CREATE_MEETING_RESERVATION_FAIL;
  payload: BaseErrorResponse;
}
export interface CreateMeetingReservationSuccess {
  type: typeof CREATE_MEETING_RESERVATION_SUCCESS;
  payload: BaseResponse<CreateMeetingReservationResponse>;
}

// cancel meeting reservation
export interface CancelMeetingReservation {
  type: typeof CANCEL_MEETING_RESERVATION;
  payload: ActionPayload<CancelMeetingReservationRequest>;
}
export interface CancelMeetingReservationFail {
  type: typeof CANCEL_MEETING_RESERVATION_FAIL;
  payload: BaseErrorResponse;
}
export interface CancelMeetingReservationSuccess {
  type: typeof CANCEL_MEETING_RESERVATION_SUCCESS;
  payload: BaseResponse<CancelMeetingReservationResponse>;
}

// set meeting reservation data
export interface SetMeetingReservationData {
  type: typeof SET_MEETING_RESERVATION_DATA;
  payload: Partial<State>;
}

export type Actions =
  | GetMeetingReservations
  | GetMeetingReservationsFail
  | GetMeetingReservationsSuccess
  | GetPreviousMeetingReservations
  | GetPreviousMeetingReservationsFail
  | GetPreviousMeetingReservationsSuccess
  | CreateMeetingReservation
  | CreateMeetingReservationFail
  | CreateMeetingReservationSuccess
  | CancelMeetingReservation
  | CancelMeetingReservationFail
  | CancelMeetingReservationSuccess
  | SetMeetingReservationData  

export interface State {
  error: string;
  successMessage: string,
  loading: boolean;  
  reservationCreationLoading: boolean;
  totalCount: number;
  totalUpcomingCount: number;
  previousTotalCount: number;
  reservationSelected: string;
  detailsOpened: boolean;  
  reservationCanceled: boolean;
  reservationCreated: boolean;
  previousReservations: {
    [key: string]: IMeetingReservation;
  };
  meetingReservations: {
    [key: string]: IMeetingReservation
  };  
  pages: number;
}

const initialState: State = {
  error: '',
  successMessage: '',
  loading: false,  
  reservationCreationLoading: false,
  totalCount: 0,
  totalUpcomingCount: 0,
  previousTotalCount: 0,  
  reservationSelected: '',
  detailsOpened: false,  
  reservationCanceled: false,
  reservationCreated: false,
  previousReservations: {},
  meetingReservations: {},
  pages: 0,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case GET_MEETING_RESERVATIONS:
      return {
        ...state,
        loading: true,
        meetingReservations: action.clearData ? {} : state.meetingReservations,
      };
    case GET_MEETING_RESERVATIONS_SUCCESS: {
      const { items } = action.payload.data.result.data;      

      const newReservations = { ...state.meetingReservations };

      items.forEach(reservation => {
        newReservations[reservation.id] = reservation;
      });

      return {
        ...state,
        loading: false,
        error: '',
        meetingReservations: newReservations,
        totalCount: action.payload.data.result.data.meta.total,
      };
    }      
    case GET_MEETING_RESERVATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: t`There was an error getting meeting reservations. Please try again.`,
      };
    case GET_PREVIOUS_MEETING_RESERVATIONS:
      return {
        ...state,        
        previousReservations: action.clearData ? { } : state.previousReservations,
        loading: true,
      };
    case GET_PREVIOUS_MEETING_RESERVATIONS_SUCCESS: {
      const { items } = action.payload.data.result.data;      

      const newReservations = { ...state.previousReservations };

      items.forEach(reservation => {
        newReservations[reservation.id] = reservation;
      });

      return {
        ...state,
        loading: false,
        error: '',
        previousReservations: newReservations,
        previousTotalCount: action.payload.data.result.data.meta.total,
        pages: action.payload.data.result.data.meta.pages,
      };
    }
    case GET_PREVIOUS_MEETING_RESERVATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: t`There was an error getting meeting reservations. Please try again.`,
      };
    case CREATE_MEETING_RESERVATION:
      return {
        ...state,
        reservationCreationLoading: true,
        error: '',
      };
    case CREATE_MEETING_RESERVATION_FAIL: {
      const errors: string[] = [t`There was an error creating meeting reservation.`];

      if (action.payload?.error?.code === 1_001_011) {
        errors.push(t`You don't have permissions to access the user's calendar.`);
      }
      
      return {
        ...state,
        reservationCreationLoading: false,
        error: errors.join(" "),
      };
    }
    case CREATE_MEETING_RESERVATION_SUCCESS: {
      return {
        ...state,    
        reservationCreationLoading: false,            
        error: '',
      };
    }

    case CANCEL_MEETING_RESERVATION: {
      return {
        ...state,
        loading: true,
      };
    }

    case CANCEL_MEETING_RESERVATION_FAIL: {
      const errors: string[] = [t`There was an error cancelling meeting reservation.`];

      if (action.payload?.error?.code === 1_001_011) {
        errors.push(t`You don't have permissions to access the user's calendar.`);
      }

      return {
        ...state,
        loading: false,
        error: errors.join(" "),        
      };
    }
    case CANCEL_MEETING_RESERVATION_SUCCESS: {
      const reservations = state.meetingReservations;

      reservations[action.payload.data.result.data.id].status = 'canceled';

      return {
        ...state,
        error: '',
        loading: false,
        detailsOpened: false,
        reservationCanceled: true,
        meetingReservations: reservations,
      };
    }    

    case SET_MEETING_RESERVATION_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function getMeetingReservations
(data: GetMeetingReservationsRequest): GetMeetingReservations {
  let url = `/api/users/${data.delegatedUserId || "me"}/reservations?page=${data.page}&limit=${data.limit}&filter${data.filter}&include[0]=schedule&include[1]=room&include[2]=floor&include[3]=floor.location&include[4]=attendees&filter[status]=booked&orderBy=asc:schedule.startDate`;

  // update BE for statuses
  // if (data.statuses?.length) {
  //   url += `&status=${data.statuses.join(',')}`;
  // }

  // replace s with search after BE update
  if (data.s) {
    url += `&s=${data.s}`;
  }

  return {
    type: GET_MEETING_RESERVATIONS,
    payload: {
      request: {
        method: 'GET',
        url,
      },
    },
    clearData: data.clearData,
  };
}

export function getPreviousMeetingReservations
(data: GetMeetingReservationsRequest): GetPreviousMeetingReservations {
  let url = `/api/users/${data.delegatedUserId || "me"}/reservations?page=${data.page}&limit=${data.limit}&filter${data.filter}&include[0]=schedule&include[1]=room&include[2]=floor&include[3]=floor.location&include[4]=attendees&filter[status]=booked`;

  // update BE for statuses
  // if (data.statuses?.length) {
  //   url += `&status=${data.statuses.join(',')}`;
  // }

  // replace s with search after BE update
  if (data.s) {
    url += `&s=${data.s}`;
  }

  return {
    type: GET_PREVIOUS_MEETING_RESERVATIONS,
    payload: {
      request: {
        method: 'GET',
        url,
      },
    },
    clearData: data.clearData,
  };
}



export function createMeetingReservation(data: CreateMeetingReservationRequest, delegatedUserId?: string): CreateMeetingReservation {
  return {
    type: CREATE_MEETING_RESERVATION,
    payload: {
      request: {
        method: 'POST',
        url: `/api/users/${delegatedUserId || "me"}/reservations`,
        data,
      },
    },
  };
}

export function cancelMeetingReservation(data: CancelMeetingReservationRequest): CancelMeetingReservation {
  const { reservationId, delegatedUserId } = data;
  
  return {
    type: CANCEL_MEETING_RESERVATION,
    payload: {
      request: {
        method: 'POST',
        url: `/api/users/${delegatedUserId || "me"}/reservations/${reservationId}/cancel`,
      },
    },
  };
}

export function setMeetingReservationData(data: Partial<State>): SetMeetingReservationData {
  return {
    type: SET_MEETING_RESERVATION_DATA,
    payload: data,
  };
}

export function getMeetingReservationSelected(state: State): IMeetingReservation | undefined {
  const reservationsMerged = { ...state.meetingReservations, ...state.previousReservations };  

  const reservationArray = Object.keys(reservationsMerged).map((reservationId) => reservationsMerged[reservationId]);

  return reservationArray.find((reservation) => reservation.id === state.reservationSelected);  
}

function orderReservations(reservations: IMeetingReservation[], previous: boolean): IMeetingReservation[] {
  const ordered = reservations.sort((a, b) => {
    const bBookingDate = b.schedule.startDate;
    const aBookingDate = a.schedule.startDate;

    const aIsAfterB = moment(aBookingDate).isAfter(bBookingDate);
    const aIsBeforeB = moment(aBookingDate).isBefore(bBookingDate);

    if (aIsAfterB) {
      return previous ? -1 : 1;
    } else if (aIsBeforeB) {
      return previous ? 1 : -1;
    }

    return 0;
  });

  return ordered;
}

export function orderReservationAndBookings(reservations: any, previous: boolean): any[] {
  const ordered = reservations.sort((a: BookingModel | IMeetingReservation, b: BookingModel | IMeetingReservation) => {
    const bBookingDate = ('schedule' in b) ? b.schedule.startDate : getBookingDate(b);    
    const aBookingDate = ('schedule' in a) ? a.schedule.startDate : getBookingDate(a);

    const aIsAfterB = moment(aBookingDate).isAfter(bBookingDate);
    const aIsBeforeB = moment(aBookingDate).isBefore(bBookingDate);
  
    if (aIsAfterB) {
      return previous ? -1 : 1;
    } else if (aIsBeforeB) {
      return previous ? 1 : -1;
    }

    return 0;
  });

  return ordered;
}

export function selectReservationsForToday(state: State): IMeetingReservation[] {
  const now = new Date();
  const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
  const endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

  const reservationsArray = Object.keys(state.meetingReservations)
    .map(reservationId => state.meetingReservations[reservationId]);  
    
  const reservations = reservationsArray.filter((reservation) => {
    const startDate = new Date(reservation.schedule.startDate);
    const endDate = new Date(reservation.schedule.endDate);

    return moment(startDate).isBetween(startOfToday, endOfToday) || moment(endDate).isBetween(startOfToday, endOfToday);
  });

  return orderReservations(reservations, false);  
}

export function selectReservationsBookedForToday(state: State): IMeetingReservation[] {
  const reservationsForToday = selectReservationsForToday(state);

  return reservationsForToday.filter(reservation => reservation.status === 'booked');
}

/**
 * Select reservations that are after today at 23:59:59.
 */

 export function selectUpcomingReservations(state: State): IMeetingReservation[] {
  const nextDay = new Date(new Date().getTime() + 86400000);
  const startOfNextDay = new Date(nextDay.getFullYear(), nextDay.getMonth(), nextDay.getDate(), 0, 0, 0, 0);

  const reservationsArray = Object.keys(state.meetingReservations)
    .map(reservationId => state.meetingReservations[reservationId]);  
    
  const reservations = reservationsArray.filter((reservation) => {
    const startDate = new Date(reservation.schedule.startDate);

    return moment(startDate).isSameOrAfter(startOfNextDay);
  });

  return orderReservations(reservations, false); 
}

export function selectUpcomingBookedReservations(state: State): IMeetingReservation[] {
  const upcomingReservations = selectUpcomingReservations(state);

  return upcomingReservations.filter(reservation => reservation.status === 'booked');
}


/**
 * Select reservations that are before today at 00:00:00.
 */

export function selectPreviousReservations(state: State): IMeetingReservation[] {
  const reservationsArray = Object.keys(state.meetingReservations)
      .map(reservationId => state.meetingReservations[reservationId]);

  const today = new Date();

  const reservations = reservationsArray.filter((reservation: IMeetingReservation) => moment(reservation.schedule.startDate).isBefore(today, 'day'));
  
  const ordered = reservations.sort((a, b) => {
    const bBookingDate = b.schedule.startDate;
    const aBookingDate = a.schedule.startDate;

    const aIsAfterB = moment(aBookingDate).isAfter(bBookingDate);
    const aIsBeforeB = moment(aBookingDate).isBefore(bBookingDate);

    if (aIsAfterB) {
      return -1;
    } else if (aIsBeforeB) {
      return 1;
    }

    return 0;
  });
    
  return ordered;
}

export function selectReservationsForDate(state: State, date: Date): IMeetingReservation[] {
  const reservationsArray = Object.keys(state.meetingReservations)
    .map(reservationId => state.meetingReservations[reservationId]);

  const reservations = reservationsArray.filter(reservation => 
      isSameDay(date, moment(reservation.schedule.startDate).toDate()));  

  return reservations;
}

export function formatMeetingDateInLocalTime(reservation: IMeetingReservation | undefined): string {
  if (!reservation) return '';

  const utcStartDate = reservation.schedule.startDate;
  const utcEndDate = reservation.schedule.endDate;
  const zonedStartDate = new Date(utcStartDate);
  const zonedEndDate = new Date(utcEndDate);
  const firstPart = format(zonedStartDate, 'MMM d, h:mmaa');
  const secondPart = format(zonedEndDate, 'h:mmaa');
  const gmt = format(zonedStartDate, "XXX");

  return `${firstPart} - ${secondPart} GMT${gmt}`;
}

export function formatMeetingDateInTimeZone(reservation?: IMeetingReservation): string {
  if (!reservation) {
    return "";
  }

  const { schedule } = reservation;

  if (!schedule.timeZone) {
    return formatMeetingDateInLocalTime(reservation);
  }

  const timeZone = schedule.timeZone.split(";")[0];
  const start = formatInTimeZone(new Date(schedule.startDate), timeZone, "MMM d, h:mmaa");
  const end = formatInTimeZone(new Date(schedule.endDate), timeZone, "h:mmaa");
  const gmt = formatInTimeZone(new Date(schedule.startDate), timeZone, "XXX");

  return `${start} - ${end} GMT${gmt}`;
}

export function formatDifferenceDate(reservation: IMeetingReservation, dateCompare: Moment): string {
  const schedule = reservation.schedule;

  // Get hours. Ex: 6
  const hours = moment(schedule.startDate).diff(dateCompare, 'hour');
  // Get minutes. Ex: 370 (this means, 6 hours and 10 minutes)
  let minutes = moment(schedule.startDate).diff(dateCompare, 'minute');
  // Get only minutes remaining from hours
  minutes = minutes % 60;

  // Formats hours and minutes. Ex: 06:10
  const alwaysPositiveHours = Math.abs(hours);
  const alwaysPositiveMinutes = Math.abs(minutes);

  let formated = '';

  if (hours !== 0) {
    formated = `${String(alwaysPositiveHours).length === 1 ? '0' + alwaysPositiveHours : alwaysPositiveHours}:${String(alwaysPositiveMinutes).length === 1 ? '0' + alwaysPositiveMinutes : alwaysPositiveMinutes}h`;
  } else {
    formated = `${String(alwaysPositiveMinutes).length === 1 ? '0' + alwaysPositiveMinutes : alwaysPositiveMinutes}m`;
  }

  return formated;


}

/**
 * Counts all reservations locations and returns the one that appears more often
 */
 export function selectMoreOftenLocation(state: State): IMeetingReservationLocation | undefined {
  const reservationsArray = Object.keys(state.meetingReservations)
    .map(reservationId => state.meetingReservations[reservationId]);

  const locationsCount: { [locationId: string]: { location: IMeetingReservationLocation; count: number } } = {};
  let moreOftenLocation: { location: IMeetingReservationLocation; count: number } | undefined;

  reservationsArray.forEach(reservation => {
      if (!reservation.floor.location) return;

      const locationCount = locationsCount[reservation.floor.location.id];

      if (!locationCount) {
        locationsCount[reservation.floor.location.id] = {
          count: 0,
          location: reservation.floor.location,
        };
      } else {
        locationsCount[reservation.floor.location.id] = {
          count: locationCount.count + 1,
          location: locationCount.location,
        };
      }
  });

  Object.keys(locationsCount).forEach(locationId => {
    const locationCount = locationsCount[locationId];

    if (!moreOftenLocation) {
      moreOftenLocation = locationCount;
    } else {
      if (locationCount.count > moreOftenLocation.count) {
        moreOftenLocation = locationCount;
      }
    }
  });

  return moreOftenLocation?.location;
}