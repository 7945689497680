import {
  CreateGlobalNotificationResponse,
  DeleteGlobalNotificationResponse,
  GetGlobalNotificationsResponse,
  RecipientType,
  SendGlobalNotificationResponse,
  UpdateGlobalNotificationResponse,
} from './models';

export const createGlobalNotificationResponse: CreateGlobalNotificationResponse = {
  result: {
    statusCode: 200,
    data: {
      id: 'f6df3a54-604e-4878-84fa-41c10c0122bd',
      subject: 'Global Notification Mock Test',
      message: 'Test',
      status: 'pending',
      createdAt: '2021-11-11T13:49:03.212Z',
      recipients: RecipientType['All users'],
      sendDate: '2021-11-11T09:00:00.000Z',
      startDate: '2021-11-11T09:00:00.000Z',
      endDate: '2021-11-11T09:00:00.000Z',
      locationId: null,
    },
  },
};

export const deleteGlobalNotificationResponse: DeleteGlobalNotificationResponse = {
  result: {
    statusCode: 200,
    data: {
      id: 'ac6388af-358a-48e8-b4c6-fe589741f1d3',
    },
  },
};

export const getGlobalNotificationsResponse: GetGlobalNotificationsResponse = {
  result: {
    statusCode: 200,
    data: {
      page: 1,
      limit: 10,
      totalPages: 2,
      notifications: [
        {
          id: '9e614633-8bd5-4c37-8762-882a1f542bd7',
          subject: 'Global Notification Test Mocked',
          message: 'Teste',
          status: 'pending',
          createdAt: '2021-11-05T14:38:21.853Z',
          recipients: RecipientType['All users'],
          sendDate: '2021-11-30T11:37:00.000Z',
          startDate: '2021-11-30T11:37:00.000Z',
          endDate: '2021-11-30T11:37:00.000Z',
          locationId: null,
        },
        {
          id: 'a6a11093-6902-48f6-a498-8280f63cf98e',
          subject: 'Global Notification Test Mocked 2',
          message: 'Teste 23123',
          status: 'pending',
          createdAt: '2021-11-05T15:14:45.961Z',
          recipients: RecipientType['Web users'],
          sendDate: '2021-11-23T15:00:00.000Z',
          startDate: '2021-11-23T15:00:00.000Z',
          endDate: '2021-11-23T15:00:00.000Z',
          locationId: null,
        },
        {
          id: '93d191ce-ee95-499f-848a-252726917284',
          subject: 'Global Notification Test Mocked 3',
          message: 'Teste 123123',
          status: 'pending',
          createdAt: '2021-11-05T15:17:19.792Z',
          recipients: RecipientType['Mobile users'],
          sendDate: '2021-11-22T18:15:00.000Z',
          startDate: '2021-11-22T18:15:00.000Z',
          endDate: '2021-11-22T18:15:00.000Z',
          locationId: null,
        },
        {
          id: '3aec7dfb-b0ad-47a8-8670-d82b48ce2980',
          subject: 'Global Notification Test Mocked 4',
          message: 'Testre 1\'23',
          status: 'sent',
          createdAt: '2021-11-05T15:19:23.701Z',
          recipients: RecipientType['Mobile users'],
          sendDate: '2021-11-19T13:15:00.000Z',
          startDate: '2021-11-19T13:15:00.000Z',
          endDate: '2021-11-19T13:15:00.000Z',
          locationId: null,
        },
      ],
    },
  },
};

export const sendGlobalNotificationResponse: SendGlobalNotificationResponse = {
  result: {
    statusCode: 200,
    data: {
      id: 'ac6388af-358a-48e8-b4c6-fe589741f1d3',
    },
  },
};

export const updateGlobalNotificationResponse: UpdateGlobalNotificationResponse = {
  result: {
    statusCode: 200,
    data: {
      id: 'ac6388af-358a-48e8-b4c6-fe589741f1d3',
      subject: 'Global Notification Test',
      message: 'Teste',
      status: 'pending',
      createdAt: '2021-11-05T14:37:25.305Z',
      recipients: RecipientType['All users'],
      sendDate: '2021-11-05T11:37:05.000Z',
      startDate: '2021-11-05T11:37:05.000Z',
      endDate: '2021-11-05T11:37:05.000Z',
      locationId: null,
    },
  },
};
