import { Trans, t } from "@lingui/macro";
import { useTypedSelector } from "../../../../Store/Redux/store";
import Box from "../../../../Components/Box";
import styles from "./styles.module.scss";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Skeleton} from "@material-ui/lab";
import Text from "../../../../Components/Text";
import OverflowToolTip from "../../../../Components/OverflowTooltip";
import { UserManagementUser } from "../../../Store/userManagement/models";
import GroupUserTableBody from "./GroupUserTableBody";

const getHeadCells = (isAdGroup: boolean): { id: string; label: string }[] => {
  const headCells = [
    { id: 'fullName', label: t`Full name` },
    { id: 'email', label: t`Email` },
    { id: 'documents', label: t`Documents` },
    { id: 'approvedAccomodation', label: t`Approved Accomodation` },
    { id: 'uploadedDate', label: t`Uploaded date` },
  ];

  if (!isAdGroup) {
    headCells.push({ id: 'menu', label: '' });
  }

  return headCells;
};

interface Props {
  allUsers: UserManagementUser[];
  isAdGroup: boolean;
}

export default function GroupUserTable(props: Props) {
  // to do - need api of members from BE
  const { loading } = useTypedSelector(state => state.adminUserManagement);
  const { allUsers, isAdGroup } = props;
  const headCells = getHeadCells(isAdGroup);

  return (
    <Box>
      <Box className={styles.profileTableHeader} display="flex" justifyContent="between" marginBottom={10}>
        <h3><Trans>Members</Trans></h3>
      </Box>
      <TableContainer>
        <Table
          aria-label={t`users documents list`}
          className={styles.profileTable}
        >
          <TableHead classes={{ root: styles.tableHead }}>
            <TableRow classes={{ root: styles.tableRow }}>
              {headCells.map((headCell) => (
                <TableCell classes={{ root: styles.tableCell }} key={headCell.id} padding="none">
                  <OverflowToolTip text={headCell.label} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {!loading &&
          <TableBody classes={{root: styles.tableBody}}>
            {
              allUsers.map((user, index) => {
                return (<GroupUserTableBody index={index} isAdGroup={isAdGroup} key={index} user={user} />);
              })
            }
          </TableBody>
          }
        </Table>
      </TableContainer>
      {loading ? (
        <Box height={200}>
          <Skeleton height="100%" variant="rect" />
        </Box>
      ) : (
        !allUsers.length && (
          <Box marginTop={50}>
            <Text align="center" color="lightGray" size="md">
              <Trans>No members found</Trans>
            </Text>
          </Box>
        )
      )}
    </Box>
  );
}
