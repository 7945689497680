import { PaletteColorOptions } from "@material-ui/core";
import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

const primary: PaletteColorOptions = {
  main: "#1E1F7B",
};

declare module '@mui/material/styles' {
  interface Theme {
    background: {
      blue: React.CSSProperties['color'];
    };
  }

  interface ThemeOptions {
    background: {
      blue: React.CSSProperties['color'];
    };
  }
}

export const theme = createTheme({
  background: {
    blue: "#EFEFF8",
  },
  palette: {
    primary,
    mode: "light",
    secondary: {
      main: '#f1f8ff',
      contrastText: '#1e1f7b',
    },
    error: {
      main: "#d32f2f",
      light: "#ffbebe",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "capitalize",
          fontWeight: "600",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: grey[100],
          borderRadius: 8,
        },
      },
      defaultProps: {
        animation: "wave",
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: grey[100],
          borderRadius: 8,
          ":after": {
            display: "none",
          },
          ":before": {
            display: "none",
          },
          "&.Mui-focused": {
            backgroundColor: "#EFEFF8",
          },
          "& .MuiInputAdornment-root": {
            marginTop: "0px !important",
          },
        },
        input: {
          padding: "6px 8px",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          height: 14,
          width: 28,
        },
        switchBase: {
          height: 14,
          width: 14,
          padding: 0,
          "&.Mui-checked": {
            transform: 'translateX(14px)',
          },
          "&.Mui-checked+.MuiSwitch-track": {
            opacity: 1,
          },
        },
        track: {
          height: 14,
          width: 28,
          borderRadius: 4,
          backgroundColor: grey[400],
        },
        thumb: {
          height: 12,
          width: 12,
          borderRadius: 4,
          backgroundColor: "#fff",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: "relative",
          transform: "none",
          fontSize: 14,
          color: "#000",
          marginBottom: 4,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: grey[100],
        },
      },
    },
  },
});
