import Api from 'Store/Services/Api';
import {
  call,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  EDIT_ATTRIBUTES,
  EDIT_ATTRIBUTES_FAIL,
  EDIT_ATTRIBUTES_SUCCESS,
  EditAttribute,
  GET_ATTRIBUTES,
  GET_ATTRIBUTES_FAIL,
  GET_ATTRIBUTES_SUCCESS,
  GetAttributes,
} from "./index";

function* getAttributesSaga(action: GetAttributes): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_ATTRIBUTES_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_ATTRIBUTES_FAIL, payload: e });
  }
}

function* editAttributeSaga(action: EditAttribute): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: EDIT_ATTRIBUTES_SUCCESS, payload });
  } catch (e) {
    yield put({ type: EDIT_ATTRIBUTES_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_ATTRIBUTES, getAttributesSaga),
  takeEvery(EDIT_ATTRIBUTES, editAttributeSaga),
];
