import styles from "./styles.module.scss";
import Box from "Components/Box";
import { IGroup } from "../../../../../Store/groups/models";
import OverflowToolTip from "../../../../../../Components/OverflowTooltip";
import ActiveDirectory from "../../../../../../Components/Icons/ActiveDirectory";
import Tooltip from "../../../../../../Components/Tooltip";

interface Props {
  group: IGroup;
}

export default function Name({ group }: Props) {
  return (
    <>
      {
        group.adGroup?.id ?
          <Box alignItems="center" display="flex">
            <Tooltip
              placement="top-start"
              title={<div className={styles.tooltipContent}>Linked to Active Directory</div>}
            >
              <div className={styles.tooltipContentBox}>
                <ActiveDirectory/>
              </div>
            </Tooltip>
            <OverflowToolTip text={group.name}/>
          </Box>
           :
          <OverflowToolTip text={group.name}/>
      }
    </>
  );
}
