import {useState} from "react";
import Dropzone, {IFileWithMeta, StatusValue} from "react-dropzone-uploader";
import Compressor from "compressorjs";
import heic2any from "heic2any";
import styles from "../styles.module.scss";
import Box from "../../Box";
import {Trans} from "@lingui/macro";
import InputLabel from "@material-ui/core/InputLabel";
import Text from "../../Text";
// @ts-ignore
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import {InputComponent, LayoutComponent, PreviewComponent} from "../../DropZone";

interface Props {
  fileWithMeta: IFileWithMeta | null;
  setFileWithMeta: Function;
}

export default function UploadCovidDropzone(props: Props) {
  const { fileWithMeta, setFileWithMeta } = props;
  const [loadStatus, setLoadStatus] = useState<'loading' | 'done'>('done');
  const MAX_IMAGE_HEIGHT_WIDTH = 1500;

  const handleRemoveFile = () => {
    if (fileWithMeta) {
      fileWithMeta.remove();
      setFileWithMeta(null);
    }
  };

  const getFilesFromEvent = (e: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>): File[] | Promise<File[]> => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then((chosenFiles: any) => {
        resolve(chosenFiles.map((f: any) => f.fileObject));
      });
    });
  };

  const onCompressImage = (fileWithMetaResponse: IFileWithMeta, image: File | Blob) => {
    new Compressor(image, {
      maxHeight: MAX_IMAGE_HEIGHT_WIDTH,
      maxWidth: MAX_IMAGE_HEIGHT_WIDTH,
      quality: 1,
      success: async (result: File) => {
        const reader = new FileReader();

        // Get an image from the file to take meta data
        reader.onload = function (e: any) {
          const imageObject = new Image();
          const previewUrl = window.URL.createObjectURL(result);

          imageObject.src = e.target.result;

          // After the image is loaded, sets metadata
          imageObject.onload = () => {
            fileWithMetaResponse.file = result;
            fileWithMetaResponse.meta.previewUrl = previewUrl;
            fileWithMetaResponse.meta.height = imageObject.height;
            fileWithMetaResponse.meta.width = imageObject.width;
            fileWithMetaResponse.meta.size = result.size;

            setLoadStatus('done');
            setFileWithMeta(fileWithMetaResponse);
          };
        };

        reader.readAsDataURL(result);
      },
      error() {
        setLoadStatus('done');
        setFileWithMeta(fileWithMetaResponse);
      },
    });
  };

  const handleChangeStatus = (fileWithMetaResponse: IFileWithMeta, status: StatusValue) => {
    if (status === 'preparing') {
      setLoadStatus('loading');
    }

    if (status === 'done') {
      fileWithMeta?.remove();

      if (
        fileWithMetaResponse.file.name.toLowerCase().includes(".heic") ||
        fileWithMetaResponse.file.name.toLowerCase().includes(".heif")
      ) {
        heic2any({ blob: fileWithMetaResponse.file, toType: "jpg", quality: 0.1 }).then(
          // @ts-ignore
          (newImage: Blob) => {
            onCompressImage(fileWithMetaResponse, newImage);
          },
        );
      } else {
        onCompressImage(fileWithMetaResponse, fileWithMetaResponse.file);
      }
    }
  };

  return (
    <>
      <Box marginBottom={9} position="relative">
        <InputLabel className={styles.inputLabel} htmlFor="add-image">
          <Text size="md">
            <span className={styles.requiredSign}>*</span>
            <Trans>
              Select document (file type here)
            </Trans>
          </Text>
        </InputLabel>
      </Box>

      <Dropzone
        InputComponent={InputComponent}
        LayoutComponent={LayoutComponent}
        PreviewComponent={(props) => <PreviewComponent {...props} handleRemoveFile={handleRemoveFile} loadingState={loadStatus === "loading"} />}
        accept="image/*,.pdf,.heic,.heif"
        classNames={{
          dropzone: '',
        }}
        getFilesFromEvent={getFilesFromEvent}
        multiple={false}
        onChangeStatus={handleChangeStatus}
      />
      { loadStatus === "loading" ? <div>Loading...</div> : null }
    </>
  );
}
