import { DeepNonNullable } from "types";
import { ReservationsAvailabilityQuery } from "../types";
import { format, set } from "date-fns";

export const getDefaultReservationsAvailabilityQuery = (): DeepNonNullable<ReservationsAvailabilityQuery> => {
  const today = new Date();
  const startDate = format(today, "yyyy-MM-dd");
  const endDate = startDate;
  const startTime = format(set(today, { hours: 9, minutes: 0, seconds: 0, milliseconds: 0 }), "HH:mm:ssxxx");
  const endTime = format(set(today, { hours: 18, minutes: 0, seconds: 0, milliseconds: 0 }), "HH:mm:ssxxx");

  return { startDate, endDate, startTime, endTime, rrule: "" };
};