import Box from 'Components/Box';

interface Props {    
  hours: Array<number>;
  hour: number;
}

export default function Hour({ hours, hour }: Props) {
  const hourText = (hour: number) => {
    const formattedHour = `${Number.isInteger(hour) ? hour: Math.floor(hour)}:${Number.isInteger(hour) ? '00': '30'}`;

    if (hours.length <= 2) return formattedHour;

    return Number.isInteger(hour) ? formattedHour : '';
  };

  const text = hourText(hour);

  return (   
    <Box minWidth={40}>
      {text}
    </Box>    
  );
}