interface Props {
  color?: string;
  size?: number;
}

export default function Room({
  color = '#5E5E5E',
  size = 16,
}: Props) {
  return (
    <svg height={`${size}px`} viewBox="0 0 16.0 16.0" width={`${size}px`}>
      <defs>
        <clipPath id="i0">
          <path d="M1440,0 L1440,1046 L0,1046 L0,0 L1440,0 Z"></path>
        </clipPath>
        <clipPath id="i1">
          <path d="M756,0 C763.731986,-1.42034288e-15 770,6.2680135 770,14 L770,76 C770,83.7319865 763.731986,90 756,90 L14,90 C6.2680135,90 9.46895252e-16,83.7319865 0,76 L0,14 C-9.46895252e-16,6.2680135 6.2680135,1.42034288e-15 14,0 L756,0 Z">
          </path>
        </clipPath>
        <clipPath id="i2">
          <path d="M11,3 L11,14 L3,14 L3,3 L11,3 Z M12.5,0 C13.2796961,0 13.9204487,0.594888083 13.9931334,1.35553999 L14,1.5 L14,13 C14,13.5522847 13.5522847,14 13,14 C12.4871642,14 12.0644928,13.6139598 12.0067277,13.1166211 L12,13 L12,2 L2,2 L2,13 C2,13.5128358 1.61395981,13.9355072 1.11662113,13.9932723 L1,14 C0.487164161,14 0.0644928393,13.6139598 0.00672773133,13.1166211 L0,13 L0,1.5 C0,0.720303883 0.594888083,0.0795513218 1.35553999,0.00686657806 L1.5,0 L12.5,0 Z M9,5 L5,5 L5,12 L9,12 L9,10 L8,10 C7.44771525,10 7,9.55228475 7,9 C7,8.44771525 7.44771525,8 8,8 L9,8 L9,5 Z">
          </path>
        </clipPath>
        </defs>
        <g transform="translate(-120.0 -325.0)">
          <g clipPath="url(#i0)">
            <g transform="translate(100.0 272.0)">
              <g clipPath="url(#i1)">
                <polygon fill="#F4F4F4" points="0,0 770,0 770,90 0,90 0,0" stroke="none">
                </polygon>
              </g>
            <g transform="translate(20.0 49.0)">
              <g transform="translate(0.0 4.0)">
                <g transform="">
                  <g transform="translate(1.0 1.000000000003979)">
                    <g clipPath="url(#i2)">
                      <polygon fill={color} points="0,0 14,0 14,14 0,14 0,0" stroke="none">
                      </polygon>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>   
  );
}