import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import useSnackbar from "../../../Components/Snackbar/useSnackbar";
import {useTypedSelector} from "../../../Store/Redux/store";
import {useEffect} from "react";
import {getAttributes} from "../../Store/attributes";
import {setFloorMapApiRequestsData} from "../../Store/floorMapApiRequestsDuck";
import Box from "../../../Components/Box";
import EditableMap from "./EditableMap";
import FloorMapEditHeader from "./FloorMapEditHeader";
import ButtonBase from "@material-ui/core/ButtonBase";
import SectionIcon from "../../../Components/Icons/Section";
import Text from "../../../Components/Text";
import {Trans, t} from "@lingui/macro";
import Checkbox from "../../../Components/Checkbox";
import styles from "../FloorMap/styles.module.scss";
import {setDrawDeskState, setDrawSectionState, setFloorMapData} from "../../Store/floorMapDuck";
import DefaultDeskSize from "../FloorMap/Components/DefaultDeskSize";

export default function FloorMapEdit() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ floorId: string; locationId: string }>();
  const [open] = useSnackbar();
  const { adminFloorMap, adminFloorMapApiRequests, config } = useTypedSelector(state => state);
  const {
    drawingStates,
    showSections,
    mapSizeToEdit,
    fileWithMetaToEdit,
  } = adminFloorMap;
  const { drawDesk, drawSection } = drawingStates;
  const previewUrlToEdit = fileWithMetaToEdit ? fileWithMetaToEdit.meta.previewUrl : '';

  const toggleAddDesk = () => {
    dispatch(setDrawDeskState({ active: !drawDesk.active }));
  };

  const toggleAddSection = () => {
    // Show sections if the're inactive when adding new sections
    if (!drawSection.active) {
      dispatch(setFloorMapData({ showSections: true }));
    }

    dispatch(setDrawSectionState({ active: !drawSection.active }));
  };

  useEffect(() => {
    dispatch(getAttributes({ locationId }));
  }, [locationId]);

  useEffect(() => {
    if (adminFloorMapApiRequests.error) {
      open({
        text: adminFloorMapApiRequests.error,
        type: 'error',
        onClose: () => {
          dispatch(setFloorMapApiRequestsData({ error: '' }));
        },
      });
    }
  }, [adminFloorMapApiRequests.error]);

  return (
    <>
      <Box marginTop={20}>
        <Box>
          <FloorMapEditHeader />

          <Box alignItems="center" display="flex" justifyContent="between" marginTop={14}>
            <Box alignItems="center" display="flex">
              <ButtonBase disableRipple onClick={toggleAddSection}>
                <Box alignItems="center" display="flex">
                  <SectionIcon color={drawSection.active ? config.theme.primary : "#5E5E5E"} size={16} />

                  <Box marginStart={5}>
                    <Text color={drawSection.active ? "blue" : "gray"} size="md" weight="semi-bold">
                      <Trans>Add Section</Trans>
                    </Text>
                  </Box>
                </Box>
              </ButtonBase>

              <Box marginEnd={15} />

              <ButtonBase disableRipple onClick={toggleAddDesk}>
                <Box alignItems="center" display="flex">
                  <SectionIcon color={drawDesk.active ? config.theme.primary : "#5E5E5E"} size={16} />

                  <Box marginStart={5}>
                    <Text color={drawDesk.active ? "blue" : "gray"} size="md" weight="semi-bold">
                      <Trans>Add Desk</Trans>
                    </Text>
                  </Box>
                </Box>
              </ButtonBase>

              <Box marginStart={21}>
                <Checkbox
                  checked={showSections}
                  classes={{ container: styles.checkbox }}
                  label={t`Show sections`}
                  name="showSections"
                  onChange={(value) => dispatch(setFloorMapData({ showSections: value }))}
                />
              </Box>

              <Box marginEnd={21} marginStart={15}>
                <DefaultDeskSize />
              </Box>
            </Box>
          </Box>

          <Box marginTop={14}>
            {(previewUrlToEdit) && (
              <EditableMap
                mapSize={mapSizeToEdit}
                previewUrl={previewUrlToEdit}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
