import AdminRoutes from 'Admin/AdminRoutes';
import Api from '../Store/Services/Api';
import AppDownload from './Pages/AppDownload';
import BookingApprovalRoutes from 'BookingApproval/BookingApprovalRoutes';
import BookingDetails from './Pages/Booking/BookingDetails';
import Checkin from './Pages/Checkin';
import CreateNewBookingRoutes from './Pages/CreateNewBooking/CreateNewBookingRoutes';
import CreateNewBookingSupportRoutes from './Pages/CreateNewBooking/CreateNewBookingSupportRoutes';
import CreateNewMeetingRoutes from './Pages/CreateNewMeeting/CreateNewMeetingRoutes';
import CreateNewVisitRoutes from './Pages/CreateNewVisit/CreateNewVisitRoutes';
import EmailLayoutsRoutes from 'BookingApproval/EmailLayouts/EmailLayoutsRoutes';
import Error404 from './Pages/Error404';
import Help from './Pages/Help';
import jwt_decode from 'jwt-decode';
import Login from 'App/Pages/Login';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import LoginQA from 'App/Pages/LoginQA';
import useGaTracker from 'config/useGaTracker';
import UserProfile from './Pages/Profile';
import useSnackbar from 'Components/Snackbar/useSnackbar';
import { addUserDeviceToken, getProfile } from 'App/Store/Users/profileDuck';
import { AnimatePresence, motion } from 'framer-motion';
import { graphRequest, loginRequest, msalConfig} from '../Store/Services/AuthConfig';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useTypedSelector } from 'Store/Redux/store';
import {
  logout,
  setGraphLoginToken,
  setLoginData,
  setLoginToken,
} from './Store/Auth/loginDuck';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { isAnotherUserSelectedByExecutiveAssistant } from "./Store/Users/executiveAssistant/helpers";
import {IPublicClientApplication} from "@azure/msal-browser";
import { useIsAuthenticatedInAllScopes } from 'hooks';
import { EditReservationRoute, ReservationsRoute } from './Pages/Reservations';
import { PushControl, Questionnaires, Wrapper } from 'components';
import { getMessagingToken } from 'messaging';
import { HomePendingRoute, HomePreviousRoute, HomeSavedRoute, HomeUpcomingRoute } from './Pages/Home';
import { getAdminLocations } from './Store/Locations/locationsDuck';

export function PageTransition({ children }: React.PropsWithChildren<any>) {
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.3,
  };

  return (
    <motion.div
      animate="in"
      exit="out"
      initial="initial"
      transition={pageTransition}
      variants={pageVariants}
    >
      {children}
    </motion.div>
  );
}

export default function AppRoutes() {
  const { instance } = useMsal();
  const history = useHistory();
  const activeAccount = instance.getActiveAccount();
  const accounts = instance.getAllAccounts();
  const account = activeAccount || accounts[0];

  const [openSnackbar] = useSnackbar();

  // Initializers Google Analytics tracker
  useGaTracker();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticatedInAllScopes();
  const location = useLocation();
  const { login, executiveAssistant, profile } = useTypedSelector(state => state);
  const { unauthorized, token, tokenGraphApi, loginQA } = login;

  const isAdminRoutes = location.pathname.includes('/admin');
  const anotherUserSelectedByExecutiveAssistant = isAnotherUserSelectedByExecutiveAssistant(profile, executiveAssistant);
  // show Questionnaire if no admin pages or executiveAssistant is not select another account
  const showQuestionnaire = !isAdminRoutes && !anotherUserSelectedByExecutiveAssistant;
  const showProfile = !anotherUserSelectedByExecutiveAssistant;

  // This token is used for unit testing. It doesn't affect the application since it's only
  // effect will be to render pages as if the user was logged in. All requests will still
  // fail since it's not a valid token.
  // TODO remove this logic and replace tests for mocked api requests tests
  const validToken = token === 'jwt-token-test'
    ? true
    // @ts-ignore
    : token && (jwt_decode(token).exp >= Date.now() / 1000); // test check for valida token
  const forceLogout = !validToken && !unauthorized;

  // login error handling
  const handleOpenSnackBar = (error: string) => {
    openSnackbar({
      text: `There was an error with login. ${error} Please try again or contact the admin.`,
      type: 'error',
      autoHideDuration: 10000,
    });
  };

  // get and set tokens from MSAL (used as bearer token for API)
  const setAzureToken = async (instance: IPublicClientApplication, tokenType: 'default' | 'graph' = 'default') => {
    const activeAccount = await instance.getActiveAccount();
    const accounts = instance.getAllAccounts();
    const azureAccount = activeAccount || accounts[0];

    const request = tokenType === 'default' 
      ? {...loginRequest, account: azureAccount} 
      : {...graphRequest, account: azureAccount};
    const saveTokenAction = tokenType === 'default' 
      ? (token: string, expiration?: Date) => setLoginToken(token, expiration)
      : (token: string, expiration?: Date) => setGraphLoginToken(token, expiration);

    try {
      const { accessToken, expiresOn } = await instance.acquireTokenSilent(request);

      dispatch(saveTokenAction(accessToken, expiresOn || undefined));
    } catch (error) {
      handleOpenSnackBar(error as any);
    }
  };

  // create the user account if not exist and load the user data
  const addTokenAndProfile = async (deviceToken?: string) => {
    const data = deviceToken ? { deviceToken } : {};

    const action = addUserDeviceToken(data); // action create an account in DB if it doesn't exist
    await Api(action, false, true);
    dispatch(getProfile());
  };

  const loadProfile = () => {
    getMessagingToken()?.then((deviceToken) => {
      deviceToken ? addTokenAndProfile(deviceToken) : addTokenAndProfile();
    }).catch(() => {
      // call with no token anyway
      addTokenAndProfile();
    });
  };

  // get user anyway if using QA login instead of MSAL login
  useEffect(() => {
    if (loginQA) {
      dispatch(getProfile()); // call profile data in case of auth without MSAL
    }
  }, [loginQA]);

  useEffect(() => {
    if (isAuthenticated && !forceLogout) {
      setAzureToken(instance, 'default');
      setAzureToken(instance, 'graph');
    }
  }, [validToken, isAuthenticated, unauthorized]);

  // if token is expired and user is in app - call force update
  useEffect(() => {
    if (isAuthenticated && !forceLogout) {
      loadProfile();

      const interval = setInterval(() => {
        setAzureToken(instance,'default');
        setAzureToken(instance,'graph');
      }, 1000 * 60 * 10); // 10 min
      return () => clearInterval(interval);
    }
  }, [validToken, isAuthenticated, unauthorized]);

  // set authorized only if both tokens are exist (it required for api calls)
  useEffect(() => {
    if (token && tokenGraphApi) {
      dispatch(setLoginData({ unauthorized: false }));
    }
  }, [token, tokenGraphApi]);

  useEffect(() => {
    if (forceLogout) {
      instance.logoutPopup({
        account: account,
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
      }).then(() => {
        dispatch(logout()); // also clear token data
        history.replace('/', null);
      });
    }
  }, [validToken, unauthorized]);

  useEffect(() => {
    if (isAdminRoutes) {
      const isAdmin = profile.roleAccess.delegate
        || profile.roleAccess.executiveAssistant
        || profile.roleAccess.hr
        || profile.roleAccess.localAdmin
        || profile.roleAccess.reservationManager
        || profile.roleAccess.superAdmin;

      if (isAdmin) {
        dispatch(getAdminLocations({ activeOnly: false }));
      }
    }
  }, [isAdminRoutes, JSON.stringify(profile.roleAccess)]);

  const sharedRoutes = [
    <Route key="app-download" path='/app-download' render={() => <AppDownload />} />,
    <Route key="booking-approval" path='/booking-approval' render={() => <BookingApprovalRoutes />} />,
    <Route key="privacy" path='/privacy' render={() => <PrivacyPolicy />} />,
    <Route key="help" path='/help' render={() => <Help />} />,
    <Route key="login-qa" path='/login-qa' render={() => <LoginQA />} />,
  ];

  // isAuthenticated - detect if the azure logic is true from the MSAL login
  // unauthorized - detect if token and graph tokens are exist, which are used for api calls
  // validToken - check if token has a correct format and not expired
  // loginQA - alternative way to login without MSAL
  return token === 'jwt-token-test' || (isAuthenticated && !unauthorized && validToken) || loginQA ? (
    <>
      <AnimatePresence exitBeforeEnter initial={true}>
        <Wrapper>
          <Switch key={location.pathname} location={location}>
            {sharedRoutes}
            <Route path='/admin' render={() => <AdminRoutes />} />
            <Route path='/booking/:bookingId/:bookingType/details' render={() => <BookingDetails />} />
            <Route path='/visit/:bookingId/:bookingType/details' render={() => <BookingDetails isVisit />} />
            <Route key="checkin" path='/checkin' render={() => <Checkin />} />
            <Route path='/create-new-booking' render={() => <CreateNewBookingRoutes />} />
            <Route path='/create-new-visit' render={() => <CreateNewVisitRoutes />} />
            <Route path='/create-new-booking-support' render={() => <CreateNewBookingSupportRoutes />} />
            <Route path='/create-new-meeting' render={() => <CreateNewMeetingRoutes />} />
            {showProfile ? <Route path='/profile' render={() => <UserProfile />} /> : null}
            <Route path='/emails' render={() => <EmailLayoutsRoutes />} />
            <Route exact path="/reservations/:reservationId/edit" render={() => <EditReservationRoute />} />
            <Route path="/reservations/locations" render={() => <ReservationsRoute />} />
            <Route exact path="/" render={() => <HomeUpcomingRoute />} />
            <Route exact path="/pending" render={() => <HomePendingRoute />} />
            <Route exact path="/saved" render={() => <HomeSavedRoute />} />
            <Route exact path="/previous" render={() => <HomePreviousRoute />} />
            <Route path='*' render={() => <Error404 />} />
          </Switch>
        </Wrapper>
      </AnimatePresence>
      {showQuestionnaire ? <Questionnaires /> : undefined}
      <PushControl />
    </>
  ) : (
    <Switch>
      {sharedRoutes}
      <Route path='/' render={() => <Login />} />
    </Switch>
  );
}
