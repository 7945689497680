import { useDispatch } from 'react-redux';
import _ from 'underscore';
import { Trans, t } from '@lingui/macro';
import styles from './styles.module.scss';

import Box from '../../../../Components/Box';
import Heading from '../../../../Components/Heading';
import SearchIcon from '../../../../Components/Icons/Search';
import AddUserButton from "../AddUserButton";
import UserManagementFilters from "../../../Pages/UserManagement/UserManagementFilters";
import UserManagementExportCsv from "../../../Pages/UserManagement/UserManagementExportCsv";
import { TextField } from "@material-ui/core";
import { useTypedSelector } from "../../../../Store/Redux/store";
import { setUserManagementData } from "../../../Store/userManagement";

export default function UserManagementHeader() {
  const dispatch = useDispatch();
  const { allUsersFilters } = useTypedSelector(state => state.adminUserManagement);

  const onSearchChange = _.debounce((event: any) => {
    dispatch(setUserManagementData({
      allUsersFilters: {
        ...allUsersFilters,
        search: event.target.value,
      },
    }));
  }, 300);

  return (
    <Box className={styles.header} dataTestId="location-header">
      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={15}>
        <Box>
          <Heading size="sm">
            <Trans>User Management</Trans>
          </Heading>

        </Box>

        <Box alignItems="center" display="flex" justifyContent="between">
          <Box alignItems="center" display="flex" gap={20}>
            <UserManagementExportCsv />
            <TextField
              InputProps={{
                startAdornment: <SearchIcon color="#5E5E5E" size={24} />,
              }}
              className={`input input--default input--search ${styles.searchInput}`}
              defaultValue={''}
              fullWidth
              onChange={onSearchChange}
              placeholder={t`Search by name, email`}
              variant="outlined"
            />

            <UserManagementFilters />
            <AddUserButton />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
