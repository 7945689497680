import { useTypedSelector } from "../../../../../../../Store/Redux/store";
import { Trans } from "@lingui/macro";

import Box from "../../../../../../../Components/Box";
import Text from "../../../../../../../Components/Text";
import Map from "./Map";
import { getMeetingsDraw } from "../../../../../../Store/Meetings/meetingRooms";

interface Props {
  roomId: string;
}

export default function RoomMap(props: Props) {
  const { adminFloorMap } = useTypedSelector(state => state);
  const { previewUrl, name, mapSize, mapImageBounds, meetings } = adminFloorMap;
  const { roomId } = props;

  const meetingsDrawArray = getMeetingsDraw(meetings);
  const showMeetingMap = !!meetingsDrawArray.find(meeting => meeting.roomId === roomId);

  return (
    <>
      {showMeetingMap ?
        <Box display="grid" gap={15}>
          <Text weight="semi-bold">
            {(previewUrl) && (
              <Map
                mapImageBounds={mapImageBounds}
                mapSize={mapSize}
                name={name}
                previewUrl={previewUrl}
                roomId={roomId}
              />
            )}
          </Text>
        </Box> :
        <Box marginTop={50}>
          <Text align="center" weight="semi-bold">
            <Trans>
              No associated room drawings
            </Trans>
          </Text>
        </Box>
      }
    </>
  );
}
