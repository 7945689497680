import { Box, BoxProps } from "@mui/material";

export const TogetherIconHolder: React.FC<BoxProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Box
      alignItems="center"
      bgcolor={({ palette }) => palette.grey[100]}
      borderRadius={2}
      display="flex"
      height={32}
      justifyContent="center"
      marginRight={1}
      width={32}
      {...rest}
    >
      {children}
    </Box>
  );
};
