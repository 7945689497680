import { RefObject, useCallback } from "react";
import { debounce } from "underscore";

export const useLazyLoadScrollHandler = (viewRef: RefObject<HTMLDivElement>, onLoadMore?: () => void, wait?: number) => useCallback(debounce(() => {
  if (viewRef.current) {
    const offset = viewRef.current.scrollHeight - viewRef.current.clientHeight;
    const scrollTop = viewRef.current.scrollTop;

    if (scrollTop > (offset * 0.90)) {
      onLoadMore?.();
    }
  }
}, wait || 300), [onLoadMore]);
