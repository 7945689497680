import styles from "./styles.module.scss";
import arrowIcon from "../../../Locations/LocationForm/assets/arrow-down.svg";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../../Store/Redux/store";
import {UserManagementUser} from "../../../../Store/userManagement/models";
import {Autocomplete, AutocompleteInputChangeReason} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import Box from "../../../../../Components/Box";
import {t} from "@lingui/macro";
import {getAllUsers, setUserManagementData} from "../../../../Store/userManagement";
import {setJustInTimeReservationData} from "../../../../Store/reservationJustInTime";
import {AutocompleteRenderInputParams} from "@material-ui/lab/Autocomplete/Autocomplete";
import {useEffect} from "react";

export default function EmailSearching() {
  const dispatch = useDispatch();
  const { allUsers } = useTypedSelector(state => state.adminUserManagement);
  const { selectedData } = useTypedSelector(state => state.adminReservationJustInTime);

  const onResetSearchState = () => {
    dispatch(setUserManagementData({
      allUsers: [],
    }));
  };

  useEffect(() => {
    onResetSearchState();
  }, []);

  const onUserChange = (event: any, user: UserManagementUser) => {
    return dispatch(setJustInTimeReservationData({
      selectedData: {
        ...selectedData,
        user,
      },
    }));
  };

  const onSearchChange = (event: any, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason !== 'reset' && reason !== 'clear') { // 'reset' fires on select from list, no need to handle api
      const search = event.target.value;
      dispatch(getAllUsers({
        page: 1,
        search,
      }));
    }
  };

  return (
    <Box display="flex" justifyContent="between">
      <Box direction="column" display="flex" width="100%">
        <Autocomplete
          className={`input input--default input--inline`}
          filterOptions={(x) => x} // fix load throttling
          getOptionLabel={(option) => option.email}
          id="address"
          onBlur={() => onResetSearchState()}
          onChange={(event: any, user: any) => onUserChange(event, user)}
          onInputChange={(event, value, reason) => onSearchChange(event, value, reason)}
          options={allUsers}
          popupIcon={
            <img height={10} src={arrowIcon} width={14} />
          }
          renderInput={(params: AutocompleteRenderInputParams) => {
            // @ts-ignore
            const hasValue = params.inputProps.value;
            const hasValueClass = hasValue ? styles.hasValue : '';

            return (
              <div className={styles.inputPropsWrapper}>
                {hasValue && (
                  <div className={styles.inputPropsIcon}>
                    <svg height="24px" viewBox="0 0 24 24" width="24px">
                      <g fill="none" fillRule="evenodd" id="A-reservations" stroke="none" strokeWidth="1">
                        <g id="Create-reservation--2" transform="translate(-905.000000, -154.000000)">
                          <g id="Fields/dd-r" transform="translate(900.000000, 114.000000)">
                            <g id="icons/profileplaceholder" transform="translate(5.000000, 40.000000)">
                              <rect fill="#1E1F7B" height="24" id="Rectangle" rx="6" width="24" x="0" y="0"></rect>
                              <path d="M12,4 C14.7614237,4 17,6.23857625 17,9 C17,10.4808123 16.356267,11.8112727 15.3333763,12.7268057 C18.0871152,13.989024 20,16.7711229 20,20 C20,20.5522847 19.5522847,21 19,21 C18.4477153,21 18,20.5522847 18,20 C18,16.6862915 15.3137085,14 12,14 C8.6862915,14 6,16.6862915 6,20 C6,20.5522847 5.55228475,21 5,21 C4.44771525,21 4,20.5522847 4,20 C4,16.7711229 5.91288484,13.989024 8.66722394,12.7251339 C7.64373303,11.8112727 7,10.4808123 7,9 C7,6.23857625 9.23857625,4 12,4 Z M12,6 C10.3431458,6 9,7.34314575 9,9 C9,10.6568542 10.3431458,12 12,12 C13.6568542,12 15,10.6568542 15,9 C15,7.34314575 13.6568542,6 12,6 Z" fill="#FFFFFF" fillRule="nonzero"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                )}
                <TextField
                  {...params}
                  className={`input input--default ${hasValueClass}`}
                  placeholder={t`Not selected`}
                  variant="outlined"
                />
              </div>
            );
          }}
          value={selectedData.user}
        />
      </Box>
    </Box>
  );
}
