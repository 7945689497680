import { UserRole } from "enums";

const delegatedAccessRoles = [
  UserRole.DELEGATE,
  UserRole.EXECUTIVE_ASSISTANT,
  UserRole.LOCAL_ADMIN,
  UserRole.SUPER_ADMIN,
];

export const isDelegatedAccessRole = (role?: UserRole) => role && delegatedAccessRoles.includes(role);
