import Api from 'Store/Services/Api';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CONFIRM_APPROVE_BOOKING,
  CONFIRM_APPROVE_BOOKING_FAILURE,
  CONFIRM_APPROVE_BOOKING_SUCCESS,
  CONFIRM_OR_DENY_BOOKING,
  CONFIRM_OR_DENY_BOOKING_FAILURE,
  CONFIRM_OR_DENY_BOOKING_SUCCESS,
  ConfirmApproveBooking,
  ConfirmApproveBookingSuccess,
} from '.';

function* confirmApproverEmailSaga(action: ConfirmApproveBooking): any {
  try {
    const payload: ConfirmApproveBookingSuccess = yield call(Api, action, true);

    yield put({ type: CONFIRM_APPROVE_BOOKING_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CONFIRM_APPROVE_BOOKING_FAILURE, payload: e });
  }
}

function* confirmOrDenyBookingSaga(action: ConfirmApproveBooking): any {
  try {
    const payload: ConfirmApproveBookingSuccess = yield call(Api, action, true);

    yield put({ type: CONFIRM_OR_DENY_BOOKING_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CONFIRM_OR_DENY_BOOKING_FAILURE, payload: e });
  }
}

export default [
  takeLatest(CONFIRM_APPROVE_BOOKING, confirmApproverEmailSaga),
  takeLatest(CONFIRM_OR_DENY_BOOKING, confirmOrDenyBookingSaga),
];
