import L from 'leaflet';

/**
 * Gets a height, width and a limit size, and returns the proportion that both sizes
 * must have to achieve that limit size
 * 
 * Ex: sizeLimit = 100; height = 1000; width = 50.
 * Results in height and width should be 10% of it's size, so that height, the higher
 * value, gets the maximum limit of 100 and that width shrinks proportionally to 5.
 */
export function getImageSizeProportion({
  height,
  sizeLimit,
  width,
}: {
  height: number;
  sizeLimit: number;
  width: number
}): number {
  const isWidthBigger = width > height;
  let proportion = 0;

  if (height > sizeLimit || width > sizeLimit) {
    if (isWidthBigger) {
      proportion = eval(`(${width} - ${sizeLimit}) * 100 / ${width}`);
    } else {
      proportion = eval(`(${height} - ${sizeLimit}) * 100 / ${height}`);
    }
  }

  return 100 - proportion;
}

/**
 * Receives an image height and size with an already calculated proportion
 * that it must have, and returns a Latitude/Longitude expression to create
 * that image into a square starting from point 0,0.
 */
export function getImageBoundsLimited({
  height,
  width,
  proportion,
}: {
  height: number;
  width: number
  proportion: number;
}): L.LatLngBoundsExpression {
  const limitedHeight = height * proportion / 100;
  const limitedWidth = width * proportion / 100;

  const latlng = L.CRS.Simple.pointToLatLng(L.point(-limitedHeight, -limitedWidth), 0);

  return [[latlng.lng, 0], [0, latlng.lat]];
}

/**
 * Receveis a point in pixels with x and y coordinates, and returns in the form of
 * latitude and longitude.
 * Mode graph will consider y positive as going up. Ex: y: 10 means 10px from bottom
 * Mode web will consider y positive as going down. Ex: y: 10 means 10px from top
 * Proportion will calculate the relative position based on it. Ex: y: 10 with a proportion of 10%, will be y: 1.
 */
export function getPointInLatLng({
  y,
  x,
  proportion = 100,
}: {
  y: number,
  x: number,
  proportion?: number,
}): L.LatLng {
  y = y * proportion / 100;
  x = x * proportion / 100;

  const latlng = L.CRS.Simple.pointToLatLng(L.point(x, y), 0);

  return latlng;
}

/**
 * Receives an image height and width
 * calculates min zoom that means that image fit 100% of block in this zoom ratio
 */
interface MapMinZoomValueProps {
  imageSize: {
    height: number;
    width: number;
  },
  containerSize: {
    height: number;
    width: number;
  },
}

export function getMapMinZoomValue(props: MapMinZoomValueProps) {
  const { imageSize, containerSize } = props;
  // get proportion of sides if imageSize > containerSize, 0 is the default lib value
  const heightRatio = imageSize.height > containerSize.height ?
    imageSize.height / containerSize.height : 0;
  const widthRatio = imageSize.width > containerSize.width ?
    imageSize.width / containerSize.width : 0;

  /**
   * reduced scale is achieved by negative values, so need to convert the number to negative
   * minZoom -1 means 2 times smaller map, -2 means 4 times, so need to divide by 2
   */

  return Math.max(heightRatio, widthRatio) / -2;
}