import L from "leaflet";
import { Popup, useMap } from "react-leaflet";
import { FloorMapObjectTypeEnum, MapDrawingGroup } from "../../../../../../Store/floorMapDuck/models";
import { MEETING_COLOR } from "../../../Helpers/consts";
import styles from "../../MapDrawingSections/CreateSection/styles.module.scss";
import Box from "../../../../../../../Components/Box";
import Text from "../../../../../../../Components/Text";
import { Trans } from "@lingui/macro";
import Button from "../../../../../../../Components/Button";
import MeetingSearching from "./MeetingSearching";

interface Props {
  center: L.LatLngExpression;
  popupOpened: boolean;
  meetingData: MapDrawingGroup;
  setCenter: (center: L.LatLngExpression) => void;
  setDrawMeetingActive: (active: boolean) => void;
  setMeetingData: (meetingData: MapDrawingGroup) => void;
  setPopupOpened: (popupOpened: boolean) => void;
}

/**
 * Popup to create meeting.
 * When the map is clicked on an empty area, this popup will open to fill in section name
 * and start drawing the section.
 */
export default function CreateMeeting({
  center,
  popupOpened,
  meetingData,
  setDrawMeetingActive,
  setPopupOpened,
  setMeetingData,
}: Props) {
  const map = useMap();

  // @ts-ignore Typescript is not recognizing extension. Argument of type 'Map' is not assignable to parameter of type 'DrawMap'
  const rectangleDrawer = new L.Draw.Polygon(map);
  rectangleDrawer.setOptions({
    shapeOptions: {
      // In the lack of a better way to pass data do draw layer, we're using the attribution field with a JSON string
      attribution: JSON.stringify({ meetingData, type: FloorMapObjectTypeEnum.Meeting }),
      color: MEETING_COLOR,
    },
    showArea: false,
  });

  const handleClosePopup = () => {
    setPopupOpened(false);
  };

  // Closes popup, set draw state to active and enable rectangle draw
  const handleConfirmAddMeeting = () => {
    setPopupOpened(false);
    setDrawMeetingActive(true);

    rectangleDrawer.enable();
  };

  return (
    <>
      {popupOpened && (
        <Popup
          className={styles.createSectionContainer}
          closeButton={false}
          minWidth={320}
          position={center}
        >
          <Box>
            <Text weight="semi-bold">
              <Trans>Add room</Trans>
            </Text>

            <Box marginTop={5}>
              <Box display="flex" justifyContent="between">
                <Box direction="column" display="flex" width="100%">
                  <div className={styles.requiredField}>
                    <span className={styles.requiredSign}>*</span>
                    <label htmlFor="meeting-name"><Trans>Select room to add</Trans></label>
                  </div>

                </Box>
              </Box>
                  <MeetingSearching meetingData={meetingData} setMeetingData={setMeetingData}/>
            </Box>

            <Box
              alignItems="center"
              display="flex"
              justifyContent="end"
              marginTop={15}
            >
              <Button
                onClick={handleClosePopup}
                size="sm"
                type="clear"
                withShadow={false}
              >
                <Trans>Cancel</Trans>
              </Button>

              <Button
                buttonType="submit"
                disabled={!meetingData}
                onClick={handleConfirmAddMeeting}
                size="sm"
              >
                <Trans>Confirm</Trans>
              </Button>
            </Box>
          </Box>
        </Popup>
      )}
    </>
  );
}