import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useTypedSelector} from "../../../Store/Redux/store";
import styles from './styles.module.scss';
import Box from '../../../Components/Box';
import Container from '../../../Components/Container';
import UserProfileHeader from './Header';
import ProfileUserInfo from './ProfileUserInfo';
import ProfileDocumentsTable from "./ProfileDocumentsTable";
import CustomPagination from "../../../Components/CustomPagination";
import {getProfile} from "../../Store/Users/profileDuck";
import {getCertificatesByUser} from "../../Store/CovidSertificate";
import {
  ErrorUploadCovidFileSnackbar,
  SuccessUploadCovidFileSnackbar,
} from "../../../Components/UploadCovidFile/SnackBars";

export default function UserProfile() {
  const dispatch = useDispatch();
  const { profile, covidCertificate, executiveAssistant } = useTypedSelector(state => state);
  const { roleAccess, id: profileId } = profile;
  const { selectedUser } = executiveAssistant;
  const { totalUserDocumentsPages } = covidCertificate;

  const selectedUserId = roleAccess.executiveAssistant && selectedUser?.id ? selectedUser?.id : profileId;

  const onChangePage = (page: number) => {
    dispatch(getCertificatesByUser({ userId: selectedUserId, page }));
  };

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    onChangePage(1);
  }, [selectedUserId]);

  return (
    <Container>
      <SuccessUploadCovidFileSnackbar />
      <ErrorUploadCovidFileSnackbar />

      <UserProfileHeader />

      <Box dataTestId="user-profile-content" display="flex" justifyContent="between" marginTop={20}>
        <Box dataTestId="user-profile-info" marginRight={30} width={270}>
          <ProfileUserInfo />
        </Box>
        <div className={styles.profileDocumentsWrapper} data-testid="user-profile-documents">
          <ProfileDocumentsTable />
          <Box dataTestId="user-profile-pagination" padding="50px 0">
            {
              totalUserDocumentsPages ?
                <CustomPagination count={totalUserDocumentsPages} onChange={onChangePage} /> : null
            }
          </Box>
        </div>
      </Box>
    </Container>
  );
}
