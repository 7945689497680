import axios, { AxiosRequestConfig, ResponseType } from 'axios';
import qs from 'qs';
import store from 'Store/Redux/store';
import { Action } from 'Store/Models/ReduxModels';
import { ApiUrls, getApiUrl } from 'Store/Models/ConfigModels';

export type UrlApis = '' | 'development' | 'local' | 'postman' | 'viacep' | 'paypal';

function getClient(client: UrlApis, isUploadFile: boolean | undefined) {
  let baseURL = '';
  const responseType: ResponseType = isUploadFile ? 'blob' : 'json';

  switch (client) {
    case 'development':
      baseURL = ApiUrls.development;
      break;

    case 'local': {
      baseURL = 'http://192.168.0.14:3000';
      break;
    }

    default: {
      baseURL = getApiUrl();
      break;
    }
  }

  return axios.create({
    baseURL,
    responseType,
  });
}

async function Api(action: Action<any>, noAuth?: boolean, useGraphApi?: boolean) {
  const state = store.getState();
  const apiClient: UrlApis = action.payload.client || '';
  const { data, isUploadFile, method, url } = action.payload.request;

  const client = getClient(apiClient, isUploadFile);

  client.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const { response } = error;

      return Promise.reject(response?.data || response || error);
    },
  );

  let config: AxiosRequestConfig = {};

  // If it needs authorization, include bearer token to headers
  if (!noAuth) {
    const { token, tokenGraphApi } = state.login;
    const headers = {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    if (useGraphApi) {
      // @ts-ignore ts not corresponding this prop with axios, its correct
      headers['graph-token'] = tokenGraphApi;
    }

    config = {
      headers,
    };
  }

  if (method === 'POST') {
    return client.post(url, data, config);
  } else if (method === 'PUT') {
    return client.put(url, data, config);
  } else if (method === 'PATCH') {
    return client.patch(url, data, config);
  } else if (method === 'DELETE') {
    let deleteConfig = config;
    // set data to config due to axios method interface (do not support data param as POST)
    if (data) {
      deleteConfig = {
        ...config,
        data,
      };
    }
    return client.delete(url, deleteConfig);
  } else {
    return client.get(url, {
      params: {
        ...data,
      },
      paramsSerializer: params => qs.stringify(params),
      ...config,
    });
  }
}

export default Api;
