import React, { useState } from "react";
import { ReservationDetailsDialogProps } from "./types";
import { Box, Button, Dialog, Divider, Typography, darken, useTheme } from "@mui/material";
import { IconBox } from "../box";
import { TogetherFloor, TogetherRoom, TogetherUser } from "../icons";
import { t } from "@lingui/macro";
import { Link } from "react-router-dom";
import { BasicChip } from "../chip";
import { LocationOnOutlined } from "@mui/icons-material";
import { formatInTimeZone } from "date-fns-tz";
import { ConfirmationDialog } from "../dialog";
import { useToast } from "../toast-provider";
import { isSuccessAPIResponse, useCancelMyReservationMutation } from "store";
import { useExceptionTracker } from "hooks";

export const ReservationDetailsDialog: React.FC<ReservationDetailsDialogProps> = (props) => {
  const { Trigger, reservation, modifiable } = props;
  const { palette } = useTheme();
  const toast = useToast();
  const trackException = useExceptionTracker();
  const [isOpen, setIsOpen] = useState(false);
  const [cancelMyReservation, { isLoading: isCancelingReservation }] = useCancelMyReservationMutation();
  const { id, title, resourceName, floorName, location, additionalTags, attendees, schedule } = reservation;

  const handleTriggerClick = () => {
    if (!isCancelingReservation) {
      setIsOpen(true);
    }
  };

  const handleCancelClick = () => {
    setIsOpen(false);
    
    void (async () => {
      const response = await cancelMyReservation(id);

      if (isSuccessAPIResponse(response)) {
        toast.showToast({ severity: "success", message: t`Your reservation was canceled` });
      } else {
        toast.showToast({ severity: "error", message: t`There was an error while canceling your reservation` });
        trackException(response.error, { endpointName: cancelMyReservation.name });
      }
    })();
  };

  const formattedDateTime = [
    formatInTimeZone(new Date(schedule.startDate), schedule.timeZone, "MMM d, h:mma"),
    formatInTimeZone(new Date(schedule.endDate), schedule.timeZone, "h:mma OOOO"),
  ].join(" - ");

  return (
    <>
      <Trigger onClick={handleTriggerClick} />
      <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
        <Box p={2} width={480}>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Box alignItems="center" display="flex">
              <IconBox mr={1}><TogetherRoom stroke={palette.grey[700]} sx={{ height: 14, width: 14 }}/></IconBox>
              <Typography fontWeight="600" maxWidth={240} noWrap title={title}>{title}</Typography>
            </Box>
            {modifiable ? <Button component={Link} to={`/reservations/${id}/edit`}>{t`Edit meeting`}</Button> : undefined}
          </Box>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Box mb={2}>
            <Typography fontWeight="600" mb={1}>{t`Details`}</Typography>
            <Box display="flex" mb={1}>
              <BasicChip>
                <TogetherFloor fill={palette.grey[700]} sx={{ height: 14, width: 14 }} />
                <Typography color={palette.grey[700]} fontSize={14} ml={1}>{floorName}</Typography>
              </BasicChip>
              <BasicChip ml={2}>
                <TogetherRoom stroke={palette.grey[700]} sx={{ height: 14, width: 14 }} />
                <Typography color={palette.grey[700]} fontSize={14} ml={1}>{resourceName}</Typography>
              </BasicChip>
            </Box>
            <Box display="flex">
              <LocationOnOutlined fontSize="small" sx={{ color: palette.grey[700] }} />
              <Typography color={palette.grey[700]} fontSize={14} ml={1}>{location.name}. {location.address}</Typography>
            </Box>
          </Box>
          {additionalTags?.length ? (
            <Box mb={2}>
              <Typography fontWeight="600" mb={1}>{t`Additional`}</Typography>
              <Box display="flex" mb={1}>
                {additionalTags.map((tag, index) => (
                  <BasicChip key={tag} ml={index > 0 ? 1 : 0}>
                    <Typography color={palette.grey[700]} fontSize={14}>{tag}</Typography>
                  </BasicChip>
                ))}
              </Box>
            </Box>
          ) : undefined}
          {attendees?.length ? (
            <Box mb={2}>
              <Divider sx={{ mt: 1, mb: 2 }} />
              <Box alignItems="center" display="flex" mb={1}>
                <IconBox mr={1}><TogetherUser fill={palette.grey[700]} sx={{ height: 14, width: 14 }}/></IconBox>
                <Typography fontWeight="600">{t`Attendees`}: {attendees.length}</Typography>
              </Box>
              <Box maxHeight={220} sx={{ overflowY: "auto", overflowX: "hidden" }}>
                {attendees.map(({ name, email }) => (
                  <Box alignItems="center" display="flex" height={44} key={email}>
                    <Box
                      alignItems="center"
                      bgcolor={({ palette }) => !name ? palette.grey[400] : palette.primary.main}
                      borderRadius={2}
                      display="flex"
                      height={30}
                      justifyContent="center"
                      marginRight={1}
                      width={30}
                    >
                      <TogetherUser fill="#fff" sx={{ width: 14 }} />
                    </Box>
                    <Box maxWidth={280}>
                      <Typography fontSize={14} fontWeight="600" noWrap>{name || t`Invited user`}</Typography>
                      <Typography color={({ palette }) => palette.grey[700]} fontSize={12} noWrap>{email}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          ) : undefined}
          <Box alignItems="center" bgcolor={palette.primary.main} borderRadius={2} display="flex" justifyContent="space-between" p={2}>
            <Box>
              <Typography color="#fff" fontSize={14}>{t`Date and time`}</Typography>
              <Typography color="#fff" fontSize={14} maxWidth={modifiable ? 240 : undefined} noWrap={modifiable} title={formattedDateTime}>
                {formattedDateTime}
              </Typography>
            </Box>
            {modifiable ? (
              <ConfirmationDialog
                Trigger={({ onClick }) => (
                  <Button onClick={onClick} sx={{ bgcolor: "#fff", ":hover": { bgcolor: darken("#fff", 0.06) } }}>
                    {t`Cancel meeting`}
                  </Button>
                )}
                description={t`Are you sure you want to cancel this meeting room reservation?`}
                onConfirm={handleCancelClick}
                title={t`Cancel meeting`}
              />
            ) : undefined}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
