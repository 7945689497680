import React from "react";
import { Route, Switch } from "react-router-dom";
import { ReservationLocationsRoute } from "./reservation-locations";
import { ReservationLocationFloorsRoute } from "./reservation-location-floors";
import { ReservationLocationFloorRoomsRoute } from "./reservation-location-floor-rooms";
import { PageContainer, PageHeader, ReservationsWrapper } from "components";
import { t } from "@lingui/macro";
import { ReservationLocationFloorRoomsCalendarViewRoute } from "./reservation-location-floor-rooms-calendar-view";

export const ReservationsRoute: React.FC = () => {
  return (
    <PageContainer>
      <PageHeader href="/" title={t`Meeting room reservation`} />
      <ReservationsWrapper>
        <Switch>
          <Route exact path="/reservations/locations" render={() => <ReservationLocationsRoute />} />
          <Route exact path="/reservations/locations/:locationId/floors" render={() => <ReservationLocationFloorsRoute />} />
          <Route exact path="/reservations/locations/:locationId/floors/:floorId/rooms" render={() => <ReservationLocationFloorRoomsRoute />} />
          <Route
            exact
            path="/reservations/locations/:locationId/floors/:floorId/rooms/calendar-view"
            render={() => <ReservationLocationFloorRoomsCalendarViewRoute />}
          />
        </Switch>
      </ReservationsWrapper>
    </PageContainer>
  );
};
