import Box from 'Components/Box';
import Button from '../../../../../Components/Button';
import CancelReservationModal from './CancelReservationModal';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grow from '@material-ui/core/Grow';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ResetAllModal from './ResetAllModal';
import styles from './styles.module.scss';
import Switch from '@material-ui/core/Switch';
import Text from '../../../../../Components/Text';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'Store/Redux/store';
import 'react-day-picker/lib/style.css';

import DayPicker,
{
  CaptionElementProps,
  DayModifiers,
  WeekdayElementProps,
}
  from 'react-day-picker';

import {
  editCalendarDates,
  resetSelectedWeekDays,
} from '../../../../Store/calendar';
import {
  compareDateFormat,
  compensateTimezoneOffset,
  getUpdatedSingleDay,
  reservationExist,
} from '../Helpers/Helpers';
import {EditCalendarSingleDaysModel} from "../../../../Store/calendar/models";

// customize calendar elements
function Caption(props: CaptionElementProps) {
  const monthName = moment(props.date).format('MMMM');
  return (
    <div className={props.classNames.caption}>
      <div>
        {monthName}
      </div>
    </div>
  );
}

function Weekday(props: WeekdayElementProps) {
  const weekdayName = props.localeUtils.formatWeekdayLong(props.weekday, props.locale);
  return (
    <div className={props.className} title={weekdayName}>
      {weekdayName.slice(0, 3)}
    </div>
  );
}

function getPopupDate(selectionDate: Date | undefined) {
  return moment(selectionDate).format('MMMM, DD');
}

export default function MonthlyBooking() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const { adminCalendar, config } = useTypedSelector(state => state);
  const { calendarDates, numberOfMonths } = adminCalendar;

  const disabledDates = calendarDates.disabledDates.map(date => new Date(date));
  const reservedDates = calendarDates.reservedDates.map(date => new Date(date));

  const [reservationModalOpen, setReservationModalOpen] = useState(false); // available confirmation popup state
  const closeReservationModal = () => setReservationModalOpen(false); // available confirmation popup state
  const [resetModalOpen, setResetModalOpen] = useState(false); // reset all confirmation popup state
  const closeResetModal = () => setResetModalOpen(false); // reset all confirmation popup state

  const initDate = undefined; // user for selection - if user click on any day

  const [tempUpdatedDates, setTempUpdatedDates] = useState<EditCalendarSingleDaysModel>(); // temp data user for confirmation available popup
  const [tempSelectionDate, setTempSelectionDate] = useState<string>(compareDateFormat(new Date())); // temp data user for confirmation available popup
  const [tempIsAvailableCurrentDay, setTempIsAvailableCurrentDay] = useState<boolean>(true); // temp data user for confirmation available popup

  const [isAvailableCurrentDay, setIsAvailableCurrentDay] = useState<boolean>(true);
  const [selectionDate, setSelectionDate] = useState<Date | undefined>(initDate);
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedDays = selectionDate && anchorEl ? selectionDate : [];
  // set unavailable days (need to prevent cancelling previous and today dates)
  const modifiers = {
    unavailable: [
      {
        from: new Date(moment().startOf('month').format()), // first day of month
        to: new Date(),
      },
    ],
  };
  const modifiersStyles = {
    unavailable: {
      background: "#f8f8f8",
      color: "#aeb9bf",
      cursor: 'not-allowed',
    },
  };

  const handleDayClick = (day: Date, modifiers: DayModifiers, e: any) => {
    if (modifiers.unavailable) { // prevent click to unavailable day
      return;
    }

    const availableDay = !modifiers.disabled; // get available for booking this day
    const date = modifiers.selected ? undefined : day; // select \ unselect day

    setIsAvailableCurrentDay(availableDay);
    setSelectionDate(date);
    setAnchorEl(e.currentTarget);
  };

  const onClearSelection = () => {
    setSelectionDate(initDate);
    dispatch(editCalendarDates({
      resetAll: true,
      locationId,
    }));

    dispatch(resetSelectedWeekDays());
  };

  const onUpdateDisabledDays = (event: any, selectionDate: Date | string) => {
    const isAvailable = event.target.checked;
    const updatedDays: EditCalendarSingleDaysModel = getUpdatedSingleDay(isAvailable, selectionDate);
    
    if (reservationExist(reservedDates, selectionDate) && !isAvailable) { // if date has booking and the date is enabled
      setReservationModalOpen(o => !o);
      // data for cancel/save reservation modal
      setTempIsAvailableCurrentDay(isAvailable);
      setTempUpdatedDates(updatedDays);
      setTempSelectionDate(compareDateFormat(selectionDate));
    } else {
      setIsAvailableCurrentDay(isAvailable);
      dispatch(editCalendarDates({
        ...updatedDays,
        locationId,
      }));
    }
  };

  const onDisableDayWithBooking = (cancelExistingReservations: boolean) => {
    const daysToCancelReservations: string[] = cancelExistingReservations ? [tempSelectionDate] : [];
    setIsAvailableCurrentDay(tempIsAvailableCurrentDay);

    dispatch(editCalendarDates({
      ...tempUpdatedDates,
      locationId,
      daysToCancelReservations,
    }));
  };

  const handleCloseDisableDate = () => {
    setAnchorEl(null);
  };

  const handleOutsideCloseDisableDate = (e: any) => {
    const targetClasses = e.target.classList;
    // close outside if click on document, keep popup if click on another day
    if (!targetClasses.contains('DayPicker-Day') || targetClasses.contains('DayPicker-Day--outside')) {
      handleCloseDisableDate();
    }
  };

  const currentYear = moment().format('YYYY');

  return (
    <Box dataTestId="admin-calendar-monthly">
      <Box marginBottom={15}>
        <Text size="lg" weight="semi-bold">
          <Trans>Blackout days</Trans>
        </Text>
        <Box alignItems="center" className={styles.yearLine} display="flex" justifyContent="between">
          {currentYear}
          <Button data-testid="admin-calendar-reset-all" onClick={() => setResetModalOpen(true)} size="sm" type="clear">Reset All</Button>
        </Box>
      </Box>
      {
        selectionDate ?
          <Popper
            anchorEl={anchorEl}
            className={styles.availablePopupWrapper}
            disablePortal
            open={Boolean(anchorEl)}
            placement='bottom-start'
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'bottom left' : 'top left',
                  margin: placement === 'bottom' ? '0 0 5px 0' : '5px 0 0 0',
                }}
              >
                <Paper className={styles.availablePopupAnimate}>
                  <ClickAwayListener onClickAway={handleOutsideCloseDisableDate}>
                    <div className={styles.availablePopupContent} data-testid="admin-calendar-toggle-day-modal">
                      <Box display="flex" justifyContent="between" marginBottom={7}>
                        <div className={styles.availablePopupDate}>{getPopupDate(selectionDate)}</div>
                        <svg className={styles.availablePopupClose} height="25px" onClick={handleCloseDisableDate} viewBox="0 0 30 30" width="25px">
                          <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
                            <g id="Booking-savedall" transform="translate(-875.000000, -132.000000)">
                              <g id="Group-8" transform="translate(515.000000, 112.000000)">
                                <g id="icons/close" transform="translate(360.000000, 20.000000)">
                                  <rect fill={config.theme.primaryLight} height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                                  <path d="M20.704633,9.29536704 C21.0984557,9.68918977 21.0984557,10.3277026 20.704633,10.7215253 L16.4261582,15 L20.704633,19.2784747 C21.0984557,19.6722974 21.0984557,20.3108102 20.704633,20.704633 C20.3108102,21.0984557 19.6722974,21.0984557 19.2784747,20.704633 L15,16.4261582 L10.7215253,20.704633 C10.3277026,21.0984557 9.68918977,21.0984557 9.29536704,20.704633 C8.90154432,20.3108102 8.90154432,19.6722974 9.29536704,19.2784747 L13.5738418,15 L9.29536704,10.7215253 C8.90154432,10.3277026 8.90154432,9.68918977 9.29536704,9.29536704 C9.68918977,8.90154432 10.3277026,8.90154432 10.7215253,9.29536704 L15,13.5738418 L19.2784747,9.29536704 C19.6722974,8.90154432 20.3108102,8.90154432 20.704633,9.29536704 Z" fill={config.theme.primary}></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </Box>
                      <Box alignItems="center" className={styles.availablePopupSwitch} display="flex">
                        <span>Available for booking</span>
                        <FormControlLabel
                          className={styles.availablePopupSwitchLabel}
                          control={
                            <Switch
                              checked={isAvailableCurrentDay}
                              className="switcher switcher--default switcher--thin"
                              data-testid="admin-calendar-toggle-day-switcher"
                              name={''}
                              onChange={(e) => onUpdateDisabledDays(e, selectionDate)}
                            />
                          }
                          label=""
                        />
                      </Box>
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper> : null
      }
      <div>
      </div>
      <DayPicker
        canChangeMonth={false} // disable navigation
        captionElement={(props) => <Caption {...props} />}
        className="admin-calendar"
        // disabledDays work incorrectly with timezones inside lib - need to add offset manually
        disabledDays={disabledDates.map(d => compensateTimezoneOffset(d))}
        initialMonth={new Date()}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        numberOfMonths={numberOfMonths}
        onDayClick={handleDayClick}
        selectedDays={selectedDays}
        weekdayElement={(props) => <Weekday {...props} />}
      />
      <ResetAllModal
        close={closeResetModal}
        onUpdate={() => onClearSelection()}
        open={resetModalOpen}
      />
      <CancelReservationModal
        close={closeReservationModal}
        onUpdate={onDisableDayWithBooking}
        open={reservationModalOpen}
      />
    </Box>
  );
}
