import { Calendar } from "react-date-range";
import React from "react";
import { DatePickerCalendarProps } from "./types";

export const DatePickerCalendar: React.FC<DatePickerCalendarProps> = (props) => {
  const { date, onChange, minDate, maxDate } = props;

  return (
    <Calendar
      className="custom-calendar"
      date={date}
      maxDate={maxDate}
      minDate={minDate}
      onChange={onChange}
      showMonthAndYearPickers={false}
    />
  );
};
