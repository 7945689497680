export interface AttributeModel {
  id: string;
  locationId: string;
  name: string;
  isAvailable: boolean;
}

export enum AttributeTypeEnum {
  DESK = 'desk',
  ROOM = 'room',
}
export type AttributeType = AttributeTypeEnum.DESK | AttributeTypeEnum.ROOM

export interface GetAttributesRequest {
  locationId: string;
}

export interface GetAttributesResponse {
  result: {
    statusCode: number;
    data: {
      deskResources: AttributeModel[];
      roomResources: AttributeModel[];
    }
  }
}

export interface EditAttributeModel {
  isAvailable: boolean;
  type: AttributeType;
}

export interface EditAttributeRequest {
  attribute: EditAttributeModel;
  resourceId: string;
}

export interface EditAttributeResponse {
  result: {
    statusCode: number;
    data: AttributeModel
  }
}
