import { booking } from '../Bookings/bookingDuck/fixtures';
import {
  GetQuestionnaireBookingByIdResponse,
  GetQuestionnaireBookingsResponse,
  QuestionnaireModel,
} from './models';

export const getQuestionnaireBookingsSuccess: GetQuestionnaireBookingsResponse = {
  result: {
    statusCode: 200,
    data: {
      bookings: [],
      appointments: [],
    },
  },
};

export const getQuestionnaireBookingByIdSuccess: GetQuestionnaireBookingByIdResponse = {
  result: {
    data: booking,
    statusCode: 200,
  },
};

export const getQuestionQuestionnaireSuccess: { result: { data: QuestionnaireModel[] } } = {
  result: {
    data: [
      {
        id: 'question-1',
        isPositive: false,
        locationId: booking.location.id,
        questionDetails: 'Mocked Question Details 1 <a href="/">test link</a>',
        questionText: 'Mocked Question text 1 (false)',
      },
      {
        id: 'question-2',
        isPositive: false,
        locationId: booking.location.id,
        questionDetails: 'Mocked Question Details 2',
        questionText: 'Mocked Question text 2 (false)',
      },
      {
        id: 'question-3',
        isPositive: true,
        locationId: booking.location.id,
        questionDetails: 'Mocked Question Details 3',
        questionText: 'Mocked Question text 3 (true)',
      },
    ],
  },
};

export const getQuestionQuestionnaireSuccessEmpty: { result: { data: QuestionnaireModel[] } } = {
  result: {
    data: [],
  },
};
