import React, { useEffect } from "react";
import { useTypedSelector } from "../../../../../Store/Redux/store";
import { useDispatch } from "react-redux";
import { t } from "@lingui/macro";
import Popup from "reactjs-popup";
import styles from "../../../Locations/LocationForm/styles.module.scss";

import Box from "../../../../../Components/Box";
import Text from "../../../../../Components/Text";
import Tabs from "./Tabs";
import Name from "./FormFields/Name";
import AdEmail from "./FormFields/AdEmail";
import Locations from "./FormFields/Locations";
import ActionButtons from "./ActionButtons";
import { IGroup } from "../../../../Store/groups/models";
import {
  ActionState,
  GroupCreateEditFormActionStateEnum,
  GroupCreateEditFormTabEnum,
} from "../../../../Store/groups/forms/createEditForm/models";
import {
  initialFormErrors,
  setGroupsCreateEditFormData,
} from "../../../../Store/groups/forms/createEditForm";
import ActiveDirectory from "../../../../../Components/Icons/ActiveDirectory";

interface Props {
  actionState: ActionState;
  open: boolean;
  onCancelAction: () => void;
  group?: IGroup; // data used for edit state
}

export default function CreateEditGroupModal(props: Props) {
  const dispatch = useDispatch();
  const { config, groupCreateEditForm } = useTypedSelector(state => state);
  const { tabsState } = groupCreateEditForm;
  const {
    group,
    actionState,
    open,
    onCancelAction,
  } = props;

  const isActiveDirectory = tabsState === GroupCreateEditFormTabEnum.activeDirectory;
  const isAddState = actionState === GroupCreateEditFormActionStateEnum.add;
  const isEditState = actionState === GroupCreateEditFormActionStateEnum.edit;

  const isActiveAdGroup = isActiveDirectory || (isEditState && group?.adGroup);
  const actionTypeName = isAddState ? t`Add` : t`Edit`;

  const setDefaultPropsData = () => {
    dispatch(setGroupsCreateEditFormData({
      tabsState: GroupCreateEditFormTabEnum.newGroup,
      formErrors: initialFormErrors,
      // set from props
      actionState,
      group,
    }));
  };

  // set data if modal is open
  useEffect(() => {
    if (open) {
      setDefaultPropsData();
    }
  }, [open]);

  return (
    <Popup
      className={'modal'}
      closeOnDocumentClick
      contentStyle={{ width: 410 }}
      nested
      onClose={onCancelAction}
      open={open}
    >
      <div className="modal-inner">
        <div className="modal-header">
          <h2>
            {actionTypeName} group
          </h2>

          <svg className="modal-header__close" height="30px" onClick={() => onCancelAction()} viewBox="0 0 30 30" width="30px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g id="Booking-savedall" transform="translate(-875.000000, -132.000000)">
                <g id="Group-8" transform="translate(515.000000, 112.000000)">
                  <g id="icons/close" transform="translate(360.000000, 20.000000)">
                    <rect fill={config.theme.primaryLight} height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                    <path d="M20.704633,9.29536704 C21.0984557,9.68918977 21.0984557,10.3277026 20.704633,10.7215253 L16.4261582,15 L20.704633,19.2784747 C21.0984557,19.6722974 21.0984557,20.3108102 20.704633,20.704633 C20.3108102,21.0984557 19.6722974,21.0984557 19.2784747,20.704633 L15,16.4261582 L10.7215253,20.704633 C10.3277026,21.0984557 9.68918977,21.0984557 9.29536704,20.704633 C8.90154432,20.3108102 8.90154432,19.6722974 9.29536704,19.2784747 L13.5738418,15 L9.29536704,10.7215253 C8.90154432,10.3277026 8.90154432,9.68918977 9.29536704,9.29536704 C9.68918977,8.90154432 10.3277026,8.90154432 10.7215253,9.29536704 L15,13.5738418 L19.2784747,9.29536704 C19.6722974,8.90154432 20.3108102,8.90154432 20.704633,9.29536704 Z" fill={config.theme.primary}></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={`modal-inner-content ${styles.modalInnerContent}`}>

          {/* show tabs only for add new group */}
          {isAddState &&
            <Box marginBottom={15}>
              <Tabs />
            </Box>
          }

          {isActiveAdGroup &&
            <Box alignItems="baseline" display="flex" marginBottom={15}>
              <Box alignItems="center" display="flex" marginRight={5}>
                <ActiveDirectory/>
              </Box>
              <Text color="lightGray" size="md" weight="regular">
                Active directory allow to fetch all existing users automatically
              </Text>
            </Box>
          }

          {/* don't display name for add (for edit is ok) AD group, name is taken from AD */}
          {!(isActiveDirectory && isAddState) &&
            <Box marginBottom={15}>
              <Name />
            </Box>
          }
          {/*  display AD email only for add group from AD */}
          {isActiveAdGroup &&
            <Box marginBottom={15}>
              <AdEmail />
            </Box>
          }
          <Box marginBottom={15}>
            <Locations />
          </Box>

          <ActionButtons onCancelAction={onCancelAction} />
        </div>
      </div>
    </Popup >
  );
}
