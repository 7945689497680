import Box from 'Components/Box';
import Slider from '@material-ui/core/Slider';
import styles from './styles.module.scss';
import { useState } from 'react';
import { selectableSliderHours } from 'App/Store/Meetings/createNewMeetingDuck';

interface Props {
  hours: number[];
  step: number,
  setHours: (hours: number[]) => void;
}

export default function HourSlider({ hours, step = 0.25, setHours }: Props) {
  const [sliderHours, setSliderHours] = useState<number[]>(hours);

  const handleChange = (_: any, newValue: any) => {
    setSliderHours(newValue);    
  };

  const handleChangeCommitted = (_: any, newValue: any) => {    
    setHours(newValue);    
  };

  return (
    <Box className={styles.hourSlider}>
      <Slider
        classes={{
          mark: styles.mark,
          markActive: styles.markActive,
          markLabel: styles.markLabel,
          root: styles.slider,
          rail: styles.rail,
          thumb: styles.thumb,
          track: styles.track,
        }}
        marks={selectableSliderHours}
        max={21}
        min={6}   
        onChange={handleChange}     
        onChangeCommitted={handleChangeCommitted}
        step={step}                
        value={sliderHours}
      />
    </Box>
  );
}