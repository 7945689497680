import BookingDetails from '../BookingDetails';
import Box from 'Components/Box';
import Breadcrumbs from 'App/Components/Breadcrumbs';
import Divider from 'Components/Divider';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { CalendarSupport } from '../Calendar';
import { Trans } from '@lingui/macro';

interface DeskProps {
  type?: 'support' | 'desk';
}

export default function DeskSupport({ type = 'desk' }: DeskProps) {
  return (
    <Box display="grid" gap={30} gridTemplateColumns="8fr 4fr" paddingBottom={50}>
      <Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="between"
          marginBottom={24}
        >
          <Breadcrumbs type={type} />
        </Box>

        <Box display="grid" gap={10}>
          <Text weight="semi-bold">
            <Trans>Instructions</Trans>
          </Text>

          <ol className={styles.instructionList}>
            <li>
              <Trans>Onsite visits are for days where you need to visit the office but do not require a desk.</Trans>
            </li>

            <li>
              <Trans>Pick the time slot that best matches the time you will be in the office. If your time will overlap the 2 hour timeslots provided, please select the time slot that reflects your arrival time.</Trans>
            </li>
          </ol>
        </Box>
      </Box>

      <Box className={styles.bookingRightSide}>
        <Box marginBottom={15}>
          <Text size="xlg" weight="semi-bold">
            <Trans>Booking details</Trans>
          </Text>
        </Box>

        <CalendarSupport />

        <Box marginBottom={10} marginTop={30}>
          {/* time slots here  */}
        </Box>

        <Divider />

        <Box marginTop={10}>
          <BookingDetails type={type} />
        </Box>
      </Box>
    </Box>
  );
}
