import { GetPushNotificationsResponse, UpdatePushNotificationStatusResponse } from './models';

export const getPushNotificationsResponse: GetPushNotificationsResponse = {
  result: {
    statusCode: 200,
    data: {
      limit: 20,
      page: 1,
      totalPages: 1,
      notifications: [
        {
          id: '1',
          isWatched: true,
          message: 'Message 1',
          sendDate: '2021-09-13T13:34:12.215Z',
          subject: 'Test Message 1',
          userId: '123',
        },
        {
          id: '2',
          isWatched: true,
          message: 'Message 2',
          sendDate: '2021-09-13T13:34:12.215Z',
          subject: 'Test Message 2',
          userId: '123',
        },
        {
          id: '3',
          isWatched: false,
          message: 'Message 3',
          sendDate: '2021-09-13T13:34:12.215Z',
          subject: 'Test Message 3',
          userId: '123',
        },
      ],
    },
  },
};

export const updatePushNotificationStatusResponse: UpdatePushNotificationStatusResponse = {
  result: {
    statusCode: 200,
  },
};
