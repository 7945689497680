import { Tabs as MUITabs, styled } from "@mui/material";
import { TabsProps } from "./types";

export const Tabs = styled((props: TabsProps) => <MUITabs {...props} />)(({ theme, inline }) => ({
  backgroundColor: theme.palette.grey[100],
  display: inline ? "inline-flex" : "flex",
  padding: 4,
  borderRadius: 8,
  minHeight: 0,
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
}));
