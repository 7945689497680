import {FloorMapObject} from "../../../../Store/floorMapDuck/models";
import {useDispatch} from "react-redux";
import {updateDesk} from "../../../../Store/floorMapDuck";
import Select from "../../../../../Components/Select";
import { DESK_STATUS_TO_EDIT } from "../../../../Store/floorMapDuck/desk";

interface DeskSelectStatusProps {
  desk: FloorMapObject;
}

export default function DeskSelectStatus(props: DeskSelectStatusProps) {
  const dispatch = useDispatch();
  const { desk } = props;

  const onChangeDeskStatus = (event: any) => {
    dispatch(updateDesk({
      deskId: typeof desk.id === 'string' ? desk.id : '',
      updateDeskProperties: {
        status: event.target.value,
      },
    }));
  };

  return (
    <Select
      id="desk-status"
      items={DESK_STATUS_TO_EDIT}
      label=""
      labelColor="gray"
      onChange={onChangeDeskStatus}
      value={desk.status}
    />
  );
}
