import { GuardFunction } from "Components/ProtectedRoute/models";
import { useTypedSelector } from "Store/Redux/store";

const RoomGuard: GuardFunction = () => {
  const { selectedData } = useTypedSelector(state => state.createNewMeeting);

  const { floor } = selectedData;

  if (!floor) {
    return false;
  }
  
  return true;
};

export default RoomGuard;