import {t} from "@lingui/macro";
import TextField from "@material-ui/core/TextField";
import Popup from "reactjs-popup";
import styles from "../styles.module.scss";
import ReservationDetailsCalendar from "../ReservationDetailsCalendar";

interface ReservationJustInTimeDateProps {
  dateTime: string;
}

export default function ReservationJustInTimeDate(props: ReservationJustInTimeDateProps) {
  const { dateTime } = props;

  return (
    <Popup
      closeOnDocumentClick={true}
      closeOnEscape={false} // prop to rerender TextField after dateTime change
      key={dateTime}
      position="bottom right"
      trigger={
        <TextField
          InputProps={{
            endAdornment: (
              <svg height="20px" viewBox="0 0 20 20" width="20px">
                <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
                  <g fill="#1E1F7B" fillRule="nonzero" id="icons/datetime-blue">
                    <g id="icons/datetimewhite">
                      <path d="M14,2 C15.0543618,2 15.9181651,2.81587779 15.9945143,3.85073766 L16,4 L16.0008689,7.52856479 C17.2278632,8.62718157 18,10.2234197 18,12 C18,15.3137085 15.3137085,18 12,18 C10.2234197,18 8.62718157,17.2278632 7.52856479,16.0008689 L4,16 C2.9456382,16 2.08183488,15.1841222 2.00548574,14.1492623 L2,14 L2,4 C2,2.9456382 2.81587779,2.08183488 3.85073766,2.00548574 L4,2 L14,2 Z M12,8 C9.790861,8 8,9.790861 8,12 C8,14.209139 9.790861,16 12,16 C14.209139,16 16,14.209139 16,12 C16,9.790861 14.209139,8 12,8 Z M14,4 L4,4 L4,14 L6.34165389,14.0006859 C6.1203964,13.3749342 6,12.7015272 6,12 C6,8.6862915 8.6862915,6 12,6 C12.7015272,6 13.3749342,6.1203964 14.0006859,6.34165389 L14,4 Z M12,9 C12.5522847,9 13,9.44771525 13,10 L13,11 L14,11 C14.5522847,11 15,11.4477153 15,12 C15,12.5522847 14.5522847,13 14,13 L12,13 L12,13 C11.4477153,13 11,12.5522847 11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 Z M7,5 C7.55228475,5 8,5.44771525 8,6 C8,6.55228475 7.55228475,7 7,7 L6,7 C5.44771525,7 5,6.55228475 5,6 C5,5.44771525 5.44771525,5 6,5 L7,5 Z" id="Combined-Shape"></path>
                    </g>
                  </g>
                </g>
              </svg>
            ),
            readOnly: true,
          }}
          className={`input input--default ${styles.inputInline}`}
          defaultValue={dateTime}
          fullWidth
          placeholder={t`Not selected`}
          rows={5}
          variant="outlined"
        />
      }
    >
      {/* calendar here */}
      {(close: any) => (
        <ReservationDetailsCalendar onCloseModal={close} />
      )}
    </Popup>
  );
}
