interface Props {
  color?: string;
  size?: number;
}

export default function Monitor({ color = '#4A4A4A', size = 25 }: React.PropsWithChildren<Props>) {
  return (
    <svg
      height={size}
      version="1.1"
      viewBox="0 0 20 20"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        fill="none"
        fillRule="evenodd"
        id="A-locations"
        stroke="none"
        strokeWidth="1"
      >
        <g
          id="A-Location-dashboard2"
          transform="translate(-243.000000, -149.000000)"
        >
          <g
            id="icons/list-active"
            transform="translate(243.000000, 149.000000)"
          >
            <path
              d="M16,16 C16.5522847,16 17,16.4477153 17,17 C17,17.5522847 16.5522847,18 16,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 L16,16 Z M16,12 C16.5522847,12 17,12.4477153 17,13 C17,13.5522847 16.5522847,14 16,14 L4,14 C3.44771525,14 3,13.5522847 3,13 C3,12.4477153 3.44771525,12 4,12 L16,12 Z"
              fill={color}
              id="Combined-Shape"
            />
            <rect
              height="6"
              id="Rectangle"
              rx="2"
              stroke={color}
              strokeWidth="2"
              width="12"
              x="4"
              y="3"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
