import { DeskStatusEnum } from 'Admin/Store/floorMapDuck/desk/models';
import { AvailableDeskResponse, GetAvailableDesksResponse } from './models';

export const availableDesks: {
  [deskId: string]: AvailableDeskResponse;
} = {
  '20797476-19f6-4347-b2ef-5491f1150c7f': {
    id: '20797476-19f6-4347-b2ef-5491f1150c7f',
    name: 'Desk-6',
    section: null,
    sectionId: null,
    status: DeskStatusEnum.Available,
    floorId: 'ef033a54-58c4-4562-9066-eb2d8bfe4f71',
    owner: null,
    resources: [
      {
        id: '31676345-f85f-4241-aaff-47d41ec5e0bf',
        name: 'Dual Monitor',
        locationId: 'bf2ab97c-db32-4de9-92e3-922b82f343fa',
        isAvailable: true,
        createdAt: '2021-07-09T11:46:04.166Z',
      },
    ],
    isBooked: true,
  },
  '835595f5-4a05-4064-986d-9ee2d673bf8e': {
    id: '835595f5-4a05-4064-986d-9ee2d673bf8e',
    name: 'Desk-5',
    section: null,
    sectionId: null,
    status: DeskStatusEnum.Available,
    floorId: 'ef033a54-58c4-4562-9066-eb2d8bfe4f71',
    owner: null,
    resources: [
      {
        id: '7e28280d-bd52-4b8b-bc2c-35b73ec91e66',
        name: 'Stands',
        locationId: 'bf2ab97c-db32-4de9-92e3-922b82f343fa',
        isAvailable: true,
        createdAt: '2021-07-09T11:46:04.166Z',
      },
    ],
    isBooked: false,
  },
  '9e67c2e8-830a-47a6-b789-afaaa9366d44': {
    id: '9e67c2e8-830a-47a6-b789-afaaa9366d44',
    name: 'Desk-3',
    section: 'Section test',
    sectionId: 223,
    status: DeskStatusEnum.Available,
    floorId: 'f640dac7-2979-4f48-8648-436a6978a9ba',
    owner: null,
    resources: [
      {
        id: '3be3a123-0f50-49e8-adc9-d20fce0bdea3',
        name: 'Stands',
        locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
        isAvailable: true,
        createdAt: '2021-07-09T16:16:25.575Z',
      },
    ],
    isBooked: false,
  },
  '166cb95e-c3dd-4877-a86e-e99878a6e49d': {
    id: '166cb95e-c3dd-4877-a86e-e99878a6e49d',
    name: 'Desk-2',
    section: 'Section test',
    sectionId: 223,
    status: DeskStatusEnum.Available,
    floorId: 'f640dac7-2979-4f48-8648-436a6978a9ba',
    owner: null,
    resources: [
      {
        id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
        name: 'Dual Monitor',
        locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
        isAvailable: true,
        createdAt: '2021-07-09T16:16:25.576Z',
      },
      {
        id: '8b1622cb-6cf5-43a5-811d-305862971c73',
        name: 'Docking Station',
        locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
        isAvailable: true,
        createdAt: '2021-07-09T16:16:25.576Z',
      },
    ],
    isBooked: false,
  },
  '4db6e56f-8bca-4c96-8b2c-c216623a2152': {
    id: '4db6e56f-8bca-4c96-8b2c-c216623a2152',
    name: 'Desk-1',
    section: 'Section test',
    sectionId: 223,
    status: DeskStatusEnum.Available,
    floorId: 'f640dac7-2979-4f48-8648-436a6978a9ba',
    owner: null,
    resources: [
      {
        id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
        name: 'Dual Monitor',
        locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
        isAvailable: true,
        createdAt: '2021-07-09T16:16:25.576Z',
      },
      {
        id: '8b1622cb-6cf5-43a5-811d-305862971c73',
        name: 'Docking Station',
        locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
        isAvailable: true,
        createdAt: '2021-07-09T16:16:25.576Z',
      },
    ],
    isBooked: true,
  },
  'e87d1494-6ecd-41e2-adee-448f0657cae2': {
    id: 'e87d1494-6ecd-41e2-adee-448f0657cae2',
    name: 'Desk-4',
    section: 'Section test',
    sectionId: 223,
    status: DeskStatusEnum.Available,
    floorId: 'f640dac7-2979-4f48-8648-436a6978a9ba',
    owner: null,
    resources: [
      {
        id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
        name: 'Multimedia Workstation',
        locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
        isAvailable: true,
        createdAt: '2021-07-09T16:16:25.576Z',
      },
    ],
    isBooked: true,
  },
  '264b3088-a6cb-4e83-8c3d-40250e39452b': {
    id: '264b3088-a6cb-4e83-8c3d-40250e39452b',
    name: 'Desk-6',
    section: 'Section test',
    sectionId: 223,
    status: DeskStatusEnum.Available,
    floorId: 'f640dac7-2979-4f48-8648-436a6978a9ba',
    owner: null,
    resources: [],
    isBooked: false,
  },
  'e31475fb-1e0c-41b9-beb4-c9c4bd04d52b': {
    id: 'e31475fb-1e0c-41b9-beb4-c9c4bd04d52b',
    name: 'Desk-3',
    section: null,
    sectionId: null,
    status: DeskStatusEnum.Available,
    floorId: '172c7e14-bf25-43ad-bfad-3e35c8fb2c0d',
    owner: null,
    resources: [],
    isBooked: false,
  },
  'bb3956be-6315-4e3b-9e3d-93a12eb522d1': {
    id: 'bb3956be-6315-4e3b-9e3d-93a12eb522d1',
    name: 'Desk-1',
    section: null,
    sectionId: null,
    status: DeskStatusEnum.Available,
    floorId: '172c7e14-bf25-43ad-bfad-3e35c8fb2c0d',
    owner: null,
    resources: [],
    isBooked: true,
  },
  '1a536b0e-8c3f-4df4-9da1-a5ee30e134be': {
    id: '1a536b0e-8c3f-4df4-9da1-a5ee30e134be',
    name: 'Desk-5',
    section: 'Section test',
    sectionId: 223,
    status: DeskStatusEnum.Available,
    floorId: 'f640dac7-2979-4f48-8648-436a6978a9ba',
    owner: null,
    resources: [],
    isBooked: false,
  },
};

export const getAvailableDesksSuccess: GetAvailableDesksResponse = {
  result: {
    statusCode: 200,
    data: [
      {
        id: '9e67c2e8-830a-47a6-b789-afaaa9366d44',
        name: 'Desk-3',
        section: 'Section test',
        sectionId: 223,
        status: DeskStatusEnum.Available,
        floorId: 'f640dac7-2979-4f48-8648-436a6978a9ba',
        owner: null,
        resources: [
          {
            id: '3be3a123-0f50-49e8-adc9-d20fce0bdea3',
            name: 'Stands',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.575Z',
          },
        ],
        isBooked: false,
      },
      {
        id: '4db6e56f-8bca-4c96-8b2c-c216623a2152',
        name: 'Desk-1',
        section: 'Section test',
        sectionId: 223,
        status: DeskStatusEnum.Available,
        floorId: 'f640dac7-2979-4f48-8648-436a6978a9ba',
        owner: null,
        resources: [
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: 'e87d1494-6ecd-41e2-adee-448f0657cae2',
        name: 'Desk-4',
        section: 'Section test',
        sectionId: 223,
        status: DeskStatusEnum.Available,
        floorId: 'f640dac7-2979-4f48-8648-436a6978a9ba',
        owner: null,
        resources: [
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: '0934f222-66a0-41e4-b354-a8c08d96f005',
        name: 'Desk-6',
        section: null,
        sectionId: null,
        status: DeskStatusEnum.Available,
        floorId: 'c41076ed-6ac2-4714-96b0-77c0e4d1bef1',
        owner: null,
        resources: [
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: true,
      },
      {
        id: '86c4311f-94b5-48bc-b72e-9fbf881c4dca',
        name: 'Desk-4',
        section: null,
        sectionId: null,
        status: DeskStatusEnum.Available,
        floorId: 'c41076ed-6ac2-4714-96b0-77c0e4d1bef1',
        owner: null,
        resources: [
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: '60fb7369-c75d-4147-86ae-3fa724a3cff4',
        name: 'Desk-4',
        section: null,
        sectionId: null,
        status: DeskStatusEnum.Available,
        floorId: '4699dca8-1143-4534-8843-3f795e72e60a',
        owner: null,
        resources: [
          {
            id: '31676345-f85f-4241-aaff-47d41ec5e0bf',
            name: 'Dual Monitor',
            locationId: 'bf2ab97c-db32-4de9-92e3-922b82f343fa',
            isAvailable: true,
            createdAt: '2021-07-09T11:46:04.166Z',
          },
          {
            id: '7e28280d-bd52-4b8b-bc2c-35b73ec91e66',
            name: 'Stands',
            locationId: 'bf2ab97c-db32-4de9-92e3-922b82f343fa',
            isAvailable: true,
            createdAt: '2021-07-09T11:46:04.166Z',
          },
        ],
        isBooked: true,
      },
      {
        id: 'ad162d6d-dcc5-4f5e-9849-557afd3846ea',
        name: 'Desk-3',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: 'c07bd1eb-116b-48e9-bef3-87b57b1584d8',
        name: 'Desk-5',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: 'd71fa3e8-9445-4355-8d4d-4f20e063bb4f',
        name: 'Desk-4',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.ApprovalRequired,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: 'a073334b-5f31-45ac-8dd3-02d294b16a45',
        name: 'Desk-2',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Unavailable,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: 'ffca888c-b0e8-4a80-9811-00b693538b55',
        name: 'Desk-1',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: 'cb391e9b-4d36-469a-9ff8-91bf1b5ca950',
        name: 'Desk-6',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: '88357989-0253-4d83-9028-345e96b02c93',
        name: 'Desk-1',
        section: null,
        sectionId: null,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: '10ffbda1-3c33-4990-9fe8-6a22364046e3',
        name: 'Desk-5',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.ApprovalRequired,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: '6ef16ef2-dba1-4c9a-b511-c2ef7029708b',
        name: 'Desk-9',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: '2154bb36-ad0b-458f-86f4-c2bf5f773253',
        name: 'Desk-7',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: '67bb6e42-59c2-40b4-b221-53240f271fc0',
        name: 'Desk-6',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: 'b583b3ea-0e78-4bf4-9315-5c2d578b9ee6',
        name: 'Desk-2',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: '67915251-c7b5-4140-aef1-142ce9d2c7c7',
        name: 'Desk-3',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Unavailable,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: 'fbf3cabb-cafa-4c70-9991-f047a898e81e',
        name: 'Desk-4',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: true,
      },
      {
        id: 'd7f38e94-66e5-434b-873a-fb23cfd6b05c',
        name: 'Desk-8',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: '09b78fd6-05f3-4195-bd06-ed61c268b4c3',
        name: 'Desk-11',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: 'a06d7749-fea1-48ab-b43c-9f3405d136dd',
        name: 'Desk-13',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: '5bbd8756-5bae-450a-beb3-d089ef390762',
        name: 'Desk-10',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: '5a36c1a2-3725-405c-bd78-44669f71ecb1',
        name: 'Desk-12',
        section: 'Sectino test 79',
        sectionId: 79,
        status: DeskStatusEnum.Available,
        floorId: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        owner: null,
        resources: [
          {
            id: '4c1d248e-87bf-4a4c-827c-029e7b312b89',
            name: 'Dedicated PC',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: '8b1622cb-6cf5-43a5-811d-305862971c73',
            name: 'Docking Station',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'effdbbfa-e007-4a68-a2f3-78adc5d02fff',
            name: 'Multimedia Workstation',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
          {
            id: 'b9d2e7c5-86be-48f5-a699-63b6d6fa5c83',
            name: 'Dual Monitor',
            locationId: '0f296d76-80ef-41c1-8315-2edcd0105ce2',
            isAvailable: true,
            createdAt: '2021-07-09T16:16:25.576Z',
          },
        ],
        isBooked: false,
      },
      {
        id: 'e31475fb-1e0c-41b9-beb4-c9c4bd04d52b',
        name: 'Desk-3',
        section: null,
        sectionId: null,
        status: DeskStatusEnum.Available,
        floorId: '172c7e14-bf25-43ad-bfad-3e35c8fb2c0d',
        owner: null,
        resources: [],
        isBooked: false,
      },
      {
        id: 'b5980a4d-d91c-4c05-932c-2d1957cb8783',
        name: 'Desk-4',
        section: null,
        sectionId: null,
        status: DeskStatusEnum.Available,
        floorId: '53d9f40b-aea7-4a50-95c6-dfba6fd1f521',
        owner: null,
        resources: [],
        isBooked: true,
      },
    ],
  },
  error: null,
};
