import { ActionPayload, BaseErrorResponse, BaseResponse } from 'Store/Models/ReduxModels';
import { GetCalendarDatesRequest, GetCalendarDatesResponse } from '../../../../Admin/Store/calendar/models';
import { locationDisabledDaysType } from '../../Bookings/availableDesksDuck/models';
import { t } from '@lingui/macro';
import {
  AdminLocation,
  AutocompleteLocationRequest,
  CreateLocationRequest,
  CreateLocationResponse,
  DeleteFloorFakeResponse,
  DeleteFloorRequest,
  DeleteLocationRequest,
  DeleteLocationResponse,
  EditFloorRequest,
  EditFloorResponse,
  EditLocationModel,
  EditLocationRequest,
  EditLocationResponse,
  Floor,
  FloorAdmin,
  GetFloorsAdminRequest,
  GetFloorsAdminResponse,
  GetFloorsRequest,
  GetFloorsResponse,
  GetFloorsV2Request,
  GetFloorsV2Response,
  GetLocationsRequest,
  GetLocationsResponse,
  Location,
  SetLocationsDataRequest,
  SetLocationsSearchRequest,
  locationTimezonesInterface,
} from './models';

export const SET_LOADING = 'locationsDuck.SET_LOADING';

export const SET_FLOORS_ADMIN = "locationsDuck.SET_FLOORS_ADMIN";

export const GET_FLOORS = 'GET_FLOORS';
export const GET_FLOORS_FAIL = 'GET_FLOORS_FAIL';
export const GET_FLOORS_SUCCESS = 'GET_FLOORS_SUCCESS';

export const GET_FLOORS_V2 = 'GET_FLOORS_V2';
export const GET_FLOORS_V2_FAIL = 'GET_FLOORS_V2_FAIL';
export const GET_FLOORS_V2_SUCCESS = 'GET_FLOORS_V2_SUCCESS';

export const GET_FLOORS_ADMIN = 'GET_FLOORS_ADMIN';
export const GET_FLOORS_ADMIN_FAIL = 'GET_FLOORS_ADMIN_FAIL';
export const GET_FLOORS_ADMIN_SUCCESS = 'GET_FLOORS_ADMIN_SUCCESS';

export const GET_LOCATION_DISABLED_DATES = 'GET_LOCATION_DISABLED_DATES';
export const GET_LOCATION_DISABLED_DATES_SUCCESS = 'GET_LOCATION_DISABLED_DATES_SUCCESS';
export const GET_LOCATION_DISABLED_DATES_FAIL = 'GET_LOCATION_DISABLED_DATES_FAIL';

export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_FAIL = 'GET_LOCATIONS_FAIL';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';

export const GET_ADMIN_LOCATIONS = 'GET_ADMIN_LOCATIONS';
export const GET_ADMIN_LOCATIONS_FAIL = 'GET_ADMIN_LOCATIONS_FAIL';
export const GET_ADMIN_LOCATIONS_SUCCESS = 'GET_ADMIN_LOCATIONS_SUCCESS';

export const CREATE_LOCATION = 'CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAIL = 'CREATE_LOCATION_FAIL';

export const DELETE_FLOOR = 'DELETE_FLOOR';
export const DELETE_FLOOR_SUCCESS = 'DELETE_FLOOR_SUCCESS';
export const DELETE_FLOOR_FAIL = 'DELETE_FLOOR_FAIL';

export const EDIT_FLOOR = 'EDIT_FLOOR';
export const EDIT_FLOOR_SUCCESS = 'EDIT_FLOOR_SUCCESS';
export const EDIT_FLOOR_FAIL = 'EDIT_FLOOR_FAIL';

export const EDIT_LOCATION = 'EDIT_LOCATION';
export const EDIT_LOCATION_SUCCESS = 'EDIT_LOCATION_SUCCESS';
export const EDIT_LOCATION_FAIL = 'EDIT_LOCATION_FAIL';

export const DELETE_LOCATION = 'DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAIL = 'DELETE_LOCATION_FAIL';

export const GET_LOCATION_AUTOCOMPLETE = 'GET_LOCATION_AUTOCOMPLETE';
export const GET_LOCATION_AUTOCOMPLETE_SUCCESS = 'GET_LOCATION_AUTOCOMPLETE_SUCCESS';
export const GET_LOCATION_AUTOCOMPLETE_FAIL = 'GET_LOCATION_AUTOCOMPLETE_FAIL';

export const SET_LOCATIONS_DATA = 'SET_LOCATIONS_DATA';
export const SET_LOCATION_SEARCH = 'SET_LOCATION_SEARCH';

export const GET_LOCATION = 'GET_LOCATION';
export const GET_LOCATION_FAIL = 'GET_LOCATION_FAIL';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';

export interface SetLoading {
  type: typeof SET_LOADING;
  payload: boolean;
}

export interface SetFloorsAdmin {
  type: typeof SET_FLOORS_ADMIN;
  payload: FloorAdmin[];
}

export interface GetFloors {
  type: typeof GET_FLOORS;
  payload: ActionPayload<GetFloorsRequest>;
}
export interface GetFloorsFail {
  type: typeof GET_FLOORS_FAIL;
  payload: BaseErrorResponse;
}
export interface GetFloorsSuccess {
  type: typeof GET_FLOORS_SUCCESS;
  payload: BaseResponse<GetFloorsResponse>;
}

export interface GetFloorsV2 {
  type: typeof GET_FLOORS_V2;
  payload: ActionPayload<GetFloorsV2Request>;
}
export interface GetFloorsV2Fail {
  type: typeof GET_FLOORS_V2_FAIL;
  payload: BaseErrorResponse;
}
export interface GetFloorsV2Success {
  type: typeof GET_FLOORS_V2_SUCCESS;
  payload: BaseResponse<GetFloorsV2Response>;
}

export interface GetFloorsAdmin {
  type: typeof GET_FLOORS_ADMIN;
  payload: ActionPayload<GetFloorsAdminRequest>;
}
export interface GetFloorsAdminFail {
  type: typeof GET_FLOORS_ADMIN_FAIL;
  payload: BaseErrorResponse;
}
export interface GetFloorsAdminSuccess {
  type: typeof GET_FLOORS_ADMIN_SUCCESS;
  payload: BaseResponse<GetFloorsAdminResponse>;
}

// get list of location disabled dates
export interface GetLocationDisabledDates {
  type: typeof GET_LOCATION_DISABLED_DATES;
  payload: ActionPayload<GetCalendarDatesRequest>
}

export interface GetLocationDisabledDatesSuccess {
  type: typeof GET_LOCATION_DISABLED_DATES_SUCCESS;
  payload: BaseResponse<GetCalendarDatesResponse>
}

export interface GetLocationDisabledDatesFail {
  type: typeof GET_LOCATION_DISABLED_DATES_FAIL;
  payload: BaseErrorResponse;
}

// get all locations
export interface GetLocations {
  type: typeof GET_LOCATIONS;
  payload: ActionPayload<GetLocationsRequest>;
}
export interface GetLocationsFail {
  type: typeof GET_LOCATIONS_FAIL;
  payload: BaseErrorResponse;
}
export interface GetLocationsSuccess {
  type: typeof GET_LOCATIONS_SUCCESS;
  payload: BaseResponse<GetLocationsResponse>;
}

// get all locations with desks and floors info
export interface GetAdminLocations {
  type: typeof GET_ADMIN_LOCATIONS;
  payload: ActionPayload<GetLocationsRequest>;
}
export interface GetAdminLocationsFail {
  type: typeof GET_ADMIN_LOCATIONS_FAIL;
  payload: BaseErrorResponse;
}
export interface GetAdminLocationsSuccess {
  type: typeof GET_ADMIN_LOCATIONS_SUCCESS;
  payload: BaseResponse<GetLocationsResponse>;
}

// create location
export interface CreateLocation {
  type: typeof CREATE_LOCATION;
  payload: ActionPayload<CreateLocationRequest>;
}
export interface CreateLocationSuccess {
  type: typeof CREATE_LOCATION_SUCCESS;
  payload: BaseResponse<CreateLocationResponse>;
}
export interface CreateLocationFail {
  type: typeof CREATE_LOCATION_FAIL;
  payload: BaseErrorResponse;
}

export interface DeleteFloor {
  type: typeof DELETE_FLOOR;
  payload: ActionPayload<DeleteFloorRequest>;
}
export interface DeleteFloorSuccess {
  type: typeof DELETE_FLOOR_SUCCESS;
  payload: DeleteFloorFakeResponse;
}
export interface DeleteFloorFail {
  type: typeof DELETE_FLOOR_FAIL;
  payload: BaseErrorResponse;
}

// edit floor
export interface EditFloor {
  type: typeof EDIT_FLOOR;
  payload: ActionPayload<EditFloorRequest>;
}

export interface EditFloorSuccess {
  type: typeof EDIT_FLOOR_SUCCESS;
  payload: BaseResponse<EditFloorResponse>;
}

export interface EditFloorFail {
  type: typeof EDIT_FLOOR_FAIL;
  payload: BaseErrorResponse;
}

// edit location
export interface EditLocation {
  type: typeof EDIT_LOCATION;
  payload: ActionPayload<EditLocationModel>;
}

export interface EditLocationSuccess {
  type: typeof EDIT_LOCATION_SUCCESS;
  payload: BaseResponse<EditLocationResponse>;
}

export interface EditLocationFail {
  type: typeof EDIT_LOCATION_FAIL;
  payload: BaseErrorResponse;
}

// delete location
export interface DeleteLocation {
  type: typeof DELETE_LOCATION;
  payload: ActionPayload<DeleteLocationRequest>
}

export interface DeleteLocationSuccess {
  type: typeof DELETE_LOCATION_SUCCESS;
  payload: BaseResponse<DeleteLocationResponse>;
}

export interface DeleteLocationFail {
  type: typeof DELETE_LOCATION_FAIL;
  payload: BaseErrorResponse;
}

// get autocomplete data
export interface GetLocationAutocomplete {
  type: typeof GET_LOCATION_AUTOCOMPLETE;
  payload: ActionPayload<AutocompleteLocationRequest>
}

export interface GetLocationAutocompleteSuccess {
  type: typeof GET_LOCATION_AUTOCOMPLETE_SUCCESS;
  payload: BaseResponse<any>; // to do change type to response obj
}

export interface GetLocationAutocompleteFail {
  type: typeof GET_LOCATION_AUTOCOMPLETE_FAIL;
  payload: BaseErrorResponse;
}

export interface SetLocationsData {
  type: typeof SET_LOCATIONS_DATA;
  payload: SetLocationsDataRequest;
}

export interface SetLocationsSearch {
  type: typeof SET_LOCATION_SEARCH;
  payload: SetLocationsSearchRequest;
}

export interface GetLocation {
  type: typeof GET_LOCATION;
  payload: ActionPayload<void>;
}
export interface GetLocationFail {
  type: typeof GET_LOCATION_FAIL;
  payload: BaseErrorResponse;
}
export interface GetLocationSuccess {
  type: typeof GET_LOCATION_SUCCESS;
  payload: BaseResponse<{ result: { data: Location } }>;
}

export type Actions =
  | SetLoading
  | SetFloorsAdmin
  | GetFloors
  | GetFloorsFail
  | GetFloorsSuccess
  | GetFloorsV2
  | GetFloorsV2Fail
  | GetFloorsV2Success
  | GetFloorsAdmin
  | GetFloorsAdminFail
  | GetFloorsAdminSuccess
  | GetLocations
  | GetLocationsFail
  | GetLocationsSuccess
  | GetAdminLocations
  | GetAdminLocationsFail
  | GetAdminLocationsSuccess
  | CreateLocation
  | CreateLocationSuccess
  | CreateLocationFail
  | EditFloor
  | EditFloorFail
  | EditFloorSuccess
  | EditLocation
  | EditLocationSuccess
  | EditLocationFail
  | DeleteFloor
  | DeleteFloorSuccess
  | DeleteFloorFail
  | DeleteLocation
  | DeleteLocationSuccess
  | DeleteLocationFail
  | GetLocationAutocomplete
  | GetLocationAutocompleteSuccess
  | GetLocationAutocompleteFail
  | GetLocationDisabledDates
  | GetLocationDisabledDatesSuccess
  | GetLocationDisabledDatesFail
  | SetLocationsData
  | SetLocationsSearch
  | GetLocation
  | GetLocationFail
  | GetLocationSuccess;

export interface State {
  error: string;
  successMessage: string,
  loading: boolean;
  locationsLoaded: boolean;
  floorDeleteError: string;
  floorDeleted: boolean;
  locationDisabledDaysLoaded: boolean;
  floors: Floor[];
  floorsAdmin: FloorAdmin[];
  locations: Location[];
  adminLocations: AdminLocation[];
  locationSearch: string;
  locationDisabledDays: locationDisabledDaysType;
  locationTimezones: locationTimezonesInterface[];
}

const initialState: State = {
  error: '',
  successMessage: '',
  loading: false,
  locationsLoaded: false,
  floorDeleteError: '',
  floorDeleted: false,
  locationDisabledDaysLoaded: false,
  floors: [],
  floorsAdmin: [],
  locations: [],
  adminLocations: [],
  locationSearch: '',
  locationDisabledDays: [],
  locationTimezones: [],
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_FLOORS_ADMIN:
      return { ...state, floorsAdmin: action.payload };
    case GET_FLOORS:
      return {
        ...state,
        error: '',
        loading: true,
        floors: [],
      };
    case GET_FLOORS_FAIL:
      return {
        ...state,
        error: t`There was an error loading floors.`,
        loading: false,
      };
    case GET_FLOORS_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        floors: action.payload.data.result.data,
      };
    }

    case GET_FLOORS_V2:
      return {
        ...state,
        error: '',
        loading: true,
        floors: [],
      };

    case GET_FLOORS_V2_FAIL:
      return {
        ...state,
        error: t`There was an error loading floors.`,
        loading: false,
      };

    case GET_FLOORS_V2_SUCCESS: {
      const floors = action.payload.data.result.data.items;

      const floorsNormalized = floors.map(
          (floor) => ({ id: floor.id, floorName: floor.name, extra: floor.extra, sitDesks: 0 }));

      return {
        ...state,
        error: '',
        loading: false,
        floors: floorsNormalized,
      };
    }

    case "GET_LOCATION_DISABLED_DATES":
      return {
        ...state,
        locationDisabledDaysLoaded: false,
      };
    case GET_LOCATION_DISABLED_DATES_SUCCESS:
      return {
        ...state,
        locationDisabledDaysLoaded: true,
        locationDisabledDays: action.payload.data.result.data.disabledDates,
      };
    case GET_LOCATION_DISABLED_DATES_FAIL:
      return {
        ...state,
        locationDisabledDaysLoaded: false,
        error: t`There was an error loading location disabled days. Please try again.`,
      };

    case GET_FLOORS_ADMIN:
      return {
        ...state,
        error: '',
        loading: true,
        floorsAdmin: [],
      };
    case GET_FLOORS_ADMIN_FAIL:
      return {
        ...state,
        error: t`There was an error loading floors.`,
        loading: false,
      };
    case GET_FLOORS_ADMIN_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        floorsAdmin: action.payload.data.result.data,
      };
    }
    case GET_LOCATIONS:
      return {
        ...state,
        error: '',
        loading: true,
        locationsLoaded: false,
      };
    case GET_LOCATIONS_FAIL:
      return {
        ...state,
        error: t`There was an error loading locations.`,
        loading: false,
        locationsLoaded: false,
      };
    case GET_LOCATIONS_SUCCESS: {
      const locations = action.payload.data.result.data;
      const sortedLocations = locations.sort((a: Location, b: Location) => a.locationName.localeCompare(b.locationName));

      return {
        ...state,
        error: '',
        loading: false,
        locationsLoaded: true,
        locations: sortedLocations,
      };
    }
    case GET_ADMIN_LOCATIONS:
      return {
        ...state,
        error: '',
        loading: true,
        locationsLoaded: false,
      };
    case GET_ADMIN_LOCATIONS_FAIL:
      return {
        ...state,
        error: t`There was an error loading locations.`,
        loading: false,
        locationsLoaded: false,
      };
    case GET_ADMIN_LOCATIONS_SUCCESS: {
      const locations = action.payload.data.result.data;
      const sortedLocations = locations.sort((a: AdminLocation, b: AdminLocation) => a.locationName.localeCompare(b.locationName));

      return {
        ...state,
        error: '',
        loading: false,
        locationsLoaded: true,
        adminLocations: sortedLocations,
      };
    }

    case CREATE_LOCATION:
      return {
        ...state,
      };
    case CREATE_LOCATION_SUCCESS: {
      const location = action.payload.data.result.data;
      return {
        ...state,
        error: '',
        successMessage: 'Location was added',
        locations: [...state.locations, location],
      };
    }
    case CREATE_LOCATION_FAIL:
      return {
        ...state,
        error: t`There was an error. Please try again.`,
      };

    case DELETE_FLOOR: {
      return {
        ...state,
        floorDeleteError: '',
      };
    }
    case DELETE_FLOOR_FAIL: {
      return {
        ...state,
        floorDeleteError: t`It wasn\'t possible to delete the floor. Please try again.`,
        error: t`It wasn\'t possible to delete the floor. Please try again.`,
      };
    }
    case DELETE_FLOOR_SUCCESS: {
      const floors = [...state.floors].filter(floor => floor.id !== action.payload.floorId);
      const floorsAdmin = [...state.floorsAdmin].filter(floor => floor.id !== action.payload.floorId);

      return {
        ...state,
        floorDeleteError: '',
        error: '',
        successMessage: t`Floor deleted`,
        floorDeleted: true,
        floors,
        floorsAdmin,
      };
    }

    case EDIT_FLOOR: {
      return {
        ...state,
      };
    }
    case EDIT_FLOOR_FAIL: {
      return {
        ...state,
        error: t`There was an error. Please try again.`,
      };
    }
    case EDIT_FLOOR_SUCCESS: {
      const { active, id } = action.payload.data.result.data;
      const newFloorsAdmin = [...state.floorsAdmin];
      const index = newFloorsAdmin.findIndex(floor => floor.id === id);

      newFloorsAdmin[index].active = active;

      return {
        ...state,
        error: '',
        successMessage: 'Floor was edited',
        floorsAdmin: newFloorsAdmin,
      };
    }

    case EDIT_LOCATION:
      return {
        ...state,
      };
    case EDIT_LOCATION_SUCCESS: {
      const location = action.payload.data.result.data;
      const newLocations = [...state.locations];
      const newAdminLocations = [...state.adminLocations];

      const index = newLocations.findIndex(q => q.id === location.id);
      const adminIndex = newAdminLocations.findIndex(q => q.id === location.id);

      newLocations[index] = {
        ...newLocations[index],
        ...location,
      };

      newAdminLocations[adminIndex] = {
        ...newAdminLocations[adminIndex],
        ...location,
      };

      return {
        ...state,
        error: '',
        successMessage: 'Location was edited',
        locations: newLocations,
        adminLocations: newAdminLocations,
      };
    }
    case EDIT_LOCATION_FAIL:
      return {
        ...state,
        error: t`There was an error. Please try again.`,
      };

    case DELETE_LOCATION:
      return {
        ...state,
      };
    case DELETE_LOCATION_SUCCESS: {

      const deletedLocationId = action.payload.data.result.data;
      const newLocations = [...state.locations].filter(q => q.id !== deletedLocationId);
      const newAdminLocations = [...state.adminLocations].filter(q => q.id !== deletedLocationId);

      return {
        ...state,
        locations: newLocations,
        adminLocations: newAdminLocations,
      };
    }
    case DELETE_LOCATION_FAIL:
      return {
        ...state,
        error: t`There was an error. Please try again.`,
      };

    case SET_LOCATIONS_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case SET_LOCATION_SEARCH: {
      return {
        ...state,
        locationSearch: action.payload.locationSearch,
      };
    }
    case GET_LOCATION:
      return {
        ...state,
        error: '',
        loading: true,
        locationsLoaded: false,
      };
    case GET_LOCATION_FAIL:
      return {
        ...state,
        error: t`There was an error loading location.`,
        loading: false,
        locationsLoaded: false,
      };
    case GET_LOCATION_SUCCESS: {
      const location = action.payload.data.result.data;
      const newLocations: Location[] = [location];

      for (const item of state.locations || []) {
        if (item.id === location.id) {
          continue;
        }

        newLocations.push(item);
      }
      const sortedLocations = newLocations.sort((a: Location, b: Location) => a.locationName.localeCompare(b.locationName));

      return {
        ...state,
        error: '',
        loading: false,
        locationsLoaded: true,
        locations: sortedLocations,
      };
    }
    default:
      return state;
  }
}

export function setLoading(payload: boolean): SetLoading {
  return {
    payload,
    type: SET_LOADING,
  };
}

// Actions
export function getFloors({
  data,
  locationId,
}: {
  data: GetFloorsRequest;
  locationId: string;
}): GetFloors {
  return {
    type: GET_FLOORS,
    payload: {
      request: {
        method: 'GET',
        url: `/api/locations/${locationId}/floors`,
        data,
      },
    },
  };
}

export function getFloorsV2({
  data, 
  locationId,
}: {
  data: GetFloorsV2Request;
  locationId: string;
}): GetFloorsV2 {
  let url = `api/v2/locations/${locationId}/floors?include[0]=extra.availableRooms&limit=${data.limit}&page=${data.page}`;

  if (data.custom) {
    data.custom.map((custom) => {
      url += `&custom[${custom.key}]=${encodeURIComponent(custom.value)}`;
    });    
  }

  return {
    type: GET_FLOORS_V2,
    payload: {
      request: {
        method: 'GET',
        url,
      },
    },
  };
}

export function getFloorsAdmin(locationId: string): GetFloorsAdmin {
  return {
    type: GET_FLOORS_ADMIN,
    payload: {
      request: {
        method: 'GET',
        url: `/api/locations/${locationId}/admin/floors`,
      },
    },
  };
}

export function getLocationDisabledDates(data: GetCalendarDatesRequest): GetLocationDisabledDates {
  return {
    type: GET_LOCATION_DISABLED_DATES,
    payload: {
      request: {
        method: 'GET',
        url: `/api/calendar/${data.locationId}`,
      },
    },
  };
}

export function getLocations(data?: GetLocationsRequest): GetLocations {
  return {
    type: GET_LOCATIONS,
    payload: {
      request: {
        method: 'GET',
        url: '/api/v2/locations',
        data,
      },
    },
  };
}

export function getAdminLocations(data?: GetLocationsRequest): GetAdminLocations {
  return {
    type: GET_ADMIN_LOCATIONS,
    payload: {
      request: {
        method: 'GET',
        url: '/api/locations/admin',
        data,
      },
    },
  };
}

export function createLocation(data: CreateLocationRequest): CreateLocation {
  return {
    type: CREATE_LOCATION,
    payload: {
      request: {
        method: 'POST',
        url: `/api/locations`,
        data,
      },
    },
  };
}

export function editFloor(floorId: string, data: EditFloorRequest): EditFloor {
  return {
    type: EDIT_FLOOR,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/locations/${floorId}/floor`,
        data,
      },
    },
  };
}

export function editLocation(data: EditLocationRequest): EditLocation {
  return {
    type: EDIT_LOCATION,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/locations/${data.locationId}`,
        data: data.location,
      },
    },
  };
}

export function deleteFloor(floorId: string): DeleteFloor {
  return {
    type: DELETE_FLOOR,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/locations/${floorId}/floor`,
        data: { floorId },
      },
    },
  };
}

export function deleteLocation(data: DeleteLocationRequest): DeleteLocation {
  return {
    type: DELETE_LOCATION,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/locations/${data.locationId}`,
      },
    },
  };
}

export function getLocationAutocomplete(data: AutocompleteLocationRequest): GetLocationAutocomplete {
  return {
    type: GET_LOCATION_AUTOCOMPLETE,
    payload: {
      request: {
        method: 'GET',
        url: `/api/v2/locations/addresses/?limit=5&search=${data.search}`,
      },
    },
  };
}

export function setLocationsData(data: SetLocationsDataRequest): SetLocationsData {
  return {
    type: SET_LOCATIONS_DATA,
    payload: data,
  };
}

export function setLocationsSearch(data: SetLocationsSearchRequest): SetLocationsSearch {
  return {
    type: SET_LOCATION_SEARCH,
    payload: data,
  };
}

export const setFloorsAdmin = (payload: FloorAdmin[]): SetFloorsAdmin => ({ type: SET_FLOORS_ADMIN, payload });

export function getLocation(locationId: string): GetLocation {
  return {
    type: GET_LOCATION,
    payload: {
      request: {
        method: 'GET',
        url: `/api/v2/locations/${locationId}`,
      },
    },
  };
}