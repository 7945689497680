import { IGroup } from "../../../../../../../../Store/groups/models";
import Text from "Components/Text";

interface Props {
  searchText: string;
  groups: IGroup[];
}

export default function NoGroupsText(props: Props) {
  const { searchText, groups } = props;

  if (searchText && !groups.length) { // show in case of no search conditions
    return (
      <Text align="center" color="lightGray" size="md">
        No groups found
      </Text>
    );
  }

  return (
    <Text align="center" color="lightGray" size="md">
      Please use search to add new groups
    </Text>
  );
}