import { useTypedSelector } from "Store/Redux/store";
import { useGetMeQuery } from "store";

export const useDelegatedId = (): string | undefined => {
  const getMeQuery = useGetMeQuery();
  const { data: getMeResponse, isLoading } = getMeQuery;
  const { data: user } = getMeResponse?.result || {};
  const selectedUserId = useTypedSelector(({ executiveAssistant }) => executiveAssistant.selectedUser?.id);

  if (isLoading || !user) {
    return;
  }

  const isDelegatedAccess = selectedUserId && selectedUserId !== user.id;

  return isDelegatedAccess ? selectedUserId : undefined;
};
