import { t } from '@lingui/macro';
import { CREATE_MEETING_RESERVATION_SUCCESS, CreateMeetingReservationSuccess } from '../meetingReservations';
import {
  CreateNewMeetingModel,
  MeetingReservationSelectedData,
  RoomSelectEnum,
  RoomSelectType,
  SetCreateNewMeetingRequest,
} from './models';

export const CLEAR_CREATE_NEW_MEETING_DATA = 'CLEAR_CREATE_NEW_MEETING_DATA';
export const CLEAR_CREATE_NEW_MEETING_DATA_IF_BOOKING_CREATED = 'CLEAR_CREATE_NEW_MEETING_DATA_IF_BOOKING_CREATED';
export const SET_CREATE_NEW_MEETING_DATA = 'SET_CREATE_NEW_MEETING_DATA';

export interface ClearCreateNewMeetingData {
  type: typeof CLEAR_CREATE_NEW_MEETING_DATA;
}

export interface ClearCreateNewMeetingDataIfBookingCreated {
  type: typeof CLEAR_CREATE_NEW_MEETING_DATA_IF_BOOKING_CREATED;
}

export interface SetCreateNewMeetinggData {
  type: typeof SET_CREATE_NEW_MEETING_DATA;
  payload: SetCreateNewMeetingRequest;
}

export type Actions =
  | ClearCreateNewMeetingData
  | ClearCreateNewMeetingDataIfBookingCreated
  | CreateMeetingReservationSuccess
  | SetCreateNewMeetinggData;

export interface State extends CreateNewMeetingModel {
  error: string;
  loading: boolean;
  reservationCreated: boolean;
  roomSelect: RoomSelectType;
  selectedData: MeetingReservationSelectedData;
}

interface Hour {
  value: number;
  label: string;
}

export const selectableSliderHours: Array<Hour> = [
  {
    value: 6,
    label: t`6 AM`,
  },
  {
    value: 9,
    label: t`9 AM`,
  },
  {
    value: 12,
    label: t`12 PM`,
  },
  {
    value: 15,
    label: t`3 PM`,
  },
  {
    value: 18,
    label: t`6 PM`,
  },
  {
    value: 21,
    label: t`9 PM`,
  },
];

const tomorrow = new Date(new Date().getTime() + 86400000);
const initialState: State = {
  error: '',
  loading: false,
  reservationCreated: false,
  roomSelect: RoomSelectEnum.LIST_VIEW,
  dateFrom: tomorrow,
  dateTo: tomorrow,
  meetingFrom: undefined,
  meetingTo: undefined,  
  timeFrom: undefined,
  timeTo: undefined,
  selectedData: {
    roomId: '',
    floor: null,
    location: null,    
    meetingTitle: '',
    attendees: [],
    requestedServices: [],
    isTeamsMeeting: true,
  },
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case CLEAR_CREATE_NEW_MEETING_DATA: {
      return {
        ...initialState,
      };
    }

    case CLEAR_CREATE_NEW_MEETING_DATA_IF_BOOKING_CREATED: {
      const newState = state.reservationCreated ? { ...initialState } : state;

      return newState;
    }

    case CREATE_MEETING_RESERVATION_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        reservationCreated: true,
      };
    }

    case SET_CREATE_NEW_MEETING_DATA: {
      return {
        ...state,
        ...action.payload,        
      };
    }

    default:
      return state;
  }
}

// Actions
export function clearCreateNewMeetingData(): ClearCreateNewMeetingData {
  return {
    type: CLEAR_CREATE_NEW_MEETING_DATA,
  };
}

export function clearCreateNewMeetingDataIfBookingCreated(): ClearCreateNewMeetingDataIfBookingCreated {
  return {
    type: CLEAR_CREATE_NEW_MEETING_DATA_IF_BOOKING_CREATED,
  };
}

export function setCreateNewMeetingData(data: SetCreateNewMeetingRequest): SetCreateNewMeetinggData {
  return {
    type: SET_CREATE_NEW_MEETING_DATA,
    payload: data,
  };
}

/**
 * Receives the number of the step and the redux state, and returns if that
 * step is completed.
 */
 export function isStepCompleted(number: number, state: State): boolean {
  let isCompleted = false;

  const { selectedData } = state;

  switch (number) {
    case 1: {
      isCompleted = !!selectedData.location;
      break;
    }

    case 2: {
      isCompleted = !!selectedData.floor;
      break;
    }

    case 3: {
      isCompleted = !!selectedData.roomId;
      break;
    }

    default:
      break;
  }

  return isCompleted;
}

/**
 * Receives the number of the step and the redux state, and returns if that
 * step should be disabled. This means, if the step before is completed.
 */
export function isStepDisabled(number: number, state: State): boolean {
  let isDisabled = false;

  const { selectedData } = state;

  switch (number) {
    case 2: {
      isDisabled = !selectedData.location;
      break;
    }

    case 3: {
      isDisabled = !selectedData.floor || !selectedData.location;
      break;
    }

    default:
      break;
  }

  return isDisabled;
}