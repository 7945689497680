import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "underscore";
import styles from "./styles.module.scss";

import ManageLocationsModal from "../../../../Components/ManageLocationsForm";
import {IGroup} from "../../../../Store/groups/models";
import {Location} from "../../../../../App/Store/Locations/locationsDuck/models";
import { editGroup } from "../../../../Store/groups";

interface LocationsGroupModalProps {
  group: IGroup;
}

export default function LocationsGroupModal(props: LocationsGroupModalProps) {
  const dispatch = useDispatch();
  const { group } = props;
  const [key, setKey] = useState(1);

  const onSave = (selectedLocations: Location[]) => {
    const updatedLocationsIds = selectedLocations.map(l => l.id);
    const isDataChanged = !_.isEqual(_.sortBy(updatedLocationsIds), _.sortBy(group.locationIds));

    if (isDataChanged) {
      const updatedGroup = { name: group.name, locationIds: updatedLocationsIds, adGroupId: group.adGroup?.id };
      dispatch(editGroup({ group: updatedGroup, groupId: group.id }));
    }
  };

  // update data on data update with any component
  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [group.locationIds]);

  return (
    <ManageLocationsModal
      contentStyle={{width: '370px'}}
      key={key}
      locationIds={group.locationIds}
      onSave={onSave}
      openButtonClassName={styles.openButtonClassName}
    />
  );
}
