import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useTypedSelector } from "../../../../../../Store/Redux/store";
import {selectDesksFiltered} from "../../../../../../App/Store/Bookings/availableDesksDuck";
import L from "leaflet";
import moment from "moment";
import {Popup, Rectangle, Tooltip} from "react-leaflet";
import styles from "./styles.module.scss";
import Api from "../../../../../../Store/Services/Api";
import { DeskDetails } from "../../DeskDetails";
import Box from "../../../../../../Components/Box";
import DeskBookingDetails from "./DeskBookingDetails";
import { cancelBooking } from "../../../../../../App/Store/Bookings/bookingDuck";
import { getReservationsBookings, setReservationsData } from "../../../../../Store/reservations";
import { FloorMapObject } from "../../../../../Store/floorMapDuck/models";
import {useParams} from "react-router-dom";

interface DeskInterface extends FloorMapObject {
  bookingId?: string;
  userName?: string;
}

interface Props {
  desk: DeskInterface;
}

/**
 * Desk Drawing
 *
 * This component handles deletion and edition internally.
 *
 * React Leaflet Draw handles it's own states, so this component syncs them with internal states.
 */
export default function DeskObjectAllBooked({ desk }: Props) {
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const { coordinatesInLatLngBounds } = desk;
  const [deskPopupOpened, setDeskPopupOpened] = useState(false);
  const [disabledCancel, setDisabledCancel] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { floorId, locationId } = useParams<{ floorId: string; locationId: string }>();
  const { adminFloorMap, adminReservations, availableDesks, createNewBooking } = useTypedSelector(state => state);
  const { filters } = adminReservations;
  const { id, name } = desk;
  const isBooked = !!desk.bookingId;
  const isHighLighted = availableDesks.highlightNeighbourDeskId === id;
  const desksFiltered = selectDesksFiltered({
    availableDesksState: availableDesks,
    filters: createNewBooking.filters,
    floorMapState: adminFloorMap,
  });
  const isDeskOnFiltered = Boolean(desksFiltered.find(desk => desk.id === id));

  // Close popup if needed when deskPopupOpened changes
  useEffect(() => {
    if (ref.current && ref.current._popup) {
      if (!deskPopupOpened) {
        ref.current._popup.remove();
      }
    }
  }, [deskPopupOpened]);

  // show popup only for non expired bookings
  useEffect(() => {
    const today = moment().startOf('day');
    const isBefore = moment(filters.selectedEndDate).isBefore(today);

    if (isBefore) {
      return setShowPopup(false);
    }

    return setShowPopup(true);
  }, [filters.selectedEndDate]);

  const handleCancelBooking = async () => {
    if (desk.bookingId) {
      try {
        const action = dispatch(cancelBooking({
          bookingId: desk.bookingId,
          bookingType: 'custom',
        }));
        setDisabledCancel(true);
        const payload = await Api(action);

        if (payload.status === 200 || payload.status === 201) {
          dispatch(getReservationsBookings({
            page: 1,
            limit: 1000,
            order: 'ASC',
            locationIds: [locationId],
            floorIds: [floorId],
            statuses: ["BOOKED"],
            dateFrom: moment(filters.selectedStartDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
            dateTo: moment(filters.selectedEndDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          }));         
          dispatch(setReservationsData({ successMessage: 'The booking was cancelled' }));
          setDisabledCancel(false);
          onPopupClose();
        }
      } catch (e) {
        setDisabledCancel(false);
        console.log('error with Cancel Booking');
      }
    }
  };

  // Set popup state to false
  const onPopupClose = () => {
    setDeskPopupOpened(false);
  };

  // Set popup state to true. Since leaflet draw handles states separately, this syncs both
  const onPopupOpen = () => {
    setDeskPopupOpened(true);
  };

  const arrayOfCoordinatesInLatLng = coordinatesInLatLngBounds.map(coordinate => L.latLng(coordinate.lat, coordinate.lng));
  const bounds: L.LatLngBoundsExpression = L.latLngBounds(arrayOfCoordinatesInLatLng);
  const color = `${isBooked ? '#5BC535' : "#a3a3a3"}${!isDeskOnFiltered ? '38' : ''}`;

  return (
    <Rectangle
      bounds={bounds}
      eventHandlers={{
        popupclose: isBooked ? onPopupClose : undefined,
        popupopen: isBooked ? onPopupOpen : undefined,
      }}
      pathOptions={{
        color,
        fillColor: color,
        fillOpacity: isHighLighted ? 1 : 0.2,
      }}
      ref={ref}
    >
      <Tooltip sticky>
        {name}
      </Tooltip>

      {
        isBooked && showPopup ? (
          <Popup
            className={styles.popupContainer}
            closeButton={false}
          >
            <Box padding="20px" width={370}>
              <DeskDetails desk={desk} />
            </Box>
            <DeskBookingDetails disabledCancel={disabledCancel} onCancel={handleCancelBooking} />
          </Popup>
        ) : undefined
      }
    </Rectangle>
  );
}
