import { APIResponseBody } from "../types";

export const isSuccessAPIResponse = <T, C extends Object>(
  response: { data: APIResponseBody<T> } | C,
): response is { data: APIResponseBody<T> } => {
  if ("data" in response && Math.floor(response.data?.result?.statusCode / 100) === 2) {
    return true;
  }

  return false;
};
