import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useClickOutside} from "../../../../../../../Functions/hooks";
import styles from './styles.module.scss';

import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import { FloorMapObject, MapDrawingOwner } from "../../../../../../Store/floorMapDuck/models";
import {setUserManagementData} from "../../../../../../Store/userManagement";
import OwnersList from "./OwnerList";
import UserSearch from "./UserSearch";

interface Props {
  drawingObject: FloorMapObject; // TODO - in future use separate models for desk/section/meeting object
  onUpdate: (owners: MapDrawingOwner[]) => void;
}

export default function OwnersMultiSelect(props: Props) {
  const dispatch = useDispatch();
  const deskOwnersWrapperRef = useRef<any>(null);
  const { drawingObject, onUpdate } = props;

  const [searchText, setSearchText] = useState('');
  const [isOpenOwnerList, setIsOpenOwnerList] = useState(false);

  const onResetSearchState = () => {
    dispatch(setUserManagementData({
      allUsers: [],
    }));
  };

  const onCloseOwnerList = () => {
    setIsOpenOwnerList(false);
    setSearchText(''); // clear search field on close dropdown
    onResetSearchState(); // clear user list on close dropdown
  };

  const onOwnersUpdate = (owners: MapDrawingOwner[]) => {
    onUpdate(owners);
  };

  useEffect(() => {
    onResetSearchState(); // clear user list on load
  }, []);

  // close list by outside click
  useClickOutside(deskOwnersWrapperRef, onCloseOwnerList);

  return (
    <div
      className={styles.deskOwners}
      ref={deskOwnersWrapperRef}
    >
      <UserSearch
        drawingObject={drawingObject}
        isOpenOwnerList={isOpenOwnerList}
        onOwnersUpdate={onOwnersUpdate}
        searchText={searchText}
        setIsOpenOwnerList={setIsOpenOwnerList}
        setSearchText={setSearchText}
      />

      <Accordion
        classes={{expanded: styles.accordionExpanded, root: styles.accordion}}
        expanded={isOpenOwnerList}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          classes={{
            content: styles.accordionSummaryContent,
            expandIcon: styles.accordionSummaryIcon,
            root: styles.accordionSummary,
          }}
        >
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: styles.accordionDetails,
          }}
        >
          <OwnersList
            drawingObject={drawingObject}
            onOwnersUpdate={onOwnersUpdate}
            searchText={searchText}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
