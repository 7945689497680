type V = number | boolean | string | undefined;

export const parseObjectQueryParam = (name: string, value?: Record<string, V>): Record<string, string> => {
  const carry: Record<string, string> = {};

  if (!value) {
    return carry;
  }

  const entries = Object.entries(value);

  for (const [key, value] of entries) {
    if (typeof value !== "undefined") {
      carry[`${name}[${key}]`] = `${value}`;
    }
  }

  return carry;
};
