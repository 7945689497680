import AppStoreIcon from './assets/appstore.png';
import pngBackgroundImage from './assets/welcome-bg.png';
import webpBackgroundImage from './assets/welcome-bg.webp';
import Box from 'Components/Box';
import Button from 'Components/Button';
import Container from 'Components/Container';
import GooglePlayIcon from './assets/googleplay.png';
import Heading from 'Components/Heading';
import Select from 'Components/Select';
import styles from './styles.module.scss';
import useSnackbar from 'Components/Snackbar/useSnackbar';
import { graphRequest, loginRequest } from 'Store/Services/AuthConfig';
import { setGraphLoginToken, setLoginToken } from 'App/Store/Auth/loginDuck';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { useTypedSelector } from 'Store/Redux/store';
import { setLanguage } from "../../../Store/Redux/Ducks/configDuck";
import { LANGUAGES } from "../../../Store/Redux/Ducks/configDuck/models";

export default function Login() {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const [openSnackbar] = useSnackbar();
  const { language } = useTypedSelector(state => state.config);

  const languages = LANGUAGES;
  const handleChangeLanguage = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    const newLanguage = [...languages].find(language => language.value === event.target.value);
    if (newLanguage) {
      dispatch(setLanguage(newLanguage));
    }
  };

  const handleLogin = async () => {
    try {
      const { accessToken, expiresOn } = await instance.loginPopup(loginRequest);

      dispatch(setLoginToken(accessToken, expiresOn || undefined));

      const activeAccount = await instance.getActiveAccount();
      const [account] = instance.getAllAccounts();

      if (activeAccount || account) {
        const { accessToken, expiresOn } = await instance.acquireTokenSilent({ ...graphRequest, account: activeAccount || account });

        dispatch(setGraphLoginToken(accessToken, expiresOn || undefined));
      }
    } catch {
      openSnackbar({
        text: t`There was an error with login. Please try again or contact the admin.`,
        type: 'error',
        autoHideDuration: 10000,
      });
    }
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.background}>
        <Box />

        <Box height="100vh" overflow="hidden">
          <picture>
            <source srcSet={webpBackgroundImage} type="image/webp" />
            <source srcSet={pngBackgroundImage} type="image/png" />
            <img alt={t`Background Image`} className={styles.backgroundImage} src={pngBackgroundImage} />
          </picture>
        </Box>
      </Box>

      <Container>
        <form>
          <Box
            alignItems="center"
            display="grid"
            gap={50}
            gridTemplateColumns="1fr 1fr"
            minHeight="100vh"
          >
            <Box maxWidth={370}>
              <Box marginBottom={20}>
                <Heading>
                  <Trans>
                    Welcome to Together
                  </Trans>
                </Heading>
              </Box>

              <Box marginBottom={20}>
                 <div className={styles.textBlock}>
                  <Trans>
                    Book and manage your places, get notified on arrival day in one app.
                  </Trans>
                </div>
              </Box>

              <Box
                alignItems="center"
                display="flex"
                justifyContent="between"
                marginTop={15}
              >
                <Button
                  aria-label={t`login`}
                  buttonType="button"
                  name={t`login`}
                  onClick={handleLogin}
                  size="full"
                  type="black"
                >
                  <Trans>
                    Log In
                  </Trans>
                </Button>
              </Box>

              <Box marginTop={20}>
                <Select
                  aria-label={t`language`}
                  className={styles.selectContainer}
                  id="language"
                  items={languages}
                  label={t`Language:`}
                  name={t`language`}
                  onChange={handleChangeLanguage}
                  value={language.value}
                />
              </Box>

              <Box alignItems='center' display='flex' marginTop={50}>
                <a href="https://apps.apple.com/us/app/mbw-kso-together/id1563045637" rel='noopener noreferrer' target='_blank'>
                  <img height={45} src={AppStoreIcon} />
                </a>

                <Box marginStart={20}>
                  <a href="https://play.google.com/store/apps/details?id=mbwkso.together.com" rel='noopener noreferrer' target='_blank'>
                    <img height={45} src={GooglePlayIcon} />
                  </a>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </Container>
    </Box>
  );
}
