import Box from 'Components/Box';
import Button from 'Components/Button';
import CreateNotificationForm from '../CreateNotificationForm';
import Heading from 'Components/Heading';
import styles from './styles.module.scss';
import { Trans } from '@lingui/macro';
import { useState } from 'react';

export default function SendNotificationHeader() {
  const [createNotificationModalOpen, setCreateNotificationModalOpen] = useState(false);

  return (
    <>
      <Box className={styles.header} dataTestId="location-header">
        <Box alignItems="center" display="flex" justifyContent="between" marginBottom={15}>
          <Box>
            <Heading size="sm">
              <Trans>
                Send Notification
              </Trans>
            </Heading>

          </Box>

          <Box alignItems="center" display="flex" justifyContent="between">
            <Box alignItems="center" display="flex">
              <Button
                onClick={() => setCreateNotificationModalOpen(open => !open)}
                size="sm"
              >
                <Trans>
                  Create Notification
                </Trans>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <CreateNotificationForm
        onCancelAction={() => setCreateNotificationModalOpen(false)}
        open={createNotificationModalOpen}
      />
    </>
  );
}