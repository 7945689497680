import Skeleton from '@material-ui/lab/Skeleton';
import { t } from '@lingui/macro';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConfirmationDialog, OrderButton, SortableList, SortableListItem, TextButton, useToast } from 'components';
import { Box, Button, ButtonGroup, Checkbox, IconButton, IconButtonProps, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Typography, useTheme } from '@mui/material';
import { DeleteOutlineRounded, DragIndicator, ModeEditOutlined, MoreVert } from '@mui/icons-material';
import { Room, RoomStatus, isSuccessAPIResponse, useGetFloorRoomsQuery, useUpdateRoomByIdMutation, useUpdateRoomsMutation } from 'store';
import { moveArrayItem, resolveArrayOrder } from 'utils';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { useTypedSelector } from 'Store/Redux/store';
import { difference } from 'underscore';
import RoomManagementForm from './RoomManagementForm';
import { AdminRoomStatusEnum, IAdminRoom } from 'Admin/Store/roomsManagement/models';
import { Order } from 'types';

const SortableListItemComponent: React.FC<{
  attributes: DraggableAttributes;
  listeners: SyntheticListenerMap | undefined;
  item: Room;
  locationId: string;
  isDragging?: boolean;
  onEdit?: (room: Room) => void,
}> = (props) => {
  const { attributes, listeners, item, isDragging, onEdit } = props;
  const { locationId, floorId } = useParams<{ locationId: string; floorId: string }>();
  const toast = useToast();
  const theme = useTheme();
  const amenities = useTypedSelector((state) => state.adminAttributes.attributesRoom);
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [amenitiesAnchorEl, setAmenitiesAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [amenityIds, setAmenityIds] = useState<string[]>([]);
  const [updateRoomById] = useUpdateRoomByIdMutation();

  useEffect(() => {
    if (item.amenities?.length) {
      setAmenityIds(item.amenities.map(({ id }) => id));
    }
  }, [JSON.stringify(item.amenities)]);

  useEffect(() => {
    if (amenityIds.length !== item.amenities?.length || difference(amenityIds, item.amenities ? item.amenities.map(({ id }) => id) : []).length) {
      const timeout = setTimeout(() => void (async () => {
        const response = await updateRoomById({ locationId, floorId, roomId: item.id, amenityIds });
        
        if (isSuccessAPIResponse(response)) {
          toast.showToast({ severity: "success", message: t`Room amenities were updated` });
        }
      })(), 700);
  
      return () => clearTimeout(timeout);
    }
  }, [JSON.stringify(amenityIds)]);

  const handleMoreClick: IconButtonProps["onClick"] = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setMoreAnchorEl(event.currentTarget);
  };

  const handleAmenityClick = (id: string) => {
    if (amenityIds.includes(id)) {
      setAmenityIds(amenityIds.filter((amenityId) => amenityId !== id));
    } else {
      setAmenityIds([...amenityIds, id]);
    }
  };

  const handleStatusChange = async (status: RoomStatus) => {
    if (status !== item.status) {
      const response = await updateRoomById({ locationId, floorId, roomId: item.id, status });
        
      if (isSuccessAPIResponse(response)) {
        toast.showToast({ severity: "success", message: t`Room status was updated` });
      }
    }

    setStatusAnchorEl(null);
  };

  const amenitiesLabel = item.amenities?.length ? item.amenities?.map(({ name }) => name)?.join(", ") : t`Not set`;

  return (
    <>
      <Box borderBottom="1px solid" borderColor={theme.palette.grey[100]} display="flex" sx={{ ":hover": { bgcolor: theme.palette.grey[100] } }}>
        <Box
          alignItems="center"
          display="flex"
          flexBasis={36}
          justifyContent="center"
          sx={{ cursor: isDragging ? "grabbing" : "grab" }}
          width={36}
          {...attributes}
          {...listeners}
        >
          <DragIndicator color="primary" fontSize="small" />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flex="1 0 auto"
          paddingY={1}
        >
          <Box width={260}>
            <Typography fontSize={14} maxWidth={244} noWrap title={item.id}>{item.id}</Typography>
          </Box>
          <Box width={340}>
            <Typography fontSize={14} maxWidth={324} noWrap title={item.name}>{item.name}</Typography>
          </Box>
          <Box flex={1}>
            <TextButton
              onClick={(event) => setStatusAnchorEl(event.currentTarget)}
              sx={{ fontWeight: 400, fontSize: 14 }}
            >
              {item.status === "AVAILABLE" ? t`Available` : t`Unavailable`}
            </TextButton>
          </Box>
          <Box width={360}>
            <TextButton onClick={(event) => setAmenitiesAnchorEl(event.currentTarget)} sx={{ maxWidth: 344 }} title={amenitiesLabel}>
              <Typography noWrap>{amenitiesLabel}</Typography>
            </TextButton>
          </Box>
          <Box flex={1}>
            <Typography fontSize={14}>{item.capacity || "-"}</Typography>
          </Box>
          <Box display="flex" flexBasis={50} justifyContent='center' width={50}>
            <IconButton color="primary" onClick={handleMoreClick} size="small"><MoreVert fontSize="small" /></IconButton>
          </Box>
        </Box>
      </Box>
      <Popover
        anchorEl={moreAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setMoreAnchorEl(null)}
        open={!!moreAnchorEl}
      >
        <Box display="flex" flexDirection="column" padding={1} width={200}>
          <ButtonGroup orientation="vertical" variant="text">
            <Button
              onClick={() => onEdit?.(item)}
              startIcon={<ModeEditOutlined />}
              sx={{ justifyContent: "flex-start", borderBottom: `1px solid ${theme.palette.grey[100]} !important` }}
            >
              {t`Edit Room`}
            </Button>
            <ConfirmationDialog
              Trigger={({ onClick }) => (
                <Button
                  onClick={onClick}
                  startIcon={<DeleteOutlineRounded />}
                  sx={{ justifyContent: "flex-start" }}
                >
                  {t`Delete room`}
                </Button>
              )}
              description={t`Are you sure you want to delete room ${item.name}?`}
              labels={{ no: t`Cancel`, yes: t`Delete` }}
              title={t`Delete room`}
            />
          </ButtonGroup>
        </Box>
      </Popover>
      <Popover
        anchorEl={statusAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setStatusAnchorEl(null)}
        open={!!statusAnchorEl}
      >
        <List disablePadding>
          <ListItem disablePadding divider>
            <ListItemButton onClick={() => void handleStatusChange(RoomStatus.AVAILABLE)}>
              <Typography fontSize={14}>{t`Available`}</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => void handleStatusChange(RoomStatus.UNAVAILABLE)}>
              <Typography fontSize={14}>{t`Unavailable`}</Typography>
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      <Popover
        anchorEl={amenitiesAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        onClose={() => setAmenitiesAnchorEl(null)}
        open={!!amenitiesAnchorEl}
      >
        <List disablePadding>
          {amenities.map(({ name, id }) => (
            <ListItem disablePadding key={id}>
              <ListItemButton dense onClick={() => handleAmenityClick(id)} role={undefined}>
                <ListItemIcon>
                  <Checkbox
                    checked={amenityIds.includes(id)}
                    disableRipple
                    edge="start"
                    inputProps={{ 'aria-labelledby': id }}
                    tabIndex={-1}
                  />
                </ListItemIcon>
                <ListItemText id={id} primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export const RoomsList: React.FC = () => {
  const { locationId, floorId } = useParams<{ locationId: string; floorId: string }>();
  const theme = useTheme();
  const toast = useToast();
  const [hasSorted, setHasSorted] = useState(false);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [room, setRoom] = useState<Room>();
  const nameOrder = useMemo(() => resolveArrayOrder(rooms.map(({ name }) => name)), [JSON.stringify(rooms.map(({ name }) => name))]);
  const getFloorRoomsQuery = useGetFloorRoomsQuery({ locationId, floorId, limit: -1, includeUnavailable: true });
  const { data, isLoading } = getFloorRoomsQuery;
  const { items } = data?.result?.data || {};
  const [updateRooms] = useUpdateRoomsMutation();

  useEffect(() => {
    setHasSorted(false);
  }, [locationId, floorId]);

  useEffect(() => {
    if (items?.length) {
      setHasSorted(false);
      setRooms(items);
    }
  }, [JSON.stringify(items)]);

  useEffect(() => {
    if (hasSorted && rooms.length) {
      const timeout = setTimeout(() => void (async () => {
        const orderedRooms = rooms.map(({ id }, sortIndex) => ({ id, sortIndex }));
        const response = await updateRooms({ locationId, floorId, rooms: orderedRooms });

        if (isSuccessAPIResponse(response)) {
          toast.showToast({ severity: "success", message: t`Rooms sorting updated` });
        }
      })(), 700);

      return () => clearTimeout(timeout);
    }
  }, [JSON.stringify(rooms), hasSorted]);

  const handleRoomsOrderChange = (current: number, next: number) => {
    setRooms(moveArrayItem(rooms, current, next));
    setHasSorted(true);
  };

  const handleNameOrderChange = (order: Order) => {
    if (rooms.length) {
      const ordered = order === "asc" ? rooms.map(({ name }) => name).sort() : rooms.map(({ name }) => name).sort().reverse();

      setRooms(ordered.map((name) => rooms.find((room) => room.name === name)) as Room[]);
      setHasSorted(true);
    }
  };

  return (
    <Box>
      <Box bgcolor={theme.palette.grey[100]} borderRadius={1} display="flex" marginY={1} paddingY={0.5}>
        <Box flexBasis={36} width={36} />
        <Box width={260}>
          <Typography color={theme.palette.grey[700]} fontSize={13}>{t`Room ID`}</Typography>
        </Box>
        <Box width={340}>
          <OrderButton onOrderChange={handleNameOrderChange} order={nameOrder} sx={{ display: "flex" }}>
            <Typography color={theme.palette.grey[700]} fontSize={13}>{t`Room name`}</Typography>
          </OrderButton>
        </Box>
        <Box flex={1}>
          <Typography color={theme.palette.grey[700]} fontSize={13}>{t`Status`}</Typography>
        </Box>
        <Box width={360}>
          <Typography color={theme.palette.grey[700]} fontSize={13}>{t`Amenities`}</Typography>
        </Box>
        <Box flex={1}>
          <Typography color={theme.palette.grey[700]} fontSize={13}>{t`Capacity`}</Typography>
        </Box>
        <Box flexBasis={50} width={50} />
      </Box>
      {isLoading ? <Skeleton height={46} variant="rect" width="100%" /> : undefined}
      {!isLoading && !rooms?.length ? (
        <Box paddingY={2}>
          <Typography color={theme.palette.grey[700]} textAlign="center">{t`No room found on this floor`}</Typography>
        </Box>
      ) : undefined}
      {rooms?.length ? (
        <SortableList items={rooms} onChange={handleRoomsOrderChange}>
          {rooms.map((item) => (
            <SortableListItem
              Component={(props) => <SortableListItemComponent {...props} item={item} locationId={locationId} onEdit={(room) => setRoom(room)} />}
              id={item.id}
              key={item.id}
            />
          ))}
        </SortableList>
      ) : undefined}
      <RoomManagementForm
        actionState={'edit'}
        onCancelAction={() => setRoom(undefined)}
        open={!!room}
        room={room ? ({
          id: room.id,
          name: room.name,
          capacity: room.capacity,
          status: room.status === RoomStatus.AVAILABLE ? AdminRoomStatusEnum.Available : AdminRoomStatusEnum.Unavailable,
          description: room.description,
          reservationDayLimit: room.reservationDayLimit,
          amenities: room.amenities,
          groups: room.groups,
          availableServices: room.availableServices,
        }) as IAdminRoom : undefined}
      />
    </Box>
  );
};