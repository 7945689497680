import { FloorMapObject } from "../../Admin/Store/floorMapDuck/models";

interface IGetAvailableDesksForOwner {
  selectedUserId?: string;
  desksArray: FloorMapObject[];
}

export function getAvailableDesksForOwner(props: IGetAvailableDesksForOwner): FloorMapObject[] {
  const { selectedUserId, desksArray } = props;

  if (!desksArray?.length) {
    return []; // if no data return empty array
  }

  if (!selectedUserId) {
    return desksArray; // return all desks if no specific user was chosen
  }

  // return desks with no owners or if selectedUserId includes in desk owners array
  return desksArray.filter(desk => !desk.owners?.length || desk.owners.some(owner => owner.id === selectedUserId));
}

interface IIsDeskAvailableForOwner {
  selectedUserId?: string;
  userId?: string;
  userGroups?: string[];
  desk: FloorMapObject;
}

export function isDeskAvailableForOwner(props: IIsDeskAvailableForOwner): boolean {
  const { selectedUserId, userId, userGroups, desk } = props;

  if (!selectedUserId || selectedUserId === userId) {
    return true; // return available if no specific user was chosen
  }

  // true with no owners or if selectedUserId includes in desk owners array
  const isDirectOwner = desk.owners?.length && desk.owners.some(owner => owner.id === selectedUserId);
  // true with no selected groups or if desk groups includes some user assigned groups
  const isDirectGroup = desk.groups?.length && desk.groups.some(group => userGroups?.includes(group.id));
  // true if desk has no restrictions
  const noRestrictions = !desk.owners?.length && !desk.groups?.length;

  return isDirectOwner || isDirectGroup || noRestrictions;
}

