import {useTypedSelector} from "../../../../Store/Redux/store";
import Box from "../../../../Components/Box";
import styles from "./styles.module.scss";
import {Trans, t} from "@lingui/macro";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import UploadCovidFile from "../../../../Components/UploadCovidFile";
import ProfileDocumentBodyItem from "./ProfileDocumentBodyItem";
import {UserDocument} from "../../../../Admin/Store/userManagement/models";
import Text from "../../../../Components/Text";
import {Skeleton} from "@material-ui/lab";

export default function ProfileDocumentsTable() {
  const { userDocuments, loading } = useTypedSelector(state => state.covidCertificate);

  return (
    <Box dataTestId="user-profile-documents-table">
      <Box className={styles.profileTableHeader} display="flex" justifyContent="between" marginBottom={10}>
        <h3><Trans>Documents</Trans></h3>
        {/* temp hide up to https://zira.zstream.io/app/tasks/task/IPG-1915 */}
        {/*<UploadCovidFile />*/}
      </Box>
      <TableContainer>
        <Table
          aria-label={t`users documents list`}
          className={styles.profileTable}
        >
          <TableHead classes={{ root: styles.tableHead }}>
            <TableRow classes={{ root: styles.tableRow }}>
              <TableCell classes={{ root: styles.tableCell }} padding="none">
                <Trans>Status</Trans>
              </TableCell>
              <TableCell classes={{ root: styles.tableCell }} padding="none">
                <Trans>Vax/Test date-Expire Date</Trans>
              </TableCell>
              <TableCell classes={{ root: styles.tableCell }} padding="none">
                <Trans>Verified date</Trans>
              </TableCell>
              <TableCell classes={{ root: styles.tableCell }} padding="none">
                <Trans>Uploaded date</Trans>
              </TableCell>
            </TableRow>
          </TableHead>

          {!loading &&
            <TableBody classes={{root: styles.tableBody}}>
              {userDocuments.length ?
                (userDocuments.map((document: UserDocument, index) => {
                    return <ProfileDocumentBodyItem document={document} key={index}/>;
                  })
                ) : null
              }
            </TableBody>
          }
        </Table>
      </TableContainer>
      {loading ? (
        <Box height={200}>
          <Skeleton height="100%" variant="rect" />
        </Box>
      ) : (
        !userDocuments.length && (
          <Box marginTop={50}>
            <Text align="center" color="lightGray" size="md">
              <Trans>No documents found</Trans>
            </Text>
          </Box>
        )
      )}
    </Box>
  );
}
