interface Props {
  color?: string;
  size?: number;
}

export default function ChevronDown({ color = '#4A4A4A', size = 10 }: Props) {
  return (
    <svg
      height={size}
      version="1.1"
      viewBox="0 0 14 10"
      width={size * 140 / 100}
    >
      <g
        fill="none"
        fillRule="evenodd"
        id="A-locations"
        stroke="none"
        strokeWidth="1"
      >
        <g
          id="A-Location-notifications-createnew"
          transform="translate(-478.000000, -316.000000)"
        >
          <rect
            fill="transparent"
            height="900"
            width="1440"
            x="0"
            y="0"
          ></rect>
          <g
            id="Fields/dd-r"
            transform="translate(230.000000, 269.000000)"
          >
            <rect
              height="34"
              id="Rectangle"
              rx="6"
              width="270"
              x="0"
              y="35"
            ></rect>
            <g
              fill={color}
              id="Group"
              transform="translate(248.000000, 47.000000)"
            >
              <g
                id="icons/arrow-down-w"
                transform="translate(7.000000, 5.000000) rotate(-90.000000) translate(-7.000000, -5.000000) translate(2.000000, -2.000000)"
              >
                <path
                  d="M7.15685425,0.692893219 C7.54737854,0.302368927 8.18054352,0.302368927 8.57106781,0.692893219 C8.9615921,1.08341751 8.9615921,1.71658249 8.57106781,2.10710678 L3.62132034,7.05685425 L8.57106781,12.0066017 C8.9615921,12.397126 8.9615921,13.030291 8.57106781,13.4208153 C8.18054352,13.8113396 7.54737854,13.8113396 7.15685425,13.4208153 L1.5,7.76396103 C1.10947571,7.37343674 1.10947571,6.74027176 1.5,6.34974747 L7.15685425,0.692893219 Z"
                  id="icons/arrow-down"
                  transform="translate(5.035534, 7.056854) rotate(-360.000000) translate(-5.035534, -7.056854) "
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
