import { Trans, t } from "@lingui/macro";
import { Tooltip } from "@material-ui/core";

import { isNumberString } from "class-validator";
import Box from "Components/Box";
import Info from "Components/Icons/Info";
import Text from "Components/Text";
import TextField from "Components/TextField";

interface Props {
  value?: number;
  setValue: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export default function ReservationDayLimit(props: Props) {
  const { value, setValue } = props;
  const reservationDayLimitValue = value ? `${value}` : "";
  const handleReservationDayLimitChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const { value = "" } = event.target;

    if (!value) {
      setValue(undefined);
    }

    if (isNumberString(value)) {
      const reservationDayLimitIntValue = Number.parseInt(value);

      if (reservationDayLimitIntValue > 0) {
        setValue(reservationDayLimitIntValue);
      }
    }
  };

  return (
    <Box>
      <Box alignItems="center" display="flex" marginBottom={4}>
        <Tooltip interactive={false} title={t`This change doesn't affect existing reservations`}>
          <span style={{ display: "inline-block", paddingRight: 4 }}>
            <Info size={12} />
          </span>
        </Tooltip>
        <Text color="black" size="md" weight="regular">
          <Trans>Reservation day limit:</Trans>
        </Text>
      </Box>
      <Text color="gray" size="md">
        <TextField
          className={`input input--default input--inline`}
          id="meeting-reservation-day-limit"
          onChange={handleReservationDayLimitChange}
          value={reservationDayLimitValue}
        />
      </Text>
    </Box>
  );
}
