import { Configuration } from '@azure/msal-browser';

export type CompanyAccount = 'MBWW/Kinesso' | 'MediaExperts' | 'Acxiom';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * 
 * Passes configuration depending on what
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: "8e702bce-44fe-4897-b356-b4354098b9bc",
    authority: "https://login.microsoftonline.com/d026e4c1-5892-497a-b9da-ee493c9f0364", // tenant ID
    redirectUri: `${location.origin}/redirect`,
    postLogoutRedirectUri: `${location.origin}/`,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

/**
 * Passes configuration depending on what Client we're using
 */
export function getMsalConfig(userAccount: CompanyAccount): Configuration {
  // Default configuration is for MBWW/Kinesso
  const config = { ...msalConfig };

  if (userAccount === 'MBWW/Kinesso') {
    config.auth.clientId = "8e702bce-44fe-4897-b356-b4354098b9bc";
    config.auth.authority = "https://login.microsoftonline.com/d026e4c1-5892-497a-b9da-ee493c9f0364";
  } else if (userAccount === 'MediaExperts') {
    config.auth.clientId = "2d4390fc-40f3-422d-a60a-9eb3ea2f68f0";
    config.auth.authority = "https://login.microsoftonline.com/5e26ebba-fc83-41e6-9917-3f9fce8b7dba"; // tenant ID
  }

  return config;
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [`https://graph.windows.net/.default`],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphRequest = {
  scopes: [`https://graph.microsoft.com/.default`],
};
