import {useDispatch} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {useTypedSelector} from "../../../../../../Store/Redux/store";
import {useState} from "react";
import {Trans, t} from "@lingui/macro";
import {setFloorMapData, updateAllDesks} from "../../../../../Store/floorMapDuck";
import {FloorMapSectionUpdate} from "../../../../../Store/floorMapDuck/models";
import Box from "../../../../../../Components/Box";
import Text from "../../../../../../Components/Text";
import styles from "../../styles.module.scss";
import Popup from "reactjs-popup";
import IconButton from "@material-ui/core/IconButton";
import ButtonBase from "@material-ui/core/ButtonBase";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CloseIcon from "@material-ui/icons/Close";
import MassActionsEditAmenities from "./MassActionsEditAmenities";
import MassActionsEditStatus from "./MassActionsEditStatus";
import TableSubmenuButton from "../../../../Buttons/TableSubmenuButton";
import MassActionsEditOwners from "./MassActionsEditOwners";
import MassActionsEditGroups from "./MassActionsEditGroups";

export default function AddFloorDeskListMassActions() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { floorId, locationId } = useParams<{ floorId: string; locationId: string; }>();
  const { adminFloorMap, config } = useTypedSelector(state => state);
  const [popupEditAmenitiesOpened, setPopupEditAmenitiesOpened] = useState(false);
  const [popupEditStatusOpened, setPopupEditStatusOpened] = useState(false);
  const [editOwnersIsOpen, setEditOwnersIsOpen] = useState(false);
  const [editGroupsIsOpen, setEditGroupsIsOpen] = useState(false);
  const { editDeskList } = adminFloorMap;
  const selectedDesksArray = Object.keys(editDeskList.selectedDesks).map(deskId => editDeskList.selectedDesks[deskId]);
  const deskListLocationPathname = `/admin/location/${locationId}/add-floor/${floorId}/desk-list`;

  const onClosePopupEdit = () => {
    setPopupEditAmenitiesOpened(false);
    setPopupEditStatusOpened(false);
  };
  
  // Update all desks status to selected one and closes modal
  const onUpdateAllDesks = (updateDeskProperties: FloorMapSectionUpdate) => {
    setPopupEditAmenitiesOpened(false);
    setPopupEditStatusOpened(false);
    dispatch(updateAllDesks({ updateDeskProperties }));
  };

  // unselect desks on mass action close click
  const handleUnselectDesks = () => {
    dispatch(setFloorMapData({
      editDeskList: {
        ...editDeskList,
        selectedDesks: {},
      },
    }));
  };

  return (
    <>
      {location.pathname === deskListLocationPathname &&
      <Box marginEnd={15}>
        <Box className={styles.massActionButton} dataTestId="floor-map-details-header-massActions">
          <Trans>
            Mass Action
          </Trans>

          <span className={styles.massActionCount}>
                  <span>{selectedDesksArray.length}</span>
                  <div
                    className={styles.massActionCountClose}
                    onClick={handleUnselectDesks}
                  >
                    <svg height="12px" viewBox="0 0 12 12" width="12px">
                          <g fill="none" fillRule="evenodd" id="UI" stroke="none" strokeWidth="1">
                              <g fill="#1E1F7B" id="UI-guidelines" transform="translate(-1101.000000, -2780.000000)">
                                  <g id="Group" transform="translate(478.000000, 2647.000000)">
                                      <g id="icons/close" transform="translate(623.000000, 133.000000)">
                                          <path d="M11.704633,0.295367042 C12.0984557,0.689189766 12.0984557,1.32770256 11.704633,1.72152528 L7.42615824,6 L11.704633,10.2784747 C12.0984557,10.6722974 12.0984557,11.3108102 11.704633,11.704633 C11.3108102,12.0984557 10.6722974,12.0984557 10.2784747,11.704633 L6,7.42615824 L1.72152528,11.704633 C1.32770256,12.0984557 0.689189766,12.0984557 0.295367042,11.704633 C-0.0984556808,11.3108102 -0.0984556808,10.6722974 0.295367042,10.2784747 L4.57384176,6 L0.295367042,1.72152528 C-0.0984556808,1.32770256 -0.0984556808,0.689189766 0.295367042,0.295367042 C0.689189766,-0.0984556808 1.32770256,-0.0984556808 1.72152528,0.295367042 L6,4.57384176 L10.2784747,0.295367042 C10.6722974,-0.0984556808 11.3108102,-0.0984556808 11.704633,0.295367042 Z"></path>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </svg>
                  </div>
                </span>

          <TableSubmenuButton
            iconButtonColor={'#ffffff'}
            iconButtonStyle={{ backgroundColor: config.theme.primary, borderRadius: 6, padding: 0 }}
          >
            <Box borderStyle="shadow" className="popover-content">
              <ButtonBase className="popover-item-button" onClick={() => setPopupEditAmenitiesOpened(true)}>
                <CreateOutlinedIcon style={{ color: config.theme.primary, fontSize: 20 }} />

                <Box marginStart={5}>
                  <Text color="blue" size="md">
                    <Trans>
                      Edit amenities
                    </Trans>
                  </Text>
                </Box>
              </ButtonBase>
              <ButtonBase className="popover-item-button" onClick={() => setPopupEditStatusOpened(true)}>
                <CreateOutlinedIcon style={{ color: config.theme.primary, fontSize: 20 }} />
                <Box marginStart={5}>
                  <Text color="blue" size="md">
                    <Trans>
                      Edit status
                    </Trans>
                  </Text>
                </Box>
              </ButtonBase>
              <ButtonBase className="popover-item-button" onClick={() => setEditOwnersIsOpen(true)}>
                <CreateOutlinedIcon style={{ color: config.theme.primary, fontSize: 20 }} />
                <Box marginStart={5}>
                  <Text color="blue" size="md">{t`Edit owners`}</Text>
                </Box>
              </ButtonBase>
              <ButtonBase className="popover-item-button" onClick={() => setEditGroupsIsOpen(true)}>
                <CreateOutlinedIcon style={{ color: config.theme.primary, fontSize: 20 }} />
                <Box marginStart={5}>
                  <Text color="blue" size="md">{t`Edit groups`}</Text>
                </Box>
              </ButtonBase>
            </Box>
          </TableSubmenuButton>
        </Box>
      </Box>
      }

      <Popup
        className="modal"
        closeOnDocumentClick
        contentStyle={{
          maxWidth: 410,
          width: '100%',
        }}
        nested
        onClose={onClosePopupEdit}
        open={popupEditAmenitiesOpened || popupEditStatusOpened}
      >
        <Box padding="2px 1px">
          <Box alignItems="center" display="flex" justifyContent="between">
            <Text size="xlg" weight="semi-bold">
              <Trans>
                Mass action
              </Trans>
            </Text>

            <IconButton
              onClick={onClosePopupEdit}
              size="small"
              style={{
                backgroundColor: config.theme.primaryLight,
                borderRadius: 8,
                height: 30,
                width: 30,
              }}
            >
              <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
            </IconButton>
          </Box>

          {popupEditAmenitiesOpened &&
            <MassActionsEditAmenities 
              onClosePopupEdit={onClosePopupEdit}
              onUpdateAllDesks={onUpdateAllDesks}
            />
          }
          {popupEditStatusOpened &&
              <MassActionsEditStatus
                onClosePopupEdit={onClosePopupEdit}
                onUpdateAllDesks={onUpdateAllDesks}
              />
          }
        </Box>
      </Popup>
      <MassActionsEditOwners isOpen={editOwnersIsOpen} onClose={() => setEditOwnersIsOpen(false)} />
      <MassActionsEditGroups isOpen={editGroupsIsOpen} onClose={() => setEditGroupsIsOpen(false)} />
    </>
  );
}
