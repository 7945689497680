import Api from 'Store/Services/Api';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CANCEL_MEETING_RESERVATION,
  CANCEL_MEETING_RESERVATION_FAIL,
  CANCEL_MEETING_RESERVATION_SUCCESS,
  CREATE_MEETING_RESERVATION,
  CREATE_MEETING_RESERVATION_FAIL,
  CREATE_MEETING_RESERVATION_SUCCESS,
  CancelMeetingReservation,
  CreateMeetingReservation,
  GET_MEETING_RESERVATIONS,
  GET_MEETING_RESERVATIONS_FAIL,
  GET_MEETING_RESERVATIONS_SUCCESS,
  GET_PREVIOUS_MEETING_RESERVATIONS,
  GET_PREVIOUS_MEETING_RESERVATIONS_FAIL,  
  GET_PREVIOUS_MEETING_RESERVATIONS_SUCCESS,
  GetMeetingReservations,
} from "./index";

function* getMeetingReservationsSaga(action: GetMeetingReservations): any {
  try {
    const payload = yield call(Api, action, false, true);

    yield put({ type: GET_MEETING_RESERVATIONS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_MEETING_RESERVATIONS_FAIL, payload: e });
  }
}

function* getPreviousMeetingReservationsSaga(action: GetMeetingReservations): any {
  try {
    const payload = yield call(Api, action, false, true);

    yield put({ type: GET_PREVIOUS_MEETING_RESERVATIONS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_PREVIOUS_MEETING_RESERVATIONS_FAIL, payload: e });
  }
}

function* createMeetingReservationSaga(action: CreateMeetingReservation): any {
  try {
    const payload = yield call(Api, action, false, true);

    yield put({ type: CREATE_MEETING_RESERVATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CREATE_MEETING_RESERVATION_FAIL, payload: e });
  }
}

function* cancelMeetingReservationSaga(action: CancelMeetingReservation): any {
  try {
    const payload = yield call(Api, action, false, true);

    yield put({ type: CANCEL_MEETING_RESERVATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CANCEL_MEETING_RESERVATION_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_MEETING_RESERVATIONS, getMeetingReservationsSaga),
  takeLatest(GET_PREVIOUS_MEETING_RESERVATIONS, getPreviousMeetingReservationsSaga),
  takeLatest(CREATE_MEETING_RESERVATION, createMeetingReservationSaga),
  takeLatest(CANCEL_MEETING_RESERVATION, cancelMeetingReservationSaga),
];