import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { setNotificationsData } from '../../../Store/notifications';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTypedSelector } from '../../../../Store/Redux/store';

export function SuccessAdminNotificationSnackbar() {
  const dispatch = useDispatch();
  const { successMessage } = useTypedSelector(state => state.adminNotifications);

  const handleClose = () => {
    dispatch(setNotificationsData({ successMessage: '' }));
  };

  useEffect(() => {
    return () => {
      dispatch(setNotificationsData({ successMessage: '' }));
    };
  }, []);

  return successMessage ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="success">
        {successMessage}
      </Alert>
    </Snackbar>
  ) : null;
}

export function ErrorAdminNotificationSnackbar() {
  const dispatch = useDispatch();
  const { error } = useTypedSelector(state => state.adminNotifications);

  const handleClose = () => {
    dispatch(setNotificationsData({ error: '' }));
  };

  useEffect(() => {
    return () => {
      dispatch(setNotificationsData({ error: '' }));
    };
  }, []);

  return error ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="error">
        {error}
      </Alert>
    </Snackbar>
  ) : null;
}
