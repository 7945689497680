import AppleIcon from '@material-ui/icons/Apple';
import Box from 'Components/Box';
import Button from 'Components/Button';
import Container from 'Components/Container';
import Heading from 'Components/Heading';
import styles from './styles.module.scss';
import Text from 'Components/Text';

export default function AppDownload() {
  return (
    <Box
      alignItems="center"
      backgroundColor="#f2f3f7"
      className={styles.appDownload}
      display="flex"
      minHeight="100vh"
    >
      <Container maxWidth="1200px">
        <Box
          direction="column"
          display="flex"
          justifyContent="center"
          margin="0 auto"
          maxWidth={450}
          padding="10px 20px"
        >
          <Heading size="lg">
            Welcome to Together
          </Heading>

          <Box marginBottom={40} marginTop={40}>
            <Box marginBottom={10}>
              <Text size="xlg">
                Before opening the Together app for the first time….
              </Text>
            </Box>

            <ol>
              <li>
                <Text>
                  Open the Settings app and navigate to General {'>'} Device Management (or Profiles & Device Management)
                </Text>
              </li>

              <li>
                <Text>
                  Select the developer certificate for Zazmic Inc
                </Text>
              </li>

              <li>
                <Text>
                  Click Trust on the details page
                </Text>
              </li>

              <li>
                <Text>
                  Accept the confirmation dialog. You’re now ready to use the app!
                </Text>
              </li>
            </ol>
          </Box>

          <Button
            className={styles.button}
            external
            size="sm"
            to="itms-services://?action=download-manifest&url=https://home.mbwkso-together.com/bookingipg/manifest.plist"
          >
            <AppleIcon style={{ fontSize: 30, marginRight: 5, position: 'relative', top: -2 }} />
            <Text color="white">Download</Text>
          </Button>

          <Box marginTop={20}>
            <Text align="center" color="orange" size="md">
              *Please ensure you are on the most recent version of iOS
            </Text>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
