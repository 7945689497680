import Api from 'Store/Services/Api';
import { GetFloorRoomScheduleResponse } from './models';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_FLOOR_ROOM_SCHEDULE,
  GET_FLOOR_ROOM_SCHEDULE_FAIL,
  GET_FLOOR_ROOM_SCHEDULE_SUCCESS,
  GetFloorRoomSchedule,
} from '.';

function* getFloorRoomSchedule(action: GetFloorRoomSchedule): any {
  try {
    const payload: GetFloorRoomScheduleResponse = yield call(Api, action, false, true);      

    yield put({ type: GET_FLOOR_ROOM_SCHEDULE_SUCCESS, payload });
  } catch (e) {    
    yield put({ type: GET_FLOOR_ROOM_SCHEDULE_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_FLOOR_ROOM_SCHEDULE, getFloorRoomSchedule),
];