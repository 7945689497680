import { t } from "@lingui/macro";
import { ReservationsList } from "components";
import { formatInTimeZone } from "date-fns-tz";
import { useDelegatedId } from "hooks";
import React from "react";
import { ReservationStatus, ReservationType } from "store";

export const HomePendingRoute: React.FC = () => {
  const delegatedId = useDelegatedId();

  return (
    <ReservationsList
      filter={{
        "status": ReservationStatus.PENDING,
        "schedule.entries.startDate": `$gt:${formatInTimeZone(new Date(), "UTC", "yyyy-MM-dd'T'HH:mm:00")}`,
        "type": `$in:${[ReservationType.DESK].join(",")}`,
      }}
      include={["attendees", "desk", "room", "schedule", "schedule.entries", "floor", "floor.location"]}
      limit={15}
      modifiable={!delegatedId}
      orderBy={["asc:schedule.entries.startDate"]}
      placeholder={t`No pending bookings`}
      userId={delegatedId}
    />
  );
};
