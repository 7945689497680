interface Props {
  color?: string;
  size?: number;
}

export default function QRCode({
  color = '#4A4A4A',
  size = 24,
}: Props) {
  return (
    <svg height={size} viewBox="0 0 24 24" width={size}>
      <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
        <g id="icons/qrcode">
          <path
            d="M17,18.980901 L17,19 C17,20.1045695 16.1045695,21 15,21 L7,21 C5.8954305,21 5,20.1045695 5,19 L5,5 C5,3.8954305 5.8954305,3 7,3 L15,3 C16.1045695,3 17,3.8954305 17,5 L17,5.0103597 L17,5.0103597"
            id="Path"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="2"
          ></path>
          <path
            d="M13,7 C13.5522847,7 14,7.44771525 14,8 C14,8.55228475 13.5522847,9 13,9 L12,9 L12,10 C12,10.5522847 11.5522847,11 11,11 C10.4477153,11 10,10.5522847 10,10 L10,8 C10,7.44771525 10.4477153,7 11,7 L13,7 Z"
            fill={color}
            id="Combined-Shape"
          ></path>
          <path
            d="M13,13 C13.5522847,13 14,13.4477153 14,14 C14,14.5522847 13.5522847,15 13,15 L12,15 L12,16 C12,16.5522847 11.5522847,17 11,17 C10.4477153,17 10,16.5522847 10,16 L10,14 C10,13.4477153 10.4477153,13 11,13 L13,13 Z"
            fill={color}
            id="Combined-Shape"
            transform="translate(12.000000, 15.000000) scale(1, -1) translate(-12.000000, -15.000000) "
          ></path>
          <path
            d="M19,7 C19.5522847,7 20,7.44771525 20,8 C20,8.55228475 19.5522847,9 19,9 L18,9 L18,10 C18,10.5522847 17.5522847,11 17,11 C16.4477153,11 16,10.5522847 16,10 L16,8 C16,7.44771525 16.4477153,7 17,7 L19,7 Z"
            fill={color}
            id="Combined-Shape"
            transform="translate(18.000000, 9.000000) scale(-1, 1) translate(-18.000000, -9.000000) "
          ></path>
          <path
            d="M19,13 C19.5522847,13 20,13.4477153 20,14 C20,14.5522847 19.5522847,15 19,15 L18,15 L18,16 C18,16.5522847 17.5522847,17 17,17 C16.4477153,17 16,16.5522847 16,16 L16,14 C16,13.4477153 16.4477153,13 17,13 L19,13 Z"
            fill={color}
            id="Combined-Shape"
            transform="translate(18.000000, 15.000000) scale(-1, -1) translate(-18.000000, -15.000000) "
          ></path>
          <rect
            fill={color}
            height="4"
            id="Rectangle"
            width="4"
            x="13"
            y="10"
          ></rect>
        </g>
      </g>
    </svg>
  );
}
