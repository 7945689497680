import DocStatusFilter from './DocStatusFilter';
import LocationsFilter from './LocationsFilter';
import StatusFilter from './StatusFilter';
import { VisitFiltersModel, filterStatusType, locationIdsType } from 'Admin/Store/visits/models';
import { setVisitsData } from 'Admin/Store/visits';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { UserDocumentStatus } from '../../../Store/userManagement/models';
import { useTypedSelector } from '../../../../Store/Redux/store';
import { checkingType } from "../../../Store/reservations/models";
import CheckinFilter from "./CheckinFilter";
import FilterModal from "../../../Components/Filters/FilterModal";

function getFilterCount(filters: VisitFiltersModel): number {
  let count = 0;

  if (filters.statuses && filters.statuses.length) {
    count++;
  }

  if (filters.locationIds && filters.locationIds.length) {
    count++;
  }

  if (filters.documentStatuses && filters.documentStatuses.length) {
    count++;
  }

  if (filters.checking !== undefined) {
    count++;
  }

  return count;
}

export default function AppointmentsFilters() {
  const dispatch = useDispatch();
  const { filters: reduxFilters } = useTypedSelector(state => state.adminVisits);
  const initialFiltersState: { [key in filterStatusType]: boolean } = {
    BOOKED: false,
    PENDING: false,
    CANCELED: false,
    PAST: false,
  };
  const initialDocStatusFiltersState: { [key in UserDocumentStatus]: boolean } = {
    approved: false,
    denied: false,
    needsApproval: false,
    expired: false,
    notUploaded: false,
  };
  const initialCheckingFiltersState: { [key in checkingType]: boolean } = {
    yes: false,
    no: false,
  };
  const [statusFilters, setStatusFilters] = useState<{ [key in filterStatusType]: boolean }>(initialFiltersState);
  const [docStatusFilters, setDocStatusFilters] = useState<{ [key in UserDocumentStatus]: boolean }>(initialDocStatusFiltersState);
  const [checkingFilters, setCheckingFilters] = useState<{ [key in checkingType]: boolean }>(initialCheckingFiltersState);
  const [locationIds, setLocationIds] = useState<locationIdsType>([]);
  const [locationSearchText, setLocationSearchText] = useState('');
  const filterCount = getFilterCount(reduxFilters);

  // on open the model set only data which was saved by onSubmitFilters
  const setSavedData = () => {
    const newStatusState = {...initialFiltersState};
    const newDocStatusState = {...initialDocStatusFiltersState};
    const newCheckingState = {...initialCheckingFiltersState};

    reduxFilters.statuses?.map(status => {
      newStatusState[status] = true;
    });
    reduxFilters.documentStatuses?.map(status => {
      newDocStatusState[status] = true;
    });
    if (reduxFilters.checking !== undefined) {
      reduxFilters.checking ? newCheckingState.yes = true : newCheckingState.no = true;
    }

    setStatusFilters(newStatusState); // set saved state from redux
    setDocStatusFilters(newDocStatusState); // set saved state from redux
    setLocationIds(reduxFilters.locationIds); // set saved state from redux
    setLocationSearchText(''); // clear text input
    setCheckingFilters(newCheckingState); // set saved state from redux
  };

  const getCheckinValue = () => {
    const checkinArray: checkingType[] = checkingFilters && Object.keys(checkingFilters).map(key => key as checkingType).filter(key => checkingFilters[key as checkingType]);
    if (checkinArray?.length === 1) {
      return checkinArray[0] === 'yes'; // return true if approved and false if notApproved
    }

    return undefined;
  };

  const onSubmitFilters = () => {
    const filtersArray = Object.keys(statusFilters).map((key: any) => key).filter((key: filterStatusType) => statusFilters[key]);
    const docStatusFiltersArray = Object.keys(docStatusFilters).map((key: any) => key).filter((key: UserDocumentStatus) => docStatusFilters[key]);
    const checkinValue = getCheckinValue();

    dispatch(setVisitsData({
      filters: {
        ...reduxFilters,
        statuses: filtersArray,
        documentStatuses: docStatusFiltersArray,
        checking: checkinValue,
        locationIds,
      },
    }));
  };

  const onResetFilters = () => {
    setStatusFilters(initialFiltersState);
    setDocStatusFilters(initialDocStatusFiltersState);
    setCheckingFilters(initialCheckingFiltersState);
    setLocationIds([]);
    dispatch(setVisitsData({
      filters: {
        ...reduxFilters,
        statuses: [],
        documentStatuses: [],
        checking: undefined,
        locationIds: [],
      },
    }));
  };

  return (
    <FilterModal
      filterCount={filterCount}
      onResetFilters={onResetFilters}
      onSubmitFilters={onSubmitFilters}
      setSavedData={setSavedData}
    >
      <LocationsFilter locationIds={locationIds} locationSearchText={locationSearchText} setLocationIds={setLocationIds} setLocationSearchText={setLocationSearchText} />
      <StatusFilter filters={statusFilters} initialFiltersState={initialFiltersState} setFilters={setStatusFilters} />
      <DocStatusFilter filters={docStatusFilters} initialFiltersState={initialDocStatusFiltersState} setFilters={setDocStatusFilters} />
      <CheckinFilter filters={checkingFilters} initialFiltersState={initialCheckingFiltersState} setFilters={setCheckingFilters} />
    </FilterModal>
  );
}
