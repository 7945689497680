import AddFloorHeader from "../../Components/Header/AddFloorHeader";
import AddFloorDataUpdate from "../../Components/Header/AddFloorHeader/Components/AddFloorDataUpdate";
import AddFloorActionMenu from "../../Components/Header/AddFloorHeader/Components/AddFloorActionMenu";
import AddFloorRoomData from "../../Components/Header/AddFloorHeader/Components/AddFloorRoomData";
import { ErrorRoomsManagementSnackbar, SuccessRoomsManagementSnackbar } from "./SnackBars";
import { RoomsList } from "./rooms-list";

export default function RoomsManagement() {
  return (
    <>
      <SuccessRoomsManagementSnackbar />
      <ErrorRoomsManagementSnackbar />
      <AddFloorHeader>
        <AddFloorDataUpdate />
        <AddFloorRoomData />
        <AddFloorActionMenu />
      </AddFloorHeader>
      <RoomsList />
    </>
  );
}