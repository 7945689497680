import { isBefore } from "date-fns";
import { MinuteStep } from "types";
import { isEqual } from "underscore";
import { ceiledCalendarViewDate } from "./ceiled-calendar-view-date";

export const isCalendarViewScheduleVisible = (end: Date, step: MinuteStep, firstStep: Date): boolean => {
  const ceiledEnd = ceiledCalendarViewDate(end, step);

  if (isEqual(ceiledEnd, firstStep) || isBefore(ceiledEnd, firstStep)) {
    return false;
  }

  return true;
};
