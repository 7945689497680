import { MeetingStatus, MeetingStatusEnum } from "./models";
import { t } from "@lingui/macro";

export const MEETING_STATUS_TO_EDIT: Array<{ name: string; value: MeetingStatus }> = [
  {
    name: t`Available`,
    value: MeetingStatusEnum.Available,
  },
  {
    name: t`Unavailable`,
    value: MeetingStatusEnum.Unavailable,
  },
];
