import Box from 'Components/Box';
import Text from 'Components/Text';
import TimeSlots from '../TimeSlots';
import { DateRange, Range, RangeKeyDict } from 'react-date-range';
import { Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';

export default function SupportBooking() {
  const { config, createNewBooking } = useTypedSelector(state => state);

  const { dateFrom, dateTo } = createNewBooking;

  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: dateFrom ? new Date(dateFrom) : new Date(),
    endDate: dateTo ? new Date(dateTo) : new Date(),
    key: 'selection',
  });

  const handleSelect = (ranges: RangeKeyDict) => {
    setSelectionRange(ranges.selection);

    // add dispatch here after backend update
  };


  // Updates selection range when redux data changes
  useEffect(() => {
    setSelectionRange({
      startDate: dateFrom ? new Date(dateFrom) : new Date(),
      endDate: dateTo ? new Date(dateTo) : new Date(),
      key: 'selection',
    });
  }, [dateFrom, dateTo]);

  return (
    <Box>
      <DateRange
        className="custom-calendar"
        minDate={new Date()}
        onChange={handleSelect}
        rangeColors={[config.theme.primaryLight]}
        ranges={[selectionRange]}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
      />

      <Box marginBottom={14} marginTop={10}>
        <Text size="md" weight="semi-bold">
          <Trans>Time slot</Trans>
        </Text>
        <TimeSlots />
      </Box>
    </Box>
  );
}
