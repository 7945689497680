import Box from 'Components/Box';
import Slider from '@material-ui/core/Slider';
import styles from './styles.module.scss';
import { t } from '@lingui/macro';

const HOURS = [
  {
    value: 6,
    label: t`6 AM`,
  },
  {
    value: 9,
    label: t`9 AM`,
  },
  {
    value: 12,
    label: t`12 PM`,
  },
  {
    value: 15,
    label: t`3 PM`,
  },
  {
    value: 18,
    label: t`6 PM`,
  },
  {
    value: 21,
    label: t`9 PM`,
  },
];

interface Props {
  hours: number;
  setHours: (hours: number) => void;
}

export default function HourSlider({ hours, setHours }: Props) {
  const handleChange = (_: any, newValue: any) => {
    setHours(newValue);
  };

  return (
    <Box className={styles.hourSlider}>
      <Slider
        classes={{
          mark: styles.mark,
          markActive: styles.markActive,
          markLabel: styles.markLabel,
          root: styles.slider,
          rail: styles.rail,
          thumb: styles.thumb,
          track: styles.track,
        }}
        marks={HOURS}
        max={21}
        min={6}
        onChange={handleChange}
        step={0.25}
        value={hours}
      />
    </Box>
  );
}
