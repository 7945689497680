import Box from 'Components/Box';
import clsx from 'clsx';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import styles from './styles.module.scss';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import {
  ContentState,
  EditorState,
  convertToRaw,
} from 'draft-js';

interface Props extends EditorProps {
  classes?: {
    editorWrapper?: string;
  };
  dataTestId?: string;
  errorMessage?: string;
  onChangeText: (text: string, plainText: string) => void;
  initialText: string;
}

export default function HtmlEditor({
  ariaLabel,
  classes,
  dataTestId,
  errorMessage,
  onChangeText,
  initialText,
}: Props) {
  const { contentBlocks, entityMap } = htmlToDraft(initialText);
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const { language, themeName } = useTypedSelector(state => state.config);

  const onEditorStateChange = (editorState: EditorState) => {
    const editorText = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const plainText = editorState.getCurrentContent().getPlainText().trim();

    setEditorState(editorState);
    onChangeText(editorText, plainText);
  };

  const editWrapperClasses = clsx(styles.editorWrapper, classes?.editorWrapper, {
    'theme-blue': themeName === 'ogBlue',
    'theme-dark-blue': themeName === 'ipg',
    'theme-green': themeName === 'green',
    [styles.formErrorShow]: errorMessage,
  });

  useEffect(() => {
    onEditorStateChange(editorState);
  }, []);

  return (
    <Box className={styles.htmlEditor} dataTestId={dataTestId}>
      <Editor
        ariaLabel={ariaLabel}
        defaultEditorState={editorState}
        editorClassName={styles.editor}
        localization={{
          locale: language.value,
        }}
        onEditorStateChange={onEditorStateChange}
        placeholder={t`Type here`}
        toolbar={{
          options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'history'],
        }}
        toolbarClassName={styles.toolbar}
        wrapperClassName={editWrapperClasses}
      />

      {errorMessage && (
        <Box className={styles.formErrors}>
          {errorMessage}
        </Box>
      )}
    </Box>
  );
}
