import { togetherApi } from "../together-api";
import { APIResponseBody, GetGroupsInput, Group } from "../types";

const groups = togetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query<APIResponseBody<{ groups: Group[] }>, GetGroupsInput | void>({
      query: ({ search } = {}) => ({
        url: `/api/groups`,
        params: { search },
      }),
    }),
  }),
});

export const { useGetGroupsQuery, useLazyGetGroupsQuery } = groups;
