import { Room as RoomModel } from "../../../../../Store/Meetings/meetingRooms/models";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../../Store/Redux/store";
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styles from "../styles.module.scss";
import Switch from "@material-ui/core/Switch";
import { MeetingReservationSelectedData } from 'App/Store/Meetings/createNewMeetingDuck/models';
import { t } from "@lingui/macro";
import _ from "underscore";

type meetingSwitcherType = 'catering' | 'itSupport' | 'teamMeeting';

interface Props {
  room: RoomModel | undefined;
  type: meetingSwitcherType;
}

export default function MeetingSwitcher(props: Props) {
  const dispatch = useDispatch();
  const { selectedData } = useTypedSelector(state => state.createNewMeeting);
  const { requestedServices } = selectedData;
  const { room, type } = props;

  const getLabel = (type: meetingSwitcherType): string => {
    switch (type) {
      case "catering":
        return t`I need catering`;
      case "itSupport":
        return t`I need IT support`;
      case "teamMeeting":
        return t`Teams meeting`;
      default:
        return '';
    }
  };

  const getChecked = (type: meetingSwitcherType): boolean => {
    const data: MeetingReservationSelectedData = { ...selectedData };

    switch (type) {
      case "catering":
        return data.requestedServices.some((service => service === 'catering'));  
      case "itSupport":
        return data.requestedServices.some((service => service === 'it-support'));
      case 'teamMeeting':
        return data.isTeamsMeeting;
      default:
        return false;
    }
  };

  const onChange = _.debounce((event: any) => {
    const isActive = event.target.checked;    

    const data: MeetingReservationSelectedData = { ...selectedData };
    switch (type) {
      case "catering":
        data.requestedServices = isActive ? [...requestedServices].filter(service => service !== 'catering'):
          [...requestedServices, 'catering'];
        break;
      case "itSupport":
        data.requestedServices = isActive ? [...requestedServices].filter(service => service !== 'it-support'):
          [...requestedServices, 'it-support'];
        break;
      case "teamMeeting":
        data.isTeamsMeeting = !isActive;
        break;
      default:
        return false;
    }

    dispatch(setCreateNewMeetingData({
      selectedData: {
        ...selectedData,
        ...data,
      },
    }));
  }, 300);  

  const label = getLabel(type);
  const checked = getChecked(type);

  return (
    <FormControlLabel      
      checked={checked}
      classes={{label: styles.switchLabel}}
      control={
        <Switch
          className="switcher switcher--default"
          name={label}
          onChange={onChange}
        />}
      disabled={!room}
      label={label}
    />
  );
}