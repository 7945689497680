interface Props {
  color?: string;
  size?: number;
}

export default function Pen({ color = '#4A4A4A', size = 25 }: Props) {
  return (
    <svg
      height={size}
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
        <g fill={color} fillRule="nonzero" id="A-Location-email-templates" transform="translate(-1376.000000, -155.000000)">
          <g id="icons/editpencilgrey" transform="translate(1376.000000, 155.000000)">
            <path
              d="M17.2077147,4.29289322 L19.329035,6.41421356 C19.7195593,6.80473785 19.7195593,7.43790283 19.329035,7.82842712 L9.42954011,17.7279221 L5.89400621,14.1923882 L15.7935011,4.29289322 C16.1840254,3.90236893 16.8171904,3.90236893 17.2077147,4.29289322 Z M16.5006079,6.41421356 L8.72243333,14.1923882 L9.42954011,14.8994949 L17.2077147,7.12132034 L16.5006079,6.41421356 Z M5.31649907,19.5703403 C5.11123226,19.6387625 4.88931034,19.6387625 4.68404354,19.5703403 C4.16010022,19.3956925 3.87694023,18.8293725 4.051588,18.3054292 L5.18689943,14.8994949 L8.72243333,18.4350288 L5.31649907,19.5703403 Z"
              id="pen"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
