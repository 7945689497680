import BookingDetails from '../../CreateNewBooking/BookingDetails';
import Box from 'Components/Box';
import Button from 'Components/Button';
import LocationItem, { LocationItemLoading } from './LocationItem';
import styles from './styles.module.scss';
import bookingStyles from '../styles.module.scss';
import Text from 'Components/Text';
import { getMeetingReservations, selectMoreOftenLocation } from 'App/Store/Meetings/meetingReservations';
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';
import { StaggeredItems } from 'Components/Animations/StaggeredItems';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import Divider from "../../../../Components/Divider";
import CovidDocConfirmation from "../../CreateNewBooking/CovidDocConfirmation";
import { isLocationRequiredCovidDocument } from "../../../Functions/Helpers";
import { setBookingCovidConfirmationData } from "../../../Store/Bookings/covidConfirmationDuck";
import { getUserCovidDocRestrictions, getUserIdForBooking } from "../../../Store/Users/executiveAssistant/helpers";
import moment from 'moment';
import { IMeetingReservationLocation } from 'App/Store/Meetings/meetingReservations/models';
import MeetingBreadcrumbs from '../MeetingBreadcrumbs';
import ReservationRegionSearch from "../../../Components/ReservationRegionSearch";

export default function MeetingLocation() {
  const dispatch = useDispatch();
  const { createNewMeeting,
          meetingReservations,
          bookingCovidConfirmation,
          locations: locationsDuck,
          profile,
          executiveAssistant } = useTypedSelector(state => state);

  const { selectedData } = createNewMeeting;
  const { location } = selectedData;

  const { disableFloorPage } = bookingCovidConfirmation;
  const { loading } = locationsDuck;

  const { actualCovidDocument, approvedAccommodation } = getUserCovidDocRestrictions(profile, executiveAssistant);
  const selectedUserId = getUserIdForBooking(profile, executiveAssistant);
  const defaultLocation = selectMoreOftenLocation(meetingReservations);

  const bookingPath = 'create-new-meeting';
  const detailTitle = t`Meeting Details`;
  const nextButtonPath = disableFloorPage ? undefined : `/${bookingPath}/select-floor`;

  // Sort locations so if there's a default, it's always the first one
  const locations = locationsDuck.locations.sort(a => {
    if (a.id === defaultLocation?.id) {
      return -1;
    }

    return 0;
  });

  // If there're no bookings loaded, tries to load them
  // This is necessary if the user enter this route without
  // navigating on the first page, so we can know what is the default location
  useEffect(() => {
    const hasReservations = Object.keys(meetingReservations.meetingReservations).length > 0;

    if (!hasReservations) {
      const data = {
        limit: 20,
        page: 1,
        filter: `[schedule.startDate]=$lt:${moment().format('YYYY-MM-DD')}`,
        clearData: true,
      };

      dispatch(getMeetingReservations({
        ...data,
      }));
    }
  }, [selectedUserId]);  

  // If there's a defaultLocation, automatically assign it to selected
  useEffect(() => {
    if (defaultLocation) {      
      dispatch(setCreateNewMeetingData({
        selectedData: { 
          ...selectedData,
          location: defaultLocation,
        }}));
    }
  }, [defaultLocation]);

  /**
   * Set location on redux
   */
  const onChangeLocation = (location: IMeetingReservationLocation) => {
    dispatch(setCreateNewMeetingData({  
        selectedData: {
          ...selectedData,
          location, 
        }}));
  };

  const onClickNext = () => {
    const locationId = location?.id;

    const isRequired = isLocationRequiredCovidDocument(locations, locationId, actualCovidDocument, approvedAccommodation);
    if (isRequired) {
      dispatch(setBookingCovidConfirmationData({ openCovidModal: true }));
    }
  };

  return (
    <Box display="grid" gap={30} gridTemplateColumns="8fr 4fr" paddingBottom={50}>
      <Box>
        <Box marginBottom={15}>
          <MeetingBreadcrumbs />          
        </Box>

        <Box marginBottom={15}>
          <ReservationRegionSearch withRoomsOnly />
        </Box>

        {loading ? (
          <LocationItemLoading />
        ) : (
          <Box className={styles.locationList} dataTestId="location-list" display="grid" gap={15}>
            {locations.length > 0 ? (
              <StaggeredItems className={styles.items}>
                {locations.map((location, index) => (
                  <LocationItem
                    {...location}
                    isDefault={defaultLocation?.id === location.id}
                    key={location.id + index}
                    onClick={onChangeLocation}
                    selected={selectedData.location?.id === location.id}
                  />
                ))}

              </StaggeredItems>
            ): (
              <Box
                alignItems="center"
                display="flex"
                height="100%"
                justifyContent="center"
                margin="20px 0 100px"
              >
                <Text align="center" color="gray" size="md">
                  {t`There're no locations for this region and search`}
                </Text>
              </Box>
            )}

            <Box className={styles.stickyButtonRow} display="flex" justifyContent="end" marginTop={5}>
              <Button
                aria-label={t`next`}
                disabled={!location}
                name={t`next`}
                onClick={() => onClickNext()}
                size="sm130"
                to={nextButtonPath}
              >
                <Trans>Next</Trans>
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <Box className={bookingStyles.stickyBookingWrapper}>
        <Box>
          <Text size="xlg" weight="semi-bold">
            { detailTitle }            
          </Text>
        </Box>

        <Box className={bookingStyles.stickyBookingLocationRow}>
          <Box marginBottom={10}>
            <Divider />
          </Box>          
          <BookingDetails type={'meeting'} />
        </Box>
      </Box>
      <CovidDocConfirmation locationId={location?.id} />
    </Box>
  );
}