import Box from 'Components/Box';
import L from 'leaflet';
import MapDrawingRoom from './MapDrawingRoom';
import {getImageBoundsLimited, getMapMinZoomValue} from './Helpers/mapFunctions';
import { MAP_HEIGHT } from './Helpers/consts';
import { useEffect, useRef, useState } from 'react';
import 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import {
  ImageOverlay,
  MapContainer,
} from 'react-leaflet';
import { useTypedSelector } from 'Store/Redux/store';
import { MapImageBoundsType } from 'Admin/Store/floorMapDuck/models';

interface Props {
  mapImageBounds: MapImageBoundsType | null;
  mapSize: {
    height: number;
    width: number;
  };
  name: string;
  previewUrl: string;
  roomId: string;
}

export default function Map({ mapImageBounds, mapSize, name, previewUrl, roomId }: Props) {
  const { adminFloorMap, adminFloorMapApiRequests, createNewBooking } = useTypedSelector(state => state);
  const { height = 100, width = 100 } = mapSize;  

  // We use this key to set the map size correctly after it's rendered.
  // Ref: https://zira.zstream.io/app/tasks/task/IPG-772
  const [key, setKey] = useState(0);
  const [map, setMap] = useState<L.Map | null>(null);
  const imageRef = useRef<L.ImageOverlay>(null);
  const [mapCentered, setMapCentered] = useState(false);
  const [minZoom, setMinZoom] = useState(0);
  const desk = createNewBooking.deskId ? adminFloorMap.desks[createNewBooking.deskId] : null;
  // @ts-ignore its correct type for bounds // use getImageBoundsLimited for support old image bounds format
  const mapBounds: L.LatLngBoundsExpression = mapImageBounds ? mapImageBounds : getImageBoundsLimited({ height, proportion: 100, width });

  useEffect(() => {
    if (imageRef.current && map && !mapCentered) {
      const mapImage = imageRef.current;

      // @ts-ignore Typescript is not recognizing this objects. It's correct though
      const containerSize = mapImage._map._size;      

      const mapMinZoom = getMapMinZoomValue({
        imageSize: { height, width },
        containerSize: { height: containerSize.y, width: containerSize.x },
      });

      setMinZoom(mapMinZoom);
      setMapCentered(true);
    }
  }, [imageRef.current, map]);

  useEffect(() => {
    if (imageRef.current && map) {
      const deskCoordinates = desk?.coordinatesInLatLngBounds[0];
      const mapImage = imageRef.current;
      const imageBounds = mapImage.getBounds();

      // Set the map bounds to the image uploaded. This will center the map correctly.
      if (deskCoordinates) {
        // centered for created booking (up to selected desk)
        map.setView(deskCoordinates, map.getZoom());
      } else {
        map.fitBounds(imageBounds);
      }
    }
  }, [imageRef.current, map, desk, minZoom]);

  useEffect(() => {
    if (!adminFloorMapApiRequests.loading) {
      setKey(prev => prev + 1);
    }
  }, [adminFloorMapApiRequests.loading, minZoom]);

  return (
    <>
      <Box dataTestId="map" height={MAP_HEIGHT} id="mapid">
        <MapContainer
          attributionControl={false}
          boundsOptions={{ padding: [0, 0] }}
          center={[50, 50]}
          className="app-map"
          crs={L.CRS.Simple}
          key={key}
          maxZoom={5}
          minZoom={minZoom}
          // scrollWheelZoom={false}
          style={{ height: MAP_HEIGHT }}
          whenCreated={setMap}
          zoom={desk ? 0 : minZoom}
          zoomSnap={0}
        >
          {previewUrl && !adminFloorMapApiRequests.loading &&
            <ImageOverlay
              alt={`floor ${name}`}
              bounds={mapBounds}
              key={key}
              ref={imageRef}
              url={previewUrl}
            />
          }

          {
            !adminFloorMapApiRequests.loading &&
            <MapDrawingRoom roomId={roomId} />
          }
        </MapContainer>
      </Box>
    </>
  );
}