import React from "react";
import { SortableListItemProps } from "./types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const SortableListItem: React.FC<SortableListItemProps> = (props) => {
  const { id, Component } = props;
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Component attributes={attributes} isDragging={isDragging} listeners={listeners} />
    </div>
  );
};
