import Box from 'Components/Box';
import Button from 'Components/Button';
import CustomBooking from '../CustomBooking';
import DailyBooking from '../DailyBooking';
import datetimeIcon from '../assets/datetime.png';
import Divider from '../../../../../Components/Divider';
import styles from '../styles.module.scss';
import accordionStyles from './styles.module.scss';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Text from 'Components/Text';
import WeeklyBooking from '../WeeklyBooking';
import { BookingType } from 'App/Store/Bookings/bookingDuck/models';
import {getClosestAvailableDay, getClosestStartDate} from '../../../../Functions/Helpers';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import {useEffect, useState} from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";

function a11yProps(index: number) {
  return {
    id: `calendar-tab-${index}`,
    'aria-controls': `calendar-tab-panel-${index}`,
  };
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`calendar-tab-${index}`}
      hidden={value !== index}
      id={`calendar-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && children}
    </div>
  );
}

interface AccordionCalendarProps {
  selectedDesk: boolean;
}

/**
 * Calendar component to select date and time ranges.
 */
export default function AccordionCalendar(props: AccordionCalendarProps) {
  const dispatch = useDispatch();
  const { selectedDesk } = props;

  const [expanded, setExpanded] = useState(true);
  const { locations, config } = useTypedSelector(state => state);
  const { bookingType, locationId, weeklySlots } = useTypedSelector(state => state.createNewBooking);
  const selectedLocation = locations.locations.find(location => location.id === locationId);

  useEffect(() => {
    if (selectedDesk) {
      setExpanded(false);
    }
  }, [selectedDesk]);

  const getBookingTypeNumber = (bookingType: BookingType): number => {
    let bookingTypeNumber = 0;

    switch (bookingType) {
      case 'daily': {
        bookingTypeNumber = 1;
        break;
      }

      case 'weekly': {
        bookingTypeNumber = 2;
        break;
      }

      default:
        break;
    }

    return bookingTypeNumber;
  };

  const [activeTab, setActiveTab] = useState(getBookingTypeNumber(bookingType));

  const handleSetActiveTab = (_: React.ChangeEvent<{}>, value: any) => {
    setActiveTab(value);

    let bookingType: BookingType = 'custom';

    switch (value) {
      case 1: {
        bookingType = 'daily';
        break;
      }

      case 2: {
        bookingType = 'weekly';
        break;
      }

      default:
        break;
    }

    dispatch(setCreateNewBookingData({ bookingType }));
  };

  const handleResetClick = (e: any) => {
    e.stopPropagation(); // to not handle accordion
    const closestStartDay = selectedLocation ? getClosestStartDate(selectedLocation?.allowedBookingDayType) : new Date();
    const closestDay = getClosestAvailableDay(closestStartDay, locations.locationDisabledDays);
    
    dispatch(setCreateNewBookingData({
      dateFrom: closestDay,
      dateTo: closestDay,
      timeFrom: new Date(1970, 0 , 1, 9),
      timeTo: new Date(1970, 0 , 1, 18),
      weeklySlots: weeklySlots?.map((weeklySlot) => ({
        ...weeklySlot,
        isSelected: false,
        timeFrom: new Date(1970, 0 , 1, 9),
        timeTo: new Date(1970, 0 , 1, 18),
      })),
    }));
  };

  const onToggleExpanded = () => {
    setExpanded(expanded => !expanded);
  };

  return (
    <Accordion
      classes={{expanded: accordionStyles.accordionExpanded, root: accordionStyles.accordion}}
      expanded={expanded}
    >
      <>
        <AccordionSummary
          aria-controls="panel1a-content"
          classes={{
            content: accordionStyles.accordionSummaryContent,
            expandIcon: accordionStyles.accordionSummaryIcon,
            root: accordionStyles.accordionSummary,
          }}
          expandIcon={
            <svg height="18px" version="1.1" viewBox="0 0 10 18" width="10px">
              <g fill="none" fillRule="evenodd" id="A-reservations" stroke="none" strokeWidth="1">
                <g id="A-Reservations" transform="translate(-1390.000000, -176.000000)">
                  <rect fill="transparent" height="900" width="1440" x="0" y="0"></rect>
                  <g fill={config.theme.primary} id="Header" transform="translate(1030.000000, 171.000000)">
                    <g id="icons/arrow-left" transform="translate(360.000000, 5.000000)">
                      <path
                        d="M8.29396103,1.29289322 C8.68448532,0.902368927 9.3176503,0.902368927 9.70817459,1.29289322 C10.0986989,1.68341751 10.0986989,2.31658249 9.70817459,2.70710678 L3.34421356,9.07106781 L9.70817459,15.4350288 C10.0986989,15.8255531 10.0986989,16.4587181 9.70817459,16.8492424 C9.3176503,17.2397667 8.68448532,17.2397667 8.29396103,16.8492424 L1.22289322,9.77817459 C0.832368927,9.3876503 0.832368927,8.75448532 1.22289322,8.36396103 L8.29396103,1.29289322 Z"
                        transform="translate(5.465534, 9.071068) scale(-1, 1) translate(-5.465534, -9.071068) "
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          }
          onClick={onToggleExpanded}
        >
          <Box className={styles.calendar} height={35} width={'100%'}>
            <Box alignItems="center" display="flex" height={'100%'} justifyContent="between">
              <Box alignItems="center" display="flex">
                <Box className={styles.datetimeIconContainer} marginEnd={10}>
                  <img src={datetimeIcon}/>
                </Box>

                <Text weight="semi-bold">
                  <Trans>Select date and time</Trans>
                </Text>
              </Box>

              {
                expanded ?
                  <Box marginRight={10}>
                    <Button noPaddingX onClick={handleResetClick} type="clear">
                      <Trans>Reset</Trans>
                    </Button>
                  </Box> : null
              }
            </Box>
          </Box>
        </AccordionSummary>
        <Box marginTop={7}>
          <Divider />
        </Box>
      </>
      <AccordionDetails
        classes={{
          root: accordionStyles.accordionDetails,
        }}
      >
        <Box className={styles.calendar} marginTop={10}>
          <Tabs
            classes={{
              root: styles.tabs,
              indicator: styles.indicator,
              scroller: styles.scroller,
            }}
            onChange={handleSetActiveTab}
            value={activeTab}
          >
            <Tab
              classes={{
                root: styles.tab,
                selected: styles.tabSelected,
              }}
              label={t`Custom`}
              {...a11yProps(0)}
            />
            <Tab
              classes={{
                root: styles.tab,
                selected: styles.tabSelected,
              }}
              label={t`Daily`}
              {...a11yProps(1)}
            />
            <Tab
              classes={{
                root: styles.tab,
                selected: styles.tabSelected,
              }}
              label={t`Weekly`}
              {...a11yProps(2)}
            />
          </Tabs>

          <TabPanel index={0} value={activeTab}>
            <CustomBooking />
          </TabPanel>

          <TabPanel index={1} value={activeTab}>
            <DailyBooking />
          </TabPanel>

          <TabPanel index={2} value={activeTab}>
            <WeeklyBooking />
          </TabPanel>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
