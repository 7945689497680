import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../Store/Redux/store";
import { useState } from "react";
import { t } from "@lingui/macro";
import styles from "./styles.module.scss";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "../../../../../Components/Box";
import MeetingBreadcrumbs from "../../MeetingBreadcrumbs";
import ListView from "../ListView";
import CalendarHorizontalView from "../Calendars/CalendarHorizontalView";
import { setCreateNewMeetingData } from "../../../../Store/Meetings/createNewMeetingDuck";
import { RoomSelectEnum, RoomSelectType } from "../../../../Store/Meetings/createNewMeetingDuck/models";

function a11yProps(index: number) {
  return {
    id: `room-tab-${index}`,
    'aria-controls': `room-tab-panel-${index}`,
  };
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`room-tab-${index}`}
      hidden={value !== index}
      id={`room-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function MeetingViewTabs() {
  const dispatch = useDispatch();
  const { room, createNewMeeting } = useTypedSelector(state => state);
  const { loading } = room;
  const { roomSelect } = createNewMeeting;

  const getRoomTypeNumber = (roomType: RoomSelectType): number => {
    let roomTypeNumber = 0;

    switch (roomType) {
      case RoomSelectEnum.LIST_VIEW: {
        roomTypeNumber = 0;
        break;
      }

      case RoomSelectEnum.CALENDAR_VIEW: {
        roomTypeNumber = 1;
        break;
      }

      default:
        break;
    }

    return roomTypeNumber;
  };

  const [roomSelectViewType, setRoomSelectViewType] = useState(getRoomTypeNumber(roomSelect));

  const handleSetActiveTab = (_: React.ChangeEvent<{}>, value: any) => {
    setRoomSelectViewType(value);

    let roomType: RoomSelectType = RoomSelectEnum.LIST_VIEW;

    switch (value) {
      case 0: {
        roomType = RoomSelectEnum.LIST_VIEW;
        break;
      }

      case 1: {
        roomType = RoomSelectEnum.CALENDAR_VIEW;
        break;
      }

      default:
        break;
    }

    dispatch(setCreateNewMeetingData({ roomSelect: roomType }));
  };

  return (
    <Box style={{ width: 'calc(95%)' }}>
      <Box
        alignItems="start"
        direction="column"
        display="flex"
        marginBottom={15}
      >
        <MeetingBreadcrumbs />

        <Tabs
          classes={{
            root: styles.tabs,
            indicator: styles.indicator,
            scroller: styles.scroller,
          }}
          onChange={handleSetActiveTab}
          value={roomSelectViewType}
        >
          <Tab
            classes={{
              root: styles.tab,
              selected: styles.tabSelected,
            }}
            label={t`List View`}
            {...a11yProps(0)}
          />
          <Tab
            classes={{
              root: styles.tab,
              selected: styles.tabSelected,
            }}
            label={t`Calendar View`}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      
      {loading ? (
        <Box borderRadius={14} height={300}>
          <Skeleton classes={{ root: styles.skeletonRoot }} height="100%" variant="rect" />
        </Box>
      ) : (
        <>
          <TabPanel index={0} value={roomSelectViewType}>
            <ListView />
          </TabPanel>

          <TabPanel index={1} value={roomSelectViewType}>
            <CalendarHorizontalView />
          </TabPanel>
        </>
      )}
    </Box>
  );
}