import Box from 'Components/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { Floor as FloorModel } from 'App/Store/Locations/locationsDuck/models';

interface FloorItemProps extends FloorModel {
  onClick: (floorId: string) => any;
  selected?: boolean;
}

/**
 * Loading state for floors.
 * Renders a few placeholders.
 */
export function FloorItemLoading() {
  const item = (
    <Box borderRadius={14} height={90}>
      <Skeleton classes={{ root: styles.skeletonRoot }} height="100%" variant="rect" />
    </Box>
  );

  return (
    <Box display="grid" gap={15}>
      {item}
      {item}
      {item}
      {item}
      {item}
    </Box>
  );
}

/**
 * Renders the floor UI as a dumb component.
 */
export default function FloorSupportItem({
  id,
  floorName,
  onClick,
  selected,
}: FloorItemProps) {
  const classes = clsx(styles.container, selected && styles.selected);

  return (
    <Box className={styles.floorItem}>
      <ButtonBase classes={{ root: styles.buttonBase }} disableRipple onClick={() => onClick(id)}>
        <Box className={classes}>
          <Box>
            <Text color={selected ? 'blue' : 'gray'} size="md" weight="semi-bold">
              {floorName}
            </Text>
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
}
