import { Button, styled } from "@mui/material";

export const TextButton = styled(Button)({
  padding: 0,
  minWidth: 0,
  fontSize: 16,
  color: "black",
  ":hover": {
    bgcolor: "transparent",
  },
});
