import Text from "../../../../../../../../Components/Text";
import {UserManagementUser} from "../../../../../../../Store/userManagement/models";

interface Props {
  searchText: string;
  allUsers: UserManagementUser[];
}

export default function NoUsersText(props: Props) {
  const { searchText, allUsers } = props;

  if (searchText && !allUsers.length) { // show in case of no search conditions
    return (
      <Text align="center" color="lightGray" size="md">
        No users found
      </Text>
    );
  }

  return (
    <Text align="center" color="lightGray" size="md">
      Please use search to add new users
    </Text>
  );
}
