import Desk from './Desk';
import Error404 from '../Error404';
import Floor from './Floor';
import Layout from './Layout';
import Location from './Location';
import { AnimatePresence } from 'framer-motion';
import { DeskGuard, FloorGuard } from './guards';
import { ProtectedRoute } from 'Components/ProtectedRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import { t } from '@lingui/macro';
import { useTypedSelector } from 'Store/Redux/store';
import { useEffect } from 'react';
import { getNowInTimeZone, startOfDayInTimeZone } from 'utils';
import { isBefore } from 'date-fns';
import { useDispatch } from 'react-redux';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';

export default function CreateNewBookingRoutes() {
  const dispatch = useDispatch();
  const dateFrom = useTypedSelector((state) => state.createNewBooking.dateFrom);
  const dateTo = useTypedSelector((state) => state.createNewBooking.dateTo);
  const location = useTypedSelector((state) => {
    const { locationId } = state.createNewBooking;

    if (!locationId) {
      return;
    }

    return state.locations.locations?.find(({ id }) => id === locationId);
  });

  useEffect(() => {
    if (!location) {
      return;
    }

    const timeZone = location?.timezone || "UTC";
    const startOfDay = startOfDayInTimeZone(getNowInTimeZone(timeZone), timeZone);

    if (dateFrom && isBefore(new Date(dateFrom), startOfDay)) {
      dispatch(setCreateNewBookingData({ dateFrom: startOfDay }));
    }

    if (dateTo && isBefore(new Date(dateTo), startOfDay)) {
      dispatch(setCreateNewBookingData({ dateTo: startOfDay }));
    }
  }, [dateFrom, dateTo, location]);

  return (
    <Layout title={t`Create new booking`}>
      <AnimatePresence exitBeforeEnter initial={true}>
        <Switch>
          <Route
            exact
            path='/create-new-booking/select-location'
            render={() => <Location />}
          />
          <ProtectedRoute
            exact
            fallback={() => <Redirect to="/create-new-booking/select-location" />}
            guards={[FloorGuard]}
            path='/create-new-booking/select-floor'
            render={() => <Floor />}
          />
          <ProtectedRoute
            exact
            fallback={() => <Redirect to="/create-new-booking/select-floor" />}
            guards={[DeskGuard]}
            path='/create-new-booking/select-desk'
            render={() => <Desk />}
          />
          <Route path='*' render={() => <Error404 />} />
        </Switch>
      </AnimatePresence >
    </Layout >
  );
}
