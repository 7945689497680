import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReservationsAvailabilityQuery, ReservationsState, ReservationsStateCurrent } from "./types";

const initialState: ReservationsState = {};

const setAvailabilityQuery: CaseReducer<ReservationsState, PayloadAction<Partial<ReservationsAvailabilityQuery>>> = (state, action) => ({
  ...state,
  queries: { ...state.queries, availability: { ...state.queries?.availability, ...action.payload } },
});

const resetAvailabilityQuery: CaseReducer<ReservationsState, PayloadAction<undefined>> = (state) => ({
  ...state,
  queries: { ...state.queries, availability: undefined },
});

const resetCurrent: CaseReducer<ReservationsState, PayloadAction<Partial<ReservationsStateCurrent> | undefined>> = (state, action) => ({
  ...state,
  current: action.payload,
});

const setCurrent: CaseReducer<ReservationsState, PayloadAction<Partial<ReservationsStateCurrent> | undefined>> = (state, action) => ({
  ...state,
  current: { ...state.current, ...action.payload },
});

export const reservationsSlice = createSlice({
  initialState,
  name: "reservations",
  reducers: {
    setAvailabilityQuery,
    resetAvailabilityQuery,
    resetCurrent,
    setCurrent,
  },
});
