import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../Store/Redux/store";
import { Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import styles from "./styles.module.scss";

import TextField from "@material-ui/core/TextField";
import Box from "../../../../Components/Box";
import Heading from "../../../../Components/Heading";
import Button from "../../../../Components/Button";
import AddMembersModal from "./AddMembersModal";
import AdNotification from "./AdNotification";
import { setGroupDetailsData } from "../../../Store/groupDetails";
import {IGroup} from "../../../Store/groups/models";

interface Props {
  group: IGroup | null;
}

export default function GroupDetailsHeader(props: Props) {
  const dispatch = useDispatch();
  const { config, groupDetails } = useTypedSelector(state => state);
  const { group } = props;
  const { filters } = groupDetails;
  const [isOpenMembersModal, setIsOpenMembersModal] = useState(false);

  const onOpenMembersForm = () => {
    setIsOpenMembersModal(o => !o);
  };

  const onCloseMembersForm = () => {
    setIsOpenMembersModal(false);
  };

  const onSearchChange = (event: any) => {
    dispatch(setGroupDetailsData({
      filters: {
        ...filters,
        search: event.target.value,
      },
    }));
  };

  return (
    <>
      <Box className={styles.header} dataTestId="group-details-header">
        <Box alignItems="center" display="flex" justifyContent="between" marginBottom={15}>
          <Heading className={styles.heading} size="sm">
            <Link className={styles.headingBack} to='/admin/groups'>
              <svg height="18px" version="1.1" viewBox="0 0 18 18" width="18px">
                <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
                  <g id="A-Location-notifications-createnew" transform="translate(-207.000000, -28.000000)">
                    <rect fill="transparent" height="900" width="1440" x="0" y="0" />
                    <path d="M220,0 C208.954305,2.02906125e-15 200,8.954305 200,20 L200,20 L200,1024 L0,1024 L0,0 Z" fill={config.theme.primaryLight} id="Combined-Shape" />
                    <g id="icons/arrow-back" transform="translate(207.000000, 28.000000)">
                      <g id="icons/arrow-left" />
                      <rect fill={config.theme.primary} height="2" id="Rectangle" rx="1" width="14" x="2" y="8" />
                      <path d="M10,5 C10.5522847,5 11,5.44771525 11,6 C11,6.55228475 10.5522847,7 10,7 L5,7 L5,12 C5,12.5522847 4.55228475,13 4,13 C3.44771525,13 3,12.5522847 3,12 L3,6 C3,5.44771525 3.44771525,5 4,5 L10,5 Z" fill={config.theme.primary} id="Combined-Shape" transform="translate(7.000000, 9.000000) rotate(-45.000000) translate(-7.000000, -9.000000) " />
                    </g>
                  </g>
                </g>
              </svg>
            </Link>
            {group?.name}
          </Heading>
          <Box alignItems="center" display="flex" gap={20}>
            <TextField
              InputProps={{
                startAdornment: (
                  <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px">
                    <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
                      <g fill="#5E5E5E" fillRule="nonzero" id="icons/search">
                        <g id="delete-white-copy-5">
                          <path d="M6.05025253,6.05025253 C8.78392257,3.31658249 13.2160774,3.31658249 15.9497475,6.05025253 C18.4434716,8.54397661 18.6623564,12.4511046 16.6064021,15.1929497 L20.1923882,18.7781746 C20.5829124,19.1686989 20.5829124,19.8018639 20.1923882,20.1923882 C19.8018639,20.5829124 19.1686989,20.5829124 18.7781746,20.1923882 L15.1929497,16.6064021 C12.4511046,18.6623564 8.54397661,18.4434716 6.05025253,15.9497475 C3.31658249,13.2160774 3.31658249,8.78392257 6.05025253,6.05025253 Z M14.5355339,7.46446609 C12.5829124,5.51184464 9.41708755,5.51184464 7.46446609,7.46446609 C5.51184464,9.41708755 5.51184464,12.5829124 7.46446609,14.5355339 C9.41708755,16.4881554 12.5829124,16.4881554 14.5355339,14.5355339 C16.4881554,12.5829124 16.4881554,9.41708755 14.5355339,7.46446609 Z" id="Combined-Shape"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                ),
              }}
              className={`input input--default input--search ${styles.searchInput}`}
              defaultValue={''}
              fullWidth
              onChange={onSearchChange}
              placeholder={t`Search by name`}
              variant="outlined"
            />
            { // add members is possible only for non AD groups
              !group?.adGroup?.id ? (
                <Button onClick={onOpenMembersForm} size="sm">
                  <Trans>Add Members</Trans>
                </Button>
              ) : <AdNotification />
            }
          </Box>
          {/* members form */}
          <AddMembersModal
            onCancelAction={onCloseMembersForm}
            open={isOpenMembersModal}
          />
        </Box>
      </Box>
    </>
  );
}
