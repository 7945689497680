import Box from 'Components/Box';
import CustomDot from './CustomDot';
import CustomTooltip from './CustomTooltip';
import Select from 'Components/Select';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { dashboardsTotalCountByPeriodPeriodsArray } from 'Admin/Store/locationDashboardDuck/models';
import { setLocationDashboardData } from 'Admin/Store/locationDashboardDuck';
import { SizeMe } from 'react-sizeme';
import { TOP_CHARTS_HEIGHT } from '../..';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'Store/Redux/store';
import {
  Area,
  AreaChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export default function TotalByQuarter() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();

  const { config, locationDashboard } = useTypedSelector(state => state);

  const counts = locationDashboard.totalCountByPeriod[locationId] ?? [];
  const periods = dashboardsTotalCountByPeriodPeriodsArray;

  return (
    <Box className={styles.container} dataTestId="dashboard-total-by-quarter">
      <Box className={styles.inputContainer}>
        <Box alignItems='center' display='flex' gap={10}>
          <Text inline overflow='noWrap' weight="bold">
            <Trans>
              Total by
            </Trans>
          </Text>
          <Text inline>|</Text>
        </Box>

        <Select
          classes={{
            input: styles.input,
            wrapper: styles.select,
            // materialSelect: styles.materialInput,
          }}
          data-testid="dashboard-total-by-quarter-select"
          iconColor="#000000"
          id="status"
          items={periods}
          labelColor="gray"
          onChange={(event: any) => {
            dispatch(setLocationDashboardData({ selectedPeriod: event.target.value }));
          }}
          value={locationDashboard.selectedPeriod}
        />
      </Box>

      <SizeMe>
        {({ size }) => {
          return (
            <Box dataTestId="dashboard-total-by-quarter-chart" minHeight={TOP_CHARTS_HEIGHT}>
              <AreaChart
                data={counts}
                height={size.height ?? TOP_CHARTS_HEIGHT}
                width={size.width ?? undefined}
              >
                <defs>
                  <linearGradient id="gradientPrimary" x1="0%" x2="0%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor={config.theme.primaryLight} />
                    <stop offset="100%" stopColor="#ffffff" />
                  </linearGradient>

                  <linearGradient id="gradientWarn" x1="0%" x2="0%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor={config.theme.warnLight} />
                    <stop offset="100%" stopColor="#ffffff" />
                  </linearGradient>
                </defs>

                <Area
                  activeDot={<CustomDot />}
                  dataKey="booked"
                  fill="url(#gradientPrimary)"
                  stroke={config.theme.primary}
                  strokeWidth={2}
                  type="linear"
                  yAxisId="left-axis"
                />
                <Area
                  activeDot={<CustomDot />}
                  dataKey="cancelled"
                  fill="url(#gradientWarn)"
                  stroke={config.theme.warn}
                  strokeWidth={2}
                  type="linear"
                  yAxisId="left-axis"
                />

                <XAxis
                  axisLine={{
                    stroke: config.theme.primaryLight,
                  }}
                  dataKey="quarterDetail"
                  stroke="#5E5E5E"
                  tickLine={false}
                />
                <YAxis
                  allowDecimals={false}
                  axisLine={{
                    stroke: config.theme.primaryLight,
                  }}
                  stroke="#5E5E5E"
                  tickLine={false}
                  width={25}
                  yAxisId="left-axis"
                />
                <YAxis
                  axisLine={{
                    stroke: config.theme.primaryLight,
                  }}
                  orientation="right"
                  stroke="#5E5E5E"
                  tickLine={false}
                  width={1}
                  yAxisId="right-axis"
                />

                <Tooltip content={<CustomTooltip />} />
              </AreaChart >
            </Box>
          );
        }}
      </SizeMe>
    </Box>
  );
}
