import { togetherApi } from "../together-api";
import { APIResponseBody, GetHomeCountsInput, HomeCounts } from "../types";
import { ApiTag } from "../enums";

const counts = togetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getHomeCounts: builder.query<APIResponseBody<HomeCounts>, GetHomeCountsInput>({
      query: ({ endOfDay, userId }) => ({
        url: `/api/users/${userId || "me"}/counts/home`,
        params: { endOfDay: endOfDay.toISOString() },
      }),
      providesTags: [{ type: ApiTag.USER_HOME_COUNTS }],
    }),
  }),
});

export const {
  useGetHomeCountsQuery,
} = counts;
