import { Typography, TypographyProps, useTheme } from "@mui/material";
import React from "react";

export const InteractiveTypography: React.FC<TypographyProps> = (props) => {
  const { children, ...rest } = props;
  const theme = useTheme();
  const sx: TypographyProps["sx"] = {
    paddingX: 0.75,
    paddingY: 0.25,
    borderRadius: 2,
    cursor: "pointer",
    transition: `color 0.12s`,
    ":hover": {
      bgcolor: theme.palette.grey[100],
    },
  };

  return (
    <Typography {...rest} sx={{ ...sx, ...rest?.sx }}>
      {children}
    </Typography>
  );
};
