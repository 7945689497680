import { useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";
import { Room as RoomModel } from "../../../../../Store/Meetings/meetingRooms/models";
import Text from "../../../../../../Components/Text";
import Button from "../../../../../../Components/Button";
import Box from "../../../../../../Components/Box";
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';
import RoomDetailsIcon from "../../../../../../Components/Icons/RoomDetails";
import { useTypedSelector } from "Store/Redux/store";

interface Props {
  room?: RoomModel;
}

export default function MeetingDetailsHeader(props: Props) {
  const dispatch = useDispatch();
  const { room } = props;
  const { createNewMeeting } = useTypedSelector(state => state);
  const { selectedData } = createNewMeeting;

  const onDeselectRoom = () => {
    dispatch(setCreateNewMeetingData({
      selectedData: {
        ...selectedData,
        roomId: null,
      }}));
  };

  return (
    <>
      <Box alignItems="center" display='flex' marginEnd={10}>
        <RoomDetailsIcon />
      </Box>
      {room ?
        <Box alignItems="center" display="flex" justifyContent="between" width={'100%'}>
          <Text weight="semi-bold">{room.name}</Text>
          <Button noPaddingX onClick={onDeselectRoom} type="clear">
            <Trans>
              Deselect
            </Trans>
          </Button>
        </Box> :
        <Text weight="semi-bold">
          <Trans>Room is not selected</Trans>
        </Text>
      }
    </>
  );
}