import format from 'date-fns/format';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { t } from '@lingui/macro';

export default function CustomTooltip(props: any) {
  const { active } = props;

  const getName = (name: string) => {
    switch (name.toLowerCase()) {
      case "bookedandcheckedin": {
        return t`Booked and Checked In`;
      }

      case "bookedandnotcheckedin": {
        return t`Booked and Not Checked In`;
      }

      case "cancelled": {
        return t`Cancelled`;
      }

      default:
        return '';
    }
  };

  if (active) {
    return (
      <div className={styles.container}>
        {props.payload?.map((payload: any, i: number) => {
          const day = payload.payload.dayDate ? format(new Date(payload.payload.dayDate), 'd MMM, yyyy') : format(new Date(), 'd MMM, yyyy');

          return (
            <div key={i}>
              {(payload.name === 'booked' && day) ? (
                <Text color="white" size="md">
                  {day}
                </Text>
              ) : null}

              <Text>
                <Text
                  color="white"
                  inline
                  size="md"
                  weight="bold"
                >
                  {payload.value}
                </Text>{' '}

                <Text
                  color="white"
                  inline
                  size="md"
                >
                  {getName(payload.name)}
                </Text>
              </Text>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}