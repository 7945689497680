import { Button, ButtonProps } from "@mui/material";

export const NavigationButton: React.FC<ButtonProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Button
      color="secondary"
      disableElevation
      size="small"
      sx={{ minWidth: 32, width: 32, height: 32, padding: 0, borderRadius: 2 }}
      variant="contained"
      {...rest}
    >
      {children}
    </Button>
  );
};