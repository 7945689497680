import {Trans} from "@lingui/macro";
import Select from "react-select";
import styles from '../styles.module.scss';
import {DropdownIndicator} from "../../Select";
import {ICovidCertificateTypeList} from "../../../App/Store/CovidSertificate/models";

interface UploadCovidTypeProps {
  documentType: ICovidCertificateTypeList;
  documentTypeList: ICovidCertificateTypeList[];
  setDocumentType: Function;
}

export default function UploadCovidType(props: UploadCovidTypeProps) {
  const { documentType, documentTypeList, setDocumentType } = props;

  const onDocumentChange = (document: any) => {
    setDocumentType(document);
  };

  return (
    <>
      <div className={styles.fieldLabel}>
        <span className={styles.requiredSign}>*</span>
        <Trans>Document Type</Trans>
      </div>
      <Select
        className={`react-select-custom`}
        classNamePrefix="select-custom"
        components={{ DropdownIndicator }}
        defaultValue={documentType}
        isClearable={false}
        isSearchable={false}
        onChange={(document) => onDocumentChange(document)}
        options={documentTypeList}
      />
    </>
  );
}
