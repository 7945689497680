// add room images
import { ActionPayload, BaseErrorResponse, BaseResponse } from "../../../Store/Models/ReduxModels";
import {
  AddRoomImageRequest,
  AddRoomImageResponse,
  IRoomImage,
  SetRoomsImagesDataRequest,
} from "./models";

export const SET_ROOMS_IMAGES_DATA = 'SET_ROOMS_IMAGES_DATA';

export const ADD_ROOM_IMAGE = 'ADD_ROOM_IMAGE';
export const ADD_ROOM_IMAGE_SUCCESS = 'ADD_ROOM_IMAGE_SUCCESS';
export const ADD_ROOM_IMAGE_FAIL = 'ADD_ROOM_IMAGE_FAIL';

// set any data
export interface SetRoomsImagesData {
  type: typeof SET_ROOMS_IMAGES_DATA;
  payload: SetRoomsImagesDataRequest;
}

// add image to room
export interface AddRoomImage {
  type: typeof ADD_ROOM_IMAGE;
  payload: ActionPayload<AddRoomImageRequest>
}

export interface AddRoomImageSuccess {
  type: typeof ADD_ROOM_IMAGE_SUCCESS;
  payload: BaseResponse<AddRoomImageResponse>;
}

export interface AddRoomImageFail {
  type: typeof ADD_ROOM_IMAGE_FAIL;
  payload: BaseErrorResponse;
}

export type Actions =
  | SetRoomsImagesData
  | AddRoomImage
  | AddRoomImageSuccess
  | AddRoomImageFail

export interface State {
  error: string;
  successMessage: string;
  loading: boolean;
  roomsImages: IRoomImage[];
}

const initialState: State = {
  error: '',
  successMessage: '',
  loading: false,
  roomsImages: [],
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SET_ROOMS_IMAGES_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ADD_ROOM_IMAGE: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }
    case ADD_ROOM_IMAGE_FAIL: {
      return {
        ...state,
        error: `There was an error adding room image. ${action.payload?.error?.message}. Please try again.`,
        loading: false,
      };
    }
    case ADD_ROOM_IMAGE_SUCCESS: {

      return {
        ...state,
        loading: false,
        // roomsImages: Add after BE update
      };
    }

    default:
      return state;
  }
}

// actions
export function setRoomsImagesData(data: SetRoomsImagesDataRequest): SetRoomsImagesData {
  return {
    type: SET_ROOMS_IMAGES_DATA,
    payload: data,
  };
}

