import { ActionPayload, BaseErrorResponse, BaseResponse } from '../../../Store/Models/ReduxModels';
import { BookingModel } from '../Bookings/bookingDuck/models';
import { t } from '@lingui/macro';
import {
  AnswerQuestionModel,
  GetQuestionQuestionnaireRequest,
  GetQuestionQuestionnaireResponse,
  GetQuestionnaireAppointmentByIdRequest,
  GetQuestionnaireAppointmentByIdResponse,
  GetQuestionnaireBookingByIdRequest,
  GetQuestionnaireBookingByIdResponse,
  GetQuestionnaireBookingsResponse,
  PassQuestionnaireResultRequest,
  PassedStatus,
  QuestionnaireAppointment,
  QuestionnaireBooking,
  QuestionnaireModel,
  QuestionnaireStatus,
  SaveAnswersToQuestionnaireRequest,
  SaveAnswersToQuestionnaireRequestBody,
  SaveAnswersToQuestionnaireResponse,
  SetQuestionnaireDataRequest,
} from './models';
import {VisitModel} from "../../../Admin/Store/visits/models";

export const ANSWER_QUESTION = 'ANSWER_QUESTION';
export const SET_QUESTIONNAIRE_DATA = 'SET_QUESTIONNAIRE_DATA';

export const GET_QUESTIONNAIRE_BOOKINGS = 'GET_QUESTIONNAIRE_BOOKINGS';
export const GET_QUESTIONNAIRE_BOOKINGS_FAIL = 'GET_QUESTIONNAIRE_BOOKINGS_FAIL';
export const GET_QUESTIONNAIRE_BOOKINGS_SUCCESS = 'GET_QUESTIONNAIRE_BOOKINGS_SUCCESS';

export const GET_QUESTIONS_QUESTIONNAIRE = 'GET_QUESTIONS_QUESTIONNAIRE';
export const GET_QUESTIONS_QUESTIONNAIRE_FAIL = 'GET_QUESTIONS_QUESTIONNAIRE_FAIL';
export const GET_QUESTIONS_QUESTIONNAIRE_SUCCESS = 'GET_QUESTIONS_QUESTIONNAIRE_SUCCESS';

export const PASS_QUESTIONNAIRE_RESULT = 'PASS_QUESTIONNAIRE_RESULT';
export const PASS_QUESTIONNAIRE_RESULT_FAIL = 'PASS_QUESTIONNAIRE_RESULT_FAIL';

export const GET_QUESTIONNAIRE_BOOKING_BY_ID = 'GET_QUESTIONNAIRE_BOOKING_BY_ID';
export const GET_QUESTIONNAIRE_BOOKING_BY_ID_FAIL = 'GET_QUESTIONNAIRE_BOOKING_BY_ID_FAIL';
export const GET_QUESTIONNAIRE_BOOKING_BY_ID_SUCCESS = 'GET_QUESTIONNAIRE_BOOKING_BY_ID_SUCCESS';

export const GET_QUESTIONNAIRE_APPOINTMENT_BY_ID = 'GET_QUESTIONNAIRE_APPOINTMENT_BY_ID';
export const GET_QUESTIONNAIRE_APPOINTMENT_BY_ID_FAIL = 'GET_QUESTIONNAIRE_APPOINTMENT_BY_ID_FAIL';
export const GET_QUESTIONNAIRE_APPOINTMENT_BY_ID_SUCCESS = 'GET_QUESTIONNAIRE_APPOINTMENT_BY_ID_SUCCESS';

export const SAVE_ANSWERS_TO_QUESTIONNAIRE = 'SAVE_ANSWERS_TO_QUESTIONNAIRE';
export const SAVE_ANSWERS_TO_QUESTIONNAIRE_FAIL = 'SAVE_ANSWERS_TO_QUESTIONNAIRE_FAIL';
export const SAVE_ANSWERS_TO_QUESTIONNAIRE_SUCCESS = 'SAVE_ANSWERS_TO_QUESTIONNAIRE_SUCCESS';

export interface AnswerQuestion {
  type: typeof ANSWER_QUESTION;
  payload: AnswerQuestionModel;
}

export interface SetQuestionnaireData {
  type: typeof SET_QUESTIONNAIRE_DATA;
  payload: SetQuestionnaireDataRequest;
}

// get list of all questions
export interface GetQuestionsQuestionnaire {
  type: typeof GET_QUESTIONS_QUESTIONNAIRE;
  payload: ActionPayload<GetQuestionQuestionnaireRequest>
}

export interface GetQuestionsQuestionnaireFail {
  type: typeof GET_QUESTIONS_QUESTIONNAIRE_FAIL;
  payload: BaseErrorResponse;
}

export interface GetQuestionsQuestionnaireSuccess {
  type: typeof GET_QUESTIONS_QUESTIONNAIRE_SUCCESS;
  payload: BaseResponse<GetQuestionQuestionnaireResponse>;
}

// get list of Questionnaire bookings
export interface GetQuestionnaireBookings {
  type: typeof GET_QUESTIONNAIRE_BOOKINGS;
  payload: ActionPayload<any>;
}

export interface GetQuestionnaireBookingsFail {
  type: typeof GET_QUESTIONNAIRE_BOOKINGS_FAIL;
  payload: BaseErrorResponse;
}

export interface GetQuestionnaireBookingsSuccess {
  type: typeof GET_QUESTIONNAIRE_BOOKINGS_SUCCESS;
  payload: BaseResponse<GetQuestionnaireBookingsResponse>;
}

// pass success result
export interface PassQuestionnaireResult {
  type: typeof PASS_QUESTIONNAIRE_RESULT;
  payload: ActionPayload<PassQuestionnaireResultRequest>;
}

export interface PassQuestionnaireResultFail {
  type: typeof PASS_QUESTIONNAIRE_RESULT_FAIL;
  payload: BaseErrorResponse;
}

// get booking data
export interface GetQuestionnaireBookingById {
  type: typeof GET_QUESTIONNAIRE_BOOKING_BY_ID;
  payload: ActionPayload<GetQuestionnaireBookingByIdRequest>;
  clearData?: boolean;
}
export interface GetQuestionnaireBookingByIdFail {
  type: typeof GET_QUESTIONNAIRE_BOOKING_BY_ID_FAIL;
  payload: BaseErrorResponse;
}
export interface GetQuestionnaireBookingByIdSuccess {
  type: typeof GET_QUESTIONNAIRE_BOOKING_BY_ID_SUCCESS;
  payload: BaseResponse<GetQuestionnaireBookingByIdResponse>;
}

// get appointment data
export interface GetQuestionnaireAppointmentById {
  type: typeof GET_QUESTIONNAIRE_APPOINTMENT_BY_ID;
  payload: ActionPayload<GetQuestionnaireAppointmentByIdRequest>;
  clearData?: boolean;
}
export interface GetQuestionnaireAppointmentByIdFail {
  type: typeof GET_QUESTIONNAIRE_APPOINTMENT_BY_ID_FAIL;
  payload: BaseErrorResponse;
}
export interface GetQuestionnaireAppointmentByIdSuccess {
  type: typeof GET_QUESTIONNAIRE_APPOINTMENT_BY_ID_SUCCESS;
  payload: BaseResponse<GetQuestionnaireAppointmentByIdResponse>;
}

export interface SaveAnswersToQuestionnaire {
  type: typeof SAVE_ANSWERS_TO_QUESTIONNAIRE;
  payload: ActionPayload<SaveAnswersToQuestionnaireRequestBody>;
  clearData?: boolean;
}
export interface SaveAnswersToQuestionnaireFail {
  type: typeof SAVE_ANSWERS_TO_QUESTIONNAIRE_FAIL;
  payload: BaseErrorResponse;
}
export interface SaveAnswersToQuestionnaireSuccess {
  type: typeof SAVE_ANSWERS_TO_QUESTIONNAIRE_SUCCESS;
  payload: BaseResponse<SaveAnswersToQuestionnaireResponse>;
}


export type Actions =
  | AnswerQuestion
  | GetQuestionsQuestionnaire
  | GetQuestionsQuestionnaireFail
  | GetQuestionsQuestionnaireSuccess
  | PassQuestionnaireResult
  | PassQuestionnaireResultFail
  | GetQuestionnaireBookingById
  | GetQuestionnaireBookingByIdFail
  | GetQuestionnaireBookingByIdSuccess
  | GetQuestionnaireAppointmentById
  | GetQuestionnaireAppointmentByIdFail
  | GetQuestionnaireAppointmentByIdSuccess
  | GetQuestionnaireBookings
  | GetQuestionnaireBookingsFail
  | GetQuestionnaireBookingsSuccess
  | SetQuestionnaireData
  | SaveAnswersToQuestionnaire
  | SaveAnswersToQuestionnaireFail
  | SaveAnswersToQuestionnaireSuccess;

export interface State {
  error: string;
  loading: boolean;
  isOpen: boolean;
  passedStatus: PassedStatus;
  booking: BookingModel | null; // data for result message
  appointment: VisitModel | null; // data for result message
  locationId: string;
  activeIndex: number;
  answers: boolean[];
  questions: QuestionnaireModel[];
  status: QuestionnaireStatus;
  currentQuestionnaires: {
    bookings: QuestionnaireBooking[],
    appointments: QuestionnaireAppointment[],
  }
}

export const initialState: State = {
  error: '',
  loading: false,
  isOpen: false,

  // add these info to currentQuestionnaire ?
  passedStatus: '',
  booking: null,
  appointment: null,
  locationId: '',
  activeIndex: 0,
  answers: [],
  questions: [],
  status: 'progress',

  currentQuestionnaires: {
    bookings: [],
    appointments: [],
  },
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ANSWER_QUESTION: {
      const { answer } = action.payload;
      const newAnswers = [...state.answers, answer];

      return {
        ...state,
        error: '',
        loading: true,
        answers: newAnswers,
      };
    }

    case GET_QUESTIONS_QUESTIONNAIRE_FAIL: {
      return {
        ...state,
        error: t`There was an error loading questionnaire questions. Please try again.`,
      };
    }
    case GET_QUESTIONS_QUESTIONNAIRE_SUCCESS: {
      return {
        ...state,
        error: '',
        questions: action.payload.data.result.data,
        isOpen: true,
      };
    }

    case PASS_QUESTIONNAIRE_RESULT_FAIL: {
      return {
        ...state,
        error: t`There was an error sending questionnaire result. Please try again.`,
      };
    }

    case GET_QUESTIONNAIRE_BOOKING_BY_ID_FAIL:
      return {
        ...state,
        error: t`There was an error loading current questionnaire booking. Please try again.`,
      };
    case GET_QUESTIONNAIRE_BOOKING_BY_ID_SUCCESS: {
      return {
        ...state,
        error: '',
        booking: action.payload.data.result.data,
        appointment: null, // clear any active appointment from state after getting new booking
      };
    }

    case GET_QUESTIONNAIRE_APPOINTMENT_BY_ID_FAIL:
      return {
        ...state,
        error: t`There was an error loading current questionnaire appointment. Please try again.`,
      };
    case GET_QUESTIONNAIRE_APPOINTMENT_BY_ID_SUCCESS: {
      return {
        ...state,
        error: '',
        appointment: action.payload.data.result.data,
        booking: null, // clear any active booking from state after getting new appointment
      };
    }

    case GET_QUESTIONNAIRE_BOOKINGS_FAIL:
      return {
        ...state,
        error: t`There was an error loading current questionnaires. Please try again.`,
      };
    case GET_QUESTIONNAIRE_BOOKINGS_SUCCESS: {
      const { bookings, appointments } = action.payload.data.result.data;

      return {
        ...state,
        error: '',
        currentQuestionnaires: { bookings, appointments },
      };
    }

    case SAVE_ANSWERS_TO_QUESTIONNAIRE: {
      return {
        ...state,
        error: '',
      };
    }
    case SAVE_ANSWERS_TO_QUESTIONNAIRE_FAIL: {
      return {
        ...state,
        error: t`Failed to save answers to questionnaire`,
      };
    }
    case SAVE_ANSWERS_TO_QUESTIONNAIRE_SUCCESS: {
      return {
        ...state,
        error: '',
      };
    }

    case SET_QUESTIONNAIRE_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function answerQuestion(data: AnswerQuestionModel): AnswerQuestion {
  return {
    type: ANSWER_QUESTION,
    payload: data,
  };
}

export function setQuestionnaireData(data: SetQuestionnaireDataRequest): SetQuestionnaireData {
  return {
    type: SET_QUESTIONNAIRE_DATA,
    payload: data,
  };
}

export function getQuestionsQuestionnaire(data: GetQuestionQuestionnaireRequest): GetQuestionsQuestionnaire {
  return {
    type: GET_QUESTIONS_QUESTIONNAIRE,
    payload: {
      request: {
        method: 'GET',
        url: `/api/covid-questions/${data.locationId}/location-questions`,
      },
    },
  };
}

export function passQuestionnaireResult(data: PassQuestionnaireResultRequest): PassQuestionnaireResult {
  return {
    type: PASS_QUESTIONNAIRE_RESULT,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/bookings/hasPassedQuestionnaire/${data.hasPassedQuestionnaire}`,
        data: data,
      },
    },
  };
}

export function getQuestionnaireBookingById(data: GetQuestionnaireBookingByIdRequest): GetQuestionnaireBookingById {
  return {
    type: GET_QUESTIONNAIRE_BOOKING_BY_ID,
    payload: {
      request: {
        method: 'GET',
        url: `/api/bookings/${data.bookingId}`,
      },
    },
  };
}

export function getQuestionnaireAppointmentById(data: GetQuestionnaireAppointmentByIdRequest): GetQuestionnaireAppointmentById {
  return {
    type: GET_QUESTIONNAIRE_APPOINTMENT_BY_ID,
    payload: {
      request: {
        method: 'GET',
        url: `/api/bookings/appointments/${data.appointmentId}`,
      },
    },
  };
}

export function getQuestionnaireBookings(): GetQuestionnaireBookings {
  return {
    type: GET_QUESTIONNAIRE_BOOKINGS,
    payload: {
      request: {
        method: 'GET',
        url: '/api/v2/bookings/questionnaire-bookings',
      },
    },
  };
}

export function saveAnswersToQuestionnaire(data: SaveAnswersToQuestionnaireRequest): SaveAnswersToQuestionnaire {
  return {
    type: SAVE_ANSWERS_TO_QUESTIONNAIRE,
    payload: {
      request: {
        method: 'POST',
        url: `/api/covid-questions/${data.params.locationId}/answers`,
        data: data.body,
      },
    },
  };
}
