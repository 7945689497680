import Popup from "reactjs-popup";
import {useTypedSelector} from "../../../../Store/Redux/store";
import moment from "moment";
import styles from "./styles.module.scss";
import Box from "../../../../Components/Box";
import CalendarIcon from "../../../../Components/Icons/Calendar";
import {ReservationListViewCalendar, ReservationMapViewCalendar} from "../ReservationCalendar";

const getReservationDateRangeDateFormat = (
  startDate: Date | string | null | undefined,
  endDate: Date | string | null | undefined,
) => {
  const isSameDay = moment(startDate).isSame(endDate);

  if (isSameDay) { // return data on first open (in case of edit state)
    return `${moment(startDate).format('MMM DD, YYYY')}`;
  }

  return `${moment(startDate).format('MMM DD')}-${moment(endDate).format('MMM DD, YYYY')}`;
};

export default function ReservationHeaderCalendar() {
  const { config, adminReservations } = useTypedSelector(state => state);
  const { reservationPageType, filters } = adminReservations;
  const { selectedStartDate, selectedEndDate } = filters;
  const calendarSelectedDate = getReservationDateRangeDateFormat(selectedStartDate, selectedEndDate);

  return (
    <Box className={styles.reservationHeaderCalendar}>
      <Box className={styles.calendarDate}>
        {calendarSelectedDate}
      </Box>
      <Popup
        className={'modal'}
        modal
        nested
        trigger={(
          <div style={{display: 'flex'}}>
            <CalendarIcon
              backgroundColor={config.theme.primaryLight}
              className={styles.calendarDateTrigger}
              color={config.theme.primary}
              size={30}
            />
          </div>
        )}
      >
        {reservationPageType === 'list' && <ReservationListViewCalendar />}
        {reservationPageType === 'map' && <ReservationMapViewCalendar />}
      </Popup>
    </Box>
  );
}
