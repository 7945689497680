import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTypedSelector } from '../../../Store/Redux/store';
import moment from 'moment';
import { Trans } from '@lingui/macro';

import Box from 'Components/Box';
import ReservationHeader from '../../Components/Header/ReservationHeader';
import { ReservationListViewCalendar, ReservationMapViewCalendar } from './ReservationCalendar';
import CustomPagination from '../../../Components/CustomPagination';
import ReservationListView from './ReservationListView';
import ReservationMapView from './ReservationMapView';
import styles from './styles.module.scss';
import Button from '../../../Components/Button';
import { GetReservationsRequest, ReservationPageTypeModel } from '../../Store/reservations/models';
import {
  getReservations,
  getReservationsByFloors,
  initFiltersData,
  resetReservationMassActions,
  setReservationsData,
  updateReservationPageType,
  updateReservationsLoading,
} from 'Admin/Store/reservations';
import {getAllowedLocationsByRoles, getLocationIdsByRoles} from "../Locations/Helpers";
import { useDebouncedCallback } from 'hooks';
import { filterStatusType } from 'Admin/Store/visits/models';
import { UserDocumentStatus } from 'Admin/Store/userManagement/models';

export default function Reservations() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { adminReservations, profile, locations } = useTypedSelector(state => state);
  const { roleAccess, locationIds: profileLocationIds } = profile;
  const {
    reservationPageType,
    filters,
    reservations,
    reservationsByFloors,
    totalCount,
    limit,
  } = adminReservations;
  const { selectedStartDate, selectedEndDate, statuses, documentStatuses, checking, search } = filters;
  const totalPages = Math.ceil(totalCount / limit);
  const [updatePaginationCount, setUpdatePaginationCount] = useState(0);

  // set restrictions for local admin role
  const allowedLocations = getAllowedLocationsByRoles(roleAccess, locations.adminLocations, profileLocationIds);
  const restrictedLocationIds = getLocationIdsByRoles(roleAccess, filters, allowedLocations);

  const onResetFiltersData = () => {
    dispatch(resetReservationMassActions());
    dispatch(setReservationsData({ // reset all filters on page load
      filters: initFiltersData,
    }));
  };

  useEffect(() => {
    // @ts-ignore
    const prevRoute = history.location.state ? `${history.location.state.route}` : '';
    // do not reset if previous route was onResetFiltersData map view details
    if (prevRoute !== 'mapViewDetails') {
      onResetFiltersData();
    }
  }, []);

  useEffect(() => {
    // set default list view type on component unmount
    return () => {
      dispatch(updateReservationPageType({ type: 'list' }));
    };
  }, []);

  const getReservationData = (pageNumber?: number) => {
    const page = pageNumber ? pageNumber : 1;
    const data: GetReservationsRequest = {
      page,
      limit,
      order: 'ASC',
      statuses,
      documentStatuses,
      locationIds: restrictedLocationIds,
      search,
      checking,
      dateFrom: moment(selectedStartDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'), // start of day in iso format
      dateTo: moment(selectedEndDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss'), // end of day in iso format
    };

    // call for list view
    if (reservationPageType === 'list') {
      return dispatch(getReservations(data));
    }

    // call for map view
    return dispatch(getReservationsByFloors(data));
  };

  const debouncedGetReservationsData = useDebouncedCallback((
    reservationPageType: string,
    limit: number,
    locationIds: string[],
    selectedStartDate: string | Date,
    selectedEndDate: string | Date,
    search?: string,
    checking?: boolean,
    statuses?: filterStatusType[],
    documentStatuses?: UserDocumentStatus[],
    pageNumber?: number,
  ) => {
    const page = pageNumber ? pageNumber : 1;
    const data: GetReservationsRequest = {
      page,
      limit,
      order: 'ASC',
      statuses,
      documentStatuses,
      locationIds,
      search,
      checking,
      dateFrom: moment(selectedStartDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'), // start of day in iso format
      dateTo: moment(selectedEndDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss'), // end of day in iso format
    };

    // call for list view
    if (reservationPageType === 'list') {
      return dispatch(getReservations(data));
    }

    // call for map view
    return dispatch(getReservationsByFloors(data));
  }, [dispatch], 800);

  useEffect(() => {
    setUpdatePaginationCount(prev => prev + 1); // show 1 page on each filter update
    dispatch(updateReservationsLoading(true));
    debouncedGetReservationsData(
      reservationPageType,
      limit,
      restrictedLocationIds,
      selectedStartDate,
      selectedEndDate,
      search,
      checking,
      statuses,
      documentStatuses,
    );
  }, [
    reservationPageType,
    limit,
    selectedStartDate,
    selectedEndDate,
    search,
    checking,
    statuses,
    documentStatuses,
  ]);

  const onChangeTabIndex = (newType: ReservationPageTypeModel) => {
    onResetFiltersData();
    dispatch(updateReservationPageType(newType));
  };

  const onChangePage = (page: number) => {
    getReservationData(page);
  };

  return (
    <Box>
      <ReservationHeader />
      <Box display="flex" justifyContent="between" marginTop={20}>
        <Box className={styles.reservationList}>
          <Box className="tabs-custom">
            <Button
              className={reservationPageType === 'list' ? 'active' : ''}
              onClick={() => onChangeTabIndex({ type: 'list' })}
              size="sm"
            >
              <Trans>List view</Trans>
            </Button>

            <Button
              className={reservationPageType === 'map' ? 'active' : ''}
              onClick={() => onChangeTabIndex({ type: 'map' })}
              size="sm"
            >
              <Trans>Map view</Trans>
            </Button>
          </Box>
          <Box>
            {reservationPageType === 'list' && <ReservationListView reservations={reservations} />}
            {reservationPageType === 'map' && <ReservationMapView reservationsByFloors={reservationsByFloors} />}
          </Box>
        </Box>

        {/* hide on screen < 1660 */}
        <Box className={styles.reservationCalendar} marginLeft={30} marginTop={45} width={370}>
          {reservationPageType === 'list' && <ReservationListViewCalendar />}
          {reservationPageType === 'map' && <ReservationMapViewCalendar />}
        </Box>
      </Box>
      <Box padding="100px 0 50px">
        <CustomPagination
          count={totalPages}
          key={updatePaginationCount}
          onChange={onChangePage}
        />
      </Box>
    </Box>
  );
}
