import { useState } from 'react';
import { FeatureGroup, useMapEvents } from 'react-leaflet';
import { useTypedSelector } from 'Store/Redux/store';
import 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import MeetingObject from "../MeetingObject";
import { getMeetingsDraw } from "../../../../../../../../Store/Meetings/meetingRooms";

interface Props {
  roomId: string;
}

export default function MapDrawingRoom({ roomId }: Props) {
  const { adminFloorMap } = useTypedSelector(state => state);
  const { meetings } = adminFloorMap;
  const [zoomLevel, setZoomLevel] = useState<number>(1); // variable to handle zoom event
  const meetingsArray = getMeetingsDraw(meetings);

  useMapEvents({
    'zoom': (e) => {
      setZoomLevel(e.target._zoom);
    },
  });

  return (
    <>
      {meetingsArray.map((meeting) => {
        const showMeeting = roomId === meeting.roomId;

        return showMeeting && (
          <FeatureGroup key={`${meeting.id}`}>
            <MeetingObject
              meeting={meeting}
              zoomLevel={zoomLevel}
            />
          </FeatureGroup>
        );
      })}      
    </>
  );
}