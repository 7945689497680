import Api from 'Store/Services/Api';
import {
  CREATE_OR_UPDATE_EMAIL_TEMPLATE,
  CREATE_OR_UPDATE_EMAIL_TEMPLATE_FAIL,
  CREATE_OR_UPDATE_EMAIL_TEMPLATE_SUCCESS,
  CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE,
  CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE_FAIL,
  CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS,
  CreateOrUpdateEmailTemplate,
  CreateOrUpdatePushNotificationTemplate,
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES_FAIL,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GetEmailTemplates,
} from '.';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';

function* createOrUpdateEmailTemplateSaga(action: CreateOrUpdateEmailTemplate): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: CREATE_OR_UPDATE_EMAIL_TEMPLATE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CREATE_OR_UPDATE_EMAIL_TEMPLATE_FAIL, payload: e });
  }
}

function* createOrUpdatPushNotificationTemplateSaga(action: CreateOrUpdatePushNotificationTemplate): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE_FAIL, payload: e });
  }
}

function* getEmailTemplatesSaga(action: GetEmailTemplates): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_EMAIL_TEMPLATES_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_EMAIL_TEMPLATES_FAIL, payload: e });
  }
}

export default [
  takeLatest(CREATE_OR_UPDATE_EMAIL_TEMPLATE, createOrUpdateEmailTemplateSaga),
  takeLatest(CREATE_OR_UPDATE_PUSH_NOTIFICATION_TEMPLATE, createOrUpdatPushNotificationTemplateSaga),
  takeLatest(GET_EMAIL_TEMPLATES, getEmailTemplatesSaga),
];
