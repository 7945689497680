import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTypedSelector } from "../../../Store/Redux/store";

import Box from '../../../Components/Box';
import UserManagementHeader from '../../Components/Header/UserManagementHeader';
import CustomPagination from "../../../Components/CustomPagination";
import AllUsersTable from './AllUsersTable';
import { getAllUsers, getListRoles, setUserManagementData } from '../../Store/userManagement';
import { ErrorUserManagementSnackbar, SuccessUserManagementSnackbar } from "./SnackBars";
import { getLocations } from "../../../App/Store/Locations/locationsDuck";
import {UserAccommodation, UserDocumentStatus, userRole} from 'Admin/Store/userManagement/models';

export default function UserManagement() {
  const dispatch = useDispatch();
  const { allUsers, totalPages, allUsersFilters, filters } = useTypedSelector(state => state.adminUserManagement);
  const { search } = allUsersFilters;

  const getAccommodationValue = () => {
    const accommodationArray: UserAccommodation[] = filters?.accommodation && Object.keys(filters.accommodation).map(key => key as UserAccommodation).filter(key => filters.accommodation[key as UserAccommodation].value);
    if (accommodationArray?.length === 1) {
      return accommodationArray[0] === 'approved'; // return true if approved and false if notApproved
    }

    return undefined;
  };

  function getAllUsersData(pageNumber?: number) {
    const page = pageNumber ? pageNumber : 1;

    const statusArray = filters?.status && Object.keys(filters.status).map(key => key as UserDocumentStatus).filter(key => filters.status[key as UserDocumentStatus].value);
    const rolesArray = filters?.roles && Object.keys(filters.roles).map(key => key as userRole).filter(key => filters.roles[key as userRole].value);
    const accommodationValue = getAccommodationValue();

    dispatch(getAllUsers({
      page,
      search: search ? search : undefined,
      city: filters.cities?.length > 0 ? filters.cities[0] : undefined,
      country: filters.countries?.length > 0 ? filters.countries[0] : undefined,
      status: statusArray?.length > 0 ? statusArray : undefined,
      role: rolesArray?.length > 0 ? rolesArray : undefined,
      approvedAccommodation: accommodationValue,
    }));
  }

  useEffect(() => {
    dispatch(getLocations({ activeOnly: false })); // get locations for local admin location management
    dispatch(getListRoles());
    dispatch(setUserManagementData({ // reset filters on page load
      allUsersFilters: {
        ...allUsersFilters,
        search: '',
      },
    }));
  }, []);

  useEffect(() => {
    getAllUsersData();
  }, [search]);

  // Listens for changes on filters and if needed, call API
  useEffect(() => {
    if (filters.applyFilters) {
      getAllUsersData();
    }
  }, [filters]);

  const onChangePage = (page: number) => {
    getAllUsersData(page);
  };

  return (
    <>
      <SuccessUserManagementSnackbar />
      <ErrorUserManagementSnackbar />

      <UserManagementHeader />

      <Box marginTop={20}>
        <AllUsersTable allUsers={allUsers} />
        {allUsers.length ?
          <Box padding="50px 0 50px">
            <CustomPagination
              count={totalPages}
              key={totalPages}
              onChange={onChangePage}
            />
          </Box> : null
        }
      </Box>
    </>
  );
}
