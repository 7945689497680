import Box from '../../../Components/Box';
import LocationDetailsHeader from 'Admin/Components/Header/LocationDetailsHeader';
import Skeleton from '@material-ui/lab/Skeleton';
import StandIcon from '../../../Functions/getDeskAttributeIcon/assets/stand.svg';
import styles from './styles.module.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { AttributeType, AttributeTypeEnum } from '../../Store/attributes/models';
import { getAttributes } from '../../Store/attributes';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '../../../Store/Redux/store';
import Button from "../../../Components/Button";
import AttributesBody from "./AttributesBody";
import { deskAttributesCells } from 'Functions/getDeskAttributeIcon';

interface BodyCellsInterface {
  name: string;
  image: string;
}

const headCells = [
  { id: 'name', name: 'Name' },
  { id: 'available', name: 'Available' },
];

const bodyCellsRoom: BodyCellsInterface[] = [
  { name: 'Teams Meeting Room', image: StandIcon },
  { name: 'TV/Display', image: StandIcon },
  { name: 'Whiteboard', image: StandIcon },
  { name: 'Phone', image: StandIcon },
  { name: 'Camera', image: StandIcon },
  { name: 'Speakers', image: StandIcon },
  { name: 'HDMI Connection', image: StandIcon },
];

export default function Attributes() {
  const dispatch = useDispatch();
  const { floorId, locationId } = useParams<{ floorId?: string; locationId: string }>();
  const { attributesDesk, attributesRoom, loading } = useTypedSelector(state => state.adminAttributes);

  const [attributeTab, setAttributeTab] = useState<AttributeType>(AttributeTypeEnum.DESK);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    dispatch(getAttributes({ locationId }));
    setLoaded(true);
  }, [locationId]);

  const onChangeTabIndex = (attributeType: AttributeType) => {
    setAttributeTab(attributeType);
  };

  return (
    <>
      {!floorId && <LocationDetailsHeader />}

      <Box className="tabs-custom" marginTop={20}>
        <Button
          className={attributeTab === AttributeTypeEnum.DESK ? 'active' : ''}
          onClick={() => onChangeTabIndex(AttributeTypeEnum.DESK)}
          size="sm"
        >
          <Trans>Desk</Trans>
        </Button>

        <Button
          className={attributeTab === AttributeTypeEnum.ROOM ? 'active' : ''}
          onClick={() => onChangeTabIndex(AttributeTypeEnum.ROOM)}
          size="sm"
        >
          <Trans>Room</Trans>
        </Button>
      </Box>

      <Box className={styles.attributes} display="flex" marginTop={20}>
        <TableContainer>
          <Table
            aria-label={t`attributes available`}
            aria-labelledby={t`attributes list`}
          >
            <TableHead classes={{ root: styles.tableHead }}>
              <TableRow classes={{ root: styles.tableRow }}>
                {headCells.map((headCell) => (
                  <TableCell classes={{ root: styles.tableCell }} key={headCell.id} padding="none">
                    {headCell.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {(!loading && loaded) ? (
              <TableBody classes={{ root: styles.tableBody }}>
                {attributeTab === AttributeTypeEnum.DESK &&
                  <AttributesBody
                    attributes={attributesDesk}
                    bodyCells={deskAttributesCells}
                    type={AttributeTypeEnum.DESK}
                  />
                }
                {attributeTab === AttributeTypeEnum.ROOM &&
                  <AttributesBody
                    attributes={attributesRoom}
                    bodyCells={bodyCellsRoom}
                    type={AttributeTypeEnum.ROOM}
                  />
                }
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>

        {loading ? (
          <Box height={200}>
            <Skeleton height="100%" variant="rect" />
          </Box>
        ) : null}
      </Box>
    </>
  );
}
