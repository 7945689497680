import Approve from './Pages/Approve';
import Deny from './Pages/Deny';
import EmailConfirmed from './Pages/EmailConfirmed';
import Error404 from 'App/Pages/Error404';
import { Route, Switch, useLocation } from 'react-router-dom';

export default function BookingApprovalRoutes() {
  const location = useLocation();

  return (
    <Switch key={location.pathname} location={location}>
      <Route path='/booking-approval/approve' render={() => <Approve />} />
      <Route path='/booking-approval/deny' render={() => <Deny />} />
      <Route path='/booking-approval/email-confirmed' render={() => <EmailConfirmed />} />
      <Route path='*' render={() => <Error404 />} />
    </Switch>
  );
}