import { togetherApi } from "../together-api";
import { APIResponseBody, FinishQuestionnaireInput, PendingQuestionnaire, SaveQuestionnaireAnswersInput } from "../types";

const questionnaires = togetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getPendingQuestionnaires: builder.query<APIResponseBody<{ bookings?: PendingQuestionnaire[]; appointments?: PendingQuestionnaire[] }>, void>({
      query: () => ({ url: "/api/v2/bookings/questionnaire-bookings" }),
    }),
    finishQuestionnaire: builder.mutation<APIResponseBody<void>, FinishQuestionnaireInput>({
      query: ({ result, ...body }) => ({
        body,
        url: `/api/bookings/hasPassedQuestionnaire/${result}`,
        method: "put",
      }),
    }),
    saveQuestionnaireAnswers: builder.mutation<APIResponseBody<void>, SaveQuestionnaireAnswersInput>({
      query: ({ locationId, answers: body }) => ({
        body,
        url: `/api/covid-questions/${locationId}/answers`,
        method: "post",
      }),
    }),
  }),
});

export const {
  useGetPendingQuestionnairesQuery,
  useFinishQuestionnaireMutation,
  useSaveQuestionnaireAnswersMutation,
} = questionnaires;
