import { isSupported } from "firebase/messaging";
import { useExceptionTracker, useIsAuthenticatedInAllScopes } from "hooks";
import { getMessagingToken } from "messaging";
import React, { useEffect } from "react";
import { isSuccessAPIResponse, useSignUpMutation } from "store";

export const PushSetup: React.FC = () => {
  const trackException = useExceptionTracker();
  const isAuthenticated = useIsAuthenticatedInAllScopes();
  const [signUp] = useSignUpMutation();

  useEffect(() => {
    void (async () => {
      const supported = await isSupported();

      if (supported && Notification.permission === "default") {
        const permission = await Notification.requestPermission();

        if (isAuthenticated && permission === "granted") {
          const deviceToken = await getMessagingToken();

          if (deviceToken) {
            const response = await signUp({ deviceToken });

            if (!isSuccessAPIResponse(response)) {
              trackException(response.error, { endpointName: signUp.name });
            }
          }
        }
      }
    })();
  }, [isAuthenticated]);

  return <></>;
};
