import { Plural, t } from "@lingui/macro";
import { Box, Skeleton, Typography } from "@mui/material";
import { TogetherRoom, useToast } from "components";
import { useLazyLoadScrollHandler } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import { Floor, reservationsSlice, selectCurrentReservation, useLazyGetFloorsByLocationIdQuery } from "store";
import { theme } from "theme";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

export const ReservationLocationFloorsRoute: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const currentReservation = useSelector(selectCurrentReservation);
  const viewRef = useRef<HTMLDivElement>(null);
  const [floorsToList, setFloorsToList] = useState<Floor[]>([]);
  const [page, setPage] = useState(1);
  const [triggerGetFloorsQuery, getFloorsQuery] = useLazyGetFloorsByLocationIdQuery();
  const { data, isLoading: floorsAreLoading, isFetching: floorsAreFetching, isError: failedToGetFloors } = getFloorsQuery;
  const floors = data?.result?.data?.items;
  const hasMore = data?.result?.data?.links?.next;

  useEffect(() => {
    if (failedToGetFloors) {
      toast.showToast({ severity: "error", message: t`Sorry, we couldn't load the floors list. Try again later.` });
    }
  }, [failedToGetFloors]);

  useEffect(() => {
    if (currentReservation?.locationId !== locationId) {
      dispatch(reservationsSlice.actions.setCurrent({ locationId }));
    }
  }, []);

  useEffect(() => {
    setFloorsToList([]);
    setPage(1);
  }, [locationId]);

  useEffect(() => {
    if (floors) {
      const newFloors = floors.filter(({ id }) => !floorsToList.some((floor) => floor.id === id));

      setFloorsToList([...floorsToList, ...newFloors]);
    }
  }, [floors]);

  useEffect(() => {
    triggerGetFloorsQuery({
      locationId,
      page: 1,
      orderBy: "asc:floorName",
      include: ["extra.availableRooms"],
    }, true);
  }, [locationId]);

  useEffect(() => {
    triggerGetFloorsQuery({
      locationId,
      page,
      orderBy: "asc:floorName",
      include: ["extra.availableRooms"],
    }, true);
  }, [page]);

  const handleScroll = useLazyLoadScrollHandler(viewRef, () => {
    if (hasMore && !floorsAreLoading && !floorsAreFetching) {
      setPage(page + 1);
    }
  }, 200);

  return (
    <Box display="flex" flexDirection="column">
      <Typography fontWeight="600" marginBottom={1}>{t`Floors list`}</Typography>
      <Box
        display="flex"
        flexDirection="column"
        maxHeight={710}
        mb={2}
        onScroll={handleScroll}
        paddingRight={2}
        ref={viewRef}
        sx={{ overflowX: "hidden", overflowY: "auto" }}
      >
        {floorsAreFetching || floorsAreLoading ? <Box mb={2}><Skeleton height={85} variant="rectangular" /></Box> : undefined}
        {floorsToList.map(({ id, name, extra }) => {
          const hasRooms = (extra?.availableRooms || 0) > 0;

          return (
            <Box
              bgcolor={theme.palette.grey[100]}
              borderRadius={2}
              component={Link}
              display="flex"
              flexDirection="column"
              key={id}
              marginBottom={2}
              padding={2}
              sx={{ 
                pointerEvents: hasRooms ? "all" : "none",
                opacity: hasRooms ? 1 : 0.5,
                cursor: hasRooms ? "pointer" : "initial",
                transition: "background-color .15s",
                ":hover": { bgcolor: theme.palette.grey[200] },
              }}
              to={`/reservations/locations/${locationId}/floors/${id}/rooms`} 
            >
              <Typography fontSize={14} fontWeight="600" mb={1}>{name}</Typography>
              <Box alignItems="center" display="flex">
                <TogetherRoom stroke={theme.palette.grey[600]} sx={{ width: 16, mr: 1 }} />
                <Typography color={theme.palette.grey[600]} fontSize={14}>
                  {hasRooms ? <Plural one="# Room" other="# Rooms" value={extra?.availableRooms} /> : t`No available rooms`}
                </Typography>
              </Box>
            </Box>
          );
        })}
        {hasMore ? <Box><Skeleton height={85} variant="rectangular" /></Box> : undefined}
      </Box>
    </Box>
  );
};
