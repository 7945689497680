import Error404 from '../Error404';
import Layout from '../CreateNewBooking/Layout';
import VisitDate from './VisitDate';
import VisitFloor from './VisitFloor';
import VisitLocation from './VisitLocation';
import { AnimatePresence } from 'framer-motion';
import { ProtectedRoute } from 'Components/ProtectedRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import { t } from '@lingui/macro';
import { VisitDateGuard, VisitFloorGuard } from './guards';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'Store/Redux/store';
import { useEffect } from 'react';
import { getNowInTimeZone, startOfDayInTimeZone } from 'utils';
import { isBefore } from 'date-fns';
import { setCreateNewVisitData } from 'App/Store/Bookings/createNewVisitDuck';

export default function CreateNewVisitRoutes() {
  const dispatch = useDispatch();
  const dateFrom = useTypedSelector((state) => state.createNewVisit.dateFrom);
  const dateTo = useTypedSelector((state) => state.createNewVisit.dateTo);
  const location = useTypedSelector((state) => {
    const { locationId } = state.createNewVisit;

    if (!locationId) {
      return;
    }

    return state.locations.locations?.find(({ id }) => id === locationId);
  });

  useEffect(() => {
    if (!location) {
      return;
    }

    const timeZone = location?.timezone || "UTC";
    const startOfDay = startOfDayInTimeZone(getNowInTimeZone(timeZone), timeZone);

    if (dateFrom && isBefore(new Date(dateFrom), startOfDay)) {
      dispatch(setCreateNewVisitData({ dateFrom: startOfDay }));
    }

    if (dateTo && isBefore(new Date(dateTo), startOfDay)) {
      dispatch(setCreateNewVisitData({ dateTo: startOfDay }));
    }
  }, [dateFrom, dateTo, location]);

  return (
    <Layout title={t`Create new visit`}>
      <AnimatePresence exitBeforeEnter initial={true}>
        <Switch>
          <Route
            exact
            path='/create-new-visit/onsite/select-location'
            render={() => <VisitLocation />}
          />

          <ProtectedRoute
            exact
            fallback={() => <Redirect to="/create-new-visit/onsite/select-location" />}
            guards={[VisitFloorGuard]}
            path='/create-new-visit/onsite/select-floor'
            render={() => <VisitFloor />}
          />

          <ProtectedRoute
            exact
            fallback={() => <Redirect to="/create-new-visit/onsite/select-location" />}
            guards={[VisitDateGuard]}
            path='/create-new-visit/onsite/select-date'
            render={() => <VisitDate />}
          />

          <Route path='*' render={() => <Error404 />} />
        </Switch>
      </AnimatePresence >
    </Layout >
  );
}
