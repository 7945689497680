import { t } from '@lingui/macro';

/**
 * A payload can have multiple data inside it.
 * This function tries to find an error message in the mapped data and returns a
 * fallback message if it doesn't find one.
 */
export default function getErrorMessageFromPayload({
  fallbackMessage = t`An error occurred`,
  payload,
}: {
  fallbackMessage?: string;
  payload: any;
}) {
  return payload.statusText ?? payload.error?.message ?? fallbackMessage;
}