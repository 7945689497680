interface Props {
  color?: string;
  size?: number;
}

export default function DeskIcon({ color = '#4A4A4A', size = 16 }: React.PropsWithChildren<Props>) {
  return (
    <svg height={`${size}px`} viewBox={`0 0 ${size} ${size}`} width={`${size}px`}>
      <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
        <g id="A-Location-Floor-addsection1" transform="translate(-1030.000000, -247.000000)">
          <rect fill="none" height="943" width="1440" x="0" y="0"></rect>
          <g id="icons/sit" transform="translate(1030.000000, 247.000000)">
            <g transform="translate(0.000000, 0.000000)"></g>
            <rect fill={color} height="5" id="Rectangle" rx="1" width="2" x="7" y="10"></rect>
            <path d="M10,0 C11.6568542,0 13,1.34314575 13,3 L13,3 L13.0004345,7.05009729 C14.1413382,7.28190033 15,8.29067455 15,9.5 C15,10.8807119 13.8807119,12 12.5,12 L12.5,12 L3.5,12 C2.11928813,12 1,10.8807119 1,9.5 C1,8.29031824 1.85916789,7.2813059 3.00057405,7.0498926 L3,3 C3,1.34314575 4.34314575,0 6,0 L6,0 Z M12.5,9 L3.5,9 C3.22385763,9 3,9.22385763 3,9.5 C3,9.77614237 3.22385763,10 3.5,10 L3.5,10 L12.5,10 C12.7761424,10 13,9.77614237 13,9.5 C13,9.22385763 12.7761424,9 12.5,9 L12.5,9 Z M10,2 L6,2 C5.44771525,2 5,2.44771525 5,3 L5,3 L5,7 L11,7 L11,3 C11,2.48716416 10.6139598,2.06449284 10.1166211,2.00672773 L10.1166211,2.00672773 L10,2 Z M10,13 C11.5976809,13 12.9036609,14.24892 12.9949073,15.8237272 L13,16 L11,16 C11,15.4871642 10.6139598,15.0644928 10.1166211,15.0067277 L10,15 L6,15 C5.48716416,15 5.06449284,15.3860402 5.00672773,15.8833789 L5,16 L3,16 C3,14.4023191 4.24891996,13.0963391 5.82372721,13.0050927 L6,13 L10,13 Z" fill={color} fillRule="nonzero" id="Combined-Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
