import { useState } from "react";
import { Trans } from "@lingui/macro";
import Button from "../../../../Components/Button";
import AddUsersForm from "./AddUsersForm";

interface Props {
  buttonType?: 'default' | 'thin';
}

export default function AddUserButton({ buttonType }: Props) {
  const [isOpenUsersForm, setIsOpenUsersForm] = useState(false);

  const onOpenUsersForm = () => {
    setIsOpenUsersForm(o => !o);
  };

  const onCloseUsersForm = () => {
    setIsOpenUsersForm(false);
  };

  return (
    <>
      <Button
        noPaddingX={ buttonType === 'thin' }
        onClick={onOpenUsersForm}
        size="sm"
        type={ buttonType === 'thin' ? "clear" : "primary" }
      >
        <Trans>Add User</Trans>
      </Button>
      {
        isOpenUsersForm ?
          <AddUsersForm
            onCancelAction={onCloseUsersForm}
            open={isOpenUsersForm}
          /> : null
      }
    </>
  );
}
