import * as variables from './variables';
import { createTheme } from '@material-ui/core';
import { ThemeColors } from './models';

export const MaterialThemeGreen = createTheme({
  palette: {
    primary: {
      main: variables.persianGreen,
    },
    secondary: {
      main: variables.persianGreenLight,
    },
    error: {
      main: variables.red,
  },
    success: {
      main: variables.green,
    },
    warning: {
      main: variables.orange,
    },
  },
});

export const ThemeGreen: ThemeColors = {
  primary: variables.persianGreen,
  primaryHover: variables.persianGreen2,
  primaryLight: variables.persianGreenLight,
  primaryLighten20: variables.persianGreenLighten20,
  primaryLighten50: variables.persianGreenLighten50,
  primaryTransparent: variables.persianGreenTransparent,
  secondary: variables.persianGreenLight,
  error: variables.red,
  errorTransparent: variables.redTransparent,
  warn: variables.orange,
  warnLight: variables.orangeLight,
  success: variables.green,
  successTransparent: variables.greenTransparent,
  grey: variables.grey,
  greyDark: variables.greyDark,
  greyDarkTransparent: variables.greyDarkTransparent,
  greyLight: variables.greyLight,
  greyLightTransparent: variables.greyLightTransparent,
  greyMedium: variables.greyMedium,
  greyTransparent: variables.greyTransparent,
};