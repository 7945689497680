import { ActionPayload, BaseErrorResponse, BaseResponse } from 'Store/Models/ReduxModels';
import { LoginQAResponse, LoginRequest, SetLoginDataRequest } from './models';
import { t } from '@lingui/macro';

export const IPG_TOKEN_LOCAL_STORAGE_KEY = 'ipg-token';
export const IPG_GRAPH_TOKEN_LOCAL_STORAGE_KEY = 'ipg-graph-token';
export const IPG_LOGIN_QA_LOCAL_STORAGE_KEY = 'login-qa';

export const LOGIN_QA = 'LOGIN_QA';
export const LOGIN_QA_FAIL = 'LOGIN_QA_FAIL';
export const LOGIN_QA_SUCCESS = 'LOGIN_QA_SUCCESS';

export const SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN';
export const SET_GRAPH_LOGIN_TOKEN = 'SET_GRAPH_LOGIN_TOKEN';

export const LOGOUT = 'LOGOUT';

export const SET_LOGIN_DATA = 'SET_LOGIN_DATA';

export interface LoginQA {
  type: typeof LOGIN_QA;
  payload: ActionPayload<LoginRequest>;
}
export interface LoginQAFail {
  type: typeof LOGIN_QA_FAIL;
  payload: BaseErrorResponse;
}
export interface LoginQASuccess {
  type: typeof LOGIN_QA_SUCCESS;
  payload: BaseResponse<LoginQAResponse>;
}

export interface SetLoginToken {
  type: typeof SET_LOGIN_TOKEN;
  payload: { token: string; expiration?: Date };
}

export interface SetGraphLoginToken {
  type: typeof SET_GRAPH_LOGIN_TOKEN;
  payload: { token: string; expiration?: Date };
}

export interface Logout {
  type: typeof LOGOUT;
}

export interface SetLoginData {
  type: typeof SET_LOGIN_DATA;
  payload: SetLoginDataRequest;
}

export type Actions =
  | LoginQA
  | LoginQAFail
  | LoginQASuccess
  | SetLoginToken
  | SetGraphLoginToken
  | SetLoginData
  | Logout

export interface State {
  error: string;
  loading: boolean;
  token: string;
  tokenExpiration?: Date | string;
  tokenGraphApi: string;
  tokenGraphApiExpiration?: Date | string;
  unauthorized: boolean;
  loginQA: boolean;
}

const initialState: State = {
  error: '',
  loading: false,
  token: '',
  tokenGraphApi: '',
  unauthorized: true,
  loginQA: false,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case LOGIN_QA:
      return {
        ...state,
        error: '',
        loading: true,
        loginQA: false,
      };
    case LOGIN_QA_FAIL:
      return {
        ...state,
        error: t`There was an error. Please try again.`,
        loading: false,
        loginQA: false,
      };
    case LOGIN_QA_SUCCESS: {
      const { access_token } = action.payload.data.result.data;

      localStorage.setItem(IPG_TOKEN_LOCAL_STORAGE_KEY, access_token);
      localStorage.setItem(IPG_LOGIN_QA_LOCAL_STORAGE_KEY, 'true');

      localStorage.setItem(IPG_TOKEN_LOCAL_STORAGE_KEY, access_token);
      localStorage.setItem(IPG_LOGIN_QA_LOCAL_STORAGE_KEY, 'true');

      return {
        ...state,
        error: '',
        loading: false,
        token: access_token,
        unauthorized: false,
        loginQA: true,
      };
    }

    case SET_LOGIN_TOKEN: {
      const { token, expiration } = action.payload;

      localStorage.setItem(IPG_TOKEN_LOCAL_STORAGE_KEY, token);

      localStorage.setItem(IPG_TOKEN_LOCAL_STORAGE_KEY, token);

      return {
        ...state,
        error: '',
        loading: false,
        token,
        tokenExpiration: expiration,
      };
    }

    case SET_GRAPH_LOGIN_TOKEN: {
      const { token, expiration } = action.payload;

      localStorage.setItem(IPG_GRAPH_TOKEN_LOCAL_STORAGE_KEY, token);

      return {
        ...state,
        error: '',
        tokenGraphApi: token,
        tokenGraphApiExpiration: expiration,
      };
    }

    case LOGOUT:
      localStorage.removeItem(IPG_TOKEN_LOCAL_STORAGE_KEY);
      localStorage.removeItem(IPG_GRAPH_TOKEN_LOCAL_STORAGE_KEY);
      localStorage.removeItem(IPG_LOGIN_QA_LOCAL_STORAGE_KEY);

      return initialState;

    case SET_LOGIN_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function loginQA({ email, password, platform }: LoginRequest): LoginQA {
  return {
    type: LOGIN_QA,
    payload: {
      request: {
        method: 'POST',
        url: '/api/auth/login',
        data: {
          email,
          password,
          platform,
        },
      },
    },
  };
}

export function setLoginToken(token: string, expiration?: Date): SetLoginToken {
  return {
    type: SET_LOGIN_TOKEN,
    payload: { token, expiration },
  };
}

export function setGraphLoginToken(token: string, expiration?: Date): SetGraphLoginToken {
  return {
    type: SET_GRAPH_LOGIN_TOKEN,
    payload: { token, expiration },
  };
}

export function logout(): Logout {
  return {
    type: LOGOUT,
  };
}

export function setLoginData(data: SetLoginDataRequest): SetLoginData {
  return {
    type: SET_LOGIN_DATA,
    payload: data,
  };
}

