import { AddUserDeviceTokenResponse, GetProfileResponse } from './models';

export const addUserDeviceTokenResponse: AddUserDeviceTokenResponse = {
  result: {
    statusCode: 201,
  },
};

export const getProfileSuccess: GetProfileResponse = {
  error: null,
  result: {
    statusCode: 200,
    data: {
      id: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
      email: 'unit-test@kinesso.com',
      name: 'Unit Test',
      mobilePhone: '+16472935734',
      location: '13th Street 47 W 13th St, New York, USA, North America',
      isAdmin: true,
      approvedAccommodation: false,
      locationIds: [],
      groups: [],
      role: {
        id: '',
        name: 'Super Admin',
      },
    },
  },
};
