import { ActionPayload, BaseErrorResponse, BaseResponse } from 'Store/Models/ReduxModels';
import { t } from '@lingui/macro';
import {
  BookingDateRangeModel,
  CreateNotificationModel,
  CreateNotificationRequest,
  CreateNotificationResponse,
  DeleteNotificationRequest,
  DeleteNotificationResponse,
  EditNotificationRequest,
  GetNotificationsRequest,
  GetNotificationsResponse,
  NotificationModel,
  SendNotificationModel,
  SendNotificationRequest,
  SendNotificationResponse,
  SetErrorMessageRequest,
  SetNotificationsDataRequest,
} from './models';

export const SET_NOTIFICATION_WHEN_TO_SEND_DATE = 'SET_NOTIFICATION_WHEN_TO_SEND_DATE';
export const RESET_NOTIFICATION_WHEN_TO_SEND_DATE = 'RESET_NOTIFICATION_WHEN_TO_SEND_DATE';
export const SET_NOTIFICATION_DATE_RANGE_DATE = 'SET_NOTIFICATION_DATE_RANGE_DATE';
export const RESET_NOTIFICATION_DATE_RANGE_DATE = 'RESET_NOTIFICATION_DATE_RANGE_DATE';

export const SET_NOTIFICATION_ERROR_MESSAGE = 'SET_NOTIFICATION_ERROR_MESSAGE';
export const SET_NOTIFICATIONS_DATA = 'SET_NOTIFICATIONS_DATA';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_FAIL = 'CREATE_NOTIFICATION_FAIL';

export const EDIT_NOTIFICATION = 'EDIT_NOTIFICATION';
export const EDIT_NOTIFICATION_SUCCESS = 'EDIT_NOTIFICATION_SUCCESS';
export const EDIT_NOTIFICATION_FAIL = 'EDIT_NOTIFICATION_FAIL';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAIL = 'DELETE_NOTIFICATION_FAIL';

export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAIL = 'SEND_NOTIFICATION_FAIL';

// set\reset time data for notification form
export interface SetWhenToSendDate {
  type: typeof SET_NOTIFICATION_WHEN_TO_SEND_DATE;
  payload: Date | string;
}

export interface ResetWhenToSendDate {
  type: typeof RESET_NOTIFICATION_WHEN_TO_SEND_DATE;
}

export interface SetDateRangeDate {
  type: typeof SET_NOTIFICATION_DATE_RANGE_DATE;
  payload: BookingDateRangeModel;
}

export interface ResetDateRangeDate {
  type: typeof RESET_NOTIFICATION_DATE_RANGE_DATE;
}

// get list of all notifications
export interface GetNotifications {
  type: typeof GET_NOTIFICATIONS;
  payload: ActionPayload<GetNotificationsRequest>
}

export interface GetNotificationsSuccess {
  type: typeof GET_NOTIFICATIONS_SUCCESS;
  payload: BaseResponse<GetNotificationsResponse>
}

export interface GetNotificationsFail {
  type: typeof GET_NOTIFICATIONS_FAIL;
  payload: BaseErrorResponse;
}

// add notification
export interface CreateNotification {
  type: typeof CREATE_NOTIFICATION;
  payload: ActionPayload<CreateNotificationModel>;
}

export interface CreateNotificationSuccess {
  type: typeof CREATE_NOTIFICATION_SUCCESS;
  payload: BaseResponse<CreateNotificationResponse>;
}

export interface CreateNotificationFail {
  type: typeof CREATE_NOTIFICATION_FAIL;
  payload: BaseErrorResponse;
}

// edit notification
export interface EditNotification {
  type: typeof EDIT_NOTIFICATION;
  payload: ActionPayload<CreateNotificationModel>;
}

export interface EditNotificationSuccess {
  type: typeof EDIT_NOTIFICATION_SUCCESS;
  payload: BaseResponse<CreateNotificationResponse>;
}

export interface EditNotificationFail {
  type: typeof EDIT_NOTIFICATION_FAIL;
  payload: BaseErrorResponse;
}

// delete notification
export interface DeleteNotification {
  type: typeof DELETE_NOTIFICATION;
  payload: ActionPayload<DeleteNotificationRequest>
}

export interface DeleteNotificationSuccess {
  type: typeof DELETE_NOTIFICATION_SUCCESS;
  payload: BaseResponse<DeleteNotificationResponse>;
}

export interface DeleteNotificationFail {
  type: typeof DELETE_NOTIFICATION_FAIL;
  payload: BaseErrorResponse;
}

// send notification
export interface SendNotification {
  type: typeof SEND_NOTIFICATION;
  payload: ActionPayload<SendNotificationModel>;
}

export interface SendNotificationSuccess {
  type: typeof SEND_NOTIFICATION_SUCCESS;
  payload: BaseResponse<SendNotificationResponse>;
}

export interface SendNotificationFail {
  type: typeof SEND_NOTIFICATION_FAIL;
  payload: BaseErrorResponse;
}

// error message set
export interface SetErrorMessage {
  type: typeof SET_NOTIFICATION_ERROR_MESSAGE;
  payload: SetErrorMessageRequest;
}

// set any data
export interface SetNotificationsData {
  type: typeof SET_NOTIFICATIONS_DATA;
  payload: SetNotificationsDataRequest;
}

export type Actions =
  | SetWhenToSendDate
  | ResetWhenToSendDate
  | SetDateRangeDate
  | ResetDateRangeDate
  | GetNotifications
  | GetNotificationsSuccess
  | GetNotificationsFail
  | CreateNotification
  | CreateNotificationSuccess
  | CreateNotificationFail
  | EditNotification
  | EditNotificationSuccess
  | EditNotificationFail
  | DeleteNotification
  | DeleteNotificationSuccess
  | DeleteNotificationFail
  | SendNotification
  | SendNotificationSuccess
  | SendNotificationFail
  | SetErrorMessage
  | SetNotificationsData

export interface State {
  error: string;
  loading: boolean;
  successMessage: string;
  isOpenNotificationCalendar: boolean;
  isOpenNotificationDateRangeCalendar: boolean;
  notificationSent: boolean;
  notifications: NotificationModel[];
  totalPages: number;
  notificationsTimeSettings: { // to do add separate interfaces
    whenToSend: Date | string;
    bookingDateRange: BookingDateRangeModel;
  }
}

const initialWhenToSend = '';

const initialBookingDateRange: BookingDateRangeModel = {
  startDate: null,
  endDate: null,
};

const initialState: State = {
  error: '',
  loading: false,
  successMessage: '',
  isOpenNotificationCalendar: false,
  isOpenNotificationDateRangeCalendar: false,
  notificationSent: false,
  notifications: [],
  totalPages: 1,
  notificationsTimeSettings: {
    whenToSend: initialWhenToSend,
    bookingDateRange: initialBookingDateRange,
  },
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SET_NOTIFICATION_WHEN_TO_SEND_DATE:
      return {
        ...state,
        notificationsTimeSettings: {
          ...state.notificationsTimeSettings,
          whenToSend: action.payload,
        },
      };
    case RESET_NOTIFICATION_WHEN_TO_SEND_DATE:
      return {
        ...state,
        notificationsTimeSettings: {
          ...state.notificationsTimeSettings,
          whenToSend: initialWhenToSend,
        },
      };
    case SET_NOTIFICATION_DATE_RANGE_DATE:
      return {
        ...state,
        notificationsTimeSettings: {
          ...state.notificationsTimeSettings,
          bookingDateRange: action.payload,
        },
      };
    case RESET_NOTIFICATION_DATE_RANGE_DATE:
      return {
        ...state,
        notificationsTimeSettings: {
          ...state.notificationsTimeSettings,
          bookingDateRange: initialBookingDateRange,
        },
      };

    case GET_NOTIFICATIONS:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        notifications: action.payload.data.result.data.notifications,
        totalPages: action.payload.data.result.data.totalPages,
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: t`There was an error getting notifications. Please try again.`,
        loading: false,
      };

    case CREATE_NOTIFICATION:
      return {
        ...state,
      };
    case CREATE_NOTIFICATION_SUCCESS: {
      const notification = action.payload.data.result.data;
      return {
        ...state,
        error: '',
        successMessage: 'Notification was created',
        notifications: [ notification, ...state.notifications ],
      };
    }
    case CREATE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: t`There was an error creating the notification. Please try again.`,
      };

    case EDIT_NOTIFICATION:
      return {
        ...state,
      };
    case EDIT_NOTIFICATION_SUCCESS: {
      const notification = action.payload.data.result.data;
      const newNotifications = [...state.notifications];
      const index = newNotifications.findIndex(q => q.id === notification.id);
      newNotifications[index] = notification;
      return {
        ...state,
        error: '',
        successMessage: 'Notification was edited',
        notifications: newNotifications,
      };
    }
    case EDIT_NOTIFICATION_FAIL:
      return {
        ...state,
        error: t`There was an error editing the notification. Please try again.`,
      };

    case DELETE_NOTIFICATION:
      return {
        ...state,
      };
    case DELETE_NOTIFICATION_SUCCESS: {
      const deletedNotificationId = action.payload.data.result.data.id;
      const newNotifications = [...state.notifications].filter(q => q.id !== deletedNotificationId);
      return {
        ...state,
        successMessage: 'Notification was deleted',
        notifications: newNotifications,
      };
    }
    case DELETE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: t`There was an error removing the notification. Please try again.`,
      };

    case SEND_NOTIFICATION:
      return {
        ...state,
      };
    case SEND_NOTIFICATION_SUCCESS: {
      const newNotifications = [...state.notifications];
      const sendNotificationId = action.payload.data.result.data.id;
      const notification = newNotifications.find(q => q.id === sendNotificationId);

      if (notification) {
        notification.status = 'sent';
        const index = newNotifications.findIndex(q => q.id === notification.id);
        newNotifications[index] = notification;
      }

      return {
        ...state,
        successMessage: 'Notification sent',
        notifications: newNotifications,
      };
    }
    case SEND_NOTIFICATION_FAIL: {
      const error = t`There was an error. ${action.payload?.error?.message}. Please try again.`;

      return {
        ...state,
        error,
      };
    }

    case SET_NOTIFICATION_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload.error,
      };

    case SET_NOTIFICATIONS_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function setWhenToSendDate(date: Date | string): SetWhenToSendDate {
  return {
    type: SET_NOTIFICATION_WHEN_TO_SEND_DATE,
    payload: date,
  };
}

export function resetWhenToSendDate(): ResetWhenToSendDate {
  return {
    type: RESET_NOTIFICATION_WHEN_TO_SEND_DATE,
  };
}

export function setDateRange(dates: BookingDateRangeModel): SetDateRangeDate {
  return {
    type: SET_NOTIFICATION_DATE_RANGE_DATE,
    payload: dates,
  };
}

export function resetDateRangeDate(): ResetDateRangeDate {
  return {
    type: RESET_NOTIFICATION_DATE_RANGE_DATE,
  };
}

export function setErrorMessage(error: SetErrorMessageRequest): SetErrorMessage {
  return {
    type: SET_NOTIFICATION_ERROR_MESSAGE,
    payload: error,
  };
}

export function getNotifications(data: GetNotificationsRequest): GetNotifications {
  return {
    type: GET_NOTIFICATIONS,
    payload: {
      request: {
        method: 'GET',
        url: `/api/locations/${data.locationId}/notifications?page=${data.page}`,
      },
    },
  };
}

export function createNotification(data: CreateNotificationRequest): CreateNotification {
  return {
    type: CREATE_NOTIFICATION,
    payload: {
      request: {
        method: 'POST',
        url: `/api/locations/${data.locationId}/notifications`,
        data: data.notification,
      },
    },
  };
}

export function editNotification(data: EditNotificationRequest): EditNotification {
  return {
    type: EDIT_NOTIFICATION,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/${data.notificationId}/notifications`,
        data: data.notification,
      },
    },
  };
}

export function deleteNotification(data: DeleteNotificationRequest): DeleteNotification {
  return {
    type: DELETE_NOTIFICATION,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/${data.notificationId}/notifications`,
      },
    },
  };
}

export function sendNotification(data: SendNotificationRequest): SendNotification {
  return {
    type: SEND_NOTIFICATION,
    payload: {
      request: {
        method: 'POST',
        url: `/api/${data.notificationId}/send-notification`,
        data: data.notificationEmailData,
      },
    },
  };
}

export function setNotificationsData(data: SetNotificationsDataRequest): SetNotificationsData {
  return {
    type: SET_NOTIFICATIONS_DATA,
    payload: data,
  };
}
