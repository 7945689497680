import {
  BulkCancelVisitsBookingModel,
  BulkCancelVisitsBookingRequest,
  BulkCancelVisitsBookingResponse,
  CancelVisitRequest,
  CancelVisitResponse,
  ExportVisitsRequest,
  ExportVisitsResponse,
  GetVisitsRequest,
  GetVisitsResponse,
  SetVisitsDataRequest,
  VisitFiltersModel,
  VisitModel,
} from './models';
import {
  ActionPayload,
  BaseErrorResponse,
  BaseResponse,
} from '../../../Store/Models/ReduxModels';

export const UPDATE_VISITS_MASS_ACTIONS = 'UPDATE_VISITS_MASS_ACTIONS';
export const RESET_VISITS_MASS_ACTIONS = 'RESET_VISITS_MASS_ACTIONS';
export const UPDATE_VISITS_MASS_ACTIONS_IDS = 'UPDATE_VISITS_MASS_ACTIONS_IDS';
export const UPDATE_BULK_VISITS_MASS_ACTIONS_IDS = 'UPDATE_BULK_VISITS_MASS_ACTIONS_IDS';
export const RESET_VISITS_MASS_ACTIONS_IDS = 'RESET_VISITS_MASS_ACTIONS_IDS';
export const SET_VISITS_DATA = 'SET_VISITS_DATA';

export const EXPORT_VISITS = 'EXPORT_VISITS';
export const EXPORT_VISITS_SUCCESS = 'EXPORT_VISITS_SUCCESS';
export const EXPORT_VISITS_FAIL = 'EXPORT_VISITS_FAIL';

export const GET_VISITS = 'GET_VISITS';
export const GET_VISITS_SUCCESS = 'GET_VISITS_SUCCESS';
export const GET_VISITS_FAIL = 'GET_VISITS_FAIL';

export const CANCEL_VISITS_BOOKING = 'CANCEL_VISITS_BOOKING';
export const CANCEL_VISITS_BOOKING_FAIL = 'CANCEL_VISITS_BOOKING_FAIL';
export const CANCEL_VISITS_BOOKING_SUCCESS = 'CANCEL_VISITS_BOOKING_SUCCESS';

export const BULK_CANCEL_VISITS_BOOKING = 'BULK_CANCEL_VISITS_BOOKING';
export const BULK_CANCEL_VISITS_BOOKING_FAIL = 'BULK_CANCEL_VISITS_BOOKING_FAIL';
export const BULK_CANCEL_VISITS_BOOKING_SUCCESS = 'BULK_CANCEL_VISITS_BOOKING_SUCCESS';

export const UPDATE_VISITS_LOADING = 'UPDATE_VISITS_LOADING';

export interface UpdateVisitMassActions {
  type: typeof UPDATE_VISITS_MASS_ACTIONS;
  payload: boolean;
}

export interface UpdateBulkVisitMassActions {
  type: typeof UPDATE_BULK_VISITS_MASS_ACTIONS_IDS;
  payload: string[];
}

export interface ResetVisitMassActions {
  type: typeof RESET_VISITS_MASS_ACTIONS;
}

export interface UpdateVisitMassActionsIds {
  type: typeof UPDATE_VISITS_MASS_ACTIONS_IDS;
  payload: string;
}

export interface ResetVisitMassActionsIds {
  type: typeof RESET_VISITS_MASS_ACTIONS_IDS;
}

export interface SetVisitsData {
  type: typeof SET_VISITS_DATA;
  payload: SetVisitsDataRequest;
}

export interface ExportVisits {
  type: typeof EXPORT_VISITS;
  payload: ActionPayload<ExportVisitsRequest>
}

export interface ExportVisitsSuccess {
  type: typeof EXPORT_VISITS_SUCCESS;
  payload: BaseResponse<ExportVisitsResponse>
}

export interface ExportVisitsFail {
  type: typeof EXPORT_VISITS_FAIL;
  payload: BaseErrorResponse;
}

// get list of all Visits
export interface GetVisits {
  type: typeof GET_VISITS;
  payload: ActionPayload<GetVisitsRequest>
}

export interface GetVisitsSuccess {
  type: typeof GET_VISITS_SUCCESS;
  payload: BaseResponse<GetVisitsResponse>
}

export interface GetVisitsFail {
  type: typeof GET_VISITS_FAIL;
  payload: BaseErrorResponse;
}

// cancel Visits
export interface CancelVisitsBooking {
  type: typeof CANCEL_VISITS_BOOKING;
  payload: ActionPayload<CancelVisitRequest>;
}
export interface CancelVisitsBookingFail {
  type: typeof CANCEL_VISITS_BOOKING_FAIL;
  payload: BaseErrorResponse;
}
export interface CancelVisitsBookingSuccess {
  type: typeof CANCEL_VISITS_BOOKING_SUCCESS;
  payload: CancelVisitResponse;
}

// cancel Visits bulk
export interface BulkCancelVisitsBooking {
  type: typeof BULK_CANCEL_VISITS_BOOKING;
  payload: ActionPayload<BulkCancelVisitsBookingModel>;
}
export interface BulkCancelVisitsBookingFail {
  type: typeof BULK_CANCEL_VISITS_BOOKING_FAIL;
  payload: BaseErrorResponse;
}
export interface BulkCancelVisitsBookingSuccess {
  type: typeof BULK_CANCEL_VISITS_BOOKING_SUCCESS;
  payload: BulkCancelVisitsBookingResponse;
}

export interface UpdateVisitsLoading {
  type: typeof UPDATE_VISITS_LOADING;
  payload: boolean;
}

export type Actions =
  | ExportVisits
  | ExportVisitsSuccess
  | ExportVisitsFail
  | UpdateVisitMassActions
  | ResetVisitMassActions
  | UpdateVisitMassActionsIds
  | UpdateBulkVisitMassActions
  | ResetVisitMassActionsIds
  | SetVisitsData
  | GetVisits
  | GetVisitsSuccess
  | GetVisitsFail
  | CancelVisitsBooking
  | CancelVisitsBookingFail
  | CancelVisitsBookingSuccess
  | BulkCancelVisitsBooking
  | BulkCancelVisitsBookingFail
  | BulkCancelVisitsBookingSuccess
  | UpdateVisitsLoading;

export interface State {
  error: string;
  successMessage: string;
  loading: boolean;
  totalCount: number;
  limit: number;
  visits: VisitModel[];
  visitMassActions: boolean;
  visitMassActionsIds: string[];
  filters: VisitFiltersModel;
}

export const initFiltersData: VisitFiltersModel = {
  selectedStartDate: new Date(),
  selectedEndDate: new Date(),
  statuses: [],
  documentStatuses: [],
  search: '',
  locationIds: [],
  allSelected: false,
};
const initialState: State = {
  error: '',
  successMessage: '',
  loading: false,
  totalCount: 0,
  limit: 10,
  visits: [],
  visitMassActions: false,
  visitMassActionsIds: [],
  filters: initFiltersData,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case UPDATE_VISITS_MASS_ACTIONS:
      return {
        ...state,
        visitMassActions: action.payload,
      };
    case RESET_VISITS_MASS_ACTIONS:
      return {
        ...state,
        visitMassActions: initialState.visitMassActions,
      };
    case UPDATE_VISITS_MASS_ACTIONS_IDS: {
      const updatedIds = [...state.visitMassActionsIds];
      const found = updatedIds.indexOf(action.payload);

      if (found != -1) {
        updatedIds.splice(found, 1);
      } else {
        updatedIds.push(action.payload);
      }

      return {
        ...state,
        visitMassActionsIds: updatedIds,
      };
    }
    case UPDATE_BULK_VISITS_MASS_ACTIONS_IDS:
      return {
        ...state,
        visitMassActionsIds: action.payload,
      };
    case RESET_VISITS_MASS_ACTIONS_IDS:
      return {
        ...state,
        visitMassActionsIds: initialState.visitMassActionsIds,
      };

    case SET_VISITS_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case GET_VISITS:
      return {
        ...state,
        loading: true,
      };
    case GET_VISITS_SUCCESS:
      return {
        ...state,
        loading: false,
        visits: action.payload.data.result.data.appointments,
        totalCount: action.payload.data.result.data.totalCount,
        visitMassActionsIds: [],
      };
    case GET_VISITS_FAIL:
      return {
        ...state,
        loading: false,
        error: 'There was an error getting visits. Please try again.',
      };

    case CANCEL_VISITS_BOOKING_SUCCESS: {
      const visits = [...state.visits];
      const changedVisit = visits.find(r => r.id === action.payload.result.data.id);

      if (changedVisit) {
        changedVisit.status = 'CANCELED';
        const index = visits.findIndex(x => x.id === action.payload.result.data.id);
        visits[index] = changedVisit;
      }

      return {
        ...state,
        visits,
      };
    }
    case CANCEL_VISITS_BOOKING_FAIL:
      return {
        ...state,
        error: 'There was an error cancelling visits. Please try again.',
      };

    case BULK_CANCEL_VISITS_BOOKING_SUCCESS: {
      const visits = state.visits.map(v => {
        if (action.payload.bookingIds.includes(v.id)) {
          v.status = 'CANCELED';
        }

        return v;
      });

      return {
        ...state,
        filters: {
          ...state.filters,
          allSelected: false,
        },
        visits,
        visitMassActionsIds: [],
      };
    }
    case BULK_CANCEL_VISITS_BOOKING_FAIL:
      return {
        ...state,
        error: 'There was an error bulk cancelling visits. Please try again.',
      };
    case UPDATE_VISITS_LOADING: {
      return { ...state, loading: action.payload };
    }
    default:
      return state;
  }
}

// Actions
export function updateVisitMassActions(data: boolean): UpdateVisitMassActions {
  return {
    type: UPDATE_VISITS_MASS_ACTIONS,
    payload: data,
  };
}

export function resetVisitMassActions(): ResetVisitMassActions {
  return {
    type: RESET_VISITS_MASS_ACTIONS,
  };
}

export function updateVisitMassActionsIds(data: string): UpdateVisitMassActionsIds {
  return {
    type: UPDATE_VISITS_MASS_ACTIONS_IDS,
    payload: data,
  };
}

export function updateBulkVisitMassActionsIds(data: string[]): UpdateBulkVisitMassActions {
  return {
    type: UPDATE_BULK_VISITS_MASS_ACTIONS_IDS,
    payload: data,
  };
}


export function resetVisitMassActionsIds(): ResetVisitMassActionsIds {
  return {
    type: RESET_VISITS_MASS_ACTIONS_IDS,
  };
}

export function setVisitsData(data: SetVisitsDataRequest): SetVisitsData {
  return {
    type: SET_VISITS_DATA,
    payload: data,
  };
}

export function getVisits(data: GetVisitsRequest): GetVisits {
  let url = `/api/bookings/appointments/list?page=${data.page}&order=${data.order}&limit=${data.limit}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`;

  if (data.statuses?.length) {
    url += `&status=${data.statuses.join(',')}`;
  }

  if (data.documentStatuses?.length) {
    url += `&documentStatus=${data.documentStatuses.join(',')}`;
  }

  if (data.checking !== undefined ) {
    url += `&checking=${data.checking}`;
  }

  if (data.search) {
    url += `&search=${data.search}`;
  }

  if (data.locationIds && data.locationIds.length) {
    url += `&locationIds=${data.locationIds}`;
  }

  return {
    type: GET_VISITS,
    payload: {
      request: {
        method: 'GET',
        url,
      },
    },
  };
}


export function exportVisits(data: ExportVisitsRequest): ExportVisits {
  let url = `/api/files/appointments?&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`;

  if (data.statuses?.length) {
    url += `&status=${data.statuses.join(',')}`;
  }

  if (data.documentStatuses?.length) {
    url += `&documentStatus=${data.documentStatuses.join(',')}`;
  }

  if (data.checking !== undefined ) {
    url += `&checking=${data.checking}`;
  }

  if (data.search) {
    url += `&search=${data.search}`;
  }

  if (data.locationIds && data.locationIds.length) {
    url += `&locationIds=${data.locationIds}`;
  }

  return {
    type: EXPORT_VISITS,
    payload: {
      request: {
        method: 'GET',
        url,
        isUploadFile: true,
      },
    },
  };
}

export function cancelVisit(data: CancelVisitRequest): CancelVisitsBooking {
  return {
    type: CANCEL_VISITS_BOOKING,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/bookings/appointments/${data.appointmentId}`,
      },
    },
  };
}

export function bulkCancelVisitsBooking(data: BulkCancelVisitsBookingRequest): BulkCancelVisitsBooking {
  let url = `/api/bookings/appointments/cancel?&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}`;

  if (data.cancelAll) {
    url += `&cancelAll=${data.cancelAll}`;

    if (data.statuses?.length) {
      url += `&status=${data.statuses.join(',')}`;
    }

    if (data.search) {
      url += `&search=${data.search}`;
    }

    if (data.locationIds && data.locationIds.length) {
      url += `&locationIds=${data.locationIds}`;
    }
  }

  return {
    type: BULK_CANCEL_VISITS_BOOKING,
    payload: {
      request: {
        method: 'DELETE',
        url,
        data: { bookingIds: data.bookingIds },
      },
    },
  };
}

export const updateVisitsLoading = (payload: boolean): UpdateVisitsLoading => ({ type: UPDATE_VISITS_LOADING, payload });
