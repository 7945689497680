import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useTypedSelector } from 'Store/Redux/store';
import { de, en, es, fr, it, ja, pt } from 'make-plural/plurals';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import momentTZ from 'moment-timezone';
import moment from 'moment';

import { Languages } from "./Store/Redux/Ducks/configDuck/models";
import { setCalendarTranslations } from "./Store/Redux/Ducks/configDuck";

i18n.loadLocaleData({
  en: { plurals: en },
  es: { plurals: es },
  fr: { plurals: fr },
  it: { plurals: it },
  pt: { plurals: pt },
  ja: { plurals: ja },
  de: { plurals: de },
});

// dynamic import of lingui .po files
export async function dynamicLoadLinguiTranslations(locale: Languages) {
  try {
    const { messages } = await import(`./locales/${locale}/messages.js`);

    // update custom @lingui/react translations
    i18n.load(locale, messages);
    i18n.activate(locale);

  } catch (e) {
    console.log(e);
  }
}

// dynamic import of moment.js translations
export async function dynamicLoadMomentTranslations(locale: Languages) {
  const localePath = locale === 'en' ? 'en-gb' : locale; // english lang has a different format
  try {
    await import(`moment/locale/${localePath}`); // load data from moment catalog
    moment.locale(locale); //  apply it to moment
    // @ts-ignore
    momentTZ.defineLocale(locale, moment.localeData()._config); // copy locale to moment-timezone
    momentTZ.locale(locale); // apply it to moment-timezone
  } catch (e) {
    console.log(e);
  }
}

// dynamic import of "react-date-range" translations
export async function dynamicLoadCalendarTranslations(locale: Languages, dispatch: Function) {
  const localePath = locale === 'en' ? 'en-GB' : locale; // english lang has a different format
  try {
    const translations = await import(`date-fns/locale/${localePath}`);
    dispatch(setCalendarTranslations(translations));

  } catch (e) {
    console.log(e);
  }
}

/**
 * Adds the provider and change the language with redux config param.
 */
export default function CustomI18nProvider({ children }: React.PropsWithChildren<{}>) {
  const dispatch = useDispatch();
  const { language } = useTypedSelector(state => state.config);

  useEffect(() => {
    // With this method we dynamically load the catalogs
    dynamicLoadCalendarTranslations(language.value, dispatch);
    dynamicLoadMomentTranslations(language.value);
    dynamicLoadLinguiTranslations(language.value);
  }, [language]);

  return (
    <I18nProvider i18n={i18n}>
      {children}
    </I18nProvider>
  );
}
