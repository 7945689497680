import {useDispatch} from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Box from "../../../../../../Components/Box";
import {updateUserAccommodation} from "../../../../../Store/userManagement";

interface Props {
  approvedAccommodation: boolean;
  userId: string;
  labelText?: string;
  disabled?: boolean;
}

export default function UserAccommodation(props: Props) {
  const dispatch = useDispatch();
  const { approvedAccommodation, userId, labelText, disabled } = props;

  const handleAvailableChange = (event: any, id: string) => {
    return dispatch(updateUserAccommodation({
        userId: id,
        approve: event.target.checked,
      },
    ));
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={approvedAccommodation}
            className="switcher switcher--default"
            disabled={disabled}
            onChange={(e) => handleAvailableChange(e, userId)}
          />
        }
        label={labelText}
      />
    </Box>
  );
}
