interface Props {
  color?: string;
  size?: number;
}

export default function CheckCircle({
  color = '#4A4A4A',
  size = 16,
}: Props) {
  return (
    <svg height={size} version="1.1" viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g fill="none" fillRule="evenodd" id="A-users" stroke="none" strokeWidth="1">
        <g fill={color} fillRule="nonzero" id="p-certificate-preview" transform="translate(-60.000000, -129.000000)">
          <g id="icons/plus" transform="translate(60.000000, 129.000000)">
            <path d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z M11.9308681,5.25619392 C12.3100614,5.59694281 12.3679981,6.16188823 12.0843255,6.56988627 L12.0062787,6.66839548 L8.1788919,10.9276008 C7.82472657,11.3217242 7.23148909,11.3669028 6.82336105,11.0504194 L6.7326567,10.970959 L3.97680215,8.25120852 C3.58371143,7.86326763 3.57953667,7.23011641 3.96747756,6.83702569 C4.32557685,6.47417273 4.89261274,6.44270377 5.28690659,6.74513547 L5.38166039,6.82770111 L7.391,8.811 L10.5186665,5.33160452 C10.8878111,4.92081177 11.5200753,4.88704929 11.9308681,5.25619392 Z" id="Combined-Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
