import Skeleton from '@material-ui/lab/Skeleton';
import { clearFloorMapState, setFloorMapData } from 'Admin/Store/floorMapDuck';
import { FloorAdmin } from 'App/Store/Locations/locationsDuck/models';
import { editFloor, getFloorsAdmin, setFloorsAdmin, setLoading } from 'App/Store/Locations/locationsDuck';
import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTypedSelector } from 'Store/Redux/store';
import { SortableList, SortableListItem, useToast } from 'components';
import { Box, Button, ButtonGroup, IconButton, IconButtonProps, Popover, Typography, useTheme } from '@mui/material';
import { DragIndicator, ModeEditOutlined, MoreVert, ToggleOffOutlined, ToggleOnOutlined } from '@mui/icons-material';
import { useDebouncedCallback, useExceptionTracker } from 'hooks';
import { isSuccessAPIResponse, useUpdateLocationFloorsMutation } from 'store';
import { moveArrayItem } from 'utils';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { theme } from 'theme';


export const SortableListItemComponent: React.FC<{
  attributes: DraggableAttributes;
  listeners: SyntheticListenerMap | undefined;
  item: FloorAdmin;
  locationId: string;
  isDragging?: boolean;
}> = (props) => {
  const { attributes, listeners, locationId, item, isDragging } = props;
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick: IconButtonProps["onClick"] = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleFloorToggle = () => {
    dispatch(editFloor(item.id, { active: !item.active }));
  };

  const handleEditFloor = (floorId: string) => {
    dispatch(clearFloorMapState());
    dispatch(setFloorMapData({ locationId }));

    history.push(`/admin/location/${locationId}/add-floor/${floorId}/floor-map`);
  };

  return (
    <>
      <Box
        bgcolor={item.active ? undefined : palette.grey[50]}
        borderBottom="1px solid"
        borderColor={palette.grey[100]}
        display="flex"
        sx={{ ":hover": { bgcolor: palette.grey[100] } }}
      >
        <Box
          alignItems="center"
          display="flex"
          flexBasis={36}
          justifyContent="center"
          sx={{ cursor: isDragging ? "grabbing" : "grab" }}
          width={36}
          {...attributes}
          {...listeners}
        >
          <DragIndicator color={item.active ? "primary" : undefined} fontSize="small" sx={{ color: item.active ? undefined : palette.grey[700] }} />
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flex="1 0 auto"
          onClick={() => handleEditFloor(item.id)}
          paddingY={1}
          sx={{ cursor: "pointer" }}
        >
          <Box flex={1}>
            <Typography color={item.active ? undefined : palette.grey[700]} fontSize={14}>{item.floorName}</Typography>
          </Box>
          <Box flex={1}>
            <Typography color={item.active ? undefined : palette.grey[700]} fontSize={14}>{item.totalDesks}</Typography>
          </Box>
          <Box flex={1}>
            <Typography color={item.active ? undefined : palette.grey[700]} fontSize={14}>{item.availableDesks}</Typography>
          </Box>
          <Box flex={1}>
            <Typography color={item.active ? undefined : palette.grey[700]} fontSize={14}>{item.assignedDesks}</Typography>
          </Box>
          <Box display="flex" flexBasis={50} justifyContent='center' width={50}>
            <IconButton color="primary" onClick={handleClick} size="small">
              <MoreVert color={item.active ? "primary" : undefined} fontSize="small" sx={{ color: item.active ? undefined : palette.grey[700] }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
        open={!!anchorEl}
      >
        <Box display="flex" flexDirection="column" padding={1} width={200}>
          <ButtonGroup orientation="vertical" variant="text">
            <Button
              onClick={() => handleEditFloor(item.id)}
              startIcon={<ModeEditOutlined />}
              sx={{ justifyContent: "flex-start", borderBottom: `1px solid ${palette.grey[100]} !important` }}
            >
              {t`Edit Floor`}
            </Button>
            <Button
              onClick={handleFloorToggle}
              startIcon={item.active ? <ToggleOffOutlined /> : <ToggleOnOutlined />}
              sx={{ justifyContent: "flex-start", color: item.active ? theme.palette.grey[700] : undefined }}
            >
              {item.active ? t`Turn Off Floor` : t`Turn On Floor`}
            </Button>
          </ButtonGroup>
        </Box>
      </Popover>
    </>
  );
};

export const FloorsList: React.FC = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const toast = useToast();
  const trackException = useExceptionTracker();
  const [hasSorted, setHasSorted] = useState(false);
  const location = useTypedSelector(state => state.locations.locations.find(({ id }) => id === locationId));
  const isLoading = useTypedSelector(state => state.locations.loading);
  const floors = useTypedSelector(state => state.locations.floorsAdmin);
  const [updateLocationFloors] = useUpdateLocationFloorsMutation();

  useEffect(() => {
    setHasSorted(false);
    dispatch(setLoading(true));
    dispatch(getFloorsAdmin(locationId));
  }, [locationId]);

  const debouncedUpdateLocationFloors = useDebouncedCallback(async (floors: FloorAdmin[]) => {
    const orderedFloors = floors.map(({ id }, sortIndex) => ({ id, sortIndex }));

    const response = await updateLocationFloors({ locationId, floors: orderedFloors });
    
    if (isSuccessAPIResponse(response)) {
      toast.showToast({ severity: "success", message: t`Location's floors sorting updated` });
    } else {
      toast.showToast({ severity: "error", message: t`Failed to update location floors` });
      trackException(response.error, { endpointName: updateLocationFloors.name });
    }
  }, [updateLocationFloors], 700);

  useEffect(() => {
    if (hasSorted && floors.length) {
      void debouncedUpdateLocationFloors(floors);
    }
  }, [floors, hasSorted]);

  const handleFloorOrderChange = (current: number, next: number) => {
    dispatch(setFloorsAdmin(moveArrayItem(floors, current, next)));
    setHasSorted(true);
  };

  return (
    <Box>
      <Box bgcolor={palette.grey[100]} borderRadius={1} display="flex" marginY={1} paddingY={0.5}>
          <Box flexBasis={36} width={36} />
          <Box flex={1}>
            <Typography color={palette.grey[700]} fontSize={13}>{t`Floor`}</Typography>
          </Box>
          <Box flex={1}>
            <Typography color={palette.grey[700]} fontSize={13}>{t`Total desks`}</Typography>
          </Box>
          <Box flex={1}>
            <Typography color={palette.grey[700]} fontSize={13}>{t`Available desks`}</Typography>
          </Box>
          <Box flex={1}>
            <Typography color={palette.grey[700]} fontSize={13}>{t`Assigned desks`}</Typography>
          </Box>
          <Box flexBasis={50} width={50} />
        </Box>
        {isLoading && !floors?.length ? <Skeleton height={46} variant="rect" width="100%" /> : undefined}
        {!isLoading && !floors?.length ? (
          <Box paddingY={2}>
            <Typography color={palette.grey[700]} textAlign="center">{t`No floors found for ${location?.locationName}`}</Typography>
          </Box>
        ) : undefined}
        {floors?.length ? (
          <SortableList items={floors} onChange={handleFloorOrderChange}>
            {floors.map((item) => (
              <SortableListItem
                Component={(props) => <SortableListItemComponent {...props} item={item} locationId={locationId} />}
                id={item.id}
                key={item.id}
              />
            ))}
          </SortableList>
        ) : undefined}
    </Box>
  );
};