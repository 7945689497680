import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { getMessaging as getSwMessaging, isSupported as swIsSupported } from "firebase/messaging/sw";
import uuid from "uuid";
import { remove } from "lodash";

import { MessagingHandler } from "./types";

const firebaseApp = initializeApp({
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: "together-4526b.firebaseapp.com",
  projectId: "together-4526b",
  storageBucket: "together-4526b.appspot.com",
  messagingSenderId: "709789936388",
  appId: "1:709789936388:web:fb72becec177ce79ddbe51",
  measurementId: "G-C8GX17ZRXC",
});

swIsSupported().then((result) => {
  if (result) {
    getSwMessaging(firebaseApp);
  }
});

const vapidKey = 'BHhDv0f-6sV573VMrNjLCsLULr_QtFudaOJbSGbXzDZzatKtvGAB5pM3huzJFtBEx5TzwHitxKC01t4ne-QW2jQ';

export const getMessagingToken = async (): Promise<string | false> => {
  const supported = await isSupported();

  if (!supported) {
    return false;
  }

  const messaging = getMessaging();
  const token = await getToken(messaging, { vapidKey });

  return !token ? false : token;
};

const handlers: [id: string, handler: MessagingHandler][] = [];

export const subscribeMessagingHandler = (handler: MessagingHandler): { unsubscribe: () => void } => {
  const id = uuid.v4();
  const unsubscribe = () => remove(handlers, ([handlerId]) => handlerId === id);

  handlers.push([id, handler]);

  return { unsubscribe };
};

isSupported().then((result) => {
  if (result) {
    onMessage(getMessaging(), (payload) => {
      for (const [, handler] of handlers) {
        handler(payload);
      }
    });
  }
});

