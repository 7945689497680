import { InputLabel as MuiInputLabel, InputLabelProps as MuiInputLabelProps, Typography } from "@mui/material";
import React from "react";

export const InputLabel: React.FC<MuiInputLabelProps> = (props) => {
  const { required, children, ...rest } = props;

  return (
    <MuiInputLabel {...rest}>
      {required ? <Typography color={({ palette }) => palette.error.main} display="inline">*</Typography> : undefined }{children}
    </MuiInputLabel>
  );
};
