import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../Store/Redux/store";
// @ts-ignore
import DoubleScrollbar from 'react-double-scrollbar';
import styles from "./styles.module.scss";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import {Trans, t} from "@lingui/macro";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Box from "../../../../Components/Box";
import Text from "../../../../Components/Text";

import GroupBodyItem from "./GroupBodyItem";
import CustomPagination from "../../../../Components/CustomPagination";
import { getGroups, initFiltersData, setGroupsData } from "../../../Store/groups";
import { IGroup } from "../../../Store/groups/models";
import { Skeleton } from "@material-ui/lab";

export default function GroupsTable() {
  const dispatch = useDispatch();
  const { groups } = useTypedSelector(state => state);
  const { groupsList, loading, filters, limit, totalPages } = groups;
  const { search } = filters;
  const [updatePaginationCount, setUpdatePaginationCount] = useState(0);

  function getGroupsData(pageNumber?: number) {
    const page = pageNumber ? pageNumber : 1;

    return dispatch(getGroups({
      page,
      limit,
      search,
    }));
  }

  const onChangePage = (page: number) => {
    getGroupsData(page);
  };

  useEffect(() => {
    dispatch(setGroupsData({ // reset all filters on page load
      filters: initFiltersData,
    }));
  }, []);

  // update data on filters
  useEffect(() => {
    setUpdatePaginationCount(prev => prev + 1); // show 1 page on each filter update
    getGroupsData();
  }, [search]);

  return (
    <Box className={styles.groups} dataTestId="locations" marginTop={20}>
      <TableContainer>
        <DoubleScrollbar>
          <Table
            aria-label={t`groups table list`}
            aria-labelledby={t`groups table list`}
            className={styles.groupsTable}
          >
            <TableHead classes={{ root: styles.tableHead }}>
              <TableRow classes={{ root: styles.tableRow }}>
                <TableCell classes={{root: styles.tableCell}} padding="none">
                  <Trans>Name</Trans>
                </TableCell>
                <TableCell classes={{root: styles.tableCell}} padding="none">
                  <Trans>Location name</Trans>
                </TableCell>
                <TableCell classes={{root: styles.tableCell}} padding="none">
                  <Trans>Members</Trans>
                </TableCell>
                <TableCell classes={{root: styles.tableCell}} padding="none">
                  <Trans>Added date</Trans>
                </TableCell>
                <TableCell classes={{root: styles.tableCell}} padding="none">
                  {/* options menu cell */}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody classes={{ root: styles.tableBody }}>
              {groupsList.map((group: IGroup) => {
                return <GroupBodyItem group={group} key={group.id} />;
              })}
            </TableBody>
          </Table>
        </DoubleScrollbar>
      </TableContainer>

      {loading ? (
        <Box height={200}>
          <Skeleton height="100%" variant="rect" />
        </Box>
      ) : (
        !groupsList.length && (
          <Box marginTop={100}>
            <Text align="center" color="lightGray" size="md">
              <Trans>No Groups found</Trans>
            </Text>
          </Box>
        )
      )}

      {totalPages > 1 &&
        <Box padding="100px 0 50px">
          <CustomPagination
            count={totalPages}
            key={updatePaginationCount}
            onChange={onChangePage}
          />
        </Box>
      }
    </Box>
  );
}
