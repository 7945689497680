interface Props {
  color?: string;
  size?: number;
}

export default function Section({ color = '#4A4A4A', size = 25 }: React.PropsWithChildren<Props>) {
  return (
    <svg
      height={size}
      version="1.1"
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        fill="none"
        fillRule="evenodd"
        id="A-locations"
        stroke="none"
        strokeWidth="1"
      >
        <g
          id="A-Location-Floor-adddesk"
          transform="translate(-230.000000, -119.000000)"
        >
          <rect
            fill="#FFFFFF"
            height="943"
            width="1440"
            x="0"
            y="0"
          ></rect>
          <g
            fill={color}
            fillRule="nonzero"
            id="Group-5"
            transform="translate(230.000000, 114.000000)"
          >
            <g
              id="icons/section"
              transform="translate(0.000000, 5.000000)"
            >
              <g
                id="Combined-Shape"
                transform="translate(0.000000, 0.000000)"
              >
                <path
                  d="M12,1 C13.6568542,1 15,2.34314575 15,4 L15,4 L15,12 C15,13.6568542 13.6568542,15 12,15 L12,15 L4,15 C2.34314575,15 1,13.6568542 1,12 L1,12 L1,4 C1,2.34314575 2.34314575,1 4,1 L4,1 Z M12,3 L4,3 C3.44771525,3 3,3.44771525 3,4 L3,4 L3,12 C3,12.5522847 3.44771525,13 4,13 L4,13 L12,13 C12.5522847,13 13,12.5522847 13,12 L13,12 L13,4 C13,3.44771525 12.5522847,3 12,3 L12,3 Z M11,8 C11.5522847,8 12,8.44771525 12,9 L12,11 C12,11.5522847 11.5522847,12 11,12 L9,12 C8.44771525,12 8,11.5522847 8,11 L8,9 C8,8.44771525 8.44771525,8 9,8 L11,8 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
