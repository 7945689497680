import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import useSnackbar from "../../../../../Components/Snackbar/useSnackbar";
import {useEffect, useState} from "react";
import Dropzone, {IFileWithMeta, StatusValue} from "react-dropzone-uploader";
import {useTypedSelector} from "../../../../../Store/Redux/store";
import {setFloorMapApiRequestsData} from "../../../../Store/floorMapApiRequestsDuck";
import {setFloorMapData} from "../../../../Store/floorMapDuck";
import Compressor from "compressorjs";
import Box from "../../../../../Components/Box";
import Button from "../../../../../Components/Button";
import {Trans, t} from "@lingui/macro";
import Dialog from "@material-ui/core/Dialog";
import styles from "./styles.module.scss";
import Heading from "../../../../../Components/Heading";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import Text from "../../../../../Components/Text";
import {InputComponent, LayoutComponent, PreviewComponent} from "../../../../../Components/DropZone";
// @ts-ignore
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import {useHistory} from "react-router";

export const MAX_IMAGE_HEIGHT_WIDTH = 1500;

/**
 * Add floor button with modal to upload file.
 */
export default function UploadFloorMap() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const [openSnackbar] = useSnackbar();
  const [fileWithMeta, setFileWithMeta] = useState<IFileWithMeta | null>(null);
  const [open, setOpen] = useState(false);
  const { adminFloorMap, adminFloorMapApiRequests, config } = useTypedSelector(state => state);

  const { loading, mapFileUploaded } = adminFloorMapApiRequests;

  useEffect(() => {
    dispatch(setFloorMapApiRequestsData({ mapFileUploaded: false }));
  }, []);

  useEffect(() => {
    if (mapFileUploaded && fileWithMeta) {
      dispatch(setFloorMapData({
        mapFileWithMeta: fileWithMeta,
      }));

      setOpen(false);
    }
  }, [mapFileUploaded]);

  useEffect(() => {
    if (adminFloorMapApiRequests.error) {
      const handleClose = () => {
        dispatch(setFloorMapApiRequestsData({ error: '' }));
      };

      openSnackbar({
        onClose: handleClose,
        text: adminFloorMapApiRequests.error,
        type: 'error',
      });
    }
  }, [adminFloorMapApiRequests.error]);

  // Correctly resolves the input file upload
  const getFilesFromEvent = (e: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>): File[] | Promise<File[]> => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then((chosenFiles: any) => {
        resolve(chosenFiles.map((f: any) => f.fileObject));
      });
    });
  };

  // Clears previous state, add file and name to redux, and closes modal
  const handleAddFloor = () => {
    if (fileWithMeta) {
      dispatch(setFloorMapData({
        mapSizeToEdit: {
          height: fileWithMeta.meta.height ?? 1000,
          width: fileWithMeta.meta.width ?? 1000,
        },
        fileWithMetaToEdit: fileWithMeta,
      }));
      dispatch(setFloorMapApiRequestsData({ mapFileUpdated: false }));

      return history.push(`/admin/location/${locationId}/add-floor/${adminFloorMap.id}/floor-map-edit`);
    }
  };

  // Listen for status changes on file upload and removes/updates the file properly
  const handleChangeStatus = (fileWithMetaResponse: IFileWithMeta, status: StatusValue) => {
    if (status === 'done') {
      fileWithMeta?.remove();

      new Compressor(fileWithMetaResponse.file, {
        maxHeight: MAX_IMAGE_HEIGHT_WIDTH,
        maxWidth: MAX_IMAGE_HEIGHT_WIDTH,
        quality: 1,
        success: async (result: File) => {
          const reader = new FileReader();

          // Get an image from the file to take meta data
          reader.onload = function (e: any) {
            const imageObject = new Image();
            const previewUrl = window.URL.createObjectURL(result);

            imageObject.src = e.target.result;

            // After the image is loaded, sets metadata
            imageObject.onload = () => {
              fileWithMetaResponse.file = result;
              fileWithMetaResponse.meta.previewUrl = previewUrl;
              fileWithMetaResponse.meta.height = imageObject.height;
              fileWithMetaResponse.meta.width = imageObject.width;
              fileWithMetaResponse.meta.size = result.size;

              setFileWithMeta(fileWithMetaResponse);
            };
          };

          reader.readAsDataURL(result);
        },
        error() {
          setFileWithMeta(fileWithMetaResponse);
        },
      });
    }
  };

  // Remove file from state and from input
  const handleRemoveFile = () => {
    if (fileWithMeta) {
      fileWithMeta.remove();
      setFileWithMeta(null);
    }
  };

  // Toggle modal open state
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={styles.uploadFile} data-testid="floor-map-details-uploadMap" onClick={handleToggle}>
        <svg height="24px" viewBox="0 0 24 24" width="24px">
          <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
            <g fill={config.theme.primary} id="Dashboard" transform="translate(-896.000000, -35.000000)">
              <g id="icons/upload" transform="translate(896.000000, 35.000000)">
                <path d="M19,16 C19.5522847,16 20,16.4477153 20,17 L20,20 C20,20.5128358 19.6139598,20.9355072 19.1166211,20.9932723 L19,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,17 C4,16.4477153 4.44771525,16 5,16 C5.55228475,16 6,16.4477153 6,17 L6,19 L18,19 L18,17 C18,16.4477153 18.4477153,16 19,16 Z M12,16.8284271 C12.5522847,16.8284271 13,16.3807119 13,15.8284271 L13,6.41442712 L14.1213203,7.53553391 C14.5118446,7.9260582 15.1450096,7.9260582 15.5355339,7.53553391 C15.9260582,7.14500961 15.9260582,6.51184464 15.5355339,6.12132034 L12.7071068,3.29289322 C12.3165825,2.90236893 11.6834175,2.90236893 11.2928932,3.29289322 L8.46446609,6.12132034 C8.0739418,6.51184464 8.0739418,7.14500961 8.46446609,7.53553391 C8.85499039,7.9260582 9.48815536,7.9260582 9.87867966,7.53553391 L11,6.41442712 L11,15.8284271 C11,16.3807119 11.4477153,16.8284271 12,16.8284271 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </svg>
        <Trans>
          Upload Map
        </Trans>
      </div>

      <Dialog
        aria-labelledby="add floor dialog"
        className={styles.dialogContainer}
        classes={{ paper: styles.dialog }}
        fullWidth
        onClose={handleToggle}
        open={open}
      >
        <Box>
          <Box alignItems="center" display="flex" justifyContent="between">
            <Heading size="sm">
              <Trans>
                Upload Map
              </Trans>
            </Heading>

            <IconButton
              onClick={handleToggle}
              size="small"
              style={{
                backgroundColor: config.theme.primaryLight,
                borderRadius: 8,
                height: 30,
                width: 30,
              }}
            >
              <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
            </IconButton>
          </Box>

          <Box marginTop={12}>
            <Text color="gray" size="md">All existing desks, section will be saved. After you upload new map you can
              adjust it to existing desks and sections.</Text>

            <Box marginTop={20}>
              <Box marginBottom={9} paddingLeft={8} position="relative">
                <InputLabel className={styles.inputLabel} htmlFor="add-image">
                  <Box left={0} position="absolute" top={-1}>
                    <Text color="orange" inline weight="semi-bold">* </Text>
                  </Box>

                  <Text size="md" weight="semi-bold">
                    <Trans>
                      Select floor map (image file)
                    </Trans>
                  </Text>
                </InputLabel>
              </Box>

              <Dropzone
                InputComponent={InputComponent}
                LayoutComponent={LayoutComponent}
                PreviewComponent={(props) => <PreviewComponent {...props} handleRemoveFile={handleRemoveFile} />}
                accept="image/*"
                classNames={{
                  dropzone: '',
                }}
                getFilesFromEvent={getFilesFromEvent}
                multiple={false}
                onChangeStatus={handleChangeStatus}
              />
            </Box>
          </Box>

          <Box
            alignItems="center"
            display="flex"
            justifyContent="end"
            marginTop={15}
          >
            <Button
              aria-label={t`Cancel Add Floor`}
              name={t`Cancel Add Floor`}
              onClick={handleToggle}
              size="sm"
              type="clear"
              withShadow={false}
            >
              <Trans>
                Cancel
              </Trans>
            </Button>

            <Button
              aria-label={t`Add Floor`}
              buttonType="submit"
              disabled={!fileWithMeta || loading}
              name={t`Add Floor`}
              onClick={handleAddFloor}
              size="sm"
            >
              {loading ? t`Uploading file...` : t`Next`}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
