interface Props {
  color?: string;
  size?: number;
}

export default function ActiveDirectory({ color = '#5E5E5E', size = 12 }: React.PropsWithChildren<Props>) {
  return (
    <svg height={size} version="1.1" viewBox="0 0 12 12" width={size}>
      <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
        <g id="delete-white-copy-5">
          <rect height="10" id="Rectangle" rx="1" stroke={color} strokeWidth="2" width="9" x="2" y="1"></rect>
          <circle cx="6.5" cy="4.5" id="Oval" r="1.5" stroke={color}></circle>
          <path d="M6.5,6 C7.88071187,6 9,7.11928813 9,8.5 C9,8.67138643 8.98275399,8.83874484 8.94990271,9.00043449 L4.05009729,9.00043449 C4.01724601,8.83874484 4,8.67138643 4,8.5 C4,7.11928813 5.11928813,6 6.5,6 Z" id="Combined-Shape" stroke="#5E5E5E"></path>
          <rect fill={color} height="2" id="Rectangle" rx="1" width="2" x="0" y="2"></rect>
          <rect fill={color} height="2" id="Rectangle" rx="1" width="2" x="0" y="5"></rect>
          <rect fill={color} height="2" id="Rectangle" rx="1" width="2" x="0" y="8"></rect>
        </g>
      </g>
    </svg>
  );
}
