import React, { useEffect, useRef, useState } from "react";
import { BookingSearchItem, LocationBookingsSearchProps } from "./types";
import { Box, BoxProps, Divider, InputAdornment, Popover, Skeleton, TextField, Typography, useTheme } from "@mui/material";
import { TogetherDesk, TogetherIconHolder, TogetherUser } from "../icons";
import { t } from "@lingui/macro";
import { FloorSelect } from "../select";
import { DaySelector } from "../day-selector";
import { Booking, useLazyGetLocationBookingsQuery, useLazyGetLocationFloorBookingsQuery } from "store";
import { format, parseISO } from "date-fns";
import { Search } from "@mui/icons-material";
import { useDebouncedCallback, useLazyLoadScrollHandler, useStorageFileObjectURL } from "hooks";
import { useSelector } from "react-redux";
import { ReduxStore } from "Store/Redux";

const Item: React.FC<{ booking: Booking; handleItemClick: (booking: BookingSearchItem) => void }> = (props) => {
  const { booking, handleItemClick } = props;
  const { palette } = useTheme();
  const { id, user, desk, floor, timeFrom, timeTo } = booking;
  const objectURL = useStorageFileObjectURL(user.profilePictureUrl);

  return (
    <Box
      borderRadius={2}
      display="flex"
      key={id}
      onClick={() => handleItemClick({
        id, 
        userName: user.name,
        userProfilePictureUrl: objectURL || "",
        deskId: desk.id,
        deskName: desk.name,
        startTime: timeFrom,
        endTime: timeTo,
        floorId: floor.id,
        floorName: floor.floorName,
      })}
      paddingBottom="5px"
      paddingLeft="10px"
      paddingRight="10px"
      paddingTop="5px"
      sx={{
        ":hover": {
          bgcolor: palette.grey[100],
        },
      }}
    >
      <Box
        alignItems="center"
        bgcolor={palette.grey[100]}
        borderRadius={2}
        display="flex"
        height={70}
        justifyContent="center"
        marginRight="10px"
        width={70}
      >
        {objectURL ? (
          <img height="100%" src={objectURL} style={{ borderRadius: 8 }} width="100%" />
        ) : (
          <TogetherUser fill={palette.grey[700]} stroke={palette.grey[700]} sx={{ width: 32 }} />
        )}
      </Box>
      <Box alignItems="flex-start" display="flex" flexDirection="column" height={70} justifyContent="space-between">
        <Box>
          <Typography fontSize={14}>{user.name}</Typography>
          <Box display="flex">
            <Typography color={palette.grey[700]} fontSize={14} maxWidth={120} noWrap title={floor.floorName}>{floor.floorName}</Typography>
            <Typography color={palette.grey[700]} fontSize={14}>{`, ${timeFrom} - ${timeTo}`}</Typography>
          </Box>
        </Box>
        <Box alignItems="center" bgcolor={palette.grey[100]} borderRadius={2} display="flex" paddingX={1} paddingY={0.2}>
          <TogetherDesk stroke={palette.grey[700]} sx={{ width: 16, marginRight: 0.5 }} />
          <Typography color={palette.grey[700]} fontSize={14} maxWidth={220} noWrap title={desk.name}>{desk.name}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const LocationBookingsSearch: React.FC<LocationBookingsSearchProps> = (props) => {
  const { locationId, floorId, trigger, onItemClick, onFloorChange } = props;
  const { palette } = useTheme();
  const viewRef = useRef<HTMLDivElement>(null);
  const dateFrom = useSelector((state: ReduxStore) => state.createNewBooking?.dateFrom as Date | string);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const [day, setDay] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>();
  const [bookingsToList, setBookingsToList] = useState<Booking[]>([]);
  const [listAllFloors, setListAllFloors] = useState(false);
  const debouncedSetSearch = useDebouncedCallback(setSearch, [setSearch], 700);
  const [triggerGetLocationFloorBookingsQuery, getLocationFloorBookingsQuery] = useLazyGetLocationFloorBookingsQuery();
  const [triggerGetLocationBookingsQuery, getLocationBookingsQuery] = useLazyGetLocationBookingsQuery();
  const { data, isLoading: bookingsAreLoading, isFetching: bookingsAreFetching } = listAllFloors 
    ? getLocationBookingsQuery 
    : getLocationFloorBookingsQuery;
  const bookings = data?.result?.data?.items;
  const hasMore = data?.result?.data?.links?.next;
  const formattedDay = format(day, "yyyy-MM-dd");

  useEffect(() => {
    if (!listAllFloors) {
      setBookingsToList([]);
      setPage(1); 
    }
  }, [floorId]);

  useEffect(() => {
    setBookingsToList([]);
    setPage(1);
  }, [search, formattedDay]);

  useEffect(() => {
    if (bookings?.length) {
      const newBookings = bookings.filter(({ id, floor }) => {
        return !bookingsToList.some((booking) => booking.id === id) && (listAllFloors || floor.id === floorId);
      });

      setBookingsToList([...bookingsToList, ...newBookings]);
    }
  }, [bookings]);

  useEffect(() => {
    if (isOpen) {
      const params = {
        locationId,
        search,
        page: 1,
        orderBy: "asc:user.name",
        include: ["user", "desk", "desk.floor"],
        filter: { dateFrom: formattedDay },
      };

      if (listAllFloors) {
        triggerGetLocationBookingsQuery(params);
      } else {
        triggerGetLocationFloorBookingsQuery({ ...params, floorId });
      }
    }
  }, [isOpen, floorId, listAllFloors, search, formattedDay]);

  useEffect(() => {
    if (page > 1) {
      const params = {
        page,
        locationId,
        search,
        orderBy: "asc:user.name",
        include: ["user", "desk", "desk.floor"],
        filter: { dateFrom: formattedDay },
      };

      if (listAllFloors) {
        triggerGetLocationBookingsQuery(params);
      } else {
        triggerGetLocationFloorBookingsQuery({ ...params, floorId });
      }
    }
  }, [page]);

  useEffect(() => {
    if (dateFrom) {
      if (typeof dateFrom === "string") {
        setDay(parseISO(dateFrom));
      } else {
        setDay(dateFrom);
      }
    }
  }, [dateFrom]);

  const handleScroll = useLazyLoadScrollHandler(viewRef, () => {
    if (hasMore) {
      setPage(page + 1);
    }
  }, 200);
  
  const handleOpen: BoxProps["onClick"] = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(undefined);
  };

  const handleFloorChange = (floorId: string): void => {
    setPage(1);
    setBookingsToList([]);

    if (floorId === "all") {
      setListAllFloors(true);
    } else {
      setListAllFloors(false);
      onFloorChange?.(floorId);
    }
  };

  const handleItemClick = (booking: BookingSearchItem) => {
    if (booking.floorId !== floorId) {
      onFloorChange?.(booking.floorId);
    }

    onItemClick?.(booking);
  };

  return (
    <>
      <Box onClick={handleOpen}>{trigger}</Box>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleClose}
        open={isOpen}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box padding={2} width={440}>
          <Box alignItems="center" display="flex">
            <TogetherIconHolder>
              <TogetherUser fill={palette.grey[700]} stroke={palette.grey[700]} sx={{ width: 16 }} />
            </TogetherIconHolder>
            <Typography fontSize={16} fontWeight="600">{t`Who's In`}</Typography>
          </Box>
          <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
          <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={1}>
            <FloorSelect
              getOptionDisabled={({ extra }) => !extra?.availableDesks}
              include={["extra.availableDesks"]}
              locationId={locationId}
              maxWidth={220}
              onChange={handleFloorChange}
              showAllOption
              value={listAllFloors ? "all" : floorId}
            />
            <DaySelector day={day} fmt="LLL, d" onChange={(date) => setDay(date)} />
          </Box>
          <TextField
            InputProps={{
              startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
            }}
            onChange={(event) => debouncedSetSearch(event.currentTarget.value)}
            placeholder={t`Search by name, email`}
            size="small"
            sx={{ marginBottom: 1 }}
            variant="filled"
          />
          <Box>
            {bookingsToList?.length === 0 && (bookingsAreFetching || bookingsAreLoading || !isOpen) ? (
              <Box display="flex" paddingBottom="5px" paddingLeft="10px" paddingRight="10px" paddingTop="5px">
                <Skeleton height={70} sx={{ marginRight: "10px" }} variant="rectangular" width={70} />
                <Box alignItems="flex-start" display="flex" flexDirection="column" height={70} justifyContent="space-between">
                  <Box>
                    <Skeleton height={20} sx={{ marginBottom: 0.5 }} variant="rectangular" width={120} />
                    <Skeleton height={20} variant="rectangular" width={180} />
                  </Box>
                  <Skeleton height={20} variant="rectangular" width={60} />
                </Box>
              </Box>
            ) : (
              bookingsToList?.length ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  maxHeight={400}
                  onScroll={handleScroll}
                  ref={viewRef}
                  sx={{ overflowX: "hidden", overflowY: "auto" }}
                >
                  {bookingsToList.map((booking) => <Item booking={booking} handleItemClick={handleItemClick} key={booking.id} />)}
                  {
                    hasMore ? (
                      <Box display="flex" paddingBottom="5px" paddingLeft="10px" paddingRight="10px" paddingTop="5px">
                        <Skeleton height={70} sx={{ marginRight: "10px" }} variant="rectangular" width={70} />
                        <Box alignItems="flex-start" display="flex" flexDirection="column" height={70} justifyContent="space-between">
                          <Box>
                            <Skeleton height={20} sx={{ marginBottom: 0.5 }} variant="rectangular" width={120} />
                            <Skeleton height={20} variant="rectangular" width={180} />
                          </Box>
                          <Skeleton height={20} variant="rectangular" width={60} />
                        </Box>
                      </Box>
                    ) : undefined
                  }
                </Box>
              ) : (
                <Box alignItems="center" display="flex" height={80} justifyContent="center">
                  <Typography color={palette.grey[700]} textAlign="center" width={300}>
                    {search ? t`No results` : t`There are no reservations at this floor on selected date`}
                  </Typography>
                </Box>
              )
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
};
