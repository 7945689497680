interface Props {
  color?: string;
  size?: number;
}

export default function NoneCircle({
  color = '#ABABAB',
  size = 16,
}: Props) {
  return (
    <svg height={size} version="1.1" viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g fill="none" fillRule="evenodd" id="A-users" stroke="none" strokeWidth="1">
        <g fill={color} fillRule="nonzero" id="p-users-filters" transform="translate(-77.000000, -485.000000)">
          <g id="Group-3" transform="translate(30.000000, 25.000000)">
            <g id="icons/plus" transform="translate(47.000000, 460.000000)">
              <path d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z M11,7 C11.5522847,7 12,7.44771525 12,8 C12,8.55228475 11.5522847,9 11,9 L5,9 C4.44771525,9 4,8.55228475 4,8 C4,7.44771525 4.44771525,7 5,7 L11,7 Z" id="Combined-Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
