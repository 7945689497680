import Box from "../../../../Components/Box";
import {Trans} from "@lingui/macro";
import GroupName from "./GroupName";
import LocationsGroupDetailsModal from "./LocationGroupDetailsModal";
import {IGroup} from "../../../Store/groups/models";
import GroupUpdateControls from "./GroupUpdateControls";

interface Props {
  group: IGroup;
}

export default function GroupInfo(props: Props) {
  const { group } = props;

  return (
    <div>
      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={10}>
        <h3>
          <Trans>
            Details
          </Trans>
        </h3>
      </Box>

      <GroupName group={group} />
      <LocationsGroupDetailsModal group={group} />
      <Box display="flex" justifyContent="end" marginBottom={10}>
        <GroupUpdateControls group={group} />
      </Box>
    </div>
  );
}
