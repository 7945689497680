import { SnackbarContext, SnackbarProps } from './SnackbarProvider';
import { useContext } from 'react';

type Return = [(props: SnackbarProps) => any, () => any];

export default function useSnackbar(): Return {
  const { openSnackbar, closeSnackbar } = useContext(SnackbarContext);

  const open = (props: SnackbarProps) => {
    openSnackbar(props);
  };

  return [open, closeSnackbar];
}