import moment, { Moment } from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import { Trans } from "@lingui/macro";
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';
import { useDispatch } from 'react-redux';
import { getFloorRoomSchedule, selectSchedulesByRoomId } from "App/Store/Meetings/meetingSchedule";
import { endOfMoment, startOfMoment } from '../../../../../Functions/Helpers';
import Text from 'Components/Text';
import styles from './styles.module.scss';
import Box from 'Components/Box';
import hoursMinutesToNumber from 'Functions/hoursMinutesToNumber.ts';
import { Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CalendarType, FINAL_CALENDAR_HOUR, INITIAL_CALENDAR_HOUR, INITIAL_VERTICAL_SCROLL_POSITION, TIME_STEP_CALENDAR, blockClass, getAllNumbersBetween, handleCalendarClick, tooltipText } from '../../Helpers';
import DateSelector from '../../../Calendar/DateSelector';
import Hour from './Hour';
import Day from './Day';
import { eachMinuteOfInterval } from 'date-fns';
import { startOfDay } from 'date-fns';
import { isSameDay } from 'date-fns';

interface CalendarVerticalViewProps {
  roomId: string;
}

interface Item {
  day?: Moment | undefined;
  hours: Array<number>;
}

export default function CalendarVerticalView({ roomId }: CalendarVerticalViewProps) {
  const dispatch = useDispatch();
  const {    
    createNewMeeting,        
    roomSchedule,    
  } = useTypedSelector(state => state);

  const {    
    dateFrom,    
    timeFrom,
    timeTo,  
    meetingFrom,
    meetingTo,
    selectedData,    
  } = createNewMeeting;  

  const { location, floor  } = selectedData;
  const { loading } = roomSchedule;
  const scheduleArray = selectSchedulesByRoomId(roomSchedule, roomId);  
  const [hours, setHours] = useState<Array<number>>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [availableDays, setAvailableDays] = useState<Array<Moment>>();  
  const [initialScrollState, setInitialScrollState] = useState(false);
  const listRef = useRef<HTMLHeadingElement>(null);
  const itemSelected = useRef<Item>();  
  const clicksCount = useRef(0);  

  const handleClick = (day: Moment, hour: number) => {
    const calendarClickResponse = handleCalendarClick(CalendarType.VERTICAL, clicksCount, itemSelected, scheduleArray, hour, hours, dateFrom, { day });

    if (!calendarClickResponse) return;

    const { newMeetingFrom, newMeetingTo, clicksCount: newClicksCount, itemSelected: newItemSelected } = calendarClickResponse;

    if (newClicksCount && newClicksCount.current)
      clicksCount.current = newClicksCount.current;

    if (clicksCount.current === 2) resetItems();

    if (newItemSelected && newItemSelected.current)
      itemSelected.current = newItemSelected.current;

    dispatch(setCreateNewMeetingData({
      meetingFrom: newMeetingFrom,
      meetingTo: newMeetingTo,
      selectedData: {
        ...selectedData,
        roomId,        
      },
    }));
  };

  const getBlockClass = (day: Moment, hour: number) => {    
    return blockClass(CalendarType.VERTICAL, scheduleArray, itemSelected, hour, styles, dateFrom, { day });
  };   

  const getDateRangeText = () => {    
    if (!availableDays) return moment().format("D MMM, YYYY");

    const firstAvailableDay = availableDays[0];
    const lastAvailableDay = availableDays[availableDays?.length - 1];

    return `${firstAvailableDay.format("D")}-${lastAvailableDay.format("D MMM, YYYY")}`;    
  };

  const handleChangeSelectedDate = (nextDays: boolean) => {
    if (nextDays) return setSelectedDate(current => moment(current).add(5, 'day').toDate());

    const lessFiveDays = moment(selectedDate).subtract(5, 'day');

    setSelectedDate(lessFiveDays.toDate());
  };

  const getTooltipText = (day: Moment, hour: number) => {
    return tooltipText(CalendarType.VERTICAL, scheduleArray, hour, dateFrom, { day });    
  };

  const checkItemPreviousSelected = () => {
    if (!meetingFrom || !meetingTo) return;

    const hoursFrom = hoursMinutesToNumber(meetingFrom);
    const hoursTo = hoursMinutesToNumber(meetingTo) - TIME_STEP_CALENDAR;
    const hoursBetween = getAllNumbersBetween(CalendarType.VERTICAL, hoursFrom, hoursTo);    
    const day = moment(meetingFrom);
    itemSelected.current = {
      day,
      hours: hoursBetween,
    };
  };

  const resetItems = () => {
    if (clicksCount.current === 0 && itemSelected) return;

    clicksCount.current = 0;
    itemSelected.current = undefined;    
  };

  //Put the scroll on initial state, actually it is 7 AMß
  const handleScroll = () => {
    if (!listRef || !listRef.current || initialScrollState) return;        

    listRef.current.scrollTo({ top: INITIAL_VERTICAL_SCROLL_POSITION });
    setInitialScrollState(true);    
  };

  useEffect(() => {
    if (!location || !floor || !availableDays || availableDays.length == 0) return;  

    const locationId = location.id;
    const floorId = floor.id;
    const startDate = startOfMoment(availableDays[0]).toISOString();
    const endDate = endOfMoment(availableDays[availableDays?.length - 1]).toISOString();
    const data = {
      startDate,
      endDate,
    };    

    dispatch(getFloorRoomSchedule({ data: { ...data }, locationId, floorId }));
  }, [availableDays]);

  useEffect(() => {    
    if (!listRef || !listRef.current || !availableDays || availableDays.length === 0) return;    

    listRef.current.scrollTop += 10;
    handleScroll();    
  }, [listRef, availableDays]);

  useEffect(() => {
    const availableInitialDays = [moment(selectedDate)];

    // Calculate the next 5 days
    for (let i = 1; i < 5; i++) {  
      const nextDate = moment(selectedDate).add(i, 'day');
      availableInitialDays.push(nextDate);
    }    

    setAvailableDays(availableInitialDays);
  }, [selectedDate]);  

  useEffect(() => {
    if (!timeFrom || !timeTo) return;

    const hourFrom = INITIAL_CALENDAR_HOUR;
    const hourTo = FINAL_CALENDAR_HOUR;
    const hours = getAllNumbersBetween(CalendarType.VERTICAL, hourFrom, hourTo);    
    checkItemPreviousSelected();
    setHours(hours);
  }, []);

  return (    
    <Box className={styles.calendar} direction='column' display='flex' width='100%'>              
      <Box display='flex' justifyContent='between' marginBottom={5} paddingTop={10}>
        <span className={styles.dateRange}>{getDateRangeText()}</span>
        <DateSelector handleChangeSelectedDate={handleChangeSelectedDate} /> 
      </Box>      
      <Box className={styles.header} gap={5} id='header'>
        <Box className={styles.timer} minWidth={40}>
          <Text className={styles.headerText}>
            <Trans>Time</Trans>
          </Text>
          <Text>
            <Trans>AM</Trans>
          </Text>
        </Box>
        {availableDays?.map((day, index) => (
          <Day day={day} itemSelected={itemSelected} key={index} />
        ))}
      </Box>      
      <div
        className={styles.list}        
        id='list'
        onScroll={handleScroll}
        ref={listRef}
        style={{ position: "relative" }}
      >
        {hours.map((hour, index) => loading ? (
          <Box borderRadius={14} height={30} key={`${new Date().getTime()+index}-${hour}`}>
            <Skeleton height="100%" variant="rect" />
          </Box>
        ) : (
          <Box className={styles.time} direction='row' display='flex' key={`${new Date().getTime()+index}-${hour}`} position="relative">            
            <Hour hour={hour} hours={hours} />            
            {availableDays?.map((day) => {
              const hourSlotClass = getBlockClass(day, hour);
              const hourSlot = <div className={hourSlotClass} onClick={() => handleClick(day, hour)} />;
              const isScheduled = [
                styles.blockScheduled,
                styles.blockFirstScheduled,
                styles.blockLastScheduled,
              ].includes(hourSlotClass);

              return isScheduled ? (
                hourSlot
              ) : (
                <Tooltip key={`${new Date().getTime()+hour}-${day}`} title={getTooltipText(day, hour)}>
                  {hourSlot}
                </Tooltip>
              );
            })}
          </Box>
        ))}
        {scheduleArray.map(({ id, startDate, endDate, summary }) => {
          const index = availableDays?.findIndex((day) => isSameDay(day.toDate(), new Date(startDate)));
          const left = index ? `calc(32px + ${index} * 20%)` : "32px";
          const top = (eachMinuteOfInterval({ start: startOfDay(new Date(startDate)), end: new Date(startDate) }, { step: 15 }).length - 1) * 30;
          const height = (eachMinuteOfInterval({ start: new Date(startDate), end: new Date(endDate) }, { step: 15 }).length - 1) * 30;

          return (
            <Box
              key={id}
              style={{
                height,
                left,
                position: "absolute",
                top: top,
                bottom: 0,
                width: "calc(20% - 8px)",
              }}
            >
              {summary ? (
                <Tooltip title={summary || ""}>
                  <span
                    style={{ display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      padding: 8,
                    }}
                  >
                    <Text align="center" style={{ width: "100%" }} truncate>
                      {`${summary?.split(" ")?.[0]?.split("")?.[0]?.toUpperCase()}${summary?.split(" ")?.[1]?.split("")?.[0]?.toUpperCase()}`}
                    </Text>
                  </span>
                </Tooltip>
              ) : (
                <span
                  style={{ display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    padding: 8,
                  }}
                />
              )}
            </Box>
          );
      })}
      </div>
    </Box>
  );
}