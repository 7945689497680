import {
  FloorMapObjectTypeEnum,
  IApprover,
  MapDrawingOwner,
} from "../models";
import { IFloorMapDrawing, IFloorMapDrawingUpdate } from "../mapDrawing/models";
import { RoomServiceType } from "App/Store/Meetings/meetingRooms/models";

export enum MeetingStatusEnum {
  Available = "AVAILABLE",
  Unavailable = "UNAVAILABLE",
  // ApprovalRequired = "APPROVAL_REQUIRED", // not implemented in BE yet
}

export type MeetingStatus =
  | MeetingStatusEnum.Available
  | MeetingStatusEnum.Unavailable
  // | MeetingStatusEnum.ApprovalRequired // not implemented in BE yet

export interface ResourceAvailableService {
  type: RoomServiceType;
  emails?: string[];
}

export interface IFloorMapMeeting extends IFloorMapDrawing {
  roomId: string; // unique email of meeting room
  owners: MapDrawingOwner[];
  // groups: MapDrawingGroup[]; // not implemented in BE yet
  approvers: {
    approver1: IApprover;
    approver2: IApprover;
  };
  resourceIds: { [resourceId: string]: string };
  readonly capacity: number;
  status: MeetingStatus;
  availableServices?: ResourceAvailableService[];
  type: FloorMapObjectTypeEnum.Meeting;
}

export interface IFloorMapMeetingUpdate extends IFloorMapDrawingUpdate {
  roomId?: string; // unique email of meeting room
  owners?: MapDrawingOwner[];
  // groups?: MapDrawingGroup[]; // not implemented in BE yet
  approvers?: {
    approver1: IApprover;
    approver2: IApprover;
  };
  resourceIds?: { [resourceId: string]: string };
  status?: MeetingStatus;
  availableServices?: ResourceAvailableService[];
}
