import { Box, BoxProps, useTheme } from "@mui/material";
import React from "react";

export const BasicChip: React.FC<BoxProps> = (props) => {
  const { children, ...rest } = props;
  const { palette } = useTheme();

  return (
    <Box alignItems="center" bgcolor={palette.grey[100]} borderRadius={2} display="flex" px={1} py={0.5} {...rest}>
      {children}
    </Box>
  );
};
