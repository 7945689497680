import {useTypedSelector} from "../../../Store/Redux/store";
import styles from "../styles.module.scss";
import {Trans, t} from "@lingui/macro";
import OverflowToolTip from "../../OverflowTooltip";
import { ProfileLinkProps, greetingType } from "../../../App/Store/Users/profileDuck/models";

function getProfileGreeting(greeting: greetingType, name: string) {
  switch (greeting) {
    case "admin":
      return (
        <>
          <div className={styles.profileWelcome}>
            <Trans>
              Welcome
            </Trans>
          </div>
          <div className={styles.profileName}>
            <OverflowToolTip text={t`Hello` + " " + name} />
          </div>
        </>
      );
    case "home":
      return (
        <>
          <div className={styles.profileName}>
            <OverflowToolTip text={t`Hello` + " " + name} />
          </div>
          <div className={styles.profileWelcome}>
            <Trans>
              Welcome Back
            </Trans>
          </div>
        </>
      );
    case "profile":
      return (
        <div className={styles.profileWelcome}>
          <OverflowToolTip text={name}/>
        </div>
      );
    default:
      return (
        <>
          <div className={styles.profileName}>
            <OverflowToolTip text={t`Hello` + " " + name} />
          </div>
          <div className={styles.profileWelcome}>
            <Trans>
              Welcome Back
            </Trans>
          </div>
        </>
      );
  }
}

export default function DefaultProfile(props: ProfileLinkProps) {
  const { profile } = useTypedSelector(state => state);
  const profileGreeting = getProfileGreeting(props.greeting, profile.name);
  
  return profileGreeting;
}
