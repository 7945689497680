import { IAttendees } from "../meetingAttendees/models";
import { IMeetingReservationFloor, IMeetingReservationLocation, MeetingReservationRequestedServicesType } from "../meetingReservations/models";

export enum RoomSelectEnum {
  LIST_VIEW = 'listview',
  CALENDAR_VIEW = 'calendarview'
}
export type RoomSelectType = RoomSelectEnum.LIST_VIEW | RoomSelectEnum.CALENDAR_VIEW;

export interface MeetingReservationSelectedData {
  roomId: string | null;
  location: IMeetingReservationLocation | null;
  floor: IMeetingReservationFloor | null;  
  meetingTitle: string;
  attendees: IAttendees[];
  externalAttendees?: string[];
  requestedServices: MeetingReservationRequestedServicesType[];
  isTeamsMeeting: boolean;
}

export interface CreateNewMeetingModel {  
  dateFrom: Date;
  dateTo: Date;
  meetingFrom?: Date;
  meetingTo?: Date;
  timeFrom?: Date;
  timeTo?: Date;  
  selectedData: MeetingReservationSelectedData;  
}

export interface SetCreateNewMeetingRequest {  
  error?: '',
  dateFrom?: Date;
  dateTo?: Date;
  meetingFrom?: Date;
  meetingTo?: Date;
  timeFrom?: Date;
  timeTo?: Date;
  selectedData?: MeetingReservationSelectedData;
  roomSelect?: RoomSelectType;
}