import {useTypedSelector} from "../../../../../../../../Store/Redux/store";
import styles from "./styles.module.scss";

import {FormControlLabel} from "@material-ui/core";
import Box from "../../../../../../../../Components/Box";
import CheckboxCustom from "../../../../../../../../Components/CheckBoxCustom";
import {FloorMapObject, MapDrawingOwner} from "../../../../../../../Store/floorMapDuck/models";
import {UserManagementUser} from "../../../../../../../Store/userManagement/models";
import NoUsersText from "./NoUsersText";
import OverflowToolTip from "../../../../../../../../Components/OverflowTooltip";

// return users in desk owner format
function getDeskOwnersFromUsers(users: UserManagementUser[]): MapDrawingOwner[] {
  return users.map(user => { return { id: user.id, name: user.name, email: user.email }; });
}

interface Props {
  drawingObject: FloorMapObject;
  onOwnersUpdate: (owners: MapDrawingOwner[]) => void;
  searchText: string;
}

export default function OwnersList(props: Props) {
  const { adminUserManagement } = useTypedSelector(state => state);
  const { drawingObject, onOwnersUpdate, searchText } = props;
  const { owners } = drawingObject;
  const { allUsers } = adminUserManagement;

  const ownersArray: MapDrawingOwner[] = owners?.length ? owners : [];
  const filteredUsers = allUsers.filter(user => !ownersArray.some(owner => owner.id === user.id)); // all users that not includes users from ownersArray
  const usersArray: MapDrawingOwner[] = getDeskOwnersFromUsers(filteredUsers);

  const onUpdateOwners = (checked: boolean, owner: MapDrawingOwner) => {
    if (checked) {
      const updatedOwners = [...ownersArray, owner];
      return onOwnersUpdate(updatedOwners);
    }

    const updatedOwners = ownersArray.filter(o =>  o.id !== owner.id);
    return onOwnersUpdate(updatedOwners);
  };

  return (
    <Box className={styles.filtersFormControlWrapper} display="grid" >
      {
        ownersArray.length ?
          ownersArray.map(((owner, key) => {
            return (
              <FormControlLabel
                className={styles.checkedLabel}
                control={
                  <CheckboxCustom
                    checked={true} // always checked array
                    classes={{ checked: styles.checked }} // always checked array
                    name={owner.email}
                    onChange={(event: any, checked: boolean) => onUpdateOwners(checked, owner)}
                  />
                }
                key={key}
                label={<OverflowToolTip placement={'left'} text={owner.email} />}
              />
            );
          })) : null
      }
      {
        usersArray.length ?
          usersArray.map(((owner, key) => {
            return (
              <FormControlLabel
                control={
                  <CheckboxCustom
                    checked={false}
                    classes={{ checked: styles.checked }}
                    name={owner.email}
                    onChange={(event: any, checked: boolean) => onUpdateOwners(checked, owner)}
                  />
                }
                key={key}
                label={<OverflowToolTip placement={'left'} text={owner.email} />}
              />
            );
          })) : <NoUsersText allUsers={allUsers} searchText={searchText} />
      }
    </Box>
  );
}
