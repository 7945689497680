import { useDispatch } from "react-redux";
import { updateDesk } from "../../../../Store/floorMapDuck";
import { FloorMapObject, MapDrawingOwner } from "../../../../Store/floorMapDuck/models";
import React, { useState } from "react";
import { Box, Popover, Tooltip, Typography, TypographyProps } from "@mui/material";
import { UsersSelect, UsersSelectProps } from "components";

interface Props {
  desk: FloorMapObject;
}

export const DeskOwners: React.FC<Props> = (props) => {
  const { desk } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement>();
  const owners = desk.owners?.length ? desk.owners.map(owner => owner.name).join(", ") : "-";
  const value = desk.owners;

  const handleChange: UsersSelectProps["onChange"] = (value) => {
    dispatch(updateDesk({
      deskId: typeof desk.id === "string" ? desk.id : "",
      updateDeskProperties: {
        owners: value as MapDrawingOwner[],
      },
    }));
  };

  const handleClick: TypographyProps["onClick"] = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  return (
    <>
      <Tooltip placement="top" title={owners !== "-" ? owners : undefined}>
        <Typography
          maxWidth={280}
          noWrap
          onClick={handleClick}
          sx={{ cursor: "pointer" }}
        >
          {owners}
        </Typography>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setAnchorEl(undefined)}
        open={!!anchorEl}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box padding={2} width={360}>
          <UsersSelect onChange={handleChange} value={value} />
        </Box>
      </Popover>
    </>
  );
};
