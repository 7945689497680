import { eachMinuteOfInterval, isBefore, isEqual } from "date-fns";
import { MinuteStep } from "types";
import { flooredCalendarViewDate } from "./floored-calendar-view-date";

export const resolveCalendarViewScheduleSpan = (start: Date, dateToCompare: Date, step: MinuteStep, firstStep: Date): number => {
  const flooredDateToCompare = flooredCalendarViewDate(dateToCompare, step);
  const flooredStart = isBefore(start, flooredDateToCompare) ? flooredDateToCompare : flooredCalendarViewDate(start, step);

  if (isEqual(flooredStart, firstStep) || isBefore(flooredStart, firstStep)) {
    return 0;
  }

  const minutes = eachMinuteOfInterval({ start: firstStep, end: flooredStart }, { step });

  return minutes.length - 1;
};
