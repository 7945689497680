import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherFloor: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M12 1a2 2 0 0 1 2 2v12H2V3a2 2 0 0 1 2-2Zm0 2H4v10h8zm-5 7a1 1 0 1 1 0 2H6a1 1 0 1 1 0-2Zm3 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2zM7 7a1 1 0 1 1 0 2H6a1 1 0 1 1 0-2Zm3 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM7 4a1 1 0 1 1 0 2H6a1 1 0 1 1 0-2Zm3 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z"
        display="inline"
        fill={props.fill}
        fillOpacity="1"
        stroke="none"
        strokeWidth="1"
        transform="matrix(1.14286 0 0 1.14286 -1.143 -1.143)"
      />
    </SvgIcon>
  );
};
