import { GetLocationResourcesResponse, Resource } from './models';

export const resources: Resource[] = [
  {
    id: 'd0ba1769-391c-4d6e-a368-c627fbb09465',
    locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
    name: 'Monitor Mock',
    isAvailable: true,
    createdAt: '2021-05-27T11:05:00.806Z',
  },
  {
    id: '5d46384c-167b-4946-afda-3efb91de74d8',
    locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
    name: 'Dual Monitors Mock',
    isAvailable: true,
    createdAt: '2021-05-27T11:05:27.124Z',
  },
  {
    id: '5acb35e4-4ba5-4601-8acf-c1cd36e3943a',
    locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
    name: 'Docking Station Mock',
    isAvailable: true,
    createdAt: '2021-05-27T11:06:15.858Z',
  },
  {
    id: 'f74c6c26-47f5-42d4-a97a-71f2226f537a',
    locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
    name: 'Dedicated PC Mock',
    isAvailable: false,
    createdAt: '2021-05-27T11:06:30.549Z',
  },
  {
    id: '29abd546-aa53-4ad5-b349-4c64844c805b',
    locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
    name: 'Multimedia Workstation Mock',
    isAvailable: true,
    createdAt: '2021-05-27T11:07:49.659Z',
  },
  {
    id: 'a8275c5f-0e7d-44a5-a1f7-67878b15c3f9',
    locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
    name: 'Stand Mock',
    isAvailable: false,
    createdAt: '2021-05-27T11:08:31.563Z',
  },
];

export const getLocationResourcesSuccess: GetLocationResourcesResponse = {
  result: {
    data: resources,
    statusCode: 200,
  },
  error: null,
};
