import { locationDisabledDaysType } from '../../Store/Bookings/availableDesksDuck/models';
import {
  compareDateFormat,
  compensateTimezoneOffset,
} from '../../../Admin/Pages/Calendar/AdminCalendar/Helpers/Helpers';
import { AllowedBookingDayType, Location } from '../../Store/Locations/locationsDuck/models';
import { UserDocument } from '../../../Admin/Store/userManagement/models';
import { Moment } from 'moment';
import moment from 'moment'; 

export function getClosestStartDate(allowedBookingDayType: AllowedBookingDayType) {
  return new Date(new Date().getTime() + 86400000 * allowedBookingDayType);
}
export function getClosestAvailableDay(date: Date | undefined, disabledDays: locationDisabledDaysType) {
  let closestDate = date ? new Date(date) : new Date();
  const disabledDaysToCompare = disabledDays.map(d => compareDateFormat(d));

  while (disabledDaysToCompare.includes(compareDateFormat(closestDate, true))) {
    closestDate = new Date(closestDate.getTime() + 86400000); // add 1 day
  }

  return closestDate;
}

export function isLocationRequiredCovidDocument(
  locations: Location[],
  selectedLocationId: string | undefined,
  actualCovidDocument: UserDocument | null,
  approvedAccommodation: boolean,
) {
  const selectedLocation = locations.find(location => location.id === selectedLocationId);
  const document = actualCovidDocument ? actualCovidDocument.status : null;

  const isRequiredForLocation = !!(selectedLocation && selectedLocation.uploadDocNotification);
  const isApprovedDocument = document === "approved";
  const isApprovedForUser = approvedAccommodation;

  return isRequiredForLocation && !isApprovedDocument && !isApprovedForUser;
}

export function getStartDisabledDaysArray(allowedBookingDayType: AllowedBookingDayType) {
  switch (allowedBookingDayType as AllowedBookingDayType) {
    case 1:
      return [compensateTimezoneOffset(compareDateFormat(new Date))];
    case 2: {
      const tomorrow = new Date(new Date().getTime() + 86400000);
      return [compensateTimezoneOffset(compareDateFormat(new Date)), compensateTimezoneOffset(compareDateFormat(tomorrow))];
    }
    default:
      return [];
  }
}

export function startOfMoment (date: Moment | Date) {
  return moment(date).startOf('day').toDate();
}

export function endOfMoment (date: Moment | Date) {
  return moment(date).endOf('day').toDate();
}