import AppointmentCalendar from './AppointmentCalendar';
import AppointmentsHeader from '../../Components/Header/AppointmentsHeader';
import AppointmentsList from './AppointmentsList';
import Box from 'Components/Box';
import CustomPagination from '../../../Components/CustomPagination';
import styles from './styles.module.scss';
import {getVisits, initFiltersData, setVisitsData, updateVisitsLoading} from 'Admin/Store/visits';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import {getAllowedLocationsByRoles, getLocationIdsByRoles} from "../Locations/Helpers";
import { useDebouncedCallback } from 'hooks';
import { filterStatusType } from 'Admin/Store/visits/models';
import { UserDocumentStatus } from 'Admin/Store/userManagement/models';
import { endOfDay, format, startOfDay } from 'date-fns';

export default function Appointments() {
  const dispatch = useDispatch();
  const { adminVisits, profile, locations } = useTypedSelector(state => state);
  const { roleAccess, locationIds: profileLocationIds } = profile;
  const {
    filters,
    visits,
    totalCount,
    limit,
  } = adminVisits;
  const { selectedStartDate, selectedEndDate, statuses, documentStatuses, checking, search } = filters;
  const totalPages = Math.ceil(totalCount / limit);
  const [updatePaginationCount, setUpdatePaginationCount] = useState(0);
  const allowedLocations = getAllowedLocationsByRoles(roleAccess, locations.adminLocations, profileLocationIds);
  const restrictedLocationIds = getLocationIdsByRoles(roleAccess, filters, allowedLocations);
  const startDateTime = format(startOfDay(new Date(selectedStartDate)), "yyyy-MM-dd'T'HH:mm:ss");
  const endDateTime = format(endOfDay(new Date(selectedEndDate)), "yyyy-MM-dd'T'HH:mm:ss");

  useEffect(() => {
    dispatch(setVisitsData({ // reset all filters on page load
      filters: initFiltersData,
    }));
  }, []);

  const getVisitData = (pageNumber?: number) => {
    const page = pageNumber ? pageNumber : 1;

    return dispatch(getVisits({
      page,
      limit,
      order: 'ASC',
      statuses,
      documentStatuses,
      locationIds: restrictedLocationIds,
      search,
      checking,
      dateFrom: startDateTime, // start of day in iso format
      dateTo: endDateTime, // end of day in iso format
    }));
  };

  const debouncedGetVisitData = useDebouncedCallback((
    limit: number,
    locationIds: string[],
    startDateTime: string,
    endDateTime: string,
    search?: string,
    checking?: boolean,
    statuses?: filterStatusType[],
    documentStatuses?: UserDocumentStatus[],
    pageNumber?: number,
  ) => {
    const page = pageNumber ? pageNumber : 1;

    dispatch(getVisits({
      page,
      limit,
      locationIds,
      order: 'ASC',
      statuses,
      documentStatuses,
      search,
      checking,
      dateFrom: startDateTime, // start of day in iso format
      dateTo: endDateTime, // end of day in iso format
    }));
  }, [dispatch], 800);

  useEffect(() => {
    setUpdatePaginationCount(prev => prev + 1); // show 1 page on each filter update
    dispatch(updateVisitsLoading(true));
    debouncedGetVisitData(
      limit,
      restrictedLocationIds,
      startDateTime,
      endDateTime,
      search,
      checking,
      statuses,
      documentStatuses,
    );
  }, [
    limit,
    restrictedLocationIds.toString(),
    startDateTime,
    endDateTime,
    search,
    checking,
    statuses.toString(),
    documentStatuses.toString(),
  ]);

  const onChangePage = (page: number) => {
    getVisitData(page);
  };

  return (
    <Box>
      <AppointmentsHeader />

      <Box display="flex" justifyContent="between">
        <Box className={styles.appointmentsList}>
          <AppointmentsList appointments={visits} />
        </Box>

        <Box className={styles.appointmentsCalendar} marginLeft={30} width={370}>
          <AppointmentCalendar />
        </Box>
      </Box>

      {totalPages > 0 &&
        <Box padding="100px 0 50px">
          <CustomPagination
            count={totalPages}
            key={updatePaginationCount}
            onChange={onChangePage}
          />
        </Box>
      }
    </Box>
  );
}
