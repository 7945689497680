import Box from "../../../../Components/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import SectionIcon from "../../../../Components/Icons/Section";
import RoomIcon from "../../../../Components/Icons/Room";
import Text from "../../../../Components/Text";
import { Trans, t } from "@lingui/macro";
import Checkbox from "../../../../Components/Checkbox";
import styles from "../styles.module.scss";
import {
  setDrawDeskState,
  setDrawMeetingState,
  setDrawSectionState,
  setFloorMapData,
} from "../../../Store/floorMapDuck";
import DefaultDeskSize from "../Components/DefaultDeskSize";
import { useTypedSelector } from "../../../../Store/Redux/store";
import { useDispatch } from "react-redux";
import DeskIcon from "Components/Icons/Desk";

export default function FloorMapControls() {
  const dispatch = useDispatch();
  const { adminFloorMap,  config } = useTypedSelector(state => state);
  const {
    drawingStates,
    showSections,
  } = adminFloorMap;
  const { drawDesk, drawSection, drawMeeting } = drawingStates;


  const toggleAddDesk = () => {
    dispatch(setDrawDeskState({ active: !drawDesk.active }));
  };

  const toggleAddSection = () => {
    // Show sections if the're inactive when adding new sections
    if (!drawSection.active) {
      dispatch(setFloorMapData({ showSections: true }));
    }

    dispatch(setDrawSectionState({ active: !drawSection.active }));
  };

  const toggleAddMeeting = () => {
    dispatch(setDrawMeetingState({ active: !drawMeeting.active }));
  };

  return (
    <Box alignItems="center" display="flex" justifyContent="between">
      <Box alignItems="center" display="flex" gap={5}>
        <ButtonBase data-testid="floor-map-details-add-section" disableRipple onClick={toggleAddSection}>
          <Box alignItems="center" className={drawSection.active ? styles.mapControlActive : styles.mapControl} display="flex">
            <SectionIcon color={drawSection.active ? config.theme.primary : "#5E5E5E"} size={16}/>

            <Box marginStart={5}>
              <Text color={drawSection.active ? "blue" : "gray"} size="md" weight="semi-bold">
                <Trans>Add Section</Trans>
              </Text>
            </Box>
          </Box>
        </ButtonBase>
        <ButtonBase data-testid="floor-map-details-add-desk" disableRipple onClick={toggleAddDesk}>
          <Box alignItems="center" className={drawDesk.active ? styles.mapControlActive : styles.mapControl} display="flex">
            <DeskIcon color={drawDesk.active ? config.theme.primary : "#5E5E5E"} size={16}/>
            <Box marginStart={5}>
              <Text color={drawDesk.active ? "blue" : "gray"} size="md" weight="semi-bold">
                <Trans>Add Desk</Trans>
              </Text>
            </Box>
          </Box>
        </ButtonBase>
        <ButtonBase data-testid="floor-map-details-add-meeting" disableRipple onClick={toggleAddMeeting}>
          <Box alignItems="center" className={drawMeeting.active ? styles.mapControlActive : styles.mapControl} display="flex">
            <RoomIcon color={drawMeeting.active ? config.theme.primary : "#5E5E5E"} size={16}/>

            <Box marginStart={5}>
              <Text color={drawMeeting.active ? "blue" : "gray"} size="md" weight="semi-bold">
                <Trans>Add Room</Trans>
              </Text>
            </Box>
          </Box>
        </ButtonBase>
      </Box>

      <Box alignItems="center" display="flex" gap={10}>
        <Box dataTestId="floor-map-details-show-sections">
          <Checkbox
            checked={showSections}
            classes={{container: styles.checkbox}}
            label={t`Show sections`}
            name="showSections"
            onChange={(value) => dispatch(setFloorMapData({showSections: value}))}
          />
        </Box>
        <Box>
          <DefaultDeskSize/>
        </Box>
      </Box>
    </Box>
  );
}
