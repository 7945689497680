import BookingDetails from '../BookingDetails';
import Box from 'Components/Box';
import Breadcrumbs from 'App/Components/Breadcrumbs';
import Button from 'Components/Button';
import FloorSupportItem, { FloorItemLoading } from './FloorSupportItem';
import format from 'date-fns/format';
import numberToHoursMinutes from 'Functions/numberToHoursMinutes';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { getFloors } from 'App/Store/Locations/locationsDuck';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { StaggeredItems } from 'Components/Animations/StaggeredItems';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import { WeekDays } from 'App/Store/Bookings/bookingDuck/models';

interface FloorProps {
  type?: 'support' | 'desk';
}

export default function FloorSupport({ type = 'desk' }: FloorProps) {
  const dispatch = useDispatch();
  const { createNewBooking, locations: locationsDuck } = useTypedSelector(state => state);

  const {
    bookingType,
    dateFrom,
    dateTo,
    filters,
    floorId,
    locationId,
    timeFrom,
    timeTo,
    weekDays,
  } = createNewBooking;
  const { floors, loading } = locationsDuck;
  const bookingPath = type === 'desk' ? 'create-new-booking' : 'create-new-booking-support';

  useEffect(() => {
    if (locationId) {
      const weeklyBooking: Array<{ weekDay: WeekDays; timeFrom: string; timeTo: string }> = [];

      Object.keys(weekDays).forEach(weekDayNumber => {
        const weekDay = weekDays[parseInt(weekDayNumber)];

        if (weekDay.selected) {
          const { hours: timeFromHours, minutes: timeFromMinutes } = numberToHoursMinutes(weekDay.timeFrom);
          const { hours: timeToHours, minutes: timeToMinutes } = numberToHoursMinutes(weekDay.timeTo);

          weeklyBooking.push({
            timeFrom: `${timeFromHours}:${String(timeFromMinutes).length === 1 ? timeFromMinutes + '0' : timeFromMinutes}`,
            timeTo: `${timeToHours}:${String(timeToMinutes).length === 1 ? timeToMinutes + '0' : timeToMinutes}`,
            weekDay: weekDay.label,
          });
        }
      });

      const timeFromFormated = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
      const timeToFormated = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

      dispatch(getFloors({
        data: {
          custom: bookingType === 'custom'
            ? {
              dateFrom: dateFrom ? format(new Date(dateFrom), 'yyyy-MM-dd') : '',
              dateTo: dateTo ? format(new Date(dateTo), 'yyyy-MM-dd') : '',
              timeFrom: timeFromFormated,
              timeTo: timeToFormated,
            }
            : undefined,
          daily: bookingType === 'daily' ? { timeFrom: timeFromFormated, timeTo: timeToFormated } : undefined,
          weekly: bookingType === 'weekly' ? weeklyBooking : undefined,
        },
        locationId,
      }));
    }
  }, [dateFrom, dateTo, timeFrom, timeTo, filters, locationId, weekDays]);

  const onChangeFloor = (floorId: string) => {
    dispatch(setCreateNewBookingData({ floorId }));
  };

  return (
    <Box display="grid" gap={30} gridTemplateColumns="8fr 4fr" paddingBottom={50}>
      <Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="between"
          marginBottom={15}
        >
          <Breadcrumbs type={type} />
        </Box>

        {loading ? (
          <FloorItemLoading />
        ) : (
          <Box display="grid" gap={15}>
            <StaggeredItems className={styles.items}>
              {floors.map((floor, index) => (
                <FloorSupportItem
                  {...floor}
                  key={floor.id + index}
                  onClick={onChangeFloor}
                  selected={floorId === floor.id}
                />
              ))}
            </StaggeredItems>

            <Box display="flex" justifyContent="end" marginTop={5}>
              <Button disabled={!floorId} size="sm" to={`/${bookingPath}/select-desk`}>
                <Trans>Next</Trans>
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <Box>
        <Box marginBottom={15}>
          <Text size="xlg" weight="semi-bold">
            <Trans>Booking details</Trans>
          </Text>
        </Box>

        <Box marginTop={30}>
          <BookingDetails type={type} />
        </Box>
      </Box>
    </Box >
  );
}
