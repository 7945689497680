import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../../../Store/Redux/store";
import { Trans } from "@lingui/macro";
import _ from "underscore";
import styles from "../../../../../Locations/LocationForm/styles.module.scss";

import Box from "../../../../../../../Components/Box";
import { Autocomplete, AutocompleteInputChangeReason } from "@material-ui/lab";
import arrowIcon from "../../../../../Locations/LocationForm/assets/arrow-down.svg";
import {
  AutocompleteRenderOptionState,
} from "@material-ui/lab/Autocomplete/Autocomplete";
import { searchGroupsFromAd } from "../../../../../../Store/groups";
import { setGroupsCreateEditFormData } from "../../../../../../Store/groups/forms/createEditForm";
import { IGroupAdSearch } from "../../../../../../Store/groups/models";
import {
  AutocompleteRenderInputDefault,
  AutocompleteRenderOptionTruncate,
} from "../../../../../../../Components/Autocomplete";

export default function AdEmail() {
  const dispatch = useDispatch();
  const { loadingAdSearch, actionState, group, groupsAd, formErrors } = useTypedSelector(state => state.groupCreateEditForm);
  const adGroup: IGroupAdSearch | null = actionState === 'edit' && group && group.adGroup ? group.adGroup : null;
  const [groupAdValue, setGroupAdValue] = useState<IGroupAdSearch | null>(adGroup);

  const adEmailErrorClass = formErrors.groupAdError ? styles.formErrorShow : '';

  // set actual redux data after load group data
  useEffect(() => {
    dispatch(setGroupsCreateEditFormData({
      formDataAdGroup: adGroup,
    }));
  }, [group]);

  const onResetSearchState = () => {
    dispatch(setGroupsCreateEditFormData({
      groupsAd: [],
    }));
  };

  useEffect(() => {
    onResetSearchState();
  }, []);

  const onAdEmailChange = (event: any, adGroup: IGroupAdSearch) => {
    setGroupAdValue(adGroup);

    dispatch(setGroupsCreateEditFormData({
      formDataAdGroup: adGroup,
    }));

    // set error as false if fields is not empty
    if (adGroup) {
      dispatch(setGroupsCreateEditFormData({
        formErrors: {
          ...formErrors,
          groupAdError: false,
        },
      }));
    }
  };

  const onSearchChange = _.debounce((event: any, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason !== 'reset' && reason !== 'clear') { // 'reset' fires on select from list, no need to handle api
      const search = event.target.value;
      dispatch(searchGroupsFromAd({
        limit: 10,
        search,
      }));
    }
  }, 300);

  const getLabelName = (option: IGroupAdSearch | null): string => {
    if (!option) {
      return '';
    }

    let labelName = option.displayName;

    if (option.mail) {
      labelName = `${labelName} (${option.mail})`;
    }

    return labelName;
  };

  return (
    <Box>
      <span className={styles.requiredSign}>*</span><label htmlFor="ad-email"><Trans>Directory email</Trans></label>

      <Box display="flex" justifyContent="between">
        <Box direction="column" display="flex" width="100%">
          <Autocomplete
            className={`input input--default input--inline ${adEmailErrorClass}`}
            filterOptions={(x) => x} // fix load throttling
            getOptionLabel={(option) => getLabelName(option)}
            id="address"
            loading={loadingAdSearch}
            loadingText="Loading..."
            onBlur={() => onResetSearchState()}
            onChange={(event: any, adEmail: any) => onAdEmailChange(event, adEmail)}
            onInputChange={(event, value, reason) => onSearchChange(event, value, reason)}
            options={groupsAd}
            popupIcon={
              <img height={10} src={arrowIcon} width={14} />
            }
            renderInput={AutocompleteRenderInputDefault}
            renderOption={(option, state: AutocompleteRenderOptionState) => AutocompleteRenderOptionTruncate(getLabelName(option), state)}
            value={groupAdValue}
          />
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          {formErrors.groupAdError ? <div className={styles.formErrors}><Trans>This field can't be empty</Trans></div> : null}
        </Box>
      </Box>
    </Box>
  );
}
