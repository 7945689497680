import { Box, BoxProps } from "@mui/material";
import React from "react";

export const Container: React.FC<BoxProps> = (props) => {
  return (
    <Box
      margin="0 auto"
      maxWidth={1580}
      paddingLeft={6}
      paddingRight={6}
      sx={({ breakpoints }) => ({
        paddingBottom: 6,
        [breakpoints.up("xl")]: {
          paddingBottom: 0,
        },
      })} 
      {...props}
    />
  );
};