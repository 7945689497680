import { useState } from "react";
import { useTypedSelector } from "../../../../../Store/Redux/store";
// import Popup from "reactjs-popup";
// import {Trans} from "@lingui/macro";
import styles from "../../styles.module.scss";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// import IconButton from "@material-ui/core/IconButton";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import ButtonBase from "@material-ui/core/ButtonBase";
// import Text from "../../../../../Components/Text";
// import Box from "../../../../../Components/Box";
import OverflowToolTip from "../../../../../Components/OverflowTooltip";
import Select from "../../../../../Components/Select";
import DeleteUserModal from "../../DeleteUserModal";
import { UserManagementUser, userRole } from "../../../../Store/userManagement/models";
import AdminLocationsModal from "../../AdminLocationsModal";
import { useDispatch } from "react-redux";
import { updateUserRole } from "../../../../Store/userManagement";
import UserDocumentDisplay from "./UserDocumentDisplay";
import UserAccommodation from "./UserAccommodation";
import format from "date-fns/format";

interface AllUsersTableBodyProps {
  user: UserManagementUser;
  index: number;
}

function getUserLocation(user: UserManagementUser, role: userRole) {
  switch (role) {
    case "Delegate":
    case "User":
      return (
        <div className={styles.locationNotAvailable}>
          <OverflowToolTip text={'Not available'} />
        </div>
      );
    case "Super Admin":
      return (<OverflowToolTip text={'All locations'} />);
    case "Local Admin":
    case "Reservation Manager":
    case "HR":
      return <AdminLocationsModal user={user} />;

    default:
      return '-';
  }
}

function getUserRole(user: UserManagementUser): { value: string, name: userRole } {
  const { userRoles } = useTypedSelector(state => state.adminUserManagement);
  const userRoleId = user.role?.id;
  const role = userRoles.find(role => role.id === userRoleId);

  if (role) {
    return {
      value: role.id,
      name: role.name,
    };
  }

  return { value: '', name: 'User' };
}

export default function AllUsersTableBody(props: AllUsersTableBodyProps) {
  const dispatch = useDispatch();
  const { user, index } = props;
  const { adminUserManagement, profile } = useTypedSelector(state => state);
  const { userRoles } = adminUserManagement;
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const isSuperAdmin = profile.roleAccess.superAdmin;

  const userRoleList = userRoles.length ? userRoles
    .map(role => { return { value: role.id, name: role.name }; }) : [];
  const userRole = getUserRole(user);
  const userLocation = getUserLocation(user, userRole.name);

  // delete modal
  // const onOpenDeleteModal = () => {
  //   setIsOpenDeleteModal(o => !o);
  // };

  const onCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  const onChangeUserRole = (e: any) => {
    dispatch(updateUserRole({ userId: user.id, roleId: e.target.value }));
  };

  // TODO: [IPG-1096] - Validate logic of what documents to show
  const firstDocument = user.userDocument || undefined;

  return (
    <>
      <TableRow
        classes={{ root: styles.tableRow }}
        hover
        key={index}
        tabIndex={-1}
      >
        <TableCell classes={{ root: styles.tableCell }}>
          <OverflowToolTip text={user.name} />
        </TableCell>
        <TableCell classes={{ root: styles.tableCell }}>
          <OverflowToolTip text={user.email} />
        </TableCell>
        <TableCell classes={{ root: styles.tableCell }}>
          <Select
            classes={{
              selectWrapper: styles.selectWrapper,
              materialSelect: styles.selectFullWidth,
              materialLabel: styles.selectLabel,
              text: styles.selectText,
              input: styles.rolePadding,
            }}
            disabled={!isSuperAdmin}
            id="desk-status"
            items={userRoleList}
            label=""
            labelColor="gray"
            onChange={onChangeUserRole}
            overflowText
            showIconArrow={false}
            value={userRole.value}
          />
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }}>
          <div className={!isSuperAdmin ? styles.disableRow : ''}>{userLocation}</div>
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }}>
          {user.country ?? '-'}
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }}>
          {user.city ?? '-'}
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }}>
          {firstDocument ? <UserDocumentDisplay document={firstDocument} user={user} /> : '-'}
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }}>
          <UserAccommodation approvedAccommodation={user.approvedAccommodation} userId={user.id} />
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }}>
          {firstDocument && firstDocument.createdAt ? format(new Date(firstDocument.createdAt), 'MM/dd/yyyy') : '-'}
        </TableCell>

        {/* <TableCell align="right" classes={{ root: styles.tableCell }}> */}
        {/* temporary hide user delete */}
        {/*<Popup*/}
        {/*  closeOnDocumentClick*/}
        {/*  contentStyle={{marginLeft: 27, width: 230}}*/}
        {/*  position="bottom right"*/}
        {/*  trigger={*/}
        {/*    <IconButton style={{padding: 0}}>*/}
        {/*      <MoreVertIcon style={{color: config.theme.primary, fontSize: 22}}/>*/}
        {/*    </IconButton>*/}
        {/*  }*/}
        {/*>*/}
        {/*  {(close: any) => (*/}
        {/*    <Box borderStyle="shadow" className={styles.popoverContent}>*/}
        {/*      <ButtonBase*/}
        {/*        className={styles.popoverItemButton}*/}
        {/*        onClick={() => {*/}
        {/*          onOpenDeleteModal();*/}
        {/*          close();*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px">*/}
        {/*          <g fill="none" fillRule="evenodd" id="A-users" stroke="none" strokeWidth="1">*/}
        {/*            <g fill={config.theme.primary} id="A-Usermanagment" transform="translate(-1185.000000, -309.000000)">*/}
        {/*              <g id="dd-actions" transform="translate(1170.000000, 294.000000)">*/}
        {/*                <g id="delete-white-copy-5" transform="translate(15.000000, 15.000000)">*/}
        {/*                  <path d="M14,4 C14.5522847,4 15,4.44771525 15,5 C15,5.55228475 14.5522847,6 14,6 L19,6 C19.5522847,6 20,6.44771525 20,7 C20,7.55228475 19.5522847,8 19,8 L18,8 L17,18 C17,19.1045695 16.1045695,20 15,20 L9,20 C7.8954305,20 7,19.1045695 7,18 L6,8 L5,8 C4.44771525,8 4,7.55228475 4,7 C4,6.44771525 4.44771525,6 5,6 L10,6 C9.44771525,6 9,5.55228475 9,5 C9,4.44771525 9.44771525,4 10,4 L14,4 Z M15.6,12 L8.4,12 L9,18 L15,18 L15.6,12 Z M16,8 L8,8 L8.2,10 L15.8,10 L16,8 Z" id="Combined-Shape"></path>*/}
        {/*                </g>*/}
        {/*              </g>*/}
        {/*            </g>*/}
        {/*          </g>*/}
        {/*        </svg>*/}
        {/*        <Box marginStart={5}>*/}
        {/*          <div>*/}
        {/*            <Text color="blue" size="md">*/}
        {/*              <Trans>Delete User</Trans>*/}
        {/*            </Text>*/}
        {/*          </div>*/}
        {/*        </Box>*/}
        {/*      </ButtonBase>*/}
        {/*    </Box>*/}
        {/*  )}*/}
        {/*</Popup>*/}
        {/* </TableCell> */}
      </TableRow>

      <DeleteUserModal
        onCancelAction={onCloseDeleteModal}
        open={isOpenDeleteModal}
        user={user}
      />
    </>
  );
}
