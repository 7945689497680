import { format } from "date-fns";
import { toDate } from "date-fns-tz";

export const shiftTimeZone = (date: Date, timeZone = "UTC"): Date => {
  if (timeZone === Intl.DateTimeFormat().resolvedOptions().timeZone) {
    return date;
  }

  return toDate(format(date, "yyyy-MM-dd HH:mm:ss.SSS"), { timeZone });
};
