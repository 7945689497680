import { ActionPayload, BaseErrorResponse, BaseResponse } from '../../../../Store/Models/ReduxModels';
import { t } from '@lingui/macro';
import {
  GetSectionNeighboursRequest,
  GetSectionNeighboursResponse,
} from "./models";

export const GET_SECTION_NEIGHBOURS = 'GET_SECTION_NEIGHBOURS';
export const GET_SECTION_NEIGHBOURS_FAILURE = 'GET_SECTION_NEIGHBOURS_FAILURE';
export const GET_SECTION_NEIGHBOURS_SUCCESS = 'GET_SECTION_NEIGHBOURS_SUCCESS';

export interface GetSectionNeighbours {
  type: typeof GET_SECTION_NEIGHBOURS;
  payload: ActionPayload<GetSectionNeighboursRequest>;
}
export interface GetSectionNeighboursFailure {
  type: typeof GET_SECTION_NEIGHBOURS_FAILURE;
  payload: BaseErrorResponse;
}
export interface GetSectionNeighboursSuccess {
  type: typeof GET_SECTION_NEIGHBOURS_SUCCESS;
  payload: BaseResponse<GetSectionNeighboursResponse>;
}

export type Actions =
  | GetSectionNeighbours
  | GetSectionNeighboursFailure
  | GetSectionNeighboursSuccess;

export interface State {
  error: string;
  loading: boolean;
  sectionNeighbors: {
    [sectionId: string]: GetSectionNeighboursResponse;
  };
}

const initialState: State = {
  error: '',
  loading: false,
  sectionNeighbors: {},
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case GET_SECTION_NEIGHBOURS:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case GET_SECTION_NEIGHBOURS_FAILURE:
      return {
        ...state,
        error: t`There was an error loading section neighbors. Please try again.`,
        loading: false,
      };
    case GET_SECTION_NEIGHBOURS_SUCCESS: {
      // const sectionNeighbors: { [sectionId: string]: GetSectionNeighboursResponse } = {};
      // const result = action.payload.data.result.data;

      // sectionNeighbors[result.sectionId] = result;

      return {
        ...state,
        error: '',
        // loading: false,
        // sectionNeighbors,
      };
    }

    default:
      return state;
  }
}

// Actions
export function getSectionNeighbours({
  data,
  floorId,
  sectionId,
}: {
  data: GetSectionNeighboursRequest,
  floorId: string,
  sectionId: string | number,
}): GetSectionNeighbours {
  return {
    type: GET_SECTION_NEIGHBOURS,
    payload: {
      request: {
        method: 'GET',
        url: `/api/locations/${floorId}/section/${sectionId}/users`,
        data,
      },
    },
  };
}
