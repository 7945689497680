import { t } from "@lingui/macro";

export enum WeekDaysEnum {
  'Sun' = 0,
  'Mon' = 1,
  'Tue' = 2,
  'Wed' = 3,
  'Thu' = 4,
  'Fri' = 5,
  'Sat' = 6,
}

export interface WeekDaysObject {
  [key: number]: {
    label: string;
    selected: boolean;
    timeFrom: number;
    timeTo: number;
    value: number;
  };
}


export const WEEK_DAYS: WeekDaysObject = {
  0: {
    label: t`Sun`,
    selected: false,
    timeFrom: 9,
    timeTo: 17,
    value: 0,
  },
  1: {
    label: t`Mon`,
    selected: false,
    timeFrom: 9,
    timeTo: 17,
    value: 1,
  },
  2: {
    label: t`Tue`,
    selected: false,
    timeFrom: 9,
    timeTo: 17,
    value: 2,
  },
  3: {
    label: t`Wed`,
    selected: false,
    timeFrom: 9,
    timeTo: 17,
    value: 3,
  },
  4: {
    label: t`Thu`,
    selected: false,
    timeFrom: 9,
    timeTo: 17,
    value: 4,
  },
  5: {
    label: t`Fri`,
    selected: false,
    timeFrom: 9,
    timeTo: 17,
    value: 5,
  },
  6: {
    label: t`Sat`,
    selected: false,
    timeFrom: 9,
    timeTo: 17,
    value: 6,
  },
};

export interface CalendarDatesModel {
  disabledDates: Date[] | string[],
  disabledWeekDays: WeekDaysEnum[];
  reservedDates: Date[] | string[],
}

export interface GetCalendarDatesRequest {
  locationId: string;
}

export interface GetCalendarDatesResponse {
  result: {
    statusCode: number;
    data: CalendarDatesModel;
  }
}

// add separate edit for week day? (just number of week, without all dates for this week)

export interface EditCalendarWeekDaysModel {
  disabledWeekDays?: WeekDaysEnum[];
  enableWeekDays?: WeekDaysEnum[];
}

export interface EditCalendarSingleDaysModel {
  disabledDates?: Date[] | string[];
  enableDates?: Date[] | string[];
}

export interface EditCalendarDatesModel extends EditCalendarWeekDaysModel, EditCalendarSingleDaysModel  {
  daysToCancelReservations?: Date[] | string[];
  resetAll?: boolean;
}

export interface EditCalendarDatesRequest extends EditCalendarDatesModel {
  locationId: string;
}

export interface EditCalendarDatesResponse {
  result: {
    statusCode: number;
    data: CalendarDatesModel
  }
}
