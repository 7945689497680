interface Props {
  color?: string;
  size?: number;
}

export default function Clock({
  color = '#4A4A4A',
  size = 16,
}: Props) {
  return (
    <svg height={size} version="1.1" viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g fill="none" fillRule="evenodd" id="A-users" stroke="none" strokeWidth="1">
        <g fill={color} fillRule="nonzero" id="A-Usermanagment" transform="translate(-1004.000000, -114.000000)">
          <g id="Group" transform="translate(230.000000, 110.000000)">
            <g id="icons/approval" transform="translate(774.000000, 4.000000)">
              <path
                d="M8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 C12.418278,16 16,12.418278 16,8 C16,3.581722 12.418278,0 8,0 Z M8,2 C11.3137085,2 14,4.6862915 14,8 C14,11.3137085 11.3137085,14 8,14 C4.6862915,14 2,11.3137085 2,8 C2,4.6862915 4.6862915,2 8,2 Z M8,3 C8.55228475,3 9,3.44771525 9,4 L9,8 C9,8.55228475 8.55228475,9 8,9 C7.44771525,9 7,8.55228475 7,8 L7,4 C7,3.44771525 7.44771525,3 8,3 Z M8,7 L12,7 C12.5522847,7 13,7.44771525 13,8 C13,8.55228475 12.5522847,9 12,9 L8,9 C7.44771525,9 7,8.55228475 7,8 C7,7.44771525 7.44771525,7 8,7 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
