import floor from './assets/floor.png';
import location from './assets/location.png';
import { DOMAIN_URL } from 'Consts/domain';
import { useTypedSelector } from 'Store/Redux/store';

export default function ApprovalRequired() {
  const { config } = useTypedSelector(state => state);
  
  return (
    <html>
      <head>
        <link href="https://fonts.gstatic.com" rel="preconnect" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap" rel="stylesheet" />
      </head>

      <body style={{ fontFamily: `'Poppins', sans-serif` }}>
        <div style={{ maxWidth: 500, margin: '0 auto', padding: 20 }}>
          <table style={{ borderCollapse: 'collapse' }}>
            <tr>
              <td>
                <span style={{ fontSize: 18, fontWeight: 600 }}>
                  Approval required
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <div style={{ padding: '14px 0 21px', borderBottom: '1px solid #F4F4F4' }}>
                  <span style={{ color: "#5E5E5E", fontSize: 14 }}>
                    <strong style={{ color: "#000000" }}>Desk 12</strong> required approval for user <a href="mailto:alex@zazmic.com" style={{ color: config.theme.primary, fontWeight: 500 }}>alex@zazmic.com</a>. <br />
                    Please approve or deny
                  </span>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div style={{ marginTop: 20 }}>
                  <span style={{ fontSize: 18, fontWeight: 600 }}>
                    Details
                  </span>

                  <br />

                  <table style={{ borderCollapse: 'collapse', marginTop: 10 }}>
                    <tr>
                      <td valign="top">
                        <span style={{ color: "#5E5E5E", fontSize: 14, whiteSpace: 'nowrap' }}>
                          <span style={{ padding: '4px 9px', borderRadius: 8, backgroundColor: "#f4f4f4" }}>
                            <img alt="floor" src={floor} style={{ position: 'relative', top: 4, height: 17 }} /> Floor 2
                          </span>
                        </span>
                      </td>

                      <td>
                        <div style={{ marginLeft: 7 }}>
                          <span style={{ color: "#5E5E5E", fontSize: 14 }}>
                            <img alt="location" src={location} style={{ position: 'relative', top: 4, height: 17 }} /> Flatiron Building, 175 5th Ave, New York, NY 10010, United States
                          </span>
                        </div>
                      </td>
                    </tr>
                  </table>

                  <div style={{ marginTop: 9, fontSize: 14, fontWeight: 600 }}>
                    Mar 5, 9am-12pm
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <table style={{ borderCollapse: 'collapse', marginTop: 26 }}>
                  <tr>
                    <td>
                      <a
                        href={`${DOMAIN_URL}/booking-approval/deny/:bookingId/:approverEmail/:token`}
                        style={{
                          padding: '7px 46px',
                          backgroundColor: "#ffffff",
                          border: '1px solid rgba(13,14,76,0.2)',
                          color: config.theme.primary,
                          borderRadius: 6,
                          fontSize: 14,
                          fontWeight: 600,
                          minWidth: 130,
                          textAlign: 'center',
                          textDecoration: 'none',
                        }}
                      >
                        Deny
                      </a>
                    </td>

                    <td>
                      <div style={{ marginLeft: 20 }}>
                        <a
                          href={`${DOMAIN_URL}/booking-approval/approve/:bookingId/:approverEmail/:token`}
                          style={{
                            padding: '7px 35px',
                            backgroundColor: config.theme.primary,
                            color: "#ffffff",
                            borderRadius: 6,
                            fontSize: 14,
                            fontWeight: 600,
                            minWidth: 130,
                            textAlign: 'center',
                            textDecoration: 'none',
                          }}
                        >
                          Approve
                        </a>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </body>
    </html>
  );
}
