import { Tab as MUITab, TabProps as MUITabProps, styled } from "@mui/material";

export const Tab = styled((props: MUITabProps) => <MUITab disableRipple {...props} />)(() => ({
  borderRadius: 8,
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 6,
  paddingBottom: 6,
  minHeight: 0,
  fontWeight: "600",
  minWidth: 120,
  textTransform: "capitalize",
  "&.Mui-selected": {
    backgroundColor: "#ffffff",
  },
}));
