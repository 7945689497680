import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import CitiesFilter from './CitiesFilter';
import CountriesFilter from './CountriesFilter';
import StatusFilter from './StatusFilter';
import RolesFilter from "./RolesFilter";
import AccommodationFilter from "./AccommodationFiIter";
import {
  clearUserManagementFilters,
  getCountriesFilter,
  setUserManagementData,
} from 'Admin/Store/userManagement';
import {
  UserAccommodation,
  UserDocumentStatus,
  UserManagemetFiltersModel,
  userRole,
} from 'Admin/Store/userManagement/models';
import FilterModal from "../../../Components/Filters/FilterModal";

function getFilterCount(filters: UserManagemetFiltersModel) {
  let count = 0;

  if (filters.cities.length) {
    count++;
  }

  if (filters.countries.length) {
    count++;
  }

  const hasStatus = filters?.status && Object.keys(filters.status).some(key => filters.status[key as UserDocumentStatus].value);
  const hasRole = filters?.roles && Object.keys(filters.roles).some(key => filters.roles[key as userRole].value);
  const hasAccommodation = filters?.accommodation && Object.keys(filters.accommodation).some(key => filters.accommodation[key as UserAccommodation].value);

  if (hasStatus) {
    count++;
  }

  if (hasRole) {
    count++;
  }

  if (hasAccommodation) {
    count++;
  }

  return count;
}

export default function UserManagementFilters() {
  const dispatch = useDispatch();
  const { filters: reduxFilters } = useTypedSelector(state => state.adminUserManagement);
  const filterCount = getFilterCount(reduxFilters);

  useEffect(() => {
    dispatch(getCountriesFilter({
      country: reduxFilters.countries[0],
    }));
  }, []);

  /**
   * Set redux data applyFilters to true.
   * This will trigger the useEffect on component UserManagement src\Admin\Pages\UserManagement\index.tsx
   * and load the users again
   */
  const onSubmitFilters = () => {
    dispatch(setUserManagementData({
      filters: {
        ...reduxFilters,
        applyFilters: true,
      },
    }));
  };

  const onResetFilters = () => {
    dispatch(clearUserManagementFilters());
  };

  return (
    <>
      <FilterModal
        filterCount={filterCount}
        onResetFilters={onResetFilters}
        onSubmitFilters={onSubmitFilters}
        setSavedData={() => {}}
      >
        <CountriesFilter />
        <CitiesFilter />
        <StatusFilter />
        <RolesFilter />
        <AccommodationFilter />
      </FilterModal>
    </>
  );
}
