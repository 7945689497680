import { Box, BoxProps, useTheme } from "@mui/material";
import React from "react";

export const IconBox: React.FC<BoxProps> = (props) => {
  const { children, ...rest } = props;
  const { palette } = useTheme();

  return (
    <Box
      alignItems="center"
      bgcolor={palette.grey[100]}
      borderRadius={2}
      display="flex"
      height={30}
      justifyContent="center"
      width={30}
      {...rest}
    >
      {children}
    </Box>
  );
};
