import {useState} from "react";
import {useLocation} from "react-router-dom";
import {useTypedSelector} from "../../../Store/Redux/store";
import Popup from "reactjs-popup";
import styles from "../styles.module.scss";

import Box from "../../Box";
import SwitchToAccount from "./SwitchToAccount";
import UsersSearchList from "./UsersSearchList";
import ExecutiveAssistantProfileName from "./ExecutiveAssistantProfileName";

export default function ExecutiveAssistantProfile() {
  const location = useLocation();
  const { profile, executiveAssistant } = useTypedSelector(state => state);
  const [triggerIconClass, setTriggerIconClass] = useState('');
  // show selected user name if its chosen
  const greetingName = executiveAssistant.selectedUser?.name ? executiveAssistant.selectedUser.name : profile.name;
  const showUserSelection = !location.pathname.includes('/profile');

  const onOpenModal = () => {
    setTriggerIconClass(styles.open);
  };

  const onCloseModal = () => {
    setTriggerIconClass('');
  };

  return (
    <>
      <ExecutiveAssistantProfileName />
      <div className={styles.profileWelcome}>
        {showUserSelection ?
          <Popup
            closeOnDocumentClick
            contentStyle={{ width: 330 }}
            nested
            offsetX={-45}
            onClose={onCloseModal}
            onOpen={onOpenModal}
            position="bottom left"
            trigger={
              <div className={styles.delegateTrigger}>
                <div>{greetingName}</div>
                <svg className={triggerIconClass} height="8px" viewBox="0 0 8 8" width="8px">
                  <g fill="none" fillRule="evenodd" id="A-Delegate-role" stroke="none" strokeWidth="1">
                    <g id="Dashboard-Switched-from-Delegate-Roll" transform="translate(-276.000000, -54.000000)">
                      <g id="Group-7" transform="translate(100.000000, 20.000000)">
                        <g id="icons/arrow-down-s" transform="translate(176.000000, 34.000000)">
                          <g id="icons/arrow-right-s"></g>
                          <path d="M3.32132034,7.54974747 C2.93079605,7.94027176 2.29763107,7.94027176 1.90710678,7.54974747 C1.51658249,7.15922318 1.51658249,6.5260582 1.90710678,6.13553391 L4.02842712,4.01421356 L1.90710678,1.89289322 C1.51658249,1.50236893 1.51658249,0.869203948 1.90710678,0.478679656 C2.29763107,0.0881553647 2.93079605,0.0881553647 3.32132034,0.478679656 L6.14974747,3.30710678 C6.51237717,3.66973648 6.53827929,4.24158187 6.22745383,4.63409953 L6.14974747,4.72132034 L3.32132034,7.54974747 Z" fill="#1E1F7B" id="Path" transform="translate(4.028427, 4.014214) rotate(-270.000000) translate(-4.028427, -4.014214) "></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            }
          >
            {(close: Function) => (
              <Box borderStyle="shadow" className={styles.popoverContent} dataTestId="modal">
                <Box alignItems="center" className={styles.delegateModalTitle} display="flex" justifyContent="between">
                  <div>Switch to account</div>
                  <SwitchToAccount onClosePopup={close} />
                </Box>
                <Box>
                  <UsersSearchList onClosePopup={close} />
                </Box>
              </Box>
            )}
          </Popup> :
          <div>{greetingName}</div>
        }
      </div>
    </>
  );
}
