import AddParkingSpotModal from './AddEditParkingSpotModal';
import Box from 'Components/Box';
import Button from 'Components/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import CustomPagination from 'Components/CustomPagination';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LocationDetailsHeader from 'Admin/Components/Header/LocationDetailsHeader';
import ParkingSpotDeleteModal from './ParkingSpotDeleteModal';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './styles.module.scss';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Text from 'Components/Text';
import { editParkingSpot, getLocationParkingSpots, selectLocationParkingSpots } from 'Admin/Store/parking';
import { ParkingSpot } from 'Admin/Store/parking/models';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'Store/Redux/store';
import TableSubmenuButton from "../../Components/Buttons/TableSubmenuButton";

/**
 * Parknig spots listed by selected location.
 *
 * reference: https://projects.invisionapp.com/d/main#/console/21248637/460539173/preview
 *
 * OwnedBy: Bruno
 */
export default function Parking() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const { config, locations: locationsDuck, parking } = useTypedSelector(state => state);

  const [activeDeleteModal, setActiveDeleteModal] = useState<ParkingSpot | null>(null);
  const [activeEditParkingSpot, setActiveEditParkingSpot] = useState<ParkingSpot | null>(null);
  const [openAddParkingSpotModal, setOpenAddParkingSpotModal] = useState(false);

  const { loading } = locationsDuck;
  const selectedLocation = locationsDuck.locations.find(location => location.id === locationId);

  const parkingSpots = selectLocationParkingSpots(parking, locationId);

  const [page, setPage] = useState(1);
  const pageSize = 10;
  const pages = Math.ceil(parkingSpots.length / pageSize);

  useEffect(() => {
    dispatch(getLocationParkingSpots({ locationId }));
  }, [locationId]);

  const toggleOpenAddParkingSpotModal = () => {
    setActiveEditParkingSpot(null);
    setOpenAddParkingSpotModal(current => !current);
  };

  const addParkingSpotButton = (
    <Button
      aria-label={t`Add Parking Spot`}
      data-testid="add-parking-spots-button"
      name={t`Add Parking Spot`}
      onClick={toggleOpenAddParkingSpotModal}
      size="sm"
    >
      <Trans>
        Add Parking Spot
      </Trans>
    </Button>
  );

  const headCells = [
    { id: 'parkingSpot', label: t`Parking spot` },
    { id: 'floor', label: t`Floor` },
    { id: 'available', label: t`Available` },
    { id: 'options', label: '' },
  ];

  return (
    <>
      <LocationDetailsHeader buttons={addParkingSpotButton} />

      <Box
        className={styles.container}
        dataTestId="parking-spots"
        marginTop={20}
      >
        <TableContainer style={{ minHeight: pages > 0 ? 618 : 0 }}>
          <Table aria-label={t`parking spots listed by location`}>
            <TableHead classes={{ root: styles.tableHead }}>
              <TableRow classes={{ root: styles.tableRow }}>
                {headCells.map((headCell) => (
                  <TableCell classes={{ root: styles.tableCell }} key={headCell.id} padding="none">
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {!loading && (
              <TableBody classes={{ root: styles.tableBody }}>
                {parkingSpots
                  // This slices the array to show only items from correct page
                  // Eg: page 2 will show from 11 to 20 =>
                  // from: ((2 - 1) * 10) = 10 (slice will take the next item)
                  // to: 10 * 2 = 20

                  .slice((page - 1) * pageSize, pageSize * page)
                  .map((parkingSpot, index) => {
                    const handleEditParkingSpot = () => {
                      setActiveEditParkingSpot(parkingSpot);
                      setOpenAddParkingSpotModal(true);
                    };

                    const onToggleParkingSpot = () => {
                      dispatch(editParkingSpot({
                        parkingSpotId: parkingSpot.id,
                        data: {
                          name: parkingSpot.name,
                          floor: parkingSpot.floor ?? '',
                          isAvailable: !parkingSpot.isAvailable,
                        },
                      }));
                    };

                    return (
                      <TableRow
                        aria-label={t`Parking Spot Item`}
                        className={`${parkingSpot.isAvailable ? '' : styles.inactive}`}
                        classes={{ root: styles.tableRow }}
                        hover
                        key={`${parkingSpot.id} ${index}`}
                        role="row"
                        tabIndex={-1}
                      >
                        <TableCell classes={{ root: styles.tableCell }} component="th" id={parkingSpot.id} padding="none" scope="row">
                          {parkingSpot.name}
                        </TableCell>

                        <TableCell classes={{ root: styles.tableCell }}>
                          {parkingSpot.floor}
                        </TableCell>

                        <TableCell classes={{ root: styles.tableCell }}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={parkingSpot.isAvailable}
                                className="switcher switcher--default"
                                data-testid="parking-spot-toggle"
                                name={parkingSpot.id}
                                onChange={onToggleParkingSpot}
                              />
                            }
                            label=""
                          />
                        </TableCell>

                        <TableCell align="right" classes={{ root: styles.tableCell }}>
                          <TableSubmenuButton
                            iconButtonName={t`Parking Spot options`}
                            iconButtonStyle={{ padding: 0, width: '30px', height: '30px' }}
                          >
                            <Box borderStyle="shadow" className="popover-content" dataTestId="parking-spot-item-options-popup">
                              <ButtonBase className="popover-item-button" data-testid="parking-spot-actionMenu-edit" onClick={handleEditParkingSpot}>
                                <CreateOutlinedIcon style={{ color: config.theme.primary, fontSize: 20 }} />

                                <Box marginStart={5}>
                                  <Text color="blue" size="md">
                                    <Trans>
                                      Edit Parking Spot
                                    </Trans>
                                  </Text>
                                </Box>
                              </ButtonBase>

                              <ButtonBase
                                className="popover-item-button"
                                data-testid="parking-spot-actionMenu-delete"
                                onClick={() => setActiveDeleteModal(parkingSpot)}
                              >
                                <DeleteOutlineIcon style={{ color: config.theme.primary, fontSize: 20 }} />

                                <Box marginStart={5}>
                                  <Text color="blue" size="md">
                                    <Trans>Delete Parking Spot</Trans>
                                  </Text>
                                </Box>
                              </ButtonBase>
                            </Box>
                          </TableSubmenuButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {pages > 0 &&
          <Box padding="50px 0">
            <CustomPagination
              count={pages}
              onChange={(page) => setPage(page)}
            />
          </Box>
        }

        {loading ? (
          <Box dataTestId="parking-spots-loading" height={200}>
            <Skeleton height="100%" variant="rect" />
          </Box>
        ) : (
          !parkingSpots.length && (
            <Box dataTestId="parking-spots-no-data" marginTop={100}>
              <Text align="center" color="lightGray" size="md">
                <Trans>No parking spots found for {selectedLocation?.locationName}</Trans>
              </Text>
            </Box>
          )
        )}
      </Box>

      <AddParkingSpotModal
        open={openAddParkingSpotModal}
        parkingSpot={activeEditParkingSpot}
        toggleOpen={toggleOpenAddParkingSpotModal}
      />
      <ParkingSpotDeleteModal
        closeModal={() => setActiveDeleteModal(null)}
        open={Boolean(activeDeleteModal)}
        parkingSpot={activeDeleteModal}
      />
    </>
  );
}
