import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../../../Store/Redux/store";
import {compensateTimezoneOffset} from "../../../../Calendar/AdminCalendar/Helpers/Helpers";
import {
  getClosestAvailableDay,
  getClosestStartDate,
  getStartDisabledDaysArray,
} from "../../../../../../App/Functions/Helpers";
import {useEffect, useState} from "react";
import {DateRange, Range} from "react-date-range";
// import numberToHoursMinutes from "../../../../../../Functions/numberToHoursMinutes";
import Box from "../../../../../../Components/Box";
import Text from "../../../../../../Components/Text";
import {Trans} from "@lingui/macro";
import {setJustInTimeReservationData} from "../../../../../Store/reservationJustInTime";
import {setLocationsData} from "../../../../../../App/Store/Locations/locationsDuck";
import { FromToTimeInput, FromToTimeInputProps } from "App/Pages/CreateNewBooking/Calendar/FromToTimeInput";
import { resolvePersistedDate } from "Store/Utils";
import { add } from "date-fns";

const DEFAULT_TIME_FROM = new Date(1970, 0, 1, 9);
const DEFAULT_TIME_TO = new Date(1970, 0, 1, 18);

export default function CustomBooking() {
  const dispatch = useDispatch();
  const { config, adminReservationJustInTime, locations: locationsDuck } = useTypedSelector(state => state);
  const { dateFrom: reduxDateFrom, dateTo: reduxDateTo, selectedData } = adminReservationJustInTime;

  const locationDisabledDays = locationsDuck.locationDisabledDays.map(d => compensateTimezoneOffset(d));
  // add today to disabled days as default
  const startDisabledDaysArray = selectedData.location ? getStartDisabledDaysArray(selectedData.location?.allowedBookingDayType) : [];
  const disabledDays = startDisabledDaysArray.length ? [...startDisabledDaysArray, ...locationDisabledDays] : [...locationDisabledDays];

  const dateFrom = getClosestAvailableDay(reduxDateFrom, locationsDuck.locationDisabledDays);
  const dateTo = getClosestAvailableDay(reduxDateTo, locationsDuck.locationDisabledDays);
  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  });

  const handleSelect = (ranges: any) => {
    // @ts-ignore Typescript is nor recognizing this type. It's correct though
    setSelectionRange(ranges.selection);

    dispatch(setJustInTimeReservationData({
      // @ts-ignore Typescript is nor recognizing this type. It's correct though
      dateFrom: ranges.selection.startDate,
      // @ts-ignore Typescript is nor recognizing this type. It's correct though
      dateTo: ranges.selection.endDate,
    }));
  };


  // Updates selection range when redux data changes
  useEffect(() => {
    setSelectionRange({
      startDate: dateFrom,
      endDate: dateTo,
      key: 'selection',
    });
  }, [reduxDateFrom, reduxDateTo, locationsDuck.locationDisabledDays]);

  // set selection range on init load after all data is ready
  useEffect(() => {
    const closestStartDay = selectedData.location ? getClosestStartDate(selectedData.location?.allowedBookingDayType) : new Date();

    if (locationsDuck.locationDisabledDaysLoaded) {
      dispatch(setJustInTimeReservationData({
        dateFrom: getClosestAvailableDay(closestStartDay, locationsDuck.locationDisabledDays),
        dateTo: getClosestAvailableDay(closestStartDay, locationsDuck.locationDisabledDays),
      }));
      dispatch(setLocationsData({ locationDisabledDaysLoaded: false }));
    }
  }, [locationsDuck.locationDisabledDaysLoaded]);

  const { timeFrom, timeTo } = adminReservationJustInTime;
  const timeFromDate = resolvePersistedDate(timeFrom);
  const timeToDate = resolvePersistedDate(timeTo);

  useEffect(() => {
    dispatch(setJustInTimeReservationData({
      timeFrom: timeFromDate || DEFAULT_TIME_FROM,
      timeTo: timeToDate || DEFAULT_TIME_TO,
    }));
  }, []);

  const handleFromToTimeInputChange: FromToTimeInputProps["onChange"] = (value, error) => {
    if (error.from || error.to) {
      dispatch(setJustInTimeReservationData({
        timeFrom: undefined,
        timeTo: undefined,
      }));
    } else {
      dispatch(setJustInTimeReservationData({
        timeFrom: value.from,
        timeTo: value.to,
      }));
    }
  };

  return (
    <Box>
      <DateRange
        className="custom-calendar"
        disabledDates={disabledDays}
        maxDate={add(new Date(), { days: selectedData?.location?.customReservationDayLimit || 1 })}
        minDate={new Date()}
        onChange={handleSelect}
        rangeColors={[config.theme.primaryLight]}
        ranges={[selectionRange]}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
      />
      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={14} marginTop={10}>
        <Text size="md" weight="semi-bold">
          <Trans>Time</Trans>
        </Text>
        <Box>
          <FromToTimeInput
            defaultValue={{ from: new Date(0, 0, 0, 9), to: new Date(0, 0, 0, 18) }}
            from={timeFromDate}
            onChange={handleFromToTimeInputChange}
            to={timeToDate}
          />
        </Box>
      </Box>
    </Box>
  );
}
