import { t } from '@lingui/macro';

export type DashboardPeriod = 'month' | 'quarter' | 'halfYear' | 'year';

export interface LocationDashboard {
  reservationsByMonth: Array<{
    day: number;
    count: string;
  }>;
  reservationsByDay: Array<{
    day: string;
    count: string;
  }>;
  reservationsByDomain: Array<{
    domain: string;
    count: string;
  }>;
  reservationsCount: {
    total: string;
    unique: string;
  };
}

export interface DashboardsTotalCount {
  total: number;
  unique: number;
  cancelled: number;
}

export interface GetLocationDashboardsTotalCountRequest {
  locationId: string;
}
export interface GetLocationDashboardsTotalCountResponse {
  result: {
    data: DashboardsTotalCount;
  }
}

export type DashboardsTotalByDayPeriod = 'thisWeek' | 'lastWeek';
export const dashboardsTotalByDayArray: Array<{ name: string; value: DashboardsTotalByDayPeriod }> = [
  {
    name: t`This Week`,
    value: 'thisWeek',
  },
  {
    name: t`Last Week`,
    value: 'lastWeek',
  },
];
export interface TotalCountByDayPeriod {
  day: string;
  dayDate: string | null;
  booked: number;
  cancelled: number;
}
export interface GetLocationDashboardTotalByDayRequest {
  locationId: string;
  period: DashboardsTotalByDayPeriod;
}
export interface GetLocationDashboardTotalByDayResponse {
  result: {
    data: Array<TotalCountByDayPeriod>;
  }
}


export type DashboardsTotalByDomainPeriod = 'thisWeek' | 'lastWeek';
export const dashboardsTotalByDomainArray: Array<{ name: string; value: DashboardsTotalByDomainPeriod }> = [
  {
    name: t`This Week`,
    value: 'thisWeek',
  },
  {
    name: t`Last Week`,
    value: 'lastWeek',
  },
];
export interface TotalCountByDomainPeriod {
  domain: string;
  bookedAndCheckedIn: number;
  bookedAndNotCheckedIn: number;
  cancelled: number;
}
export interface GetLocationDashboardTotalByDomainRequest {
  locationId: string;
  period: DashboardsTotalByDomainPeriod;
}
export interface GetLocationDashboardTotalByDomainResponse {
  result: {
    data: Array<TotalCountByDomainPeriod>;
  }
}

export type DashboardsTotalCountByPeriodPeriods = 'week' | 'month' | 'quarter';
export const dashboardsTotalCountByPeriodPeriodsArray: Array<{ name: string; value: DashboardsTotalCountByPeriodPeriods }> = [
  {
    name: t`Month`,
    value: 'month',
  },
  {
    name: t`Quarter`,
    value: 'quarter',
  },
  {
    name: t`Week`,
    value: 'week',
  },
];
export interface TotalCountByPeriod {
  month: string | null;
  quarter: number | null;
  quarterDetail?: string | null;
  week: number | null;
  weekRange?: Array<string> | null;
  booked: number;
  cancelled: number;
}
export interface GetLocationDashboardsTotalCountByPeriodRequest {
  locationId: string;
  period: DashboardsTotalCountByPeriodPeriods;
}
export interface GetLocationDashboardsTotalCountByPeriodResponse {
  result: {
    data: Array<TotalCountByPeriod>;
  }
}

export interface SetLocationDashboardDataRequest {
  selectedPeriod?: DashboardsTotalCountByPeriodPeriods;
}
