import { t } from '@lingui/macro';
import {
  ActionPayload,
  BaseErrorResponse,
  BaseResponse,
} from '../../../Store/Models/ReduxModels';
import {
  CovidQuestionModel,
  CovidScheduleModel,
  CreateCovidQuestionModel,
  CreateCovidQuestionRequest,
  CreateCovidQuestionResponse,
  DeleteCovidQuestionRequest,
  DeleteCovidQuestionResponse,
  EditCovidQuestionRequest,
  EditCovidScheduleRequest,
  GetCovidQuestionRequest,
  GetCovidQuestionsResponse,
  GetCovidScheduleRequest,
  GetCovidScheduleResponse,
  SetCovidQuestionsDataRequest,
} from './models';

export const TOGGLE_QUESTION_FORM = 'TOGGLE_QUESTION_FORM';
export const SET_COVID_QUESTIONS_DATA = 'SET_COVID_QUESTIONS_DATA';

export const GET_COVID_QUESTIONS = 'GET_COVID_QUESTIONS';
export const GET_COVID_QUESTIONS_SUCCESS = 'GET_COVID_QUESTIONS_SUCCESS';
export const GET_COVID_QUESTIONS_FAIL = 'GET_COVID_QUESTIONS_FAIL';

export const CREATE_COVID_QUESTION = 'CREATE_COVID_QUESTION';
export const CREATE_COVID_QUESTION_SUCCESS = 'CREATE_COVID_QUESTION_SUCCESS';
export const CREATE_COVID_QUESTION_FAIL = 'CREATE_COVID_QUESTION_FAIL';

export const EDIT_COVID_QUESTION = 'EDIT_COVID_QUESTION';
export const EDIT_COVID_QUESTION_SUCCESS = 'EDIT_COVID_QUESTION_SUCCESS';
export const EDIT_COVID_QUESTION_FAIL = 'EDIT_COVID_QUESTION_FAIL';

export const DELETE_COVID_QUESTION = 'DELETE_COVID_QUESTION';
export const DELETE_COVID_QUESTION_SUCCESS = 'DELETE_COVID_QUESTION_SUCCESS';
export const DELETE_COVID_QUESTION_FAIL = 'DELETE_COVID_QUESTION_FAIL';

// schedule
export const GET_COVID_SCHEDULE = 'GET_COVID_SCHEDULE';
export const GET_COVID_SCHEDULE_SUCCESS = 'GET_COVID_SCHEDULE_SUCCESS';
export const GET_COVID_SCHEDULE_FAIL = 'GET_COVID_SCHEDULE_FAIL';

export const EDIT_COVID_SCHEDULE = 'EDIT_COVID_SCHEDULE';
export const EDIT_COVID_SCHEDULE_SUCCESS = 'EDIT_COVID_SCHEDULE_SUCCESS';
export const EDIT_COVID_SCHEDULE_FAIL = 'EDIT_COVID_SCHEDULE_FAIL';


// toggle add\edit form
export interface ToggleQuestionForm {
  type: typeof TOGGLE_QUESTION_FORM;
  payload: boolean;
}

// add data set
export interface SetCovidQuestionsData {
  type: typeof SET_COVID_QUESTIONS_DATA;
  payload: SetCovidQuestionsDataRequest;
}

// add question
export interface CreateCovidQuestion {
  type: typeof CREATE_COVID_QUESTION;
  payload: ActionPayload<CreateCovidQuestionModel>;
}

export interface CreateCovidQuestionSuccess {
  type: typeof CREATE_COVID_QUESTION_SUCCESS;
  payload: BaseResponse<CreateCovidQuestionResponse>;
}

export interface CreateCovidQuestionFail {
  type: typeof CREATE_COVID_QUESTION_FAIL;
  payload: BaseErrorResponse;
}

// get list of all questions
export interface GetCovidQuestions {
  type: typeof GET_COVID_QUESTIONS;
  payload: ActionPayload<GetCovidQuestionRequest>
}

export interface GetCovidQuestionsSuccess {
  type: typeof GET_COVID_QUESTIONS_SUCCESS;
  payload: BaseResponse<GetCovidQuestionsResponse>
}

export interface GetCovidQuestionsFail {
  type: typeof GET_COVID_QUESTIONS_FAIL;
  payload: BaseErrorResponse;
}

// edit question
export interface EditCovidQuestion {
  type: typeof EDIT_COVID_QUESTION;
  payload: ActionPayload<CreateCovidQuestionModel>;
}

export interface EditCovidQuestionSuccess {
  type: typeof EDIT_COVID_QUESTION_SUCCESS;
  payload: BaseResponse<CreateCovidQuestionResponse>;
}

export interface EditCovidQuestionFail {
  type: typeof EDIT_COVID_QUESTION_FAIL;
  payload: BaseErrorResponse;
}

// delete question
export interface DeleteCovidQuestion {
  type: typeof DELETE_COVID_QUESTION;
  payload: ActionPayload<DeleteCovidQuestionRequest>
}

export interface DeleteCovidQuestionSuccess {
  type: typeof DELETE_COVID_QUESTION_SUCCESS;
  payload: BaseResponse<DeleteCovidQuestionResponse>;
}

export interface DeleteCovidQuestionFail {
  type: typeof DELETE_COVID_QUESTION_FAIL;
  payload: BaseErrorResponse;
}

// get schedule
export interface GetCovidSchedule {
  type: typeof GET_COVID_SCHEDULE;
  payload: ActionPayload<GetCovidScheduleRequest>
}

export interface GetCovidScheduleSuccess {
  type: typeof GET_COVID_SCHEDULE_SUCCESS;
  payload: BaseResponse<GetCovidScheduleResponse>
}

export interface GetCovidScheduleFail {
  type: typeof GET_COVID_SCHEDULE_FAIL;
  payload: BaseErrorResponse;
}

// edit schedule
export interface EditCovidSchedule {
  type: typeof EDIT_COVID_SCHEDULE;
  payload: ActionPayload<CovidScheduleModel>;
}

export interface EditCovidScheduleSuccess {
  type: typeof EDIT_COVID_SCHEDULE_SUCCESS;
  payload: BaseResponse<GetCovidScheduleResponse>;
}

export interface EditCovidScheduleFail {
  type: typeof EDIT_COVID_SCHEDULE_FAIL;
  payload: BaseErrorResponse;
}


export type Actions =
  | ToggleQuestionForm
  | SetCovidQuestionsData
  | CreateCovidQuestion
  | CreateCovidQuestionSuccess
  | CreateCovidQuestionFail
  | GetCovidQuestions
  | GetCovidQuestionsSuccess
  | GetCovidQuestionsFail
  | EditCovidQuestion
  | EditCovidQuestionSuccess
  | EditCovidQuestionFail
  | DeleteCovidQuestion
  | DeleteCovidQuestionSuccess
  | DeleteCovidQuestionFail
  | GetCovidSchedule
  | GetCovidScheduleSuccess
  | GetCovidScheduleFail
  | EditCovidSchedule
  | EditCovidScheduleSuccess
  | EditCovidScheduleFail

export interface State {
  error: string;
  successMessage: string;
  loading: boolean;
  isOpenQuestionForm: boolean;
  covidQuestions: CovidQuestionModel[];
  covidSchedule: CovidScheduleModel;
}

const initialScheduleData: CovidScheduleModel = {
  id: '',
  startTime: 4,
  reminderTime: 15,
  cancellationTime: 5,
  isActive: false,
  collectQuestionnaireAnswers: false,
};

const initialState: State = {
  error: '',
  successMessage: '',
  loading: false,
  isOpenQuestionForm: false,
  covidQuestions: [],
  covidSchedule: initialScheduleData,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case TOGGLE_QUESTION_FORM:
      return {
        ...state,
        isOpenQuestionForm: action.payload,
      };
    case SET_COVID_QUESTIONS_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case CREATE_COVID_QUESTION:
      return {
        ...state,
      };
    case CREATE_COVID_QUESTION_SUCCESS: {
      const covidQuestion = action.payload.data.result.data;
      return {
        ...state,
        error: '',
        successMessage: 'Covid question was created',
        covidQuestions: [...state.covidQuestions, covidQuestion],
      };
    }
    case CREATE_COVID_QUESTION_FAIL:
      return {
        ...state,
        error: t`There was an error creating question. Please try again.`,
      };

    case EDIT_COVID_QUESTION:
      return {
        ...state,
      };
    case EDIT_COVID_QUESTION_SUCCESS: {
      const covidQuestion = action.payload.data.result.data;
      const newCovidQuestions = [...state.covidQuestions];
      const index = newCovidQuestions.findIndex(q => q.id === covidQuestion.id);
      newCovidQuestions[index] = covidQuestion;
      return {
        ...state,
        error: '',
        successMessage: 'Covid question was edited',
        covidQuestions: newCovidQuestions,
      };
    }
    case EDIT_COVID_QUESTION_FAIL:
      return {
        ...state,
        error: t`There was an error editing question. Please try again.`,
      };

    case GET_COVID_QUESTIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_COVID_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        covidQuestions: action.payload.data.result.data,
      };
    case GET_COVID_QUESTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: t`There was an error getting questions. Please try again.`,
      };

    case DELETE_COVID_QUESTION:
      return {
        ...state,
      };
    case DELETE_COVID_QUESTION_SUCCESS: {
      const deletedQuestionId = action.payload.data.result.data.id;
      const newCovidQuestions = [...state.covidQuestions].filter(q => q.id !== deletedQuestionId);
      return {
        ...state,
        error: '',
        successMessage: 'Covid question was deleted',
        covidQuestions: newCovidQuestions,
      };
    }
    case DELETE_COVID_QUESTION_FAIL:
      return {
        ...state,
        error: t`There was an error removing question. Please try again.`,
      };

    case GET_COVID_SCHEDULE:
      return {
        ...state,
      };
    case GET_COVID_SCHEDULE_SUCCESS: {
      const response = action.payload.data.result.data;
      let covidSchedule = { ...initialScheduleData };

      if (response) {
        covidSchedule = {
          id: response.id,
          startTime: response.startTime,
          reminderTime: response.reminderTime,
          cancellationTime: response.cancellationTime,
          isActive: response.isActive,
          collectQuestionnaireAnswers: response.collectQuestionnaireAnswers,
          cancelReservation: response.cancelReservation,
          ignoreWeekends: response.ignoreWeekends,
        };
      }

      return {
        ...state,
        covidSchedule,
      };
    }
    case GET_COVID_SCHEDULE_FAIL:
      return {
        ...state,
        error: t`There was an error getting questionnaire schedule. Please try again.`,
      };

    case EDIT_COVID_SCHEDULE:
      return {
        ...state,
      };
    case EDIT_COVID_SCHEDULE_SUCCESS: {
      return {
        ...state,
        error: '',
        covidSchedule: action.payload.data.result.data,
      };
    }
    case EDIT_COVID_SCHEDULE_FAIL:
      return {
        ...state,
        error: t`There was an error editing questionnaire schedule. Please try again.`,
      };

    default:
      return state;
  }
}

// Actions
export function updateToggleQuestionForm(isOpenQuestionForm: boolean): ToggleQuestionForm {
  return {
    type: TOGGLE_QUESTION_FORM,
    payload: isOpenQuestionForm,
  };
}

export function setCovidQuestionsData(data: SetCovidQuestionsDataRequest): SetCovidQuestionsData {
  return {
    type: SET_COVID_QUESTIONS_DATA,
    payload: data,
  };
}

export function getCovidQuestions(data: GetCovidQuestionRequest): GetCovidQuestions {
  return {
    type: GET_COVID_QUESTIONS,
    payload: {
      request: {
        method: 'GET',
        url: `/api/covid-questions/${data.locationId}/location-questions`,
      },
    },
  };
}

export function createCovidQuestion(data: CreateCovidQuestionRequest): CreateCovidQuestion {
  return {
    type: CREATE_COVID_QUESTION,
    payload: {
      request: {
        method: 'POST',
        url: `/api/covid-questions/${data.locationId}`,
        data: data.question,
      },
    },
  };
}

export function editCovidQuestion(data: EditCovidQuestionRequest): EditCovidQuestion {
  return {
    type: EDIT_COVID_QUESTION,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/covid-questions/${data.questionId}`,
        data: data.question,
      },
    },
  };
}

export function deleteCovidQuestion(data: DeleteCovidQuestionRequest): DeleteCovidQuestion {
  return {
    type: DELETE_COVID_QUESTION,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/covid-questions/${data.questionId}`,
      },
    },
  };
}

export function getCovidSchedule(data: GetCovidScheduleRequest): GetCovidSchedule {
  return {
    type: GET_COVID_SCHEDULE,
    payload: {
      request: {
        method: 'GET',
        url: `/api/covid-questions/${data.locationId}/schedule`,
      },
    },
  };
}

export function editCovidSchedule(data: EditCovidScheduleRequest): EditCovidSchedule {
  return {
    type: EDIT_COVID_SCHEDULE,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/covid-questions/${data.locationId}/schedule`,
        data: data.schedule,
      },
    },
  };
}
