import { FloorMapObject } from '../../../../../../Store/floorMapDuck/models';
import { useEffect, useState } from 'react';
import { Marker } from 'react-leaflet';
import { renderToStaticMarkup } from "react-dom/server";
import { LatLng, divIcon } from "leaflet";
import styles from './styles.module.scss';

interface Props {
  sectionRef: any; // ref to section object
  drawing: FloorMapObject;
  sectionLabelKey: number; // triggered after each section coordinate update
  iconMarkupOpacity: number;
}

function getCenter(bounds: any) {
  if (bounds) {
    return new LatLng(
      (bounds._southWest.lat + bounds._northEast.lat) / 2,
      (bounds._southWest.lng + bounds._northEast.lng) / 2);
  }
}

export default function DrawingNameLabel({ sectionRef, drawing, sectionLabelKey, iconMarkupOpacity }: Props) {
  const [key, setKey] = useState(0);
  const [centerCoordinates, setCenterCoordinates] = useState<any>({lat: 0, lng: 0});

  useEffect(() => {
    const bounds = sectionRef && sectionRef.getBounds();
    if (bounds) {
      setCenterCoordinates(getCenter(bounds));
    }
  }, [sectionLabelKey, sectionRef]);

  useEffect(() => {
    setKey(key + 1);
  }, [sectionLabelKey, sectionRef]);

  const iconMarkup = renderToStaticMarkup(
    <div className={styles.leafletSectionName}>
      {drawing.name}
    </div>,
  );
  const customMarkerIcon = divIcon({
    html: iconMarkup,
  });

  return (
    <Marker
      icon={customMarkerIcon}
      key={key}
      opacity={iconMarkupOpacity}
      position={centerCoordinates}
    />
  );
}
