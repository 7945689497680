import moment from 'moment';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import numberToHoursMinutes from 'Functions/numberToHoursMinutes';

interface Props {    
  hour: number;
}

export default function Hour({ hour }: Props) {
  const date = numberToHoursMinutes(hour);

  const text = Number.isInteger(hour) ? moment(date).format('HH:mm'): '';

  return (   
    <Text className={styles.hour}>
      {text}
    </Text>
  );
}