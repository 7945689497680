import { togetherApi } from "../together-api";
import { Booking, GetLocationBookingsInput, GetLocationFloorBookingsInput, PaginatedAPIResponseBody } from "../types";
import { parseArrayQueryParam, parseObjectQueryParam } from "../../utils";

const bookings = togetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocationFloorBookings: builder.query<PaginatedAPIResponseBody<Booking>, GetLocationFloorBookingsInput>({
      query: ({ locationId, floorId, filter, page, limit, search, orderBy }) => ({
        url: `/api/locations/${locationId}/floors/${floorId}/bookings`,
        params: {
          page,
          limit,
          search,
          orderBy,
          ...parseArrayQueryParam("include", ["user", "desk", "desk.floor"]),
          ...parseObjectQueryParam("filter", filter),
        },
      }),
    }),
    getLocationBookings: builder.query<PaginatedAPIResponseBody<Booking>, GetLocationBookingsInput>({
      query: ({ locationId, filter, page, limit, search, orderBy }) => ({
        url: `/api/locations/${locationId}/bookings`,
        params: {
          page,
          limit,
          search,
          orderBy,
          ...parseArrayQueryParam("include", ["user", "desk", "desk.floor"]),
          ...parseObjectQueryParam("filter", filter),
        },
      }),
    }),
  }),
});

export const {
  useGetLocationFloorBookingsQuery,
  useLazyGetLocationFloorBookingsQuery,
  useGetLocationBookingsQuery,
  useLazyGetLocationBookingsQuery,
} = bookings;
