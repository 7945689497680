import moment from 'moment';
import Text from 'Components/Text';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { Plural, Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'Store/Redux/store';
import translateDynamicVariable from "../../../../../Functions/translateDynamicVariable";
import { WeeklySlot } from 'App/Store/Bookings/createNewBookingDuck/models';
import { Button, darken } from '@mui/material';
import { FromToTimeInput, FromToTimeInputProps } from '../FromToTimeInput';
import { resolvePersistedDate } from 'Store/Utils';
import { blueLight } from 'Theme/variables';
import { darkBlue } from 'Theme/variables';
import { Box } from '@material-ui/core';

export type WeeklySlotInputProps = {
  weeklySlot: WeeklySlot;
  disabled?: boolean;
  onChange: (weeklySlot: WeeklySlot) => void;
}

export const WeeklySlotInput = (props: WeeklySlotInputProps) => {
  const { weeklySlot, onChange, disabled } = props;
  const { day, isSelected, timeFrom, timeTo } = weeklySlot;
  const timeFromDate = resolvePersistedDate(timeFrom);
  const timeToDate = resolvePersistedDate(timeTo);
  
  const handleButtonClick = () => {
    onChange({ ...weeklySlot, isSelected: !isSelected });
  };

  const handleFromToTimeInputChange: FromToTimeInputProps["onChange"] = (value, error) => {
    if (error.from || error.to) {
      onChange({ ...weeklySlot, timeFrom: undefined, timeTo: undefined });
    } else {
      onChange({ ...weeklySlot, timeFrom: value.from, timeTo: value.to });
    }
  };
  
  return (
    <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
      <Box>
        <Button
          disabled={disabled}
          onClick={handleButtonClick}
          size="medium"
          sx={(theme) => ({
            width: 76,
            borderRadius: "5px",
            backgroundColor: isSelected ? blueLight : theme.palette.grey[200],
            color: isSelected ? darkBlue : theme.palette.text.primary,
            textTransform: "capitalize",
            fontWeight: 600,
            fontSize: 14,
            boxShadow: "none",
            ":hover": {
              backgroundColor: isSelected ? darken(blueLight, 0.05) : theme.palette.grey[300],
              boxShadow: "none",
            },
          })}
          variant="contained"
        >
          {translateDynamicVariable(day)}
        </Button>
     </Box>
      <Box>
        <FromToTimeInput
          defaultValue={{ from: new Date(0, 0, 0, 9), to: new Date(0, 0, 0, 18) }}
          disabled={disabled}
          from={timeFromDate}
          onChange={handleFromToTimeInputChange}
          to={timeToDate}
        />
      </Box>
    </Box>
  );
};

export default function WeeklyBooking() {
  const dispatch = useDispatch();
  const locationDisabledDays = useTypedSelector(state => state.locations.locationDisabledDays);
  const { weeklySlots, locationId } = useTypedSelector(state => state.createNewBooking);
  const location = useTypedSelector((state) => state.locations.locations?.find(({ id }) => id === locationId));

  const isDisabled = (index: number): boolean => {
    const start = moment().utc().add(1, 'day').startOf('day');
    const end = moment().utc().add(1, 'day').add(2, 'week').endOf('day');
    const availableDays: string[] = [];

    for (let d = start; d <= end; ) {
      availableDays.push(moment(d).utc().startOf('day').format());
      d = moment(d).utc().startOf('day').add(1, 'day');
    }

    const uniqueAvailableDays: { [key: number]: number } = {};
    const uniqueDisabledDays: { [key: number]: number } = {};

    availableDays
      .map(d => moment(d).day())
      .forEach(el => uniqueAvailableDays[el] = 1  + (uniqueAvailableDays[el] || 0));
    locationDisabledDays
      // @ts-ignore
      .filter((d: moment.MomentInput) => availableDays.includes(moment(d).utc().startOf('day').format()))
      .map((d: moment.MomentInput) => moment(d).utc().startOf('day').day())
      .forEach((el: number) => uniqueDisabledDays[el] = 1  + (uniqueDisabledDays[el] || 0));

    return uniqueDisabledDays[index] >= uniqueAvailableDays[index];
  };

  const handleWeeklySlotInputChange: WeeklySlotInputProps["onChange"] = (weeklySlot) => {
    const newWeeklySlots = weeklySlots.map((currentWeeklySlot) => {
      return currentWeeklySlot.day !== weeklySlot.day ? currentWeeklySlot : weeklySlot;
    });

    dispatch(setCreateNewBookingData({ weeklySlots: newWeeklySlots }));
  };

  return (
    <Box paddingBottom="20px" paddingTop="20px">
      <Box mb={2}>
        <Text size="md" weight="semi-bold">
          <Trans>Select days</Trans>
        </Text>
        <Text color="gray" size="md">
          <Plural
            one="Weekly repeated schedule. You can book only for # week."
            other="Weekly repeated schedule. You can book only for # weeks."
            value={location?.defaultWeekRecurrence}
          />
        </Text>
      </Box>
      <Box>
        {weeklySlots.map((weeklySlot) => (
          <WeeklySlotInput
            disabled={isDisabled(weeklySlot.index)}
            key={weeklySlot.day}
            onChange={handleWeeklySlotInputChange}
            weeklySlot={weeklySlot}
          />
        ))}
      </Box>
    </Box>
  );
}
