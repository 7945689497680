import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { ToastContextProps, ToastOptions } from "./types";
import { Alert, Snackbar } from "@mui/material";

export const ToastContext = React.createContext<ToastContextProps>({
  showToast: () => null,
});

export const useToast = () => React.useContext(ToastContext);

export const ToastProvider: React.FC<PropsWithChildren<unknown>> = (props) => {
  const { children } = props;
  const [toast, setToast] = useState<ToastOptions>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOpen(false);
    }, toast?.duration || 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [toast, open, setOpen]);

  const showToast = useCallback((options: ToastOptions) => {
    const { message, duration, severity } = options;

    setToast({ message, duration, severity });
    
    if (!open) {
      setOpen(true);
    }
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast ? (
        <Snackbar anchorOrigin={{ horizontal: "center", vertical: "bottom" }} open={open}>
          <Alert severity={toast.severity || "info"}>{toast.message}</Alert>
        </Snackbar>
      ) : <></>}
    </ToastContext.Provider>
  );
};