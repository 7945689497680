import Box from 'Components/Box';
import format from 'date-fns/format';
import numberToHoursMinutes from 'Functions/numberToHoursMinutes';
import Select from 'Components/Select';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { Trans } from '@lingui/macro';

interface Props {
  onChangeValues?: ({ timeFrom, timeTo }: { timeFrom: number; timeTo: number; }) => any;
  timeFrom: number;
  timeTo: number
}

/**
 * timeFrom and timeTo are numbers representing hours in percentages.
 * Ex: timeFrom: 1.5 equals 1 hour and 30 minutes
 */
export default function FromToTimeUI({ onChangeValues, timeFrom, timeTo }: Props) {
  // Get the hours and minutes. Ex: 1.5 => 1h 30m
  const { hours: timeFromHours, minutes: timeFromMinutes } = numberToHoursMinutes(timeFrom);
  const { hours: timeToHours, minutes: timeToMinutes } = numberToHoursMinutes(timeTo);

  // These dates are arbitrary, the only parameters needed are the hour and minute, so we can format properly like this: 09:00
  const dateFrom = new Date(2021, 3, 14, timeFromHours, timeFromMinutes);
  const dateTo = new Date(2021, 3, 14, timeToHours, timeToMinutes);

  const timeFromPeriod = timeFrom < 12 ? 'AM' : 'PM';
  const timeToPeriod = timeTo < 12 ? 'AM' : 'PM';

  const hourInitial = 6;
  const hourFinal = 21;

  const arrayHours: Array<{ name: string; value: number }> = [];

  for (let i = hourInitial; i <= hourFinal; i += 0.25) {
    const { hours, minutes } = numberToHoursMinutes(i);

    const date = new Date(2021, 5, 4, hours, minutes);
    const period = i < 12 ? 'AM' : 'PM';

    arrayHours.push({
      name: format(date, 'hh:mm') + ' ' + period,
      value: i,
    });
  }

  return (
    <Box
      alignItems="center"
      className={styles.fromToTimeUI}
      display="grid"
      gap={15}
      gridTemplateColumns="auto auto"
    >
      <Box
        alignItems="center"
        display="grid"
        gap={10}
        gridTemplateColumns="auto auto"
      >
        <Text color="lightGray" overflow="noWrap" size="md">
          <Trans>From</Trans>
        </Text>

        {onChangeValues ? (
          <Select
            classes={{
              input: styles.selectInput,
              text: styles.selectText,
              wrapper: styles.selectWrapper,
            }}
            id="date-from"
            items={arrayHours}
            onChange={(event: any) => onChangeValues({ timeFrom: event.target.value, timeTo })}
            showIconArrow={false}
            value={timeFrom}
          />
        ) : (
          <Box className={styles.hourContainer}>
            <Text>
              {format(dateFrom, 'hh:mm')} {timeFromPeriod}
            </Text>
          </Box>
        )}
      </Box>

      <Box alignItems="center" display="grid" gap={10} gridTemplateColumns="auto auto">
        <Text color="lightGray" overflow="noWrap" size="md">
          <Trans>To</Trans>
        </Text>

        {onChangeValues ? (
          <Select
            classes={{
              input: styles.selectInput,
              text: styles.selectText,
              wrapper: styles.selectWrapper,
            }}
            id="date-from"
            items={arrayHours}
            onChange={(event: any) => onChangeValues({ timeFrom, timeTo: event.target.value })}
            showIconArrow={false}
            value={timeTo}
          />
        ) : (
          <Box className={styles.hourContainer}>
            <Text>
              {format(dateTo, 'hh:mm')} {timeToPeriod}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
}