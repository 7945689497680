import {useDispatch} from "react-redux";
import {useEffect} from "react";
import styles from './styles.module.scss';

import Box from '../../../Components/Box';
import CreateReservationHeader from '../../Components/Header/CreateReservationHeader';
import ReservationJustInTimeDetails from './ReservationJustIntimeDetails';
import ReservationJustInTimeMap from "./ReservationJustInTimeMap";
import ReservationJustInTimeCreated from "./ReservationJustInTimeCreated";
import {
  ErrorUserManagementSnackbar,
  SuccessUserManagementSnackbar,
} from "../UserManagement/SnackBars";
import {getLocations} from "../../../App/Store/Locations/locationsDuck";
import {getListRoles} from "../../Store/userManagement";

export default function ReservationJustInTime() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLocations({ activeOnly: false })); // get locations for booking details list
    dispatch(getListRoles()); // get data for add users form
  }, []);

  return (
    <Box>
      {/* notifications for add users form */}
      <SuccessUserManagementSnackbar />
      <ErrorUserManagementSnackbar />
      {/* end notifications for add users form */}

      <ReservationJustInTimeCreated />

      <CreateReservationHeader />
      <Box className={styles.createReservation} display="flex" justifyContent="between" marginTop={20}>
        <Box className={styles.floorMap}>
          <ReservationJustInTimeMap />
        </Box>

        <Box marginLeft={30} width={370}>
          <ReservationJustInTimeDetails />
        </Box>
      </Box>
    </Box>
  );
}
