import Api from 'Store/Services/Api';
import { GetRoomsResponse } from './models';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_ROOMS,
  GET_ROOMS_FAIL,
  GET_ROOMS_SUCCESS,
  GetRooms,
} from '.';

function* getRooms(action: GetRooms): any {
  try {    
    const payload: GetRoomsResponse = yield call(Api, action, false, true);            

    yield put({ type: GET_ROOMS_SUCCESS, payload });
  } catch (e) {      
    yield put({ type: GET_ROOMS_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_ROOMS, getRooms),
];