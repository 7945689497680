import { ActionPayload, BaseErrorResponse, BaseResponse } from '../../../Store/Models/ReduxModels';
import { t } from '@lingui/macro';
import {
  AttributeModel,
  EditAttributeModel,
  EditAttributeRequest,
  EditAttributeResponse,
  GetAttributesRequest,
  GetAttributesResponse,
} from './models';

export const GET_ATTRIBUTES = 'GET_ATTRIBUTES';
export const GET_ATTRIBUTES_SUCCESS = 'GET_ATTRIBUTES_SUCCESS';
export const GET_ATTRIBUTES_FAIL = 'GET_ATTRIBUTES_FAIL';

export const EDIT_ATTRIBUTES = 'EDIT_ATTRIBUTES';
export const EDIT_ATTRIBUTES_SUCCESS = 'EDIT_ATTRIBUTES_SUCCESS';
export const EDIT_ATTRIBUTES_FAIL = 'EDIT_ATTRIBUTES_FAIL';

// get list of all attributes
export interface GetAttributes {
  type: typeof GET_ATTRIBUTES;
  payload: ActionPayload<GetAttributesRequest>
}

export interface GetAttributesSuccess {
  type: typeof GET_ATTRIBUTES_SUCCESS;
  payload: BaseResponse<GetAttributesResponse>
}

export interface GetAttributesFail {
  type: typeof GET_ATTRIBUTES_FAIL;
  payload: BaseErrorResponse;
}

// edit attribute
export interface EditAttribute {
  type: typeof EDIT_ATTRIBUTES;
  payload: ActionPayload<EditAttributeModel>;
}

export interface EditAttributeSuccess {
  type: typeof EDIT_ATTRIBUTES_SUCCESS;
  payload: BaseResponse<EditAttributeResponse>;
}

export interface EditAttributeFail {
  type: typeof EDIT_ATTRIBUTES_FAIL;
  payload: BaseErrorResponse;
}


export type Actions =
  | GetAttributes
  | GetAttributesSuccess
  | GetAttributesFail
  | EditAttribute
  | EditAttributeSuccess
  | EditAttributeFail


export interface State {
  error: string;
  attributesDesk: AttributeModel[];
  attributesRoom: AttributeModel[];
  loading: boolean;
}

const initialState: State = {
  error: '',
  attributesDesk: [],
  attributesRoom: [],
  loading: false,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case GET_ATTRIBUTES:
      return {
        ...state,
        loading: true,
      };
    case GET_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        attributesDesk: action.payload.data.result.data.deskResources,
        attributesRoom: action.payload.data.result.data.roomResources,
        loading: false,
      };
    case GET_ATTRIBUTES_FAIL:
      return {
        ...state,
        error: t`There was an error. Please try again.`,
        loading: false,
      };

    case EDIT_ATTRIBUTES:
      return {
        ...state,
      };
    case EDIT_ATTRIBUTES_SUCCESS: {
      const attribute = action.payload.data.result.data;
      const newDeskAttributes = [...state.attributesDesk];
      const newRoomAttributes = [...state.attributesRoom];

      const indexDesk = newDeskAttributes.findIndex(q => q.id === attribute.id);
      const indexRoom = newRoomAttributes.findIndex(q => q.id === attribute.id);

      if (indexDesk !== -1) {
        newDeskAttributes[indexDesk] = attribute;
      }

      if (indexRoom !== -1) {
        newRoomAttributes[indexRoom] = attribute;
      }

      return {
        ...state,
        error: '',
        attributesDesk: newDeskAttributes,
        attributesRoom: newRoomAttributes,
      };
    }
    case EDIT_ATTRIBUTES_FAIL:
      return {
        ...state,
        error: t`There was an error. Please try again.`,
      };

    default:
      return state;
  }
}

// Actions
export function getAttributes(data: GetAttributesRequest): GetAttributes {
  return {
    type: GET_ATTRIBUTES,
    payload: {
      request: {
        method: 'GET',
        url: `/api/resources/locations/${data.locationId}/location-resources`,
      },
    },
  };
}

export function editAttribute(data: EditAttributeRequest): EditAttribute {
  return {
    type: EDIT_ATTRIBUTES,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/resources/${data.resourceId}`,
        data: data.attribute,
      },
    },
  };
}

// selectors
export function selectAvailableAttributes(attributes: AttributeModel[]): AttributeModel[] {
  return attributes.filter(attribute => attribute.isAvailable);
}

/**
 * Receives an array of resource ids and return the resources.
 */
export function selectResourcesByIds(attributes: AttributeModel[], resourceIds: string[]): AttributeModel[] {
  const resources = attributes.filter(attribute => resourceIds.includes(attribute.id));

  return resources;
}
