import Box from 'Components/Box';
import TotalByMonth from './TotalByMonth';
import TotalByQuarter from './TotalByQuarter';
import TotalByWeek from './TotalByWeek';
import { getLocationDashboardsTotalCountByPeriod } from 'Admin/Store/locationDashboardDuck';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'Store/Redux/store';

export default function TotalByPeriod() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();

  const { selectedPeriod } = useTypedSelector(state => state.locationDashboard);

  useEffect(() => {
    dispatch(getLocationDashboardsTotalCountByPeriod({ locationId, period: selectedPeriod }));
  }, [locationId, selectedPeriod]);

  const getChart = () => {
    switch (selectedPeriod) {
      case 'month': {
        return <TotalByMonth />;
      }

      case 'quarter': {
        return <TotalByQuarter />;
      }

      case 'week': {
        return <TotalByWeek />;
      }

      default:
        return;
    }
  };

  return (
    <Box>
      {getChart()}
    </Box>
  );
}
