import { CreateOrUpdateEmailTemplateResponse, GetEmailTemplatesResponse } from "./models";

export const createOrUpdateEmailTemplateSuccess: CreateOrUpdateEmailTemplateResponse = {
  result: {
    statusCode: 200,
    data: {
      id: '91db6262-6a65-4d1b-83f0-e2f9627a912a',
      locationId: 'e79ded7f-b76a-4bcd-b942-261decb27e6c',
      type: 'confirmBooking',
      emailNotificationText: '<p>Email notification template text</p>\n',
      emailNotificationTitle: 'Email notification title text',
      pushNotificationText: '',
      pushNotificationTitle: '',
    },
  },
};

export const getEmailTemplatesSuccess: GetEmailTemplatesResponse = {
  result: {
    statusCode: 200,
    data: [
      {
        id: '91db6262-6a65-4d1b-83f0-e2f9627a912a',
        locationId: 'e79ded7f-b76a-4bcd-b942-261decb27e6c',
        type: 'confirmBooking',
        emailNotificationText: '<p>Email notification template text</p>\n',
        emailNotificationTitle: 'Email notification title text',
        pushNotificationText: '',
        pushNotificationTitle: '',
      },
    ],
  },
};
