import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../../Store/Redux/store";
import clsx from "clsx";
import {t} from "@lingui/macro";
import styles from "../StatusFilter/styles.module.scss";

import Box from "../../../../../Components/Box";
import Text from "../../../../../Components/Text";
import {FormControlLabel} from "@material-ui/core";
import CheckboxCustom from "../../../../../Components/CheckBoxCustom";
import {initialFilters, setUserManagementData} from "../../../../Store/userManagement";
import {userRole} from "../../../../Store/userManagement/models";
import FilterAccordion from "../../../../Components/Filters/FilterAccordion";

export default function RolesFilter() {
  const dispatch = useDispatch();
  const { adminUserManagement } = useTypedSelector(state => state);

  const { filters: reduxFilters } = adminUserManagement;

  const localCounter = Object.keys(reduxFilters.roles).map(key => reduxFilters.roles[key as userRole]).filter(status => status.value).length;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserManagementData({
      filters: {
        ...reduxFilters,
        roles: {
          ...reduxFilters.roles,
          [event.target.name]: {
            label: reduxFilters.roles[event.target.name as userRole].label,
            value: event.target.checked,
          },
        },
      },
    }));
  };

  const onResetFilter = () => {
    dispatch(setUserManagementData({
      filters: {
        ...reduxFilters,
        roles: initialFilters.roles,
      },
    }));
  };

  return (
    <Box display="flex" justifyContent="between" marginTop={10}>
      <FilterAccordion headerName={t`Role`} localCounter={localCounter} resetFilter={onResetFilter}>
        <Box className={styles.filtersFormControlWrapper} display="grid">
          {Object.keys(reduxFilters.roles).map((key, index) => {
            const role = reduxFilters.roles[key as userRole];

            return (
              <FormControlLabel
                className={clsx(role.value && styles.checkedLabel)}
                control={(
                  <CheckboxCustom
                    checked={role.value}
                    name={key}
                    onChange={handleChange}
                  />
                )}
                key={`${role.label} ${index}`}
                label={(
                  <Box alignItems='center' display='flex' gap={5} marginStart={3}>
                    <Text size="md" weight='regular'>
                      {role.label}
                    </Text>
                  </Box>
                )}
              />
            );
          })}
        </Box>
      </FilterAccordion>
    </Box>
  );
}
