import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../../Store/Redux/store";
import {UserAccommodation} from "../../../../Store/userManagement/models";
import {initialFilters, setUserManagementData} from "../../../../Store/userManagement";
import {t} from "@lingui/macro";
import Box from "../../../../../Components/Box";
import styles from "../StatusFilter/styles.module.scss";
import {FormControlLabel} from "@material-ui/core";
import clsx from "clsx";
import CheckboxCustom from "../../../../../Components/CheckBoxCustom";
import Text from "../../../../../Components/Text";
import FilterAccordion from "../../../../Components/Filters/FilterAccordion";

export default function AccommodationFilter() {
  const dispatch = useDispatch();
  const { adminUserManagement } = useTypedSelector(state => state);

  const { filters: reduxFilters } = adminUserManagement;

  const localCounter = Object.keys(reduxFilters.accommodation).map(key => reduxFilters.accommodation[key as UserAccommodation]).filter(status => status.value).length;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUserManagementData({
      filters: {
        ...reduxFilters,
        accommodation: {
          ...reduxFilters.accommodation,
          [event.target.name]: {
            label: reduxFilters.accommodation[event.target.name as UserAccommodation].label,
            value: event.target.checked,
          },
        },
      },
    }));
  };

  const onResetFilter = () => {
    dispatch(setUserManagementData({
      filters: {
        ...reduxFilters,
        accommodation: initialFilters.accommodation,
      },
    }));
  };

  return (
    <Box display="flex" justifyContent="between" marginTop={10}>
      <FilterAccordion headerName={t`Accommodation`} localCounter={localCounter} resetFilter={onResetFilter}>
        <Box className={styles.filtersFormControlWrapper} display="grid">
          {Object.keys(reduxFilters.accommodation).map((key, index) => {
            const accommodation = reduxFilters.accommodation[key as UserAccommodation];

            return (
              <FormControlLabel
                className={clsx(accommodation.value && styles.checkedLabel)}
                control={(
                  <CheckboxCustom
                    checked={accommodation.value}
                    name={key}
                    onChange={handleChange}
                  />
                )}
                key={`${accommodation.label} ${index}`}
                label={(
                  <Box alignItems='center' display='flex' gap={5} marginStart={3}>
                    <Text size="md" weight='regular'>
                      {accommodation.label}
                    </Text>
                  </Box>
                )}
              />
            );
          })}
        </Box>
      </FilterAccordion>
    </Box>
  );
}
