import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Box from "../../../../Components/Box";
import SettingsHeader from "../../../Components/Header/SettingsHeader";
import SettingsMobileForm from "./SettingMobileForm";
import { ErrorSettingsMobileSnackbar, SuccessSettingsMobileSnackbar } from "./SnackBars";
import { getSettingsMobileData } from "../../../Store/settingsMobile";

export default function SettingsMobile() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettingsMobileData());
  }, []);

  return (
    <>
      <SettingsHeader />

      <Box marginTop={20}>
        <SettingsMobileForm label="IOS Version Controls" type="ios" />
        <SettingsMobileForm label="Android Version Controls" type="android" />
      </Box>

      <SuccessSettingsMobileSnackbar />
      <ErrorSettingsMobileSnackbar />
    </>
  );
}
