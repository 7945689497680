import styles from "../styles.module.scss";
import {Trans, t} from "@lingui/macro";
import Box from "../../../../Components/Box";
import TextField from "Components/TextField";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { isNumberString } from "class-validator";
import { debounce } from "underscore";
import Info from "Components/Icons/Info";
import { Tooltip } from "@material-ui/core";

interface Props {
  value: number;
  onChange: (value: number) => void;
}

export default function DeskReservationDayLimit(props: Props) {
  const { value, onChange } = props;
  const [limit, setLimit] = useState<number>();

  useEffect(() => {
    setLimit(value);
  }, [value]);

  const debouncedOnChange = useCallback(onChange ? debounce(onChange, 700) : () => {}, [onChange]);

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === "") {
      setLimit(undefined);
      debouncedOnChange?.(1);
    }

    if (!isNumberString(value)) {
      return;
    }

    const limit = Number.parseInt(value);

    if (limit < 1) {
      return;
    }

    if (limit > 365) {
      return;
    }

    setLimit(limit);
    debouncedOnChange?.(limit);
  };

  return (
    <Box className={styles.dayListContainer}>
      <Box alignItems='center' className={styles.dayListContainerLabel} display='flex' gap={10}>
        <div>
          <Tooltip interactive={false} title={t`This change doesn't affect existing reservations`}>
            <span style={{ display: "inline-block", paddingRight: 4 }}>
              <Info size={12} />
            </span>
          </Tooltip>
          <Trans>Desk reservation days limitation (custom calendar)</Trans>:
        </div>
      </Box>
      <TextField id="desk-reservation-day-limit" onChange={handleLimitChange} style={{ width: 120 }} value={limit} />
    </Box>
  );
}
