import { t } from "@lingui/macro";
import { ReservationsList } from "components";
import { useDelegatedId } from "hooks";
import React, { useMemo } from "react";
import { ReservationStatus, ReservationType } from "store";

export const HomePreviousRoute: React.FC = () => {
  const delegatedId = useDelegatedId();
  const now = useMemo(() => new Date(), [delegatedId]);

  return (
    <ReservationsList
      filter={{
        "status": `$in:${ReservationStatus.BOOKED},${ReservationStatus.FAILED}`,
        "schedule.entries.endDate": `$lt:${now.toISOString()}`,
        "type": `$in:${[ReservationType.DESK, ReservationType.VISIT, ReservationType.ROOM].join(",")}`,
      }}
      include={["attendees", "desk", "room", "schedule", "schedule.entries", "floor", "floor.location"]}
      limit={15}
      modifiable={false}
      orderBy={["desc:schedule.entries.startDate"]}
      placeholder={t`No previous bookings`}
      userId={delegatedId}
    />
  );
};
