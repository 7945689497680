import { DragEndEvent } from "@dnd-kit/core";

export const resolveDragEndEventIndexes = (event: DragEndEvent, items: { id: string }[]): [number, number] | undefined => {
  const { active, over } = event;

  if (over?.id && active.id !== over.id) {
    const current = items.findIndex(({ id }) => id === active.id);
    const next = items.findIndex(({ id }) => id === over.id);

    return [current, next];
  }
};
