import { addDays, isAfter, isSameDay } from "date-fns";

export const isDaySelectorDisabled = (
  direction: "next" | "previous",
  day: Date,
  dateToCompare: Date,
  maxDate?: Date,
) => {
  if (direction === "previous") {
    return isSameDay(dateToCompare, day) || isAfter(dateToCompare, day);
  }

  if (maxDate) {
    return isSameDay(day, addDays(maxDate, 1)) || isAfter(day, maxDate);
  }

  return false;
};
