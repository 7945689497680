import Box from 'Components/Box';
import Button from 'Components/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Popup from 'reactjs-popup';
import Text from 'Components/Text';
import { Trans, t } from '@lingui/macro';
import { useTypedSelector } from 'Store/Redux/store';

interface Props {
  onClose: () => any;
  onConfirm: () => any;
  open: boolean;
}

export default function ToggleLocationModal({ onClose, onConfirm, open }: Props) {
  const { config } = useTypedSelector(state => state);

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Popup
      className="modal"
      closeOnDocumentClick
      contentStyle={{ width: 570 }}
      onClose={onClose}
      open={open}
    >
      <Box className="modal-inner">
        <Box className="modal-header">
          <h2>
            <Trans>
              Turn off location
            </Trans>
          </h2>

          <IconButton
            onClick={onClose}
            size="small"
            style={{
              backgroundColor: config.theme.primaryLight,
              borderRadius: 8,
              height: 30,
              width: 30,
            }}
          >
            <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
          </IconButton>
        </Box>

        <Box>
          <Box marginBottom={20}>
            <Text color="gray" size="md" weight="regular">
              <Trans>
                All reservations will be cancelled. Do you want to turn off location?
              </Trans>
            </Text>
          </Box>

          <Box display="flex" justifyContent="end">
            <Button
              aria-label={t`cancel toggle location`}
              name={t`cancel toggle location`}
              onClick={onClose}
              size="sm"
              type="clear"
            >
              <Trans>
                Cancel
              </Trans>
            </Button>

            <Button
              aria-label={t`confirm toggle location`}
              name={t`confirm toggle location`}
              onClick={handleConfirm}
              size="sm"
            >
              <Trans>
                Turn off Location
              </Trans>
            </Button>
          </Box>
        </Box>
      </Box>
    </Popup>
  );
}
