import { togetherApi } from "../together-api";
import { APIResponseBody, Notification } from "../types";

const notifications = togetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyNotificationHistory: builder
      .query<APIResponseBody<{ notifications: Notification[]; page: number; totalPages: number }>, { page?: number; limit?: number }>({
        query: (params) => ({ url: "/api/users/me/notifications-history", params }),
      }),
  }),
});

export const {
  useGetMyNotificationHistoryQuery,
  useLazyGetMyNotificationHistoryQuery,
} = notifications;
