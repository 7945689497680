import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherRoom: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <g transform="matrix(.57143 0 0 .57143 -4.571 -4.571)">
        <path
          d="M11 9h22a2 2 0 0 1 2 2v24H9V11a2 2 0 0 1 2-2z"
          fill="none"
          fillRule="evenodd"
          stroke={props.stroke}
          strokeOpacity="1"
          strokeWidth="3"
        />
        <rect
          fill="none"
          fillRule="evenodd"
          height="18"
          rx="1"
          stroke={props.stroke}
          strokeOpacity="1"
          strokeWidth="3"
          width="10"
          x="17"
          y="17"
        />
        <rect
          fill="none"
          fillRule="evenodd"
          height="2"
          rx="1"
          stroke={props.stroke}
          strokeOpacity="1"
          strokeWidth="2"
          width="3"
          x="24"
          y="25"
        />
      </g>
    </SvgIcon>
  );
};
