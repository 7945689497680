import { Tabs as MUITabs, styled } from "@mui/material";
import { TabsProps } from "./types";

export const TextTabs = styled((props: TabsProps) => <MUITabs {...props} />)(() => ({
  minHeight: 0,
  "& .MuiTabs-indicator": {
    height: 3,
    borderRadius: 3,
  },
}));
