import Fuse from 'fuse.js';
import getErrorMessageFromPayload from 'Store/Redux/Helpers/getErrorMessageFromPayload';
import { ActionPayload, BaseErrorResponse, BaseResponse } from '../../../Store/Models/ReduxModels';
import { t } from '@lingui/macro';
import {
  AddUserRequest,
  AddUserResponse,
  AllUsersFiltersModel,
  ApproveOrDenyUserDocumentRequest,
  ApproveOrDenyUserDocumentRequestBody,
  ApproveOrDenyUserDocumentRequestParams,
  ApproveOrDenyUserDocumentResponse,
  CountryModel,
  DeleteUserRequest,
  DeleteUserResponse,
  ExportUsersRequest,
  ExportUsersResponse,
  GetAllUsersRequest,
  GetAllUsersResponse,
  GetCountriesFilterRequest,
  GetCountriesFilterResponse,
  GetListRolesResponse,
  GetUsersFromADRequest,
  GetUsersFromADResponse,
  SetUserManagementDataRequest,
  UpdateLocationsForLocalAdminModel,
  UpdateLocationsForLocalAdminRequest,
  UpdateLocationsForLocalAdminResponse,
  UpdateUserAccommodationModel,
  UpdateUserAccommodationRequest,
  UpdateUserAccommodationResponse,
  UpdateUserRoleRequest,
  UpdateUserRoleResponse,
  UserAdModel,
  UserManagementUser,
  UserManagemetFiltersModel,
  UserRoleModel,
} from './models';

export const APPROVE_OR_DENY_USER_DOCUMENT = 'APPROVE_OR_DENY_USER_DOCUMENT';
export const APPROVE_OR_DENY_USER_DOCUMENT_SUCCESS = 'APPROVE_OR_DENY_USER_DOCUMENT_SUCCESS';
export const APPROVE_OR_DENY_USER_DOCUMENT_FAIL = 'APPROVE_OR_DENY_USER_DOCUMENT_FAIL';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const GET_AVAILABLE_USERS_FROM_AD = 'GET_AVAILABLE_USERS_FROM_AD';
export const GET_AVAILABLE_USERS_FROM_AD_SUCCESS = 'GET_AVAILABLE_USERS_FROM_AD_SUCCESS';
export const GET_AVAILABLE_USERS_FROM_AD_FAIL = 'GET_AVAILABLE_USERS_FROM_AD_FAIL';

export const GET_COUNTRIES_FILTER = 'GET_COUNTRIES_FILTER';
export const GET_COUNTRIES_FILTER_SUCCESS = 'GET_COUNTRIES_FILTER_SUCCESS';
export const GET_COUNTRIES_FILTER_FAIL = 'GET_COUNTRIES_FILTER_FAIL';

export const GET_LIST_ROLES = 'GET_LIST_ROLES';
export const GET_LIST_ROLES_SUCCESS = 'GET_LIST_ROLES_SUCCESS';
export const GET_LIST_ROLES_FAIL = 'GET_LIST_ROLES_FAIL';

export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_FAIL = 'UPDATE_USER_ROLE_FAIL';

export const UPDATE_LOCATIONS_FOR_LOCAL_ADMIN = 'UPDATE_LOCATIONS_FOR_LOCAL_ADMIN';
export const UPDATE_LOCATIONS_FOR_LOCAL_ADMIN_SUCCESS = 'UPDATE_LOCATIONS_FOR_LOCAL_ADMIN_SUCCESS';
export const UPDATE_LOCATIONS_FOR_LOCAL_ADMIN_FAIL = 'UPDATE_LOCATIONS_FOR_LOCAL_ADMIN_FAIL';

export const UPDATE_USER_ACCOMMODATION = 'UPDATE_USER_ACCOMMODATION';
export const UPDATE_USER_ACCOMMODATION_SUCCESS = 'UPDATE_USER_ACCOMMODATION_SUCCESS';
export const UPDATE_USER_ACCOMMODATION_FAIL = 'UPDATE_USER_ACCOMMODATION_FAIL';

export const EXPORT_USERS = 'EXPORT_USERS';
export const EXPORT_USERS_FAIL = 'EXPORT_USERS_FAIL';
export const EXPORT_USERS_SUCCESS = 'EXPORT_USERS_SUCCESS';

export const CLEAR_USER_MANAGEMENT_FILTERS = 'CLEAR_USER_MANAGEMENT_FILTERS';
export const SET_USER_MANAGEMENT_DATA = 'SET_USER_MANAGEMENT_DATA';

export interface ApproveOrDenyUserDocument {
  type: typeof APPROVE_OR_DENY_USER_DOCUMENT;
  payload: ActionPayload<ApproveOrDenyUserDocumentRequestBody>;
  params: ApproveOrDenyUserDocumentRequestParams;
}
export interface ApproveOrDenyUserDocumentSuccess {
  type: typeof APPROVE_OR_DENY_USER_DOCUMENT_SUCCESS;
  payload: BaseResponse<ApproveOrDenyUserDocumentResponse>;
  request: ApproveOrDenyUserDocumentRequest;
}
export interface ApproveOrDenyUserDocumentFail {
  type: typeof APPROVE_OR_DENY_USER_DOCUMENT_FAIL;
  payload: BaseErrorResponse;
}

// get list of all users
export interface GetAllUsers {
  type: typeof GET_ALL_USERS;
  payload: ActionPayload<GetAllUsersRequest>
}

export interface GetAllUsersSuccess {
  type: typeof GET_ALL_USERS_SUCCESS;
  payload: BaseResponse<GetAllUsersResponse>
}

export interface GetAllUsersFail {
  type: typeof GET_ALL_USERS_FAIL;
  payload: BaseErrorResponse;
}

export interface GetCountriesFilter {
  type: typeof GET_COUNTRIES_FILTER;
  payload: ActionPayload<GetCountriesFilterRequest>
}

export interface GetCountriesFilterSuccess {
  type: typeof GET_COUNTRIES_FILTER_SUCCESS;
  payload: BaseResponse<GetCountriesFilterResponse>
}

export interface GetCountriesFilterFail {
  type: typeof GET_COUNTRIES_FILTER_FAIL;
  payload: BaseErrorResponse;
}

// add user
export interface AddUser {
  type: typeof ADD_USER;
  payload: ActionPayload<AddUserRequest>;
  updateLocationsRequest: UpdateLocationsForLocalAdminModel;
}
export interface AddUserSuccess {
  type: typeof ADD_USER_SUCCESS;
  payload: BaseResponse<AddUserResponse>;
}
export interface AddUserFail {
  type: typeof ADD_USER_FAIL;
  payload: BaseErrorResponse;
}

// delete user
export interface DeleteUser {
  type: typeof DELETE_USER;
  payload: ActionPayload<DeleteUserRequest>;
}
export interface DeleteUserSuccess {
  type: typeof DELETE_USER_SUCCESS;
  payload: BaseResponse<DeleteUserResponse>;
}
export interface DeleteUserFail {
  type: typeof DELETE_USER_FAIL;
  payload: BaseErrorResponse;
}

// update user role
export interface UpdateUserRole {
  type: typeof UPDATE_USER_ROLE;
  payload: ActionPayload<UpdateUserRoleRequest>;
}
export interface UpdateUserRoleSuccess {
  type: typeof UPDATE_USER_ROLE_SUCCESS;
  payload: BaseResponse<UpdateUserRoleResponse>;
}
export interface UpdateUserRoleFail {
  type: typeof UPDATE_USER_ROLE_FAIL;
  payload: BaseErrorResponse;
}

// get list of roles
export interface GetListRoles {
  type: typeof GET_LIST_ROLES;
  payload: ActionPayload<null>;
}
export interface GetListRolesSuccess {
  type: typeof GET_LIST_ROLES_SUCCESS;
  payload: BaseResponse<GetListRolesResponse>;
}
export interface GetListRolesFail {
  type: typeof GET_LIST_ROLES_FAIL;
  payload: BaseErrorResponse;
}

// get list of available users from AD
export interface GetUsersFromAD {
  type: typeof GET_AVAILABLE_USERS_FROM_AD;
  payload: ActionPayload<GetUsersFromADRequest>
}

export interface GetUsersFromADSuccess {
  type: typeof GET_AVAILABLE_USERS_FROM_AD_SUCCESS;
  payload: BaseResponse<GetUsersFromADResponse>
}

export interface GetUsersFromADFail {
  type: typeof GET_AVAILABLE_USERS_FROM_AD_FAIL;
  payload: BaseErrorResponse;
}

// update locations for local admin
export interface UpdateLocationsForLocalAdmin {
  type: typeof UPDATE_LOCATIONS_FOR_LOCAL_ADMIN;
  payload: ActionPayload<UpdateLocationsForLocalAdminRequest>
}

export interface UpdateLocationsForLocalAdminSuccess {
  type: typeof UPDATE_LOCATIONS_FOR_LOCAL_ADMIN_SUCCESS;
  payload: BaseResponse<UpdateLocationsForLocalAdminResponse>
}

export interface UpdateLocationsForLocalAdminFail {
  type: typeof UPDATE_LOCATIONS_FOR_LOCAL_ADMIN_FAIL;
  payload: BaseErrorResponse;
}

// update user accommodation
export interface UpdateUserAccommodation {
  type: typeof UPDATE_USER_ACCOMMODATION;
  payload: ActionPayload<UpdateUserAccommodationRequest>;
}
export interface UpdateUserAccommodationSuccess {
  type: typeof UPDATE_USER_ACCOMMODATION_SUCCESS;
  payload: BaseResponse<UpdateUserAccommodationResponse>;
}
export interface UpdateUserAccommodationFail {
  type: typeof UPDATE_USER_ACCOMMODATION_FAIL;
  payload: BaseErrorResponse;
}

// export users in csv
export interface ExportUsers {
  type: typeof EXPORT_USERS;
  payload: ActionPayload<ExportUsersRequest>;
}
export interface ExportUsersFail {
  type: typeof EXPORT_USERS_FAIL;
  payload: BaseErrorResponse;
}
export interface ExportUsersSuccess {
  type: typeof EXPORT_USERS_SUCCESS;
  payload: ExportUsersResponse;
}

// set data
export interface ClearUserManagementFilters {
  type: typeof CLEAR_USER_MANAGEMENT_FILTERS;
}
export interface SetUserManagementData {
  type: typeof SET_USER_MANAGEMENT_DATA;
  payload: SetUserManagementDataRequest;
}

export type Actions =
  | ApproveOrDenyUserDocument
  | ApproveOrDenyUserDocumentSuccess
  | ApproveOrDenyUserDocumentFail
  | ClearUserManagementFilters
  | SetUserManagementData
  | GetAllUsers
  | GetAllUsersSuccess
  | GetAllUsersFail
  | GetCountriesFilter
  | GetCountriesFilterSuccess
  | GetCountriesFilterFail
  | AddUser
  | AddUserSuccess
  | AddUserFail
  | DeleteUser
  | DeleteUserSuccess
  | DeleteUserFail
  | UpdateUserRole
  | UpdateUserRoleSuccess
  | UpdateUserRoleFail
  | GetListRoles
  | GetListRolesSuccess
  | GetListRolesFail
  | GetUsersFromAD
  | GetUsersFromADSuccess
  | GetUsersFromADFail
  | ExportUsers
  | ExportUsersFail
  | ExportUsersSuccess
  | UpdateLocationsForLocalAdmin
  | UpdateLocationsForLocalAdminSuccess
  | UpdateLocationsForLocalAdminFail
  | UpdateUserAccommodation
  | UpdateUserAccommodationSuccess
  | UpdateUserAccommodationFail;

export interface State {
  error: string;
  successMessage: string;
  loading: boolean;
  countries: CountryModel[];
  limit: number;
  page: number;
  totalPages: number;
  availableUsersAd: UserAdModel[];
  availableUsersAdTotalCount: number;
  userRoles: UserRoleModel[];
  allUsers: UserManagementUser[];
  allUsersFilters: AllUsersFiltersModel;
  filters: UserManagemetFiltersModel;
}

export const initialFilters: UserManagemetFiltersModel = {
  applyFilters: false,
  cities: [],
  countries: [],
  status: {
    'approved': {
      label: t`Approved`,
      value: false,
    },
    'needsApproval': {
      label: t`Needs Approval`,
      value: false,
    },
    'notUploaded': {
      label: t`Not uploaded`,
      value: false,
    },
    'denied': {
      label: t`Denied`,
      value: false,
    },
    'expired': {
      label: t`Expired`,
      value: false,
    },
  },
  roles: {
    'User': {
      label: t`User`,
      value: false,
    },
    'Super Admin': {
      label: t`Super Admin`,
      value: false,
    },
    'Local Admin': {
      label: t`Local Admin`,
      value: false,
    },
    'HR': {
      label: t`HR`,
      value: false,
    },
    'Reservation Manager': {
      label: t`Reservation Manager`,
      value: false,
    },
    'Delegate': {
      label: t`Delegate`,
      value: false,
    },
    'Executive Assistant': {
      label: t`Executive Assistant`,
      value: false,
    },
  },
  accommodation: {
    'approved': {
      label: t`Approved`,
      value: false,
    },
    'notApproved': {
      label: t`Not Approved`,
      value: false,
    },
  },
};
const initialState: State = {
  error: '',
  successMessage: '',
  loading: false,
  countries: [],
  limit: 10,
  page: 1,
  totalPages: 1,
  availableUsersAd: [],
  availableUsersAdTotalCount: 0,
  userRoles: [],
  allUsers: [],
  allUsersFilters: {
    search: '',
  },
  filters: initialFilters,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case APPROVE_OR_DENY_USER_DOCUMENT: {
      return {
        ...state,
        error: '',
      };
    }
    case APPROVE_OR_DENY_USER_DOCUMENT_SUCCESS: {
      const updatedDocument = action.payload.data.result.data;

      return {
        ...state,
        error: '',
        successMessage: action.request.queryParams.approve ? t`Document approved` : t`Document denied`,
        allUsers: state.allUsers.map(user => {

          if (user.userDocument?.id === updatedDocument.id) {
            return {
              ...user,
              userDocument: user.userDocument ? updatedDocument : null,
            };
          }

          return {
            ...user,
          };
        }),
      };
    }
    case APPROVE_OR_DENY_USER_DOCUMENT_FAIL: {
      return {
        ...state,
        error: getErrorMessageFromPayload({ payload: action.payload, fallbackMessage: t`There was an error approving/denying document` }),
      };
    }

    case CLEAR_USER_MANAGEMENT_FILTERS: {
      return {
        ...state,
        filters: {
          ...initialFilters,
          applyFilters: true,
        },
      };
    }

    case GET_ALL_USERS:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_USERS_SUCCESS: {
      const data = action.payload.data.result.data;

      return {
        ...state,
        loading: false,
        allUsers: data.users,
        limit: data.limit,
        page: data.page,
        totalPages: data.totalPages,
        filters: {
          ...state.filters,
          applyFilters: false,
        },
      };
    }
    case GET_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: t`There was an error getting all users. Please try again.`,
        filters: {
          ...state.filters,
          applyFilters: false,
        },
      };

    case GET_COUNTRIES_FILTER:
      return {
        ...state,
        error: '',
      };
    case GET_COUNTRIES_FILTER_SUCCESS: {
      return {
        ...state,
        countries: action.payload.data.result.data,
      };
    }
    case GET_COUNTRIES_FILTER_FAIL:
      return {
        ...state,
        error: t`There was an error getting countries filter. Please try again.`,
      };

    case DELETE_USER_SUCCESS: {
      const deleteUserId = action.payload.data.result.data.id;
      const newUsers = [...state.allUsers].filter(q => q.id !== deleteUserId);

      return {
        ...state,
        successMessage: t`User was deleted.`,
        allUsers: newUsers,
      };
    }
    case DELETE_USER_FAIL:
      return {
        ...state,
        error: t`There was an error deleting users. Please try again.`,
      };

    case ADD_USER_SUCCESS: {
      const newUser = action.payload.data.result.data;
      const newUsers = [newUser, ...state.allUsers];

      return {
        ...state,
        successMessage: t`Users was added.`,
        allUsers: newUsers,
      };
    }
    case ADD_USER_FAIL: {
      let errorText = t`There was an error adding users. Please try again.`;
      const errorMessage = action.payload?.error?.message;

      if (errorMessage) {
        errorText = t`There was an error adding users.` + ` ${errorMessage}. ` + t`Please try again.`;
      }

      return {
        ...state,
        error: errorText,
      };
    }

    case UPDATE_USER_ROLE_SUCCESS: {
      const user = action.payload.data.result.data;
      const newUsers = [...state.allUsers];
      const index = newUsers.findIndex(u => u.id === user.id);
      newUsers[index] = user;

      return {
        ...state,
        successMessage: t`User role was updated.`,
        allUsers: newUsers,
      };
    }
    case UPDATE_USER_ROLE_FAIL:
      return {
        ...state,
        error: t`There was an error updating user role. Please try again.`,
      };

    case GET_LIST_ROLES_SUCCESS: {
      return {
        ...state,
        userRoles: action.payload.data.result.data,
      };
    }
    case GET_LIST_ROLES_FAIL:
      return {
        ...state,
        error: t`There was an error loading user roles. Please try again.`,
      };

    case GET_AVAILABLE_USERS_FROM_AD_SUCCESS: {
      return {
        ...state,
        availableUsersAd: action.payload.data.result.data.users,
        availableUsersAdTotalCount: action.payload.data.result.data.totalCount,
      };
    }
    case GET_AVAILABLE_USERS_FROM_AD_FAIL:
      return {
        ...state,
        error: t`There was an error loading users from AD. Please try again.`,
      };

    case UPDATE_LOCATIONS_FOR_LOCAL_ADMIN_SUCCESS: {
      const { id, locations } = action.payload.data.result.data;
      const newUsers = [...state.allUsers];
      const user = newUsers.find(user => user.id === id);

      if (user) {
        const index = newUsers.findIndex(u => u.id === user.id);
        user.locationIds = locations;
        newUsers[index] = user;
      }

      return {
        ...state,
        successMessage: t`User locations was updated.`,
        allUsers: newUsers,
      };
    }
    case UPDATE_LOCATIONS_FOR_LOCAL_ADMIN_FAIL:
      return {
        ...state,
        error: t`There was an error updating user locations. Please try again.`,
      };

    case UPDATE_USER_ACCOMMODATION_SUCCESS: {
      const newUsers = [...state.allUsers];
      const user = action.payload.data.result.data;

      if (user) {
        const index = newUsers.findIndex(u => u.id === user.id);
        newUsers[index].approvedAccommodation = user.approvedAccommodation;
      }

      return {
        ...state,
        successMessage: t`User accommodation was updated.`,
        allUsers: newUsers,
      };
    }
    case UPDATE_USER_ACCOMMODATION_FAIL:
      return {
        ...state,
        error: t`There was an error updating user accommodation. Please try again.`,
      };

    case SET_USER_MANAGEMENT_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// Actions
export function approveOrDenyUserDocument(data: ApproveOrDenyUserDocumentRequest): ApproveOrDenyUserDocument {
  return {
    type: APPROVE_OR_DENY_USER_DOCUMENT,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/users/${data.params.documentId}/document/${data.params.userId}/approve/?approve=${data.queryParams.approve}`,
        data: data.queryParams,
      },
    },
    params: data.params,
  };
}

export function clearUserManagementFilters(): ClearUserManagementFilters {
  return {
    type: CLEAR_USER_MANAGEMENT_FILTERS,
  };
}

export function setUserManagementData(data: SetUserManagementDataRequest): SetUserManagementData {
  return {
    type: SET_USER_MANAGEMENT_DATA,
    payload: data,
  };
}

export function getAllUsers(data: GetAllUsersRequest): GetAllUsers {
  return {
    type: GET_ALL_USERS,
    payload: {
      request: {
        method: 'GET',
        url: '/api/users',
        data,
      },
    },
  };
}

export function getCountriesFilter(data: GetCountriesFilterRequest): GetCountriesFilter {
  return {
    type: GET_COUNTRIES_FILTER,
    payload: {
      request: {
        method: 'GET',
        url: '/api/users/country',
        data,
      },
    },
  };
}

export function getUsersFromAD(data: GetUsersFromADRequest): GetUsersFromAD {
  let url = `/api/users/email?limit=${data.limit}`;

  if (data.search) {
    url += `&search=${data.search}`;
  }

  return {
    type: GET_AVAILABLE_USERS_FROM_AD,
    payload: {
      request: {
        method: 'GET',
        url,
      },
    },
  };
}

export function addUser(data: AddUserRequest, updateLocationsRequest: UpdateLocationsForLocalAdminModel): AddUser {
  return {
    type: ADD_USER,
    payload: {
      request: {
        method: 'POST',
        url: `/api/users/admin`,
        data,
      },
    },
    updateLocationsRequest,
  };
}

export function deleteUser(data: DeleteUserRequest): DeleteUser {
  return {
    type: DELETE_USER,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/users/${data.userId}`,
      },
    },
  };
}

export function updateUserRole(data: UpdateUserRoleRequest): UpdateUserRole {
  return {
    type: UPDATE_USER_ROLE,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/users/role`,
        data,
      },
    },
  };
}

export function updateUserAccommodation(data: UpdateUserAccommodationModel): UpdateUserAccommodation {
  return {
    type: UPDATE_USER_ACCOMMODATION,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/users/${data.userId}/approveAccommodation`,
        data,
      },
    },
  };
}

export function getListRoles(): GetListRoles {
  return {
    type: GET_LIST_ROLES,
    payload: {
      request: {
        method: 'GET',
        url: `/api/roles`,
      },
    },
  };
}

export function updateUserLocations(data: UpdateLocationsForLocalAdminModel): UpdateLocationsForLocalAdmin {
  return {
    type: UPDATE_LOCATIONS_FOR_LOCAL_ADMIN,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/roles/${data.userId}`,
        data: { locationIds: data.locationIds },
      },
    },
  };
}

export function exportUsers(data: ExportUsersRequest): ExportUsers {
  return {
    type: EXPORT_USERS,
    payload: {
      request: {
        method: 'GET',
        url: '/api/files/users',
        data,
        isUploadFile: true,
      },
    },
  };
}

// selectors
export function selectUserManagementCities(state: State, search = ''): string[] {
  let allCities: string[] = [];

  state.countries.forEach(country => {
    // If there's a country selected, return only cities from that country
    if (state.filters.countries[0]) {
      if (state.filters.countries[0] === country.country) {
        allCities = [...allCities, ...country.cities];
      }
    } else {
      allCities = [...allCities, ...country.cities];
    }
  });

  allCities = allCities.filter((city, index) => allCities.indexOf(city) === index);

  const fuse = new Fuse(allCities, { minMatchCharLength: 1 });

  return search ? fuse.search(search).map(i => i.item) : allCities;
}

export function selectUserManagementCountries(state: State, search = ''): string[] {
  const allCountries = state.countries.map(c => c.country);

  const fuse = new Fuse(allCountries, { minMatchCharLength: 1 });

  return search ? fuse.search(search).map(i => i.item) : allCountries;
}
