import { Box, Button, Popover, Skeleton, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { TogetherUser } from "../icons";
import { useGetMeQuery } from "store";
import { t } from "@lingui/macro";
import { isDelegatedAccessRole } from "utils";
import { TextButton } from "../button";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { UserSelect, UserSelectProps } from "../select";
import { useTypedSelector } from "Store/Redux/store";
import { useDispatch } from "react-redux";
import { setExecutiveAssistantData } from "App/Store/Users/executiveAssistant";
import { UserManagementUser } from "Admin/Store/userManagement/models";

export const UserGreetings: React.FC = () => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const selectedUser = useTypedSelector(({ executiveAssistant }) => executiveAssistant.selectedUser);
  const getMeQuery = useGetMeQuery();
  const { data: getMeResponse, isLoading: isLoadingMe, isFetching: isFetchingMe } = getMeQuery;
  const { data: user } = getMeResponse?.result || {};
  const hasDelegatedAccess = isDelegatedAccessRole(user?.role);

  const handleUserSelect: UserSelectProps["onChange"] = (value) => {
    if (value && value.id !== user?.id) {
      dispatch(setExecutiveAssistantData({ selectedUser: { ...value, role: { id: "", name: value.role } } as UserManagementUser }));
    } else if (selectedUser) {
      dispatch(setExecutiveAssistantData({ selectedUser: null }));
    }

    setAnchorEl(null);
  };

  const handleSwitchClick = () => {
    if (selectedUser) {
      dispatch(setExecutiveAssistantData({ selectedUser: null }));
    }

    setAnchorEl(null);
  };

  return (
    <Box display="flex">
      {isLoadingMe || isFetchingMe ? (
        <Skeleton height={36} variant="rectangular" width={36} />
      ) : (
        <Button
          component={Link}
          disableElevation
          sx={{
            padding: 0,
            minWidth: 36,
            width: 36,
            height: 36,
          }}
          to="/profile"
          variant="contained"
        >
          <TogetherUser fill="white" stroke="white" sx={{ width: 16 }} />
        </Button>
      )}
      <Box ml={1}>
        {isLoadingMe || isFetchingMe ? (
          <>
            <Skeleton height={14} sx={{ mb: 0.5 }} variant="rectangular" width={170} />
            <Skeleton height={22} variant="rectangular" width={180} />
          </>
        ) : (
          <>
            <Typography color={palette.grey[700]} fontSize={14} fontWeight="600" lineHeight={1} mb={0.5}>
              {hasDelegatedAccess ? t`This is account of` : t`Hello ${user?.name}`}
            </Typography>
            {
              hasDelegatedAccess ? (
                <>
                  <TextButton onClick={(event) => setAnchorEl(event.currentTarget)} sx={{ fontSize: 22, lineHeight: 1 }} >
                    {selectedUser?.name || user?.name}
                    {anchorEl ? <KeyboardArrowUp color="primary" /> : <KeyboardArrowDown color="primary" />}
                  </TextButton>
                  <Popover
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    keepMounted
                    onClose={() => setAnchorEl(null)}
                    open={!!anchorEl}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                  >
                    <Box minWidth={420} padding={2}>
                      <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
                        <Typography color={palette.grey[700]} fontSize={14}>{t`Switch to account`}</Typography>
                        <Button onClick={handleSwitchClick} size="small">{t`Switch to my account`}</Button>
                      </Box>
                      <UserSelect
                        onChange={handleUserSelect}
                        value={selectedUser ? { id: selectedUser.id, name: selectedUser.name, email: selectedUser.email } : null}
                      />
                    </Box>
                  </Popover>  
                </>
              ) : (
                <Typography fontSize={22} fontWeight="600" lineHeight={1}>{t`Welcome back`}</Typography>
              )
            }
          </>
        )}
      </Box>
    </Box>
  );
};
