import BookingDetails from '../BookingDetails';
import Box from 'Components/Box';
import Breadcrumbs from 'App/Components/Breadcrumbs';
import Calendar from '../Calendar';
import Filters from '../Filters';
import FloorItem, { FloorItemLoading } from './FloorItem';
import format from 'date-fns/format';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { getFloors } from 'App/Store/Locations/locationsDuck';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { StaggeredItems } from 'Components/Animations/StaggeredItems';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import {useCallback, useEffect} from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import bookingStyles from "../styles.module.scss";
import Divider from "../../../../Components/Divider";
import { debounce } from 'underscore';
import { setLoading } from 'App/Store/Locations/locationsDuck';
import { ReduxStore } from 'Store/Redux';
import { weeklySlotsToWeeklyBooking } from 'App/Store/Utils';

interface FloorProps {
  type?: 'support' | 'desk';
}

export default function Floor({ type = 'desk' }: FloorProps) {
  const dispatch = useDispatch();
  const { createNewBooking, locations: locationsDuck } = useTypedSelector(state => state);

  const {
    bookingType,
    dateFrom,
    dateTo,
    filters,
    floorId,
    locationId,
    timeFrom,
    timeTo,
    weeklySlots,
  } = createNewBooking;
  const { floors, loading } = locationsDuck;
  const handleFloorsRequest = (
    {
      bookingType,
      dateFrom,
      dateTo,
      filters,
      locationId,
      timeFrom,
      timeTo,
      weeklySlots,
    }: Partial<ReduxStore["createNewBooking"]>,
  ) => {
    switch (bookingType) {
      case "custom": {
        if (locationId && dateFrom && dateTo && timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          dispatch(getFloors({
            data: {
              custom: {
                dateFrom: dateFrom ? format(new Date(dateFrom), 'yyyy-MM-dd') : '',
                dateTo: dateTo ? format(new Date(dateTo), 'yyyy-MM-dd') : '',
                timeFrom: timeFromFormatted,
                timeTo: timeToFormatted,
              },
              resources: Object.keys(filters || {}).filter(filterName => filters?.[filterName]),
              onlyAvailableDesks: false,
            },
            locationId,
          }));
        }
        break;
      }
      case "daily": {
        if (locationId && timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          dispatch(getFloors({
            data: {
              daily: { timeFrom: timeFromFormatted, timeTo: timeToFormatted },
              resources: Object.keys(filters || {}).filter(filterName => filters?.[filterName]),
              onlyAvailableDesks: false,
            },
            locationId,
          }));
        }
        break;
      }
      case "weekly": {
        if (locationId && dateFrom && dateTo && timeFrom && timeTo && weeklySlots) {
          dispatch(getFloors({
            data: {
              weekly: weeklySlotsToWeeklyBooking(weeklySlots),
              resources: Object.keys(filters || {}).filter(filterName => filters?.[filterName]),
              onlyAvailableDesks: false,
            },
            locationId,
          }));
        }
        break;
      }
      default:
        break;
    }
  };
  const debouncedHandleFloorsRequest = useCallback(debounce(handleFloorsRequest, 900), []);

  useEffect(() => {
    dispatch(setLoading(true));
    debouncedHandleFloorsRequest({
      bookingType,
      dateFrom,
      dateTo,
      filters,
      locationId,
      timeFrom,
      timeTo,
      weeklySlots,
    });
  }, [dateFrom, dateTo, timeFrom, timeTo, filters, locationId, bookingType, weeklySlots]);

  // clear selected floor if floor has no available desks (it can be after changing date)
  useEffect(() => {
    if (floors.length) {
      const selectedFloor = floors.find(floor => floor.id === floorId);
      const noSelectedDesks = selectedFloor?.sitDesks === 0;

      if (noSelectedDesks) {
        dispatch(setCreateNewBookingData({ floorId: undefined }));
      }
    }
  }, [floors]);

  const onChangeFloor = (floorId: string) => {
    dispatch(setCreateNewBookingData({ floorId }));
  };

  return (
    <Box display="grid" gap={30} gridTemplateColumns="8fr 4fr" paddingBottom={50}>
      <Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="between"
          marginBottom={15}
        >
          <Breadcrumbs type={type} />

          <Filters />
        </Box>

        {loading ? (
          <FloorItemLoading />
        ) : (
          <Box className={styles.floorList} dataTestId="floor-list" display="grid">
            {floors.length > 0 ? (
              <StaggeredItems className={styles.items}>
                {floors.map((floor, index) => (
                  <FloorItem
                    {...floor}
                    key={floor.id + index}
                    onClick={onChangeFloor}
                    selected={floorId === floor.id}
                  />
                ))}
              </StaggeredItems>
            ) : (
              <Box
                alignItems="center"
                display="flex"
                height="100%"
                justifyContent="center"
                margin="20px 0 100px"
              >
                <Text align="center" color="gray" size="md">
                  {t`There're no floors for this location`}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Box>

      <Box className={bookingStyles.stickyBookingWrapper}>
        <Box marginBottom={15}>
          <Text size="xlg" weight="semi-bold">
            <Trans>Booking details</Trans>
          </Text>
        </Box>

        <Calendar />

        <Box className={bookingStyles.stickyBookingRow} marginTop={10}>
          <Box marginBottom={10}>
            <Divider />
          </Box>
          <BookingDetails type={type} />
        </Box>
      </Box>
    </Box >
  );
}
