import React, { useRef, useState } from "react";
import { BasicImageCarouselProps } from "./types";
import { Box, Button, useTheme } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useIntersectionObserver } from "usehooks-ts";
import { useStorageFileObjectURL } from "hooks";


const Image: React.FC<{ height: number; url: string; width: number; index: number; currentIndex: number }> = (props) => {
  const { height, url, width, index, currentIndex } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const objectURL = useStorageFileObjectURL(!!entry?.isIntersecting && index === currentIndex ? url : undefined);

  return (
    <Box
      height={height}
      ref={ref}
      sx={{ backgroundImage: objectURL ? `url('${objectURL}')` : undefined, backgroundSize: "cover" }}
      width={width}
    />
  );
};

export const BasicImageCarousel: React.FC<BasicImageCarouselProps> = (props) => {
  const { height, width, images } = props;
  const theme = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <Box height={height} overflow="hidden" position="relative" width={width}>
      <Box display="flex" left={(width * currentIndex) * -1} position="absolute" top={0} width={width * images.length}>
        {images.map((url, index) => <Image currentIndex={currentIndex} height={height} index={index} key={url} url={url} width={width} />)}
      </Box>
      {images.length > 1 ? (
        <Box alignItems="center" display="flex" height={height} justifyContent="space-between" left={0} position="absolute" top={0} width={width}>
          <Box paddingLeft={1}>
            <Button
              disabled={currentIndex === 0}
              onClick={() => setCurrentIndex(currentIndex - 1)}
              size="small"
              sx={{
                borderRadius: 1,
                padding: 0,
                minWidth: 0,
                bgcolor: "#fff",
                boxShadow: currentIndex > 0 ? theme.shadows[3] : undefined,
                ":hover": { bgcolor: "#eee" },
              }}
              variant="text"
            >
              <ChevronLeft />
            </Button>
          </Box>
          <Box paddingRight={1}>
            <Button
              disabled={currentIndex === images.length - 1}
              onClick={() => setCurrentIndex(currentIndex + 1)}
              size="small"
              sx={{
                borderRadius: 1,
                padding: 0,
                minWidth: 0,
                bgcolor: "#fff",
                boxShadow: currentIndex < images.length - 1 ? theme.shadows[3] : undefined,
                ":hover": { bgcolor: "#eee" },
              }}
              variant="text"
            >
              <ChevronRight />
            </Button>
          </Box>
        </Box>
      ) : undefined}
    </Box>
  );
};
