import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';
import { useDispatch } from 'react-redux';
import { selectSchedules } from "App/Store/Meetings/meetingSchedule";
import { endOfMoment, startOfMoment } from '../../../../../Functions/Helpers';
import Text from 'Components/Text';
import styles from './styles.module.scss';
import Box from 'Components/Box';
import hoursMinutesToNumber from 'Functions/hoursMinutesToNumber.ts';
import { Tooltip } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CalendarType, FINAL_CALENDAR_HOUR, INITIAL_CALENDAR_HOUR, INITIAL_HORIZONTAL_SCROLL_POSITION, TIME_STEP_CALENDAR, blockClass, createRelativeDiv, getAllNumbersBetween, getScheduleMeeting, handleCalendarClick, tooltipText } from '../../Helpers';
import DateSelector from '../../../Calendar/DateSelector';
import CalendarDatePopup from './CalendarDatePopup';
import Hour from './Hour';
import Group from './Group';
import { Schedule } from 'App/Store/Meetings/meetingSchedule/models';

interface Item {  
  roomId?: string;
  hours: Array<number>;
}

export default function CalendarHorizontalView() {
  const dispatch = useDispatch();
  const {    
    createNewMeeting,            
    roomSchedule,
    room,    
    config,
  } = useTypedSelector(state => state);

  const { loading } = roomSchedule;

  const {    
    dateFrom,   
    meetingFrom,
    meetingTo,
    selectedData,    
  } = createNewMeeting;  

  const { roomId } = selectedData;

  const scheduleArray = selectSchedules(roomSchedule);  

  const { rooms } = room;  

  const [hours, setHours] = useState<Array<number>>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [initialScrollState, setInitialScrollState] = useState(false);  
  const [calendarOpened, setCalendarOpened] = useState(false);
  
  const blocksRef = useRef<HTMLHeadingElement>(null);
  const hoursRef = useRef<HTMLHeadingElement>(null);

  const itemSelected = useRef<Item>();
  const clicksCount = useRef(0);

  const handleClick = (roomId: string, hour: number) => {
    const selectedDay = meetingFrom ? meetingFrom : dateFrom;

    const calendarClickResponse = handleCalendarClick(CalendarType.HORIZONTAL, clicksCount, itemSelected, scheduleArray,
        hour, hours, selectedDay, { roomId, day: moment(selectedDay) });

    if (!calendarClickResponse) return;

    const { newMeetingFrom, newMeetingTo, clicksCount: newClicksCount, itemSelected: newItemSelected } = calendarClickResponse;

    if (newClicksCount && newClicksCount.current)
      clicksCount.current = newClicksCount.current;

    if (clicksCount.current === 2) resetItems();

    if (newItemSelected && newItemSelected.current)
      itemSelected.current = newItemSelected.current;

    dispatch(setCreateNewMeetingData({ 
      meetingFrom: newMeetingFrom,
      meetingTo: newMeetingTo,
      selectedData: {
        ...selectedData,
        roomId,
      }}));
  };

  const getBlockClass = (roomId: string, hour: number) => {       
    const selectedDay = meetingFrom ? meetingFrom : dateFrom;
    return blockClass(CalendarType.HORIZONTAL, scheduleArray, itemSelected, hour, styles, selectedDay, { roomId, day: moment(selectedDay) });
  };    

  const handleChangeSelectedDate = (nextDay: boolean) => {
    const currentDate = meetingFrom ? meetingFrom : dateFrom;
    const selectedDate = nextDay ? moment(currentDate).add(1, 'day') : moment(currentDate).subtract(1, 'day');

    if (selectedDate < moment().startOf('day')) return;

    setSelectedDate(selectedDate.toDate());    
    const newDateFrom = startOfMoment(selectedDate);
    const newDateTo = endOfMoment(selectedDate);    
    resetItems(true);    
    dispacthDateChange(newDateFrom, newDateTo);    
  };  

  const handlePopupChangeDate = (date: any) => {
    setSelectedDate(new Date(date));    

    const newDateFrom = startOfMoment(moment(date));
    const newDateTo = endOfMoment(moment(date));    

    resetItems(true);    

    dispacthDateChange(newDateFrom, newDateTo);
    setCalendarOpened(false);
  };

  const dispacthDateChange = (dateFrom: Date, dateTo: Date) => {
    dispatch(setCreateNewMeetingData({ 
      dateFrom,
      dateTo,
      meetingFrom: undefined,
      meetingTo: undefined,
      selectedData: {
        ...selectedData,
        roomId: null,
      },      
    })); 
  };

  const getTooltipText = (roomId: string, hour: number) => {
    const selectedDay = meetingFrom ? meetingFrom : dateFrom;
    return tooltipText(CalendarType.HORIZONTAL, scheduleArray, hour, selectedDay, { roomId });    
  };

  const resetItems = (force = false) => {
    if (!force && clicksCount.current === 0 && itemSelected) return;

    clicksCount.current = 0;

    itemSelected.current = undefined;
  };

  const checkItemPreviousSelected = () => {
    if (!meetingFrom || !meetingTo || !roomId) return;    

    const hoursFrom = hoursMinutesToNumber(meetingFrom);
    const hoursTo = hoursMinutesToNumber(meetingTo) - TIME_STEP_CALENDAR;

    const hours = getAllNumbersBetween(CalendarType.HORIZONTAL, hoursFrom, hoursTo);

    itemSelected.current = {
      roomId,
      hours,
    };    
  };  

  const checkSchedules = () => {
    rooms.map((room) => {
      const blockList = document.getElementById(`blocks-${room.id}`);      

      if (!blockList) return;

      const scheduledElements = blockList.getElementsByClassName(styles.blockMeeting);      

      if (scheduledElements.length === 0) return;            

      scheduleArray.filter((schedule) => schedule.roomId === room.id)
        .map((schedule) => {
          const alreadyCreated = document.getElementById(schedule.id);
          const meetingDivs = Array.from(scheduledElements).filter((element) => element.id.includes(schedule.id));

          if (meetingDivs.length === 0 || alreadyCreated) return;

          const relativeDiv = createRelativeDiv(meetingDivs, schedule);  

          blockList.insertBefore(relativeDiv, blockList.children[0]);          
        });
    });
  };

  const checkDatePreviousSelected = () => {
    if (!meetingFrom || !meetingTo || !roomId) return;    

    setSelectedDate(meetingFrom);
  };

  // It is responsible to set scroll to initial state, actually it is 7 AM
  const handleHoursScroll = (event: any) => {
    const blocksScrollPosition = event.target.scrollLeft;    

    if (!hoursRef || !hoursRef.current || !blocksRef || !blocksRef.current || loading) return;    

    hoursRef.current.scrollTo({ left: blocksScrollPosition });        

    if (!initialScrollState) {
      blocksRef.current.scrollTo({ left: INITIAL_HORIZONTAL_SCROLL_POSITION });
      setInitialScrollState(true);
    }
  };  

  useEffect(() => {
    if (roomId && meetingFrom && meetingTo) return;

    setSelectedDate(dateFrom);
  }, [roomId, dateFrom]);

  useEffect(() => {
    resetItems();  
    setInitialScrollState(false);    
  }, [selectedDate]);
   
  useEffect(() => {    
    // Check the state from app to force a scroll and the application put it on initial state.
    if (!blocksRef || !blocksRef.current || loading || initialScrollState) return;

    blocksRef.current.scrollLeft += 10;    
  }, [blocksRef, hoursRef, scheduleArray]); 

  useEffect(() => {    
    const hourFrom = INITIAL_CALENDAR_HOUR;
    const hourTo = FINAL_CALENDAR_HOUR;

    const hours = getAllNumbersBetween(CalendarType.HORIZONTAL, hourFrom, hourTo);

    checkDatePreviousSelected();
    checkItemPreviousSelected();          
    setHours(hours);
  }, []);

  useEffect(() => {
    checkSchedules();
  });

  const roomsSchedules: Record<string, [Schedule, number[]][]> = {};

  for (const room of rooms) {
    const selectedDay = meetingFrom ? meetingFrom : dateFrom;

    for (const hour of hours) {
      const scheduleMeeting = getScheduleMeeting(CalendarType.HORIZONTAL, scheduleArray, hour, selectedDay, { roomId: room.id });

      if (scheduleMeeting) {
        if (!roomsSchedules[room.id]) {
          roomsSchedules[room.id] = [];
        }

        const existing = roomsSchedules[room.id].findIndex(([schedule]) => schedule.id === scheduleMeeting.id);

        if (existing !== -1) {
          roomsSchedules[room.id][existing][1].push(hour);
        } else {
          roomsSchedules[room.id].push([scheduleMeeting, [hour]]);
        }
      }
    }
  }

  return (    
    <Box className={styles.calendar} direction='column' display='flex' width='100%'>
      <Box className={styles.header} display="grid" gap={5} gridTemplateColumns='180px auto' id='header'>        
        <Box display='flex' justifyContent='between'>
          <Box alignItems="center" display='flex' gap={5}>
            <CalendarDatePopup
              config={config}
              handleChangeDate={handlePopupChangeDate}
              opened={calendarOpened}
              selectedDate={selectedDate}
              setCalendarOpened={setCalendarOpened}              
            />

            <span className={styles.dateRange}>{moment(selectedDate).format("DD MMM, YYYY")}</span>
          </Box>
        
          <DateSelector handleChangeSelectedDate={handleChangeSelectedDate} />
        </Box>        
        <div
          className={styles.selectableGroup}
          ref={hoursRef}
          style={{
            overflowX: 'hidden',
            display: 'flex',
          }}        
        >
          <Box className={styles.hours}>
            {hours?.map((hour, index) => (            
              <Hour hour={hour} key={index} />              
            ))}
          </Box>                    
        </div>
      </Box>      
      <Box className={styles.list} display="grid" gridTemplateColumns='180px auto' id='list'>
        <Box>
          {rooms.map((room, index) => (
            <Group itemSelected={itemSelected} key={room.id + index} room={room} />            
          ))}
        </Box>        
        <div
          className={styles.selectableGroup}        
          onScroll={handleHoursScroll}           
          ref={blocksRef}         
        >
          {rooms.map((room, index) => (
            loading ? 
            <Box borderRadius={14} height={66}>
              <Skeleton height="100%" variant="rect" />
            </Box> :
            <div className={styles.blocks} id={`blocks-${room.id}`} key={room.id + index}>                  
              {hours?.map((hour) => {
                let id = `${room.id}-${hour}`;              
                const blockClass = getBlockClass(room.id, hour);
                const selectedDay = meetingFrom ? meetingFrom : dateFrom;
                const scheduleMeeting = getScheduleMeeting(CalendarType.HORIZONTAL, scheduleArray, hour, selectedDay, { roomId: room.id });

                if (scheduleMeeting) {
                  id = `schedule-${scheduleMeeting.id}-${hour}`;
                }
                
                const hourSlot = (
                  <div  
                    className={blockClass}                   
                    id={id}                    
                    onClick={() => handleClick(room.id, hour)}
                  />
                );

                return scheduleMeeting ? (
                  hourSlot
                ) : (
                  <Tooltip id={id} key={id} title={getTooltipText(room.id, hour)}>
                    {hourSlot}
                  </Tooltip>
                );
              })}
              {roomsSchedules[room.id]?.length ? (
                <>
                  {roomsSchedules[room.id].map(([schedule, hours]) => {
                    const [firstHour] = hours;
                    const left = firstHour * 4 * 45;
                    const width = hours.length * 45;

                    return (
                      <Box
                        key={schedule.id}
                        style={{
                          position: "absolute",
                          top: 0,
                          bottom: 0,
                          left: `${left}px`,
                          width: `${width}px`,
                        }}
                      >
                        <Tooltip title={schedule.summary || ""}>
                          <span
                            style={{ display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                              height: "100%",
                              padding: 8,
                            }}
                          >
                            <Text style={{ width: "100%" }} truncate>{schedule.summary}</Text>
                          </span>
                        </Tooltip>
                      </Box>
                    );
                  })}
                </>
              ) : <></>}
            </div>         
          ))}  
        </div>              
      </Box>
    </Box>
  );
}