import { booking } from '../bookingDuck/fixtures';
import { CheckinResponse } from './models';

export const checkinResponseSuccess: CheckinResponse = {
  error: '',
  result: {
    statusCode: 200,
    data: {
      booking: {
        ...booking,
        checking: true,
      },
      message: "You have successfully checked-in.",
    },
  },
};
