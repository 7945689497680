import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../../Store/Redux/store";
import Popup from "reactjs-popup";
import styles from "../../styles.module.scss";

import ButtonBase from "@material-ui/core/ButtonBase";
import Text from "../../../../../Components/Text";
import getDisplayedResourceName from "../../../../../Functions/resourseNameParsing";
import Box from "../../../../../Components/Box";
import Checkbox from "../../../../../Components/Checkbox";
import {selectAvailableAttributes, selectResourcesByIds} from "../../../../Store/attributes";
import {AttributeModel} from "../../../../Store/attributes/models";
import {updateDesk} from "../../../../Store/floorMapDuck";
import {FloorMapObject} from "../../../../Store/floorMapDuck/models";
import { deskAttributesCells } from "../../../../../Functions/getDeskAttributeIcon";

interface DeskAmenitiesPopupProps {
  desk: FloorMapObject;
}

export default function DeskAmenitiesPopup(props: DeskAmenitiesPopupProps) {
  const dispatch = useDispatch();
  const { attributesDesk } = useTypedSelector(state => state.adminAttributes);
  const { desk } = props;
  
  const deskResourcesArray = selectResourcesByIds(attributesDesk, Object.keys(desk.resourceIds));
  const attributes = selectAvailableAttributes(attributesDesk);

  const onChangeResources = (value: boolean, attribute: AttributeModel) => {
    const newResources = { ...desk.resourceIds };

    if (value) {
      newResources[attribute.id] = attribute.id;
    } else {
      delete newResources[attribute.id];
    }

    dispatch(updateDesk({
      deskId: typeof desk.id === 'string' ? desk.id : '',
      updateDeskProperties: {
        resources: newResources,
      },
    }));
  };
  
  return (
    <Popup
      closeOnDocumentClick
      contentStyle={{ marginLeft: 27, width: 270 }}
      position="bottom left"
      trigger={(
        <ButtonBase
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            padding: "10px 0",
          }}
        >
          <Text size="md" truncate>
            {deskResourcesArray.length === 0
              ? '-'
              : deskResourcesArray.map((resource, index) => {
                return `${getDisplayedResourceName(resource.name)}${index + 1 < deskResourcesArray.length ? ', ' : ''}`;
              })}
          </Text>
        </ButtonBase>
      )}
    >
      <Box borderStyle="shadow" className="popover-content">
        {attributes.map((attribute, index) => {
          const deskResource = desk.resourceIds[attribute.id];
          const imagePath = deskAttributesCells.find(icon => icon.name === attribute.name);
          const checked = Boolean(deskResource);

          return (
            <Checkbox
              checked={checked}
              classes={{ container: styles.checkbox }}
              key={attribute.id + index}
              label={
                <Box alignItems="center" className={`${styles.attributeLabel}`} display="flex">
                  {imagePath && <img alt={imagePath.name} className={styles.attributeImage} src={imagePath.image} />}
                  {getDisplayedResourceName(attribute.name)}
                </Box>
              }
              name={attribute.name}
              onChange={(value) => onChangeResources(value, attribute)}
            />
          );
        })}
      </Box>
    </Popup>
  );
}
