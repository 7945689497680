import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {useTypedSelector} from "../../../../../Store/Redux/store";
import {ICovidCertificateTypeList} from "../../../../Store/CovidSertificate/models";
import {useEffect, useState} from "react";
import {IFileWithMeta} from "react-dropzone-uploader/dist/Dropzone";
import {uploadCovidCertificate} from "../../../../Store/CovidSertificate";
import {
  ErrorUploadCovidFileSnackbar,
  SuccessUploadCovidFileSnackbar,
} from "../../../../../Components/UploadCovidFile/SnackBars";
import {Trans, t} from "@lingui/macro";
import Popup from "reactjs-popup";
import Box from "../../../../../Components/Box";
import UploadCovidDropzone from "../../../../../Components/UploadCovidFile/UploadCovidDropzone";
import UploadCovidType from "../../../../../Components/UploadCovidFile/UploadCovidType";
import UploadCovidFileDates from "../../../../../Components/UploadCovidFile/UploadCovidDates";
import UploadCovidPrivacyStatement from "../../../../../Components/UploadCovidFile/UploadCovidPrivacyStatement";
import Button from "../../../../../Components/Button";
import {getUserIdForBooking} from "../../../../Store/Users/executiveAssistant/helpers";

interface DocumentRequiredModalProps {
  closeModal: () => any;
  open: boolean;
}

export default function DocumentRequiredModal(props: DocumentRequiredModalProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { config, covidCertificate, profile, executiveAssistant } = useTypedSelector(state => state);
  const { certificateLoaded } = covidCertificate;
  const {
    closeModal,
    open,
  } = props;

  const documentTypeList: ICovidCertificateTypeList[] = [
    { value: 'vaccineRecord', label: 'Vaccine record' },
    { value: 'negativeCovidTestResult', label: 'Negative covid test result' },
  ];

  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [fileWithMeta, setFileWithMeta] = useState<IFileWithMeta | null>(null);
  const [documentType, setDocumentType] = useState<ICovidCertificateTypeList>(documentTypeList[0]);
  const [startDate, setStartDate] = useState<Date | string | null>(null);

  const selectedUserId = getUserIdForBooking(profile, executiveAssistant);
  const isValid = fileWithMeta && startDate && acceptPrivacy;

  useEffect(() => {
    // close popup after document upload
    if (isValid && !covidCertificate.certificateLoaded) {
      closeModal();
      history.push('/'); // redirect to main page
    }
  }, [covidCertificate.certificateLoaded]);
  
  const onClosePopup = () => {
    closeModal();

    if (fileWithMeta) {
      fileWithMeta.remove();
      setFileWithMeta(null);
    }

    setStartDate(null);
  };

  const handleUploadFile = () => {
    if (fileWithMeta && startDate) {
      dispatch(uploadCovidCertificate({
        vaccinationDate: startDate,
        file: fileWithMeta.file,
        type: documentType.value,
        selectedUserId,
      }));
    }
  };

  return (
    <>
      <SuccessUploadCovidFileSnackbar timeout={6000} />
      <ErrorUploadCovidFileSnackbar />
      
      <Popup
        className="modal"
        contentStyle={{ maxWidth: 410 }}
        modal
        nested
        onClose={() => onClosePopup()}
        open={open}
        position="right center"
      >
        {(close: any) => (
          <Box className="modal-inner">
            <div className="modal-header">
              <h2 style={{ maxWidth: 'calc(100% - 40px)' }}>
                <Trans>
                  Selected location requires document for booking
                </Trans>
              </h2>

              <svg className="modal-header__close" height="30px" onClick={() => close()} viewBox="0 0 30 30" width="30px">
                <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
                  <g id="Booking-savedall" transform="translate(-875.000000, -132.000000)">
                    <g id="Group-8" transform="translate(515.000000, 112.000000)">
                      <g id="icons/close" transform="translate(360.000000, 20.000000)">
                        <rect fill={config.theme.primaryLight} height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                        <path d="M20.704633,9.29536704 C21.0984557,9.68918977 21.0984557,10.3277026 20.704633,10.7215253 L16.4261582,15 L20.704633,19.2784747 C21.0984557,19.6722974 21.0984557,20.3108102 20.704633,20.704633 C20.3108102,21.0984557 19.6722974,21.0984557 19.2784747,20.704633 L15,16.4261582 L10.7215253,20.704633 C10.3277026,21.0984557 9.68918977,21.0984557 9.29536704,20.704633 C8.90154432,20.3108102 8.90154432,19.6722974 9.29536704,19.2784747 L13.5738418,15 L9.29536704,10.7215253 C8.90154432,10.3277026 8.90154432,9.68918977 9.29536704,9.29536704 C9.68918977,8.90154432 10.3277026,8.90154432 10.7215253,9.29536704 L15,13.5738418 L19.2784747,9.29536704 C19.6722974,8.90154432 20.3108102,8.90154432 20.704633,9.29536704 Z" fill={config.theme.primary}></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="modal-inner-content">
              <Box marginTop={20}>
                <UploadCovidDropzone
                  fileWithMeta={fileWithMeta}
                  setFileWithMeta={setFileWithMeta}
                />
              </Box>
              <Box marginTop={20}>
                <UploadCovidType
                  documentType={documentType}
                  documentTypeList={documentTypeList}
                  setDocumentType={setDocumentType}
                />
              </Box>
              <Box marginTop={15}>
                <UploadCovidFileDates
                  setStartDate={setStartDate}
                  startDate={startDate}
                />
              </Box>
              <Box marginTop={15} maxHeight={100} overflow='auto' paddingLeft={9}>
                <UploadCovidPrivacyStatement
                  checked={acceptPrivacy}
                  label={t`I certify the information and documentation I submit are accurate and truthful to the best of my knowledge. I understand and acknowledge the information I submit will be used to support a safe workplace and accessible by select, authorized members of Human Resources and Information Technology on a need-to-know-basis, including others as legally required. Personal health information is stored in a dedicated, confidential file and retained for as long as needed for the purposes the information was obtained.`}
                  name='Accept Privacy Statement'
                  onChange={checked => setAcceptPrivacy(checked)}
                />
              </Box>

              <Box
                alignItems="center"
                display="flex"
                justifyContent="end"
                marginTop={15}
              >
                <Button
                  aria-label={t`Cancel Add Document`}
                  name={t`Cancel Add Document`}
                  onClick={() => close()}
                  size="sm"
                  type="clear"
                  withShadow={false}
                >
                  <Trans>
                    Cancel
                  </Trans>
                </Button>

                <Button
                  aria-label={t`Add Document`}
                  buttonType="submit"
                  disabled={!isValid || certificateLoaded}
                  name={t`Add Document`}
                  onClick={() => handleUploadFile()}
                  size="sm"
                >
                  {certificateLoaded ? t`Uploading Document...` : t`Upload Document`}
                </Button>
              </Box>
            </div>
          </Box>
        )}
      </Popup>
    </>
  );
}
