import React, { useEffect, useState } from "react";
import {useTypedSelector} from "../../../Store/Redux/store";
import _ from "underscore";
import Popup from "reactjs-popup";
import styles from "./styles.module.scss";

import ButtonBase from "@material-ui/core/ButtonBase";
import {TextField} from "@material-ui/core";
import {Trans, t} from "@lingui/macro";
import OverflowToolTip from "../../../Components/OverflowTooltip";
import Box from "../../../Components/Box";
import Checkbox from "../../../Components/Checkbox";
import Text from "../../../Components/Text";
import {Location} from "../../../App/Store/Locations/locationsDuck/models";

interface AdminLocationsModalProps {
  locationIds: string[];
  onSave: Function;

  openButtonClassName?: string;
  openButtonDisabled?: boolean;
  contentStyle?: React.CSSProperties;
}

function getAllowedLocations(allLocations: Location[], locationIds: string[]) {
  return allLocations.filter((location) => locationIds.includes(location.id));
}

export default function ManageLocationsModal(props: AdminLocationsModalProps) {
  const { locations, groupDetails } = useTypedSelector(state => state);
  const {
    locationIds = [],
    onSave,
    contentStyle = {},
    openButtonClassName,
    openButtonDisabled,
  } = props;
  const { infoDataToEdit } = groupDetails;
  const allowedLocations = getAllowedLocations(locations.locations, locationIds);
  const [filteredLocations, setFilteredLocations] = useState<Location[]>(allowedLocations); // searched locations
  const [selectedLocations, setSelectedLocations] = useState<Location[]>(filteredLocations);
  const unSelectedLocations = filteredLocations.filter(user => !selectedLocations.some(owner => owner.id === user.id)); // all users that not includes users from ownersArray
  const localAdminLocations = selectedLocations.map(l => l.locationName).join(', ');

  const disabledInputClass = openButtonDisabled ? styles.disabledInputStyle : '';

  const onChangeLocations = (location: Location) => {
    const locationIds = selectedLocations.map(l => l.id);
    let updatedLocations = [...selectedLocations, location];
    if (locationIds.includes(location.id)) {
      updatedLocations = [...selectedLocations].filter(l => l.id !== location.id);
    }

    setSelectedLocations(updatedLocations);
  };

  const onSearchChange = (event: any) => {
    // make search only for next fields
    const fieldsToSearch = ['locationName'];
    const search = event.target.value;

    const updatedFilteredLocations = locations.locations
      .filter(item => {
        // @ts-ignore
        return Object.keys(item).some(key => fieldsToSearch.includes(key) && item[key].toLowerCase().includes(search.toLowerCase()));
      });

    setFilteredLocations(updatedFilteredLocations);
  };

  const onClosePopup = (selectedLocations: Location[]) => {
    onSave(selectedLocations);
    setFilteredLocations(locations.locations); // reset search result
  };

  // turn back default name after cancelling changes
  useEffect(() => {
    const isDataChanged = !_.isEqual(_.sortBy(infoDataToEdit.locationIds), _.sortBy(selectedLocations.map(l => l.id)));

    if (isDataChanged) {
      setSelectedLocations(allowedLocations);
    }
  }, [infoDataToEdit.locationIds]);

  // reset search result on component load
  useEffect(() => {
    setFilteredLocations(locations.locations);
  }, []);

  return (
    <Popup
      closeOnDocumentClick
      contentStyle={contentStyle}
      disabled={openButtonDisabled}
      onClose={() => onClosePopup(selectedLocations)}
      position="bottom left"
      trigger={(
        <ButtonBase
          className={`${openButtonClassName} ${disabledInputClass}`}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            height: '100%',
            minHeight: '30px',
            fontSize: '14px',
          }}
        >
          {
            localAdminLocations.length ?
              <OverflowToolTip text={localAdminLocations} /> :
              <div className={`${styles.locationNotAvailable}`}>
                <OverflowToolTip text={'Not selected'} />
              </div>
          }
        </ButtonBase>
      )}
    >
      <Box borderStyle="shadow" className="popover-content">
        <TextField
          InputProps={{
            startAdornment: (
              <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px">
                <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
                  <g fill="#5E5E5E" fillRule="nonzero" id="icons/search">
                    <g id="delete-white-copy-5">
                      <path d="M6.05025253,6.05025253 C8.78392257,3.31658249 13.2160774,3.31658249 15.9497475,6.05025253 C18.4434716,8.54397661 18.6623564,12.4511046 16.6064021,15.1929497 L20.1923882,18.7781746 C20.5829124,19.1686989 20.5829124,19.8018639 20.1923882,20.1923882 C19.8018639,20.5829124 19.1686989,20.5829124 18.7781746,20.1923882 L15.1929497,16.6064021 C12.4511046,18.6623564 8.54397661,18.4434716 6.05025253,15.9497475 C3.31658249,13.2160774 3.31658249,8.78392257 6.05025253,6.05025253 Z M14.5355339,7.46446609 C12.5829124,5.51184464 9.41708755,5.51184464 7.46446609,7.46446609 C5.51184464,9.41708755 5.51184464,12.5829124 7.46446609,14.5355339 C9.41708755,16.4881554 12.5829124,16.4881554 14.5355339,14.5355339 C16.4881554,12.5829124 16.4881554,9.41708755 14.5355339,7.46446609 Z" id="Combined-Shape"></path>
                    </g>
                  </g>
                </g>
              </svg>
            ),
          }}
          className={`input input--default input--search`}
          defaultValue={''}
          fullWidth
          onChange={onSearchChange}
          placeholder={t`Search`}
          variant="outlined"
        />
        <Box className={styles.locationsCheckBoxWrapper}>
          {selectedLocations.length ?
            selectedLocations.map((location, index) => {
              return (
                <Checkbox
                  checked={true}
                  classes={{container: styles.checkbox}}
                  key={location.id + index}
                  label={
                    <Box alignItems="center" className={`${styles.attributeLabel}`} display="flex">
                      {location.locationName}
                    </Box>
                  }
                  name={location.locationName}
                  onChange={() => onChangeLocations(location)}
                />
              );
            }) : null
          }

          {unSelectedLocations.length ?
            unSelectedLocations.map((location, index) => {
              return (
                <Checkbox
                  checked={false}
                  classes={{container: styles.checkbox}}
                  key={location.id + index}
                  label={
                    <Box alignItems="center" className={`${styles.attributeLabel}`} display="flex">
                      {location.locationName}
                    </Box>
                  }
                  name={location.locationName}
                  onChange={() => onChangeLocations(location)}
                />
              );
            }) : null
          }
        </Box>
        {!unSelectedLocations.length ?
          <Box marginTop={10}>
            <Text align="center" color="lightGray" size="md">
              <Trans>No Locations found</Trans>
            </Text>
          </Box> : null
        }
      </Box>
    </Popup>
  );
}
