import Box from "../../../../Components/Box";
import styles from "../../CreateNewBooking/Layout/styles.module.scss";
import {Link} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ProfileLink from "../../../../Components/Profile";

export default function UserProfileHeader() {
  return (
    <Box
      alignItems="center"
      dataTestId="user-profile-header"
      display="flex"
      justifyContent="between"
      marginBottom={27}
      marginTop={30}
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="between"
        position="relative"
        style={{ flex: 8 }}
      >
        <Box className={styles.backButtonContainer} dataTestId="user-profile__back-icon">
          <Link to="/">
            <IconButton classes={{ root: styles.root }}>
              <svg className={`MuiSvgIcon-root ${styles.arrowBackIcon}`} height="30px" viewBox="0 0 30 30" width="30px">
                <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
                  <g id="Createbooking" transform="translate(-60.000000, -31.000000)">
                    <g id="icons/arrow-left" transform="translate(60.000000, 31.000000)">
                      <path className={styles.arrowBackIconFill} d="M13.7071068,10.0502525 C14.0976311,10.4407768 14.0976311,11.0739418 13.7071068,11.4644661 L11.1713593,13.9993593 L21,14 C21.5522847,14 22,14.4477153 22,15 C22,15.5522847 21.5522847,16 21,16 L11.1713593,15.9993593 L13.7071068,18.5355339 C14.0976311,18.9260582 14.0976311,19.5592232 13.7071068,19.9497475 C13.3165825,20.3402718 12.6834175,20.3402718 12.2928932,19.9497475 L8.05025253,15.7071068 C7.65972824,15.3165825 7.65972824,14.6834175 8.05025253,14.2928932 L12.2928932,10.0502525 C12.6834175,9.65972824 13.3165825,9.65972824 13.7071068,10.0502525 Z" id="Combined-Shape"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </IconButton>
          </Link>
        </Box>
        <Box>
          <ProfileLink greeting="profile" />
        </Box>
      </Box>
    </Box>
  );
}
