import Button from 'Components/Button';
import CheckCircleIcon from 'Components/Icons/CheckCircle';
import ClockIcon from 'Components/Icons/Clock';
import Box from "Components/Box";
import DeniedCircleIcon from 'Components/Icons/DeniedCircle';
import ExpiredCircleIcon from 'Components/Icons/ExpiredCircle';
import styles from './styles.module.scss';
import UserDocumentsModal from '../UserDocumentsModal';
import { t } from '@lingui/macro';
import { UserDocument, UserDocumentStatus, UserManagementUser } from 'Admin/Store/userManagement/models';
import { useState } from 'react';
import OverflowToolTip from "../../../../../../Components/OverflowTooltip";

export function getStatusConfig(status: UserDocumentStatus) {
  switch (status) {
    case 'approved': {
      return {
        icon: <CheckCircleIcon color="#5BC535" />,
        label: t`Approved`,
      };
    }

    case 'denied': {
      return {
        icon: <DeniedCircleIcon color="#ABABAB" />,
        label: t`Denied`,
      };
    }

    case 'expired': {
      return {
        icon: <ExpiredCircleIcon color="#FF4C0E" />,
        label: t`Expired`,
      };
    }

    case 'needsApproval': {
      return {
        icon: <ClockIcon color="#FFBD0E" />,
        label: t`Needs approval`,
      };
    }

    default:
      return;
  }
}

interface Props {
  document: UserDocument;
  user: UserManagementUser;
}

export default function UserDocumentDisplay({
  document,
  user,
}: Props) {
  const [opened, setOpened] = useState(false);

  const documentConfig = getStatusConfig(document.status);

  const onClick = () => {
    setOpened(true);
  };

  return documentConfig ? (
    <div className={styles.userDocumentButtonWrapper}>
      <Button
        className={styles.userDocumentButton}
        noPaddingX
        onClick={onClick}
        type="clear"
      >
        <Box alignContent="center" display="flex" justifyContent="center">
          {documentConfig.icon}
        </Box>

        <div className={styles.documentButtonText}>
          <OverflowToolTip text={documentConfig.label} />
        </div>
      </Button>

      <UserDocumentsModal
        open={opened}
        toggleOpen={() => setOpened(current => !current)}
        user={user}
      />
    </div>
  ) : null;
}