import { useDispatch } from "react-redux";
import { FloorMapObject, MapDrawingGroup } from "../../../../../../Store/floorMapDuck/models";
import { updateDesk } from "../../../../../../Store/floorMapDuck";
import GroupsMultiSelect from "../../Components/GroupsMultiSelect";

interface Props {
  desk: FloorMapObject;
}

export default function DeskGroupsMultiSelect(props: Props) {
  const dispatch = useDispatch();
  const { desk } = props;

  const onGroupsUpdate = (groups: MapDrawingGroup[]) => {
    dispatch(updateDesk({
      deskId: desk.id.toString(),
      updateDeskProperties: {
        groups,
      },
    }));
  };

  return (
    <GroupsMultiSelect drawingObject={desk} onUpdate={onGroupsUpdate} />
  );
}
