import { RoomServiceType } from "App/Store/Meetings/meetingRooms/models";

export enum AdminRoomStatusEnum {
  Available = "AVAILABLE",
  Unavailable = "UNAVAILABLE",
}
export type AdminRoomStatusType = AdminRoomStatusEnum.Available | AdminRoomStatusEnum.Unavailable;

export interface IAdminRoomStatus {
  value: AdminRoomStatusType;
  name: string;
}

export interface IAdminRoomAmenity {
  id: string;
  name: string;
}

export interface IAvailableRoom {
  id: string;
  name: string;
  capacity?: number;
  isAssigned?: boolean;
  isMapped?: boolean;
  floorId?: string;
}

export interface IAdminRoomAvailableService {
  type: RoomServiceType;
  emails?: string[];
}

export interface IAdminRoom {
  id: string;
  name: string;
  capacity: number;
  description?: string;
  reservationDayLimit?: number;
  status: AdminRoomStatusType;
  amenities: IAdminRoomAmenity[];
  groups?: Array<{ id: string; name: string }>;
  availableServices?: IAdminRoomAvailableService[];
}

// filters
export interface IFiltersAdminFloorRooms {
  search?: string;
}

// set any data
export interface SetAdminFloorRoomsDataRequest {
  error?: string;
  successMessage?: string;
  filters?: IFiltersAdminFloorRooms;
  allRooms?: IAdminRoom[];
  floorRooms?: IAdminRoom[];
  availableFloorRooms?: IAvailableRoom[]; // use as available rooms to add
}

// get admin rooms
export interface AdminFloorRoomsAvailabilityFilters {
  availabilityStartDate: Date | string;
  availabilityEndDate: Date | string;
  availabilityStartTime: Date | string;
  availabilityEndTime: Date | string;
}
export interface GetAdminFloorRoomsRequestData {
  page: number;
  limit: number;
  search?: string;
  custom?: AdminFloorRoomsAvailabilityFilters
}
export interface GetAdminFloorRoomsRequest {
  locationId: string;
  floorId: string;
  filters: GetAdminFloorRoomsRequestData;
}
export interface GetAdminFloorRoomsResponse {
  result: {
    statusCode: number;
    data: {
      items: IAdminRoom[],
      meta: {
        pages: number;
        total: number;
      },
      links: {
        first: string; // "/api/v1/entities?page=1&limit=20"
        next: string; // "/api/v1/entities?page=4&limit=20"
        previous: string; // "/api/v1/entities?page=2&limit=20"
        last: string; // "/api/v1/entities?page=5&limit=20"
      }
    };
  }
}

export interface GetAvailableAdminRoomsRequest {
  page: number;
  limit: number;
  search?: string;
}
export interface GetAvailableAdminRoomsResponse {
  result: {
    statusCode: number;
    data: {
      items: IAdminRoom[],
      meta: {
        pages: number;
        total: number;
      },
      links: {
        first: string; // "/api/v1/entities?page=1&limit=20"
        next: string; // "/api/v1/entities?page=4&limit=20"
        previous: string; // "/api/v1/entities?page=2&limit=20"
        last: string; // "/api/v1/entities?page=5&limit=20"
      }
    };
  }
}

// get list of all available rooms to add
export interface GetAvailableAdminFloorRoomsRequest {
  search?: string;
  limit?: number;
  includeUnmappedRooms?: boolean;
}

export interface GetAvailableAdminFloorRoomsResponse {
  result: {
    statusCode: number;
    data: {
      items: IAvailableRoom[];
      pages: number;
      total: number;
    }
  }
}

// add a new room
export interface AddAdminFloorRoomRequestData {
  roomId: string; // email
  name: string;
  status: AdminRoomStatusType;
  amenityIds: string[];
  capacity?: number | null;
  availableServices?: IAdminRoomAvailableService[];
  description?: string;
  groupIds?: string[];
  reservationDayLimit?: number;
}
export interface AddAdminFloorRoomRequest {
  locationId: string;
  floorId: string;
  data: AddAdminFloorRoomRequestData;
}
export interface AddAdminFloorRoomResponse {
  result: {
    statusCode: number;
    data: IAdminRoom;
  }
}

// edit room
export interface EditAdminFloorRoomData {
  name?: string;
  amenityIds?: string[];
  status?: AdminRoomStatusType;
  capacity?: number | null;
  availableServices?: IAdminRoomAvailableService[];
  description?: string;
  groupIds?: string[];
  reservationDayLimit?: number;
}
export interface EditAdminFloorRoomRequest {
  locationId: string;
  floorId: string;
  roomId: string;
  data: EditAdminFloorRoomData;
}
export interface EditAdminFloorRoomResponse {
  result: {
    statusCode: number;
    data: IAdminRoom;
  }
}

// delete room
export interface DeleteAdminFloorRoomRequest {
  locationId: string;
  floorId: string;
  roomId: string;
}

export interface DeleteAdminFloorRoomResponse {
  result: {
    statusCode: string;
    data: {
      id: string;
    };
  },
  error: string;
}