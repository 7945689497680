import Box from 'Components/Box';
import Button from 'Components/Button';
import FloorItem, { FloorItemLoading } from './FloorItem';
import format from 'date-fns/format';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { getFloorsV2 } from 'App/Store/Locations/locationsDuck';
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';
import { StaggeredItems } from 'Components/Animations/StaggeredItems';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import {useEffect} from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import bookingStyles from "../styles.module.scss";
import Divider from "../../../../Components/Divider";
import AccordionRoomCalendar from '../Calendar/AccordionRoomCalendar';
import moment from 'moment';
import { IMeetingReservationFloor } from 'App/Store/Meetings/meetingReservations/models';
import MeetingBreadcrumbs from '../MeetingBreadcrumbs';
import BookingDetails from 'App/Pages/CreateNewBooking/BookingDetails';

export default function MeetingFloor() {
  const dispatch = useDispatch();
  const { createNewMeeting, locations: locationsDuck } = useTypedSelector(state => state);

  const {    
    dateFrom,
    dateTo,    
    selectedData,    
    timeFrom,
    timeTo,    
  } = createNewMeeting;
  const { floors, loading } = locationsDuck;
  const { floor, location } = selectedData;  

  const detailTitle = t`Meeting Details`;

  const bookingPath = 'create-new-meeting';

  const nextPath = 'select-room';

  useEffect(() => {
    dispatch(setCreateNewMeetingData({ selectedData: { ...selectedData, floor: null } }));
  }, []);

  useEffect(() => {
    if (location && dateFrom && dateTo && timeFrom && timeTo) {
      const locationId = location.id;
      const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm:ssxxx') : '';
      const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm:ssxxx') : '';
      const formattedDate = format(new Date(dateFrom), 'yyyy-MM-dd'); 
      const startOf = moment(dateFrom).startOf('day');
      const endOf = moment(dateFrom).endOf('day');
      const startOfDateFrom = !timeFromFormatted.length ? startOf.format('HH:mm:ssZ') : timeFromFormatted;
      const endOfDateFrom = !timeToFormatted.length ? endOf.format('HH:mm:ssZ') : timeToFormatted;

      dispatch(getFloorsV2({
        data: {
          limit: 40,
          page: 1,  
          custom: [
            { key: 'availabilityStartDate', value: formattedDate},
            { key: 'availabilityEndDate', value: formattedDate},
            { key: 'availabilityStartTime', value: startOfDateFrom},
            { key: 'availabilityEndTime', value: endOfDateFrom},
          ],        
        },
        locationId,
      }));      
    }
  }, [dateFrom, dateTo, timeFrom, timeTo, location]);

  const onChangeFloor = (floor: IMeetingReservationFloor) => {    
    dispatch(setCreateNewMeetingData({
      selectedData: {
        ...selectedData,
        floor,
      },
    }));
  };

  return (
    <Box display="grid" gap={30} gridTemplateColumns="8fr 4fr" paddingBottom={50}>
      <Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="between"
          marginBottom={15}
        >
          <MeetingBreadcrumbs />
        </Box>

        {loading ? (
          <FloorItemLoading />
        ) : (
          <Box className={styles.floorList} dataTestId="floor-list" display="grid">
            {floors.length > 0 ? (
              <StaggeredItems className={styles.items}>
                {floors.map((floor, index) => (
                  <FloorItem
                    {...floor}
                    key={floor.id + index}
                    onClick={onChangeFloor}
                    selected={selectedData.floor?.id === floor.id}
                  />
                ))}
              </StaggeredItems>
            ) : (
              <Box
                alignItems="center"
                display="flex"
                height="100%"
                justifyContent="center"
                margin="20px 0 100px"
              >
                <Text align="center" color="gray" size="md">
                  {t`There're no floors for this location`}
                </Text>
              </Box>
            )}

            <Box className={styles.stickyButtonRow} display="flex" justifyContent="end">
              <Button disabled={!floor} size="sm130" to={`/${bookingPath}/${nextPath}`}>
                <Trans>Next</Trans>
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <Box className={bookingStyles.stickyBookingWrapper}>
        <Box marginBottom={15}>
          <Text size="xlg" weight="semi-bold">
            {detailTitle}
          </Text>
        </Box>

        <AccordionRoomCalendar showExpand={true} />

        <Box className={bookingStyles.stickyBookingRow} marginTop={10}>
          <Box marginBottom={10}>
            <Divider />
          </Box>

          <BookingDetails type={"meeting"} />
        </Box>
      </Box>
    </Box >
  );
}