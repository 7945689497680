import Error404 from '../Error404';
import Layout from './../CreateNewBooking/Layout';
import MeetingFloor from './MeetingFloor';
import MeetingLocation from './MeetingLocation';
import { AnimatePresence } from 'framer-motion';
import { FloorGuard, RoomGuard } from './guards';
import { ProtectedRoute } from 'Components/ProtectedRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import { t } from '@lingui/macro';
import Room from './Room';

export default function CreateNewMeetingRoutes() {
  return (
    <Layout title={t`Book meeting room`}>
      <AnimatePresence exitBeforeEnter initial={true}>
        <Switch>
          <Route
            exact
            path='/create-new-meeting/select-location'
            render={() => <MeetingLocation />}
          />

          <ProtectedRoute
            exact
            fallback={() => <Redirect to="/create-new-meeting/select-location" />}
            guards={[FloorGuard]}
            path='/create-new-meeting/select-floor'
            render={() => <MeetingFloor />}
          />

          <ProtectedRoute
            exact
            fallback={() => <Redirect to="/create-new-meeting/select-floor" />}
            guards={[RoomGuard]}
            path='/create-new-meeting/select-room'
            render={() => <Room />}
          />

          <Route path='*' render={() => <Error404 />} />
        </Switch>
      </AnimatePresence >
    </Layout >
  );
}