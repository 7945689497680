import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../../Store/Redux/store";
import Popup from "reactjs-popup";
import styles from "./styles.module.scss";
import Box from "../../../../../Components/Box";
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';
import Text from "Components/Text";
import { Trans, t } from '@lingui/macro';
import Button from "Components/Button";
import { getRoomById } from "App/Store/Meetings/meetingRooms";
import Neighbors from "Components/Icons/Neighbors";
import Floor from "Components/Icons/Floor";
import Location from "Components/Icons/Location";
import Close from "Components/Icons/Close";
import Amenities from "./RoomInfo/Amenities";
import RoomMap from "./RoomImages/RoomMap";

interface RoomDetailsProps {
  open: boolean;  
  setOpen: (open: boolean) => void;
  roomId: string;
}

export default function RoomDetails(props: RoomDetailsProps) {
  const dispatch = useDispatch();
  const { room, createNewMeeting } = useTypedSelector(state => state);
  const { selectedData } = createNewMeeting;
  const { location, floor, roomId: selectedRoomId } = selectedData;
  const { open, setOpen, roomId } = props;

  const roomInformation = getRoomById(room, roomId);

  const handleClosePopup = () => {
    setOpen(false);
  };

  const handleSelectRoom = (listRoomId: string | null) => {
    if (selectedRoomId === listRoomId) listRoomId = null;

    dispatch(setCreateNewMeetingData({
      selectedData: {
        ...selectedData,
        roomId: listRoomId,
      },

    }));
    setOpen(false);
  };

  const getButtonSelectRoomText = (listRoomId: string) => {
    return selectedRoomId === listRoomId? t`Deselect room`: t`Select room`;
  };

  return (
    <>
      <Popup                
        contentStyle={{
          maxWidth: 1170,
          width: '100%',
        }}
        modal
        nested
        onClose={() => setOpen(false)}
        open={open}
        position="bottom right"        
      >
        <Box className={styles.popoverContent}>
          <Box className={styles.roomImages}>
            <Text size="xlg" weight="semi-bold">
              <Trans>Room on map</Trans>
            </Text>

            <RoomMap roomId={roomId} />
          </Box>

          <Box className={styles.roomDetails}>
            <Box alignItems="center" display="flex" justifyContent="between" marginBottom={20}>
              <Text size="xlg" weight="semi-bold">
                <Trans>
                  Room details
                </Trans>
              </Text>

              <div className={styles.closeIcon} onClick={handleClosePopup}>
                <Close />
              </div>
            </Box>

            <Box marginBottom={20}>
              <Box marginBottom={20}>
                <Box marginBottom={10}>
                  <Text weight="semi-bold">
                    {roomInformation?.name}
                  </Text>
                </Box>
                <Box direction="row" display="flex" gap={10}>
                  <Box className={styles.roomDetailsBox} display="flex" gap={5} marginBottom={10}>
                    <Floor size={16} />
                    <Text color="gray" size="md" weight="regular">{floor?.name}</Text>
                  </Box>

                  {roomInformation?.capacity &&
                    <Box className={styles.roomDetailsBox} display="flex" gap={5} marginBottom={10}>
                      <Neighbors size={16}/>
                      <Text color="gray" size="md" weight="regular"><Trans>Capacity</Trans>: {roomInformation.capacity}</Text>
                    </Box>
                  }
                </Box>
                <Box alignItems="center" display="flex" gap={5}>
                  <Location size={16} />
                  <Text color="gray" size="md" weight="regular">{location?.address}</Text>
                </Box>
              </Box>

              <Amenities roomInformation={roomInformation} />
            </Box>

            <Button className={styles.selectRoomButton} onClick={() => handleSelectRoom(roomId)} size="sm130">
              {getButtonSelectRoomText(roomId)}
            </Button>
          </Box>
        </Box>
      </Popup>      
    </>
  );
}