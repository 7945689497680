import Box from 'Components/Box';
import CustomTooltip from './CustomTooltip';
import Select from 'Components/Select';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { DashboardsTotalByDayPeriod, dashboardsTotalByDayArray } from 'Admin/Store/locationDashboardDuck/models';
import { getLocationDashboardTotalByDay, selectTotalByDayPeriod } from 'Admin/Store/locationDashboardDuck';
import { SizeMe } from 'react-sizeme';
import { Trans } from '@lingui/macro';
import { TOP_CHARTS_HEIGHT } from '..';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'Store/Redux/store';
import {
  Bar,
  BarChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export default function TotalByDay() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();

  const [period, setPeriod] = useState<DashboardsTotalByDayPeriod>('thisWeek');

  const { config, locationDashboard } = useTypedSelector(state => state);

  useEffect(() => {
    dispatch(getLocationDashboardTotalByDay({ locationId, period }));
  }, [locationId, period]);

  const counts = selectTotalByDayPeriod(locationDashboard, locationId);

  return (
    <Box className={styles.container} dataTestId="dashboard-total-by-day">
      <Box alignItems='center' display='flex' justifyContent='between'>
        <Box className={styles.inputContainer}>
          <Box alignItems='center' display='flex' gap={10}>
            <Text inline overflow='noWrap' weight="bold">
              <Trans>
                Total by
              </Trans>
            </Text>
            <Text inline>|</Text>
          </Box>

          <Select
            classes={{
              input: styles.input,
              wrapper: styles.select,
              // materialSelect: styles.materialInput,
            }}
            data-testid="dashboard-total-by-day-select"
            iconColor="#000000"
            id="status"
            items={dashboardsTotalByDayArray}
            labelColor="gray"
            onChange={(event: any) => setPeriod(event.target.value)}
            value={period}
          />
        </Box>

        <Box alignItems='center' display='flex' gap={10}>
          <Box alignItems='center' display='flex' gap={5}>
            <Box
              backgroundColor={config.theme.primary}
              borderRadius={2}
              height={10}
              width={10}
            />

            <Text color="gray" size="sm">
              <Trans>
                Booked
              </Trans>
            </Text>
          </Box>

          <Box alignItems='center' display='flex' gap={5}>
            <Box
              backgroundColor={config.theme.warn}
              borderRadius={2}
              height={10}
              width={10}
            />

            <Text color="gray" size="sm">
              <Trans>
                Cancelled
              </Trans>
            </Text>
          </Box>
        </Box>
      </Box>

      <SizeMe>
        {({ size }) => {
          return (
            <div data-testid="dashboard-total-by-day-chart">
              <BarChart
                data={counts}
                height={size.height ?? TOP_CHARTS_HEIGHT}
                width={size.width ?? undefined}
              >
                <Tooltip
                  content={<CustomTooltip />}
                  cursor={{
                    fill: config.theme.primaryLight,
                    stroke: config.theme.primary,
                    strokeDasharray: "5 5",
                  }}
                />

                <Bar
                  barSize={10}
                  dataKey="booked"
                  fill={config.theme.primary}
                  stackId="a"
                  yAxisId="left-axis"
                />
                <Bar
                  barSize={10}
                  dataKey="cancelled"
                  fill={config.theme.warn}
                  stackId="a"
                  width={2}
                  yAxisId="left-axis"
                />

                <XAxis
                  axisLine={{
                    stroke: config.theme.primaryLight,
                  }}
                  dataKey="day"
                  stroke="#5E5E5E"
                  tickLine={false}
                />
                <YAxis
                  allowDecimals={false}
                  axisLine={{
                    stroke: config.theme.primaryLight,
                  }}
                  stroke="#5E5E5E"
                  tickLine={false}
                  width={25}
                  yAxisId="left-axis"
                />
                <YAxis
                  axisLine={{
                    stroke: config.theme.primaryLight,
                  }}
                  orientation="right"
                  stroke="#5E5E5E"
                  tickLine={false}
                  width={1}
                  yAxisId="right-axis"
                />
              </BarChart >
            </div>
          );
        }}
      </SizeMe>
    </Box>
  );
}
