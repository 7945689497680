interface Props {
  color?: string;
  size?: number;
}

export default function SendMessage({ color = '#4A4A4A', size = 25 }: Props) {
  return (
    <svg height={size} version="1.1" viewBox="0 0 24 24" width={size}>
      <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
        <g fill={color} id="A-Location-notifications" transform="translate(-409.000000, -375.000000)">
          <g id="icons/sendmessage" transform="translate(409.000000, 375.000000)">
            <path
              d="M6.48796073,3.12922004 L20.4845789,11.1037845 C20.9681095,11.3792757 21.142397,12.004737 20.8738609,12.5007913 C20.7830628,12.6685184 20.6482998,12.8068122 20.4848336,12.9000108 L6.50504246,20.870449 C6.02158998,21.1460846 5.41186877,20.9674649 5.14319201,20.4714908 C4.99646365,20.200632 4.97673748,19.8763018 5.08950758,19.5888318 L7.8904086,12.4488625 C7.9864183,12.2041176 7.98697634,11.9308618 7.89196711,11.6857063 L5.07112207,4.40698535 C4.86677865,3.87971119 5.11777435,3.28232746 5.63173667,3.07269145 C5.9109583,2.95880196 6.22527181,2.97955319 6.48796073,3.12922004 Z M16.27,12.999 L9.815,12.999 L9.75227406,13.1792439 L7.965,17.735 L16.27,12.999 Z M7.931,6.253 L9.75682063,10.9629883 L9.769,10.999 L16.262,10.999 L7.931,6.253 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
