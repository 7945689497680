import {t} from "@lingui/macro";
import {
  IFiltersGroupDetails,
  IGroupInfoData,
  SetGroupDetailsDataRequest,
  UpdateGroupUsersModel,
  UpdateGroupUsersRequest,
  UpdateGroupUsersResponse,
} from "./models";
import {ActionPayload, BaseErrorResponse, BaseResponse} from "../../../Store/Models/ReduxModels";
import { GetAllUsersRequest, GetAllUsersResponse, UserManagementUser } from "../userManagement/models";

export const SET_GROUP_DETAILS_DATA = 'SET_GROUP_DETAILS_DATA';
export const CLEAR_USER_SELECTION_FROM_SEARCH_DATA = 'CLEAR_USER_SELECTION_FROM_SEARCH_DATA';

// get data of group users
export const GET_GROUP_USERS = 'GET_GROUP_USERS';
export const GET_GROUP_USERS_SUCCESS = 'GET_GROUP_USERS_SUCCESS';
export const GET_GROUP_USERS_FAIL = 'GET_GROUP_USERS_FAIL';

// add or remove users from group
export const UPDATE_GROUP_USERS = 'UPDATE_GROUP_USERS';
export const UPDATE_GROUP_USERS_SUCCESS = 'UPDATE_GROUP_USERS_SUCCESS';
export const UPDATE_GROUP_USERS_FAIL = 'UPDATE_GROUP_USERS_FAIL';

export interface SetGroupDetailsData {
  type: typeof SET_GROUP_DETAILS_DATA;
  payload: SetGroupDetailsDataRequest;
}

export interface ClearUserSelectionFromSearchData {
  type: typeof CLEAR_USER_SELECTION_FROM_SEARCH_DATA;
}

// get list of all groups users | use another action to avoid mix data from user search and group user table
export interface GetGroupsUsers {
  type: typeof GET_GROUP_USERS;
  payload: ActionPayload<GetAllUsersRequest>
}

export interface GetGroupsUsersSuccess {
  type: typeof GET_GROUP_USERS_SUCCESS;
  payload: BaseResponse<GetAllUsersResponse>
}

export interface GetGroupsUsersFail {
  type: typeof GET_GROUP_USERS_FAIL;
  payload: BaseErrorResponse;
}

// add or remove users from group
export interface UpdateGroupUsers {
  type: typeof UPDATE_GROUP_USERS;
  payload: ActionPayload<UpdateGroupUsersModel>;
}

export interface UpdateGroupUsersSuccess {
  type: typeof UPDATE_GROUP_USERS_SUCCESS;
  payload: BaseResponse<UpdateGroupUsersResponse>;
}

export interface UpdateGroupUsersFail {
  type: typeof UPDATE_GROUP_USERS_FAIL;
  payload: BaseErrorResponse;
}

export type Actions =
  | SetGroupDetailsData
  | ClearUserSelectionFromSearchData
  | GetGroupsUsers
  | GetGroupsUsersSuccess
  | GetGroupsUsersFail
  | UpdateGroupUsers
  | UpdateGroupUsersSuccess
  | UpdateGroupUsersFail

export interface State {
  error: string;
  successMessage: string,
  loading: boolean;
  usersUpdated: boolean
  infoEditState: boolean;
  infoDataToEdit: IGroupInfoData;
  limit: number;
  page: number;
  totalPages: number;
  totalCount: number;
  users: UserManagementUser[];
  usersToAdd: UserManagementUser[];
  filters: IFiltersGroupDetails;
}

export const infoDataToEditData: IGroupInfoData = {
  name: '',
  locationIds: [],
  addUserSearch: '',
  addAll: false,
};
export const initFiltersData: IFiltersGroupDetails = {
  search: '',
};
const initialState: State = {
  error: '',
  successMessage: '',
  loading: false,
  usersUpdated: false,
  infoEditState: false,
  infoDataToEdit: infoDataToEditData,
  limit: 10,
  page: 1,
  totalPages: 1,
  totalCount: 0,
  users: [],
  usersToAdd: [],
  filters: initFiltersData,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SET_GROUP_DETAILS_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CLEAR_USER_SELECTION_FROM_SEARCH_DATA: {
      return {
        ...state,
        users: [],
        usersToAdd: [],
        page: 1,
        totalPages: 1,
        totalCount: 0,
      };
    }

    case GET_GROUP_USERS:
      return {
        ...state,
        loading: true,
      };
    case GET_GROUP_USERS_SUCCESS: {
      const data = action.payload.data.result.data;

      return {
        ...state,
        loading: false,
        users: data.users,
        limit: data.limit,
        page: data.page,
        totalPages: data.totalPages,
        totalCount: data.totalCount,
      };
    }
    case GET_GROUP_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: t`There was an error getting all users. Please try again.`,
      };

    case UPDATE_GROUP_USERS:
      return {
        ...state,
        usersUpdated: false,
        error: '',
      };
    case UPDATE_GROUP_USERS_FAIL:
      return {
        ...state,
        usersUpdated: false,
        error: t`There was an error updating users.`,
      };
    case UPDATE_GROUP_USERS_SUCCESS: {
      return {
        ...state,
        usersUpdated: true,
        error: '',
        successMessage: t`Group users was updated`,
      };
    }

    default:
      return state;
  }
}

// Actions
export function setGroupDetailsData(data: SetGroupDetailsDataRequest): SetGroupDetailsData {
  return {
    type: SET_GROUP_DETAILS_DATA,
    payload: data,
  };
}

export function clearUserSelectionFromSearchData(): ClearUserSelectionFromSearchData {
  return {
    type: CLEAR_USER_SELECTION_FROM_SEARCH_DATA,
  };
}

// use another action to avoid mix data from user search and group user table
export function getGroupsUsers(data: GetAllUsersRequest): GetGroupsUsers {
  return {
    type: GET_GROUP_USERS,
    payload: {
      request: {
        method: 'GET',
        url: '/api/users',
        data,
      },
    },
  };
}

export function updateGroupUsers(data: UpdateGroupUsersRequest): UpdateGroupUsers {
  let url = `/api/groups/${data.groupId}/users/?`;

  if (data.search) {
    url += `&search=${data.search}`;
  }

  if (data.addAll) {
    url += `&addAll=${data.addAll}`;
  }

  return {
    type: UPDATE_GROUP_USERS,
    payload: {
      request: {
        method: 'PUT',
        url,
        data: data.userData,
      },
    },
  };
}
