import { State as ProfileState } from "../profileDuck";
import { State as ExecutiveAssistantState } from "./index";
import { UserDocument, UserManagementUser } from "../../../../Admin/Store/userManagement/models";
import { RolesAccessType } from "../profileDuck/models";

// get access to user profile for executiveAssistant role
export function isAnotherUserSelectedByExecutiveAssistant(profile: ProfileState, executiveAssistant: ExecutiveAssistantState): boolean {
  const { roleAccess } = profile;
  const { selectedUser } = executiveAssistant;

  return (roleAccess.executiveAssistant || roleAccess.delegate || roleAccess.localAdmin || roleAccess.superAdmin) && !!selectedUser;
}

// return account user id or selected user id in case of choose by executiveAssistant role
export function getUserIdForBooking(profile: ProfileState, executiveAssistant: ExecutiveAssistantState): string {
  const { selectedUser } = executiveAssistant;
  const anotherUserSelectedByExecutiveAssistant = isAnotherUserSelectedByExecutiveAssistant(profile, executiveAssistant);

  return anotherUserSelectedByExecutiveAssistant && selectedUser?.id ? selectedUser.id : profile.id;
}

export function getUserGroupsForBooking(profile: ProfileState, executiveAssistant: ExecutiveAssistantState): string[] {
  const { selectedUser } = executiveAssistant;
  const anotherUserSelectedByExecutiveAssistant = isAnotherUserSelectedByExecutiveAssistant(profile, executiveAssistant);

  return anotherUserSelectedByExecutiveAssistant && selectedUser?.id ? selectedUser.groups : profile.groups;
}

export function getDelegatedUserId(roleAccess: RolesAccessType, selectedUser?: UserManagementUser): string | undefined {
  const hasDelegatedAccess = roleAccess.executiveAssistant || roleAccess.delegate || roleAccess.superAdmin || roleAccess.localAdmin;

  return hasDelegatedAccess ? selectedUser?.id : undefined;
}

// return account user restrictions or selected user restrictions in case of choose by executiveAssistant role
interface IGetUserCovidDocRestrictions {
  approvedAccommodation: boolean;
  actualCovidDocument: UserDocument | null;
}

export function getUserCovidDocRestrictions(profile: ProfileState, executiveAssistant: ExecutiveAssistantState): IGetUserCovidDocRestrictions {
  const { selectedUser } = executiveAssistant;
  const anotherUserSelectedByExecutiveAssistant = isAnotherUserSelectedByExecutiveAssistant(profile, executiveAssistant);

  const restrictions = {
    approvedAccommodation: profile.approvedAccommodation,
    actualCovidDocument: profile.actualCovidDocument,
  };

  // if another user is selected - get them covid doc data
  if (anotherUserSelectedByExecutiveAssistant && selectedUser) {
    restrictions.approvedAccommodation = selectedUser.approvedAccommodation;
    restrictions.actualCovidDocument = selectedUser.userDocument;
  }

  return restrictions;
}
