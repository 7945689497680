import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useTypedSelector} from "../../../../../Store/Redux/store";
import {Trans, t} from "@lingui/macro";
import Popup from "reactjs-popup";
import styles from "../../../Locations/LocationForm/styles.module.scss";

import Box from "../../../../../Components/Box";
import Button from "../../../../../Components/Button";
import UsersSelectAll from "./UsersSelectAll";
import UsersMultiSelect from "./UsersMultiSelect";
import {setGroupDetailsData, updateGroupUsers} from "../../../../Store/groupDetails";
import { UpdateGroupUsersRequest } from "../../../../Store/groupDetails/models";

interface Props {
  open: boolean;
  onCancelAction: any;
}

export default function AddMembersModal(props: Props) {
  const dispatch = useDispatch();
  const { config, groupDetails } = useTypedSelector(state => state);
  const { usersToAdd, infoDataToEdit } = groupDetails;
  const { groupId } = useParams<{ groupId: string; }>();
  const {
    open,
    onCancelAction,
  } = props;
  // disable add if no user is selected
  const disableAddButton = !usersToAdd?.length && !infoDataToEdit.addAll;

  const onAddMembers = () => {
    const userIds = usersToAdd.map(user => user.id);
    // add by single users ids select
    const updatedData: UpdateGroupUsersRequest = {
      groupId: groupId,
      userData: {
        userIdsToAdd: userIds,
        userIdsToDelete: [],
      },
    };

    // add by search result bulk action
    if (infoDataToEdit.addAll) {
      updatedData.addAll = infoDataToEdit.addAll;
      updatedData.search = infoDataToEdit.addUserSearch;
    }

    dispatch(updateGroupUsers(updatedData));
    onCancelAction(); // close popup
  };

  const clearSelectedData = () => {
    dispatch(setGroupDetailsData({ usersToAdd: [] }));
  };

  const onCancel = () => {
    onCancelAction(); // close popup
    clearSelectedData();
  };

  return (
    <Popup
      className={'modal'}
      closeOnDocumentClick
      contentStyle={{
        maxWidth: 410,
        width: '100%',
      }}
      modal
      onClose={onCancel}
      open={open}
    >
      <div className="modal-inner">
        <div className="modal-header">
          <h2>
            <Trans>
              Add members
            </Trans>
          </h2>

          <svg className="modal-header__close" height="30px" onClick={() => onCancelAction()} viewBox="0 0 30 30" width="30px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g id="Booking-savedall" transform="translate(-875.000000, -132.000000)">
                <g id="Group-8" transform="translate(515.000000, 112.000000)">
                  <g id="icons/close" transform="translate(360.000000, 20.000000)">
                    <rect fill={config.theme.primaryLight} height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                    <path d="M20.704633,9.29536704 C21.0984557,9.68918977 21.0984557,10.3277026 20.704633,10.7215253 L16.4261582,15 L20.704633,19.2784747 C21.0984557,19.6722974 21.0984557,20.3108102 20.704633,20.704633 C20.3108102,21.0984557 19.6722974,21.0984557 19.2784747,20.704633 L15,16.4261582 L10.7215253,20.704633 C10.3277026,21.0984557 9.68918977,21.0984557 9.29536704,20.704633 C8.90154432,20.3108102 8.90154432,19.6722974 9.29536704,19.2784747 L13.5738418,15 L9.29536704,10.7215253 C8.90154432,10.3277026 8.90154432,9.68918977 9.29536704,9.29536704 C9.68918977,8.90154432 10.3277026,8.90154432 10.7215253,9.29536704 L15,13.5738418 L19.2784747,9.29536704 C19.6722974,8.90154432 20.3108102,8.90154432 20.704633,9.29536704 Z" fill={config.theme.primary}></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={`modal-inner-content ${styles.modalInnerContent}`}>
          <Box marginBottom={15}>
            <UsersSelectAll />
          </Box>
          <Box marginBottom={15}>
            <UsersMultiSelect />
          </Box>
          <Box display="flex" justifyContent="end">
            <Button
              aria-label={t`cancel group edition`}
              name={t`cancel group edition`}
              onClick={() => onCancelAction()}
              size="sm"
              type="clear"
            >
              <Trans>Cancel</Trans>
            </Button>

            <Button
              aria-label={t`confirm group edition`}
              disabled={disableAddButton}
              name={t`confirm group edition`}
              onClick={() => onAddMembers()}
              size="sm"
            >
              <Trans>Add members</Trans>
            </Button>
          </Box>
        </div>
      </div>
    </Popup >
  );
}
