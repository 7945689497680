import React, { useMemo } from "react";
import { DailyCalendarViewRowProps } from "./types";
import { Box, useTheme } from "@mui/material";
import { isCalendarViewScheduleVisible, isCalendarViewStepDisabled, resolveCalendarViewScheduleInterval, resolveCalendarViewScheduleSpan } from "./utils";
import { DailyCalendarViewStep } from "./daily-calendar-view-step";
import { DailyCalendarViewSchedule } from "./daily-calendar-view-schedule";
import { t } from "@lingui/macro";
import { addDays, isAfter, isEqual, startOfDay } from "date-fns";

export const DailyCalendarViewRow: React.FC<DailyCalendarViewRowProps> = (props) => {
  const {
    item,
    stepDates,
    selection,
    stepHeight,
    stepWidth,
    yIndex,
    step,
    firstStepDate,
    lastStepDate,
    handleStepClick,
    handleSelectionClick,
  } = props;
  const theme = useTheme();
  const maxDate = useMemo(() => addDays(startOfDay(new Date()), item.reservationDayLimit || 180), [item.reservationDayLimit]);

  return (
    <Box display="flex" position="relative">
      {stepDates.map((stepDate, xIndex) => {
        const disabled = isEqual(stepDate, addDays(maxDate, 1)) || isAfter(stepDate, addDays(maxDate, 1));

        return (
          <DailyCalendarViewStep
            disabled={disabled || isCalendarViewStepDisabled(stepDate, item.id, item.schedules, selection)}
            disabledColor={disabled ? theme.palette.grey[100] : undefined}
            height={stepHeight}
            key={`${item.id}${stepDate.toISOString()}`}
            onClick={() => handleStepClick(item.id, stepDate)}
            tooltip={t`Click to select time`}
            width={stepWidth}
            xIndex={xIndex}
            yIndex={yIndex}
          />
        );
      })}
      {item.schedules?.map(({ startDate, endDate, summary }) => isCalendarViewScheduleVisible(endDate, step, firstStepDate) ? (
        <DailyCalendarViewSchedule
          endDate={endDate}
          firstStepDate={firstStepDate}
          key={`${item.id}${startDate.toISOString()}${endDate.toISOString()}`}
          lastStepDate={lastStepDate}
          startDate={startDate}
          step={step}
          stepHeight={stepHeight}
          stepWidth={stepWidth}
          summary={summary}
        />
      ) : undefined)}
      {selection && selection.id === item.id && selection.start ? (
        <Box
          bgcolor={selection.start && selection.end ? theme.background.blue : theme.palette.grey[100]}
          borderRadius={2}
          height={stepHeight}
          left={resolveCalendarViewScheduleSpan(selection.start, firstStepDate, step, firstStepDate) * stepWidth}
          onClick={() => handleSelectionClick()}
          position="absolute"
          top={0}
          width={
            selection.end 
              ? (resolveCalendarViewScheduleInterval(selection.start, selection.end, step, firstStepDate, lastStepDate) * stepWidth) 
              : stepWidth
          }
          zIndex={10}
        />
      ) : undefined }
    </Box>
  );
};