import InfiniteScroll from 'react-infinite-scroll-component';
import PushNotification from './Notification';
import styles from '../styles.module.scss';
import Text from '../../Text';
import { getPushNotifications } from '../../../App/Store/Notifications';
import { PushNotificationModel } from '../../../App/Store/Notifications/models';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';

export default function InfiniteNotificationList() {
  const dispatch = useDispatch();
  const { profile, pushNotifications } = useTypedSelector(state => state);

  const [notifications, setNotifications] = useState<PushNotificationModel[]>([]);
  const [hasMore, setHasMore] = useState(false);

  const onLoadMoreNotifications = () => {
    // load new notifications is pages exist
    if (pushNotifications.page < pushNotifications.totalPages) {
      dispatch(getPushNotifications({ userId: profile.id, page: pushNotifications.page + 1 }));
    }
  };

  // define if need to call onLoadMoreNotifications
  useEffect(() => {
    if (pushNotifications.page < pushNotifications.totalPages) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [pushNotifications.page]);

  // concat notifications from previous and next pages \ hasMore status after load
  useEffect(() => {
    if (pushNotifications.page !== 1) {
      setNotifications([...notifications, ...pushNotifications.pushNotifications]);
    } else {
      // set init data after data load list
      setNotifications(pushNotifications.pushNotifications);
      setHasMore(pushNotifications.page < pushNotifications.totalPages);
    }
  }, [pushNotifications.pushNotifications]);

  return (
    <>
      {
        notifications.length ?
          <InfiniteScroll
            className={styles.notificationInfiniteScroll}
            dataLength={pushNotifications.page * 10} // max items count for each load
            hasMore={hasMore}
            height={'auto'}
            loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
            next={onLoadMoreNotifications}
          >
            {
              notifications.map((notification, index) => {
                return (<PushNotification key={index} notification={notification} />);
              })
            }
          </InfiniteScroll> :
          <Text align="center" className={styles.noNotifications} color="lightGray" size="md">
            <Trans>
              No Notifications
            </Trans>
          </Text>
      }
    </>
  );
}
