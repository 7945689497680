import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import styles from './styles.module.scss';

interface CustomPaginationProps {
  count: number; // number of pages to show
  onChange: (page: number) => void; // handle change page
}

export default function CustomPagination({
  count = 5,
  onChange,
}: CustomPaginationProps) {

  const onChangePage = (_: any, page: number) => {
    onChange(page);
  };

  return (
    <Pagination
      className={'pagination-custom'}
      count={count}
      onChange={onChangePage}
      renderItem={(item) => {
        if (item.type === 'previous') {
          return (
            <PaginationItem className={styles.previousButton} {...item} />
          );
        }

        if (item.type === 'next') {
          return (
            <PaginationItem className={styles.nextButton} {...item} />
          );
        }

        return (
          <PaginationItem {...item} />
        );
      }}
    />
  );
}