import Box from 'Components/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import CancelReservationModal from '../MonthlyBooking/CancelReservationModal';
import clsx from 'clsx';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { editCalendarDates, updateSelectedWeekDays } from '../../../../Store/calendar';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useTypedSelector } from '../../../../../Store/Redux/store';
import {EditCalendarWeekDaysModel, WEEK_DAYS, WeekDaysEnum} from 'Admin/Store/calendar/models';
import {
  getAllDisabledDates,
  getDisabledDaysFromWeeks, getUpdatedWeekDays,
  reservationFromWeeklyExist,
} from '../Helpers/Helpers';

export default function WeeklyBooking() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const { adminCalendar } = useTypedSelector(state => state);

  const { calendarDates, initialMonth, numberOfMonths } = adminCalendar;

  const disabledDates = calendarDates.disabledDates.map(date => new Date(date));
  const reservedDates = calendarDates.reservedDates.map(date => new Date(date));
  const weekDaysArray = Object.keys(WEEK_DAYS).map(weekDay => WEEK_DAYS[parseInt(weekDay)]);

  const [tempDisabledDaysArray, setTempDisabledDaysArray] = useState<Date[] | string[]>([]); // temp data for daysToCancelReservations
  const [tempDisabledWeekDays, setTempDisabledWeekDays] = useState<WeekDaysEnum[]>([]); // temp data for confirmation popup
  const [tempWeekDays, setTempWeekDays] = useState<EditCalendarWeekDaysModel>({}); // temp data for confirmation popup
  const [resetReservationsOpen, setResetReservationsOpen] = useState(false); // cancel reservation from weekly confirmation popup state

  const closeReservationsModal = () => setResetReservationsOpen(false); // cancel reservation from weekly confirmation popup state

  const handleSelectWeekDay = (weekDay: number, selected: boolean) => {
    const disabledWeekDaysArray = getDisabledDaysFromWeeks(weekDay, initialMonth, numberOfMonths);
    const updatedDisabledDays = getAllDisabledDates(selected, disabledWeekDaysArray, disabledDates);

    const updateDisabledWeekDays = calendarDates.disabledWeekDays?.includes(weekDay)
      ? calendarDates.disabledWeekDays?.filter(wd => wd !== weekDay)
      : [...(calendarDates.disabledWeekDays ?? []), weekDay]; // array of all disabled week days
    // data to set for editCalendarDates() - disabledWeekDays or enableWeekDays
    const updatedWeekDays: EditCalendarWeekDaysModel = getUpdatedWeekDays(selected, updateDisabledWeekDays);

    if (reservationFromWeeklyExist(updatedDisabledDays, reservedDates)) {
      setResetReservationsOpen(true);
      // data for cancel/save reservation modal
      setTempDisabledDaysArray(disabledWeekDaysArray);
      setTempDisabledWeekDays(updateDisabledWeekDays);
      setTempWeekDays(updatedWeekDays);
    } else {
      dispatch(editCalendarDates({
        ...updatedWeekDays,
        locationId,
      }));

      dispatch(updateSelectedWeekDays(updateDisabledWeekDays));
    }
  };

  const onUpdateDisabledDaysBulk = (cancelExistingReservations: boolean) => {
    let daysToCancelReservations: Date[] | string[] = [];
    if (cancelExistingReservations) {
      // @ts-ignore
      daysToCancelReservations = [...tempDisabledDaysArray];
    }

    dispatch(editCalendarDates({
      ...tempWeekDays,
      locationId,
      daysToCancelReservations,
    }));
    dispatch(updateSelectedWeekDays(tempDisabledWeekDays));
  };

  return (
    <Box paddingBottom={20} paddingTop={20}>
      <Text size="lg" weight="semi-bold">
        <Trans>Days available for booking</Trans>
      </Text>

      <Box className={styles.weekDaysContainer} dataTestId="admin-calendar-weekly">
        {weekDaysArray.map((weekDay, index) => {
          const isSelected = calendarDates.disabledWeekDays?.includes(weekDay.value);
          const classes = clsx(styles.hourContainer, isSelected && styles.selected);

          return (
            <Box alignItems="center" display="flex" justifyContent="between" key={weekDay.label + index}>
              <ButtonBase data-testid="admin-calendar-weekly-bookingButton" disableRipple onClick={() => handleSelectWeekDay(weekDay.value, isSelected)}>
                <Box className={classes} width={68}>
                  <Text align="center" color={isSelected ? 'black' : 'blue'} size="md" weight="semi-bold">
                    {weekDay.label}
                  </Text>
                </Box>
              </ButtonBase>
            </Box>
          );
        })}
      </Box>

      <CancelReservationModal
        close={closeReservationsModal}
        onUpdate={onUpdateDisabledDaysBulk}
        open={resetReservationsOpen}
      />
    </Box>
  );
}
