import { getTimezoneOffset } from "date-fns-tz";

export const resolveGMTOffsetLabel = (timezone: string): string => {
  const utcMillisecondsOffset = getTimezoneOffset(timezone, new Date());

  if (utcMillisecondsOffset === 0) {
    return `GMT`;
  }

  const utcHoursOffset = utcMillisecondsOffset / 1000 / 60 / 60;  
  const formattedHoursOffset = utcHoursOffset > 0
    ? "+" + `${utcHoursOffset}`.padStart(2, "0") + ":00"
    : "-" + `${utcHoursOffset * -1}`.padStart(2, "0") + ":00";

  return `GMT${formattedHoursOffset}`;
};
