import { debounce } from 'lodash';
import AccordionCalendar from '../Calendar/AccordionCalendar';
import BookingDetails from '../BookingDetails';
import bookingStyles from '../styles.module.scss';
import Box from 'Components/Box';
import Breadcrumbs from 'App/Components/Breadcrumbs';
import Button from 'Components/Button';
import CloseIcon from '@material-ui/icons/Close';
import Filters from '../Filters';
import IconButton from '@material-ui/core/IconButton';
import { Button as MUIButton } from "@mui/material";
import Map from './Map';
import ParkingSpot from './ParkingSpot';
import Popup from 'reactjs-popup';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import useSnackbar from 'Components/Snackbar/useSnackbar';
import { clearCreateNewBookingDataIfBookingCreated, setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { DeskDetails } from './DeskDetails';
import { getFloorSchema, setFloorMapApiRequestsData } from 'Admin/Store/floorMapApiRequestsDuck';
import { setBookingsData } from 'App/Store/Bookings/bookingDuck';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import {
  onLoadDesks,
  onLoadDesksFilters,
} from "../../../../Admin/Pages/ReservationJustInTime/ReservationJustIntimeDetails/Floor/helpers";
import { DeskStatusEnum } from 'Admin/Store/floorMapDuck/desk/models';
import { ReduxStore } from 'Store/Redux';
import { setLoading } from 'App/Store/Bookings/availableDesksDuck';
import { Typography } from '@mui/material';
import { FloorSelect, LocationBookingsSearch, TogetherUser } from 'components';
import { ExpandMore } from '@mui/icons-material';
import { bookingsSlice } from 'store';
import { useHistory } from 'react-router-dom';
import { decodeResetQueryParam } from 'utils';
import { Dictionary } from 'ts-essentials';
import { useStorageFileObjectURL } from 'hooks';
import { getLocation, getLocationDisabledDates } from 'App/Store/Locations/locationsDuck';

interface DeskProps {
  type?: 'support' | 'desk';
}

export default function Desk({ type = 'desk' }: DeskProps) {
  const dispatch = useDispatch();
  const [open] = useSnackbar();
  const history = useHistory();
  const {
    adminFloorMap,
    adminFloorMapApiRequests,
    booking,
    config,
    executiveAssistant,
    createNewBooking,
    locations: locationsDuck,
  } = useTypedSelector(state => state);
  const { desks, mapSize, name, previewUrl, mapImageBounds } = adminFloorMap;
  const {
    bookingType,
    dateFrom,
    dateTo,
    deskId,
    floorId,
    timeFrom,
    timeTo,
    weeklySlots,
    locationId,
  } = createNewBooking;
  const { loading, locations } = locationsDuck;
  const { selectedUser } = executiveAssistant;
  const selectedDesk = deskId ? desks[deskId] : undefined;
  const bookingPath = type === 'desk' ? 'create-new-booking' : 'create-new-boo king-support';
  const previewObjectURL = useStorageFileObjectURL(previewUrl);
  // const prevCalendarData = usePrevious(calendarData);
  const selectedLocation = locations?.find(({ id }) => id === locationId);

  useEffect(() => {
    if (selectedLocation || !locationId) {
      return;
    }

    dispatch(getLocation(locationId));
    dispatch(getLocationDisabledDates({ locationId }));
  }, [selectedLocation, locationId]);

  useEffect(() => {
    const reset = decodeResetQueryParam<Dictionary<string, "locationId" | "floorId" | "deskId">>(history.location.search);

    if (reset) {
      const { locationId, floorId, deskId } = reset;

      dispatch(setCreateNewBookingData({
        floorId,
        locationId,
        deskId,
        bookingType: "custom",
        dateFrom: undefined,
        dateTo: undefined,
        filters: {},
        parkingSpotId: undefined,
        timeFrom: undefined,
        timeTo: undefined,
      }));
      history.push(history.location.pathname);
    }
  }, [history.location.search]);

  useEffect(() => {
    if (floorId) {
      dispatch(getFloorSchema({ floorId, showOwners: true }));
    }
  }, [floorId, selectedUser]);

  useEffect(() => {
    if (adminFloorMapApiRequests.error) {
      open({
        text: adminFloorMapApiRequests.error,
        type: 'error',
        onClose: () => {
          dispatch(setFloorMapApiRequestsData({ error: '' }));
        },
      });
    }
  }, [adminFloorMapApiRequests.error]);

  const handleDesksRequest = (
    {
      floorId,
      bookingType,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
      weeklySlots,
    }: Partial<ReduxStore["createNewBooking"]>,
  ) => {
    if (floorId && bookingType) {
      const deskFilters: onLoadDesksFilters = {
        dateFrom,
        dateTo,
        timeFrom,
        timeTo,
        weeklySlots,
        bookingType,
      };
      onLoadDesks(floorId, deskFilters, dispatch); // update desk list
    }
  };
  const debouncedHandleDesksRequest = useCallback(debounce(handleDesksRequest, 900), []);

  useEffect(() => {
    dispatch(setLoading(true));
    debouncedHandleDesksRequest({
      floorId,
      bookingType,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
      weeklySlots,
    });
  }, [bookingType, floorId, dateFrom, dateTo, timeFrom, timeTo, weeklySlots]);

  // useEffect(() => {
  //   const isCalendarDataChange = prevCalendarData && !_.isEqual(calendarData, prevCalendarData);
  //   if (isCalendarDataChange) {
  //     dispatch(setCreateNewBookingData({ deskId: undefined }));
  //   }
  // }, [bookingType, dateFrom, dateTo, timeFrom, timeTo, selectedWeekDays]);

  // Closes popup everytime the page render or close
  useEffect(() => {
    handleClosePopup();
  }, []);

  // Clear booking data if booking was successfully created on cleanup
  useEffect(() => {
    return () => {
      dispatch(clearCreateNewBookingDataIfBookingCreated());
      handleClosePopup();
    };
  }, []);

  const handleClosePopup = () => {
    dispatch(setBookingsData({ bookingCreated: false }));
  };

  const handleFloorChange = (floorId: string) => {
    dispatch(setCreateNewBookingData({ floorId }));
  };

  return (
    <>
      <Popup
        className="modal"
        contentStyle={{
          maxWidth: 570,
          width: '100%',
        }}
        modal
        onClose={() => {
          handleClosePopup();
        }}
        open={booking.bookingCreated}
      >
        <Box padding="2px 1px">
          <Box alignItems="center" display="flex" justifyContent="between">
            {
              selectedDesk?.status === DeskStatusEnum.ApprovalRequired
                ? <Text size="xlg" weight="semi-bold"><Trans>Booking is pending approval</Trans></Text>
                : <Text size="xlg" weight="semi-bold"><Trans>Desk was successfully booked</Trans></Text>
            }
            <IconButton
              onClick={handleClosePopup}
              size="small"
              style={{
                backgroundColor: config.theme.primaryLight,
                borderRadius: 8,
                height: 30,
                width: 30,
              }}
            >
              <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
            </IconButton>
          </Box>

          <Box margin="9px 0 18px">
            <Text color="gray" size="md">
              <Trans>You can find all booking details on the booking list page.</Trans>
            </Text>
          </Box>

          <Box alignItems="center" display="flex" justifyContent="end">
            <Button
              aria-label={t`make a new booking`}
              name={t`make a new booking`}
              size="sm"
              to={`/${bookingPath}/select-location`}
              type="clear"
              withShadow={false}
            >
              <Trans>Make a new booking</Trans>
            </Button>

            <Button
              aria-label={t`back to booked list`}
              name={t`back to booked list`}
              size="sm"
              to="/"
            >
              <Trans>Back to booked list</Trans>
            </Button>
          </Box>
        </Box>
      </Popup>

      <Box display="grid" gap={30} gridTemplateColumns="8fr 4fr" paddingBottom={50}>
        <Box>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="between"
            marginBottom={15}
          >
            <Breadcrumbs type={type} />

            <Filters />
          </Box>
          <Box display="flex" marginBottom={8}>
            <h3 className={styles.deskTitle} style={{ marginRight: 8 }}><Trans>Floor map</Trans>,</h3>
            {/* TODO: remove theme provider after mui lib migration */}
            <FloorSelect
              getOptionDisabled={({ extra }) => !extra?.availableDesks}
              include={["extra.availableDesks"]}
              locationId={locationId}
              onChange={handleFloorChange}
              value={floorId}
            />
          </Box>
          {loading ? (
            <Box borderRadius={14} height={300}>
              <Skeleton classes={{ root: styles.skeletonRoot }} height="100%" variant="rect" />
            </Box>
          ) : (
            <Box display="grid" gap={15}>
              <Text weight="semi-bold">
                {(previewObjectURL) && (
                  <Map
                    mapImageBounds={mapImageBounds}
                    mapSize={mapSize}
                    name={name}
                    previewUrl={previewObjectURL}
                  />
                )}
              </Text>
            </Box>
          )}
        </Box>

        <Box className={bookingStyles.stickyBookingWrapper}>
          <Box display="flex" justifyContent="between" marginBottom={15}>
            <Text size="xlg" weight="semi-bold">
              <Trans>Booking details</Trans>
            </Text>
            {/* TODO: remove theme provider after mui lib migration */}
            <LocationBookingsSearch
              floorId={floorId}
              locationId={locationId}
              onFloorChange={handleFloorChange}
              onItemClick={(booking) => dispatch(bookingsSlice.actions.setCurrentNeighbor(booking))}
              trigger={(
                <MUIButton variant="text">
                  <Box alignItems="center" display="flex">
                    <TogetherUser fill={"#1E1F7B"} stroke={"#1E1F7B"} sx={{ width: 16 }} />
                    <Typography fontSize={14} fontWeight="600" marginLeft={1} marginRight={0.5}>{t`Who's In`}</Typography>
                    <ExpandMore />
                  </Box>
                </MUIButton>
              )}
            />
          </Box>

          <AccordionCalendar selectedDesk={!!selectedDesk} />

          <Box marginTop={10}>
            <ParkingSpot />
          </Box>

          <Box className={styles.deskDetailsWrapper} marginTop={50}>
            {selectedDesk &&
              <DeskDetails desk={selectedDesk} />
            }
          </Box>

          <Box className={bookingStyles.stickyBookingRow}>
            <BookingDetails type={type} />
          </Box>
        </Box>
      </Box>
    </>
  );
}
