import {useTypedSelector} from "../../../../Store/Redux/store";
import Popup from "reactjs-popup";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PopupProps } from "reactjs-popup/dist/types";

interface Props extends PopupProps {
  children: React.PropsWithChildren<any>;
  iconButtonStyle?: React.CSSProperties;
  iconButtonColor?: string;
  iconButtonName?: string;
  iconButtonTestId?: string;
  iconButtonClassName?: string;
}

export default function TableSubmenuButton(props: Props) {
  const { config } = useTypedSelector(state => state);
  const {
    // default popup params
    children,
    closeOnDocumentClick = true,
    contentStyle = { marginLeft: 27, width: 230 },
    position = "bottom right",
    // default icon params
    iconButtonStyle = { padding: 0 },
    iconButtonColor = config.theme.primary,
    iconButtonName = 'table-submenu',
    iconButtonTestId = '',
    iconButtonClassName = '',
  } = props;

  return (
    <Popup
      {...props}
      closeOnDocumentClick={closeOnDocumentClick}
      contentStyle={contentStyle}
      position={position}
      trigger={
        <IconButton
          aria-label={iconButtonName}
          className={iconButtonClassName}
          data-testid={iconButtonTestId}
          name={iconButtonName}
          style={iconButtonStyle}
        >
          <MoreVertIcon style={{ color: iconButtonColor, fontSize: 22 }} />
        </IconButton>
      }
    >
      {children}
    </Popup>
  );
}
