import { add, eachMinuteOfInterval, isAfter, isBefore, isEqual } from "date-fns";
import { MinuteStep } from "types";
import { flooredCalendarViewDate } from "./floored-calendar-view-date";
import { ceiledCalendarViewDate } from "./ceiled-calendar-view-date";

export const resolveCalendarViewScheduleInterval = (
  startDate: Date,
  endDate: Date,
  step: MinuteStep,
  firstStep: Date,
  lastStep: Date,
): number => {
  const flooredStartDate = flooredCalendarViewDate(startDate, step);
  const ceiledEndDate = ceiledCalendarViewDate(endDate, step);
  const start = isAfter(firstStep, flooredStartDate) ? firstStep : flooredStartDate;
  const end = isBefore(lastStep, ceiledEndDate) ? add(lastStep, { minutes: step }) : ceiledEndDate;

  if (isEqual(start, end) || isAfter(start, end)) {
    return 0;
  }
  
  const minutes = eachMinuteOfInterval({ start, end }, { step });

  return minutes.slice(0, -1).length;
};
