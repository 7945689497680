import { Children, PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

interface Props {
  className?: string;
}

export function StaggeredItems({ children, className }: PropsWithChildren<Props>) {
  return (
    <motion.div
      animate="visible"
      className={className}
      initial="hidden"
      variants={{
        visible: {
          opacity: 1,
          transition: {
            when: 'beforeChildren',
            staggerChildren: 0.1,
          },
        },
        hidden: {
          opacity: 0,
          transition: {
            when: 'afterChildren',
          },
        },
      }}
    >
      {Children.map(children, child => (
        <motion.div
          variants={{
            visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
            hidden: { opacity: 0, y: 50 },
          }}
        >
          {child}
        </motion.div>
      ))}
    </motion.div>
  );
}
