import Box from 'Components/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { setCreateNewVisitData } from 'App/Store/Bookings/createNewVisitDuck';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'Store/Redux/store';
import { Trans } from "@lingui/macro";

const timeSlots = [
  { timeFrom: '09:00', timeTo: '11:00' },
  { timeFrom: '11:00', timeTo: '13:00' },
  { timeFrom: '13:00', timeTo: '15:00' },
  { timeFrom: '15:00', timeTo: '17:00' },
  { timeFrom: '17:00', timeTo: '19:00' },
];

export default function SelectTimeSlot() {
  const dispatch = useDispatch();
  const { config, createNewVisit } = useTypedSelector(state => state);

  const { timeFrom, timeTo } = createNewVisit;

  return (
    <Box className={styles.container}>
      <Text size="md" weight="semi-bold">
        <Trans>Time slot</Trans>
      </Text>

      {timeSlots.map((timeSlot, index) => {
        const isSelected = timeSlot.timeFrom === timeFrom && timeSlot.timeTo === timeTo;

        const onSelect = () => {
          dispatch(setCreateNewVisitData({
            timeFrom: timeSlot.timeFrom,
            timeTo: timeSlot.timeTo,
          }));
        };

        return (
          <ButtonBase
            className={clsx(styles.timeSlot, isSelected && styles.isSelected)}
            key={`${timeSlot.timeFrom} ${timeSlot.timeTo} ${index}`}
            onClick={onSelect}
          >
            <Text size="md" weight={isSelected ? 'bold' : 'regular'}>
              <span style={{ color: isSelected ? config.theme.primary : '#5e5e5e' }}>
                {timeSlot.timeFrom}-{timeSlot.timeTo}
              </span>
            </Text>
          </ButtonBase>
        );
      })}
    </Box>
  );
}
