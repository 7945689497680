import { IFloorMapDrawing, IFloorMapDrawingUpdate } from "../mapDrawing/models";
import { FloorMapObjectTypeEnum, IApprover, MapDrawingGroup, MapDrawingOwner } from "../models";

export enum DeskStatusEnum {
  Available = "AVAILABLE",
  Unavailable = "UNAVAILABLE",
  ApprovalRequired = "APPROVAL_REQUIRED",
  DeleteInProgress = "DELETE_IN_PROGRESS",
}

export type DeskStatus =
  | DeskStatusEnum.Available
  | DeskStatusEnum.Unavailable
  | DeskStatusEnum.ApprovalRequired
  | DeskStatusEnum.DeleteInProgress


export interface IFloorMapDesk extends IFloorMapDrawing {
  owners: MapDrawingOwner[];
  groups: MapDrawingGroup[];
  approvers: {
    approver1: IApprover;
    approver2: IApprover;
  };
  parentId: number | undefined;
  resourceIds: { [resourceId: string]: string };
  status: DeskStatus;
  type: FloorMapObjectTypeEnum.Desk;
}

export interface IFloorMapDeskUpdate extends IFloorMapDrawingUpdate {
  owners?: MapDrawingOwner[];
  groups?: MapDrawingGroup[];
  approvers?: {
    approver1: IApprover;
    approver2: IApprover;
  };
  resourceIds?: { [resourceId: string]: string };
  status?: DeskStatus;
}
