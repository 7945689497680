import { eachMinuteOfInterval, endOfDay, isAfter, startOfDay } from "date-fns";
import { MinuteStep } from "types";
import { isEqual } from "underscore";
import { ceiledCalendarViewDate } from "./ceiled-calendar-view-date";

export const resolveCalendarViewStepDates = (selectedDay: Date, step: MinuteStep, minDate?: Date): Date[] => {
  const start = startOfDay(selectedDay);
  const end = endOfDay(selectedDay);
  const minutes = eachMinuteOfInterval({ start, end }, { step });

  if (minDate) {
    const ceiledMinDate = ceiledCalendarViewDate(minDate, step);

    return minutes.filter((date) => isEqual(date, ceiledMinDate) || isAfter(date, ceiledMinDate));
  }
  
  return minutes;
};
