import clsx from 'clsx';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  dataTestId?: string;
  maxWidth?: string;
  style?: React.CSSProperties;
}

/**
 * Defines a common max-width for the application, aligning the content at the center of the screen.
 */
export default function Container({
  children, 
  className,
  dataTestId,
  maxWidth,
  style,
}: React.PropsWithChildren<Props>) {
  const classes = clsx(styles.container, className);

  return (
    <div
      className={classes}
      data-testid={dataTestId}
      style={{
        maxWidth,
        width: '100%',
        margin: '0 auto',
        ...style,
      }}
    >
      {children}
    </div>
  );
}

Container.defaultProps = {
  maxWidth: '1680px',
  style: {},
};
