import styles from "../styles.module.scss";
import { Trans, t } from "@lingui/macro";

import Box from "../../../../../../../Components/Box";
import Text from "../../../../../../../Components/Text";
import TextField from "../../../../../../../Components/TextField";
import { FloorMapObject } from "../../../../../../Store/floorMapDuck/models";
import { useState } from "react";
import { isNumberString } from "class-validator";
import { useDispatch } from "react-redux";
import { updateMeeting } from "Admin/Store/floorMapDuck";
import { Tooltip } from "@material-ui/core";
import Info from "Components/Icons/Info";

interface Props {
  meeting: FloorMapObject;
}

export default function MeetingDetailsReservationDayLimit(props: Props) {
  const { meeting } = props;
  const { id, reservationDayLimit } = meeting;
  const [reservationDayLimitValue, setReservationDayLimitValue] = useState(reservationDayLimit ? `${reservationDayLimit}` : "");
  const dispatch = useDispatch();
  const handleReservationDayLimitChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const { value = "" } = event.target;

    if (!value) {
      setReservationDayLimitValue("");
    }

    if (isNumberString(value)) {
      const reservationDayLimitIntValue = Number.parseInt(value);

      if (reservationDayLimitIntValue > 0) {
        setReservationDayLimitValue(`${reservationDayLimitIntValue}`);
      }
    }
  };

  const handleReservationDayLimitBlur = () => {
    if (!reservationDayLimitValue) {
      dispatch(updateMeeting({
        meetingId: typeof id === "string" ? parseInt(id) : id,
        meeting: { reservationDayLimit: undefined },
      }));
    } else {
      const reservationDayLimitIntValue = Number.parseInt(reservationDayLimitValue);

      if (reservationDayLimitIntValue !== reservationDayLimit) {
        dispatch(updateMeeting({
          meetingId: typeof id === "string" ? parseInt(id) : id,
          meeting: { reservationDayLimit: reservationDayLimitIntValue },
        }));
      }
    }
  };

  return (
    <Box className={styles.detailsItem}>
      <Box alignItems="center" display="flex">
        <Tooltip interactive={false} title={t`This change doesn't affect existing reservations`}>
          <span style={{ display: "inline-block", paddingRight: 4 }}>
            <Info size={12} />
          </span>
        </Tooltip>
        <Text color="gray" size="md">
          <Trans>Day limit:</Trans>
        </Text>
      </Box>
      <Text color="gray" size="md">
        <TextField
          classes={{ input: styles.textFieldInput }}
          id="meeting-reservationDayLimit"
          onBlur={handleReservationDayLimitBlur}
          onChange={handleReservationDayLimitChange}
          placeholder="Not set"
          value={reservationDayLimitValue}
        />
      </Text>
    </Box>
  );
}
