// @ts-nocheck

import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    --primary: ${({ theme }) => theme.primary};
    --primaryHover: ${({ theme }) => theme.primaryHover};
    --primaryLight: ${({ theme }) => theme.primaryLight};
    --primaryLighten20: ${({ theme }) => theme.primaryLighten20};
    --primaryLighten50: ${({ theme }) => theme.primaryLighten50};
    --primaryTransparent: ${({ theme }) => theme.primaryTransparent};
    --secondary: ${({ theme }) => theme.secondary};
    --error: ${({ theme }) => theme.error};
    --errorTransparent: ${({ theme }) => theme.errorTransparent};
    --warn: ${({ theme }) => theme.warn};
    --warnLight: ${({ theme }) => theme.warnLight};
    --success: ${({ theme }) => theme.success};
    --successTransparent: ${({ theme }) => theme.successTransparent};
    --grey: ${({ theme }) => theme.grey};
    --greyDark: ${({ theme }) => theme.greyDark};
    --greyDarkTransparent: ${({ theme }) => theme.greyDarkTransparent};
    --greyLight: ${({ theme }) => theme.greyLight};
    --greyLightTransparent: ${({ theme }) => theme.greyLightTransparent};
    --greyMedium: ${({ theme }) => theme.greyMedium};
    --greyTransparent: ${({ theme }) => theme.greyTransparent};
  }
`;

// @ts-check