import { useTypedSelector } from "../../Store/Redux/store";
import styles from "./styles.module.scss";
import Box from "../Box";
import Text from "../Text";
import { Color } from "../Styles/models";

interface Props {
  count: number;
  className?: string;
  boxColor?: string;
  textColor?: Color;
}

export default function NumberCounter(props: Props) {
  const { config } = useTypedSelector(state => state);
  const { count, className, boxColor = config.theme.primaryLight, textColor = 'blue' } = props;

  return (
    <Box
      backgroundColor={boxColor}
      className={`${styles.numberContainer} ${className}`}
    >
      <Text color={textColor} size="md" weight="semi-bold">
        {count}
      </Text>
    </Box>
  );
}
