import { ActionPayload, BaseErrorResponse, BaseResponse } from 'Store/Models/ReduxModels';
import { Location } from '../locationsDuck/models';
import { t } from '@lingui/macro';
import {
  GetLocationDetailsRequest,
  GetLocationDetailsResponse,
} from './models';

export const GET_LOCATION_DETAILS = 'GET_LOCATION_DETAILS';
export const GET_LOCATION_DETAILS_FAIL = 'GET_LOCATION_DETAILS_FAIL';
export const GET_LOCATION_DETAILS_SUCCESS = 'GET_LOCATION_DETAILS_SUCCESS';

export interface GetLocationDetails {
  type: typeof GET_LOCATION_DETAILS;
  payload: ActionPayload<GetLocationDetailsRequest>;
}
export interface GetLocationDetailsFail {
  type: typeof GET_LOCATION_DETAILS_FAIL;
  payload: BaseErrorResponse;
}
export interface GetLocationDetailsSuccess {
  type: typeof GET_LOCATION_DETAILS_SUCCESS;
  payload: BaseResponse<GetLocationDetailsResponse>;
}

export type Actions =
  | GetLocationDetails
  | GetLocationDetailsFail
  | GetLocationDetailsSuccess;

export interface State {
  error: string;
  loading: boolean;
  // Location details are stored as objects to make accessing and finding them
  // easier, as well as being able to store multiple location details at the same time
  // without having to worry about array operations, making processing faster
  locations: {
    [key: string]: Location;
  };
}

const initialState: State = {
  error: '',
  loading: false,
  locations: {},
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case GET_LOCATION_DETAILS:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case GET_LOCATION_DETAILS_FAIL:
      return {
        ...state,
        error: t`There was an error loading locations.`,
        loading: false,
      };
    case GET_LOCATION_DETAILS_SUCCESS: {
      const { data: location } = action.payload.data.result;
      const newLocations = { ...state.locations };

      newLocations[location.id] = location;

      return {
        ...state,
        error: '',
        loading: false,
        locations: newLocations,
      };
    }

    default:
      return state;
  }
}

// Actions
export function getLocationDetails(data: GetLocationDetailsRequest): GetLocationDetails {
  return {
    type: GET_LOCATION_DETAILS,
    payload: {
      request: {
        method: 'GET',
        url: `/api/locations/${data.locationId}`,
      },
    },
  };
}
