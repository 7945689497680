import { t } from "@lingui/macro";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { format } from "date-fns";
import { subscribeMessagingHandler } from "messaging";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ApiTag, togetherApi, useLazyGetMyReservationByIdQuery } from "store";

const slugs = ["room-reservation-failed-by-error", "room-reservation-failed-by-unavailability"];

const resolveTitleAndDescription = (slug?: string, room?: string, date?: string): [string, string] => {
  switch (slug) {
    case "room-reservation-failed-by-error":
      return [
        t`Your room reservation has failed!`,
        t`We couldn't finish your reservation for room ${room} on ${date} due to an issue on Outlook. Please try again.`,
      ];
    case "room-reservation-failed-by-unavailability":
      return [
        t`Your room reservation has failed!`,
        t`Room ${room} is not available at ${date}. Please try again with different dates.`,
      ];
    default:
      return ["", ""];
  }
};

export const PushControl: React.FC = () => {
  const dispatch = useDispatch();
  const [slug, setSlug] = useState<string>();
  const [triggerGetMyReservationByIdQuery, getMyReservationByIdQuery] = useLazyGetMyReservationByIdQuery();

  useEffect(() => {
    const subscription = subscribeMessagingHandler((payload) => {
      const { slug, reservationId } = payload.data || {};

      if (slugs.includes(slug) && reservationId) {
        setSlug(slug);
        triggerGetMyReservationByIdQuery({ reservationId, include: ["schedule", "room"] });
      }
    });

    return () => subscription.unsubscribe();
  }, [setSlug, triggerGetMyReservationByIdQuery]);

  const { data: getMyReservationByIdResponse, isLoading: reservationIsLoading, isFetching: reservationIsFetching } = getMyReservationByIdQuery;
  const { data: reservation } = getMyReservationByIdResponse?.result || {};
  const startDate = reservation?.schedule?.startDate ? new Date(reservation.schedule.startDate) : new Date();

  const handleClick = () => {
    setSlug(undefined);
    dispatch(togetherApi.util.invalidateTags([{ type: ApiTag.RESERVATION }]));
  };

  const [title, description] = resolveTitleAndDescription(slug, reservation?.room?.name, format(startDate, "MMM do"));

  return (
    <Dialog open={!!slug && !(reservationIsFetching || reservationIsLoading)}>
      <Box padding={2}>
        <Typography fontSize={18} fontWeight="600" marginBottom={1}>{title}</Typography>
        <Typography marginBottom={2}>{description}</Typography>
        <Box alignItems="center" display="flex" justifyContent="flex-end">
          <Button onClick={handleClick} variant="contained">{t`Ok`}</Button>
        </Box>
      </Box>
    </Dialog>
  );
};
 