import { Location } from "../../../../../App/Store/Locations/locationsDuck/models";
import { t } from "@lingui/macro";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import arrowIcon from "../../../Locations/LocationForm/assets/arrow-down.svg";
import { useTypedSelector } from "../../../../../Store/Redux/store";
import { getFloors, getLocationDisabledDates } from "../../../../../App/Store/Locations/locationsDuck";
import { useDispatch } from "react-redux";
import { WeekDays } from "../../../../../App/Store/Bookings/bookingDuck/models";
import numberToHoursMinutes from "../../../../../Functions/numberToHoursMinutes";
import { setJustInTimeReservationData } from "../../../../Store/reservationJustInTime";
import { useState } from "react";
import {getAllowedLocationsByRoles} from "../../../Locations/Helpers";

export default function ReservationJustInTimeLocation() {
  const dispatch = useDispatch();
  const { profile, locations, adminReservationJustInTime } = useTypedSelector(state => state);
  const { filters, weekDays, selectedData } = adminReservationJustInTime;
  const { roleAccess, locationIds: profileLocationIds } = profile;

  const allowedLocationList = getAllowedLocationsByRoles(roleAccess, locations.locations, profileLocationIds);
  const [allowedLocations, setAllowedLocations] = useState<Location[]>(allowedLocationList.filter(l => l.active));

  const onLoadLocationFloors = (location: Location) => {
    const weeklyBooking: Array<{ weekDay: WeekDays; timeFrom: string; timeTo: string }> = [];

    Object.keys(weekDays).forEach(weekDayNumber => {
      const weekDay = weekDays[parseInt(weekDayNumber)];

      if (weekDay.selected) {
        const { hours: timeFromHours, minutes: timeFromMinutes } = numberToHoursMinutes(weekDay.timeFrom);
        const { hours: timeToHours, minutes: timeToMinutes } = numberToHoursMinutes(weekDay.timeTo);

        weeklyBooking.push({
          timeFrom: `${timeFromHours}:${String(timeFromMinutes).length === 1 ? timeFromMinutes + '0' : timeFromMinutes}`,
          timeTo: `${timeToHours}:${String(timeToMinutes).length === 1 ? timeToMinutes + '0' : timeToMinutes}`,
          weekDay: weekDay.label,
        });
      }
    });

    dispatch(getFloors({
      data: {
        resources: Object.keys(filters).filter(filterName => filters[filterName]),
        onlyAvailableDesks: true,
      },
      locationId: location.id,
    }));
  };

  const onLocationChange = (event: any, location: Location) => {
    if (location !== null) {
      onLoadLocationFloors(location);
    }

    if (location === null) {
      return dispatch(setJustInTimeReservationData({
        selectedData: {
          ...selectedData,
          location: null,
          dateTime: '',
          floor: null,
          desk: null,
          parkingSpot: null,
        },
      }));
    }

    dispatch(getLocationDisabledDates({ locationId: location.id })); // load location disabled days
    return dispatch(setJustInTimeReservationData({
      selectedData: {
        ...selectedData,
        location,
        floor: null,
        desk: null,
      },
    }));
  };

  const onLocationSearchChange = (event: any) => {
    // make search only for next fields
    const fieldsToSearch = ['locationName'];
    const search = event.target.value;

    const filteredLocations = allowedLocationList
      .filter(item => {
        // @ts-ignore
        return Object.keys(item).some(key => fieldsToSearch.includes(key) && item[key].toLowerCase().includes(search.toLowerCase()));
      });

    setAllowedLocations(filteredLocations);
  };

  return (
    <Autocomplete
      className={`input input--default input--inline`}
      filterOptions={(x) => x} // fix load throttling
      getOptionLabel={(option) => option.locationName}
      id="address"
      onBlur={() => setAllowedLocations(allowedLocationList)}
      onChange={(event: any, location: any) => onLocationChange(event, location)}
      options={allowedLocations}
      popupIcon={
        <img height={10} src={arrowIcon} width={14} />
      }
      renderInput={(params) => (
        <TextField
          {...params}
          className={`input input--default`}
          onChange={(e) => onLocationSearchChange(e)}
          placeholder={t`Not selected`}
          variant="outlined"
        />
      )}
      value={selectedData.location}
    />
  );
}
