import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../../../../Store/Redux/store";
import { Trans } from "@lingui/macro";
import Tooltip from "@material-ui/core/Tooltip";
import styles from "../../../FloorHeaderTitle/FloorHeaderTitleEdit/styles.module.scss";

import Text from "../../../../../../../Components/Text";

export default function TurnedOffNotification() {
  const { floorId } = useParams<{ floorId: string; locationId: string; }>();
  const { locations } = useTypedSelector(state => state);

  const floor = locations.floorsAdmin.find(floor => floor.id === floorId);

  return (
    <>
      {!floor?.active &&
        <Tooltip
          classes={{ tooltip: 'overflow-tooltip' }}
          placement="bottom"
          title={<div className={styles.tooltipContent}>Use actions menu to Turn it on</div>}
        >
          <div className={styles.tooltipContentBox}>
            <svg height="9px" viewBox="0 0 12 9" width="12px">
              <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
                <g id="delete-white-copy-5" stroke="#5E5E5E">
                  <rect height="7" id="Rectangle" rx="1" strokeWidth="2" width="10" x="1" y="1"></rect>
                  <rect height="2" id="Rectangle" rx="0.5" width="2" x="3.5" y="3.5"></rect>
                </g>
              </g>
            </svg>
            <Text color="gray" size="md" weight="regular">
              <Trans>
                Floor is turned off
              </Trans>
            </Text>
          </div>
        </Tooltip>
      }
    </>
  );
}