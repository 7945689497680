import { UserRole } from "enums";
import { togetherApi } from "../together-api";
import { APIResponseBody, Attendee, GraphPaginatedAPIResponseBody, RawEntity, SearchAttendeesInput, User } from "../types";

const users = togetherApi.injectEndpoints({
  endpoints: (builder) => ({
    searchAttendees: builder.query<GraphPaginatedAPIResponseBody<Attendee>, SearchAttendeesInput>({
      query: ({ limit, search, skipToken }) => ({
        url: "/api/v2/users/attendees",
        params: { limit, search, skipToken },
      }),
    }),
    searchUsers: builder.query<APIResponseBody<{ users: User[] }>, string>({
      query: (search) => ({ url: "/api/users", params: { search } }),
      transformResponse: (response: APIResponseBody<{ users: RawEntity[] }>) => {
        const { result: { data, statusCode } } = response;
        const users: User[] = [];

        for (const user of data.users) {
          users.push({
            ...user,
            id: user.id as string,
            name: user.name as string,
            email: user.email as string,
            role: (user.role as RawEntity)?.name as UserRole,
          });
        }

        return { result: { statusCode, data: { users } } };
      },
    }),
    getMe: builder.query<APIResponseBody<User>, void>({
      query: () => ({ url: "/api/users/profile" }),
      transformResponse: (response: APIResponseBody<RawEntity>) => {
        const { result: { data, statusCode } } = response;
        const user: User = {
          ...data,
          id: data.id as string,
          name: data.name as string,
          email: data.email as string,
          role: (data.role as RawEntity)?.name as UserRole,
        };

        return { result: { statusCode, data: user } };
      },
    }),
    signUp: builder.mutation<APIResponseBody<void>, { deviceToken?: string }>({
      query: (body) => ({
        body,
        url: "/api/users",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useSearchAttendeesQuery,
  useLazySearchAttendeesQuery,
  useSearchUsersQuery,
  useLazySearchUsersQuery,
  useGetMeQuery,
  useLazyGetMeQuery,
  useSignUpMutation,
} = users;
