import { parseObjectQueryParam } from "../../utils";
import { togetherApi } from "../together-api";
import { APIResponseBody, GetLocationByIdInput, GetLocationsInput, Location, PaginatedAPIResponseBody, QuestionnaireQuestion } from "../types";

const locations = togetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query<PaginatedAPIResponseBody<Location>, GetLocationsInput>({
      query: ({ page, limit, orderBy, custom, search }) => ({
        url: `/api/v3/locations`,
        params: { page, limit, orderBy, search, ...parseObjectQueryParam("custom", custom) },
      }),
      transformResponse: (response: PaginatedAPIResponseBody<Record<string, unknown>>) => {
        const items: Location[] = [];
        const { result: { data: { items: locations, links, meta }, statusCode } } = response;

        for (const location of locations || []) {
          items.push({
            id: location.id as string,
            address: location.locationAddress as string,
            city: location.city as string,
            name: location.locationName as string,
            region: location.region as string,
            timeZone: location.timezone as string,
            isDefault: !!location.isDefault,
          });
        }

        return { result: { data: { items, links, meta }, statusCode } };
      },
    }),
    getLocationById: builder.query<APIResponseBody<Location>, GetLocationByIdInput>({
      query: ({ locationId }) => ({
        url: `/api/v2/locations/${locationId}`,
      }),
      transformResponse: (response: APIResponseBody<Record<string, unknown>>) => {
        const { result: { data: location, statusCode } } = response;
        const data = {
          id: location.id as string,
          address: location.locationAddress as string,
          city: location.city as string,
          name: location.locationName as string,
          region: location.region as string,
          timeZone: location.timezone as string,
        };

        return { result: { data, statusCode } };
      },
    }),
    getQuestionnaireQuestionsByLocationId: builder.query<APIResponseBody<QuestionnaireQuestion[]>, string>({
      query: (locationId) => ({ url: `/api/covid-questions/${locationId}/location-questions` }),
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useLazyGetLocationsQuery,
  useGetLocationByIdQuery,
  useLazyGetLocationByIdQuery,
  useLazyGetQuestionnaireQuestionsByLocationIdQuery,
} = locations;
