import StandIcon from "./assets/stand.svg";
import MonitorIcon from "./assets/monitor.svg";
import MonitorsIcon from "./assets/monitors.svg";
import DockingStationIcon from "./assets/dockingStation.svg";
import DedicatedPcIcon from "./assets/dedicatedPc.svg";
import MultimediaWorkstationIcon from "./assets/multimediaWorkstation.svg";
import NoMonitorIcon from "./assets/noMonitor.svg";
import Monitor27Icon from "./assets/monitor27.svg";
import UcbDockingIcon from "./assets/usbDocking.svg";
import SlimlineDockingStationIcon from "./assets/slimlineDockingStation.svg";
import PlaceholderIcon from "./assets/placeholder.svg";

import { t } from "@lingui/macro";
import { AttributeModel } from "../../Admin/Store/attributes/models";

export interface BodyCellsInterface {
  name: string;
  image: string;
}

export const deskAttributesCells: BodyCellsInterface[] = [
  { name: t`Sit/Stand`, image: StandIcon },
  { name: t`Monitor`, image: MonitorIcon },
  { name: t`Dual Monitor`, image: MonitorsIcon },
  { name: t`Docking Station`, image: DockingStationIcon },
  { name: t`Dedicated PC`, image: DedicatedPcIcon },
  { name: t`Multimedia Workstation`, image: MultimediaWorkstationIcon },
  { name: t`No Monitor`, image: NoMonitorIcon },
  { name: t`27” Monitor`, image: Monitor27Icon },
  { name: t`USB-C Docking Station`, image: UcbDockingIcon },
  { name: t`Slimline Docking Station`, image: SlimlineDockingStationIcon },
];

export function getDeskAttributeIcon(attributes: AttributeModel[]) {
  return attributes.map((attribute) => {
    const associatedCell = deskAttributesCells.find((cell: any) => cell.name === attribute.name);

    if (associatedCell) {
      return {
        ...attribute,
        image: associatedCell.image,
      };
    }

    return { ...attribute, image: PlaceholderIcon };
  });
}
