import { IAutoExceptionTelemetry } from "@microsoft/applicationinsights-web";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { applicationInsights } from "config/applicationInsights";
import { useGetMeQuery } from "store";

export const useExceptionTracker = (): ((exception?: Error | IAutoExceptionTelemetry | FetchBaseQueryError | SerializedError, properties?: Record<string, unknown>) => void) => {
  const getMeQuery = useGetMeQuery();
  const { data: user } = getMeQuery?.data?.result || {};

  return (exception, properties = {}) => {
    if (!exception) {
      return;
    }

    const custom = { ...properties };

    if (user?.id) {
      custom.userId = user.id;
    }

    if ("status" in exception) {
      applicationInsights.trackException({ exception: new Error(`HTTP ${exception.status}`) }, { ...custom, error: exception });
    } else {
      applicationInsights.trackException({ exception: exception as Error }, custom);
    }
  };
};
