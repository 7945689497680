import {useTypedSelector} from "../../../../Store/Redux/store";
import {Trans} from "@lingui/macro";
import styles from "./styles.module.scss";

interface Props {
  onSubmit: Function;
}

export default function DownloadExcelButton(props: Props) {
  const { config } = useTypedSelector(state => state);
  const { onSubmit } = props;

  return (
    <>
      <div className={styles.container} onClick={() => onSubmit()}>
        <svg height="24px" viewBox="0 0 24 24" width="24px">
          <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
            <g fill={config.theme.primary} id="delete-white-copy-5">
              <path d="M19,16 C19.5522847,16 20,16.4477153 20,17 L20,20 C20,20.5128358 19.6139598,20.9355072 19.1166211,20.9932723 L19,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,17 C4,16.4477153 4.44771525,16 5,16 C5.55228475,16 6,16.4477153 6,17 L6,19 L18,19 L18,17 C18,16.4477153 18.4477153,16 19,16 Z M12,3 C12.5522847,3 13,3.44771525 13,4 L13,14 C13,14.5522847 12.5522847,15 12,15 C11.4477153,15 11,14.5522847 11,14 L11,4 C11,3.44771525 11.4477153,3 12,3 Z" id="Combined-Shape"></path>
              <path d="M10,10 C10.5522847,10 11,10.4477153 11,11 L11,14 L14,14 C14.5522847,14 15,14.4477153 15,15 C15,15.5522847 14.5522847,16 14,16 L10,16 L10,16 C9.44771525,16 9,15.5522847 9,15 L9,11 C9,10.4477153 9.44771525,10 10,10 Z" id="Combined-Shape" transform="translate(12.000000, 13.000000) rotate(-45.000000) translate(-12.000000, -13.000000) "></path>
            </g>
          </g>
        </svg>

        <span>
          <Trans>
            Download Exсel File
          </Trans>
        </span>
      </div>
    </>
  );
}
