import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../Store/Redux/store";
import { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { setAdminFloorRoomsData } from "../../../Store/roomsManagement";

export function SuccessRoomsManagementSnackbar() {
  const dispatch = useDispatch();
  const { successMessage } = useTypedSelector(state => state.roomsManagement);

  const handleClose = () => {
    dispatch(setAdminFloorRoomsData({ successMessage: '' }));
  };

  useEffect(() => {
    return () => {
      dispatch(setAdminFloorRoomsData({ successMessage: '' }));
    };
  }, []);

  return successMessage ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="success">
        {successMessage}
      </Alert>
    </Snackbar>
  ) : null;
}

export function ErrorRoomsManagementSnackbar() {
  const dispatch = useDispatch();
  const { error } = useTypedSelector(state => state.roomsManagement);

  const handleClose = () => {
    dispatch(setAdminFloorRoomsData({ error: '' }));
  };

  useEffect(() => {
    return () => {
      dispatch(setAdminFloorRoomsData({ error: '' }));
    };
  }, []);

  return error ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="error">
        {error}
      </Alert>
    </Snackbar>
  ) : null;
}