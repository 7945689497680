export enum RoomServiceType {
  IT_SUPPORT = 'it-support',
  CATERING = 'catering',
}

export type RoomService = {
  type: RoomServiceType;
  emails: string[];
}

export interface Room {
  id: string;
  name: string;
  capacity: number;
  amenities: Array<{ id: string; name: string }>;
  availableServices: RoomService[];
  isWheelChairAccessible: boolean;
}

export interface CustomRequest {
  key: string;
  value: string;
}

export interface GetRoomsRequest {  
  custom?: CustomRequest[],  
  floorId: string;
  locationId: string;
}

export interface GetRoomsResponse {
  result: {
    statusCode: number;
    data: {
      items: Array<Room>
    };
  },
  error: string | null;
}

export interface SetRoomDataRequest {
  error?: string;
  loading?: boolean;
  rooms?: Array<Room>;  
}