import Box from 'Components/Box';
import Text from 'Components/Text';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { Plural, Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { FromToTimeInput, FromToTimeInputProps } from '../FromToTimeInput';
import { useTypedSelector } from 'Store/Redux/store';
import { resolvePersistedDate } from 'Store/Utils';

const DEFAULT_TIME_FROM = new Date(1970, 0, 1, 9);
const DEFAULT_TIME_TO = new Date(1970, 0, 1, 18);

export default function DailyBooking() {
  const dispatch = useDispatch();
  const { timeFrom, timeTo, locationId } = useTypedSelector(state => state.createNewBooking);
  const location = useTypedSelector((state) => state.locations.locations?.find(({ id }) => id === locationId));
  const timeFromDate = resolvePersistedDate(timeFrom);
  const timeToDate = resolvePersistedDate(timeTo);

  useEffect(() => {
    dispatch(setCreateNewBookingData({
      timeFrom: timeFromDate || DEFAULT_TIME_FROM,
      timeTo: timeToDate || DEFAULT_TIME_TO,
    }));
  }, []);

  const handleFromToTimeInputChange: FromToTimeInputProps["onChange"] = (value, error) => {
    if (error.from || error.to) {
      dispatch(setCreateNewBookingData({
        timeFrom: undefined,
        timeTo: undefined,
      }));
    } else {
      dispatch(setCreateNewBookingData({
        timeFrom: value.from,
        timeTo: value.to,
      }));
    }
  };

  return (
    <Box>
      <Box paddingBottom={20} paddingTop={20}>
        <Text size="md" weight="semi-bold">
          <Trans>Daily</Trans>
        </Text>
        <Text color="gray" size="md">
          <Plural
            one="Each day apart from local exceptions. You can book only for # week."
            other="Each day apart from local exceptions. You can book only for # weeks."
            value={location?.defaultWeekRecurrence}
          />
        </Text>
        <Box alignItems="center" display="flex" justifyContent="between" marginTop={18}>
          <Box style={{ width: "120px", flex: "0 0 120px" }}>
            <Text size="md" weight="semi-bold">
              <Trans>Time</Trans>
            </Text>
          </Box>
          <Box>
            <FromToTimeInput
              defaultValue={{ from: new Date(0, 0, 0, 9), to: new Date(0, 0, 0, 18) }}
              from={timeFromDate}
              onChange={handleFromToTimeInputChange}
              to={timeToDate}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
