import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../../Store/Redux/store";
import {useEffect, useState} from "react";
import {Trans, t} from "@lingui/macro";
import styles from "./styles.module.scss";
import Box from "../../../../../Components/Box";
import Button from "../../../../../Components/Button";
import {createBooking, setBookingsData} from "../../../../../App/Store/Bookings/bookingDuck";
import format from "date-fns/format";
import {CreateWeeklyBookingModel} from "../../../../../App/Store/Bookings/bookingDuck/models";
import useSnackbar from "../../../../../Components/Snackbar/useSnackbar";
import { isDelegatedAccessRole } from "utils";
import { UserRole } from "enums";
import { ConfirmationDialog } from "components";

export default function BookDesk() {
  const dispatch = useDispatch();
  const [openSnackbar] = useSnackbar();
  const config = useTypedSelector(state => state.config);
  const bookingDuck = useTypedSelector(state => state.booking);
  const adminReservationJustInTime = useTypedSelector(state => state.adminReservationJustInTime);
  const profile = useTypedSelector(state => state.profile);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [forceDialogIsOpen, setForceDialogIsOpen] = useState(false);
  const { selectedData, bookingType, dateFrom, dateTo, timeFrom, timeTo, weeklySlots } = adminReservationJustInTime;
  const { user, dateTime, location, floor, desk, parkingSpot } = selectedData;

  useEffect(() => {
    const { error, errorObject } = bookingDuck;

    if (error) {
      const errors = [error];

      if (errorObject) {
        if (errorObject.code === 1_001_012) {
          if (isDelegatedAccessRole(profile.role.name as UserRole)) {
            setForceDialogIsOpen(true);
            
            return;
          } else {
            errors.push(t`User already has a reservation for selected dates at "${location?.locationName}".`);
          }
        } else {
          errors.push(`${errorObject.statusCode}`, errorObject.message);
        }
      }

      openSnackbar({
        onClose: () => dispatch(setBookingsData({ error: '' })),
        text: errors.join(" "),
        type: 'error',
      });
    }
  }, [bookingDuck.error, bookingDuck.errorObject]);

  useEffect(() => {
    if (bookingDuck.bookingCreated || bookingDuck.error) {
      setIsSubmitting(false); // enable book button after action has processed
    }
  }, [bookingDuck.bookingCreated, bookingDuck.error]);

  const allStepsCompleted = Boolean(user && dateTime && location && floor && desk);

  const bookDesk = (force?: boolean) => {
    switch (bookingType) {
      case "custom": {
        if (user && location && desk && dateFrom && dateTo && timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          setIsSubmitting(true);
          dispatch(createBooking(
            {
              custom: {
                dateFrom: format(new Date(dateFrom), 'yyyy-MM-dd'),
                dateTo: format(new Date(dateTo), 'yyyy-MM-dd'),
                timeFrom: timeFromFormatted,
                timeTo: timeToFormatted,
              },
              deskId: desk.id,
              userId: user.id,
              parkingSpotId: parkingSpot?.id,
            },
            location.id,
            force,
          ));
        }
        break;
      }
      case "daily": {
        if (user && location && desk && timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          setIsSubmitting(true);
          dispatch(createBooking(
            {
              daily: {
                timeFrom: timeFromFormatted,
                timeTo: timeToFormatted,
              },
              deskId: desk.id,
              userId: user.id,
              parkingSpotId: parkingSpot?.id,
            },
            location.id,
            force,
          ));
        }
        break;
      }
      case "weekly": {
        if (user && location && desk && timeFrom && weeklySlots) {
          const weekly: CreateWeeklyBookingModel[] = weeklySlots
            .filter(({ isSelected, timeFrom, timeTo }) => isSelected && timeFrom && timeTo)
            .map(({ index, isSelected: selected, day: weekDay, timeFrom, timeTo }) => ({
              index,
              selected,
              weekDay,
              timeFrom: timeFrom ? format(new Date(timeFrom), "HH:mm") : new Date(),
              timeTo: timeTo ? format(new Date(timeTo), "HH:mm") : new Date(),
            }));
          
          if (weekly.length) {
            setIsSubmitting(true);
            dispatch(createBooking(
              {
                weekly,
                deskId: desk.id,
                userId: user.id,
                parkingSpotId: parkingSpot?.id,
              },
              location.id,
              force,
            ));
          }
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <Box
        alignItems="center"
        backgroundColor={config.theme.primary}
        borderRadius={20}
        display="flex"
        justifyContent="between"
        padding={22}
        style={{
          backgroundColor: config.theme.primary,
          opacity: !allStepsCompleted ? '0.5' : '1',
        }}
      >
        <Button
          aria-label={t`Book a Desk`}
          className={styles.bookDeskButton}
          disabled={!allStepsCompleted || isSubmitting}
          name={"Book a Desk"}
          onClick={() => bookDesk()}
          size="sm"
          type="white"
        >
          <Trans>Book a Desk</Trans>
        </Button>
      </Box>
      <ConfirmationDialog
        description={t`User already has a reservation for selected dates at "${location?.locationName}". Create it anyway?`}
        onClose={() => setForceDialogIsOpen(false)}
        onConfirm={() => bookDesk(true)}
        open={forceDialogIsOpen}
        title={t`Booking exists`}
      />
    </>
  );
}
