import AddFloorButton from '../AddFloorButton';
import Box from 'Components/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import clsx from 'clsx';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DownloadQrCode from './DownloadQrCode';
import Heading from 'Components/Heading';
import LocationDeleteModal from 'Admin/Pages/Locations/LocationDeleteModal';
import LocationForm from 'Admin/Pages/Locations/LocationForm';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import ToggleLocationModal from './ToggleLocationModal';
import ToggleOffIcon from 'Components/Icons/TurnOff';
import ToggleOnIcon from 'Components/Icons/TurnOn';
import { editLocation } from 'App/Store/Locations/locationsDuck';
import { Link, useLocation, useParams } from 'react-router-dom';
import { LocationOutlinedIcon } from 'App/Components/Icons';
import { ReactElement, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'Store/Redux/store';
import {
  ErrorAdminLocationsSnackbar,
  SuccessAdminLocationsSnackbar,
} from '../../../Pages/Locations/SnackBars';
import TableSubmenuButton from "../../Buttons/TableSubmenuButton";

interface Props {
  buttons?: ReactElement;
}

export default function LocationDetailsHeader({ buttons }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { locationId } = useParams<{ locationId: string }>();
  const [isOpenLocationForm, setIsOpenLocationForm] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isToggleLocationModalOpen, setIsToggleLocationModalOpen] = useState(false);
  const { config, locations, profile } = useTypedSelector(state => state);
  const { roleAccess } = profile;

  const selectedLocation = locations.adminLocations.find(location => location.id === locationId);

  const NAVIGATION_ITEMS = [
    {
      name: t`Floors`,
      to: `/admin/location/${locationId}/floors`,
    },
    {
      name: t`Parking`,
      to: `/admin/location/${locationId}/parking`,
    },
    {
      name: t`Templates`,
      subdomain: 'templates/',
      to: `/admin/location/${locationId}/templates/questions`,
    },
    {
      name: t`Attributes`,
      to: `/admin/location/${locationId}/attributes`,
    },
    {
      name: t`Calendar`,
      to: `/admin/location/${locationId}/calendar`,
    },
    {
      name: t`Location Settings`,
      to: `/admin/location/${locationId}/location-settings`,
    },
    {
      name: t`Dashboard`,
      to: `/admin/location/${locationId}/dashboard`,
    },
  ];

  const handleToggleLocation = () => {
    if (selectedLocation?.active) {
      setIsToggleLocationModalOpen(true);
    } else {
      onToggleLocation();
    }
  };

  // edit from modal
  const onOpenLocationForm = () => {
    setIsOpenLocationForm(o => !o);
  };

  const onCloseLocationForm = () => {
    setIsOpenLocationForm(false);
  };

  {/* temp prevent delete locations due to task https://zira.zstream.io/app/tasks/task/IPG-1859 */}
  // delete modal
  // const onOpenDeleteModal = () => {
  //   setIsOpenDeleteModal(o => !o);
  // };

  // Toggle location.active
  const onToggleLocation = () => {
    if (selectedLocation) {
      dispatch(editLocation({
        location: {
          ...selectedLocation,
          active: !selectedLocation?.active,
        },
        locationId: selectedLocation.id,
      }));
    }
  };

  const onCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  return (
    <>
      <SuccessAdminLocationsSnackbar />
      <ErrorAdminLocationsSnackbar />

      <Box className={styles.header} dataTestId="location-details-header">
        <Box alignItems="center" display="flex" justifyContent="between" marginBottom={15}>
          <Box>
            <Box alignItems="center" display="flex">
              <Heading className={styles.heading} size="sm">
                <Link className={styles.headingBack} to='/admin'>
                  <svg height="18px" version="1.1" viewBox="0 0 18 18" width="18px">
                    <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
                      <g id="A-Location-notifications-createnew" transform="translate(-207.000000, -28.000000)">
                        <rect fill="transparent" height="900" width="1440" x="0" y="0" />
                        <path d="M220,0 C208.954305,2.02906125e-15 200,8.954305 200,20 L200,20 L200,1024 L0,1024 L0,0 Z" fill={config.theme.primaryLight} id="Combined-Shape" />
                        <g id="icons/arrow-back" transform="translate(207.000000, 28.000000)">
                          <g id="icons/arrow-left" />
                          <rect fill={config.theme.primary} height="2" id="Rectangle" rx="1" width="14" x="2" y="8" />
                          <path d="M10,5 C10.5522847,5 11,5.44771525 11,6 C11,6.55228475 10.5522847,7 10,7 L5,7 L5,12 C5,12.5522847 4.55228475,13 4,13 C3.44771525,13 3,12.5522847 3,12 L3,6 C3,5.44771525 3.44771525,5 4,5 L10,5 Z" fill={config.theme.primary} id="Combined-Shape" transform="translate(7.000000, 9.000000) rotate(-45.000000) translate(-7.000000, -9.000000) " />
                        </g>
                      </g>
                    </g>
                  </svg>
                </Link>

                {selectedLocation?.locationName}
              </Heading>

              {!selectedLocation?.active &&
                <Box marginStart={40}>
                  <Text color="gray" size="md" weight="regular">
                    <Trans>
                      Location is turned off (use actions menu to Turn it on)
                    </Trans>
                  </Text>
                </Box>
              }
            </Box>

            <Box
              alignItems="center"
              display="flex"
              left={-1}
              marginTop={11}
              overflow="hidden"
              position="relative"
            >
              <LocationOutlinedIcon />

              <Box overflow="hidden">
                <Text color='gray' size="md" truncate>
                  {selectedLocation?.locationAddress} | {selectedLocation?.region}
                </Text>
              </Box>
            </Box>
          </Box>

          <Box alignItems="center" display="flex" justifyContent="between">
            <Box marginEnd={15}>
              <DownloadQrCode />
            </Box>

            {
              location.pathname.includes('/floors') ?
                <Box marginEnd={15}>
                  <AddFloorButton />
                </Box>
                : null
            }

            {buttons &&
              <Box marginEnd={15}>
                {buttons}
              </Box>
            }

            <TableSubmenuButton
              iconButtonColor={'#ffffff'}
              iconButtonName={t`Floor Options`}
              iconButtonStyle={{ backgroundColor: config.theme.primary, borderRadius: 6, padding: 6, cursor: 'pointer' }}
            >
              <Box borderStyle="shadow" className="popover-content" dataTestId="location-item-options-modal">
                <ButtonBase className="popover-item-button">
                  <CancelOutlinedIcon style={{ color: config.theme.primary, fontSize: 20 }} />

                  <Box marginStart={5}>
                    <Text color="blue" size="md">
                      <Trans>
                        Cancel All Reservations
                      </Trans>
                    </Text>
                  </Box>
                </ButtonBase>

                {/* only for super admin */}
                {
                  roleAccess.superAdmin &&
                  <>
                    <ButtonBase className="popover-item-button" onClick={onOpenLocationForm}>
                      <CreateOutlinedIcon style={{ color: config.theme.primary, fontSize: 20 }} />

                      <Box marginStart={5}>
                        <Text color="blue" size="md">
                          <Trans>
                            Edit Location
                          </Trans>
                        </Text>
                      </Box>
                    </ButtonBase>

                    <ButtonBase className="popover-item-button" onClick={handleToggleLocation}>
                      {selectedLocation?.active ? (
                        <ToggleOffIcon color={config.theme.primary} size={20} />
                      ) : (
                        <ToggleOnIcon color={config.theme.primary} size={20} />
                      )}

                      <Box marginStart={5}>
                        <Text color="blue" size="md">
                          {selectedLocation?.active ? (
                            <Trans>Turn off Location</Trans>
                          ) : (
                            <Trans>Turn on Location</Trans>
                          )}
                        </Text>
                      </Box>
                    </ButtonBase>

                    {/* temp prevent delete locations due to task https://zira.zstream.io/app/tasks/task/IPG-1859 */}
                    {/*<ButtonBase className="popover-item-button" onClick={onOpenDeleteModal}>*/}
                    {/*  <DeleteOutlineIcon style={{ color: config.theme.primary, fontSize: 20 }} />*/}

                    {/*  <Box marginStart={5}>*/}
                    {/*    <Text color="blue" size="md">*/}
                    {/*      <Trans>*/}
                    {/*        Delete Location*/}
                    {/*      </Trans>*/}
                    {/*    </Text>*/}
                    {/*  </Box>*/}
                    {/*</ButtonBase>*/}
                  </>
                }
              </Box>
            </TableSubmenuButton>
          </Box>
        </Box>

        <nav aria-label={t`Location Details Navigation Items`} className={styles.nav} data-testid="location-details-menu">
          <ul>
            {NAVIGATION_ITEMS.map((item, index) => {
              let isSelected = item.to === location.pathname;
              if (item.subdomain && item.subdomain === 'templates/') {
                isSelected = location.pathname.indexOf('templates/') > 0;
              }

              const classes = clsx(isSelected && styles.isSelected);

              return (
                <li className={classes} data-testid={`location-details-menu-item`} key={item.name + index}>
                  <Link to={item.to}>
                    <Text color={isSelected ? 'blue' : 'black'} size="md" weight="semi-bold">
                      {item.name}
                    </Text>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>

        <LocationForm
          actionState={'edit'}
          location={selectedLocation}
          onCancelAction={onCloseLocationForm}
          open={isOpenLocationForm}
        />
        <LocationDeleteModal
          location={selectedLocation}
          onCancelAction={onCloseDeleteModal}
          open={isOpenDeleteModal}
          redirect
        />
        <ToggleLocationModal
          onClose={() => setIsToggleLocationModalOpen(false)}
          onConfirm={onToggleLocation}
          open={isToggleLocationModalOpen}
        />
      </Box>
    </>
  );
}
