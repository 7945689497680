import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import styles from "../styles.module.scss";

import {
  FloorMapObject,
} from "../../../../../../Store/floorMapDuck/models";
import { updateMeeting } from "../../../../../../Store/floorMapDuck";
import Box from "../../../../../../../Components/Box";
import Text from "../../../../../../../Components/Text";
import Select from "../../../../../../../Components/Select";
import { DeskStatus } from "../../../../../../Store/floorMapDuck/desk/models";
import { MEETING_STATUS_TO_EDIT } from "../../../../../../Store/floorMapDuck/meeting";

interface Props {
  meeting: FloorMapObject;
}

export default function MeetingDetailsStatus(props: Props) {
  const dispatch = useDispatch();
  const { meeting } = props;
  const { id, status } = meeting;

  const [meetingStatus, setMeetingStatus] = useState<DeskStatus>(status);

  /**
   * Update desk status on redux state
   */
  const handleChangeDeskStatus = (event: any) => {
    dispatch(updateMeeting({
      meetingId: typeof id === "string" ? parseInt(id) : id,
      meeting: {
        status: event.target.value,
      },
    }));
  };

  // Sync desk name on redux and on state
  useEffect(() => {
    setMeetingStatus(status);
  }, [status]);

  return (
    <Box className={styles.detailsItem}>
      <Text color="gray" size="md">
        <Trans>Status:</Trans>
      </Text>

      <Box width={'100%'}>
        <Select
          classes={{
            materialSelect: styles.selectDefault,
            materialLabel: styles.selectLabel,
            icon: styles.selectDefaultIcon,
          }}
          fullWidth
          id="meeting-status"
          items={MEETING_STATUS_TO_EDIT}
          onChange={handleChangeDeskStatus}
          value={meetingStatus}
        />
      </Box>
      {/* TODO - after BE update implement approval required logic (as desk has now) */}
    </Box>
  );
}
