import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BookingsState, BookingsStateNeighbor } from "./types";

const initialState: BookingsState = {};

const setCurrentNeighbor: CaseReducer<BookingsState, PayloadAction<BookingsStateNeighbor | undefined>> = (state, action) => ({
  ...state,
  currentNeighbor: action.payload,
});

export const bookingsSlice = createSlice({
  initialState,
  name: "bookings",
  reducers: {
    setCurrentNeighbor,
  },
});
