interface Props {
  size?: number;
  className?: string;
  color?: string;
}

export default function Neighbors({ color = "#5E5E5E", className = "", size = 16 }: React.PropsWithChildren<Props>) {
  return (
    <svg className={className} height={size} viewBox="0 0 16 16" width={size}>
    <title>D2B58E6C-0466-4F24-ACB6-4E33E3015C72@1x</title>
    <g fill="none" fillRule="evenodd" id="Rooms-booking" stroke="none" strokeWidth="1">
        <g id="1.3.0-Book-a-room-select-room-calendar" transform="translate(-114.000000, -387.000000)">
            <g id="Room-calendar" transform="translate(100.000000, 351.000000)">
                <g id="labels/amenities" transform="translate(14.000000, 32.000000)">
                    <g id="icons/neighbors-grey" transform="translate(0.000000, 4.000000)">
                        <path d="M8,0 C5.23857625,0 3,2.23857625 3,5 C3,7.76142375 5.23857625,10 8,10 C10.7614237,10 13,7.76142375 13,5 C13,2.23857625 10.7614237,0 8,0 Z M8,2 C9.65685425,2 11,3.34314575 11,5 C11,6.65685425 9.65685425,8 8,8 C6.34314575,8 5,6.65685425 5,5 C5,3.34314575 6.34314575,2 8,2 Z" fill={color} fillRule="nonzero" id="icons/profileplaceholder"></path>
                        <path d="M14.9375137,15.0606344 C14.4785765,11.6388294 11.5474541,9 8,9 C4.47897362,9 1.56513002,11.5996585 1.0731541,14.9842906" id="Path" stroke={color} strokeLinecap="round" strokeWidth="2"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
    </svg>
  );
}