import styles from './styles.module.scss';
import Box from 'Components/Box';
import { ButtonBase } from '@material-ui/core';
import ArrowLeft from '../../Room/Arrows/ArrowLeft';
import ArrowRight from '../../Room/Arrows/ArrowRight';

interface Props {  
  handleChangeSelectedDate: (nextDay: boolean) => void;  
}

export default function DateSelector({ handleChangeSelectedDate }: Props) {
  return (
    <Box display='flex' gap={15}>
      <ButtonBase className={styles.calendarDateButton} onClick={() => { handleChangeSelectedDate(false); }}>
        <ArrowLeft className="reactCalendarTimelineGroupPrevDate" />
      </ButtonBase>                
      <ButtonBase className={styles.calendarDateButton} onClick={() => { handleChangeSelectedDate(true); }}>
        <ArrowRight />
      </ButtonBase>                
    </Box>     
  );
}
