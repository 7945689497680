export interface HoursMinutes {
  hours: number;
  minutes: number;
}

/**
 * Receives a number and returns it in hours and minute.
 * Ex: 1.5 => {hours: 1, minutes: 30}
 *     5.5 => {hours: 5, minutes: 30}
 */
export default function numberToHoursMinutes(hourInNumber: number): HoursMinutes {
  // Separate the hours. Ex: 1.9 => 1
  const hours = parseInt(String(hourInNumber));
  // Separates the minutes (in percentage). Ex: 1.9 => 0.9
  let minutes = hourInNumber % 1;
  minutes = minutes * 60;

  return {
    hours,
    minutes,
  };
}
