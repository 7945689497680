import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

export type ButtonType = 'primary' | 'secondary' | 'clear' | 'white' | 'black';

interface Props {
  buttonType?: 'button' | 'submit' | 'reset';
  className?: string;
  disabled?: boolean,
  disableRipple?: boolean;
  external?: boolean;
  name?: string;
  noPaddingX?: boolean;
  onClick?: ((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  size?: 'xsm' | 'xs' | 'sm' | 'sm130' | 'md' | 'full';
  to?: string;
  type?: ButtonType;
  withShadow?: boolean,
  disabledStyle?: boolean,
  style?: ButtonBaseProps["style"],
}

export default function Button({
  buttonType = 'button',
  className,
  children,
  disabled,
  disableRipple,
  name,
  noPaddingX,
  external,
  onClick,
  size,
  to,
  type = 'primary',
  withShadow = true,
  disabledStyle = false,
  style,
}: React.PropsWithChildren<Props>) {
  const classes = clsx(
    styles.button,
    size === 'xs' && styles.xs,
    size === 'xsm' && styles.xsm,
    size === 'sm' && styles.sm,
    size === 'sm130' && styles.sm130,
    size === 'md' && styles.md,
    size === 'full' && styles.full,
    type === 'clear' && styles.clear,
    type === 'primary' && styles.primary,
    type === 'secondary' && styles.secondary,
    type === 'white' && styles.white,
    type === 'black' && styles.black,
    withShadow && styles.shadow,
    noPaddingX && styles.noPaddingX,
    disabledStyle && styles.disabledStyle,
  );

  const button = <div className={classes}>{children}</div>;

  const buttonLayout = (
    <ButtonBase
      className={`${styles.buttonContainer} ${styles.buttonBase} ${size === 'full' ? styles.full : ''} ${className}`}
      disableRipple={disableRipple}
      disabled={disabled}
      name={name}
      onClick={(event) => onClick && onClick(event)}
      style={style}
      type={buttonType}
    >
      {button}
    </ButtonBase>
  );

  return external ? (
    <a
      className={className}
      href={to}
      onClick={() => onClick && onClick()}
      rel='noopener noreferrer'
      style={{ textAlign: 'center', textDecoration: 'none' }}
      target='_blank'
    >
      <ButtonBase className={styles.buttonBase} disableRipple={disableRipple}>
        {button}
      </ButtonBase>
    </a>
  ) : to ? (
    disabled
      ? buttonLayout
      : (
        <Link
          className={className}
          onClick={() => onClick && onClick()}
          style={{ textAlign: 'center', textDecoration: 'none' }}
          to={to}
        >
          <ButtonBase
            className={`${styles.buttonBase} ${styles.noOutlineOnFocus}`}
            disableRipple={disableRipple}
            tabIndex={-1}
          >
            {button}
          </ButtonBase>
        </Link>
      )
  ) : (
    buttonLayout
  );
}
