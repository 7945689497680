import { FloorMapObject, MapDrawingGroup } from "../../../../../../../Store/floorMapDuck/models";
import styles from "../styles.module.scss";
import { FormControlLabel } from "@material-ui/core";
import NoGroupsText from "./NoGroupsText";
import { useTypedSelector } from "../../../../../../../../Store/Redux/store";
import CheckboxCustom from "../../../../../../../../Components/CheckBoxCustom";
import { IGroup } from "../../../../../../../Store/groups/models";
import Box from "Components/Box";
import OverflowToolTip from "../../../../../../../../Components/OverflowTooltip";

interface Props {
  drawingObject: FloorMapObject;
  onGroupsUpdate: (groups: MapDrawingGroup[]) => void;
  searchText: string;
}

export default function GroupList(props: Props) {
  const { groups } = useTypedSelector(state => state);
  const { onGroupsUpdate, searchText, drawingObject } = props;
  const { groups: deskGroups = [] } = drawingObject;
  const { groupsList } = groups;

  // to do - get from selectedValue name
  const allGroups: IGroup[] = groupsList.filter(group => !deskGroups.some(selectedGroup => selectedGroup.id === group.id)); // all groups that not includes users from selectedGroups;

  const onUpdateGroups = (checked: boolean, group: MapDrawingGroup) => {
    const newGroup: MapDrawingGroup = { id: group.id, name: group.name };

    if (checked) {
      const updatedGroups = [...deskGroups, newGroup];
      return onGroupsUpdate(updatedGroups);
    }

    const updatedGroups = deskGroups.filter(o =>  o.id !== newGroup.id);
    return onGroupsUpdate(updatedGroups);
  };

  return (
    <Box className={styles.filtersFormControlWrapper} display="grid" >
      {
        deskGroups.length ?
          deskGroups.map(((group, key) => {
            return (
              <FormControlLabel
                className={styles.checkedLabel}
                control={
                  <CheckboxCustom
                    checked={true} // always checked array
                    classes={{ checked: styles.checked }} // always checked array
                    name={group.name}
                    onChange={(event: any, checked: boolean) => onUpdateGroups(checked, group)}
                  />
                }
                key={key}
                label={<OverflowToolTip placement={'left'} text={group.name} />}
              />
            );
          })) : null
      }
      {
        allGroups.length ?
          allGroups.map(((group, key) => {
            return (
              <FormControlLabel
                control={
                  <CheckboxCustom
                    checked={false}
                    classes={{ checked: styles.checked }}
                    name={group.name}
                    onChange={(event: any, checked: boolean) => onUpdateGroups(checked, group)}
                  />
                }
                key={key}
                label={<OverflowToolTip placement={'left'} text={group.name} />}
              />
            );
          })) : <NoGroupsText groups={groupsList} searchText={searchText} />
      }
    </Box>
  );
}