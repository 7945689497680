import {useTypedSelector} from "../../../../../Store/Redux/store";
import {useEffect, useRef, useState} from "react";
import L from "leaflet";
import {
  getImageBoundsLimited,
  getMapMinZoomValue,
} from "../../../../../App/Pages/CreateNewBooking/Desk/Map/Helpers/mapFunctions";
import Box from "../../../../../Components/Box";
import {MAP_HEIGHT} from "../../../../../App/Pages/CreateNewBooking/Desk/Map/Helpers/consts";
import {ImageOverlay, MapContainer} from "react-leaflet";
import MapDrawingAllBooked from "./MapDrawing";
import {MapImageBoundsType} from "../../../../Store/floorMapDuck/models";

interface Props {
  mapImageBounds: MapImageBoundsType | null;
  mapSize: {
    height: number;
    width: number;
  };
  name: string;
  previewUrl: string;
}

export default function Map({ mapImageBounds, mapSize, name, previewUrl }: Props) {
  const { adminFloorMapApiRequests } = useTypedSelector(state => state);
  const { height = 100, width = 100 } = mapSize;

  // We use this key to set the map size correctly after it's rendered.
  // Ref: https://zira.zstream.io/app/tasks/task/IPG-772
  const [key, setKey] = useState(0);
  const [map, setMap] = useState<L.Map | null>(null);
  const imageRef = useRef<L.ImageOverlay>(null);
  const [mapCentered, setMapCentered] = useState(false);
  const [minZoom, setMinZoom] = useState(0);
  // @ts-ignore its correct type for bounds // use getImageBoundsLimited for support old image bounds format
  const mapBounds: L.LatLngBoundsExpression = mapImageBounds ? mapImageBounds : getImageBoundsLimited({ height, proportion: 100, width });

  useEffect(() => {
    if (imageRef.current && map && !mapCentered) {
      const mapImage = imageRef.current;

      // @ts-ignore Typescript is not recognizing this objects. It's correct though
      const containerSize = mapImage._map._size;

      const mapMinZoom = getMapMinZoomValue({
        imageSize: { height, width },
        containerSize: { height: containerSize.y, width: containerSize.x },
      });

      setMinZoom(mapMinZoom);
      setMapCentered(true);
    }
  }, [imageRef.current, map]);

  useEffect(() => {
    if (imageRef.current && map) {
      const mapImage = imageRef.current;
      const imageBounds = mapImage.getBounds();

      // Set the map bounds to the image uploaded. This will center the map correctly.
      map.fitBounds(imageBounds);
    }
  }, [imageRef.current, map, minZoom]);

  // update map scaling after data is loaded and zoom is set
  useEffect(() => {
    if (!adminFloorMapApiRequests.loading) {
      setKey(prevKey => prevKey + 1);
    }
  }, [adminFloorMapApiRequests.loading, minZoom]);

  return (
    <>
      <Box dataTestId="map" height={MAP_HEIGHT} id="mapid">
        <MapContainer
          attributionControl={false}
          boundsOptions={{ padding: [0, 0] }}
          center={[50, 50]}
          className="app-map"
          crs={L.CRS.Simple}
          key={key}
          maxZoom={5}
          minZoom={minZoom}
          // scrollWheelZoom={false}
          style={{ height: MAP_HEIGHT }}
          whenCreated={setMap}
          zoom={minZoom}
          zoomSnap={0}
        >
          {previewUrl && !adminFloorMapApiRequests.loading &&
          <ImageOverlay
            alt={`floor ${name}`}
            bounds={mapBounds}
            key={key}
            ref={imageRef}
            url={previewUrl}
          />
          }

          <MapDrawingAllBooked />
        </MapContainer>
      </Box>
    </>
  );
}
