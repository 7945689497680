import { FloorMapObject, FloorMapObjectTypeEnum } from "../../../Store/floorMapDuck/models";
import { SaveMapDesk, SaveMapMeeting, SaveMapSection } from "../../../Store/floorMapApiRequestsDuck/models";
import {AttributeModel} from "../../../Store/attributes/models";

interface transformDeskSectionsProps {
  desks: { [deskId: string]: FloorMapObject; },
  sections: { [sectionId: number]: FloorMapObject; },
  meetings: { [meetingId: number]: FloorMapObject; },
  attributesDesk: AttributeModel[];
  attributesRoom: AttributeModel[];
}

// transform desk & sections object into array format to save and update floor mpa schema
export function transformDeskSections(props: transformDeskSectionsProps) {
  const { desks, sections, meetings, attributesDesk, attributesRoom } = props;

  let desksArray = Object.keys(desks).map(deskId => desks[deskId]);
  const sectionsArray = Object.keys(sections).map(sectionId => sections[parseInt(sectionId)]);
  const sectionsArrayFiltered = sectionsArray.filter(section => section.provisory);
  desksArray = desksArray.filter(desk => desk.provisory);

  const meetingsArray = Object.keys(meetings).map(sectionId => meetings[parseInt(sectionId)]);
  const meetingsArrayFiltered = meetingsArray.filter(section => section.provisory);

  const saveMapSectionsArray = sectionsArrayFiltered.map(section => {
    const newSection: SaveMapSection = {
      coordinates: section.coordinatesInPoints,
      fill: section.fill,
      line: section.line,
      name: section.name,
      sectionId: typeof section.id === 'number' ? section.id : 0,
      type: FloorMapObjectTypeEnum.Section,
    };

    return newSection;
  });

  const saveMapMeetingsArray = meetingsArrayFiltered.map(room => {
    const newMeeting: SaveMapMeeting = {
      coordinates: room.coordinatesInPoints,
      fill: room.fill,
      line: room.line,
      name: room.name,
      sectionId: typeof room.id === 'number' ? room.id : 0,
      type: FloorMapObjectTypeEnum.Meeting,
      // @ts-ignore
      status: room.status, // TODO - update models and fix
      owners: room.owners,
      roomId: room.roomId ? room.roomId: 'test', // TODO - update models and fix
      // @ts-ignore
      approvers: room.approvers, // TODO - update models and fix
      description: room.description,
      groups: room.groups,
      reservationDayLimit: room.reservationDayLimit,
    };

    if (room.amenityIds) {
      const amenityIds = Object.keys(room.amenityIds).filter(resourceId => {
        const isSelected = Boolean(room.amenityIds?.[resourceId]);
        const isAvailable = attributesRoom.find(attribute => {
          return attribute.id === resourceId && attribute.isAvailable;
        });
  
        if (isSelected && isAvailable) {
          return resourceId;
        }
      });
  
      newMeeting.amenityIds = amenityIds;
    }

    if (room.capacity) {
      newMeeting.capacity = room.capacity;
    }
    if (room.availableServices) {
      newMeeting.availableServices = room.availableServices;
    }

    return newMeeting;
  });

  const saveMapDesksArray = desksArray.map(desk => {
    let resourceIds = Object.keys(desk.resourceIds);

    // Filters unavailable and not selected resources
    resourceIds = resourceIds.filter(resourceId => {
      const isSelected = Boolean(desk.resourceIds[resourceId]);
      const isAvailable = attributesDesk.find(attribute => {
        return attribute.id === resourceId && attribute.isAvailable;
      });

      if (isSelected && isAvailable) {
        return resourceId;
      }
    });

    const deskSection = sectionsArray.find(section => section.id === desk.parentId);

    const newDesk: SaveMapDesk = {
      deskSchema: {
        coordinates: desk.coordinatesInPoints,
        deskId: typeof desk.id === 'string' ? desk.id : '',
        fill: desk.fill,
        line: desk.line,
        name: desk.name,
        sectionId: desk.parentId,
        owners: desk.owners,
        groups: desk.groups,
        resourceIds,
        status: desk.status,
        type: FloorMapObjectTypeEnum.Desk,
      },
      emails: desk.approvers ? [desk.approvers.approver1.email, desk.approvers.approver2.email].filter(email => Boolean(email)) : [],
      id: typeof desk.id === 'string' ? desk.id : '',
      owners: desk.owners,
      groups: desk.groups,
      name: desk.name,
      resourceIds,
      status: desk.status,
      section: deskSection ? deskSection.name : undefined,
      sectionId: desk.parentId,
      type: FloorMapObjectTypeEnum.Desk,
    };

    return newDesk;
  });

  return {
    saveMapSectionsArray,
    saveMapDesksArray,
    saveMapMeetingsArray,
  };
}
