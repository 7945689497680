import Box from '../../../../../Components/Box';
import CheckboxCustom from '../../../../../Components/CheckBoxCustom';
import styles from './styles.module.scss';
import Text from '../../../../../Components/Text';
import { FormControlLabel, TextField } from '@material-ui/core';
import { Location } from '../../../../../App/Store/Locations/locationsDuck/models';
import { locationIdsType } from 'Admin/Store/visits/models';
import { setVisitsData } from 'Admin/Store/visits';
import { useDispatch } from 'react-redux';
import {useEffect, useState} from 'react';
import { useTypedSelector } from '../../../../../Store/Redux/store';
import {getAllowedLocationsByRoles} from "../../../Locations/Helpers";
import FilterAccordion from "../../../../Components/Filters/FilterAccordion";

interface LocationsFilterProps {
  locationIds: locationIdsType;
  setLocationIds: Function;
  locationSearchText: string;
  setLocationSearchText: Function;
}

export default function LocationsFilter(props: LocationsFilterProps) {
  const dispatch = useDispatch();
  const { locationIds, setLocationIds, locationSearchText, setLocationSearchText } = props;
  const { locations, profile } = useTypedSelector(state => state);
  const { filters: reduxFilters } = useTypedSelector(state => state.adminVisits);
  const { roleAccess, locationIds: profileLocationIds } = profile;

  // set restrictions for local admin role
  const allowedLocations = getAllowedLocationsByRoles(roleAccess, locations.adminLocations, profileLocationIds);

  const [locationsArray, setLocationsArray] = useState<Location[]>(allowedLocations);
  const localCounter = locationIds.length;

  const onChangeLocation = (value: boolean, location: Location) => {
    const isChecked = locationIds.includes(location.id);

    if (isChecked) {
      const updatedLocationIds = locationIds.filter(id => ![location.id].includes(id));
      return setLocationIds(updatedLocationIds);
    }

    const updatedLocationIds = [...locationIds, location.id];
    return setLocationIds(updatedLocationIds);
  };

  const onSearchChange = (locations: Location[], event: any) => {
    // make search only for next fields
    const fieldsToSearch = ['locationName'];
    const search = event.target.value;

    const filteredLocations = locations
      .filter(item => {
        // @ts-ignore
        return Object.keys(item).some(key => fieldsToSearch.includes(key) && item[key].toLowerCase().includes(search.toLowerCase()));
      });

    setLocationSearchText(search);
    setLocationsArray(filteredLocations);
  };

  const onResetFilter = () => {
    dispatch(setVisitsData({
      filters: {
        ...reduxFilters,
        locationIds: [],
      },
    }));

    setLocationsArray(locations.locations);
    setLocationIds([]);
    setLocationSearchText('');
  };

  // show full locations list
  useEffect(() => {
    if (!locationSearchText) {
      setLocationsArray(allowedLocations);
    }
  }, [locationSearchText, profileLocationIds]);

  return (
    <Box display="flex" justifyContent="between" marginTop={10}>
      <FilterAccordion headerName="Location name" localCounter={localCounter} resetFilter={onResetFilter}>
        <Box direction="column" display="flex" width="100%">
          <TextField
            InputProps={{
              startAdornment: (
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px">
                  <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
                    <g fill="#5E5E5E" fillRule="nonzero" id="icons/search">
                      <g id="delete-white-copy-5">
                        <path d="M6.05025253,6.05025253 C8.78392257,3.31658249 13.2160774,3.31658249 15.9497475,6.05025253 C18.4434716,8.54397661 18.6623564,12.4511046 16.6064021,15.1929497 L20.1923882,18.7781746 C20.5829124,19.1686989 20.5829124,19.8018639 20.1923882,20.1923882 C19.8018639,20.5829124 19.1686989,20.5829124 18.7781746,20.1923882 L15.1929497,16.6064021 C12.4511046,18.6623564 8.54397661,18.4434716 6.05025253,15.9497475 C3.31658249,13.2160774 3.31658249,8.78392257 6.05025253,6.05025253 Z M14.5355339,7.46446609 C12.5829124,5.51184464 9.41708755,5.51184464 7.46446609,7.46446609 C5.51184464,9.41708755 5.51184464,12.5829124 7.46446609,14.5355339 C9.41708755,16.4881554 12.5829124,16.4881554 14.5355339,14.5355339 C16.4881554,12.5829124 16.4881554,9.41708755 14.5355339,7.46446609 Z" id="Combined-Shape"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              ),
            }}
            className={`input input--default input--search ${styles.locationSearch}`}
            defaultValue={''}
            fullWidth
            onChange={(event) => onSearchChange(allowedLocations, event)} // rerender and clear field on open\close
            placeholder="Search"
            value={locationSearchText}
            variant="outlined"
          />

          <Box className={styles.filtersFormControlWrapper} display="grid" >
            {
              locationsArray.length ?
                locationsArray.map(((location, key) => {
                  const isChecked = locationIds.includes(location.id);

                  return (
                    <FormControlLabel
                      className={`${isChecked ? styles.checkedLabel : ''}`}
                      control={<CheckboxCustom checked={isChecked} classes={{ checked: styles.checked }} name={location.locationName} onChange={(value: boolean) => onChangeLocation(value, location)} />}
                      key={key}
                      label={location.locationName}
                    />
                  );
                })) :
                <Text align="center" color="lightGray" size="md">
                  No locations found
                </Text>
            }
          </Box>
        </Box>
      </FilterAccordion>
    </Box>
  );
}
