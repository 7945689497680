import Box from 'Components/Box';
import ProfileLink from '../../../Components/Profile';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { Link, useLocation } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { useTypedSelector } from 'Store/Redux/store';

export default function DelegateMenu() {
  const location = useLocation();
  const { config } = useTypedSelector(state => state);
  const { pathname } = location;

  return (
    <Box className={styles.menu} dataTestId="admin-menu">
      <ProfileLink greeting="admin" />

      <Box className={styles.menuItems} marginTop={20}>

        <Link className={`${styles.menuLink} ${pathname === '/admin/create-reservation' ? styles.menuLinkActive : ''}`} to="/admin/create-reservation">
          <Box alignItems="center" display="flex" justifyContent="center" marginEnd={9}>
            <svg className={`${pathname === '/admin/create-reservation' ? styles.reservationActive : ''}`} height="20px" version="1.1" viewBox="0 0 20 20" width="20px">
              <g fill="none" fillRule="evenodd" id="A-reservations" stroke="none" strokeWidth="1">
                <g id="A-Reservations" transform="translate(-20.000000, -70.000000)">
                  <g id="Group" transform="translate(20.000000, 67.000000)">
                    <g id="icons/list-active" transform="translate(0.000000, 3.000000)">
                      <path d="M16,16 C16.5522847,16 17,16.4477153 17,17 C17,17.5522847 16.5522847,18 16,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 L16,16 Z M16,12 C16.5522847,12 17,12.4477153 17,13 C17,13.5522847 16.5522847,14 16,14 L4,14 C3.44771525,14 3,13.5522847 3,13 C3,12.4477153 3.44771525,12 4,12 L16,12 Z" fill={config.theme.primary} id="Combined-Shape"></path>
                      <rect height="6" id="Rectangle" rx="2" stroke={config.theme.primary} strokeWidth="2" width="12" x="4" y="3"></rect>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </Box>
          <Text
            className={styles.text}
            color={pathname.includes('/admin/create-reservation') ? 'blue' : 'black'}
            size="md"
            weight="semi-bold"
          >
            <Trans>Reservations</Trans>
          </Text>
        </Link>
      </Box>
    </Box>
  );
}
