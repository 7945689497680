import {useTypedSelector} from "../../../../../../Store/Redux/store";
import {useState} from "react";
import {FeatureGroup, useMapEvents} from "react-leaflet";
import SectionObject from "../../../../../../App/Pages/CreateNewBooking/Desk/Map/SectionObject";
import DeskObjectAllBooked from "../DeskObject";
import { FloorMapObject } from "Admin/Store/floorMapDuck/models";

export default function MapDrawingAllBooked() {
  const floorMap = useTypedSelector((state) => state.adminFloorMap);
  const reservations = useTypedSelector((state) => state.adminReservations.mapViewBookings);
  const [zoomLevel, setZoomLevel] = useState<number>(1);

  useMapEvents({
    'zoom': (e) => {
      setZoomLevel(e.target._zoom);
    },
  });
  
  const sections = Object.values(floorMap.sections);
  const desks: Array<FloorMapObject & { bookingId?: string; userName?: string; }> = [];
    
  for (const desk of Object.values(floorMap.desks)) {
    const reservation = reservations.find(({ desk: { id: deskId } }) => deskId === desk.id);

    if (reservation) {
      desks.push({ ...desk, bookingId: reservation.id, userName: reservation.user.name });
    } else {
      desks.push(desk);
    }
  }

  return (
    <>
      {sections.map((section, index) => (
        <FeatureGroup key={`${section.id} ${index}`}>
          <SectionObject
            neighborsData='reservationsMapViewDetails'
            section={section}
            showNeighbors
            zoomLevel={zoomLevel}
          />
        </FeatureGroup>
      ))}
      {desks.map((desk, index) => (
        <FeatureGroup key={`${index}`}>
          <DeskObjectAllBooked desk={desk} />
        </FeatureGroup>
      ))}
    </>
  );
}
