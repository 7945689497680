import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherClock: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M9.067 0a1.067 1.067 0 1 1 0 2.133V3.29a6.366 6.366 0 0 1 2.646 1.097l.058-.066A1.067 1.067 0 0 1 13.28 5.83l-.066.058a6.4 6.4 0 1 1-6.28-2.599V2.133a1.067 1.067 0 0 1 0-2.133ZM8 5.333a4.267 4.267 0 1 0 0 8.534 4.267 4.267 0 0 0 0-8.534ZM8 6.4c.59 0 1.067.478 1.067 1.067v1.066h1.066a1.067 1.067 0 1 1 0 2.134H8c-.59 0-1.067-.478-1.067-1.067V7.467c0-.59.478-1.067 1.067-1.067Z"
        fill={props.fill}
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
      />
      <path
        d="M9.067 0a1.067 1.067 0 1 1 0 2.133V3.29a6.366 6.366 0 0 1 2.646 1.097l.058-.066A1.067 1.067 0 0 1 13.28 5.83l-.066.058a6.4 6.4 0 1 1-6.28-2.599V2.133a1.067 1.067 0 0 1 0-2.133ZM8 5.333a4.267 4.267 0 1 0 0 8.534 4.267 4.267 0 0 0 0-8.534ZM8 6.4c.59 0 1.067.478 1.067 1.067v1.066h1.066a1.067 1.067 0 1 1 0 2.134H8c-.59 0-1.067-.478-1.067-1.067V7.467c0-.59.478-1.067 1.067-1.067Z"
        fill={props.fill}
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
      />
    </SvgIcon>
  );
};
