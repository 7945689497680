import { Primitive } from "ts-essentials";

export const decodeResetQueryParam = <T = Record<string, Primitive>>(search: string): T | undefined => {
  const params = new URLSearchParams(search);
  const reset = params.get("reset");

  if (!reset) {
    return undefined;
  }

  return JSON.parse(reset) as T;
};
