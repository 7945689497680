import * as variables from './variables';
import { createTheme } from '@material-ui/core';
import { ThemeColors } from './models';

export const MaterialThemeBlue = createTheme({
  palette: {
    primary: {
      main: variables.darkBlue,
    },
    secondary: {
      main: variables.blue,
    },
    error: {
      main: variables.red,
    },
    success: {
      main: variables.green,
    },
    warning: {
      main: variables.orange,
    },
  },
});

export const ThemeBlue: ThemeColors = {
  primary: variables.darkBlue,
  primaryHover: variables.darkBlue2,
  primaryLight: variables.blueLight,
  primaryLighten20: variables.darkBlueLigthen20,
  primaryLighten50: variables.darkBlueLigthen50,
  primaryTransparent: variables.darkBlueTransparent,
  secondary: variables.blue,
  error: variables.red,
  errorTransparent: variables.redTransparent,
  warn: variables.orange,
  warnLight: variables.orangeLight,
  success: variables.green,
  successTransparent: variables.greenTransparent,
  grey: variables.grey,
  greyDark: variables.greyDark,
  greyDarkTransparent: variables.greyDarkTransparent,
  greyLight: variables.greyLight,
  greyLightTransparent: variables.greyLightTransparent,
  greyMedium: variables.greyMedium,
  greyTransparent: variables.greyTransparent,
};