import { GuardFunction } from "Components/ProtectedRoute/models";
import { useTypedSelector } from "Store/Redux/store";

const DeskGuard: GuardFunction = () => {
  const { floorId } = useTypedSelector(state => state.createNewBooking);

  if (!floorId) {
    return false;
  }
  
  return true;
};

export default DeskGuard;
