import { BookingType } from '../bookingDuck/models';
import { MeetingReservationRequestedServicesType } from "../../Meetings/meetingReservations/models";

export interface CreateNewBookingModel {
  bookingType: BookingType;
  dateFrom: Date;
  dateTo: Date;
  deskId?: string;
  roomId: string;
  filters: { [key: string]: boolean };
  floorId: string;
  locationId: string;
  parkingSpotId: string;
  meetingFrom?: Date;
  meetingTo?: Date;
  timeFrom?: Date | string;
  timeTo?: Date | string;
  weekDays: WeekDaysObject;
  timeSlots: TimeSlotsObject;
  attendees: Array<string>;
  meetingTitle: string;
  requestedServices: MeetingReservationRequestedServicesType[];
  isTeamsMeeting: boolean;
}

export interface SetCreateNewBookingRequest {
  bookingType?: BookingType;
  dateFrom?: Date;
  dateTo?: Date;
  deskId?: string;
  roomId?: string;
  filters?: { [key: string]: boolean };
  floorId?: string;
  locationId?: string;
  parkingSpotId?: string;
  meetingFrom?: Date;
  meetingTo?: Date;
  timeFrom?: Date;
  timeTo?: Date;
  weekDays?: WeekDaysObject;
  timeSlots?: TimeSlotsObject;
  attendees?: Array<string>;
  meetingTitle?: string;
  requestedServices?: MeetingReservationRequestedServicesType[];
  isTeamsMeeting?: boolean;
  weeklySlots?: WeeklySlot[];
}

export type WeekDaysObject = {
  [key: number]: {
    label: string;
    selected: boolean;
    timeFrom: number;
    timeTo: number;
    value: number;
  };
}

export enum WeeklySlotDay {
  SUNDAY = "Sun",
  MONDAY = "Mon",
  TUESDAY = "Tue",
  WEDNESDAY = "Wed",
  THURSDAY = "Thu",
  FRIDAY = "Fri",
  SATURDAY = "Sat",
}

export type WeeklySlot = {
  index: number;
  day: WeeklySlotDay;
  isSelected: boolean;
  timeFrom?: Date | string;
  timeTo?: Date | string;
}

export type TimeSlotsObject = {
  [key: number]: {
    label: string;
    selected: boolean;
    timeFrom: number;
    timeTo: number;
    value: number;
  };
}


