import {useTypedSelector} from "../../../../../Store/Redux/store";
import {useState} from "react";
import {FeatureGroup, useMapEvents} from "react-leaflet";
import SectionObject from "../../../../../App/Pages/CreateNewBooking/Desk/Map/SectionObject";
import AdminBookingDesk from "../AdminBookingDesk";
import { isDeskAvailableForOwner } from "../../../../../Functions/checkDeskOwners";
import { DeskStatus, DeskStatusEnum } from "../../../../Store/floorMapDuck/desk/models";

interface Props {
  showOnlyBookedDesk?: boolean;
}

export default function ReservationJustInTimeMapDrawing({ showOnlyBookedDesk }: Props) {
  const { adminFloorMap, availableDesks, createNewBooking, adminReservationJustInTime } = useTypedSelector(state => state);
  const { desks, sections } = adminFloorMap;
  const { selectedData } = adminReservationJustInTime;
  const [zoomLevel, setZoomLevel] = useState<number>(1); // variable to handle zoom event

  const { deskId } = createNewBooking;
  const selectedUserId = selectedData.user?.id;
  const userGroups = selectedData.user?.groups;

  const deskStatusToHide: DeskStatus[] = [DeskStatusEnum.Unavailable];
  // Transform the objects with desks and sections into arrays
  const desksArray = Object.keys(desks).map(key => desks[key]).filter(desk => !deskStatusToHide.includes(desk.status));
  // add desk owner's desk as available in case of match selectedUserId (get from user booking details)
  const sectionsArray = Object.keys(sections).map(key => sections[parseInt(key)]);

  const bookedDesk = desksArray.find(desk => desk.id === deskId);

  useMapEvents({
    'zoom': (e) => {
      setZoomLevel(e.target._zoom);
    },
  });

  return (
    <>
      {sectionsArray.map((section, index) => {
        const showSection = showOnlyBookedDesk ? bookedDesk?.parentId === section.id : true;

        return showSection && (
          <FeatureGroup key={`${section.id} ${index}`}>
            <SectionObject
              neighborsData='adminReservationJustInTime'
              section={section}
              showNeighbors
              zoomLevel={zoomLevel}
            />
          </FeatureGroup>
        );
      })}

      {desksArray.map((desk) => {
        const isOnlyOneBooked = deskId === desk.id;
        const isDeskHasBooking = availableDesks.availableDesks[desk.id]?.isBooked;
        const isAvailableForOwner = isDeskAvailableForOwner({ selectedUserId, userGroups, desk });

        // show only available desk fow owners BUT show non available if its booked (need for actual neighbours info)
        const showDesk = showOnlyBookedDesk ? isOnlyOneBooked && isAvailableForOwner
          : isAvailableForOwner || isDeskHasBooking;

        return showDesk && (
          <FeatureGroup key={`${desk.id} ${desk.parentId} ${sectionsArray.length}`}>
            <AdminBookingDesk desk={desk} />
          </FeatureGroup>
        );
      })}
    </>
  );
}
