import Box from 'Components/Box';
import DashboardItems from './DashboardItems';
import LocationDetailsHeader from 'Admin/Components/Header/LocationDetailsHeader';
import styles from './styles.module.scss';
import TotalByDay from './TotalByDay';
import TotalByDomain from './TotalByDomain';
import TotalByPeriod from './TotalByPeriod';

export const TOP_CHARTS_HEIGHT = 300;

/**
 * Location dashboard
 *
 * reference: https://projects.invisionapp.com/d/main#/console/21248637/459310941/preview
 *
 * OwnedBy: Bruno
 */
export default function Dashboard() {
  return (
    <>
      <LocationDetailsHeader />

      <Box className={styles.dashboardContainer} dataTestId="dashboard-container">
        <DashboardItems />

        <Box className={styles.grid}>
          <Box className={styles.month}>
            <TotalByPeriod />
          </Box>

          <Box className={styles.day}>
            <TotalByDay />
          </Box>

          <Box className={styles.domain}>
            <TotalByDomain />
          </Box>
        </Box>
      </Box>
    </>
  );
}
