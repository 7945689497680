import Box from 'Components/Box';
import Container from 'Components/Container';
import qs from 'qs';
import Text from 'Components/Text';
import { confirmOrDenyBooking } from 'BookingApproval/Store/bookingApproval';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTypedSelector } from 'Store/Redux/store';

export default function Deny() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { confirmed, error, loading } = useTypedSelector(state => state.bookingApproval);

  const { deskName, token } = qs.parse(location.search.replace('?', ''));

  useEffect(() => {
    if (typeof token === 'string') {
      dispatch(confirmOrDenyBooking({ token }));
    }
  }, []);

  return (
    <Box>
      <Container>
        {!loading && (
          <Box display="flex" justifyContent="center" margin="30px">
            {confirmed ? (
              <Box>
                <Text align="center" size="xlg">
                  The desk <strong>{deskName}</strong> reservation was successfully cancelled
                </Text>
              </Box>
            ) : (
              <Box>
                <Text align="center" size="xlg">
                  {error ?? 'An error ocurred while denying the booking. Please try again.'}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
}