interface Props {
  color?: string;
  size?: number;
}

export default function Search({ color = '#4A4A4A', size = 25 }: Props) {
  return (
    <svg height={size} version="1.1" viewBox="0 0 24 24" width={size}>
      <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
        <g fill={color} fillRule="nonzero" id="icons/search">
          <g id="delete-white-copy-5">
            <path
              d="M6.05025253,6.05025253 C8.78392257,3.31658249 13.2160774,3.31658249 15.9497475,6.05025253 C18.4434716,8.54397661 18.6623564,12.4511046 16.6064021,15.1929497 L20.1923882,18.7781746 C20.5829124,19.1686989 20.5829124,19.8018639 20.1923882,20.1923882 C19.8018639,20.5829124 19.1686989,20.5829124 18.7781746,20.1923882 L15.1929497,16.6064021 C12.4511046,18.6623564 8.54397661,18.4434716 6.05025253,15.9497475 C3.31658249,13.2160774 3.31658249,8.78392257 6.05025253,6.05025253 Z M14.5355339,7.46446609 C12.5829124,5.51184464 9.41708755,5.51184464 7.46446609,7.46446609 C5.51184464,9.41708755 5.51184464,12.5829124 7.46446609,14.5355339 C9.41708755,16.4881554 12.5829124,16.4881554 14.5355339,14.5355339 C16.4881554,12.5829124 16.4881554,9.41708755 14.5355339,7.46446609 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
