import Box from 'Components/Box';
import format from 'date-fns/format';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'Components/Select';
import styles from './styles.module.scss';
import Switch from '@material-ui/core/Switch';
import Tooltip from 'Components/Tooltip';
import { setCreateNewVisitData } from 'App/Store/Bookings/createNewVisitDuck';
import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import {
  getLocationAvailableParkingSpots,
  getLocationParkingSpots,
  selectLocationAvailableParkingSpotsSorted,
  selectLocationParkingSpots,
} from 'Admin/Store/parking';

export default function VisitParkingSpot() {
  const dispatch = useDispatch();
  const { createNewVisit, parking } = useTypedSelector(state => state);

  const {
    dateFrom,
    dateTo,
    locationId = '',
    parkingSpotId,
    timeFrom,
    timeTo,
  } = createNewVisit;
  const { loading: parkingLoading } = parking;
  const allParkingSpotsFromLocation = selectLocationParkingSpots(parking, locationId);
  const parkingSpots = selectLocationAvailableParkingSpotsSorted(parking, locationId);

  const [enableParkingSpot, setEnableParkingSpot] = useState(false);

  const disableParkingSelect = !enableParkingSpot || parkingLoading; // disable if data is loaded or switcher is off
  const parkingValue = parkingLoading ? '' : (createNewVisit.parkingSpotId ?? ''); // show selected parking or loading placeholder while data is not ready

  const handleChangeParkingSpot = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    dispatch(setCreateNewVisitData({ parkingSpotId: event.target.value as string }));
  };

  const onResetParkingVisitData = () => {
    dispatch(setCreateNewVisitData({ parkingSpotId: undefined }));
  };

  useEffect(() => {
    // clear selection if no parking for selected date/selection after change filters
    const nonAvailableParking = !parkingSpots.some(parking => parking.id === parkingSpotId);
    if (parkingSpotId && nonAvailableParking || !parkingSpots?.length) {
      onResetParkingVisitData();
    }
  }, [parking, dateFrom, dateTo, timeFrom, timeTo]);

  useEffect(() => {
    // When parking spot is disabled, clear it from redux to avoid passing a parking spot to visits that doesn't need it
    if (!enableParkingSpot && parkingSpotId) {
      onResetParkingVisitData();
    }
  }, [enableParkingSpot]);

  useEffect(() => {
    dispatch(getLocationAvailableParkingSpots({
      locationId,
      data: {
        custom: {
          dateFrom: dateFrom ? format(new Date(dateFrom), 'yyyy-MM-dd') : '',
          dateTo: dateTo ? format(new Date(dateTo), 'yyyy-MM-dd') : '',
          timeFrom: timeFrom ?? '',
          timeTo: timeTo ?? '',
        },
        daily: undefined,
        weekly: undefined,
      },
    }));
    dispatch(getLocationParkingSpots({ locationId }));
  }, [dateFrom, dateTo, timeFrom, timeTo]);

  const parkingSpotSwitch = (
    <FormControlLabel
      classes={{ label: styles.switchLabel }}
      control={
        <Switch
          checked={enableParkingSpot}
          className="switcher switcher--default"
          name="Need Parking"
          onChange={() => setEnableParkingSpot(current => !current)}
        />
      }
      disabled={parkingSpots.length === 0}
      label={t`I need parking`}
    />
  );

  return allParkingSpotsFromLocation.length > 0 ? (
    <Box className={styles.container}>
      {parkingSpots.length > 0 ? (
        <>
          {parkingSpotSwitch}

          <Select
            classes={{
              input: styles.input,
              wrapper: styles.selectContainer,
            }}
            disabled={disableParkingSelect}
            displayEmpty
            fullWidth
            id="parking-spot"
            items={[
              {
                name: parkingLoading ? 'Loading parking data' : t`Select parking place`,
                value: '',
              },
              ...parkingSpots.map(parkingSpot => ({
                name: t`${parkingSpot.floor ? `Floor ${parkingSpot.floor}, ` : ''}${parkingSpot.name}`,
                value: parkingSpot.id,
              })),
            ]}
            labelColor="gray"
            onChange={handleChangeParkingSpot}
            value={parkingValue}
          />
        </>
      ) : (
        <Tooltip title={t`No available parking spots for the period selected`}>
          {parkingSpotSwitch}
        </Tooltip>
      )}
    </Box>
  ) : null;
}