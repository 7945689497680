import {useTypedSelector} from "../../../../Store/Redux/store";
import styles from "../styles.module.scss";
import clsx from "clsx";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {selectDeskSection} from "../../../Store/floorMapDuck";
import {FloorMapObject} from "../../../Store/floorMapDuck/models";
import DeskSelectCheckbox from "./DeskSelectCheckbox";
import DeskSelectStatus from "./DeskSelectStatus";
import DeskAmenitiesPopup from "./DeskAmenitiesPopup";
import DeskDeletePopup from "./DeskDeletePopup";
import { DeskOwners } from "./DeskOwners";
import { DeskGroups } from "./DeskGroups";
import Text from "Components/Text";

interface DeskListTableBodyProps {
  desksArray: FloorMapObject[];
}

export default function DeskListTableBody(props: DeskListTableBodyProps) {
  const { adminFloorMap } = useTypedSelector(state => state);
  const { desksArray } = props;
  const { editDeskList } = adminFloorMap;

  return (
    <TableBody classes={{ root: styles.tableBody }}>
      {desksArray.map((desk, index) => {
        const labelId = desk.id;
        const deskSection = typeof desk.id === 'string' ? selectDeskSection(adminFloorMap, desk.id) : undefined;
        const checked = typeof desk.id === 'string' ? Boolean(editDeskList.selectedDesks[desk.id]) : false;

        const tableRowClasses = clsx(
          styles.tableRow,
          checked && styles.tableRowChecked,
        );

        return (
          <TableRow
            classes={{ root: tableRowClasses }}
            hover
            key={String(desk.id) + index}
            tabIndex={-1}
          >
            <TableCell
              classes={{ root: `${styles.tableCell} ${styles.tableCellSmall}` }}
              padding="none"
            >
              <DeskSelectCheckbox checked={checked} desk={desk} />
            </TableCell>

            <TableCell
              classes={{ root: styles.tableCell }}
              id={String(labelId)}
              padding="none"
              style={{ minWidth: 180 }}
            >
              <Text truncate weight="normal">{desk.name}</Text>
            </TableCell>

            <TableCell classes={{ root: styles.tableCell }} style={{ minWidth: 120 }}>
              {deskSection ? deskSection.name : '-'}
            </TableCell>

            <TableCell classes={{ root: styles.tableCell }} style={{ minWidth: 120 }}>
              <DeskSelectStatus desk={desk}/>
            </TableCell>

            <TableCell classes={{ root: styles.tableCell }} style={{ minWidth: 120 }}>
              <DeskOwners desk={desk} />
            </TableCell>

            <TableCell classes={{ root: styles.tableCell }} style={{ minWidth: 120 }}>
              <DeskGroups desk={desk} />
            </TableCell>

            <TableCell classes={{ root: styles.tableCell }} style={{ maxWidth: 360 }}>
              <DeskAmenitiesPopup desk={desk}/>
            </TableCell>
            
            <TableCell align="right" classes={{ root: styles.tableCell }}>
              <DeskDeletePopup desk={desk} />
            </TableCell>
          </TableRow>
        );
      })
      }
    </TableBody>
  );
}

