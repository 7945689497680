import Box from 'Components/Box';
import Button from 'Components/Button';
import CloseIcon from '@material-ui/icons/Close';
import Compressor from 'compressorjs';
import Dialog from '@material-ui/core/Dialog';
import Heading from 'Components/Heading';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import TextField from 'Components/TextField';
import useSnackbar from 'Components/Snackbar/useSnackbar';
import { InputComponent, LayoutComponent, PreviewComponent } from '../../../../Components/DropZone';
import { clearFloorMapState, setFloorMapData } from 'Admin/Store/floorMapDuck';
// @ts-ignore
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import { Redirect, useParams } from 'react-router-dom';
import { saveMapFile, setFloorMapApiRequestsData } from 'Admin/Store/floorMapApiRequestsDuck';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone, {
  IFileWithMeta,
  StatusValue,
} from 'react-dropzone-uploader';
import L from "leaflet";
import {getImageBoundsLimited} from "../../../Pages/FloorMap/Map/Helpers/mapFunctions";

export const MAX_IMAGE_HEIGHT_WIDTH = 1500;

/**
 * Add floor button with modal to upload file.
 */
export default function AddFloorButton() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const [openSnackbar] = useSnackbar();
  const [fileWithMeta, setFileWithMeta] = useState<IFileWithMeta | null>(null);
  const [floorNumber, setFloorNumber] = useState('');
  const [open, setOpen] = useState(false);
  const { adminFloorMap, adminFloorMapApiRequests, config } = useTypedSelector(state => state);

  const { loading, mapFileUploaded } = adminFloorMapApiRequests;

  useEffect(() => {
    dispatch(setFloorMapApiRequestsData({ mapFileUploaded: false }));
  }, []);

  useEffect(() => {
    if (mapFileUploaded && fileWithMeta && floorNumber) {
      dispatch(setFloorMapData({
        mapFileWithMeta: fileWithMeta,
        name: floorNumber,
      }));

      setOpen(false);
    }
  }, [mapFileUploaded]);

  useEffect(() => {
    if (adminFloorMapApiRequests.error) {
      const handleClose = () => {
        dispatch(setFloorMapApiRequestsData({ error: '' }));
      };

      openSnackbar({
        onClose: handleClose,
        text: adminFloorMapApiRequests.error,
        type: 'error',
      });
    }
  }, [adminFloorMapApiRequests.error]);

  // Correctly resolves the input file upload
  const getFilesFromEvent = (e: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>): File[] | Promise<File[]> => {
    return new Promise(resolve => {
      getDroppedOrSelectedFiles(e).then((chosenFiles: any) => {
        resolve(chosenFiles.map((f: any) => f.fileObject));
      });
    });
  };

  // Clears previous state, add file and name to redux, and closes modal
  const handleAddFloor = () => {
    if (fileWithMeta) {
      const height = fileWithMeta.meta.height ?? 1000;
      const width = fileWithMeta.meta.width ?? 1000;
      // use getImageBoundsLimited by default (specific mapImageBounds can be added only after edit map)
      const mapImageBounds: L.LatLngBoundsExpression = getImageBoundsLimited({ height, proportion: 100, width });

      dispatch(clearFloorMapState());

      dispatch(saveMapFile({
        locationId,
        data: {
          floorName: floorNumber,
          map: fileWithMeta.file,
          mapSize: {
            height,
            width,
          },
          // @ts-ignore its correct type
          mapImageBounds,
        },
      }));

      dispatch(setFloorMapData({
        mapSize: {
          height: fileWithMeta.meta.height ?? 1000,
          width: fileWithMeta.meta.width ?? 1000,
        },
        previewUrl: fileWithMeta.meta.previewUrl ?? '',
      }));
    }
  };

  // Listen for status changes on file upload and removes/updates the file properly
  const handleChangeStatus = (fileWithMetaResponse: IFileWithMeta, status: StatusValue) => {
    if (status === 'done') {
      fileWithMeta?.remove();

      new Compressor(fileWithMetaResponse.file, {
        maxHeight: MAX_IMAGE_HEIGHT_WIDTH,
        maxWidth: MAX_IMAGE_HEIGHT_WIDTH,
        quality: 1,
        success: async (result: File) => {
          const reader = new FileReader();

          // Get an image from the file to take meta data
          reader.onload = function (e: any) {
            const imageObject = new Image();
            const previewUrl = window.URL.createObjectURL(result);

            imageObject.src = e.target.result;

            // After the image is loaded, sets metadata
            imageObject.onload = () => {
              fileWithMetaResponse.file = result;
              fileWithMetaResponse.meta.previewUrl = previewUrl;
              fileWithMetaResponse.meta.height = imageObject.height;
              fileWithMetaResponse.meta.width = imageObject.width;
              fileWithMetaResponse.meta.size = result.size;

              setFileWithMeta(fileWithMetaResponse);
            };
          };

          reader.readAsDataURL(result);
        },
        error() {
          setFileWithMeta(fileWithMetaResponse);
        },
      });
    }
  };

  // Remove file from state and from input
  const handleRemoveFile = () => {
    if (fileWithMeta) {
      fileWithMeta.remove();
      setFileWithMeta(null);
    }
  };

  // Toggle modal open state
  const handleToggle = () => {
    setOpen(!open);
  };

  if (mapFileUploaded && fileWithMeta && floorNumber) {
    return <Redirect to={`/admin/location/${locationId}/add-floor/${adminFloorMap.id}/floor-map`} />;
  }

  return (
    <Box>
      <Button
        aria-label={t`Add Floor`}
        name={t`Add Floor`}
        onClick={handleToggle}
        size="sm"
      >
        <Trans>
          Add Floor
        </Trans>
      </Button>

      <Dialog
        aria-labelledby="add floor dialog"
        className={styles.dialogContainer}
        classes={{ paper: styles.dialog }}
        fullWidth
        maxWidth="xs"
        onClose={handleToggle}
        open={open}
      >
        <Box>
          <Box alignItems="center" display="flex" justifyContent="between">
            <Heading size="sm">
              <Trans>
                Add floor
              </Trans>
            </Heading>

            <IconButton
              onClick={handleToggle}
              size="small"
              style={{
                backgroundColor: config.theme.primaryLight,
                borderRadius: 8,
                height: 30,
                width: 30,
              }}
            >
              <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
            </IconButton>
          </Box>

          <Box marginTop={12}>
            <TextField
              id="floorNumber"
              label={t`Floor number`}
              onChange={(event) => setFloorNumber(event.target.value)}
              placeholder={t`Type here`}
              required
              value={floorNumber}
            />

            <Box marginTop={20}>
              <Box marginBottom={9} paddingLeft={8} position="relative">
                <InputLabel className={styles.inputLabel} htmlFor="add-image">
                  <Box left={0} position="absolute" top={-1}>
                    <Text color="orange" inline weight="semi-bold">* </Text>
                  </Box>

                  <Text size="md" weight="semi-bold">
                    <Trans>
                      Select floor map (image file)
                    </Trans>
                  </Text>
                </InputLabel>
              </Box>

              <Dropzone
                InputComponent={InputComponent}
                LayoutComponent={LayoutComponent}
                PreviewComponent={(props) => <PreviewComponent {...props} handleRemoveFile={handleRemoveFile} />}
                accept="image/*"
                classNames={{
                  dropzone: '',
                }}
                getFilesFromEvent={getFilesFromEvent}
                multiple={false}
                onChangeStatus={handleChangeStatus}
              />
            </Box>
          </Box>

          <Box
            alignItems="center"
            display="flex"
            justifyContent="end"
            marginTop={15}
          >
            <Button
              aria-label={t`Cancel Add Floor`}
              name={t`Cancel Add Floor`}
              onClick={handleToggle}
              size="sm"
              type="clear"
              withShadow={false}
            >
              <Trans>
                Cancel
              </Trans>
            </Button>

            <Button
              aria-label={t`Add Floor`}
              buttonType="submit"
              disabled={!fileWithMeta || !floorNumber || loading}
              name={t`Add Floor`}
              onClick={handleAddFloor}
              size="sm"
            >
              {loading ? t`Uploading file...` : t`Add Floor`}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}
