import { t } from '@lingui/macro';

export type EmailTemplateTypes = 'cancelByAdmin' | 'confirmBooking' | 'questionnaireCancellation' | 'questionnaireReminder' | 'questionnaireStart' | 'attendeesInvitation';

export const EmailTemplateTypesNames: Record<EmailTemplateTypes, string> = {
  cancelByAdmin: t`Booking cancelled notification`,
  confirmBooking: t`Booking confirmed`,
  questionnaireCancellation: t`Booking cancel notification`,
  questionnaireReminder: t`Questionnaire remained time notification`,
  questionnaireStart: t`Questionnaire start notifications`,
  attendeesInvitation: t`Reservation attendees description`,
};

export interface EmailTemplate {
  id: string;
  emailNotificationText: string;
  pushNotificationText: string;
  type: EmailTemplateTypes;
  locationId: string;
  emailNotificationTitle: string;
  pushNotificationTitle: string;
}

export interface CreateOrUpdateEmailTemplateData {
  emailNotificationText: string;
  pushNotificationText: string;
  templateType: EmailTemplateTypes;
  emailNotificationTitle: string;
  pushNotificationTitle: string;
}

export interface CreateOrUpdateEmailTemplateRequest {
  locationId: string;
  emailTemplateData: CreateOrUpdateEmailTemplateData;
}

export interface CreateOrUpdateEmailTemplateResponse {
  result: {
    statusCode: number;
    data: EmailTemplate;
  }
}

export interface GetEmailTemplatesRequest {
  locationId: string;
}

export interface GetEmailTemplatesResponse {
  result: {
    statusCode: number;
    data: EmailTemplate[];
  }
}

export interface SetEmailTemplatesDataRequest {
  success?: string;
}
