import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../Store/Redux/store';
import moment from "moment";
import FileSaver from 'file-saver';
import {exportReservations, setReservationsData} from '../../../Store/reservations';
import Api from "../../../../Store/Services/Api";
import DownloadExcelButton from "../../../Components/Buttons/DownloadExcelButton";
import { SuccessReservationsSnackbar } from '../SnackBars';
import {getAllowedLocationsByRoles, getLocationIdsByRoles} from "../../Locations/Helpers";

export default function ReservationExportCsv() {
  const dispatch = useDispatch();
  const { adminReservations, profile, locations } = useTypedSelector(state => state);
  const { filters } = adminReservations;
  const { roleAccess, locationIds: profileLocationIds } = profile;
  const { selectedStartDate, selectedEndDate, statuses, documentStatuses, checking, search } = filters;

  // set restrictions for local admin role
  const allowedLocations = getAllowedLocationsByRoles(roleAccess, locations.adminLocations, profileLocationIds);
  const restrictedLocationIds = getLocationIdsByRoles(roleAccess, filters, allowedLocations);

  const onExportReservations = async () => {
    try {
      const action = dispatch(exportReservations({
        statuses,
        documentStatuses,
        locationIds: restrictedLocationIds,
        search,
        checking,
        dateFrom: moment(selectedStartDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'), // start of day in iso format
        dateTo: moment(selectedEndDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss'), // end of day in iso format
      }));
      dispatch(setReservationsData({ successMessage: 'The file is being generated in background. Please do not refresh or close this page.' }));

      const payload = await Api(action);

      if (payload.status === 200 || payload.status === 201) {
        const csv = payload.data;
        const blob = new Blob([csv], {});

        return FileSaver.saveAs(blob, `reservations-${moment().format('YYYY-MM-DDTHH:mm:ss')}.xlsx`);
      }
    } catch (e) {
      console.log('error with exporting reservations');
    }
  };

  return (
    <>
      <DownloadExcelButton onSubmit={onExportReservations} />
      <SuccessReservationsSnackbar />
    </>
  );
}
