import Api from 'Store/Services/Api';
import { matchPath } from 'react-router-dom';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_LOCATION_DASHBOARDS_TOTAL_COUNT,
  GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD,
  GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD_FAIL,
  GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD_SUCCESS,
  GET_LOCATION_DASHBOARDS_TOTAL_COUNT_FAIL,
  GET_LOCATION_DASHBOARDS_TOTAL_COUNT_SUCCESS,
  GET_LOCATION_DASHBOARD_TOTAL_BY_DAY,
  GET_LOCATION_DASHBOARD_TOTAL_BY_DAY_FAIL,
  GET_LOCATION_DASHBOARD_TOTAL_BY_DAY_SUCCESS,
  GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN,
  GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN_FAIL,
  GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN_SUCCESS,
  GetLocationDashboardTotalByDay,
  GetLocationDashboardTotalByDayFail,
  GetLocationDashboardTotalByDaySuccess,
  GetLocationDashboardTotalByDomain,
  GetLocationDashboardTotalByDomainFail,
  GetLocationDashboardTotalByDomainSuccess,
  GetLocationDashboardsTotalCount,
  GetLocationDashboardsTotalCountByPeriod,
  GetLocationDashboardsTotalCountByPeriodFail,
  GetLocationDashboardsTotalCountByPeriodSuccess,
} from "./index";

function* getLocationDashboardTotalByDaySaga(action: GetLocationDashboardTotalByDay): any {
  try {
    const payload = yield call(Api, action);

    const match = matchPath<{ locationId: string }>(action.payload.request.url, {
      path: '/api/bookings/:locationId/period/:period/dashboards/totalByDay',
    });

    const locationId = match?.params.locationId ?? '';

    const success: GetLocationDashboardTotalByDaySuccess = {
      type: GET_LOCATION_DASHBOARD_TOTAL_BY_DAY_SUCCESS,
      payload: {
        locationId,
        response: payload,
      },
    };

    yield put(success);
  } catch (e: any) {
    const fail: GetLocationDashboardTotalByDayFail = {
      type: GET_LOCATION_DASHBOARD_TOTAL_BY_DAY_FAIL,
      payload: e,
    };

    yield put(fail);
  }
}

function* getLocationDashboardTotalByDomainSaga(action: GetLocationDashboardTotalByDomain): any {
  try {
    const payload = yield call(Api, action);

    const match = matchPath<{ locationId: string }>(action.payload.request.url, {
      path: '/api/bookings/:locationId/period/:period/dashboards/totalByDomain',
    });

    const locationId = match?.params.locationId ?? '';

    const success: GetLocationDashboardTotalByDomainSuccess = {
      type: GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN_SUCCESS,
      payload: {
        locationId,
        response: payload,
      },
    };

    yield put(success);
  } catch (e: any) {
    const fail: GetLocationDashboardTotalByDomainFail = {
      type: GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN_FAIL,
      payload: e,
    };

    yield put(fail);
  }
}

function* getLocationDashboardsTotalCountSaga(action: GetLocationDashboardsTotalCount): any {
  try {
    const payload = yield call(Api, action);
    yield put({ type: GET_LOCATION_DASHBOARDS_TOTAL_COUNT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_LOCATION_DASHBOARDS_TOTAL_COUNT_FAIL, payload: e });
  }
}

function* getLocationDashboardsTotalCountByPeriodSaga(action: GetLocationDashboardsTotalCountByPeriod): any {
  try {
    const payload = yield call(Api, action);

    const match = matchPath<{ locationId: string }>(action.payload.request.url, {
      path: '/api/bookings/:locationId/period/:period/dashboards/totalCountByPeriod',
    });

    const locationId = match?.params.locationId ?? '';

    const success: GetLocationDashboardsTotalCountByPeriodSuccess = {
      type: GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD_SUCCESS,
      payload: {
        locationId,
        response: payload,
      },
    };

    yield put(success);
  } catch (e: any) {
    const fail: GetLocationDashboardsTotalCountByPeriodFail = {
      type: GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD_FAIL,
      payload: e,
    };

    yield put(fail);
  }
}

export default [
  takeLatest(GET_LOCATION_DASHBOARD_TOTAL_BY_DAY, getLocationDashboardTotalByDaySaga),
  takeLatest(GET_LOCATION_DASHBOARD_TOTAL_BY_DOMAIN, getLocationDashboardTotalByDomainSaga),
  takeLatest(GET_LOCATION_DASHBOARDS_TOTAL_COUNT, getLocationDashboardsTotalCountSaga),
  takeLatest(GET_LOCATION_DASHBOARDS_TOTAL_COUNT_BY_PERIOD, getLocationDashboardsTotalCountByPeriodSaga),
];
