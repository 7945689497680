import * as React from "react";
import {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from "@material-ui/lab/Autocomplete/Autocomplete";
import { t } from "@lingui/macro";
import styles from "./styles.module.scss";

import { TextField } from "@material-ui/core";
import Box from "../Box";
import OverflowToolTip from "../OverflowTooltip";

export function AutocompleteRenderInputDefault(params: AutocompleteRenderInputParams): React.ReactNode {
  return (
    <TextField
      {...params}
      className={`input input--default`}
      placeholder={t`Not selected`}
      variant="outlined"
    />
  );
}

export function AutocompleteRenderOptionTruncate(optionName: string, state: AutocompleteRenderOptionState): React.ReactNode {
  const selectedMockClass = state.selected ? '' : '';

  return (
    <li className={`${styles.renderOptionBlock} ${selectedMockClass}`}>
      <Box>
        <OverflowToolTip text={optionName} />
      </Box>
    </li>
  );
}
