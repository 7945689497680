import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherVisit: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <g transform="matrix(.55172 0 0 .55172 -4.69 -3.862)">
        <ellipse
          cx="19.5"
          cy="16.143"
          fill="none"
          fillRule="evenodd"
          rx="7"
          ry="7.143"
          stroke={props.stroke}
          strokeOpacity="1"
          strokeWidth="2"
        />
        <path
          d="M30 34c0-5.917-4.701-10.714-10.5-10.714S9 28.083 9 34"
          fill="none"
          fillRule="evenodd"
          stroke={props.stroke}
          strokeLinecap="round"
          strokeOpacity="1"
          strokeWidth="2"
        />
        <path
          d="M23.369 21.69a5.349 5.349 0 0 0 2.193 2.627c.786.479 1.705.754 2.688.754 2.9 0 5.25-2.398 5.25-5.357 0-2.958-2.35-5.357-5.25-5.357-.481 0-.947.066-1.39.19"
          fill="none"
          fillRule="evenodd"
          stroke={props.stroke}
          strokeOpacity="1"
          strokeWidth="2"
        />
        <path
          d="M37 34c0-4.931-3.918-8.929-8.75-8.929"
          fill="none"
          fillRule="evenodd"
          stroke={props.stroke}
          strokeLinecap="round"
          strokeOpacity="1"
          strokeWidth="2"
        />
      </g>
    </SvgIcon>
  );
};
