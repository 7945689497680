import { Box, BoxProps, CircularProgress } from "@mui/material";

export const ProgressOverlay: React.FC<BoxProps> = (props) => {
  return (
    <Box
      {...props}
      alignItems="center"
      bottom={0}
      display="flex"
      justifyContent="center"
      left={0}
      position="absolute"
      right={0}
      top={0}
      zIndex={1}
    >
      <CircularProgress />
    </Box>
  );
};
