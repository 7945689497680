import React from "react";
import { DndContext, DndContextProps, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { SortableListProps } from "./types";
import { Box } from "@mui/material";
import { resolveDragEndEventIndexes } from "utils";

export const SortableList: React.FC<SortableListProps> = (props) => {
  const { children, items, onChange } = props;

  const handleDragEnd: DndContextProps["onDragEnd"] = (event) => {
    const indexes = resolveDragEndEventIndexes(event, items);

    if (indexes) {
      const [current, next] = indexes;

      onChange?.(current, next);
    }
  };

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <Box display="flex" flexDirection="column">
          {children}
        </Box>
      </SortableContext>
    </DndContext>
  );
};
