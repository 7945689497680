interface Props {
  color?: string;
  size?: number;
}

export default function ArrowRight({ color = '#4A4A4A', size = 16 }: React.PropsWithChildren<Props>) {
  return (
    <svg height={size} viewBox="0 0 16 16" width={size}>
      <g enableBackground="new">
        <clipPath id="cp0">
          <path
            d="M 9.050253 12.94975 C 8.659728 12.55922 8.659728 11.92606 9.050253 11.53553 L 11.586 9.000641 L 1.757359 9 C 1.205075 9 .7573593 8.552285 .7573593 8 C .7573593 7.447715 1.205075 7 1.757359 7 L 11.586 7.000641 L 9.050253 4.464466 C 8.659728 4.073942 8.659728 3.440777 9.050253 3.050253 C 9.440777 2.659728 10.07394 2.659728 10.46447 3.050253 L 14.70711 7.292893 C 15.09763 7.683418 15.09763 8.316582 14.70711 8.707107 L 10.46447 12.94975 C 10.07394 13.34027 9.440777 13.34027 9.050253 12.94975 Z "
            fillRule="evenodd"
            transform="matrix(1,0,0,-1,0,16)"
          />
        </clipPath>

        <g clipPath="url(#cp0)">
          <clipPath id="cp1">
            <path d="M -272 -937 L 1168 -937 L 1168 109 L -272 109 Z " transform="matrix(1,0,0,-1,0,16)" />
          </clipPath>

          <g clipPath="url(#cp1)">
            <path
              d="M -.2426407 1.659728 L 16.097628 1.659728 L 16.097628 14.340268 L -.2426407 14.340268 Z "
              fill={color}
              transform="matrix(1,0,0,-1,0,16)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}


