import Box from 'Components/Box';
import DailyTimeBooking from '../DailyTimeBooking';
import Divider from '../../../../../Components/Divider';
import styles from '../styles.module.scss';
import accordionStyles from './styles.module.scss';
import Text from 'Components/Text';
import { Trans } from '@lingui/macro';
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';
import Button from 'Components/Button';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'Store/Redux/store';
import { useEffect, useState } from 'react';
import moment from 'moment';
import Datetime from 'Components/Icons/Datetime';

interface AccordionRoomCalendarProps {
  showExpand: boolean;
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`calendar-tab-${index}`}
      hidden={value !== index}
      id={`calendar-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && children}
    </div>
  );
}

/**
 * Calendar component to select date and time ranges.
 */
export default function AccordionRoomCalendar({ showExpand = true }: AccordionRoomCalendarProps) {  
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(true);
  const [formattedSelectedDate, setFormattedSelectedDate] = useState('');
  const { config } = useTypedSelector(state => state);
  const { meetingFrom, meetingTo, selectedData } = useTypedSelector(state => state.createNewMeeting);
  const { roomId } = selectedData;  

  const handleTodayClick = (e: any) => {
    e.stopPropagation(); // to not handle accordion    

    dispatch(setCreateNewMeetingData({ 
      meetingFrom: undefined,
      meetingTo: undefined,
      dateFrom: new Date(),
      dateTo: new Date(), 
      selectedData: {
        ...selectedData,
        roomId: null,
      }}));
  };

  const onToggleExpanded = () => {
    if (!showExpand) return;

    setExpanded(expanded => !expanded);
  };  

  useEffect(() => {
    if (!showExpand) setExpanded(showExpand);
  }, [showExpand]);

  useEffect(() => {  
    if (!meetingFrom && !meetingTo) return;

    const formattedSelectedDate = formatSelectedDate();

    setFormattedSelectedDate(formattedSelectedDate);
  }, [roomId, meetingFrom, meetingTo]);

  const formatSelectedDate = () => {
    const formattedDay = moment(meetingFrom).format("DD MMM, YYYY");
    const timetable = `${moment(meetingFrom).format('HH:mm')} - ${moment(meetingTo).format('HH:mm')}`;

    return `${formattedDay} ${timetable}`;
  };  

  return (
    <Accordion
      classes={{expanded: accordionStyles.accordionExpanded, root: accordionStyles.accordion}}
      expanded={expanded}
    >
      <>
        <AccordionSummary
          aria-controls="panel1a-content"
          classes={{
            content: accordionStyles.accordionSummaryContent, 
            expandIcon: accordionStyles.accordionSummaryIcon,           
            root: accordionStyles.accordionSummary,
          }}                
          expandIcon={
            showExpand ?
              <svg height="18px" version="1.1" viewBox="0 0 10 18" width="10px">
                <g fill="none" fillRule="evenodd" id="A-reservations" stroke="none" strokeWidth="1">
                  <g id="A-Reservations" transform="translate(-1390.000000, -176.000000)">
                    <rect fill="transparent" height="900" width="1440" x="0" y="0"></rect>
                    <g fill={config.theme.primary} id="Header" transform="translate(1030.000000, 171.000000)">
                      <g id="icons/arrow-left" transform="translate(360.000000, 5.000000)">
                        <path
                          d="M8.29396103,1.29289322 C8.68448532,0.902368927 9.3176503,0.902368927 9.70817459,1.29289322 C10.0986989,1.68341751 10.0986989,2.31658249 9.70817459,2.70710678 L3.34421356,9.07106781 L9.70817459,15.4350288 C10.0986989,15.8255531 10.0986989,16.4587181 9.70817459,16.8492424 C9.3176503,17.2397667 8.68448532,17.2397667 8.29396103,16.8492424 L1.22289322,9.77817459 C0.832368927,9.3876503 0.832368927,8.75448532 1.22289322,8.36396103 L8.29396103,1.29289322 Z"
                          transform="translate(5.465534, 9.071068) scale(-1, 1) translate(-5.465534, -9.071068) "
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>:
              null
          }    
          onClick={onToggleExpanded}
        >
          <Box className={styles.calendar} height={35} width={'100%'}>
            <Box alignItems="center" display="flex" height={'100%'} justifyContent="between">
              <Box alignItems="center" display="flex" gap={10}>
                <Datetime size={30} /> 
                              
                <Text weight="semi-bold">
                  {roomId? 
                    formattedSelectedDate:
                    <Trans>Select date and time</Trans>
                  }
                </Text>
              </Box>  

              <Box marginRight={10}>
                <Button noPaddingX onClick={handleTodayClick} type="clear">
                  <Trans>Reset</Trans>
                </Button>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <Box marginTop={7}>
          <Divider />
        </Box>
      </>
      <AccordionDetails
        classes={{
          root: accordionStyles.accordionDetails,
        }}
      >
        <Box className={styles.calendar} marginTop={10}>          
          <TabPanel index={0} value={0}>
            <DailyTimeBooking />
          </TabPanel>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}