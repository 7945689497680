import Box from 'Components/Box';
import { useTypedSelector } from 'Store/Redux/store';
import { setCreateNewMeetingData } from 'App/Store/Meetings/createNewMeetingDuck';
import { useDispatch } from 'react-redux';
import Text from 'Components/Text';
import Button from 'Components/Button';
import FloorIcon from 'Components/Icons/Floor';
import LocationIcon from 'Components/Icons/Location';
// import WhiteboardIcon from 'Components/Icons/Whiteboard';
// import TVIcon from 'Components/Icons/TV';
// import PhoneIcon from 'Components/Icons/Phone';
import { Trans, t } from '@lingui/macro';
import listViewStyle from './styles.module.scss';
import { Room } from 'App/Store/Meetings/meetingRooms/models';
import { useState } from "react";
import RoomDetails from '../RoomDetails';
// import RoomDetails from '../RoomDetails';

// interface Amenitie {
//   label: string;
//   icon: string;
// }

export default function ListView() {
  const dispatch = useDispatch();
  const {    
    createNewMeeting,    
    room,
  } = useTypedSelector(state => state);

  const [detailsOpened, setDetailsOpened] = useState(false);
  const [detailsRoomSelected, setDetailsRoomSelected] = useState<string>();

  const {
    timeFrom,
    timeTo,
    selectedData,    
  } = createNewMeeting;

  const { location, floor, roomId } = selectedData;  
  const { rooms } = room;   

  // const amenities: Amenitie[] = [
  //   { label: 'Whiteboard', icon: 'whiteboard' },
  //   { label: 'TV', icon: 'tv'},
  //   { label: 'Phone', icon: 'phone'},
  // ];   

  const handleSelectRoomClick = (listRoomId: string | null) => {
    if (roomId === listRoomId) listRoomId = null;

    if (timeFrom && timeTo) 
      dispatch(setCreateNewMeetingData({ 
        selectedData: {
          ...selectedData,
          roomId: listRoomId, 
        }}));    
  };

  const handleViewDetailsRoom = (listRoomId: string) => {
    setDetailsRoomSelected(listRoomId);
    setDetailsOpened(true);
  };

  const getButtonSelectRoomText = (listRoomId: string) => {
    return roomId === listRoomId? t`Deselect room`: t`Select room`;
  };

  const getListViewItemClass = (listRoomId: string) => {
    return roomId === listRoomId? listViewStyle.listViewItemSelected : listViewStyle.listViewItem;
  };

  const getTextBoldClass = (listRoomId: string) => {
    return roomId === listRoomId? listViewStyle.listViewItemTextSelected : listViewStyle.listViewItemText;
  };

  const getTextLightClass = (listRoomId: string) => {
    return roomId === listRoomId? listViewStyle.listViewItemTextSelected: listViewStyle.listViewItemLightText;
  };  

  const getIconColor = (listRoomId: string) => {
    return roomId === listRoomId? '#1E1F7B':'#5E5E5E';
  };

  // const getAmenitieIcon = (icon: string, listRoomId: string) => {
  //   const color = getIconColor(listRoomId);

  //   const amenitieIcon: { [key: string]: JSX.Element} = {
  //     'whiteboard': <WhiteboardIcon color={color} />,
  //     'tv': <TVIcon color={color} />,
  //     'phone': <PhoneIcon color={color} />,
  //   };

  //   return amenitieIcon[icon];
  // };  

  return (    
    <Box direction="column" display="flex" gap={15} height="80vh" overflow='scrollY'>
       <RoomDetails open={detailsOpened} roomId={detailsRoomSelected || ''} setOpen={setDetailsOpened} />

      {rooms?.length > 0 ?
        rooms.map((room: Room, index: number) => (
          <Box className={getListViewItemClass(room.id)} direction="column" display="flex" gap={8} key={index}>
            <Box display="flex" justifyContent='between'>
              <Text className={getTextBoldClass(room.id)} weight='bold'>
                {room.name}
              </Text>
            </Box>
            <Box display="flex" justifyContent='between'>
              <Box alignItems="center" display="flex" gap={5}>
                <FloorIcon color={getIconColor(room.id)} />
                <Text className={getTextLightClass(room.id)}>
                  {floor?.name}
                </Text>
              </Box>
              <Box alignItems="center" display="flex" gap={5}>
                <LocationIcon color={getIconColor(room.id)} />
                <Text className={getTextLightClass(room.id)}>
                  {location?.address}
                </Text>
              </Box>
            </Box>
            {/*<Text className={getTextBoldClass(room.id)} weight='bold'>Amenities</Text>*/}
            {/*<Box display='flex' gap={10}>*/}
            {/*  {amenities.map((amenitie) =>*/}
            {/*    <Box alignItems="center" display="flex" gap={5} key={amenitie.label}>*/}
            {/*      {getAmenitieIcon(amenitie.icon, room.id)}*/}
            {/*      <Text className={getTextLightClass(room.id)}>{amenitie.label}</Text>*/}
            {/*    </Box>,*/}
            {/*  )}*/}
            {/*</Box>*/}
            <Box display='flex' justifyContent='between'>
              <Button noPaddingX onClick={() => handleViewDetailsRoom(room.id)} type="clear">
                <Trans>View details</Trans>
              </Button>
              <Button noPaddingX onClick={() => handleSelectRoomClick(room.id)} type="clear">
                {getButtonSelectRoomText(room.id)}
              </Button>
            </Box>
          </Box>)):null
      }
    </Box>    
  );
}