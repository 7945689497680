import { GuardFunction } from "Components/ProtectedRoute/models";
import { useTypedSelector } from "Store/Redux/store";

const FloorGuard: GuardFunction = () => {
  const { locationId } = useTypedSelector(state => state.createNewVisit);
  const { disableFloorPage } = useTypedSelector(state => state.bookingCovidConfirmation);

  if (!locationId || disableFloorPage) {
    return false;
  }
  
  return true;
};

export default FloorGuard;