import Box from 'Components/Box';
import Button from 'Components/Button';
import CheckboxCustom from '../../../../Components/CheckBoxCustom';
import { FilterIcon } from '../../../Components/Icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import getDisplayedResourceName from '../../../../Functions/resourseNameParsing';
import Menu from '@material-ui/core/Menu';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { getAttributes } from "../../../../Admin/Store/attributes";
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import { deskAttributesCells } from "../../../../Functions/getDeskAttributeIcon";

export default function Filters() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { createNewBooking, adminAttributes: { attributesDesk: resources = [] } } = useTypedSelector(state => state);

  const { locationId } = createNewBooking;
  const resourcesObject: { [key: string]: boolean } = {};

  resources.forEach(resource => {
    resourcesObject[resource.name] = createNewBooking.filters[resource.name];
  });

  const [filters, setFilters] = useState(createNewBooking.filters);
  const [filtersDetectChanges, setFiltersDetectChanges] = useState(filters);

  const selectedResourcesCount = Object.keys(filters).filter(filterName => filters[filterName]).length;

  useEffect(() => {
    if (locationId) {
      dispatch(getAttributes({ locationId }));
    }
  }, []);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

    // Only triggers API request if filters have changed
    if (hasChanges()) {
      filter();
      setFiltersDetectChanges(filters);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  // Compares filters with filtersDetectChanges and returns if there're changes
  const hasChanges = (): boolean => {
    return Object.keys(filters).some(key => filters[key] !== filtersDetectChanges[key]);
  };

  const filter = () => {
    dispatch(setCreateNewBookingData({ filters }));
  };

  const resetFilters = () => {
    const resourcesObject: { [key: string]: boolean } = {};

    resources.forEach(resource => {
      resourcesObject[resource.name] = false;
    });

    setFilters(resourcesObject);
  };

  return (
    <>
      <Button
        aria-controls="filters"
        aria-haspopup="true"
        aria-label={t`filters`}
        name={t`filters`}
        onClick={handleClick}
        size="xsm"
        withShadow
      >
        <Box alignItems="center" display="grid" gap={4} gridTemplateColumns="auto auto auto">
          <FilterIcon margin={"1px 0"} />

          <Text color="white" size="md">
            <Trans>Filters</Trans>
          </Text>

          <span className={styles.count}>
            {selectedResourcesCount}
          </span>
        </Box>
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className={styles.menuContainer}
        classes={{
          paper: styles.menu,
        }}
        elevation={0}
        getContentAnchorEl={null}
        id="customized-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box width={370}>
          <Box alignItems="center" display="flex" justifyContent="between">
            <Box alignItems="center" display="flex">
              <Box
                alignItems="center"
                backgroundColor="#F4F4F4"
                borderRadius={8}
                display="flex"
                justifyContent="center"
                marginEnd={10}
                padding={4}
              >
                <FilterIcon color={'#5E5E5E'} />
              </Box>

              <Text weight="semi-bold">
                <Trans>Filters</Trans>
              </Text>
            </Box>

            <Box alignItems="center" display="grid" gap={10} gridTemplateColumns="auto auto">
              <Button
                aria-label={t`reset`}
                className={styles.buttonBlue}
                name={t`reset`}
                onClick={resetFilters}
                size="xsm"
                type="clear"
              >
                <Trans>Reset</Trans>
              </Button>

              <Button
                aria-label={t`done`}
                className={styles.buttonDone}
                name={t`done`}
                onClick={handleClose}
                withShadow
              >
                <Trans>Done</Trans>
              </Button>
            </Box>
          </Box>

          <Box marginTop={20}>
            <Text size="md" weight="semi-bold">
              <Trans>Amenities</Trans>
            </Text>

            <Box className={styles.filtersFormControlWrapper} display="grid">
              {resources.map((resource, index) => {
                const imagePath = deskAttributesCells.find(icon => icon.name === resource.name);

                return (
                  <Box className={styles.amenitiesCheckbox} dataTestId="amenities-checkbox" key={resource.id + index + filters[resource.name]}>
                    <FormControlLabel
                      control={(
                        <CheckboxCustom
                          checked={filters[resource.name]}
                          classes={{ checked: styles.checked }}
                          name={getDisplayedResourceName(resource.name)}
                          onChange={handleChange}
                        />)}
                      label={
                        <Box alignItems="center" className={`${styles.attributeLabel}`} display="flex">
                          {imagePath && <img alt={imagePath.name} className={styles.attributeImage} src={imagePath.image} />}
                          {getDisplayedResourceName(resource.name)}
                        </Box>
                      }
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Menu>
    </>
  );
}