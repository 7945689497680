import Box from '../../../../Components/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import clsx from 'clsx';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import LocationDeleteModal from '../LocationDeleteModal';
import LocationForm from '../LocationForm';
import OverflowToolTip from '../../../../Components/OverflowTooltip';
import styles from '../styles.module.scss';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Text from '../../../../Components/Text';
import TableSubmenuButton from "../../../Components/Buttons/TableSubmenuButton";
import ToggleLocationModal from 'Admin/Components/Header/LocationDetailsHeader/ToggleLocationModal';
import ToggleOffIcon from 'Components/Icons/TurnOff';
import ToggleOnIcon from 'Components/Icons/TurnOn';
import Tooltip from '@material-ui/core/Tooltip';
import { editLocation } from '../../../../App/Store/Locations/locationsDuck';
import { AdminLocation, AllowedBookingDayType } from '../../../../App/Store/Locations/locationsDuck/models';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';

interface LocationBodyItemProps {
  location: AdminLocation;
}

export default function LocationBodyItem({ location }: LocationBodyItemProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpenLocationForm, setIsOpenLocationForm] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isToggleLocationModalOpen, setIsToggleLocationModalOpen] = useState(false);
  const { config, locations, profile } = useTypedSelector(state => state);
  const { locationTimezones } = locations;
  const { roleAccess } = profile;

  const getReservationSettingsName = (allowedBookingDayType: AllowedBookingDayType) => {
    if (allowedBookingDayType) {
      switch (allowedBookingDayType as AllowedBookingDayType) {
        case 0:
          return t`Same day`;
        case 1:
          return t`1 day in advance`;
        case 2:
          return t`2 days in advance`;
        default:
          return '-';
      }
    }

    return '-';
  };

  const handleToggleLocation = () => {
    if (location.active) {
      setIsToggleLocationModalOpen(true);
    } else {
      onToggleLocation();
    }
  };

  // navigate to location
  const onNavigateToLocation = (event: any, locationId: string) => {
    // navigate only on clicking table row (prevent navigate by close edit/delete modal)
    if (!event.target?.classList?.contains('popup-overlay')) {
      history.push(`/admin/location/${locationId}/floors`);
    }
  };

  // edit from modal
  const onOpenLocationForm = () => {
    setIsOpenLocationForm(o => !o);
  };

  const onCloseLocationForm = () => {
    setIsOpenLocationForm(false);
  };

  {/* temp prevent delete locations due to task https://zira.zstream.io/app/tasks/task/IPG-1859 */}
  // delete modal
  // const onOpenDeleteModal = () => {
  //   setIsOpenDeleteModal(o => !o);
  // };

  const onCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  // Toggle location.active
  const onToggleLocation = () => {
    dispatch(editLocation({
      location: {
        ...location,
        active: !location?.active,
      },
      locationId: location.id,
    }));
  };

  const country = location.country || '-';
  const city = location.city || '-';
  const floors = location.floors || '-';
  const desks = location.desks || '-';
  const locationTimezone = location && location.timezone && locationTimezones.find(t => t.name === location.timezone) || { offset: "UTC", name: "UTC" };
  const reservationSettings = getReservationSettingsName(location.allowedBookingDayType);

  const rowClasses = clsx(styles.tableRow, {
    [styles.disabled]: !location.active,
  });

  return (
    <TableRow
      aria-label={t`Location item`}
      classes={{ root: rowClasses }}
      hover
      key={location.id}
      onClick={(event) => onNavigateToLocation(event, location.id)}
      role="row"
      tabIndex={-1}
    >
      <TableCell classes={{ root: styles.tableCell }}>
        <Box alignItems="center" display="flex">
          {!location.active &&
            <Box alignItems="center" display="flex" marginEnd={8}>
              <Tooltip
                classes={{ tooltip: styles.tooltip }}
                placement="top"
                title={t`Location is turned off`}
              >
                <ToggleOffIcon color={config.theme.greyMedium} size={18} />
              </Tooltip>
            </Box>
          }

          <OverflowToolTip text={location.locationName} />
        </Box>
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={country} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={city} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={location.locationAddress} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={`${locationTimezone.offset} (${locationTimezone.name})`} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={reservationSettings} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={floors} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={desks} />
      </TableCell>

      {/* location editing only for super admin */}
      <TableCell align="right" classes={{ root: styles.tableCell }}>
        {
          roleAccess.superAdmin &&
          <TableSubmenuButton
            iconButtonName={"location-item-options"}
            iconButtonStyle={{ padding: 0, width: '30px', height: '30px' }}
          >
            {
              !isOpenLocationForm && !isOpenDeleteModal ?
                <Box borderStyle="shadow" className="popover-content" dataTestId="location-item-options-popup">
                  <ButtonBase className="popover-item-button" onClick={onOpenLocationForm}>
                    <CreateOutlinedIcon style={{ color: config.theme.primary, fontSize: 20 }} />

                    <Box marginStart={5}>
                      <Text color="blue" size="md">
                        <Trans>Edit Location</Trans>
                      </Text>
                    </Box>
                  </ButtonBase>

                  <ButtonBase className="popover-item-button" onClick={handleToggleLocation}>
                    {location.active ? (
                      <ToggleOffIcon color={config.theme.primary} size={20} />
                    ) : (
                      <ToggleOnIcon color={config.theme.primary} size={20} />
                    )}

                    <Box marginStart={5}>
                      <Text color="blue" size="md">
                        {location.active ? (
                          <Trans>Turn off Location</Trans>
                        ) : (
                          <Trans>Turn on Location</Trans>
                        )}
                      </Text>
                    </Box>
                  </ButtonBase>

                  {/* temp prevent delete locations due to task https://zira.zstream.io/app/tasks/task/IPG-1859 */}
                  {/*<ButtonBaseclassName="popover-item-button" onClick={onOpenDeleteModal}>*/}
                  {/*  <DeleteOutlineIcon style={{ color: config.theme.primary, fontSize: 20 }} />*/}

                  {/*  <Box marginStart={5}>*/}
                  {/*    <Text color="blue" size="md">*/}
                  {/*      <Trans>Delete Location</Trans>*/}
                  {/*    </Text>*/}
                  {/*  </Box>*/}
                  {/*</ButtonBase>*/}
                </Box> : null
            }
          </TableSubmenuButton>
        }

        <LocationForm
          actionState={'edit'}
          location={location}
          onCancelAction={onCloseLocationForm}
          open={isOpenLocationForm}
        />
        <LocationDeleteModal
          location={location}
          onCancelAction={onCloseDeleteModal}
          open={isOpenDeleteModal}
        />
        <ToggleLocationModal
          onClose={() => setIsToggleLocationModalOpen(false)}
          onConfirm={onToggleLocation}
          open={isToggleLocationModalOpen}
        />
      </TableCell>
    </TableRow>
  );
}