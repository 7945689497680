interface Props {
  color?: string;
  size?: number;
}

export default function Parking({
  color = '#5E5E5E',
  size = 16,
}: Props) {
  return (
    <svg height={size} version="1.1" viewBox="0 0 16 16" width={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
        <g id="Dashboard" transform="translate(-556.000000, -408.000000)">
          <g id="Group-3" transform="translate(100.000000, 354.000000)">
            <rect fill="F4F4F4" height="90" id="Rectangle" rx="14" width="770" x="0" y="0" />

            <g fill={color} fillRule="nonzero" id="icons/floor" transform="translate(456.000000, 54.000000)">
              <g id="Combined-Shape" transform="translate(0.000000, 0.000000)">
                <path
                  d="M12,1 C13.6568542,1 15,2.34314575 15,4 L15,4 L15,12 C15,13.6568542 13.6568542,15 12,15 L12,15 L4,15 C2.34314575,15 1,13.6568542 1,12 L1,12 L1,4 C1,2.34314575 2.34314575,1 4,1 L4,1 Z M12,3 L4,3 C3.44771525,3 3,3.44771525 3,4 L3,4 L3,12 C3,12.5522847 3.44771525,13 4,13 L4,13 L12,13 C12.5522847,13 13,12.5522847 13,12 L13,12 L13,4 C13,3.44771525 12.5522847,3 12,3 L12,3 Z M9,4 C10.6568542,4 12,5.34314575 12,7 C12,8.59768088 10.75108,9.90366088 9.17627279,9.99490731 L9,10 L7,10 L7,12 L5,12 L5,4 L9,4 Z M9,6 L7,6 L7,8 L9,8 C9.55228475,8 10,7.55228475 10,7 C10,6.44771525 9.55228475,6 9,6 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
