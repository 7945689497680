import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../Store/Redux/store";
import {setDesks, setFloorMapData} from "../../../Store/floorMapDuck";
import {t} from "@lingui/macro";
import {FloorMapObject} from "../../../Store/floorMapDuck/models";
import styles from "../styles.module.scss";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "../../../../Components/Checkbox";
import { OrderButton } from "components";
import { Typography } from "@mui/material";
import { useMemo } from "react";
import { resolveArrayOrder } from "utils";
import { Order } from "types";

export default function DeskListTableHeader() {
  const dispatch = useDispatch();
  const { adminFloorMap } = useTypedSelector(state => state);
  const { desks, editDeskList } = adminFloorMap;
  const desksArray = Object.keys(desks).map(deskId => desks[deskId]);
  const nameOrder = useMemo(() => resolveArrayOrder(desksArray.map(({ name }) => name)), [JSON.stringify(desksArray.map(({ name }) => name))]);
  const headCells = [
    { id: 'Checkbox', label: '' },
    { id: 'DeskName', label: t`Desk name` },
    { id: 'Section', label: t`Section` },
    { id: 'Status', label: t`Status` },
    { id: 'Owner', label: t`Owner` },
    { id: 'Groups', label: t`Groups` },
    { id: 'Amenities', label: t`Amenities` },
    { id: 'options', label: '' },
  ];

  // Toggle select all checkbox, using the correct logic to select all desks
  const handleSelectAll = (selectAll: boolean) => {
    const newSelectedDesks: {
      [deskId: string]: FloorMapObject;
    } = {};

    desksArray.forEach(desk => {
      if (selectAll) {
        newSelectedDesks[desk.id] = desk;
      } else {
        delete newSelectedDesks[desk.id];
      }
    });

    dispatch(setFloorMapData({
      editDeskList: {
        ...editDeskList,
        selectedDesks: newSelectedDesks,
      },
    }));
  };

  const handleNameOrderChange = (order: Order) => {
    if (desksArray.length) {
      const ordered = order === "asc" ? desksArray.map(({ name }) => name).sort() : desksArray.map(({ name }) => name).sort().reverse();

      dispatch(setDesks(ordered.map((name) => desksArray.find((desk) => desk.name === name)) as FloorMapObject[]));
    }
  };

  const isAllSelected = Object.keys(editDeskList.selectedDesks).length === desksArray.length;

  return (
    <TableHead classes={{ root: styles.tableHead }}>
      <TableRow classes={{ root: styles.tableRow }}>
        {headCells.map((headCell) => {
          if (headCell.id === 'Checkbox') {
            return (
              <TableCell classes={{ root: styles.tableCell }} key={headCell.id} padding="none">
                <Checkbox
                  checked={isAllSelected}
                  classes={{ container: styles.checkbox }}
                  label=""
                  name={t`selectAll`}
                  onChange={handleSelectAll}
                />
              </TableCell>
            );
          }

          if (headCell.id === "DeskName") {
            return (
              <TableCell classes={{ root: styles.tableCell }} key={headCell.id} padding="none">
                <OrderButton onOrderChange={handleNameOrderChange} order={nameOrder}>
                  <Typography color="#5e5e5e" fontSize={13} fontWeight="400">{headCell.label}</Typography>
                </OrderButton>
              </TableCell>
            );  
          }

          return (
            <TableCell classes={{ root: styles.tableCell }} key={headCell.id} padding="none">
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
