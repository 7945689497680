import Api from 'Store/Services/Api';
import { BaseResponse } from 'Store/Models/ReduxModels';
import { CreateGlobalNotificationResponse, UpdateGlobalNotificationResponse } from './models';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  CREATE_AND_SEND_GLOBAL_NOTIFICATION,
  CREATE_GLOBAL_NOTIFICATION,
  CREATE_GLOBAL_NOTIFICATION_FAIL,
  CREATE_GLOBAL_NOTIFICATION_SUCCESS,
  CreateGlobalNotification,
  DELETE_GLOBAL_NOTIFICATION,
  DELETE_GLOBAL_NOTIFICATION_FAIL,
  DELETE_GLOBAL_NOTIFICATION_SUCCESS,
  DeleteGlobalNotification,
  GET_GLOBAL_NOTIFICATIONS,
  GET_GLOBAL_NOTIFICATIONS_FAIL,
  GET_GLOBAL_NOTIFICATIONS_SUCCESS,
  GetGlobalNotifications,
  SEND_GLOBAL_NOTIFICATION,
  SEND_GLOBAL_NOTIFICATION_FAIL,
  SEND_GLOBAL_NOTIFICATION_SUCCESS,
  SendGlobalNotification,
  UPDATE_AND_SEND_GLOBAL_NOTIFICATION,
  UPDATE_GLOBAL_NOTIFICATION,
  UPDATE_GLOBAL_NOTIFICATION_FAIL,
  UPDATE_GLOBAL_NOTIFICATION_SUCCESS,
  UpdateGlobalNotification,
  sendGlobalNotification,
} from "./index";

function* createGlobalNotificationSaga(action: CreateGlobalNotification): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: CREATE_GLOBAL_NOTIFICATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CREATE_GLOBAL_NOTIFICATION_FAIL, payload: e });
  }
}

function* createAndSendGlobalNotificationSaga(action: CreateGlobalNotification): any {
  try {
    const payload: BaseResponse<CreateGlobalNotificationResponse> = yield call(Api, action);

    yield put({ type: CREATE_GLOBAL_NOTIFICATION_SUCCESS, payload });

    const sendAction = sendGlobalNotification({
      notificationId: payload.data.result.data.id,
      data: {
        endDate: payload.data.result.data.endDate ?? '',
        recipientType: payload.data.result.data.recipients,
        startDate: payload.data.result.data.startDate ?? '',
      },
    });

    yield put(sendAction);
  } catch (e) {
    yield put({ type: CREATE_GLOBAL_NOTIFICATION_FAIL, payload: e });
  }
}

function* deleteGlobalNotificationSaga(action: DeleteGlobalNotification): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: DELETE_GLOBAL_NOTIFICATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: DELETE_GLOBAL_NOTIFICATION_FAIL, payload: e });
  }
}

function* getGlobalNotificationsSaga(action: GetGlobalNotifications): any {
  try {
    // We do this to fix the index of page on the API. Front consider the first page as 0, but API consider it as 1.
    const newAction = { ...action };

    if (newAction.payload.request.data) {
      newAction.payload.request.data.page = newAction.payload.request.data.page + 1;
    }

    const payload = yield call(Api, newAction);

    yield put({ type: GET_GLOBAL_NOTIFICATIONS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_GLOBAL_NOTIFICATIONS_FAIL, payload: e });
  }
}

function* sendGlobalNotificationSaga(action: SendGlobalNotification): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: SEND_GLOBAL_NOTIFICATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: SEND_GLOBAL_NOTIFICATION_FAIL, payload: e });
  }
}

function* updateGlobalNotificationSaga(action: UpdateGlobalNotification): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: UPDATE_GLOBAL_NOTIFICATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: UPDATE_GLOBAL_NOTIFICATION_FAIL, payload: e });
  }
}

function* updateAndSendGlobalNotificationSaga(action: UpdateGlobalNotification): any {
  try {
    const payload: BaseResponse<UpdateGlobalNotificationResponse> = yield call(Api, action);

    yield put({ type: UPDATE_GLOBAL_NOTIFICATION_SUCCESS, payload });

    const sendAction = sendGlobalNotification({
      notificationId: payload.data.result.data.id,
      data: {
        endDate: payload.data.result.data.endDate ?? '',
        recipientType: payload.data.result.data.recipients,
        startDate: payload.data.result.data.startDate ?? '',
      },
    });

    yield put(sendAction);
  } catch (e) {
    yield put({ type: UPDATE_GLOBAL_NOTIFICATION_FAIL, payload: e });
  }
}

export default [
  takeLatest(CREATE_AND_SEND_GLOBAL_NOTIFICATION, createAndSendGlobalNotificationSaga),
  takeLatest(CREATE_GLOBAL_NOTIFICATION, createGlobalNotificationSaga),
  takeLatest(DELETE_GLOBAL_NOTIFICATION, deleteGlobalNotificationSaga),
  takeLatest(GET_GLOBAL_NOTIFICATIONS, getGlobalNotificationsSaga),
  takeLatest(SEND_GLOBAL_NOTIFICATION, sendGlobalNotificationSaga),
  takeLatest(UPDATE_GLOBAL_NOTIFICATION, updateGlobalNotificationSaga),
  takeLatest(UPDATE_AND_SEND_GLOBAL_NOTIFICATION, updateAndSendGlobalNotificationSaga),
];
