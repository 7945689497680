import { useRef, useState } from "react";
import L from 'leaflet';
import { FloorMapObject } from 'Admin/Store/floorMapDuck/models';
import { Polygon } from 'react-leaflet';
import DrawingNameLabel from "Admin/Pages/FloorMap/Map/MapDrawing/Components/DrawingNameLabel";

interface Props {
  meeting: FloorMapObject;
  zoomLevel: number;
}

/**
 * Section Drawing
 *
 * This component handles deletion and edition internally.
 * When adding desks, it sends the state to the <Map /> component so it can
 * enable add desk on <CreateDesk />, associating that creation with this section id.
 *
 * React Leaflet Draw handles it's own states, so this component syncs them with internal states.
 */
export default function MeetingObject({ meeting }: Props) {
  const { coordinatesInLatLngBounds } = meeting;
  const ref = useRef<any>(null);
  const arrayOfCoordinatesInLatLng = coordinatesInLatLngBounds.map(coordinate => L.latLng(coordinate.lat, coordinate.lng));
  const [iconMarkupOpacity, setIconMarkupOpacity] = useState(1);

  const detectActiveElements = (e: any) => {
    const classList = e.originalEvent?.toElement?.classList || {};
    const isInnerDesk = Object.values(classList).includes('leaflet-interactive');

    return { isInnerDesk };
  };

  const toggleIconMarkupOpacity = (set: boolean, e: any) => {
    const { isInnerDesk } = detectActiveElements(e);
    return set || isInnerDesk ?
      setIconMarkupOpacity(0) : setIconMarkupOpacity(1);
  };

  return (
    <Polygon
      eventHandlers={{
        mouseover: (e) => {
          toggleIconMarkupOpacity(true, e);
        },
        mouseout:(e: any) => {
          toggleIconMarkupOpacity(false, e);
        },
      }}
      pathOptions={{
        color: meeting.line,
        fillColor: meeting.line,
      }}
      positions={arrayOfCoordinatesInLatLng}
      ref={ref}
    >
      <DrawingNameLabel
        drawing={meeting}
        iconMarkupOpacity={iconMarkupOpacity}
        sectionLabelKey={1} // use 1 by default / it triggered only with section coordinate updates in admin panel
        sectionRef={ref.current}
      />
    </Polygon>
  );
}
