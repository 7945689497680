import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherDesk: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <g transform="matrix(.57142 0 0 .57143 -4.571 -4.571)">
        <path
          d="M19 9h6a4 4 0 0 1 4 4v10H15V13a4 4 0 0 1 4-4z"
          fill="none"
          fillRule="evenodd"
          stroke={props.stroke}
          strokeOpacity="1"
          strokeWidth="2"
        />
        <path
          d="M15 35a4 4 0 0 1 4-4h6a4 4 0 0 1 4 4"
          fill="none"
          fillRule="evenodd"
          stroke={props.stroke}
          strokeLinecap="round"
          strokeOpacity="1"
          strokeWidth="2"
        />
        <rect
          fill="none"
          fillRule="evenodd"
          height="4"
          rx="2"
          stroke={props.stroke}
          strokeOpacity="1"
          strokeWidth="2"
          width="22"
          x="11"
          y="23"
        />
        <path
          d="M21 27h2v4h-2z"
          fill="none"
          fillRule="evenodd"
          stroke={props.stroke}
          strokeOpacity="1"
          strokeWidth="1"
        />
      </g>
    </SvgIcon>
  );
};
