export type Languages = 'en' | 'es' | 'fr' | 'it' | 'pt' | 'ja' | 'de';
export type Themes = 'ogBlue' | 'ipg' | 'green';

export interface Language {
  name: string;
  value: Languages;
}

// show for all non english with addition translate
export const LANGUAGES: Language[] = [
  {
    name: "English",
    value: "en",
  },
  {
    name: "Spanish (Español)",
    value: "es",
  },
  {
    name: "French (Français)",
    value: "fr",
  },
  {
    name: "Italian (Italiano)",
    value: "it",
  },
  {
    name: "Portuguese (Português)",
    value: "pt",
  },
  {
    name: "Japanese (日本語)",
    value: "ja",
  },
  {
    name: "German (Deutsch)",
    value: "de",
  },
];
