import Alert, { Color } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { createContext, useState } from 'react';

export const defaultInterval = 250;

interface SnackbarContextProps {
  closeSnackbar: () => any;
  openSnackbar: (props: SnackbarProps) => any;
}

export interface SnackbarProps {
  autoHideDuration?: number;
  onClose?: () => any;
  text: string;
  type: Color;
}

// Context used by the hook useSnackbar()
export const SnackbarContext = createContext<SnackbarContextProps>({ closeSnackbar: () => null, openSnackbar: () => null });

export default function SnackbarProvider({ children }: React.PropsWithChildren<any>) {
  // Current open state
  const [autoHideDuration, setAutoHideDuration] = useState(6000);
  const [onClose, setOnClose] = useState<() => any>(() => null);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [type, setType] = useState<Color>('success');

  const triggerSnackbar = ({ autoHideDuration, onClose, text, type }: SnackbarProps) => {
    if (autoHideDuration) {
      setAutoHideDuration(autoHideDuration);
    }

    if (onClose) {
      setOnClose(onClose);
    }

    setText(text);
    setType(type);
    setOpen(true);
  };

  // Manages all the snackbar's opening process
  const openSnackbar = (props: SnackbarProps) => {
    // Closes the snackbar if it is already open
    if (open) {
      setOpen(false);

      setTimeout(() => {
        triggerSnackbar(props);
      }, defaultInterval);
    } else {
      triggerSnackbar(props);
    }
  };

  const closeSnackbar = () => {
    setOpen(false);

    if (onClose) {
      onClose();
    }
  };

  // Returns the Provider that must wrap the application
  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}

      {/* Renders Snackbar on the end of the page */}
      {open ? (
        <Snackbar autoHideDuration={autoHideDuration} onClose={closeSnackbar} open>
          <Alert onClose={closeSnackbar} severity={type}>
            {text}
          </Alert>
        </Snackbar>
      ) : null}
    </SnackbarContext.Provider>
  );
}