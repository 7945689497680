import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../Store/Redux/store";
import {useHistory} from "react-router";
import {useMsal} from "@azure/msal-react";
import {msalConfig} from "../../../../Store/Services/AuthConfig";
import styles from "./styles.module.scss";
import {Trans, t} from "@lingui/macro";

import Box from "../../../../Components/Box";
import Select from "../../../../Components/Select";
import {setLanguage} from "../../../../Store/Redux/Ducks/configDuck";
import {logout} from "../../../Store/Auth/loginDuck";
import { isSupportLinkShow } from "../../../Store/Users/profileDuck";
import { LANGUAGES } from "../../../../Store/Redux/Ducks/configDuck/models";
import SupportLinks from "../SupportLinks";

export default function ProfileUserInfo() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { config, profile } = useTypedSelector(state => state);
  const { instance } = useMsal();

  const activeAccount = instance.getActiveAccount();
  const accounts = instance.getAllAccounts();
  const account = activeAccount || accounts[0];
  const email = profile.email || '';
  const phone = profile.mobilePhone || '';
  const showSupportLinks = isSupportLinkShow(profile.email);

  const languages = LANGUAGES;
  const handleChangeLanguage = (event: React.ChangeEvent<{ name?: string | undefined; value: any; }>) => {
    const newLanguage = [...languages].find(language => language.value === event.target.value);
    if (newLanguage) {
      dispatch(setLanguage(newLanguage));
    }
  };

  const onLogOut = () => {
    instance.logoutPopup({
      account: account,
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
    }).then(() => {
      dispatch(logout()); // also clear token data
      history.replace('/', null);
    });
  };

  return (
    <div className={styles.profileUserInfo}>
      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={10}>
        <h3>
          <Trans>
            Profile details
          </Trans>
        </h3>

        <div className={styles.profileTooltipLink} onClick={onLogOut}>
          <Trans>
            Log Out
          </Trans>
        </div>
      </Box>

      <div className={styles.profileTooltipBlock}>
        <div className={styles.profileTooltipTitle}>
          <Trans>
            Email
          </Trans>
        </div>

        <input className={styles.profileTooltipEmail} disabled type="text" value={email} />
      </div>

      <div className={styles.profileTooltipBlock}>
        <div className={styles.profileTooltipTitle}>
          <Trans>
            Cell number
          </Trans>
        </div>

        <input className={styles.profileTooltipPhone} disabled type="text" value={phone} />
      </div>

      <div className={styles.profileTooltipBlock}>
        <div className={styles.profileTooltipTitle}>
          <Trans>
            Language
          </Trans>
        </div>

        <Select
          aria-label={t`language`}
          classes={{
            materialSelect: styles.selectContainer,
            selectWrapper: styles.selectWrapper,
          }}
          id="language"
          items={languages}
          name={t`language`}
          onChange={handleChangeLanguage}
          value={config.language.value}
        />
      </div>
      { showSupportLinks && <SupportLinks /> }
    </div>
  );
}
