export const resolvePersistedDate = (persistedDate: Date | string | undefined): Date | undefined => {
  if (!persistedDate) {
    return undefined;
  }

  if (persistedDate instanceof Date) {
    return persistedDate;
  }

  return new Date(persistedDate);
};
