import {
  DeleteFloorFakeResponse,
  DeleteLocationResponse,
  EditLocationResponse,
  GetFloorsAdminResponse,
  GetFloorsResponse,
  GetLocationsResponse,
} from './models';

export const deleteFloorSuccess: DeleteFloorFakeResponse = {
  floorId: 'c6f267f9-82b1-46b9-b8a7-40ada908bc0b',
};

export const deleteLocationSuccess: DeleteLocationResponse = {
  result: {
    statusCode: 200,
    data: 'f66e1b67-c90e-42fd-8271-7f23a0bb460f',
  },
  error: null,
};

export const editLocationSuccess: EditLocationResponse = {
  result: {
    statusCode: 200,
    data: {
      id: '57d5547c-8119-4956-9098-946715c6893a',
      locationName: 'M_Location',
      locationAddress: 'Qabanbay Batyr Avenue, Nur-Sultan, Kazakhstan',
      businessHours: '00:00:00 - 24:00:00',
      country: 'Kazakhstan',
      city: 'Nur-Sultan',
      region: 'Asia',
      desks: 43,
      floors: 8,
      createdAt: '2021-07-09T10:15:39.230Z',
      timezone: 'Asia/Dhaka',
      qrCodeUrl: null,
      active: true,
      uploadDocNotification: false,
      allowedBookingDayType: 1,
      defaultWeekRecurrence: 2,
      customReservationDayLimit: 365,
    },
  },
  error: null,
};

export const getLocationsSuccess: GetLocationsResponse = {
  result: {
    statusCode: 200,
    data: [
      {
        id: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
        locationName: 'Aberdin',
        locationAddress: '100 W 33rd St, NY 10001',
        businessHours: '00:00:00 - 24:00:00',
        city: 'NY',
        country: 'USA',
        region: 'NA',
        floors: 3,
        desks: 6,
        createdAt: '2021-05-24T11:28:12.739Z',
        timezone: 'America/New_York',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: '50cdea27-9a3e-481b-83d2-aff3341d2a3a',
        locationName: 'Leeds',
        locationAddress: '100 Wellington St, Leeds, LS1 4LT',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Leeds',
        country: 'UK',
        region: 'Europe',
        floors: 1,
        desks: 11,
        createdAt: '2021-05-21T11:28:12.739Z',
        timezone: 'America/New_York',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: '87993500-1629-44f9-8797-1b9861c91a36',
        locationName: 'Montevideo',
        locationAddress: 'Tiburcio Gómez 1330, Montevideo',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Montevideo',
        country: 'Uruguay',
        region: 'Europe',
        floors: 0,
        desks: 0,
        createdAt: '2021-05-22T11:28:12.739Z',
        timezone: 'America/Goose_Bay',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: 'f66e1b67-c90e-42fd-8271-7f23a0bb460f',
        locationName: 'main office',
        locationAddress: 'boulevard 13',
        businessHours: '00:00:00 - 24:00:00',
        city: 'paris',
        country: 'france',
        region: 'europe',
        floors: 0,
        desks: 0,
        createdAt: '2021-05-26T11:28:12.739Z',
        timezone: 'Europe/London',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: '727a870d-b768-4200-bcc5-77fae94333e2',
        locationName: 'EdTownChange',
        locationAddress: '1234 East Main Street, Independence, United States',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Independence',
        country: 'United States',
        region: 'North America',
        floors: 1,
        desks: 5,
        createdAt: '2021-06-14T18:54:01.287Z',
        timezone: 'UTC',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: 'c46f6a33-1385-4266-8519-c8a6fe11c313',
        locationName: 'Birmingham',
        locationAddress: '205 Hamilton Row, Birmingham, United States',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Birmingham',
        country: 'United States',
        region: 'North America',
        floors: 0,
        desks: 0,
        createdAt: '2021-06-17T18:49:46.596Z',
        timezone: 'UTC',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: '0cbc6af1-27f4-4c9b-8dc6-a856759666ba',
        locationName: 'locEd',
        locationAddress: '123 West 9th Street, Coffeyville, United States',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Coffeyville',
        country: 'United States',
        region: 'North America',
        floors: 5,
        desks: 34,
        createdAt: '2021-07-01T14:18:52.164Z',
        timezone: 'Asia/Gaza',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: 'ff1326cb-eb24-46ea-ba64-89837ede4012',
        locationName: 'CheckAttributes',
        locationAddress: '4455 East Mustard Way, Springfield, United States',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Springfield',
        country: 'United States',
        region: 'North America',
        floors: 1,
        desks: 6,
        createdAt: '2021-07-06T18:36:03.901Z',
        timezone: 'UTC',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: '5a5407b3-15d5-45cb-83d1-4ec2004a8493',
        locationName: 'Toronto',
        locationAddress: '10 Bay Street, Toronto, Canada',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Toronto',
        country: 'Canada',
        region: 'North America',
        floors: 1,
        desks: 24,
        createdAt: '2021-07-07T16:42:58.272Z',
        timezone: null,
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
    ],
  },
  error: null,
};

// locations without floors & desks
export const getUserLocationsSuccess: GetLocationsResponse = {
  result: {
    statusCode: 200,
    data: [
      {
        id: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
        locationName: 'Aberdin',
        locationAddress: '100 W 33rd St, NY 10001',
        businessHours: '00:00:00 - 24:00:00',
        city: 'NY',
        country: 'USA',
        region: 'NA',
        createdAt: '2021-05-24T11:28:12.739Z',
        timezone: 'America/New_York',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: '50cdea27-9a3e-481b-83d2-aff3341d2a3a',
        locationName: 'Leeds',
        locationAddress: '100 Wellington St, Leeds, LS1 4LT',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Leeds',
        country: 'UK',
        region: 'Europe',
        createdAt: '2021-05-21T11:28:12.739Z',
        timezone: 'America/New_York',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: '87993500-1629-44f9-8797-1b9861c91a36',
        locationName: 'Montevideo',
        locationAddress: 'Tiburcio Gómez 1330, Montevideo',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Montevideo',
        country: 'Uruguay',
        region: 'Europe',
        createdAt: '2021-05-22T11:28:12.739Z',
        timezone: 'America/Goose_Bay',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: 'f66e1b67-c90e-42fd-8271-7f23a0bb460f',
        locationName: 'main office',
        locationAddress: 'boulevard 13',
        businessHours: '00:00:00 - 24:00:00',
        city: 'paris',
        country: 'france',
        region: 'europe',
        createdAt: '2021-05-26T11:28:12.739Z',
        timezone: 'Europe/London',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: '727a870d-b768-4200-bcc5-77fae94333e2',
        locationName: 'EdTownChange',
        locationAddress: '1234 East Main Street, Independence, United States',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Independence',
        country: 'United States',
        region: 'North America',
        createdAt: '2021-06-14T18:54:01.287Z',
        timezone: 'UTC',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: 'c46f6a33-1385-4266-8519-c8a6fe11c313',
        locationName: 'Birmingham',
        locationAddress: '205 Hamilton Row, Birmingham, United States',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Birmingham',
        country: 'United States',
        region: 'North America',
        createdAt: '2021-06-17T18:49:46.596Z',
        timezone: 'UTC',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: '0cbc6af1-27f4-4c9b-8dc6-a856759666ba',
        locationName: 'locEd',
        locationAddress: '123 West 9th Street, Coffeyville, United States',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Coffeyville',
        country: 'United States',
        region: 'North America',
        createdAt: '2021-07-01T14:18:52.164Z',
        timezone: 'Asia/Gaza',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: 'ff1326cb-eb24-46ea-ba64-89837ede4012',
        locationName: 'CheckAttributes',
        locationAddress: '4455 East Mustard Way, Springfield, United States',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Springfield',
        country: 'United States',
        region: 'North America',
        createdAt: '2021-07-06T18:36:03.901Z',
        timezone: 'UTC',
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
      {
        id: '5a5407b3-15d5-45cb-83d1-4ec2004a8493',
        locationName: 'Toronto',
        locationAddress: '10 Bay Street, Toronto, Canada',
        businessHours: '00:00:00 - 24:00:00',
        city: 'Toronto',
        country: 'Canada',
        region: 'North America',
        createdAt: '2021-07-07T16:42:58.272Z',
        timezone: null,
        qrCodeUrl: null,
        active: true,
        uploadDocNotification: false,
        allowedBookingDayType: 1,
        defaultWeekRecurrence: 2,
        customReservationDayLimit: 365,
      },
    ],
  },
  error: null,
};

export const getFloorsAdminSuccess: GetFloorsAdminResponse = {
  result: {
    statusCode: 200,
    data: [
      {
        id: 'f640dac7-2979-4f48-8648-436a6978a9ba',
        floorName: '2nd Floor - Media Experts',
        availableDesks: 15,
        assignedDesks: 0,
        totalDesks: 15,
        active: true,
      },
      {
        id: 'c41076ed-6ac2-4714-96b0-77c0e4d1bef1',
        floorName: 'PNG',
        availableDesks: 11,
        assignedDesks: 0,
        totalDesks: 12,
        active: true,
      },
      {
        id: '0d3bac16-088f-4afe-aca2-ab03ab1c13ec',
        floorName: 'My Floor 4',
        availableDesks: 15,
        assignedDesks: 0,
        totalDesks: 15,
        active: true,
      },
      {
        id: 'd0d37cdf-7be2-4a55-9643-6305bde441ee',
        floorName: 'My Floor 3',
        availableDesks: 37,
        assignedDesks: 2,
        totalDesks: 41,
        active: true,
      },
      {
        id: 'ade79f88-b7cd-4e9b-8961-f304dce8f334',
        floorName: 'My Floor 5',
        availableDesks: 4,
        assignedDesks: 0,
        totalDesks: 4,
        active: true,
      },
    ],
  },
  error: null,
};

export const getFloorsSuccess: GetFloorsResponse = {
  result: {
    data: [
      {
        id: 'f640dac7-2979-4f48-8648-436a6978a9ba',
        floorName: '2nd Floor - Media Experts',
        sitDesks: 10,
      },
      {
        id: 'c41076ed-6ac2-4714-96b0-77c0e4d1bef1',
        floorName: 'PNG',
        sitDesks: 5,
      },
      {
        id: '0d3bac16-088f-4afe-aca2-ab03ab1c13ec',
        floorName: 'My Floor 4',
        sitDesks: 2,
      },
    ],
    statusCode: 200,
  },
  error: null,
};