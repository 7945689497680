import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {useTypedSelector} from "../../../../../Store/Redux/store";
import {Trans, t} from "@lingui/macro";
import {selectLocationResources} from "../../../../../App/Store/Resources/locationsResourcesDuck";
import Button from "../../../../../Components/Button";
import Box from "../../../../../Components/Box";
import {FilterIcon} from "../../../../../App/Components/Icons";
import Text from "../../../../../Components/Text";
import styles from "../../../../../App/Pages/CreateNewBooking/Filters/styles.module.scss";
import Menu from "@material-ui/core/Menu";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckboxCustom from "../../../../../Components/CheckBoxCustom";
import getDisplayedResourceName from "../../../../../Functions/resourseNameParsing";
import {setJustInTimeReservationData} from "../../../../Store/reservationJustInTime";
import { deskAttributesCells } from "../../../../../Functions/getDeskAttributeIcon";
import { getAttributes } from "Admin/Store/attributes";

export default function ReservationJustInTimeFilters() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { adminReservationJustInTime, locationsResources } = useTypedSelector(state => state);

  const locationId = adminReservationJustInTime.selectedData.location?.id;
  const disableFilters = !adminReservationJustInTime.selectedData.floor?.id;
  const disableFilterClass = disableFilters ? styles.disableClass : '';

  const resources = locationId ? selectLocationResources({ locationId, state: locationsResources }) : [];
  const resourcesObject: { [key: string]: boolean } = {};

  resources.forEach(resource => {
    resourcesObject[resource.name] = adminReservationJustInTime.filters[resource.name];
  });

  const [filters, setFilters] = useState(adminReservationJustInTime.filters);
  const selectedResourcesCount = Object.keys(filters).filter(filterName => filters[filterName]).length;

  useEffect(() => {
    if (locationId) {
      dispatch(getAttributes({ locationId }));
    }
  }, [locationId]);

  useEffect(() => {
    if (disableFilters) {
      const resourcesObject: { [key: string]: boolean } = {};

      resources.forEach(resource => {
        resourcesObject[resource.name] = false;
      });

      setFilters(resourcesObject);
      dispatch(setJustInTimeReservationData({ filters: resourcesObject }));
    }
  }, [disableFilters]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

    filter();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, [event.target.name]: event.target.checked });
  };

  const filter = () => {
    dispatch(setJustInTimeReservationData({ filters }));
  };

  const resetFilters = () => {
    const resourcesObject: { [key: string]: boolean } = {};

    resources.forEach(resource => {
      resourcesObject[resource.name] = false;
    });

    setFilters(resourcesObject);
  };

  return (
    <>
      <Button
        aria-controls="filters"
        aria-haspopup="true"
        aria-label={t`filters`}
        className={disableFilterClass}
        disabled={disableFilters}
        name={t`filters`}
        onClick={handleClick}
        size="xsm"
        withShadow
      >
        <Box alignItems="center" display="grid" gap={4} gridTemplateColumns="auto auto auto">
          <FilterIcon margin={"1px 0"} />

          <Text color="white" size="md">
            <Trans>Filters</Trans>
          </Text>

          <span className={styles.count}>
            {selectedResourcesCount}
          </span>
        </Box>
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className={styles.menuContainer}
        classes={{
          paper: styles.menu,
        }}
        elevation={0}
        getContentAnchorEl={null}
        id="customized-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box width={370}>
          <Box alignItems="center" display="flex" justifyContent="between">
            <Box alignItems="center" display="flex">
              <Box
                alignItems="center"
                backgroundColor="#F4F4F4"
                borderRadius={8}
                display="flex"
                justifyContent="center"
                marginEnd={10}
                padding={4}
              >
                <FilterIcon color={'#5E5E5E'} />
              </Box>

              <Text weight="semi-bold">
                <Trans>Filters</Trans>
              </Text>
            </Box>

            <Box alignItems="center" display="grid" gap={10} gridTemplateColumns="auto auto">
              <Button
                aria-label={t`reset`}
                className={styles.buttonBlue}
                name={t`reset`}
                onClick={resetFilters}
                size="xsm"
                type="clear"
              >
                <Trans>Reset</Trans>
              </Button>

              <Button
                aria-label={t`done`}
                className={styles.buttonDone}
                name={t`done`}
                onClick={handleClose}
                withShadow
              >
                <Trans>Done</Trans>
              </Button>
            </Box>
          </Box>

          <Box marginTop={20}>
            <Text size="md" weight="semi-bold">
              <Trans>Amenities</Trans>
            </Text>

            <Box className={styles.filtersFormControlWrapper} display="grid">
              {resources.map((resource, index) => {
                const imagePath = deskAttributesCells.find(icon => icon.name === resource.name);

                return (
                  <Box className={styles.amenitiesCheckbox} dataTestId="amenities-checkbox" key={resource.id + index + filters[resource.name]}>
                    <FormControlLabel
                      control={(
                        <CheckboxCustom
                          checked={filters[resource.name]}
                          classes={{ checked: styles.checked }}
                          name={getDisplayedResourceName(resource.name)}
                          onChange={handleChange}
                        />)}
                      label={
                        <Box alignItems="center" className={`${styles.attributeLabel}`} display="flex">
                          {imagePath && <img alt={imagePath.name} className={styles.attributeImage} src={imagePath.image} />}
                          {getDisplayedResourceName(resource.name)}
                        </Box>
                      }
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Menu>
    </>
  );
}