import { Button, ButtonProps } from "@mui/material";
import React from "react";

export const LinkButton: React.FC<ButtonProps> = (props) => {
  const { children } = props;

  return (
    <Button
      disableRipple
      sx={{ padding: 0, minWidth: 0, ":hover": { bgcolor: "transparent" }, ...props.sx }}
      {...props}
    >
      {children}
    </Button>
  );
};
