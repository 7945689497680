import { ActionPayload, BaseErrorResponse, BaseResponse } from "../../../Store/Models/ReduxModels";
import {
  AddAdminFloorRoomRequest,
  AddAdminFloorRoomRequestData,
  AddAdminFloorRoomResponse,
  AdminRoomStatusEnum,
  DeleteAdminFloorRoomRequest,
  DeleteAdminFloorRoomResponse,
  EditAdminFloorRoomData,
  EditAdminFloorRoomRequest,
  EditAdminFloorRoomResponse,
  GetAdminFloorRoomsRequest,
  GetAdminFloorRoomsRequestData,
  GetAdminFloorRoomsResponse, GetAvailableAdminFloorRoomsRequest,
  GetAvailableAdminFloorRoomsResponse,
  GetAvailableAdminRoomsRequest,
  GetAvailableAdminRoomsResponse,
  IAdminRoom, IAvailableRoom,
  IFiltersAdminFloorRooms,
  SetAdminFloorRoomsDataRequest,
} from "./models";
import { t } from "@lingui/macro";

export const SET_ADMIN_FLOOR_ROOMS_DATA = 'SET_ADMIN_FLOOR_ROOMS_DATA';

export const GET_ADMIN_FLOOR_ROOMS = 'GET_ADMIN_FLOOR_ROOMS';
export const GET_ADMIN_FLOOR_ROOMS_SUCCESS = 'GET_ADMIN_FLOOR_ROOMS_SUCCESS';
export const GET_ADMIN_FLOOR_ROOMS_FAIL = 'GET_ADMIN_FLOOR_ROOMS_FAIL';

export const GET_AVAILABLE_ADMIN_FLOOR_ROOMS = 'GET_AVAILABLE_ADMIN_FLOOR_ROOMS';
export const GET_AVAILABLE_ADMIN_FLOOR_ROOMS_SUCCESS = 'GET_AVAILABLE_ADMIN_FLOOR_ROOMS_SUCCESS';
export const GET_AVAILABLE_ADMIN_FLOOR_ROOMS_FAIL = 'GET_AVAILABLE_ADMIN_FLOOR_ROOMS_FAIL';

export const GET_AVAILABLE_ADMIN_ROOMS = 'GET_AVAILABLE_ADMIN_ROOMS';
export const GET_AVAILABLE_ADMIN_ROOMS_SUCCESS = 'GET_AVAILABLE_ADMIN_ROOMS_SUCCESS';
export const GET_AVAILABLE_ADMIN_ROOMS_FAIL = 'GET_AVAILABLE_ADMIN_ROOMS_FAIL';

export const ADD_ADMIN_FLOOR_ROOM = 'ADD_ADMIN_FLOOR_ROOM';
export const ADD_ADMIN_FLOOR_ROOM_SUCCESS = 'ADD_ADMIN_FLOOR_ROOM_SUCCESS';
export const ADD_ADMIN_FLOOR_ROOM_FAIL = 'ADD_ADMIN_FLOOR_ROOM_FAIL';

export const EDIT_ADMIN_FLOOR_ROOM = 'EDIT_ADMIN_FLOOR_ROOM';
export const EDIT_ADMIN_FLOOR_ROOM_SUCCESS = 'EDIT_ADMIN_FLOOR_ROOM_SUCCESS';
export const EDIT_ADMIN_FLOOR_ROOM_FAIL = 'EDIT_ADMIN_FLOOR_ROOM_FAIL';

export const DELETE_ADMIN_FLOOR_ROOM = 'DELETE_ADMIN_FLOOR_ROOM';
export const DELETE_ADMIN_FLOOR_ROOM_SUCCESS = 'DELETE_ADMIN_FLOOR_ROOM_SUCCESS';
export const DELETE_ADMIN_FLOOR_ROOM_FAIL = 'DELETE_ADMIN_FLOOR_ROOM_FAIL';

// set any data
export interface SetAdminFloorRoomsData {
  type: typeof SET_ADMIN_FLOOR_ROOMS_DATA;
  payload: SetAdminFloorRoomsDataRequest;
}

// get list of all rooms of floor
export interface GetAdminFloorRooms {
  type: typeof GET_ADMIN_FLOOR_ROOMS;
  payload: ActionPayload<GetAdminFloorRoomsRequestData>
}

export interface GetAdminFloorRoomsSuccess {
  type: typeof GET_ADMIN_FLOOR_ROOMS_SUCCESS;
  payload: BaseResponse<GetAdminFloorRoomsResponse>
}

export interface GetAdminFloorRoomsFail {
  type: typeof GET_ADMIN_FLOOR_ROOMS_FAIL;
  payload: BaseErrorResponse;
}

// get list of all available rooms
export interface GetAvailableAdminRooms {
  type: typeof GET_AVAILABLE_ADMIN_ROOMS;
  payload: ActionPayload<GetAvailableAdminRoomsRequest>
}

export interface GetAvailableAdminRoomsSuccess {
  type: typeof GET_AVAILABLE_ADMIN_ROOMS_SUCCESS;
  payload: BaseResponse<GetAvailableAdminRoomsResponse>
}

export interface GetAvailableAdminRoomsFail {
  type: typeof GET_AVAILABLE_ADMIN_ROOMS_FAIL;
  payload: BaseErrorResponse;
}

// get list of all available rooms to add for some floor
export interface GetAvailableAdminFloorRooms {
  type: typeof GET_AVAILABLE_ADMIN_FLOOR_ROOMS;
  payload: ActionPayload<GetAvailableAdminFloorRoomsRequest>
}

export interface GetAvailableAdminFloorRoomsSuccess {
  type: typeof GET_AVAILABLE_ADMIN_FLOOR_ROOMS_SUCCESS;
  payload: BaseResponse<GetAvailableAdminFloorRoomsResponse>
}

export interface GetAvailableAdminFloorRoomsFail {
  type: typeof GET_AVAILABLE_ADMIN_FLOOR_ROOMS_FAIL;
  payload: BaseErrorResponse;
}

// add a new room to floor
export interface AddAdminFloorRoom {
  type: typeof ADD_ADMIN_FLOOR_ROOM;
  payload: ActionPayload<AddAdminFloorRoomRequestData>
}

export interface AddAdminFloorRoomSuccess {
  type: typeof ADD_ADMIN_FLOOR_ROOM_SUCCESS;
  payload: BaseResponse<AddAdminFloorRoomResponse>
}

export interface AddAdminFloorRoomFail {
  type: typeof ADD_ADMIN_FLOOR_ROOM_FAIL;
  payload: BaseErrorResponse;
}

// edit room from floor
export interface EditAdminFloorRoom {
  type: typeof EDIT_ADMIN_FLOOR_ROOM;
  payload: ActionPayload<EditAdminFloorRoomData>
}

export interface EditAdminFloorRoomSuccess {
  type: typeof EDIT_ADMIN_FLOOR_ROOM_SUCCESS;
  payload: BaseResponse<EditAdminFloorRoomResponse>
}

export interface EditAdminFloorRoomFail {
  type: typeof EDIT_ADMIN_FLOOR_ROOM_FAIL;
  payload: BaseErrorResponse;
}

// delete room from floor
export interface DeleteAdminFloorRoom {
  type: typeof DELETE_ADMIN_FLOOR_ROOM;
  payload: ActionPayload<DeleteAdminFloorRoomRequest>
}

export interface DeleteAdminFloorRoomSuccess {
  type: typeof DELETE_ADMIN_FLOOR_ROOM_SUCCESS;
  payload: BaseResponse<DeleteAdminFloorRoomResponse>;
}

export interface DeleteAdminFloorRoomFail {
  type: typeof DELETE_ADMIN_FLOOR_ROOM_FAIL;
  payload: BaseErrorResponse;
}

export type Actions =
  | SetAdminFloorRoomsData
  | GetAdminFloorRooms
  | GetAdminFloorRoomsSuccess
  | GetAdminFloorRoomsFail
  | GetAvailableAdminFloorRooms
  | GetAvailableAdminFloorRoomsSuccess
  | GetAvailableAdminFloorRoomsFail
  | GetAvailableAdminRooms
  | GetAvailableAdminRoomsSuccess
  | GetAvailableAdminRoomsFail
  | AddAdminFloorRoom
  | AddAdminFloorRoomSuccess
  | AddAdminFloorRoomFail
  | EditAdminFloorRoom
  | EditAdminFloorRoomSuccess
  | EditAdminFloorRoomFail
  | DeleteAdminFloorRoom
  | DeleteAdminFloorRoomSuccess
  | DeleteAdminFloorRoomFail

export interface State {
  error: string;
  successMessage: string;
  loading: boolean;
  loadingAvailableFloorRooms: boolean;
  totalCount: number;
  limit: number;
  allRooms: IAdminRoom[];
  floorRooms: IAdminRoom[];
  availableFloorRooms: IAvailableRoom[]; // use as available rooms to add
  filters: IFiltersAdminFloorRooms;
}

export const roomStatusList = [
  { value: AdminRoomStatusEnum.Available, name: 'Available' },
  { value: AdminRoomStatusEnum.Unavailable, name: 'Unavailable' },
];
export const initFiltersData: IFiltersAdminFloorRooms = {
  search: '',
};
const initialState: State = {
  error: '',
  successMessage: '',
  loading: false,
  loadingAvailableFloorRooms: false,
  totalCount: 0,
  limit: 10,
  allRooms: [],
  floorRooms: [],
  availableFloorRooms: [],
  filters: initFiltersData,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case SET_ADMIN_FLOOR_ROOMS_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case GET_ADMIN_FLOOR_ROOMS: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }
    case GET_ADMIN_FLOOR_ROOMS_FAIL: {
      return {
        ...state,
        error: `There was an error getting floor rooms. ${action.payload?.error?.message}. Please try again.`,
        loading: false,
      };
    }
    case GET_ADMIN_FLOOR_ROOMS_SUCCESS: {
      const { items, meta } = action.payload.data.result.data;

      return {
        ...state,
        loading: false,
        floorRooms: items,
        totalCount: meta.total,
      };
    }

    case GET_AVAILABLE_ADMIN_ROOMS: {
      return {
        ...state,
        error: '',
      };
    }
    case GET_AVAILABLE_ADMIN_ROOMS_FAIL: {
      return {
        ...state,
        error: `There was an error getting rooms. ${action.payload?.error?.message}. Please try again.`,
      };
    }
    case GET_AVAILABLE_ADMIN_ROOMS_SUCCESS: {
      const { items } = action.payload.data.result.data;

      return {
        ...state,
        allRooms: items,
      };
    }

    case GET_AVAILABLE_ADMIN_FLOOR_ROOMS: {
      return {
        ...state,
        loadingAvailableFloorRooms: true,
      };
    }
    case GET_AVAILABLE_ADMIN_FLOOR_ROOMS_FAIL: {
      return {
        ...state,
        loadingAvailableFloorRooms: false,
        error: `There was an error getting available rooms. ${action.payload?.error?.message}. Please try again.`,
      };
    }
    case GET_AVAILABLE_ADMIN_FLOOR_ROOMS_SUCCESS: {
      return {
        ...state,
        loadingAvailableFloorRooms: false,
        availableFloorRooms: action.payload.data.result.data.items,
      };
    }

    case ADD_ADMIN_FLOOR_ROOM: {
      return {
        ...state,
        error: '',
      };
    }
    case ADD_ADMIN_FLOOR_ROOM_FAIL: {
      return {
        ...state,
        error: `There was an error adding room. ${action.payload?.error?.message}. Please try again.`,
      };
    }
    case ADD_ADMIN_FLOOR_ROOM_SUCCESS: {
      const newFloorRoom = action.payload.data.result.data;
      const newFloorRooms = [newFloorRoom, ...state.floorRooms];

      return {
        ...state,
        error: '',
        successMessage: 'Room was added.',
        floorRooms: newFloorRooms,
      };
    }

    case EDIT_ADMIN_FLOOR_ROOM_SUCCESS: {
      const room = action.payload.data.result.data;
      const newFloorRooms = [...state.floorRooms];
      const index = newFloorRooms.findIndex(u => u.id === room.id);
      newFloorRooms[index] = room;

      return {
        ...state,
        successMessage: `Room was updated.`,
        floorRooms: newFloorRooms,
      };
    }
    case EDIT_ADMIN_FLOOR_ROOM_FAIL:
      return {
        ...state,
        error: `There was an error updating room. ${action.payload?.error?.message}. Please try again.`,
      };

    case DELETE_ADMIN_FLOOR_ROOM_SUCCESS: {
      const { id } = action.payload.data.result.data;
      const newRoomsList = [...state.floorRooms].filter(q => q.id !== id);

      return {
        ...state,
        successMessage: 'Room was deleted',
        floorRooms: newRoomsList,
      };
    }
    case DELETE_ADMIN_FLOOR_ROOM_FAIL:
      return {
        ...state,
        error: t`There was an error deleting room.`,
      };

    default:
      return state;
  }
}

// actions
export function setAdminFloorRoomsData(data: SetAdminFloorRoomsDataRequest): SetAdminFloorRoomsData {
  return {
    type: SET_ADMIN_FLOOR_ROOMS_DATA,
    payload: data,
  };
}

export function getAdminFloorRooms(data: GetAdminFloorRoomsRequest): GetAdminFloorRooms {
  const { locationId, floorId, filters } = data;
  const url = `/api/v2/locations/${locationId}/floors/${floorId}/rooms`;
  const params = new URLSearchParams();

  params.append("page", `${filters.page}`);
  params.append("limit", `${filters.limit}`);
  params.append("custom[includeUnavailable]", "true");

  if (filters.search) {
    params.append("search", filters.search);
  }

  return {
    type: GET_ADMIN_FLOOR_ROOMS,
    payload: {
      request: {
        method: 'GET',
        url: `${url}?${params.toString()}`,
      },
    },
  };
}

export function getAvailableAdminRooms(data: GetAvailableAdminRoomsRequest): GetAvailableAdminRooms {
  const { page, limit, search } = data;
  let url = `/api/v2/rooms/?page=${page}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  return {
    type: GET_AVAILABLE_ADMIN_ROOMS,
    payload: {
      request: {
        method: 'GET',
        url,
      },
    },
  };
}

export function getAvailableAdminFloorRooms(data: GetAvailableAdminFloorRoomsRequest): GetAvailableAdminFloorRooms {
  const { search, limit, includeUnmappedRooms } = data;
  const url = "/api/graph/rooms";
  const params = new URLSearchParams();

  if (search) {
    params.append("search", search);
  }

  if (limit) {
    params.append("limit", `${limit}`);
  }

  if (includeUnmappedRooms) {
    params.append("custom[includeUnmappedRooms]", "true");
  }

  return {
    type: GET_AVAILABLE_ADMIN_FLOOR_ROOMS,
    payload: {
      request: {
        method: 'GET',
        url: `${url}?${params.toString()}`,
      },
    },
  };
}

export function addAdminFloorRoom(data: AddAdminFloorRoomRequest): AddAdminFloorRoom {
  return {
    type: ADD_ADMIN_FLOOR_ROOM,
    payload: {
      request: {
        method: 'POST',
        url: `/api/locations/${data.locationId}/floors/${data.floorId}/rooms`,
        data: data.data,
      },
    },
  };
}

export function editAdminFloorRoom(data: EditAdminFloorRoomRequest): EditAdminFloorRoom {
  return {
    type: EDIT_ADMIN_FLOOR_ROOM,
    payload: {
      request: {
        method: 'PATCH',
        url: `/api/locations/${data.locationId}/floors/${data.floorId}/rooms/${data.roomId}`,
        data: data.data,
      },
    },
  };
}

export function deleteRoom(data: DeleteAdminFloorRoomRequest): DeleteAdminFloorRoom {
  return {
    type: DELETE_ADMIN_FLOOR_ROOM,
    payload: {
      request: {
        method: 'DELETE',
        url: `/api/locations/${data.locationId}/floors/${data.floorId}/rooms/${data.roomId}`,
      },
    },
  };
}