import React from "react";
import { Box, Tooltip } from "@mui/material";
import { WeeklyCalendarViewStepProps } from "./types";

export const WeeklyCalendarViewStep: React.FC<WeeklyCalendarViewStepProps> = (props) => {
  const { height, disabled, tooltip, bgcolor, onClick } = props;
  const step = (
    <Box
      border="black"
      height={height}
      onClick={disabled ? undefined : onClick}
      sx={(({ palette }) => ({
        backgroundColor: bgcolor || "#FFF",
        cursor: disabled ? undefined : "pointer",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: palette.grey[100],
        ":hover": {
          backgroundColor: disabled ? undefined : palette.grey[100],
        },
      }))}
      width="100%"
    />
  );

  return tooltip && !disabled ? <Tooltip disableInteractive title={tooltip}>{step}</Tooltip> : step;
};
