import bookingStyles from '../styles.module.scss';
import Box from 'Components/Box';
import Button from 'Components/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Popup from 'reactjs-popup';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import useSnackbar from 'Components/Snackbar/useSnackbar';
import VisitBreadcrumbs from '../VisitBreadcrumbs';
import VisitCalendar from '../Calendar/VisitCalendar';
import VisitDetails from '../VisitDetails';
import VisitParkingSpot from './VisitParkingSpot';
import { clearCreateNewVisitDataIfVisitCreated } from 'App/Store/Bookings/createNewVisitDuck';
import { getFloorSchema, setFloorMapApiRequestsData } from 'Admin/Store/floorMapApiRequestsDuck';
import { setBookingsData } from 'App/Store/Bookings/bookingDuck';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from 'Store/Redux/store';

export default function VisitDate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [open] = useSnackbar();
  const {
    adminFloorMapApiRequests,
    booking,
    config,
    createNewVisit,
    locations: locationsDuck,
  } = useTypedSelector(state => state);

  const { floorId } = createNewVisit;
  const { loading } = locationsDuck;

  useEffect(() => {
    if (floorId) {
      dispatch(getFloorSchema({ floorId }));
    }
  }, []);

  useEffect(() => {
    if (adminFloorMapApiRequests.error) {
      open({
        text: adminFloorMapApiRequests.error,
        type: 'error',
        onClose: () => {
          dispatch(setFloorMapApiRequestsData({ error: '' }));
        },
      });
    }
  }, [adminFloorMapApiRequests.error]);

  // Closes popup everytime the page render or close
  useEffect(() => {
    handleClosePopup();
  }, []);

  // Clear visit data if visit was successfully created on cleanup
  useEffect(() => {
    return () => {
      dispatch(clearCreateNewVisitDataIfVisitCreated());
      handleClosePopup();
    };
  }, []);

  const handleClosePopup = () => {
    dispatch(setBookingsData({ bookingCreated: false }));
  };

  return (
    <>
      <Popup
        className="modal"
        contentStyle={{
          maxWidth: 570,
          width: '100%',
        }}
        modal
        onClose={() => {
          handleClosePopup();
          history.push('/');
        }}
        open={booking.bookingCreated}
      >
        <Box padding="2px 1px">
          <Box alignItems="center" display="flex" justifyContent="between">
            <Text size="xlg" weight="semi-bold">
              <Trans>Visit was successfully booked</Trans>
            </Text>

            <IconButton
              onClick={handleClosePopup}
              size="small"
              style={{
                backgroundColor: config.theme.primaryLight,
                borderRadius: 8,
                height: 30,
                width: 30,
              }}
            >
              <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
            </IconButton>
          </Box>

          <Box margin="9px 0 18px">
            <Text color="gray" size="md">
              <Trans>You can find all visit details on the bookings list page.</Trans>
            </Text>
          </Box>

          <Box alignItems="center" display="flex" justifyContent="end">
            <Button
              aria-label={t`make a new visit`}
              name={t`make a new visit`}
              size="sm"
              to={`/create-new-visit/onsite/select-location`}
              type="clear"
              withShadow={false}
            >
              <Trans>Make a new visit</Trans>
            </Button>

            <Button
              aria-label={t`back to booked list`}
              name="back to booked list"
              size="sm"
              to="/"
            >
              <Trans>Back to booked list</Trans>
            </Button>
          </Box>
        </Box>
      </Popup>

      <Box className={styles.container} display="grid" gap={30} gridTemplateColumns="8fr 4fr" paddingBottom={50}>
        <Box>
          <Box marginBottom={15}>
            <VisitBreadcrumbs />
          </Box>

          <h3 className={styles.visitTitle}>
            <Trans>
              Instruction
            </Trans>
          </h3>

          {loading ? (
            <Box borderRadius={14} height={300}>
              <Skeleton classes={{ root: styles.skeletonRoot }} height="100%" variant="rect" />
            </Box>
          ) : (
            <div>
              <Text size="md">
                <Trans>Onsite visits are for days where you need to visit the office but do not require a desk.</Trans>
              </Text>
              <br/>
              <Text size="md">
               <Trans>Pick the time slot that best matches the time you will be in the office. If your time will overlap the 2
                hour timeslots provided, please select the time slot that reflects your arrival time.</Trans>
              </Text>
              <br/>
              <Text size="md">
                <Trans>Examples would include:</Trans>
              </Text>
              <ul>
                <li>
                  <Text size="md">
                    <Trans>Coming in solely to attend a boardroom meeting</Trans>
                  </Text>
                </li>
                <li>
                  <Text size="md">
                    <Trans>Short-term visits to pick up or drop off items</Trans>
                  </Text>
                </li>
                <li>
                  <Text size="md">
                    <Trans>IT appointments</Trans>
                  </Text>
                </li>
              </ul>
            </div>
          )}
        </Box>

        <Box className={bookingStyles.stickyBookingWrapper}>
          <Box marginBottom={15}>
            <Text size="xlg" weight="semi-bold">
              <Trans>Visit details</Trans>
            </Text>
          </Box>

          <VisitCalendar />

          <Box marginTop={10}>
            <VisitParkingSpot />
          </Box>

          <Box className={bookingStyles.stickyBookingRow}>
            <VisitDetails />
          </Box>
        </Box>
      </Box>
    </>
  );
}
