import * as variables from "./variables";
import { createTheme } from "@material-ui/core";
import { ThemeColors } from "./models";

export const MaterialThemeDarkBlue = createTheme({
  palette: {
    primary: {
      main: variables.darkBlue3,
    },
    secondary: {
      main: variables.blue2,
    },
    error: {
      main: variables.red,
    },
    success: {
      main: variables.green,
    },
    warning: {
      main: variables.orange,
    },
  },
});

export const ThemeDarkBlue: ThemeColors = {
  primary: variables.darkBlue3,
  primaryHover: variables.darkBlue4,
  primaryLight: variables.darkBlueLight,
  primaryLighten20: variables.darkBlueLigthen20,
  primaryLighten50: variables.darkBlueLigthen50,
  primaryTransparent: variables.darkBlueTransparent,
  secondary: variables.blue2,
  error: variables.red,
  errorTransparent: variables.redTransparent,
  warn: variables.orange,
  warnLight: variables.orangeLight,
  success: variables.green,
  successTransparent: variables.greenTransparent,
  grey: variables.grey,
  greyDark: variables.greyDark,
  greyDarkTransparent: variables.greyDarkTransparent,
  greyLight: variables.greyLight,
  greyLightTransparent: variables.greyLightTransparent,
  greyMedium: variables.greyMedium,
  greyTransparent: variables.greyTransparent,
};
