interface Props {
  color?: string;
  size?: number;
}

export default function User({ color = '#4A4A4A', size = 25 }: React.PropsWithChildren<Props>) {
  return (
    <svg
      height={size}
      version="1.1"
      viewBox="0 0 20 20"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        fill="none"
        fillRule="evenodd"
        id="A-locations"
        stroke="none"
        strokeWidth="1"
      >
        <g
          fill={color}
          fillRule="nonzero"
          id="A-Location-dashboard2"
          transform="translate(-458.000000, -149.000000)"
        >
          <g
            id="Group"
            transform="translate(458.000000, 148.000000)"
          >
            <g
              id="icons/profileplaceholder"
              transform="translate(0.000000, 1.000000)"
            >
              <path
                d="M10,2 C12.7614237,2 15,4.23857625 15,7 C15,8.48081228 14.356267,9.81127266 13.3333763,10.7268057 C16.0871152,11.989024 18,14.7711229 18,18 C18,18.5522847 17.5522847,19 17,19 C16.4477153,19 16,18.5522847 16,18 C16,14.6862915 13.3137085,12 10,12 C6.6862915,12 4,14.6862915 4,18 C4,18.5522847 3.55228475,19 3,19 C2.44771525,19 2,18.5522847 2,18 C2,14.7711229 3.91288484,11.989024 6.66722394,10.7251339 C5.64373303,9.81127266 5,8.48081228 5,7 C5,4.23857625 7.23857625,2 10,2 Z M10,4 C8.34314575,4 7,5.34314575 7,7 C7,8.65685425 8.34314575,10 10,10 C11.6568542,10 13,8.65685425 13,7 C13,5.34314575 11.6568542,4 10,4 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
