import L from "leaflet";
import topLeftIcon from "../assets/topleft.svg";
import topRightIcon from "../assets/topright.svg";
import bottomLeftIcon from "../assets/bottomleft.svg";
import bottomRightIcon from "../assets/bottomright.svg";

type cornerPositionType = 'topleft' | 'topright' | 'bottomleft' | 'bottomright';

export interface ImapMarkers {
  [key: string]: L.Marker;
}

export const getCornerIcon = (corner: cornerPositionType) => {
  let cornerIcon;
  switch (corner) {
    case "topleft":
      cornerIcon = topLeftIcon;
      break;
    case "topright":
      cornerIcon = topRightIcon;
      break;
    case "bottomleft":
      cornerIcon = bottomLeftIcon;
      break;
    case "bottomright":
      cornerIcon = bottomRightIcon;
      break;
    default:
      cornerIcon = topLeftIcon;
  }

  return L.icon({
    iconUrl: cornerIcon,
    iconSize: [30, 30],
  });
};

// to do add comments to this function
export const repositionImage = (corner: cornerPositionType, markers: ImapMarkers, overlay: any) => {
  const { topleftMarker, toprightMarker, bottomleftMarker, bottomrightMarker } = markers;

  switch (corner) {
    case "topleft": {
      const image = overlay.getBounds();
      const topLeftLatLng = topleftMarker.getLatLng();
      const toprightLatLng = toprightMarker.getLatLng();
      const bottomleftLatLng = bottomleftMarker.getLatLng();

      // @ts-ignore
      overlay.setBounds([[image._southWest.lat, topLeftLatLng.lng], [topLeftLatLng.lat, image._northEast.lng]]);
      toprightMarker.setLatLng([topLeftLatLng.lat, toprightLatLng.lng]);
      bottomleftMarker.setLatLng([bottomleftLatLng.lat, topLeftLatLng.lng]);
      break;
    }
    case "topright": {
      const image = overlay.getBounds();
      const toprightLatLng = toprightMarker.getLatLng();
      const topLeftLatLng = topleftMarker.getLatLng();
      const bottomrightLatLng = bottomrightMarker.getLatLng();
      // @ts-ignore
      overlay.setBounds([[image._southWest.lat, image._southWest.lng], [toprightLatLng.lat, toprightLatLng.lng]]);
      topleftMarker.setLatLng([toprightLatLng.lat, topLeftLatLng.lng]);
      bottomrightMarker.setLatLng([bottomrightLatLng.lat, toprightLatLng.lng]);
      break;
    }
    case "bottomleft": {
      const image = overlay.getBounds();
      const bottomleftLatLng = bottomleftMarker.getLatLng();
      const topLeftLatLng = topleftMarker.getLatLng();
      const bottomrightLatLng = bottomrightMarker.getLatLng();
      // @ts-ignore
      overlay.setBounds([[bottomleftLatLng.lat, bottomleftLatLng.lng], [image._northEast.lat, image._northEast.lng]]);
      topleftMarker.setLatLng([topLeftLatLng.lat, bottomleftLatLng.lng]);
      bottomrightMarker.setLatLng([bottomleftLatLng.lat, bottomrightLatLng.lng]);
      break;
    }
    case "bottomright": {
      const image = overlay.getBounds();
      const bottomleftLatLng = bottomleftMarker.getLatLng();
      const toprightLatLng = toprightMarker.getLatLng();
      const bottomrightLatLng = bottomrightMarker.getLatLng();
      // @ts-ignore
      overlay.setBounds([[bottomrightLatLng.lat, image._southWest.lng], [image._northEast.lat, bottomrightLatLng.lng]]);
      toprightMarker.setLatLng([toprightLatLng.lat, bottomrightLatLng.lng]);
      bottomleftMarker.setLatLng([bottomrightLatLng.lat, bottomleftLatLng.lng]);
      break;
    }
  }
};

// get height / width of edited map image
export const getMapDataToEdit = (mapImage: L.ImageOverlay) => {
  const bounds = mapImage.getBounds();
  const northEast = bounds.getNorthEast();
  const southWest = bounds.getSouthWest();

  // round to int numbers for ios/android map support
  const roundToInt = (coordinate: number) => {
    return Math.round(coordinate);
  };

  return {
    mapSizeToEdit: {
      height: roundToInt( Math.abs(northEast.lat - southWest.lat) ),
      width: roundToInt( Math.abs(northEast.lng - southWest.lng) ),
    },
    mapImageBoundsToEdit: [
      [roundToInt(southWest.lat), roundToInt(southWest.lng)],
      [roundToInt(northEast.lat), roundToInt(northEast.lng)],
    ],
  };
};
