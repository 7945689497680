import React, { useEffect, useState } from "react";
import { ConfirmationDialogProps } from "./types";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { t } from "@lingui/macro";

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
  const { title, description, Trigger, open, labels, onConfirm, onClose } = props;
  const [isOpen, setIsOpen] = useState(false);
  const isControlled = "open" in props;

  useEffect(() => {
    if (isControlled && typeof open !== "undefined") {
      setIsOpen(open);
    }
  }, [isControlled, open]);

  const handleClose = () => {
    if (isControlled) {
      onClose?.();
    } else {
      setIsOpen(false);
    }
  };

  const handleConfirmClick = () => {
    if (isControlled) {
      onClose?.();
      onConfirm?.();
    } else {
      setIsOpen(false);
      onConfirm?.();
    }
  };

  return (
    <>
      {!isControlled && Trigger ? <Trigger onClick={() => setIsOpen(true)} /> : undefined}
      <Dialog onClose={handleClose} open={isOpen}>
        <Box p={2}>
          <Box mb={2}>
            <Typography fontWeight="600" mb={1}>{title}</Typography>
            <Typography fontSize={14}>{description}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button data-cid="cancel-button" onClick={handleClose}>{labels?.no || t`No`}</Button>
            <Button data-cid="confirm-button" onClick={handleConfirmClick} sx={{ ml: 2 }} variant="contained">{labels?.yes || t`Yes`}</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
