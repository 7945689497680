import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from 'Components/Box';
import clsx from 'clsx';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LocationForm from '../../Pages/Locations/LocationForm';
import ProfileLink from '../../../Components/Profile';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import Tooltip from '@material-ui/core/Tooltip';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import {getAllowedLocationsByRoles} from "../../Pages/Locations/Helpers";

export default function Menu() {
  const location = useLocation();
  const [expandedLocations, setExpandedLocations] = useState(true);
  const [isOpenLocationForm, setIsOpenLocationForm] = useState(false);
  const { config, locations: locationsDuck, profile } = useTypedSelector(state => state);

  const { roleAccess, locationIds } = profile;
  const { pathname } = location;

  const match = matchPath<{ locationId: string }>(location.pathname, {
    path: '/admin/location/:locationId',
  });

  const locationId = match?.params.locationId;
  const { loading, adminLocations } = locationsDuck;
  // set restrictions for local admin role
  const allowedLocations = getAllowedLocationsByRoles(roleAccess, adminLocations, locationIds);

  const onToggleLocations = (e: any) => {
    e.stopPropagation(); // to not handle location link
    setExpandedLocations(!expandedLocations);
  };

  const onOpenLocationForm = () => {
    setIsOpenLocationForm(o => !o);
  };

  const onCloseLocationForm = () => {
    setIsOpenLocationForm(false);
  };

  return (
    <Box className={styles.menu} dataTestId="admin-menu">
      <ProfileLink greeting="admin" />

      <Box className={styles.menuItems} marginTop={20}>

        <Link className={`${styles.menuLink} ${pathname === '/admin/reservations' ? styles.reservationActive : ''}`} to="/admin/reservations">
          <Box alignItems="center" display="flex" justifyContent="center" marginEnd={9}>
            <svg className={`${pathname === '/admin/reservations' ? styles.reservationActive : ''}`} height="20px" version="1.1" viewBox="0 0 20 20" width="20px">
              <g fill="none" fillRule="evenodd" id="A-reservations" stroke="none" strokeWidth="1">
                <g id="A-Reservations" transform="translate(-20.000000, -70.000000)">
                  <g id="Group" transform="translate(20.000000, 67.000000)">
                    <g id="icons/list-active" transform="translate(0.000000, 3.000000)">
                      <path d="M16,16 C16.5522847,16 17,16.4477153 17,17 C17,17.5522847 16.5522847,18 16,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 L16,16 Z M16,12 C16.5522847,12 17,12.4477153 17,13 C17,13.5522847 16.5522847,14 16,14 L4,14 C3.44771525,14 3,13.5522847 3,13 C3,12.4477153 3.44771525,12 4,12 L16,12 Z" fill={config.theme.primary} id="Combined-Shape"></path>
                      <rect height="6" id="Rectangle" rx="2" stroke={config.theme.primary} strokeWidth="2" width="12" x="4" y="3"></rect>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </Box>
          <Text
            className={styles.text}
            color={pathname.includes('/admin/reservations') ? 'blue' : 'black'}
            size="md"
            weight="semi-bold"
          >
            <Trans>Reservations</Trans>
          </Text>
        </Link>

        {(roleAccess.superAdmin || roleAccess.hr || roleAccess.localAdmin || roleAccess.reservationManager) &&
          <Link className={`${styles.menuLink} ${pathname === '/admin/visits' ? styles.appointmentsActive : ''}`} to="/admin/visits">
            <Box alignItems="center" display="flex" justifyContent="center" marginEnd={9}>
              <svg height="20px" version="1.1" viewBox="0 0 20 20" width="20px">
                <g fill="none" fillRule="evenodd" id="A-appointments" stroke="none" strokeWidth="1">
                  <g id="A-Appointments" transform="translate(-20.000000, -104.000000)">
                    <g id="Group" transform="translate(15.000000, 99.000000)">
                      <g id="Group" transform="translate(5.000000, 5.000000)">
                        <circle cx="8" cy="7" id="Oval" r="4" stroke={config.theme.primary} strokeWidth="2"></circle>
                        <path className={`${pathname === '/admin/visits' ? styles.appointmentsActive : ''}`} d="M12,17 C12,14.790861 10.209139,13 8,13 C5.790861,13 4,14.790861 4,17" fill="transparent" id="Path"></path>
                        <path d="M14,17 C14,13.6862915 11.3137085,11 8,11 C4.6862915,11 2,13.6862915 2,17" id="Path" stroke={config.theme.primary} strokeLinecap="round" strokeWidth="2"></path>
                        <path d="M10.2106573,10.1065128 C10.45576,10.7238516 10.8998748,11.2405364 11.4638193,11.5773846 C11.9131566,11.8457771 12.4385679,12 13,12 C14.6568542,12 16,10.6568542 16,9 C16,7.34314575 14.6568542,6 13,6 C12.7250788,6 12.4587949,6.03698034 12.2058511,6.10623805" id="Path" stroke={config.theme.primary} strokeWidth="2"></path>
                        <path d="M18,17 C18,14.2385763 15.7614237,12 13,12" id="Path" stroke={config.theme.primary} strokeLinecap="round" strokeWidth="2"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </Box>
            <Text
              className={styles.text}
              color={pathname.includes('/admin/visits') ? 'blue' : 'black'}
              size="md"
              weight="semi-bold"
            >
              <Trans>Visits</Trans>
            </Text>
          </Link>
        }

        {(roleAccess.superAdmin || roleAccess.hr || roleAccess.localAdmin) &&
          <Accordion
            classes={{ expanded: styles.accordionExpanded, root: styles.accordion }}
            expanded={expandedLocations}
          >
            <AccordionSummary
              className={`${pathname === '/admin' ? styles.accordionSummaryActive : ''}`}
              classes={{
                content: styles.accordionSummaryContent,
                expandIcon: styles.accordionSummaryIcon,
                root: styles.accordionSummary,
              }}
              expandIcon={<KeyboardArrowDownIcon onClick={(e) => onToggleLocations(e)} style={{ fontSize: 22 }} />}
              id="locations-menu-header"
            >
              <Link to='/admin'>
                <Box alignItems="center" display="flex" justifyContent="between" width="100%">
                  <Box alignItems="center" display="flex">
                    <Box alignItems="center" display="flex" justifyContent="center" marginEnd={9}>
                      {
                        pathname === '/admin' ?
                          <svg height="20px" version="1.1" viewBox="0 0 20 20" width="20px">
                            <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
                              <g fill={config.theme.primary} fillRule="nonzero" id="icons/menu/locations-s">
                                <g id="Combined-Shape">
                                  <path d="M12.5276999,2.03297279 L17.1765496,2.86682387 C17.6531255,2.95230594 18,3.36693413 18,3.85111561 L18,16.4183862 C18,16.970671 17.5522847,17.4183862 17,17.4183862 C16.9477021,17.4183862 16.8954848,17.4142836 16.8438287,17.4061162 L12.5064833,16.7203327 C12.3988002,16.7033067 12.2890575,16.7040042 12.1815995,16.7223973 L7.2895846,17.5597444 C7.17678689,17.5790515 7.06151207,17.5788539 6.94878122,17.5591601 L2.82790909,16.8392553 C2.34926748,16.7556379 2,16.3400648 2,15.8541742 L2,3.21763184 C2,2.66534709 2.44771525,2.21763184 3,2.21763184 C3.06526793,2.21763184 3.13037909,2.22402166 3.19440184,2.23670987 L6.926696,2.97638627 C7.05377577,3.00157129 7.18453849,3.00182349 7.31171446,2.97712885 L12.1605336,2.03559997 C12.2817112,2.01207008 12.406198,2.01117934 12.5276999,2.03297279 Z M4,4.40945071 L4,15.0121286 L6,15.4180984 L6,4.8154205 L4,4.40945071 Z M12,6 C10.3431458,6 9,7.34314575 9,9 C9,10.1045695 10,11.7712362 12,14 C14,11.7712362 15,10.1045695 15,9 C15,7.34314575 13.6568542,6 12,6 Z M12,8 C12.5522847,8 13,8.44771525 13,9 L13,9 L12.9961315,9.05785326 C12.960004,9.3476695 12.6705213,9.91365913 12.114739,10.6925166 L12.114739,10.6925166 L12,10.85 L11.885261,10.6925166 C11.28978,9.85802646 11,9.2678999 11,9 C11,8.44771525 11.4477153,8 12,8 Z"></path>
                                </g>
                              </g>
                            </g>
                          </svg> :
                          <svg height="20px" version="1.1" viewBox="0 0 20 20" width="20px">
                            <g fill="none" fillRule="evenodd" id="A-reservations" stroke="none" strokeWidth="1">
                              <g fill={config.theme.primary} fillRule="nonzero" id="A-Reservations" transform="translate(-20.000000, -138.000000)">
                                <g id="Group" transform="translate(20.000000, 135.000000)">
                                  <g id="icons/list-active" transform="translate(0.000000, 3.000000)">
                                    <path d="M12.5276999,2.03297279 L17.1765496,2.86682387 C17.6531255,2.95230594 18,3.36693413 18,3.85111561 L18,16.4183862 C18,16.970671 17.5522847,17.4183862 17,17.4183862 C16.9477021,17.4183862 16.8954848,17.4142836 16.8438287,17.4061162 L12.5064833,16.7203327 C12.3988002,16.7033067 12.2890575,16.7040042 12.1815995,16.7223973 L7.2895846,17.5597444 C7.17678689,17.5790515 7.06151207,17.5788539 6.94878122,17.5591601 L2.82790909,16.8392553 C2.34926748,16.7556379 2,16.3400648 2,15.8541742 L2,3.21763184 C2,2.66534709 2.44771525,2.21763184 3,2.21763184 C3.06526793,2.21763184 3.13037909,2.22402166 3.19440184,2.23670987 L6.926696,2.97638627 C7.05377577,3.00157129 7.18453849,3.00182349 7.31171446,2.97712885 L12.1605336,2.03559997 C12.2817112,2.01207008 12.406198,2.01117934 12.5276999,2.03297279 Z M12.357,4.034 L8,4.88026453 L8,15.4082645 L11.8441746,14.7510667 C12.0859551,14.7096821 12.331591,14.698159 12.5755841,14.7165325 L12.8188259,14.7448727 L16,15.247 L16,4.687 L12.357,4.034 Z M4,4.435 L4,15.013 L6,15.3622645 L6,4.83126453 L4,4.435 Z M12,6 C13.6568542,6 15,7.34314575 15,9 C15,10.1045695 14,11.7712362 12,14 C10,11.7712362 9,10.1045695 9,9 C9,7.34314575 10.3431458,6 12,6 Z M12,8 C11.4477153,8 11,8.44771525 11,9 C11,9.2678999 11.28978,9.85802646 11.885261,10.6925166 L12,10.85 L12.114739,10.6925166 C12.6705213,9.91365913 12.960004,9.3476695 12.9961315,9.05785326 L13,9 C13,8.44771525 12.5522847,8 12,8 Z" id="Combined-Shape"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                      }
                    </Box>
                    <Text className={styles.text} size="md" weight="semi-bold">
                      <Trans>Locations</Trans>
                    </Text>
                  </Box>
                </Box>
              </Link>
              {/* create location only for super admin */}
              {
                roleAccess.superAdmin &&
                <svg height="16px" onClick={onOpenLocationForm} version="1.1" viewBox="0 0 16 16" width="16px">
                  <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
                    <g fill={config.theme.primary} id="A-Locations" transform="translate(-150.000000, -140.000000)">
                      <g id="icons/plus" transform="translate(150.000000, 140.000000)">
                        <path d="M8,2 C8.55228475,2 9,2.44771525 9,3 L9,7 L13,7 C13.5522847,7 14,7.44771525 14,8 C14,8.55228475 13.5522847,9 13,9 L9,9 L9,13 C9,13.5522847 8.55228475,14 8,14 C7.44771525,14 7,13.5522847 7,13 L7,9 L3,9 C2.44771525,9 2,8.55228475 2,8 C2,7.44771525 2.44771525,7 3,7 L7,7 L7,3 C7,2.44771525 7.44771525,2 8,2 Z" id="Combined-Shape"></path>
                      </g>
                    </g>
                  </g>
                </svg>
              }
            </AccordionSummary>

            <AccordionDetails classes={{ root: styles.accordionDetails }}>
              {(loading && !adminLocations.length) ? (
                <Box width="100%">
                  <Skeleton height={20} variant="text" width="100%" />

                  <Skeleton height={20} variant="text" width="100%" />
                </Box>
              ) : (
                <nav className={styles.nav}>
                  <ul>
                    {allowedLocations.map((location, index) => {
                      const isSelected = location.id === locationId;

                      return (
                        <li className={`${styles.menuItem} ${isSelected ? styles.menuItemSelected : ''}`} key={location.id + index}>
                          <Link data-testid="location-item" to={`/admin/location/${location.id}/floors`}>
                            {!location.active &&
                              <Box alignItems="center" display="flex" marginEnd={8}>
                                <Tooltip
                                  classes={{ tooltip: styles.tooltip }}
                                  placement="top"
                                  title={t`Location is turned off`}
                                >
                                  <ToggleOffIcon style={{ color: config.theme.greyMedium, fontSize: 18 }} />
                                </Tooltip>
                              </Box>
                            }

                            <Text
                              color={isSelected ? 'blue' : location.active ? 'black' : 'lightGray'}
                              size="sm"
                              weight={isSelected ? 'semi-bold' : 'normal'}
                            >
                              {location.locationName}
                            </Text>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              )}
            </AccordionDetails>
          </Accordion>
        }

        {
          roleAccess.superAdmin &&
          <Link
            className={clsx(
              styles.menuLink,
              pathname === '/admin/send-notification' && styles.sendNotificationActive,
            )}
            to="/admin/send-notification"
          >
            <Box alignItems="center" display="flex" justifyContent="center" marginEnd={9}>
              <svg height="20px" viewBox="0 0 20 20" width="20px">
                <g fill="none" fillRule="evenodd" id="A-users" stroke="none" strokeWidth="1">
                  <g id="A-Usermanagment" transform="translate(-20.000000, -274.000000)">
                    <g id="menu/sendnotification" transform="translate(20.000000, 271.000000)">
                      <g id="icons/appointment" transform="translate(0.000000, 3.000000)">
                        <path d="M17,12.0255434 L17,12.0255434 L17,16 C17,16.5522847 16.5522847,17 16,17 L4,17 C3.44771525,17 3,16.5522847 3,16 L3,7 C3,6.44771525 3.44771525,6 4,6 L4.98084679,6 L4.98084679,6" id="Path" stroke="#1E1F7B" strokeLinecap="round" strokeWidth="2"></path>
                        <polygon id="Path-5" points="7 2 17 8 7 14 9 8" stroke="#1E1F7B" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></polygon>
                        <rect fill="#1E1F7B" height="2" id="Rectangle" width="7" x="9" y="7"></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </Box>

            <Text
              className={styles.text}
              color={pathname.includes('/admin/send-notification') ? 'blue' : 'black'}
              size="md"
              weight="semi-bold"
            >
              <Trans>Send Notification</Trans>
            </Text>
          </Link>
        }

        {
          isOpenLocationForm ?
            <LocationForm
              actionState={'add'}
              onCancelAction={onCloseLocationForm}
              open={isOpenLocationForm}
            /> : null
        }
        {
          (roleAccess.superAdmin) && (
            <Link className={`${styles.menuLink} ${pathname === '/admin/settings/mobile' ? styles.settingsActive : ''}`} to="/admin/settings/mobile">
              <Box alignItems="center" display="flex" justifyContent="center" marginEnd={9}>
                <svg height="20px" viewBox="0 0 20 20" width="20px">
                  <g fill="none" fillRule="evenodd" id="A-locations" stroke="none" strokeWidth="1">
                    <g id="A-Location-members" transform="translate(-20.000000, -250.000000)">
                      <g id="Group" transform="translate(20.000000, 247.000000)">
                        <g className={`${pathname === '/admin/settings' ? styles.settingsActive : ''}`} id="Group" transform="translate(0.000000, 3.000000)">
                          <circle cx="14" cy="4" id="Oval" r="2" stroke={config.theme.primary} strokeWidth="2"></circle>
                          <circle cx="6" cy="10" id="Oval" r="2" stroke={config.theme.primary} strokeWidth="2"></circle>
                          <circle cx="14" cy="16" id="Oval" r="2" stroke={config.theme.primary} strokeWidth="2"></circle>
                          <rect fill={config.theme.primary} height="2" id="Rectangle" rx="1" width="11" x="2" y="3"></rect>
                          <rect fill={config.theme.primary} height="2" id="Rectangle" rx="1" width="10" x="8" y="9"></rect>
                          <rect fill={config.theme.primary} height="2" id="Rectangle" rx="1" width="10" x="2" y="15"></rect>
                          <rect fill={config.theme.primary} height="2" id="Rectangle" rx="1" width="3" x="15" y="3"></rect>
                          <rect fill={config.theme.primary} height="2" id="Rectangle" rx="1" width="3" x="2" y="9"></rect>
                          <rect fill={config.theme.primary} height="2" id="Rectangle" rx="1" width="3" x="15" y="15"></rect>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </Box>
              <Text
                className={styles.text}
                color={pathname.includes('/admin/settings/mobile') ? 'blue' : 'black'}
                size="md"
                weight="semi-bold"
              >
                Settings
              </Text>
            </Link>
          )
        }
        {
          (roleAccess.superAdmin || roleAccess.hr || roleAccess.localAdmin) && (
            <Link className={`${styles.menuLink} ${pathname === '/admin/user-management' ? styles.userManagementActive : ''}`} to="/admin/user-management">
              <Box alignItems="center" display="flex" justifyContent="center" marginEnd={9}>
                <svg height="20px" viewBox="0 0 20 20" width="20px">
                  <g fill="none" fillRule="evenodd" id="A-send-notification" stroke="none" strokeWidth="1">
                    <g id="A-Send-notification" transform="translate(-20.000000, -240.000000)">
                      <g id="menu/usermanagement" transform="translate(20.000000, 237.000000)">
                        <g id="icons/appointment" transform="translate(0.000000, 3.000000)">
                          <circle cx="9" cy="7" id="Oval" r="4" stroke="#1E1F7B" strokeWidth="2"></circle>
                          <circle className={styles.userManagementCircle} cx="14" cy="14" id="Oval" r={pathname === '/admin/user-management' ? 5 : 4} stroke="#1E1F7B" strokeWidth="2"></circle>
                          <rect fill="#1E1F7B" height="4" id="Rectangle" rx="1" width="2" x="13" y="12"></rect>
                          <rect fill="#1E1F7B" height="2" id="Rectangle" rx="1" width="4" x="12" y="13"></rect>
                          <path d="M10.9041156,11.3084369 C10.3058446,11.1083734 9.66557829,11 9,11 C5.6862915,11 3,13.6862915 3,17" id="Path" stroke="#1E1F7B" strokeLinecap="round" strokeWidth="2"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </Box>

              <Text
                className={styles.text}
                color={pathname.includes('/admin/user-management') ? 'blue' : 'black'}
                size="md"
                weight="semi-bold"
              >
                <Trans>User Management</Trans>
              </Text>
            </Link>
          )
        }
        {
          (roleAccess.superAdmin || roleAccess.localAdmin) && (
            <Link className={`${styles.menuLink} ${pathname === '/admin/groups' ? styles.groupsActive : ''}`} to="/admin/groups">
              <Box alignItems="center" display="flex" justifyContent="center" marginEnd={9}>
                <svg height="20px" viewBox="0 0 20 20" width="20px">
                  <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
                    <g id="Group">
                      <rect height="6" id="Rectangle" rx="1" stroke="#1E1F7B" strokeLinecap="round" strokeWidth="2" width="6" x="7" y="2"></rect>
                      <rect height="6" id="Rectangle" rx="1" stroke="#1E1F7B" strokeLinecap="round" strokeWidth="2" width="6" x="2" y="12"></rect>
                      <rect height="6" id="Rectangle" rx="1" stroke="#1E1F7B" strokeLinecap="round" strokeWidth="2" width="6" x="12" y="12"></rect>
                      <line id="Path-9" stroke="#1E1F7B" strokeLinecap="round" strokeWidth="2" x1="5" x2="10" y1="12" y2="8"></line>
                      <line id="Path-9" stroke="#1E1F7B" strokeLinecap="round" strokeWidth="2" transform="translate(12.500000, 10.000000) scale(-1, 1) translate(-12.500000, -10.000000) " x1="10" x2="15" y1="12" y2="8"></line>
                    </g>
                  </g>
                </svg>
              </Box>

              <Text
                className={styles.text}
                color={pathname.includes('/admin/groups') ? 'blue' : 'black'}
                size="md"
                weight="semi-bold"
              >
                <Trans>Groups</Trans>
              </Text>
            </Link>
          )
        }
      </Box>
    </Box>
  );
}
