import {useEffect} from "react";
import {useDispatch} from "react-redux";

import GroupHeader from "./GroupHeader";
import GroupsTable from "./GroupsTable";
import {
  ErrorAdminGroupsFormSnackbar,
  ErrorAdminGroupsSnackbar,
  SuccessAdminGroupsSnackbar,
} from "./SnackBars";
import {getLocations} from "../../../App/Store/Locations/locationsDuck";

export default function Groups() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLocations({ activeOnly: false })); // get locations for groups location management
  }, []);

  return (
    <>
      <SuccessAdminGroupsSnackbar />
      <ErrorAdminGroupsSnackbar />
      <ErrorAdminGroupsFormSnackbar />

      <GroupHeader />
      <GroupsTable />
    </>
  );
}
