import Box from "../../../../../../../Components/Box";
import Text from "../../../../../../../Components/Text";
import Button from "../../../../../../../Components/Button";
import {Trans} from "@lingui/macro";
import Popup from "reactjs-popup";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {useTypedSelector} from "../../../../../../../Store/Redux/store";

interface AddFloorConfirmationPopupProps {
  onClose: Function;
  open: boolean;
  onApplyChanges: Function;
  onResetChanges: Function;
}

export default function AddFloorConfirmationPopup(props: AddFloorConfirmationPopupProps) {
  const { config } = useTypedSelector(state => state);
  const { open, onClose, onApplyChanges, onResetChanges } = props;

  return (
    <Popup
      className="modal"
      contentStyle={{
        maxWidth: 570,
        width: '100%',
      }}
      modal
      onClose={() => onClose()}
      open={open}
    >
      <Box alignItems="center" display="flex" justifyContent="between">
        <Text size="xlg" weight="semi-bold">
          <Trans>
            Unsaved changes
          </Trans>
        </Text>

        <IconButton
          onClick={() => onClose()}
          size="small"
          style={{
            backgroundColor: config.theme.primaryLight,
            borderRadius: 8,
            height: 30,
            width: 30,
          }}
        >
          <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
        </IconButton>
      </Box>

      <Box margin="9px 0 12px">
        <Text color="gray" size="md">
          <Trans>
            You have unsaved changes. All changed date will be lost. Do you want to save?
          </Trans>
        </Text>
      </Box>

      <Box alignItems="center" display="flex" justifyContent="end">
        <Button
          onClick={() => onResetChanges()}
          size="sm"
          type="clear"
          withShadow={false}
        >
          <Trans>
            Discard Changes
          </Trans>
        </Button>

        <Button
          onClick={() => onApplyChanges()}
          size="sm"
        >
          <Trans>
            Save and back to  floor list
          </Trans>
        </Button>
      </Box>
    </Popup>
  );
}
