import { GuardFunction } from "Components/ProtectedRoute/models";
import { useTypedSelector } from "Store/Redux/store";

const FloorGuard: GuardFunction = () => {
  const { selectedData } = useTypedSelector(state => state.createNewMeeting);    

  const { location } = selectedData;

  if (!location) {
    return false;
  }
  
  return true;
};

export default FloorGuard;
