import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FloorsState, FloorsStateCurrent } from "./types";

const initialState: FloorsState = {};

const resetCurrent: CaseReducer<FloorsState, PayloadAction<Partial<FloorsStateCurrent> | undefined>> = (state, action) => ({
  ...state,
  current: action.payload,
});

const setCurrent: CaseReducer<FloorsState, PayloadAction<Partial<FloorsStateCurrent> | undefined>> = (state, action) => ({
  ...state,
  current: { ...state.current, ...action.payload },
});

export const floorsSlice = createSlice({
  initialState,
  name: "floors",
  reducers: {
    resetCurrent,
    setCurrent,
  },
});
