/**
 * Receives a date with hour and minutes, and returns it in number.
 * Ex: "2021-04-14T09:30:00.000Z" => 9.5
 *     "2021-04-14T12:00:00.000Z" => 12
 */
export default function hoursMinutesToNumber(hoursMinutes: Date | string): number {
  hoursMinutes = new Date(hoursMinutes);

  // Separate the hours
  const hours = hoursMinutes.getHours();
  // Separate the minutes
  let minutes = hoursMinutes.getMinutes();

  // Transform minutes into a number. Ex: 30 => 0.5
  minutes = minutes/60;

  return hours + minutes;
}
