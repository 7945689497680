import Api from 'Store/Services/Api';
import { RemoveDeskFromSavedListResponseFake } from './models';
import {
  call,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  ADD_DESK_TO_SAVED_LIST,
  ADD_DESK_TO_SAVED_LIST_FAIL,
  ADD_DESK_TO_SAVED_LIST_SUCCESS,
  AddDeskToSavedList,
  GET_SAVED_DESKS,
  GET_SAVED_DESKS_FAIL,
  GET_SAVED_DESKS_SUCCESS,
  GetSavedDesks,
  REMOVE_DESK_FROM_SAVED_LIST,
  REMOVE_DESK_FROM_SAVED_LIST_FAIL,
  REMOVE_DESK_FROM_SAVED_LIST_SUCCESS,
  RemoveDeskFromSavedList,
} from '.';

function* addDeskToSavedListSaga(action: AddDeskToSavedList): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: ADD_DESK_TO_SAVED_LIST_SUCCESS, payload });
  } catch (e) {
    yield put({ type: ADD_DESK_TO_SAVED_LIST_FAIL, payload: e });
  }
}

function* getSavedDesksSaga(action: GetSavedDesks): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_SAVED_DESKS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_SAVED_DESKS_FAIL, payload: e });
  }
}

function* removeDeskFromSavedListSaga(action: RemoveDeskFromSavedList): any {
  try {
    yield call(Api, action);
    const deskId = action.payload.additionalData?.deskId;
    const payload: RemoveDeskFromSavedListResponseFake = { deskId };

    yield put({ type: REMOVE_DESK_FROM_SAVED_LIST_SUCCESS, payload });
  } catch (e) {
    yield put({ type: REMOVE_DESK_FROM_SAVED_LIST_FAIL, payload: e });
  }
}

export default [
  takeEvery(GET_SAVED_DESKS, getSavedDesksSaga),
  takeLatest(ADD_DESK_TO_SAVED_LIST, addDeskToSavedListSaga),
  takeLatest(REMOVE_DESK_FROM_SAVED_LIST, removeDeskFromSavedListSaga),
];
