import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherLink: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M1.926 6.275a1.025 1.025 0 1 1 1.45 1.45l-.725.725a2.05 2.05 0 1 0 2.9 2.9l2.174-2.175a2.05 2.05 0 0 0 .12-2.77l-.12-.13a1.025 1.025 0 0 1 1.45-1.45 4.1 4.1 0 0 1 0 5.8L7 12.798A4.1 4.1 0 1 1 1.201 7zM7 1.201A4.1 4.1 0 0 1 12.799 7a1.025 1.025 0 0 1-1.45-1.45 2.05 2.05 0 1 0-2.9-2.9L7 4.1a2.05 2.05 0 0 0-.104 2.788L7 7a1.025 1.025 0 0 1-1.45 1.45 4.1 4.1 0 0 1 0-5.8z"
        fill={props.fill}
        fillOpacity="1"
        fillRule="nonzero"
        transform="matrix(-1.14286 0 0 -1.14286 16 16)"
      />
    </SvgIcon>
  );
};
