import {useDispatch} from "react-redux";
import {useEffect} from "react";
import Box from "../../../../../../Components/Box";
import Text from "../../../../../../Components/Text";
import {Trans} from "@lingui/macro";
import {setJustInTimeReservationData} from "../../../../../Store/reservationJustInTime";
import { useTypedSelector } from "Store/Redux/store";
import { resolvePersistedDate } from "Store/Utils";
import { FromToTimeInput, FromToTimeInputProps } from "App/Pages/CreateNewBooking/Calendar/FromToTimeInput";

const DEFAULT_TIME_FROM = new Date(1970, 0, 1, 9);
const DEFAULT_TIME_TO = new Date(1970, 0, 1, 18);

export default function DailyBooking() {
  const dispatch = useDispatch();
  const { timeFrom, timeTo } = useTypedSelector((state) => state.adminReservationJustInTime);
  const timeFromDate = resolvePersistedDate(timeFrom);
  const timeToDate = resolvePersistedDate(timeTo);

  useEffect(() => {
    dispatch(setJustInTimeReservationData({
      timeFrom: timeFromDate || DEFAULT_TIME_FROM,
      timeTo: timeToDate || DEFAULT_TIME_TO,
    }));
  }, []);

  const handleFromToTimeInputChange: FromToTimeInputProps["onChange"] = (value, error) => {
    if (error.from || error.to) {
      dispatch(setJustInTimeReservationData({
        timeFrom: undefined,
        timeTo: undefined,
      })); 
    } else {
      dispatch(setJustInTimeReservationData({
        timeFrom: value.from,
        timeTo: value.to,
      }));
    }
  };

  return (
    <Box>
      <Box paddingBottom={20} paddingTop={20}>
        <Text size="md" weight="semi-bold">
          <Trans>Daily</Trans>
        </Text>

        <Text color="gray" size="md">
          <Trans>Each day apart from local exceptions. You can book only for two weeks.</Trans>
        </Text>

        <Box alignItems="center" display="flex" justifyContent="between" marginTop={18}>
          <Text size="md" weight="semi-bold">
            <Trans>Time</Trans>
          </Text>
          <FromToTimeInput
            defaultValue={{ from: new Date(0, 0, 0, 9), to: new Date(0, 0, 0, 18) }}
            from={timeFromDate}
            onChange={handleFromToTimeInputChange}
            to={timeToDate}
          />
        </Box>
      </Box>
    </Box>
  );
}