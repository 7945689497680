import capitalizeFirstLetter from 'Functions/capitalizeFirstLetter';
import format from 'date-fns/format';
import styles from './styles.module.scss';
import Text from 'Components/Text';

export default function CustomTooltip(props: any) {
  const { active } = props;

  if (active) {
    return (
      <div className={styles.container}>
        {props.payload?.map((payload: any, i: number) => {
          const date = payload.payload ? payload.payload.monthDate : null;

          return (
            <div key={i}>
              {(payload.name === 'booked' && date) ? (
                <Text color="white" size="md">
                  {format(new Date(`${date}-01 12:00`), 'MMM, yyyy')}
                </Text>
              ) : null}

              <Text>
                <Text
                  color="white"
                  inline
                  size="md"
                  weight="bold"
                >
                  {payload.value}
                </Text>{' '}

                <Text
                  color="white"
                  inline
                  size="md"
                >
                  {capitalizeFirstLetter(payload.name)}
                </Text>
              </Text>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
}