import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../Store/Redux/store';
import { Trans, t } from '@lingui/macro';
import styles from '../styles.module.scss';

// @ts-ignore
import DoubleScrollbar from 'react-double-scrollbar';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from 'Components/Box';
import Text from 'Components/Text';
import CheckboxCustom from '../../../../Components/CheckBoxCustom';
import OverflowToolTip from "../../../../Components/OverflowTooltip";
import AppointmentBodyItem from './ListBodyItem';
import { setVisitsData, updateBulkVisitMassActionsIds } from 'Admin/Store/visits';
import { VisitModel } from 'Admin/Store/visits/models';

interface Props {
  appointments: VisitModel[];
}

export default function AppointmentsList({ appointments }: Props) {
  const dispatch = useDispatch();
  const { adminVisits } = useTypedSelector(state => state);
  const { loading, visitMassActions, visitMassActionsIds, filters, totalCount, limit } = adminVisits;

  const isMassActiveActive = visitMassActions;
  const isAllOnPageSelected = visitMassActionsIds.length === appointments.length;

  const onBulkMassActionUpdate = () => {
    let ids: string[] = [];
    if (!isAllOnPageSelected) {
      ids = appointments.map(appointment => appointment.id);
    }

    dispatch(updateBulkVisitMassActionsIds(ids));
  };

  const onSetAllSelected = () => {
    dispatch(setVisitsData({
      filters: {
        ...filters,
        allSelected: true,
      },
    }));
  };

  return (
    <Box className={styles.listView}>
      {
        isMassActiveActive && isAllOnPageSelected && totalCount > limit ?
          <Box className={styles.allSelect}>
            {
              filters.allSelected ?
                <div>You selected all visits from all pages</div> :
                <div>
                  <span>You selected all visits from current page</span>
                  <span
                    className={styles.allSelectActivate}
                    onClick={onSetAllSelected}
                  >
                    Select all {totalCount} reservations
                  </span>
                  <span>from rest of pages</span>
                </div>
            }

          </Box> : null
      }

      <TableContainer>
        <DoubleScrollbar>
          <Table
            aria-label={t`Appointments list view`}
            aria-labelledby={t`list view`}
            className={styles.appointmentTable}
          >
            <TableHead classes={{ root: styles.tableHead }}>
              <TableRow classes={{ root: styles.tableRow }}>
                <TableCell classes={{ root: styles.tableCell }} padding="none">
                  <Box alignItems="center" display="flex">
                    {
                      isMassActiveActive ?
                        <CheckboxCustom
                          checked={isAllOnPageSelected}
                          name={'listViewAll'}
                          onChange={onBulkMassActionUpdate}
                        /> : null
                    }
                    <span><Trans>Location name</Trans></span>
                  </Box>
                </TableCell>
                <TableCell classes={{ root: styles.tableCell }} padding="none"><Trans>Floor</Trans></TableCell>
                <TableCell classes={{ root: styles.tableCell }} padding="none"><Trans>User</Trans></TableCell>
                <TableCell classes={{ root: styles.tableCell }} padding="none"><Trans>Date and time</Trans></TableCell>
                <TableCell classes={{ root: styles.tableCell }} padding="none"><Trans>Parking</Trans></TableCell>
                <TableCell classes={{ root: styles.tableCell }} padding="none"><OverflowToolTip text={t`Accommodation`} /></TableCell>
                <TableCell classes={{ root: styles.tableCell }} padding="none"><Trans>Status</Trans></TableCell>
                <TableCell classes={{ root: styles.tableCell }} padding="none"><Trans>Check-in</Trans></TableCell>
                <TableCell classes={{ root: styles.tableCell }} padding="none"><Trans>Doc status</Trans></TableCell>
                <TableCell classes={{ root: styles.tableCell }} padding="none"></TableCell>
              </TableRow>
            </TableHead>

            {!loading && (
              <TableBody classes={{ root: styles.tableBody }}>
                {
                  appointments.map((appointment, index) => {
                    return (<AppointmentBodyItem appointment={appointment} index={index} key={index} />);
                  })
                }
              </TableBody>
            )}
          </Table>
        </DoubleScrollbar>
      </TableContainer>

      {loading ? (
        <Box height={200}>
          <Skeleton height="100%" variant="rect" />
        </Box>
      ) : (
        !appointments.length && (
          <Box marginTop={100}>
            <Text align="center" color="lightGray" size="md">
              <Trans>No visits found with the selected filters</Trans>
            </Text>
          </Box>
        )
      )}
    </Box>
  );
}
