export enum RecipientType {
  "All users" = "allUsers",
  "Mobile users" = "mobileUsers",
  "Web users" = "webUsers",
}

export const recipientsArray: Array<{
  key: keyof typeof RecipientType;
  label: keyof typeof RecipientType;
  value: RecipientType;
}> = [
  {
    key: "All users",
    label: "All users",
    value: RecipientType["All users"],
  },
  {
    key: "Mobile users",
    label: "Mobile users",
    value: RecipientType["Mobile users"],
  },
  {
    key: "Web users",
    label: "Web users",
    value: RecipientType["Web users"],
  },
];

export interface GlobalNotificationModel {
  id: string;
  subject: string;
  message: string;
  status: 'sent' | 'pending';
  createdAt: string;
  recipients: RecipientType;
  sendDate: string;
  startDate: string | null;
  endDate: string | null;
  locationId?: null;
}

export interface CreateGlobalNotificationRequest {
  subject: string;
  message: string;
  sendDate: string;
  recipients: RecipientType;
  startDate?: string | null;
  endDate?: string | null;
}

export interface CreateGlobalNotificationResponse {
  result: {
    statusCode: number;
    data: GlobalNotificationModel;
  }
}

export interface DeleteGlobalNotificationRequest {
  notificationId: string;
}

export interface DeleteGlobalNotificationResponse {
  result: {
    statusCode: number;
    data: {
      id: string;
    };
  }
}

export interface GetGlobalNotificationsRequest {
  limit: number;
  page: number;
  search: string;
  dateFrom?: string;
  dateTo?: string;
  recipientType?: RecipientType;
}

export interface GetGlobalNotificationsResponse {
  result: {
    statusCode: number;
    data: {
      page: number;
      limit: number;
      totalPages: number;
      notifications: GlobalNotificationModel[];
    };
  }
}

export interface UpdateGlobalNotificationRequest {
  notificationId: string;
  data: CreateGlobalNotificationRequest;
}

export interface UpdateGlobalNotificationResponse {
  result: {
    statusCode: number;
    data: GlobalNotificationModel;
  }
}

export interface SendGlobalNotificationRequestData {
  recipientType: RecipientType;
  startDate: string;
  endDate: string;
}

export interface SendGlobalNotificationRequest {
  notificationId: string;
  data: SendGlobalNotificationRequestData;
}

export interface SendGlobalNotificationResponse {
  result: {
    statusCode: number;
    data: {
      id: string;
    };
  }
}

export interface SetGlobalNotificationsReduxDataRequest {
  page?: number;
  search?: string;
  successMessage?: string;
  successDeleteMessage?: string;
  successSendMessage?: string;
  successUpdateMessage?: string;
}
