import Api from 'Store/Services/Api';
import {call, put, takeLatest} from 'redux-saga/effects';
import {
  GET_CERTIFICATES_BY_USER,
  GET_CERTIFICATES_BY_USER_FAIL,
  GET_CERTIFICATES_BY_USER_SUCCESS,
  GetCertificatesByUser,
  UPDATE_COVID_CERTIFICATE,
  UPDATE_COVID_CERTIFICATE_FAIL,
  UPDATE_COVID_CERTIFICATE_SUCCESS,
  UPLOAD_COVID_CERTIFICATE,
  UPLOAD_COVID_CERTIFICATE_FAIL,
  UPLOAD_COVID_CERTIFICATE_SUCCESS,
  UpdateCovidCertificate,
  UploadCovidCertificate,
} from './index';

function* uploadCovidCertificateSaga(action: UploadCovidCertificate): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: UPLOAD_COVID_CERTIFICATE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: UPLOAD_COVID_CERTIFICATE_FAIL, payload: e });
  }
}

function* getCertificatesByUserSaga(action: GetCertificatesByUser): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_CERTIFICATES_BY_USER_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_CERTIFICATES_BY_USER_FAIL, payload: e });
  }
}

function* updateCertificatSaga(action: UpdateCovidCertificate): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: UPDATE_COVID_CERTIFICATE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: UPDATE_COVID_CERTIFICATE_FAIL, payload: e });
  }
}

export default [
  takeLatest(UPLOAD_COVID_CERTIFICATE, uploadCovidCertificateSaga),
  takeLatest(GET_CERTIFICATES_BY_USER, getCertificatesByUserSaga),
  takeLatest(UPDATE_COVID_CERTIFICATE, updateCertificatSaga),
];
