import { Tab as MUITab, TabProps as MUITabProps, styled } from "@mui/material";

export const TextTab = styled((props: MUITabProps) => <MUITab {...props} />)(() => ({
  // borderRadius: 8,
  // paddingLeft: 16,
  // paddingRight: 16,
  // paddingTop: 6,
  // paddingBottom: 6,
  // minHeight: 0,
  // fontWeight: "600",
  // minWidth: 120,
  // textTransform: "capitalize",
  // "&.Mui-selected": {
  //   backgroundColor: "#ffffff",
  // },
  paddingLeft: 0,
  paddingRight: 0,
  minWidth: 0,
  minHeight: 0,
  textTransform: "capitalize",
  fontWeight: "600",
  fontSize: 16,
  color: "black",
  marginRight: 16,
  paddingTop: 6,
  paddingBottom: 6,
}));
