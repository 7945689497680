import Box from "../../../../Components/Box";
import {useTypedSelector} from "../../../../Store/Redux/store";
import Map from "../../../../App/Pages/CreateNewBooking/Desk/Map";
import Text from "../../../../Components/Text";
import Skeleton from "@material-ui/lab/Skeleton";
import styles from "./styles.module.scss";
import ReservationJustInTimeFilters from './ReservationJustInTimeFilters';

export default function ReservationJustInTimeMap() {
  const { adminFloorMap, locations, adminReservationJustInTime } = useTypedSelector(state => state);
  const { mapSize, name, previewUrl, mapImageBounds } = adminFloorMap;
  const { selectedData } = adminReservationJustInTime;
  const { loading } = locations;

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="between"
        marginBottom={15}
      >
        <h3 className={styles.deskTitle}>Floor map</h3>
        <ReservationJustInTimeFilters />
      </Box>
      {
        !selectedData.floor ?
          <div className={styles.noMap}>
            Select location and floor first
          </div> :
          <Box>
            {loading ? (
              <Box borderRadius={14} height={300}>
                <Skeleton classes={{ root: styles.skeletonRoot }} height="100%" variant="rect" />
              </Box>
            ) : (
              <Box display="grid" gap={15}>
                <Text weight="semi-bold">
                  {(previewUrl) && (
                    <Map
                      adminBooking
                      mapImageBounds={mapImageBounds}
                      mapSize={mapSize}
                      name={name}
                      previewUrl={previewUrl}
                    />
                  )}
                </Text>
              </Box>
            )}
          </Box>
      }
    </>
  );
}
