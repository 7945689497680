import { IGroup, IGroupAdSearch } from "../../models";

export enum GroupCreateEditFormActionStateEnum {
  add = "add",
  edit = "edit",
}
export enum GroupCreateEditFormTabEnum {
  newGroup = "newGroup",
  activeDirectory = "activeDirectory",
}

export type ActionState =
  | GroupCreateEditFormActionStateEnum.add
  | GroupCreateEditFormActionStateEnum.edit

export type TabsState =
  | GroupCreateEditFormTabEnum.newGroup
  | GroupCreateEditFormTabEnum.activeDirectory

export interface IFormErrors {
  groupNameError: boolean;
  groupAdError: boolean;
}

export interface SetGroupsCreateEditFormDataRequest {
  error?: string;
  loadingAdSearch?: boolean;
  actionState?: ActionState;
  tabsState?: TabsState;
  actionTypeName?: string;
  groupsAd?: IGroupAdSearch[],
  group?: IGroup;

  formDataName?: string;
  formDataLocationIds?: string[];
  formDataAdGroup?: IGroupAdSearch | null;

  formErrors?: IFormErrors;
}
