import {
  AddDeskToSavedListResponse,
  GetSavedDesksResponse,
  RemoveDeskFromSavedListResponse,
  SavedDeskModel,
} from './models';
import { DeskStatusEnum } from "../../../../Admin/Store/floorMapDuck/desk/models";

export const addDeskToSavedListSuccess: AddDeskToSavedListResponse = {
  result: {
    statusCode: 201,
    data: {
      id: '5959e553-550a-4c6b-8923-3856394aa596',
      userId: '5a75189b-e8c4-4df4-bb7f-f92ed36e8d8a',
      location: {
        id: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
        locationName: 'New York',
        locationAddress: '100 W 33rd St, New York, NY 10001',
        businessHours: '00:00:00 - 24:00:00',
        country: 'USA',
        city: 'NY',
        region: 'NA',
        createdAt: '2021-05-24T11:28:12.739Z',
        timezone: 'GMT-3',
      },
      floor: {
        id: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
        floorName: 'My Floor 4',
        locationId: 'd06ddbdb-75de-4175-b997-aeacc47c5aa2',
      },
      desk: {
        id: 'save-desk-id-success',
        name: 'Desk-4-test',
        floorId: 'ea36d26e-54df-4ddc-8a76-06be81e71fbd',
        owner: null,
        section: 2,
        status: DeskStatusEnum.Available,
        resources: [],
      },
    },
  },
  error: null,
};

export const removeDeskFromSavedListSuccess: RemoveDeskFromSavedListResponse = {
  result: {
    statusCode: 200,
  },
  error: null,
};

export const savedDeskModel: SavedDeskModel = {
  id: "01aa2d37-ef1a-46ee-a67d-f190a0156d10",
  userId: "db3299d0-1d76-4443-a313-93b96c784bb4",
  location: {
    id: "d06ddbdb-75de-4175-b997-aeacc47c5aa2",
    locationName: "New York",
    locationAddress: "100 W 33rd St, New York, NY 10001",
    businessHours: "00:00:00 - 24:00:00",
    city: "NY",
    country: "USA",
    region: "NA",
    createdAt: "2021-05-24T11:28:12.739Z",
    timezone: "GMT-3",
  },
  floor: {
    id: "0a3e523d-5a03-441d-b952-308944a2386c",
    floorName: "Floor_1",
    locationId: "16fc1895-057c-471f-82c5-b4a459d7ac2e",
  },
  desk: {
    id: "5e6cc199-bc14-4f9e-9a8c-686e1d33cf1a",
    name: "Desk 114",
    section: 2,
    floorId: "111f1b98-a87b-47ff-a3c5-9425fddd5036",
    owner: "3dac4fde-589e-411a-b390-28bcf9c2ce73",
    status: DeskStatusEnum.Available,
    resources: [
      {
        id: "9a8e35b8-d834-4d2c-a79d-4797ada95282",
        name: "Power cord",
        locationId: "e8a80b85-8e33-44a9-a82e-b55973eea579",
        isAvailable: true,
        createdAt: "2021-07-05T15:36:30.972Z",
      },
      {
        id: "9a8e35b8-d834-4d2c-a79d-4797ada95283",
        name: "Monitor",
        locationId: "e8a80b85-8e33-44a9-a82e-b55973eea579",
        isAvailable: true,
        createdAt: "2021-07-05T15:36:30.972Z",
      },
    ],
  },
};

export const getSavedDesksResponseSuccess: GetSavedDesksResponse = {
  result: {
    data: [
      savedDeskModel,
      {
        ...savedDeskModel,
        id: '222',
        desk: {
          ...savedDeskModel.desk,
          id: '222',
        },
      },
      {
        ...savedDeskModel,
        id: '333',
        desk: {
          ...savedDeskModel.desk,
          id: '333',
        },
      },
      {
        ...savedDeskModel,
        id: '444',
        desk: {
          ...savedDeskModel.desk,
          id: '444',
        },
      },
      {
        ...savedDeskModel,
        id: '555',
        desk: {
          ...savedDeskModel.desk,
          id: '555',
        },
      },
      {
        ...savedDeskModel,
        id: '666',
        desk: {
          ...savedDeskModel.desk,
          id: '666',
        },
      },
    ],
    statusCode: 200,
  },
};