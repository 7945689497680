import Api from 'Store/Services/Api';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ADD_USER_DEVICE_TOKEN,
  ADD_USER_DEVICE_TOKEN_FAIL,
  ADD_USER_DEVICE_TOKEN_SUCCESS,
  AddUserDeviceToken,
  GET_PROFILE,
  GET_PROFILE_FAIL,
  GET_PROFILE_SUCCESS,
  GET_USER_ACTUAL_DOCUMENT,
  GET_USER_ACTUAL_DOCUMENT_FAIL,
  GET_USER_ACTUAL_DOCUMENT_SUCCESS,
  GetActualDocument,
  GetProfile,
} from '.';

function* getProfileSaga(action: GetProfile): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_PROFILE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_PROFILE_FAIL, payload: e });
  }
}

function* addUserDeviceTokenSaga(action: AddUserDeviceToken): any {
  try {
    const payload = yield call(Api, action, false, true);

    yield put({ type: ADD_USER_DEVICE_TOKEN_SUCCESS, payload });
  } catch (e) {
    yield put({ type: ADD_USER_DEVICE_TOKEN_FAIL, payload: e });
  }
}

function* getActualDocumentSaga(action: GetActualDocument): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_USER_ACTUAL_DOCUMENT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_USER_ACTUAL_DOCUMENT_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_PROFILE, getProfileSaga),
  takeLatest(ADD_USER_DEVICE_TOKEN, addUserDeviceTokenSaga),
  takeLatest(GET_USER_ACTUAL_DOCUMENT, getActualDocumentSaga),
];
