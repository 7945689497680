import { useDispatch } from "react-redux";

import { updateMeeting } from "../../../../../../Store/floorMapDuck";
import { FloorMapObject } from "../../../../../../Store/floorMapDuck/models";
import Amenities from "../../Components/Amenities";
import { AttributeModel } from "../../../../../../Store/attributes/models";
import { useTypedSelector } from "Store/Redux/store";

interface Props {
  meeting: FloorMapObject;
}

export default function MeetingDetailsAmenities(props: Props) {
  const dispatch = useDispatch();
  const { meeting } = props;
  const { id, amenityIds } = meeting;
  const { attributesRoom } = useTypedSelector(state => state.adminAttributes);

  const handleChangeAmenities = (value: boolean, attribute: AttributeModel) => {
    const newAmenities = Object.keys(amenityIds || {}).length
      ? { ...amenityIds }
      : {};

    if (value) {
      newAmenities[attribute.id] = attribute.id;
    } else {
      delete newAmenities[attribute.id];
    }

    dispatch(updateMeeting({
      meetingId: typeof id === "string" ? parseInt(id) : id,
      meeting: {
        amenities: newAmenities,
      },
    }));
  };

  return (
    <Amenities attributes={attributesRoom} onUpdate={handleChangeAmenities} selected={meeting.amenityIds} />
  );
}
