import { Themes } from "./models";

export const domainSchemes: { [key in Themes]: string[] } = {
  ogBlue: [
    'acxiom.com',
    'bgenius.com',
    'blackglassco.com',
    'bpnww.com',
    'commonwealth-team.com',
    'elevatedigitalww.com',
    'fastbridge.hu',
    'fcb.com',
    'gmail.com',
    'healixglobal.com',
    'identitycomms.com.au',
    'identityww.com',
    'idmediaww.com',
    'initiative.com',
    'j3canada.com',
    'magnaglobal.com',
    'mbcsww.com',
    'mbww.com',
    'mediaexperts.com',
    'mediaexperts.com.duplicated',
    'momentumww.com',
    'mrm.com',
    'orionworldwide.com',
    'pursway.com',
    'rapportww.com',
    'reprisedigital.com',
    'rufusww.com',
    'societyagency.com',
    'stickyeyes.com',
    'thisisansible.com',
    'thrive-umww.com',
    'traverse32.com',
    'umj3.com',
    'umspotify.com',
    'umww.com',
    'xpeto.net',
    'zazzlemedia.co.uk',
  ],
  ipg: [
    'corp.ipgnetwork.com',
    'craftww.com',
    'fcbhealth.com',
    'interpublic.com',
    'interpublic.onmicrosoft.com',
    'ipgdxtra.com',
    'ipghealth.com',
    'ipglab.com',
    'ipgteamaccess.com',
    'ipgteamcyclone.com',
    'mccann.com',
    'mccannhealth.com',
    'mccannwg.com',
    'mediabrands.com',
    'mediabrands.fr',
    'mullenlowe.com',
  ],
  green: [
    'kinesso.com',
    'matterkind.com',
  ],
};
