import Box from 'Components/Box';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import TextField from 'Components/TextField';
import { Trans } from '@lingui/macro';
import { updateSection } from 'Admin/Store/floorMapDuck';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';

/**
 * Change section name
 * 
 * reference: https://projects.invisionapp.com/d/main?origin=v7#/console/21248637/449340369/inspect?scrollOffset=2540
 * 
 * Owned by: Bruno
 */
export default function SectionDetails() {
  const dispatch = useDispatch();
  const { adminFloorMap } = useTypedSelector(state => state);

  const { activeSectionId, sections } = adminFloorMap;

  const section = activeSectionId ? sections[activeSectionId] : undefined;

  if (!section) {
    return null;
  }

  const { id, name } = section;

  const [sectionNameState, setSectionNameState] = useState(name);

  // Sync section name on redux and on state
  useEffect(() => {
    setSectionNameState(name);
  }, [name]);

  /**
   * Updates section name on state and check if it's valid
   */
  const handleChangeSectionName = (event: any) => {
    // Always update the section name
    setSectionNameState(event.target.value);
  };

  /**
   * Update section name on redux state
   */
  const handleUpdateSectionName = () => {
    // eg: The type check is to satisfy TypeScript, since desks can have id's as strings and they share
    // the same model, but not actually necessary since the id of a section will always be a number
    if (name !== sectionNameState && typeof id === 'number') {
      dispatch(updateSection({
        sectionId: id,
        section: {
          name: sectionNameState,
        },
      }));
    }
  };

  return (
    <Box className={styles.sectionDetails}>
      <Text size="xlg" weight="semi-bold">
        <Trans>Section details</Trans>
      </Text>

      <Box marginBottom={22} marginTop={16}>
        <Box className={styles.detailsSection}>
          <label htmlFor="section-name">
            <Text color="gray" size="md">
              <Trans>Section name:</Trans>
            </Text>
          </label>

          <Text color="gray" size="md">
            <TextField
              classes={{ input: styles.textFieldInput }}
              id="section-name"
              onBlur={handleUpdateSectionName}
              onChange={handleChangeSectionName}
              value={sectionNameState}
            />
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
