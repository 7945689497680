import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../../../../Store/Redux/store";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { t } from "@lingui/macro";
import { GroupsSelect } from "components";
import { useState } from "react";
import { Group } from "store";
import { updateDesk } from "Admin/Store/floorMapDuck";
import { MapDrawingGroup } from "Admin/Store/floorMapDuck/models";

type MassActionsEditGroupsProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function MassActionsEditGroups(props: MassActionsEditGroupsProps) {
  const { isOpen, onClose } = props;
  const dispatch = useDispatch();
  const desksIds = useTypedSelector((state) => Object.keys(state.adminFloorMap.editDeskList.selectedDesks));
  const [groups, setGroups] = useState<Group[]>([]);

  const handleSave = () => {
    for (const deskId of desksIds) {
      dispatch(updateDesk({
        deskId,
        updateDeskProperties: {
          groups: groups as MapDrawingGroup[],
        },
      }));
    }
    
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>{t`Select desk groups`}</DialogTitle>
      <DialogContent>
        <Box width={360}>
          <GroupsSelect onChange={setGroups} value={groups} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>{t`Save`}</Button>
      </DialogActions>
    </Dialog>
  );
}
