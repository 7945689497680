interface Props {
  boxColor?: string;
  color?: string;
  size?: number;
}

export default function Close({ boxColor = "#F1F8FF", color = '#1D1F7B', size = 30 }: React.PropsWithChildren<Props>) {
  return (
    <svg height={`${size}px`} viewBox={`0 0 ${size} ${size}`} width={`${size}px`} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="i0">
        <path d="M1230,0 L1230,840 L0,840 L0,0 L1230,0 Z"></path>
        </clipPath><clipPath id="i1">
        <path d="M22,0 C26.418278,-8.11624501e-16 30,3.581722 30,8 L30,22 C30,26.418278 26.418278,30 22,30 L8,30 C3.581722,30 5.41083001e-16,26.418278 0,22 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 L22,0 Z"></path></clipPath><clipPath id="i2"><path d="M1.72152528,0.295367042 L6,4.57384176 L10.2784747,0.295367042 C10.6722974,-0.0984556808 11.3108102,-0.0984556808 11.704633,0.295367042 C12.0984557,0.689189766 12.0984557,1.32770256 11.704633,1.72152528 L7.42615824,6 L11.704633,10.2784747 C12.0984557,10.6722974 12.0984557,11.3108102 11.704633,11.704633 C11.3108102,12.0984557 10.6722974,12.0984557 10.2784747,11.704633 L6,7.42615824 L1.72152528,11.704633 C1.32770256,12.0984557 0.689189766,12.0984557 0.295367042,11.704633 C-0.0984556808,11.3108102 -0.0984556808,10.6722974 0.295367042,10.2784747 L4.57384176,6 L0.295367042,1.72152528 C-0.0984556808,1.32770256 -0.0984556808,0.689189766 0.295367042,0.295367042 C0.689189766,-0.0984556808 1.32770256,-0.0984556808 1.72152528,0.295367042 Z"></path>
        </clipPath>
      </defs>
      <g transform="translate(-1150.0 -45.0)"><g clipPath="url(#i0)"><g transform="translate(1150.0 45.0)"><g clipPath="url(#i1)">
        <polygon fill={boxColor} points="0,0 30,0 30,30 0,30 0,0" stroke="none"></polygon>
        </g><g transform="translate(9.0 9.0)"><g clipPath="url(#i2)">
        <polygon fill={color} points="0,0 12,0 12,12 0,12 0,0" stroke="none">
        </polygon></g></g></g></g>
      </g>
    </svg>
  );
}