import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../../Store/Redux/store";
import {BookingType} from "../../../../../App/Store/Bookings/bookingDuck/models";
import {useState} from "react";
import {getClosestAvailableDay, getClosestStartDate} from "../../../../../App/Functions/Helpers";
import Box from "../../../../../Components/Box";
import styles from "./styles.module.scss";
import datetimeIcon from "../../../../../App/Pages/CreateNewBooking/Calendar/assets/datetime.png";
import Text from "../../../../../Components/Text";
import {Trans, t} from "@lingui/macro";
import Button from "../../../../../Components/Button";
import Divider from "../../../../../Components/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CustomBooking from "./CustomBooking";
import DailyBooking from "./DailyBooking";
import WeeklyBooking from "./WeeklyBooking";
import {setJustInTimeReservationData} from "../../../../Store/reservationJustInTime";
import {JustInTimeReservationModel} from "../../../../Store/reservationJustInTime/models";
import moment from "moment";
import { resolvePersistedDate } from "Store/Utils";

function a11yProps(index: number) {
  return {
    id: `calendar-tab-${index}`,
    'aria-controls': `calendar-tab-panel-${index}`,
  };
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`calendar-tab-${index}`}
      hidden={value !== index}
      id={`calendar-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && children}
    </div>
  );
}

// add helper function for parsing date time by done button
function getDateTimeFormat(justInTimeReservation: JustInTimeReservationModel) {
  const { bookingType, dateFrom, dateTo, timeFrom, timeTo, weeklySlots } = justInTimeReservation;

  switch (bookingType) {
    case "custom": {
      let fullDate = '';
      const startDate = moment(dateFrom).format('MMM DD');
      const isSameDate = moment(dateFrom).isSame(dateTo, 'day');
      const isSameMonth = moment(dateFrom).isSame(dateTo, 'month');
      const timeFromDate = resolvePersistedDate(timeFrom);
      const timeToDate = resolvePersistedDate(timeTo);
      const timeRange = `${moment(timeFromDate).format("HH:mm")}-${moment(timeToDate).format("HH:mm")}`;

      fullDate = `${startDate}, ${timeRange}`;

      if (!isSameDate) {
        const endDate = moment(dateTo).format('DD');
        fullDate = `${startDate}-${endDate}, ${timeRange}`;
      }

      if (!isSameMonth) {
        const endDate = moment(dateTo).format('MMM DD');
        fullDate = `${startDate} - ${endDate}, ${timeRange}`;
      }

      return fullDate;
    }
    case "daily": {
      const timeFromDate = resolvePersistedDate(timeFrom);
      const timeToDate = resolvePersistedDate(timeTo);
      const timeRange = `${moment(timeFromDate).format("HH:mm")}-${moment(timeToDate).format("HH:mm")}`;

      return `Daily, ${timeRange}`;
    }
    case "weekly": {
      if (weeklySlots?.length) {
        return weeklySlots
          .filter(({ isSelected }) => isSelected)
          .map(({ day }) => day)
          .join(", ");
      }

      return '';
    }
    default:
      return '';
  }
}

interface Props {
  onCloseModal: Function;
}

export default function ReservationDetailsCalendar(props: Props) {
  const dispatch = useDispatch();
  const { onCloseModal } = props;
  const locationDisabledDays = useTypedSelector(state => state.locations.locationDisabledDays);
  const adminReservationJustInTime = useTypedSelector(state => state.adminReservationJustInTime);
  const { bookingType, selectedData, weeklySlots } = adminReservationJustInTime;
  const dateTimeText = getDateTimeFormat(adminReservationJustInTime);

  const getBookingTypeNumber = (bookingType: BookingType): number => {
    let bookingTypeNumber = 0;

    switch (bookingType) {
      case 'daily': {
        bookingTypeNumber = 1;
        break;
      }

      case 'weekly': {
        bookingTypeNumber = 2;
        break;
      }

      default:
        break;
    }

    return bookingTypeNumber;
  };

  const [activeTab, setActiveTab] = useState(getBookingTypeNumber(bookingType));

  const handleSetActiveTab = (_: React.ChangeEvent<{}>, value: any) => {
    setActiveTab(value);

    let bookingType: BookingType = 'custom';

    switch (value) {
      case 1: {
        bookingType = 'daily';
        break;
      }

      case 2: {
        bookingType = 'weekly';
        break;
      }

      default:
        break;
    }

    dispatch(setJustInTimeReservationData({ bookingType }));
  };

  const handleResetClick = () => {
    const closestStartDay = selectedData.location ? getClosestStartDate(selectedData.location?.allowedBookingDayType) : new Date();
    const closestDay = getClosestAvailableDay(closestStartDay, locationDisabledDays);
    dispatch(setJustInTimeReservationData({
      dateFrom: closestDay,
      dateTo: closestDay,
      timeFrom: new Date(1970, 0 , 1, 9),
      timeTo: new Date(1970, 0 , 1, 18),
      weeklySlots: weeklySlots?.map((weeklySlot) => ({
        ...weeklySlot,
        isSelected: false,
        timeFrom: new Date(1970, 0 , 1, 9),
        timeTo: new Date(1970, 0 , 1, 18),
      })),
    }));
  };

  const handleDoneClick = () => {
    onCloseModal();
    dispatch(setJustInTimeReservationData({
      selectedData: {
        ...selectedData,
        dateTime: dateTimeText,
      },
    }));
  };

  return (
    <Box className={styles.calendar}>
      <Box alignItems="center" display="flex" justifyContent="between" marginBottom={8}>
        <Box alignItems="center" display="flex">
          <Box className={styles.datetimeIconContainer} marginEnd={10}>
            <img src={datetimeIcon} />
          </Box>

          <Text weight="semi-bold">
            <Trans>Date and time</Trans>
          </Text>
        </Box>

        <Box className={styles.buttons}>
          <Button noPaddingX onClick={handleResetClick} type="clear">
            <Trans>Reset</Trans>
          </Button>
          <Button noPaddingX onClick={handleDoneClick} type="primary">
            <Trans>Done</Trans>
          </Button>
        </Box>
      </Box>

      <Box marginBottom={10}>
        <Divider />
      </Box>

      <Tabs
        classes={{
          root: styles.tabs,
          indicator: styles.indicator,
          scroller: styles.scroller,
        }}
        onChange={handleSetActiveTab}
        value={activeTab}
      >
        <Tab
          classes={{
            root: styles.tab,
            selected: styles.tabSelected,
          }}
          label={t`Custom`}
          {...a11yProps(0)}
        />
        <Tab
          classes={{
            root: styles.tab,
            selected: styles.tabSelected,
          }}
          label={t`Daily`}
          {...a11yProps(1)}
        />
        <Tab
          classes={{
            root: styles.tab,
            selected: styles.tabSelected,
          }}
          label={t`Weekly`}
          {...a11yProps(2)}
        />
      </Tabs>

      <TabPanel index={0} value={activeTab}>
        <CustomBooking />
      </TabPanel>

      <TabPanel index={1} value={activeTab}>
        <DailyBooking />
      </TabPanel>

      <TabPanel index={2} value={activeTab}>
        <WeeklyBooking />
      </TabPanel>
    </Box>
  );
}