interface Props {
  color?: string;
  size?: number;
}

export default function CancelCircle({ color = '#4A4A4A', size = 25 }: React.PropsWithChildren<Props>) {
  return (
    <svg
      height={size}
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        fill="none"
        fillRule="evenodd"
        id="A-locations"
        stroke="none"
        strokeWidth="1"
      >
        <g
          id="A-Location-dashboard2"
          transform="translate(-687.000000, -148.000000)"
        >
          <g
            id="delete-white-copy-5"
            transform="translate(687.000000, 148.000000)"
          >
            <circle
              cx="12"
              cy="12"
              id="Oval"
              r="8"
              stroke={color}
              strokeWidth="2"
            ></circle>
            <path
              d="M12,8 C12.5522847,8 13,8.44771525 13,9 L13,11 L15,11 C15.5522847,11 16,11.4477153 16,12 C16,12.5522847 15.5522847,13 15,13 L13,13 L13,15 C13,15.5522847 12.5522847,16 12,16 C11.4477153,16 11,15.5522847 11,15 L11,13 L9,13 C8.44771525,13 8,12.5522847 8,12 C8,11.4477153 8.44771525,11 9,11 L11,11 L11,9 C11,8.44771525 11.4477153,8 12,8 Z"
              fill={color}
              id="Combined-Shape"
              transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
