import { SvgIcon, SvgIconProps } from "@mui/material";

export const TogetherUser: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M15 7a5 5 0 0 1 3.333 8.727A7.998 7.998 0 0 1 23 23a1 1 0 1 1-2 0 6 6 0 1 0-12 0 1 1 0 1 1-2 0 8.001 8.001 0 0 1 4.667-7.275A5 5 0 0 1 15 7Zm0 2a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"
        fill={props.fill}
        fillOpacity="1"
        fillRule="nonzero"
        transform="matrix(.94118 0 0 .94118 -6.118 -6.588)"
      />
    </SvgIcon>
  );
};
