import Api from 'Store/Services/Api';
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_SECTION_NEIGHBOURS,
  GET_SECTION_NEIGHBOURS_FAILURE,
  GET_SECTION_NEIGHBOURS_SUCCESS,
  GetSectionNeighbours,
} from '.';

function* getSectionNeighboursSaga(action: GetSectionNeighbours): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_SECTION_NEIGHBOURS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_SECTION_NEIGHBOURS_FAILURE, payload: e });
  }
}

export default [
  takeLatest(GET_SECTION_NEIGHBOURS, getSectionNeighboursSaga),
];
