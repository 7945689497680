import { ActionPayload, BaseErrorResponse, BaseResponse } from 'Store/Models/ReduxModels';
import {
  GetFloorRoomScheduleRequest,
  GetFloorRoomScheduleResponse,
  Schedule,
  SetFloorRoomScheduleDataRequest,
} from './models';
import { t } from '@lingui/macro';
import { v4 as uuidv4 } from 'uuid';

export const GET_FLOOR_ROOM_SCHEDULE = 'GET_FLOOR_ROOM_SCHEDULE';

export const GET_FLOOR_ROOM_SCHEDULE_SUCCESS = 'GET_FLOOR_ROOM_SCHEDULE_SUCCESS';

export const GET_FLOOR_ROOM_SCHEDULE_FAIL = 'GET_FLOOR_ROOM_SCHEDULE_FAIL';

export const SET_FLOOR_ROOM_SCHEDULE_DATA = 'SET_FLOOR_ROOM_SCHEDULE_DATA';

export interface GetFloorRoomSchedule {
  type: typeof GET_FLOOR_ROOM_SCHEDULE;
  payload: ActionPayload<GetFloorRoomScheduleRequest>;
}

export interface GetFloorRoomScheduleSuccess {
  type: typeof GET_FLOOR_ROOM_SCHEDULE_SUCCESS;
  payload: BaseResponse<GetFloorRoomScheduleResponse>;
}

export interface GetFloorRoomScheduleFail {
  type: typeof GET_FLOOR_ROOM_SCHEDULE_FAIL;
  payload: BaseErrorResponse;
}

export interface SetFloorRoomSchedule {
  type: typeof SET_FLOOR_ROOM_SCHEDULE_DATA;
  payload: SetFloorRoomScheduleDataRequest
}

export type Actions =
  | GetFloorRoomSchedule
  | GetFloorRoomScheduleSuccess
  | GetFloorRoomScheduleFail
  | SetFloorRoomSchedule;

export interface State {
  error: string;
  loading: boolean;
  schedules: {
    [key: string]: Schedule
  };  
}

const initialState: State = {
  error: '',
  loading: false,
  schedules: {},  
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case GET_FLOOR_ROOM_SCHEDULE:
      return {
        ...state,
        error: '',
        loading: true,
      };
    case GET_FLOOR_ROOM_SCHEDULE_SUCCESS: {      
      const data = action.payload.data.result.data.items;

      const schedules = data.reduce((acc, obj) => {
        const roomId = obj.roomId;

        return {
          ...acc,
          [roomId]: obj.schedules.map((schedule) => (
            { ...schedule,
              roomId,
              id: uuidv4(),
            })),
        };
      }, {});                

      return {
        ...state,
        error: '',
        loading: false,
        schedules: {
          ...state.schedules,
          ...schedules,
        },
      };
    }
    case GET_FLOOR_ROOM_SCHEDULE_FAIL: 
      return {
        ...state,
        error: t`There was an error loading schedules for room. Please try again.`,
        loading: false,        
      };
    case SET_FLOOR_ROOM_SCHEDULE_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

export function getFloorRoomSchedule({  
  data,
  locationId,
  floorId,  
}: {
  data: GetFloorRoomScheduleRequest,
  floorId: string,
  locationId: string  
}): GetFloorRoomSchedule {
  return {
    type: GET_FLOOR_ROOM_SCHEDULE,
    payload: {
      request: {
        method: 'GET',
        url: `/api/locations/${locationId}/floors/${floorId}/rooms/schedules`,
        data,
      },
    },
  };
}

export function setFloorRoomSchedule(data: SetFloorRoomScheduleDataRequest): SetFloorRoomSchedule {
  return {
    type: SET_FLOOR_ROOM_SCHEDULE_DATA,
    payload: data,
  };
}

export function selectSchedules(state: State): Schedule[] {
  const { schedules } = state;  

  return Object.keys(schedules).flatMap(key => schedules[key]);
} 

export function selectSchedulesByRoomId(state: State, roomId: string): Schedule [] {
  const { schedules } = state;

  return Object.keys(schedules).filter((key) => key === roomId).flatMap(key => schedules[key]);
}