import { FloorMapObject } from "../../../../../../Store/floorMapDuck/models";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../../../Store/Redux/store";
import { useEffect, useRef, useState } from "react";
import {
  deleteMeeting,
  setFloorMapData,
  updateMeeting,
} from "../../../../../../Store/floorMapDuck";
import { deleteMeetingFromFloor } from "../../../../../../Store/floorMapApiRequestsDuck";
import L from "leaflet";
import { Polygon, Popup } from "react-leaflet";
import styles from "../../MapDrawingSections/SectionObject/styles.module.scss";
import Box from "../../../../../../../Components/Box";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MeetingDeleteModal from "./DeleteMeetingModal";
import DrawingNameLabel from "../../Components/DrawingNameLabel";

interface Props {
  meeting: FloorMapObject;
  zoomLevel: number;
  floorId: string;
  editable?: boolean;
}

/**
 * Section Drawing
 *
 * This component handles deletion and edition internally.
 * When adding desks, it sends the state to the <Map /> component so it can
 * enable add desk on <CreateDesk />, associating that creation with this section id.
 *
 * React Leaflet Draw handles it's own states, so this component syncs them with internal states.
 */
export default function MeetingObject({ floorId, meeting, editable }: Props) {
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const { coordinatesInLatLngBounds } = meeting;
  const [editMeetingEnabled, setEditMeetingEnabled] = useState(false);
  const [meetingPopupOpened, setMeetingPopupOpened] = useState(false);
  const [deleteMeetingPopupOpened, setDeleteMeetingPopupOpened] = useState(false);
  const { config } = useTypedSelector(state => state);
  const [iconMarkupOpacity, setIconMarkupOpacity] = useState(1);
  const id = typeof meeting.id === 'number' ? meeting.id : undefined;

  const deactivateStates = () => {
    setEditMeetingEnabled(false);
    setMeetingPopupOpened(false);
  };

  // Updates the drawing on redux and deactivates all states
  const confirmEdition = () => {
    if (ref.current) {
      const coordinatesInLatLngBounds: L.LatLng[] = ref.current.editing.latlngs.length ? ref.current.editing.latlngs[0][0] : [];
      const coordinatesInPoints = coordinatesInLatLngBounds.map(coordinate => L.CRS.Simple.latLngToPoint(coordinate, 1));

      if (typeof id === 'number') {
        dispatch(updateMeeting({
          meetingId: id,
          meeting: {
            coordinatesInLatLngBounds,
            coordinatesInPoints,
            provisory: true,
          },
        }));
      }

      deactivateStates();
    }
  };

  useEffect(() => {
    if (ref.current) {
      if (editMeetingEnabled) {
        dispatch(setFloorMapData({
          activeMeetingId: id,
          activeDeskId: undefined,
          activeSectionId: undefined,
        }));
        ref.current.editing.enable();
      } else {
        dispatch(setFloorMapData({
          activeMeetingId: undefined,
          activeDeskId: undefined,
          activeSectionId: undefined,
        }));
        ref.current.editing.disable();
      }
    }
  }, [editMeetingEnabled]);

  // Close popup if needed when meetingPopupOpened changes
  useEffect(() => {
    if (ref.current && editable) {
      if (!meetingPopupOpened) {
        ref.current._popup.remove();
      }
    }
  }, [meetingPopupOpened]);

  // Delete drawing from redux and deactivates all states
  const onDeleteMeeting = () => {
    if (typeof id === 'number') {
      dispatch(deleteMeeting(id));
    }
    deactivateStates();
  };

  const onDeleteMeetingPermanently = () => {
    if (typeof id === 'number') {
      dispatch(deleteMeetingFromFloor({meetingId: id, floorId }));
    }
    deactivateStates();
  };

  const onSetIconMarkupOpacity = (set: boolean, e: any) => {
    const classList = e.originalEvent?.toElement?.classList || {};
    const isInnerDesk = Object.values(classList).includes('leaflet-interactive');
    return set || isInnerDesk ? setIconMarkupOpacity(0) : setIconMarkupOpacity(1);
  };

  // Set popup state to false and if the section is being edited, saves the editions
  const onPopupClose = () => {
    if (editMeetingEnabled) {
      confirmEdition();
    } else {
      setMeetingPopupOpened(false);
    }
  };

  // Set popup state to true. Since leaflet draw handles states separately, this syncs both
  const onPopupOpen = () => {
    setMeetingPopupOpened(true);
  };

  // open/close DeleteMeetingPermanently popup
  const onOpenDeleteMeetingPermanentlyPopup = () => {
    setDeleteMeetingPopupOpened(true);
  };

  const onCloseDeleteMeetingPermanentlyPopup = () => {
    setDeleteMeetingPopupOpened(false);
  };

  // Toggle edit meeting functionality
  const toggleEditMeetingEnabled = () => {
    setEditMeetingEnabled(prevEditMeetingEnabled => !prevEditMeetingEnabled);
  };

  const arrayOfCoordinatesInLatLng = coordinatesInLatLngBounds.map(coordinate => L.latLng(coordinate.lat, coordinate.lng));

  return (
    <Polygon
      className="floor-map-details-section"
      eventHandlers={{
        popupclose: onPopupClose,
        popupopen: onPopupOpen,
        mouseover: (e) => onSetIconMarkupOpacity(true, e),
        mouseout: (e) => onSetIconMarkupOpacity(false, e),
      }}
      pathOptions={{
        color: meeting.line,
        fillColor: meeting.line,
      }}
      positions={arrayOfCoordinatesInLatLng}
      ref={ref}
    >
      <DrawingNameLabel
        drawing={meeting}
        iconMarkupOpacity={iconMarkupOpacity}
        sectionLabelKey={1}
        sectionRef={ref.current}
      />

      {editable &&
        <Popup
          className={styles.popupContainer}
          closeButton={false}
        >
          {editMeetingEnabled ? (
            <Box alignItems="center" display="flex">
              <IconButton
                onClick={confirmEdition}
                size="small"
                style={{ borderRadius: 6, backgroundColor: '#5BC535', padding: 0 }}
              >
                <CheckIcon
                  style={{
                    color: "#fff",
                    fontSize: 20,
                  }}
                />
              </IconButton>
            </Box>
          ) : (
            <Box alignItems="center" display="flex">
              <IconButton
                onClick={toggleEditMeetingEnabled}
                size="small"
                style={{ marginInlineStart: 10, padding: 0 }}
              >
                <CreateOutlinedIcon
                  style={{
                    color: config.theme.primary,
                    fontSize: 20,
                  }}
                />
              </IconButton>

              {(meeting.saved) &&
                <IconButton
                  onClick={onOpenDeleteMeetingPermanentlyPopup}
                  size="small"
                  style={{ marginInlineStart: 10, padding: 0 }}
                >
                  <DeleteOutlineIcon
                    style={{
                      color: config.theme.primary,
                      fontSize: 20,
                    }}
                  />
                </IconButton>
              }

              {(!meeting.saved) &&
                <IconButton
                  onClick={onDeleteMeeting}
                  size="small"
                  style={{ marginInlineStart: 10, padding: 0 }}
                >
                  <DeleteOutlineIcon
                    style={{
                      color: config.theme.primary,
                      fontSize: 20,
                    }}
                  />
                </IconButton>
              }
            </Box>
          )}
        </Popup>
      }
      <MeetingDeleteModal
        close={onCloseDeleteMeetingPermanentlyPopup}
        meeting={meeting}
        onDelete={onDeleteMeetingPermanently}
        open={deleteMeetingPopupOpened}
      />
    </Polygon>
  );
}
