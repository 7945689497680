import { Order } from "types";
import { reduce } from "underscore";

export const resolveArrayOrder = (array?: number[] | string[]): Order | undefined => {
  if (array?.length) {
    const ordered = [...array].sort();
    const asc = reduce(array, (carry, current, index) => carry && ordered[index] === current, true);

    if (asc) {
      return "asc";
    }

    const reversed = [...ordered].reverse();
    const desc = reduce(array, (carry, current, index) => carry && reversed[index] === current, true);

    if (desc) {
      return "desc";
    }
  }

  return undefined;
};
