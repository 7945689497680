import React from "react";
import { WeeklyCalendarViewScheduleProps } from "./types";
import { Box, Tooltip, Typography } from "@mui/material";
import { resolveCalendarViewScheduleInterval, resolveCalendarViewScheduleSpan, resolveCalendarViewScheduleSummary } from "./utils";
import { isAfter, isBefore } from "date-fns";

export const WeeklyCalendarViewSchedule: React.FC<WeeklyCalendarViewScheduleProps> = (props) => {
  const {
    startDate,
    endDate,
    step,
    stepHeight,
    firstStepDate,
    lastStepDate,
    summary,
  } = props;
  const scheduleInterval = resolveCalendarViewScheduleInterval(startDate, endDate, step, firstStepDate, lastStepDate);
  const top = resolveCalendarViewScheduleSpan(startDate, firstStepDate, step, firstStepDate) * stepHeight;
  const height =  scheduleInterval * stepHeight;
  const resolvedSummary = resolveCalendarViewScheduleSummary(summary, 1);
  const isBeforeFirstStep = isBefore(startDate, firstStepDate);
  const isAfterLastStep = isAfter(endDate, lastStepDate);
  let borderRadius = "0px";

  if (!isBeforeFirstStep && !isAfterLastStep) {
    borderRadius = "8px";
  } else if (isBeforeFirstStep && !isAfterLastStep) {
    borderRadius = "0px 0px 8px 8px";
  } else if (isAfterLastStep && !isBeforeFirstStep) {
    borderRadius = "8px 8px 0px 0px";
  }

  return (
    <Tooltip followCursor title={summary}>
      <Box
        alignItems="center"
        bgcolor="#FFE4DB"
        borderRadius={borderRadius}
        display="flex"
        height={height}
        justifyContent="center"
        left={1}
        padding={2}
        position="absolute"
        top={top}
        width="calc(100% - 1px)"
        zIndex={10}
      >
        <Typography noWrap>{resolvedSummary}</Typography>
      </Box>
    </Tooltip>
  );
};
