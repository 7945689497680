import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import { weakStart } from 'mocks/browser';

TagManager.initialize({
  gtmId: 'GTM-P3HMPNF',
});

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser');

  // This enables the mocks but doesn't start them by default
  // To start the mocks, on browser console, run:
  // window.mocks.start()
  // window.mocks.stop()
  // After starting, if the page refreshes, the mocks will stop again
  weakStart(worker);
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
