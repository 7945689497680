import React, { useEffect } from "react";
import { NotificationsListProps } from "./types";
import { useLazyGetMyNotificationHistoryQuery } from "store";
import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import { t } from "@lingui/macro";

export const NotificationsList: React.FC<NotificationsListProps> = (props) => {
  const { limit } = props;
  const { palette } = useTheme();
  const [triggerGetMyNotificationHistoryQuery, getMyNotificationHistoryQuery] = useLazyGetMyNotificationHistoryQuery();
  const { data: getMyNotificationHistoryResponse, isLoading } = getMyNotificationHistoryQuery;
  const { notifications } = getMyNotificationHistoryResponse?.result?.data || {};

  useEffect(() => {
    triggerGetMyNotificationHistoryQuery({ limit }, true);
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      {
        isLoading ? (
          <>
            <Skeleton height={100} sx={{ mb: 2 }} variant="rectangular" />
            <Skeleton height={100} variant="rectangular" />
          </>
        ) : (
          notifications?.length ? (
            notifications?.map(({ id, subject, message, sendDate }, index) => (
              <Box bgcolor={palette.grey[100]} borderRadius={2} key={id} mb={index === notifications.length - 1 ? 0 : 2} p={2}>
                <Typography fontSize={14} fontWeight="600">{subject}</Typography>
                <Typography fontSize={14} mb={1}>{format(new Date(sendDate), "MMM d, h:mm a")}</Typography>
                <Typography color={palette.grey[700]} fontSize={14}>{message}</Typography>
              </Box>
            ))
          ) : (
            <Typography fontSize={14} my={2} textAlign="center">{t`No notifications`}</Typography>
          )
        )
      }
    </Box>
  );
};
