import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../../../../Store/Services/Api";
import {
  GET_SELECTED_USER_ACTUAL_DOCUMENT,
  GET_SELECTED_USER_ACTUAL_DOCUMENT_FAIL,
  GET_SELECTED_USER_ACTUAL_DOCUMENT_SUCCESS,
  GetSelectedUserActualDocument,
} from "./index";

function* getSelectedUserActualDocumentSaga(action: GetSelectedUserActualDocument): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_SELECTED_USER_ACTUAL_DOCUMENT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_SELECTED_USER_ACTUAL_DOCUMENT_FAIL, payload: e });
  }
}

export default [
  takeLatest(GET_SELECTED_USER_ACTUAL_DOCUMENT, getSelectedUserActualDocumentSaga),
];
