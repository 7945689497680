import { FloorMapObject, MapDrawingGroup } from "../../../../../../Store/floorMapDuck/models";
import { useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useClickOutside } from "../../../../../../../Functions/hooks";
import styles from "./styles.module.scss";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { setGroupsData } from "../../../../../../Store/groups";
import GroupSearch from "./GroupSearch";
import GroupList from "./GroupList";

interface Props {
  drawingObject: FloorMapObject; // TODO - in future use separate models for desk/section/meeting object
  onUpdate: (groups: MapDrawingGroup[]) => void;
}

export default function GroupsMultiSelect(props: Props) {
  const dispatch = useDispatch();
  const groupsWrapperRef = useRef<any>(null);
  const { drawingObject, onUpdate } = props;

  const [searchText, setSearchText] = useState('');
  const [isOpenGroupsList, setIsOpenGroupsList] = useState(false);

  const onResetSearchState = () => {
    dispatch(setGroupsData({
      groupsList: [],
    }));
  };

  const onCloseGroupsList = () => {
    setIsOpenGroupsList(false);
    setSearchText(''); // clear search field on close dropdown
    onResetSearchState(); // clear user list on close dropdown
  };

  const onGroupsUpdate = (groups: MapDrawingGroup[]) => {
    onUpdate(groups);
  };

  useEffect(() => {
    onResetSearchState(); // clear user list on load
  }, []);

  // close list by outside click
  useClickOutside(groupsWrapperRef, onCloseGroupsList);

  return (
    <div
      className={styles.groups}
      ref={groupsWrapperRef}
    >
      <GroupSearch
        drawingObject={drawingObject}
        isOpenGroupList={isOpenGroupsList}
        onGroupsUpdate={onGroupsUpdate}
        searchText={searchText}
        setIsOpenGroupList={setIsOpenGroupsList}
        setSearchText={setSearchText}
      />

      <Accordion
        classes={{expanded: styles.accordionExpanded, root: styles.accordion}}
        expanded={isOpenGroupsList}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          classes={{
            content: styles.accordionSummaryContent,
            expandIcon: styles.accordionSummaryIcon,
            root: styles.accordionSummary,
          }}
        >
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: styles.accordionDetails,
          }}
        >
          <GroupList
            drawingObject={drawingObject}
            onGroupsUpdate={onGroupsUpdate}
            searchText={searchText}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
