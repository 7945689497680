import { createTransform } from "redux-persist";
import { CreateNewMeetingModel } from "./models";
import { differenceInCalendarDays, format, set } from "date-fns";

const now = new Date();

const resolvePastDate = (dateOrString: Date | string): Date => {
  const date = dateOrString instanceof Date ? dateOrString : new Date(dateOrString);
    
  if (differenceInCalendarDays(new Date(format(date, "yyyy-MM-dd")), new Date(format(now, "yyyy-MM-dd"))) < 0) {
    const newDate = set(now, {
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),
      milliseconds: date.getMilliseconds(),
    });

    return newDate;
  }

  return date;
};

export const removeMeetingReservationPastDates = createTransform<unknown, CreateNewMeetingModel>(undefined, (state, key) => {
  if (key === "createNewMeeting") {
    if (state.dateFrom) {
      state.dateFrom = resolvePastDate(state.dateFrom);
    }
  
    if (state.dateTo) {
      state.dateTo = resolvePastDate(state.dateTo);
    }
  }

  return state;
});
