import Api from 'Store/Services/Api';
import {
  call,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_FAIL,
  CREATE_NOTIFICATION_SUCCESS,
  CreateNotification,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION_SUCCESS,
  DeleteNotification,
  EDIT_NOTIFICATION,
  EDIT_NOTIFICATION_FAIL,
  EDIT_NOTIFICATION_SUCCESS,
  EditNotification,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
  GetNotifications,
  SEND_NOTIFICATION,
  SEND_NOTIFICATION_FAIL,
  SEND_NOTIFICATION_SUCCESS,
  SendNotification,
} from "./index";

function* getNotifications(action: GetNotifications): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: GET_NOTIFICATIONS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: GET_NOTIFICATIONS_FAIL, payload: e });
  }
}

function* createNotificationSaga(action: CreateNotification): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: CREATE_NOTIFICATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: CREATE_NOTIFICATION_FAIL, payload: e });
  }
}

function* editNotificationSaga(action: EditNotification): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: EDIT_NOTIFICATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: EDIT_NOTIFICATION_FAIL, payload: e });
  }
}

function* deleteNotificationSaga(action: DeleteNotification): any {
  try {
    const payload = yield call(Api, action);
    yield put({ type: DELETE_NOTIFICATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: DELETE_NOTIFICATION_FAIL, payload: e });
  }
}

function* sendNotificationSaga(action: SendNotification): any {
  try {
    const payload = yield call(Api, action);

    yield put({ type: SEND_NOTIFICATION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: SEND_NOTIFICATION_FAIL, payload: e });
  }
}

export default [
  takeEvery(GET_NOTIFICATIONS, getNotifications),
  takeLatest(CREATE_NOTIFICATION, createNotificationSaga),
  takeLatest(EDIT_NOTIFICATION, editNotificationSaga),
  takeLatest(DELETE_NOTIFICATION, deleteNotificationSaga),
  takeLatest(SEND_NOTIFICATION, sendNotificationSaga),
];
