import Box from 'Components/Box';
import CheckboxCustom from 'Components/CheckBoxCustom';
import clsx from 'clsx';
import SearchIcon from 'Components/Icons/Search';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import { FormControlLabel, TextField } from '@material-ui/core';
import {initialFilters, selectUserManagementCities, setUserManagementData} from 'Admin/Store/userManagement';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import FilterAccordion from "../../../../Components/Filters/FilterAccordion";

export default function CitiesFilter() {
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const { adminUserManagement } = useTypedSelector(state => state);

  const { filters: reduxFilters } = adminUserManagement;

  const citiesCount = reduxFilters.cities.length;
  const cities = selectUserManagementCities(adminUserManagement, search);

  const onResetFilter = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
    event?.stopPropagation();

    dispatch(setUserManagementData({
      filters: {
        ...reduxFilters,
        cities: initialFilters.cities,
      },
    }));

    setSearch('');
  };

  return (
    <Box display="flex" justifyContent="between" marginTop={10}>
      <FilterAccordion
        headerName={t`City`}
        localCounter={citiesCount}
        resetFilter={onResetFilter}
      >
        <Box direction="column" display="flex" width="100%">
          <TextField
            InputProps={{
              startAdornment: <SearchIcon color="#5E5E5E" />,
            }}
            className={`input input--default input--search ${styles.search}`}
            defaultValue={''}
            fullWidth
            onChange={(event) => setSearch(event.target.value)}
            placeholder="Search"
            value={search}
            variant="outlined"
          />

          <Box className={styles.filtersFormControlWrapper} display="grid" >
            {cities.length ? (
              <>
                {cities.map(((city, index) => {
                  const isChecked = adminUserManagement.filters.cities.some(c => c === city);

                  return (
                    <FormControlLabel
                      className={clsx(isChecked && styles.checkedLabel)}
                      control={(
                        <CheckboxCustom
                          checked={isChecked}
                          name={city}
                          onChange={() => {
                            let newCities = [...reduxFilters.cities].filter(c => c === city);

                            if (isChecked) {
                              newCities = newCities.filter(c => c !== city);
                            } else {
                              newCities.push(city);
                            }

                            dispatch(setUserManagementData({
                              filters: {
                                ...reduxFilters,
                                cities: newCities,
                              },
                            }));
                          }}
                        />
                      )}
                      key={`${city} ${index}`}
                      label={city}
                    />
                  );
                }))}
              </>
            ) : (
              <Text align="center" color="lightGray" size="md">
                <Trans>
                  No cities found
                </Trans>
              </Text>
            )}
          </Box>
        </Box>
      </FilterAccordion>
    </Box>
  );
}
