import {t} from '@lingui/macro';
import { ActionPayload, BaseErrorResponse, BaseResponse } from '../../../Store/Models/ReduxModels';
import {
  GetCertificatesByUserRequest,
  GetCertificatesByUserResponse,
  SetCovidCertificateDataRequest,
  UpdateVaccinationDocumentRequest,
  UpdateVaccinationDocumentRequestBody,
  UpdateVaccinationDocumentResponse,
  UploadCovidCertificateRequest,
  UploadCovidCertificateResponse,
} from './models';
import { compareDateFormat } from '../../../Admin/Pages/Calendar/AdminCalendar/Helpers/Helpers';
import {UserDocument} from "../../../Admin/Store/userManagement/models";
import {APPROVE_OR_DENY_USER_DOCUMENT_SUCCESS, ApproveOrDenyUserDocumentSuccess} from "../../../Admin/Store/userManagement";

export const SET_COVID_CERTIFICATE_DATA = 'SET_COVID_CERTIFICATE_DATA';

export const UPLOAD_COVID_CERTIFICATE = 'UPLOAD_COVID_CERTIFICATE';
export const UPLOAD_COVID_CERTIFICATE_FAIL = 'UPLOAD_COVID_CERTIFICATE_FAIL';
export const UPLOAD_COVID_CERTIFICATE_SUCCESS = 'UPLOAD_COVID_CERTIFICATE_SUCCESS';

export const GET_CERTIFICATES_BY_USER = 'GET_CERTIFICATES_BY_USER';
export const GET_CERTIFICATES_BY_USER_FAIL = 'GET_CERTIFICATES_BY_USER_FAIL';
export const GET_CERTIFICATES_BY_USER_SUCCESS = 'GET_CERTIFICATES_BY_USER_SUCCESS';

export const UPDATE_COVID_CERTIFICATE = 'UPDATE_COVID_CERTIFICATE';
export const UPDATE_COVID_CERTIFICATE_SUCCESS = 'UPDATE_COVID_CERTIFICATE_SUCCESS';
export const UPDATE_COVID_CERTIFICATE_FAIL = 'UPDATE_COVID_CERTIFICATE_FAIL';

// set any data
export interface SetCovidCertificateData {
  type: typeof SET_COVID_CERTIFICATE_DATA;
  payload: SetCovidCertificateDataRequest;
}

// upload file
export interface UploadCovidCertificate {
  type: typeof UPLOAD_COVID_CERTIFICATE;
  payload: ActionPayload<FormData>;
}
export interface UploadCovidCertificateFail {
  type: typeof UPLOAD_COVID_CERTIFICATE_FAIL;
  payload: BaseErrorResponse;
}
export interface UploadCovidCertificateSuccess {
  type: typeof UPLOAD_COVID_CERTIFICATE_SUCCESS;
  payload: BaseResponse<UploadCovidCertificateResponse>;
}

// get certificates by user
export interface GetCertificatesByUser {
  type: typeof GET_CERTIFICATES_BY_USER;
  payload: ActionPayload<GetCertificatesByUserRequest>;
}
export interface GetCertificatesByUserFail {
  type: typeof GET_CERTIFICATES_BY_USER_FAIL;
  payload: BaseErrorResponse;
}
export interface GetCertificatesByUserSuccess {
  type: typeof GET_CERTIFICATES_BY_USER_SUCCESS;
  payload: BaseResponse<GetCertificatesByUserResponse>;
}

// update certificate data
export interface UpdateCovidCertificate {
  type: typeof UPDATE_COVID_CERTIFICATE;
  payload: ActionPayload<UpdateVaccinationDocumentRequestBody>
}
export interface UpdateCovidCertificateSuccess {
  type: typeof UPDATE_COVID_CERTIFICATE_SUCCESS;
  payload: BaseResponse<UpdateVaccinationDocumentResponse>
}
export interface UpdateCovidCertificateFail {
  type: typeof UPDATE_COVID_CERTIFICATE_FAIL;
  payload: BaseErrorResponse;
}

export type Actions =
  | SetCovidCertificateData
  | UploadCovidCertificate
  | UploadCovidCertificateFail
  | UploadCovidCertificateSuccess
  | GetCertificatesByUser
  | GetCertificatesByUserFail
  | GetCertificatesByUserSuccess
  | UpdateCovidCertificate
  | UpdateCovidCertificateFail
  | UpdateCovidCertificateSuccess
  | ApproveOrDenyUserDocumentSuccess

export interface State {
  error: string;
  loading: boolean;
  certificateLoaded: boolean;
  successMessage: string
  userDocuments: UserDocument[];
  totalUserDocumentsPages: number;
}

const initialState: State = {
  error: '',
  loading: false,
  certificateLoaded: false,
  successMessage: '',
  userDocuments: [],
  totalUserDocumentsPages: 1,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case UPLOAD_COVID_CERTIFICATE:
      return {
        ...state,
        certificateLoaded: true,
      };
    case UPLOAD_COVID_CERTIFICATE_FAIL:
      return {
        ...state,
        certificateLoaded: false,
        error: t`There was an error adding user covid certificate. Please try again.`,
      };
    case UPLOAD_COVID_CERTIFICATE_SUCCESS: {
      const newDocument = action.payload.data.result.data;

      return {
        ...state,
        certificateLoaded: false,
        userDocuments: [newDocument, ...state.userDocuments],
        successMessage: t`Covid document was uploaded successfully.`,
      };
    }

    case GET_CERTIFICATES_BY_USER:
      return {
        ...state,
        loading: true,
      };
    case GET_CERTIFICATES_BY_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: t`There was an error loading user list of certificates. Please try again.`,
      };
    case GET_CERTIFICATES_BY_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        userDocuments: action.payload.data.result.data.documents,
        totalUserDocumentsPages: action.payload.data.result.data.totalPages,
      };
    }

    case UPDATE_COVID_CERTIFICATE: {
      return {
        ...state,
        error: '',
      };
    }
    case UPDATE_COVID_CERTIFICATE_SUCCESS: {
      return {
        ...state,
        error: '',
        userDocuments: state.userDocuments.map(document => {
          if (document.id === action.payload.data.result.data.id) {
            document = {
              ...document,
              ...action.payload.data.result.data,
            };
          }

          return document;
        }),
      };
    }
    case UPDATE_COVID_CERTIFICATE_FAIL: {
      return {
        ...state,
        error: t`There was an error updating vaccination document`,
      };
    }

    case APPROVE_OR_DENY_USER_DOCUMENT_SUCCESS: {
      const updatedDocument = action.payload.data.result.data;

      return {
        ...state,
        userDocuments: state.userDocuments.map(document => {
          if (document.id === updatedDocument.id) {
            return updatedDocument;
          }

          return document;
        }),
      };
    }

    case SET_COVID_CERTIFICATE_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

// actions
export function setCovidCertificateData(data: SetCovidCertificateDataRequest): SetCovidCertificateData {
  return {
    type: SET_COVID_CERTIFICATE_DATA,
    payload: data,
  };
}

export function uploadCovidCertificate(data: UploadCovidCertificateRequest): UploadCovidCertificate {
  const formData = new FormData();

  formData.append('type', data.type);
  formData.append('file', data.file);
  formData.append('vaccinationDate', compareDateFormat(data.vaccinationDate, true));

  if (data.selectedUserId) {
    formData.append('selectedUserId', data.selectedUserId);
  }

  return {
    type: UPLOAD_COVID_CERTIFICATE,
    payload: {
      request: {
        method: 'POST',
        url: `/api/users/upload-certificate`,
        data: formData,
      },
    },
  };
}

export function getCertificatesByUser(data: GetCertificatesByUserRequest): GetCertificatesByUser {
  return {
    type: GET_CERTIFICATES_BY_USER,
    payload: {
      request: {
        method: 'GET',
        url: `/api/users/${data.userId}/documents?page=${data.page}`,
      },
    },
  };
}

export function updateCovidCertificate(data: UpdateVaccinationDocumentRequest): UpdateCovidCertificate {
  return {
    type: UPDATE_COVID_CERTIFICATE,
    payload: {
      request: {
        method: 'PUT',
        url: `/api/users/document/${data.params.documentId}`,
        data: data.body,
      },
    },
  };
}
