import AdminAttributesSagas from '../../Admin/Store/attributes/sagas';
import AdminCalendarSagas from '../../Admin/Store/calendar/sagas';
import AdminCovidQuestionsSagas from '../../Admin/Store/covidQuestions/sagas';
import AdminFloorMapApiRequestsSagas from '../../Admin/Store/floorMapApiRequestsDuck/sagas';
import AdminNotificationsSagas from '../../Admin/Store/notifications/sagas';
import AdminReservationsSagas from '../../Admin/Store/reservations/sagas';
import AdminSettingsMobileSagas from '../../Admin/Store/settingsMobile/sagas';
import AdminUserManagementSagas from '../../Admin/Store/userManagement/sagas';
import AdminVisitsSagas from '../../Admin/Store/visits/sagas';
import AvailableDesksSagas from 'App/Store/Bookings/availableDesksDuck/sagas';
import BookingApprovalSagas from 'BookingApproval/Store/bookingApproval/sagas';
import BookingSagas from 'App/Store/Bookings/bookingDuck/sagas';
import CheckinSagas from 'App/Store/Bookings/checkinDuck/sagas';
import DeskSagas from 'App/Store/Desk/deskDuck/sagas';
import GroupsSagas from 'Admin/Store/groups/sagas';
import GroupDetailsSagas from 'Admin/Store/groupDetails/sagas';
import EmailTemplatesSagas from 'Admin/Store/emailTemplatesDuck/sagas';
import ExecutiveAssistantSagas from 'App/Store/Users/executiveAssistant/sagas';
import GlobalNotificationsSagas from 'Admin/Store/globalNotifications/sagas';
import LocationDashboardSagas from 'Admin/Store/locationDashboardDuck/sagas';
import LocationsDetailsSagas from 'App/Store/Locations/locationsDetailsDuck/sagas';
import LocationsResourcesSagas from 'App/Store/Resources/locationsResourcesDuck/sagas';
import LocationsSagas from 'App/Store/Locations/locationsDuck/sagas';
import LoginSagas from 'App/Store/Auth/loginDuck/sagas';
import ParkingSagas from 'Admin/Store/parking/sagas';
import ProfileSagas from 'App/Store/Users/profileDuck/sagas';
import CovidCertificateSagas from 'App/Store/CovidSertificate/sagas';
import PushNotificationsSagas from 'App/Store/Notifications/sagas';
import QuestionnaireSagas from 'App/Store/Questionnaire/sagas';
import SectionNeighborsSagas from 'App/Store/Bookings/sectionNeighbors/sagas';
import RoomSagas from 'App/Store/Meetings/meetingRooms/sagas';
import RoomScheduleSagas from 'App/Store/Meetings/meetingSchedule/sagas';
import RoomsManagementSagas from 'Admin/Store/roomsManagement/sagas';
import RoomsImagesSagas from 'Admin/Store/roomsImages/sagas';
import MeetingReservationsSagas from 'App/Store/Meetings/meetingReservations/sagas';
import MeetingAttendeesSagas from 'App/Store/Meetings/meetingAttendees/sagas';
import { all } from 'redux-saga/effects';

const mySaga = function* rootSaga() {
  yield all([
    ...AdminAttributesSagas,    
    ...AdminCalendarSagas,
    ...AdminCovidQuestionsSagas,
    ...AdminFloorMapApiRequestsSagas,
    ...AdminNotificationsSagas,
    ...AdminSettingsMobileSagas,
    ...AdminReservationsSagas,
    ...AdminUserManagementSagas,
    ...AdminVisitsSagas,
    ...AvailableDesksSagas,
    ...BookingApprovalSagas,
    ...BookingSagas,
    ...CheckinSagas,
    ...DeskSagas,
    ...GroupsSagas,
    ...GroupDetailsSagas,
    ...EmailTemplatesSagas,
    ...ExecutiveAssistantSagas,
    ...GlobalNotificationsSagas,
    ...LocationDashboardSagas,
    ...LocationsDetailsSagas,
    ...LocationsResourcesSagas,
    ...LocationsSagas,
    ...LoginSagas,
    ...ParkingSagas,
    ...ProfileSagas,
    ...CovidCertificateSagas,
    ...PushNotificationsSagas,
    ...QuestionnaireSagas,
    ...SectionNeighborsSagas,
    ...RoomSagas,
    ...RoomScheduleSagas,
    ...RoomsManagementSagas,
    ...RoomsImagesSagas,
    ...MeetingReservationsSagas,
    ...MeetingAttendeesSagas,
  ]);
};

export default mySaga;
