import { t } from "@lingui/macro";
import { MoreVert } from "@mui/icons-material";
import { Box, Button, ButtonBase, ButtonProps, Popover, Typography, useTheme } from "@mui/material";
import { useTypedSelector } from "Store/Redux/store";
import { TogetherDesk, TogetherRoom, TogetherVisit } from "../icons";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const MenuItem: React.FC<{
  title: string;
  subtitle: string;
  to: string;
  icon: React.ReactElement;
  mb?: number;
  disabled?: boolean;
}> = ({ title, subtitle, to, icon, mb, disabled }) => {
  const { palette } = useTheme();

  return (
    <ButtonBase
      component={Link}
      disabled={disabled}
      sx={{
        mb,
        padding: 1,
        bgcolor: palette.grey[100],
        display: "flex",
        textAlign: "left",
        justifyContent: "start",
        borderRadius: 2,
        transition: "background-color .2s",
        ":hover": {
          bgcolor: palette.grey[200],
        },
        opacity: disabled ? 0.5 : undefined,
      }}
      to={to}
    >
      <Box
        alignItems="center"
        bgcolor={disabled ? palette.grey[500] : palette.primary.main}
        borderRadius={2}
        display="flex"
        height={44}
        justifyContent="center"
        mr={1}
        padding={1}
        width={44}
      >
        {icon}
      </Box>
      <Box>
        <Typography fontSize={14} fontWeight="600">{title}</Typography>
        <Typography color={palette.grey[700]} fontSize={14}>{subtitle}</Typography>
      </Box>
    </ButtonBase>
  );
};

export const ReservationCreationButton: React.FC<ButtonProps> = (props) => {
  const { children, ...rest } = props;
  const selectedUserId = useTypedSelector((state) => state.executiveAssistant.selectedUser?.id); 
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <Button endIcon={<MoreVert />} onClick={(event) => setAnchorEl(event.currentTarget)} variant="contained" {...rest}>
        {children || t`Create booking`}
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setAnchorEl(null)}
        open={!!anchorEl}
        sx={{ mt: 1 }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box alignItems="stretch" display="flex" flexDirection="column" padding={2} width={300}>
          <MenuItem icon={<TogetherDesk stroke="white" />} mb={1} subtitle={t`Book desk`} title={t`Desk`} to="/create-new-booking/select-location" />
          <MenuItem
            icon={<TogetherVisit stroke="white" />}
            mb={1}
            subtitle={t`Book visit inside building`}
            title={t`Visit onsite`}
            to="/create-new-visit/onsite/select-location"
          />
          <MenuItem
            disabled={!!selectedUserId} 
            icon={<TogetherRoom stroke="white" />} 
            subtitle={t`Create meeting`}
            title={t`Meeting room`}
            to="/reservations/locations?reset=true"
          />
        </Box>
      </Popover>
    </>
  );
};
