import L from "leaflet";
import { FloorMapObjectTypeEnum, ICoordinate } from "../../../../../Store/floorMapDuck/models";
import {
  State as AdminFloorMap,
  addDesk,
  selectSectionsThatIntersectsBounds,
  setFloorMapData,
} from "../../../../../Store/floorMapDuck";
import { t } from "@lingui/macro";
import { DESK_COLOR } from "../../Helpers/consts";
import { v4 as uuidv4 } from "uuid";
import { DeskStatusEnum } from "../../../../../Store/floorMapDuck/desk/models";

export interface addDeskToPositionProps {
  position: L.LatLng;
  adminFloorMap: AdminFloorMap;
  dispatch: Function;
}

// Add a desk to the position in L.LatLng, with a fixed size
export const addDeskToPosition = (props: addDeskToPositionProps): void => {
  const {position, adminFloorMap, dispatch} = props;
  const {
    deskSize,
    drawDeskCount,
    sections: sectionsObject,
  } = adminFloorMap;
  // Transform the objects with sections into array
  const sectionsArray = Object.keys(sectionsObject).map(key => sectionsObject[parseInt(key)]);

  const { x, y } = L.CRS.Simple.latLngToPoint(position, 1);

  const corner1 = {
    x: x - deskSize.width,
    y: y - deskSize.height,
  };
  const corner2 = {
    x: x + deskSize.width,
    y: y - deskSize.height,
  };
  const corner3 = {
    x: x + deskSize.width,
    y: y + deskSize.height,
  };
  const corner4 = {
    x: x - deskSize.width,
    y: y + deskSize.height,
  };

  // Create array of all 4 coordinates in points
  const coordinatesInPoints: ICoordinate[] = [corner1, corner2, corner3, corner4];
  const coordinatesInLatLngBounds = coordinatesInPoints.map(coordinate => L.CRS.Simple.pointToLatLng(L.point(coordinate.x, coordinate.y), 1));

  // Find the section that this desk is inside, if there's one
  const sections = selectSectionsThatIntersectsBounds(sectionsArray, coordinatesInLatLngBounds);

  // This makes sure that a desk doesn't overlap two sections
  if (sections.length > 1) {
    dispatch(setFloorMapData({ errorMessage: t`A desk can't overlap two different sections` }));
  } else {
    const section = sections[0];

    dispatch(addDesk({
      approvers: {
        approver1: {
          email: '',
        },
        approver2: {
          email: '',
        },
      },
      resourceIds: {},
      coordinatesInLatLngBounds,
      coordinatesInPoints,
      fill: DESK_COLOR,
      line: DESK_COLOR,
      id: uuidv4(),
      name: `Desk-${drawDeskCount + 1}`,
      owners: [],
      groups: [],
      status: DeskStatusEnum.Available,
      parentId: section && typeof section.id === 'number' ? section.id : undefined,
      provisory: true,
      hasError: false,
      saved: false,
      type: FloorMapObjectTypeEnum.Desk,
    }));
  }
};
