import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { getApiUrl } from "Store/Models/ConfigModels";
import { ReduxStore } from "Store/Redux";
import { ApiTag } from "./enums";

type PrepareAuthorizationHeadersF = NonNullable<Parameters<typeof fetchBaseQuery>[0]>["prepareHeaders"];

const prepareAuthorizationHeaders: PrepareAuthorizationHeadersF = (headers, { getState }) => {
  const state = getState() as ReduxStore;
  const { token, tokenGraphApi } = state.login || {};

  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }

  if (tokenGraphApi) {
    headers.set("Graph-Token", tokenGraphApi);
  }

  return headers;
};

const baseQuery: typeof fetchBaseQuery = (params) => retry(
  fetchBaseQuery({ baseUrl: getApiUrl(), prepareHeaders: prepareAuthorizationHeaders, ...params }),
  { maxRetries: 3 },
);

export const togetherApi = createApi({
  reducerPath: "togetherApi",
  tagTypes: [
    ApiTag.BOOKING,
    ApiTag.FLOOR,
    ApiTag.GROUP,
    ApiTag.LOCATION,
    ApiTag.RESERVATION,
    ApiTag.ROOM,
    ApiTag.ROOM_SCHEDULE,
    ApiTag.USER_SAVED_DESK,
    ApiTag.USER_HOME_COUNTS,
  ],
  baseQuery: baseQuery(),
  endpoints: () => ({}),
});
