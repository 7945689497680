interface Props {
  backgroundColor?: string;
  className?: string;
  color?: string;
  size?: number;
}

export default function Calendar({
  backgroundColor = '#f1f8ff',
  className,
  color = '#4A4A4A',
  size = 25,
}: Props) {
  return (
    <svg className={className} height={size} width={size}>
      <g enableBackground="new">
        <clipPath id="cp0">
          <path
            d="M 8 30 L 22 30 C 26.41828 30 30 26.41828 30 22 L 30 8 C 30 3.581722 26.41828 0 22 0 L 8 0 C 3.581722 0 0 3.581722 0 8 L 0 22 C 0 26.41828 3.581722 30 8 30 Z "
            fillRule="evenodd"
            transform="matrix(1,0,0,-1,0,30)"
          />
        </clipPath>
        
        <g clipPath="url(#cp0)">
          <clipPath id="cp1">
            <path
              d="M -840 -558 L 600 -558 L 600 342 L -840 342 Z "
              transform="matrix(1,0,0,-1,0,30)"
            />
          </clipPath>

          <g clipPath="url(#cp1)">
            <path
              d="M -1 31 L 31 31 L 31 -1 L -1 -1 Z "
              fill={backgroundColor}
              transform="matrix(1,0,0,-1,0,30)"
            />
          </g>
        </g>

        <clipPath id="cp2">
          <path
            d="M 20 24 C 20.55228 24 21 23.55228 21 23 L 21 22 L 23 22 L 23 9 C 23 7.343146 21.65685 6 20 6 L 10 6 C 8.343146 6 7 7.343146 7 9 L 7 22 L 9 22 L 9 23 C 9 23.55228 9.447715 24 10 24 C 10.55228 24 11 23.55228 11 23 L 11 22 L 19 22 L 19 23 C 19 23.55228 19.44772 24 20 24 Z M 21 17 L 9 17 L 9 9 C 9 8.487164 9.38604 8.064493 9.883379 8.006728 L 10 8 L 20 8 C 20.55228 8 21 8.447715 21 9 L 21 17 Z M 14 14 C 14.55228 14 15 13.55228 15 13 L 15 11 C 15 10.44772 14.55228 10 14 10 L 12 10 C 11.44772 10 11 10.44772 11 11 L 11 13 C 11 13.55228 11.44772 14 12 14 L 14 14 Z M 21 20 L 9 20 L 9 19 L 21 19 L 21 20 Z "
            transform="matrix(1,0,0,-1,0,30)"
          />
        </clipPath>

        <g clipPath="url(#cp2)">
          <clipPath id="cp3">
            <path
              d="M -840 -558 L 600 -558 L 600 342 L -840 342 Z "
              transform="matrix(1,0,0,-1,0,30)"
            />
          </clipPath>

          <g clipPath="url(#cp3)">
            <path
              d="M 6 25 L 24 25 L 24 5 L 6 5 Z "
              fill={color}
              transform="matrix(1,0,0,-1,0,30)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
