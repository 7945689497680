import Box from '../../../../../../Components/Box';
import Button from '../../../../../../Components/Button';
import Popup from 'reactjs-popup';
import Text from '../../../../../../Components/Text';
import { Trans } from '@lingui/macro';
import { useTypedSelector } from 'Store/Redux/store';

interface ResetAllModalProps {
  open: boolean;
  close: any;
  onUpdate: any;
}

export default function ResetAllModal(props: ResetAllModalProps) {
  const {
    open,
    close,
    onUpdate,
  } = props;

  const { config } = useTypedSelector(state => state);

  return (
    <Popup
      className={'modal modal-admin-calendar'}
      closeOnDocumentClick
      modal
      nested
      onClose={close}
      open={open}
    >
      <div className="modal-inner">
        <div className="modal-header">
          <h2>
            <Trans>
              Reset all
            </Trans>
          </h2>
          <svg className="modal-header__close" height="30px" onClick={close} viewBox="0 0 30 30" width="30px">
            <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
              <g id="Booking-savedall" transform="translate(-875.000000, -132.000000)">
                <g id="Group-8" transform="translate(515.000000, 112.000000)">
                  <g id="icons/close" transform="translate(360.000000, 20.000000)">
                    <rect fill={config.theme.primaryLight} height="30" id="Rectangle" rx="8" width="30" x="0" y="0"></rect>
                    <path d="M20.704633,9.29536704 C21.0984557,9.68918977 21.0984557,10.3277026 20.704633,10.7215253 L16.4261582,15 L20.704633,19.2784747 C21.0984557,19.6722974 21.0984557,20.3108102 20.704633,20.704633 C20.3108102,21.0984557 19.6722974,21.0984557 19.2784747,20.704633 L15,16.4261582 L10.7215253,20.704633 C10.3277026,21.0984557 9.68918977,21.0984557 9.29536704,20.704633 C8.90154432,20.3108102 8.90154432,19.6722974 9.29536704,19.2784747 L13.5738418,15 L9.29536704,10.7215253 C8.90154432,10.3277026 8.90154432,9.68918977 9.29536704,9.29536704 C9.68918977,8.90154432 10.3277026,8.90154432 10.7215253,9.29536704 L15,13.5738418 L19.2784747,9.29536704 C19.6722974,8.90154432 20.3108102,8.90154432 20.704633,9.29536704 Z" fill={config.theme.primary}></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className={'modal-inner-content'}>
          <Box marginBottom={15} marginTop={20}>
            <Text color="lightGray" size="md">
              <Trans>Are you sure to reset all dates?</Trans>
            </Text>
          </Box>
          <Box display="flex" justifyContent="end">
            <Button onClick={close} size="sm" type="clear">
              <Trans>Cancel</Trans>
            </Button>
            <Button
              onClick={() => {
                close();
                onUpdate();
              }}
              size="sm"
            >
              <Trans>Reset</Trans>
            </Button>
          </Box>
        </div>
      </div>
    </Popup>
  );
}
