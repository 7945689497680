import React, { useEffect, useState } from "react";
import { GroupsSelectProps } from "./types";
import { Group, useLazyGetGroupsQuery } from "store";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { t } from "@lingui/macro";
import { useDebouncedCallback } from "hooks";

export const GroupsSelect: React.FC<GroupsSelectProps> = (props) => {
  const { value, onChange } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<Group[]>([]);
  const [search, setSearch] = useState("");
  const debouncedSetSearch = useDebouncedCallback(setSearch, [setSearch], 700);
  const [triggerSearchGroupsQuery, searchGroupsQuery] = useLazyGetGroupsQuery();
  const { data: searchGroupsQueryResponse, isLoading: usersAreLoading, isFetching: usersAreFetching } = searchGroupsQuery;
  const { groups = [] } = searchGroupsQueryResponse?.result?.data || {};

  useEffect(() => {
    if (isOpen) {
      triggerSearchGroupsQuery({ search });
    }
  }, [isOpen, search, triggerSearchGroupsQuery]);

  useEffect(() => {
    setOptions(groups);
  }, [JSON.stringify(groups)]);

  return (
    <Autocomplete
      autoComplete
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionLabel={(option) => option.name || option.id}
      loading={usersAreLoading || usersAreFetching}
      multiple
      noOptionsText={t`No users found`}
      onChange={(_, value) => onChange?.(value)}
      onClose={() => setIsOpen(false)}
      onInputChange={(_, value) => debouncedSetSearch(value)}
      onOpen={() => setIsOpen(true)}
      options={options}
      renderInput={(props) => (
        <TextField
          {...props}
          fullWidth
          sx={{
            "& .MuiFilledInput-root.MuiInputBase-sizeSmall": {
              paddingY: 1,
              paddingLeft: 1,
            },
            "& .MuiFilledInput-input": {
              fontSize: 14,
            },
          }}
          variant="filled"
        />
      )}
      renderOption={(props, option) => (
        <Box {...props} component="li">
          <Box>
            <Typography fontSize={14}>{option.name || option.id}</Typography>
          </Box>
        </Box>
      )}
      size="small"
      value={value}
    />
  );
};
