import { Box, BoxProps } from "@mui/material";
import React from "react";

export const BlueChip: React.FC<BoxProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Box alignItems="center" bgcolor="#5FB3DF" borderRadius={2} display="flex" px={1} py={0.5} {...rest}>
      {children}
    </Box>
  );
};
