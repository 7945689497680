import { BookingType } from '../bookingDuck/models';
import { CREATE_BOOKING_SUCCESS, CreateBookingSuccess } from '../bookingDuck';
import {
  CreateNewBookingModel,
  SetCreateNewBookingRequest,
  TimeSlotsObject,
  WeekDaysObject,
  WeeklySlot,
  WeeklySlotDay,
} from './models';

export const CLEAR_CREATE_NEW_BOOKING_DATA = 'CLEAR_CREATE_NEW_BOOKING_DATA';
export const CLEAR_CREATE_NEW_BOOKING_DATA_IF_BOOKING_CREATED = 'CLEAR_CREATE_NEW_BOOKING_DATA_IF_BOOKING_CREATED';
export const SET_CREATE_NEW_BOOKING_DATA = 'SET_CREATE_NEW_BOOKING_DATA';

export interface ClearCreateNewBookingData {
  type: typeof CLEAR_CREATE_NEW_BOOKING_DATA;
}

export interface ClearCreateNewBookingDataIfBookingCreated {
  type: typeof CLEAR_CREATE_NEW_BOOKING_DATA_IF_BOOKING_CREATED;
}

export interface SetCreateNewBookingData {
  type: typeof SET_CREATE_NEW_BOOKING_DATA;
  payload: SetCreateNewBookingRequest;
}

export type Actions =
  | ClearCreateNewBookingData
  | ClearCreateNewBookingDataIfBookingCreated
  | CreateBookingSuccess
  | SetCreateNewBookingData;

export interface State extends CreateNewBookingModel {
  error: string;
  loading: boolean;
  bookingCreated: boolean;
  bookingType: BookingType;
  filters: { [key: string]: boolean };
  timeSlots: TimeSlotsObject;
  weekDays: WeekDaysObject;
  weeklySlots: WeeklySlot[];
}

const tomorrow = new Date(new Date().getTime() + 86400000);
const initialState: State = {
  error: '',
  loading: false,
  bookingCreated: false,
  bookingType: 'custom',
  dateFrom: tomorrow,
  dateTo: tomorrow,
  meetingFrom: undefined,
  meetingTo: undefined,
  deskId: undefined,
  filters: {},
  roomId: '',
  floorId: '',
  locationId: '',
  parkingSpotId: '',
  timeFrom: new Date(1970, 0, 1, 9),
  timeTo: new Date(1970, 0, 1, 18),
  weekDays: {
    0: {
      label: 'Sun',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 0,
    },
    1: {
      label: 'Mon',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 1,
    },
    2: {
      label: 'Tue',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 2,
    },
    3: {
      label: 'Wed',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 3,
    },
    4: {
      label: 'Thu',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 4,
    },
    5: {
      label: 'Fri',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 5,
    },
    6: {
      label: 'Sat',
      selected: false,
      timeFrom: 9,
      timeTo: 18,
      value: 6,
    },
  },
  timeSlots: {
    0: {
      label: '09:00-11:00',
      selected: false,
      timeFrom: 9,
      timeTo: 11,
      value: 0,
    },
    1: {
      label: '11:00-01:00',
      selected: false,
      timeFrom: 11,
      timeTo: 13,
      value: 1,
    },
    2: {
      label: '01:00-03:00',
      selected: false,
      timeFrom: 13,
      timeTo: 15,
      value: 2,
    },
    3: {
      label: '03:00-05:00',
      selected: false,
      timeFrom: 15,
      timeTo: 17,
      value: 3,
    },
    4: {
      label: '05:00-07:00',
      selected: false,
      timeFrom: 17,
      timeTo: 19,
      value: 4,
    },
  },
  weeklySlots: [
    {
      index: 0,
      day: WeeklySlotDay.SUNDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
    {
      index: 1,
      day: WeeklySlotDay.MONDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
    {
      index: 2,
      day: WeeklySlotDay.TUESDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
    {
      index: 3,
      day: WeeklySlotDay.WEDNESDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
    {
      index: 4,
      day: WeeklySlotDay.THURSDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
    {
      index: 5,
      day: WeeklySlotDay.FRIDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
    {
      index: 6,
      day: WeeklySlotDay.SATURDAY,
      isSelected: false,
      timeFrom: new Date(1970, 0, 1, 9),
      timeTo: new Date(1970, 0, 1, 18),
    },
  ],
  meetingTitle: '',
  attendees: [],
  requestedServices: [],
  isTeamsMeeting: false,
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case CLEAR_CREATE_NEW_BOOKING_DATA: {
      return {
        ...initialState,
      };
    }

    case CLEAR_CREATE_NEW_BOOKING_DATA_IF_BOOKING_CREATED: {
      const newState = state.bookingCreated ? { ...initialState } : state;

      return newState;
    }

    case CREATE_BOOKING_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        bookingCreated: true,
      };
    }

    case SET_CREATE_NEW_BOOKING_DATA: {
      return {
        ...state,
        ...action.payload,
        weekDays: action.payload.weekDays ? { ...action.payload.weekDays } : state.weekDays,
      };
    }

    default:
      return state;
  }
}

// Actions
export function clearCreateNewBookingData(): ClearCreateNewBookingData {
  return {
    type: CLEAR_CREATE_NEW_BOOKING_DATA,
  };
}

export function clearCreateNewBookingDataIfBookingCreated(): ClearCreateNewBookingDataIfBookingCreated {
  return {
    type: CLEAR_CREATE_NEW_BOOKING_DATA_IF_BOOKING_CREATED,
  };
}

export function setCreateNewBookingData(data: SetCreateNewBookingRequest): SetCreateNewBookingData {
  return {
    type: SET_CREATE_NEW_BOOKING_DATA,
    payload: data,
  };
}

// Selectors
/**
 * Receives a date and returns it in Zulu time at 00:00:000
 * 
 * Ex: new Date(2021, 5, 30, 20, 36) => '2021-06-30T00:00:00.000Z'
 */
export function getDateInZeroHoursZuluTime(date: Date | string): Date {
  date = new Date(date);
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));

  return date;
}

/**
 * Receives the number of the step and the redux state, and returns if that
 * step is completed.
 */
export function isStepCompleted(number: number, state: State): boolean {
  let isCompleted = false;

  switch (number) {
    case 1: {
      isCompleted = !!state.locationId;
      break;
    }

    case 2: {
      isCompleted = !!state.floorId;
      break;
    }

    case 3: {
      isCompleted = !!state.deskId;
      break;
    }

    default:
      break;
  }

  return isCompleted;
}

/**
 * Receives the number of the step and the redux state, and returns if that
 * step should be disabled. This means, if the step before is completed.
 */
export function isStepDisabled(number: number, state: State, disableFloorPage: boolean): boolean {
  let isDisabled = false;

  switch (number) {
    case 2: {
      isDisabled = !state.locationId || disableFloorPage;
      break;
    }

    case 3: {
      isDisabled = !state.floorId || !state.locationId;
      break;
    }

    default:
      break;
  }

  return isDisabled;
}
