import BookingDetails from '../BookingDetails';
import Box from 'Components/Box';
import Breadcrumbs from 'App/Components/Breadcrumbs';
import LocationItem, { LocationItemLoading } from './LocationItem';
import styles from './styles.module.scss';
import bookingStyles from '../styles.module.scss';
import Text from 'Components/Text';
import { getBookings, selectMoreOftenLocation } from 'App/Store/Bookings/bookingDuck';
import { getLocationDisabledDates } from 'App/Store/Locations/locationsDuck';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { StaggeredItems } from 'Components/Animations/StaggeredItems';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import Divider from "../../../../Components/Divider";
import CovidDocConfirmation from "../CovidDocConfirmation";
import { isLocationRequiredCovidDocument } from "../../../Functions/Helpers";
import { setBookingCovidConfirmationData } from "../../../Store/Bookings/covidConfirmationDuck";
import { getUserCovidDocRestrictions, getUserIdForBooking } from "../../../Store/Users/executiveAssistant/helpers";
import ReservationRegionSearch from "../../../Components/ReservationRegionSearch";

interface LocationProps {
  type?: 'support' | 'desk';
}

export default function Location({ type = 'desk' }: LocationProps) {
  const dispatch = useDispatch();
  const { booking: bookingDuck, createNewBooking, locations: locationsDuck, profile, executiveAssistant } = useTypedSelector(state => state);
  const { locationId } = createNewBooking;
  const { loading } = locationsDuck;
  const { actualCovidDocument, approvedAccommodation } = getUserCovidDocRestrictions(profile, executiveAssistant);
  const selectedUserId = getUserIdForBooking(profile, executiveAssistant);
  const defaultLocation = selectMoreOftenLocation(bookingDuck);

  // Sort locations so if there's a default, it's always the first one
  const locations = locationsDuck.locations.sort(a => {
    if (a.id === defaultLocation?.id) {
      return -1;
    }

    return 0;
  });

  // If there're no bookings loaded, tries to load them
  // This is necessary if the user enter this route without
  // navigating on the first page, so we can know what is the default location
  useEffect(() => {
    const hasBookings = Object.keys(bookingDuck.bookings).length > 0;

    if (!hasBookings) {
      dispatch(getBookings({
        data: {
          limit: 20,
          page: 1,
          dateFrom: bookingDuck.selectedDate,
          order: 'ASC',
          selectedUserId,
        },
        clearData: true,
      }));
    }
  }, [selectedUserId]);

  useEffect(() => {
    // load disabled days only if location changed
    if (locationId) {
      dispatch(getLocationDisabledDates({locationId}));
    }
  }, [locationId]);

  // If there's a defaultLocation, automatically assign it to selected
  useEffect(() => {
    if (defaultLocation) {
      dispatch(setCreateNewBookingData({ locationId: defaultLocation.id }));
    }
  }, [defaultLocation]);

  /**
   * Set locationId on redux
   */
  const onChangeLocation = (newLocationId: string) => {
    const isRequired = isLocationRequiredCovidDocument(locations, locationId, actualCovidDocument, approvedAccommodation);
    
    dispatch(setCreateNewBookingData({ locationId: newLocationId }));

    if (isRequired) {
      dispatch(setBookingCovidConfirmationData({ openCovidModal: true }));
    }
  };

  return (
    <Box display="grid" gap={30} gridTemplateColumns="8fr 4fr" paddingBottom={50}>
      <Box>
        <Box marginBottom={15}>
          <Breadcrumbs type={type} />
        </Box>

        <Box marginBottom={15}>
          <ReservationRegionSearch />
        </Box>

        {loading ? (
          <LocationItemLoading />
        ) : (
          <Box className={styles.locationList} dataTestId="location-list" display="grid" gap={15}>
            <StaggeredItems className={styles.items}>
              {locations.map((location, index) => (
                <LocationItem
                  isDefault={defaultLocation?.id === location.id}
                  key={location.id + index}
                  location={location}
                  onClick={onChangeLocation}
                  selected={locationId === location.id}
                  type={type}
                />
              ))}
            </StaggeredItems>
          </Box>
        )}
      </Box>

      <Box className={bookingStyles.stickyBookingWrapper}>
        <Box>
          <Text size="xlg" weight="semi-bold">
            <Trans>Booking details</Trans>
          </Text>
        </Box>

        <Box className={bookingStyles.stickyBookingLocationRow}>
          <Box marginBottom={10}>
            <Divider />
          </Box>
          <BookingDetails type={type} />
        </Box>
      </Box>
      <CovidDocConfirmation locationId={locationId} />
    </Box>
  );
}
