import { RefObject, useEffect, useRef } from "react";

// hook is used to getting previous state of data
// example: prevState !== state comparison, where prevState is returned by usePrevious
export function usePrevious(data: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = data;
  });
  return ref.current;
}

// hook is used to detect click outside ref element by callback function
// example useClickOutside(ref, () => {do something});
export const useClickOutside = (ref: RefObject<HTMLInputElement>, callback: Function) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      callback(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, callback]);
};
