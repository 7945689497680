export const parseArrayQueryParam = <T>(name: string, values?: T[]): Record<string, T> => {
  const carry: Record<string, T> = {};

  if (!values) {
    return carry;
  }

  for (const [index, value] of values.entries()) {
    carry[`${name}[${index}]`] = value;
  }

  return carry;
};
