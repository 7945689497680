import { Trans, t } from "@lingui/macro";

import { RoomServiceType } from "App/Store/Meetings/meetingRooms/models";
import { IAdminRoomAvailableService } from "Admin/Store/roomsManagement/models";
import Box from "Components/Box";
import Checkbox from "Components/Checkbox";
import Text from "Components/Text";
import TextField from "Components/TextField";

interface Props {
  type: RoomServiceType;
  value?: IAdminRoomAvailableService;
  error?: string;
  label?: string;
  onChange: (value?: IAdminRoomAvailableService) => void, 
}

export default function RoomServiceInput(props: Props) {
  const { type, value, label, error, onChange } = props;
  const isEnabled = !!value;
  const email = value?.emails?.[0] || "";

  const onToggleCatering = (enabled: boolean) => {
    if (enabled) {
      onChange({ type, emails: [email] });
    } else {
      onChange();
    }
  };

  const onChangeCateringData = (email: string) => {
    if (isEnabled) {
      onChange({ type, emails: [email] });
    }
  };

  return (
    <Box direction="column" display="flex" margin="3px 0 0 2px" marginBottom={isEnabled ? 12 : 0}>
      <Box paddingLeft={8}>
        <Checkbox
          checked={isEnabled}
          key={type}
          label={<Box><Text size="md">{label}</Text></Box>}
          name={type}
          onChange={(value) => onToggleCatering(value)}
        />
      </Box>
      {isEnabled &&
        <Box direction="row" display="flex">
          <Box style={{ flex: "0 0 75px", width: "75px" }}>
            <Text color="gray" size="md">
              <Trans>Email:</Trans>
            </Text>
          </Box>
          <TextField
            error={!!error}
            helperText={error}
            id={`${type}-emails`}
            onChange={(event) => onChangeCateringData(event.target.value)}
            placeholder={t`Type here`}
            style={{ height: 32, padding: "0 6px" }}
            type="email"
            value={email}
          />
        </Box>
      }
    </Box>
  );
}
