import { DeskStatus, DeskStatusEnum } from "./models";
import { t } from "@lingui/macro";

export const DESK_STATUS_TO_EDIT: Array<{ name: string; value: DeskStatus }> = [
  {
    name: t`Available`,
    value: DeskStatusEnum.Available,
  },
  {
    name: t`Unavailable`,
    value: DeskStatusEnum.Unavailable,
  },
  {
    name: t`Approval required`,
    value: DeskStatusEnum.ApprovalRequired,
  },
];
