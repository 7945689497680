import Box from '../../../Components/Box';
import CustomPagination from '../../../Components/CustomPagination';
import Notification from './Notification/Notification';
import NotificationForm from './NotificationForm';
import styles from './styles.module.scss';
import Text from '../../../Components/Text';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'Store/Redux/store';
import {
  ErrorAdminNotificationSnackbar,
  SuccessAdminNotificationSnackbar,
} from './SnackBars';
import {
  getNotifications,
  resetDateRangeDate,
  resetWhenToSendDate,
} from '../../Store/notifications';

export default function Notifications() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const { adminNotifications } = useTypedSelector(state => state);

  const [firstLoad, setFirstLoad] = useState(true);
  const [openFormState, setOpenFormState] = useState(false);
  const openFormClass = '';

  useEffect(() => {
    // load first page on open notifications tab
    dispatch(getNotifications({ locationId, page: 1 }));
    setFirstLoad(false);
  }, [locationId]);

  const onChangePage = (page: number) => {
    dispatch(getNotifications({ locationId, page }));
  };

  function onOpenNotificationForm() {
    setOpenFormState(true);
    dispatch(resetWhenToSendDate());
    dispatch(resetDateRangeDate());
  }

  function onCancelNotificationForm() {
    setOpenFormState(false);
    dispatch(resetWhenToSendDate());
    dispatch(resetDateRangeDate());
  }

  return (
    <>
      <Box>
        <Box display="flex" justifyContent="between">
          <div className={styles.notificationsList}>
            <div className={styles.notificationsAction}>
              <div
                className={`${styles.notificationsActionButton} ${openFormClass}`}
                onClick={onOpenNotificationForm}
              >
                <Trans>Create Notification</Trans>
              </div>
            </div>

            {openFormState
              ? (
                <NotificationForm
                  actionState={'add'}
                  onCancelAction={onCancelNotificationForm}
                />
              ) : null
            }

            {(!adminNotifications.loading && !firstLoad) && (
              <>
                {
                  adminNotifications.notifications.length ? (
                    adminNotifications.notifications.map((notification, index) => (
                      <Notification index={index} key={index} notification={notification} />
                    ))
                  ) : (
                    <Box>
                      <Text align="center" color="lightGray" size="md">
                        <Trans>No notifications</Trans>
                      </Text>
                    </Box>
                  )
                }
              </>
            )}
          </div>
        </Box>

        <Box padding="100px 0 50px">
          {
            adminNotifications.totalPages ?
              <CustomPagination count={adminNotifications.totalPages} onChange={onChangePage} /> : null
          }
        </Box>
      </Box>

      <SuccessAdminNotificationSnackbar />
      <ErrorAdminNotificationSnackbar />
    </>
  );
}
