import { useDispatch } from 'react-redux';
import { UserDocument } from '../../../../Store/userManagement/models';
import { useTypedSelector } from 'Store/Redux/store';
import moment from 'moment';
import { Trans, t } from '@lingui/macro';
import styles from '../../styles.module.scss';

import Box from 'Components/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import TableRow from '@material-ui/core/TableRow';
import CheckboxCustom from 'Components/CheckBoxCustom';
import OverflowToolTip from 'Components/OverflowTooltip';
import TableCell from '@material-ui/core/TableCell';
import Text from 'Components/Text';
import UserAccommodation from "../../../UserManagement/AllUsersTable/AllUsersTableBody/UserAccommodation";
import { BookingModel, BookingModelStatus } from 'App/Store/Bookings/bookingDuck/models';
import { cancelReservationsBooking, updateReservationMassActionsIds } from 'Admin/Store/reservations';
import TableSubmenuButton from "../../../../Components/Buttons/TableSubmenuButton";

interface ReservationBodyItemProps {
  reservation: BookingModel;
  index: number;
}

function getReservationDate(reservation: BookingModel) {
  let fullDate = '';
  const startDate = moment(reservation.dateFrom).utc().format('MMM DD');
  const startHour = `${reservation.timeFrom}`;
  const endHour = `${reservation.timeTo}`;

  fullDate = `${startDate}, ${startHour}-${endHour}`;

  return fullDate;
}

function getStatusClass(status: BookingModelStatus) {
  switch (status) {
    case 'BOOKED':
      return styles.statusBooked;
    case 'CANCELED':
      return styles.statusCancelled;
    case 'PENDING':
      return styles.statusPending;
    case 'PAST':
      return styles.statusPassed;
    default:
      return styles.statusPending;
  }
}

export function getStatusText(status: BookingModelStatus) {
  switch (status) {
    case 'BOOKED':
      return t`Booked`;
    case 'CANCELED':
      return t`Cancelled`; // fix spelling error from backend
    case 'PENDING':
      return t`Pending`;
    case 'PAST':
      return t`Past`;
    default:
      return t`Past`;
  }
}

export function getDocStatusText(userDocument: UserDocument) {
  if (userDocument) {
    switch (userDocument.status) {
      case 'needsApproval':
        return t`Needs Approval`;
      case 'approved':
        return t`Approved`;
      case 'denied':
        return t`Denied`;
      case 'expired':
        return t`Expired`;
      default:
        return '-';
    }
  }

  return '-';
}

export default function ListViewBodyItem(props: ReservationBodyItemProps) {
  const { reservation, index } = props;
  const dispatch = useDispatch();
  const { adminReservations, config } = useTypedSelector(state => state);
  const { reservationPageType, reservationMassActionsIds } = adminReservations;

  const isMassActiveActive = adminReservations.reservationMassActions[reservationPageType];
  const isReservationChecked = reservationMassActionsIds.includes(reservation.id);
  const checkedRowClass = isReservationChecked && isMassActiveActive ? styles.checkedRow : '';

  const reservationDate = getReservationDate(reservation);
  const reservationStatus = getStatusText(reservation.status);
  const reservationStatusClass = getStatusClass(reservation.status);
  const reservationDocStatus = getDocStatusText(reservation.user.userDocument);
  const reservationCheckin = reservation.checking ? "Yes" : "No";

  const onCancelReservation = () => {
    dispatch(cancelReservationsBooking({ bookingId: reservation.id }));
  };

  const onCheckedChange = () => {
    dispatch(updateReservationMassActionsIds(reservation.id));
  };

  return (
    <TableRow
      className={checkedRowClass}
      classes={{ root: styles.tableRow }}
      hover
      key={index}
      tabIndex={-1}
    >
      <TableCell classes={{ root: styles.tableCell }}>
        <Box alignItems="center" display="flex">
          {
            isMassActiveActive ?
              <CheckboxCustom
                checked={isReservationChecked}
                name={reservation.id}
                onChange={onCheckedChange}
              /> : null
          }
          <OverflowToolTip isLocation text={reservation.location?.locationName} />
        </Box>
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={reservation.floor?.floorName} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={reservation.user.name} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={reservation.desk?.name} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={reservationDate} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={reservation.parkingSpot ? t`${reservation.parkingSpot.floor ? `Floor ${reservation.parkingSpot.floor}, ` : ''}${reservation.parkingSpot.name}` : '-'} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <span className={reservationStatusClass}>
          <OverflowToolTip text={reservationStatus} />
        </span>
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <UserAccommodation
          approvedAccommodation={reservation.user?.approvedAccommodation}
          disabled
          userId={reservation.user?.id}
        />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={reservationCheckin} />
      </TableCell>
      <TableCell classes={{ root: styles.tableCell }}>
        <OverflowToolTip text={reservationDocStatus} />
      </TableCell>
      <TableCell align="right" classes={{ root: styles.tableCell }}>
        <TableSubmenuButton>
          {(close: any) => (
            <Box borderStyle="shadow" className="popover-content">
              <ButtonBase
                className="popover-item-button"
                onClick={() => {
                  onCancelReservation();
                  close();
                }}
              >
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px">
                  <g fill="none" fillRule="evenodd" id="A-reservations" stroke="none" strokeWidth="1">
                    <g id="A-Reservations" transform="translate(-785.000000, -208.000000)">
                      <g id="dd-actions" transform="translate(770.000000, 193.000000)">
                        <g id="delete-white-copy-5" transform="translate(15.000000, 15.000000)">
                          <circle
                            cx="12"
                            cy="12"
                            id="Oval"
                            r="8"
                            stroke={config.theme.primary}
                            strokeWidth="2"
                          ></circle>
                          <path
                            d="M12,8 C12.5522847,8 13,8.44771525 13,9 L13,11 L15,11 C15.5522847,11 16,11.4477153 16,12 C16,12.5522847 15.5522847,13 15,13 L13,13 L13,15 C13,15.5522847 12.5522847,16 12,16 C11.4477153,16 11,15.5522847 11,15 L11,13 L9,13 C8.44771525,13 8,12.5522847 8,12 C8,11.4477153 8.44771525,11 9,11 L11,11 L11,9 C11,8.44771525 11.4477153,8 12,8 Z"
                            fill={config.theme.primary}
                            id="Combined-Shape"
                            transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) "
                          >
                          </path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>

                <Box marginStart={5}>
                  <div>
                    <Text color="blue" size="md">
                      <Trans>Cancel Reservation</Trans>
                    </Text>
                  </div>
                </Box>
              </ButtonBase>
            </Box>
          )}
        </TableSubmenuButton>
      </TableCell>
    </TableRow>
  );
}
