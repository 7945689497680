import React from "react";
import { UploadBoxProps } from "./types";
import { Box } from "@mui/material";

export const UploadBox: React.FC<UploadBoxProps> = (props) => {
  const { uploadOptions, ...rest } = props;
  const { accept, onChange } = uploadOptions || {};

  const handleDragOver: React.DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop: React.DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const fileList = event?.dataTransfer?.files;
    const files: File[] = [];

    if (fileList?.length) {
      for (const file of fileList) {
        if (!accept || accept.includes(file.type)) {
          files.push(file);
        }
      }
    }
    
    onChange?.(files);
  };  

  return (
    <Box {...rest} onDragOver={handleDragOver} onDrop={handleDrop} />
  );
};
