import { t } from "@lingui/macro";
import { Search } from "@mui/icons-material";
import { Box, InputAdornment, Skeleton, TextField, Typography } from "@mui/material";
import { DefaultChip, TogetherLocation, useToast } from "components";
import { useDebouncedCallback, useExceptionTracker, useLazyLoadScrollHandler } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import { GetLocationsInput, Location, floorsSlice, reservationsSlice, roomsSlice, useLazyGetLocationsQuery } from "store";
import { theme } from "theme";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resolveGMTOffsetLabel } from "utils";

export const ReservationLocationsRoute: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const trackException = useExceptionTracker();
  const viewRef = useRef<HTMLDivElement>(null);
  const [locationsToList, setLocationsToList] = useState<Location[]>([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>();
  const debouncedSetSearch = useDebouncedCallback(setSearch, [setSearch], 700);
  const [triggerGetLocationsQuery, getLocationsQuery] = useLazyGetLocationsQuery();
  const {
    data,
    isLoading: locationsAreLoading,
    isFetching: locationsAreFetching,
    isError: failedToGetLocations,
    error: getLocationsError,
    endpointName,
  } = getLocationsQuery;
  const locations = data?.result?.data?.items;
  const hasMore = data?.result?.data?.links?.next;

  useEffect(() => {
    if (failedToGetLocations) {
      toast.showToast({ severity: "error", message: t`Sorry, we couldn't load the locations list. Try again later.` });
      trackException(getLocationsError, { endpointName });
    }
  }, [failedToGetLocations]);

  useEffect(() => {
    if (location.search.includes("reset=true")) {
      dispatch(reservationsSlice.actions.resetCurrent({ isTeamsMeeting: true }));
      dispatch(reservationsSlice.actions.resetAvailabilityQuery());
      dispatch(roomsSlice.actions.resetCurrent());
      dispatch(floorsSlice.actions.resetCurrent());
      history.replace(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    setLocationsToList([]);
    setPage(1);
  }, [search]);

  useEffect(() => {
    if (locations) {
      const newLocations = locations.filter(({ id }) => !locationsToList.some((location) => location.id === id));

      setLocationsToList([...locationsToList, ...newLocations]);
    }
  }, [locations]);

  const getLocationsInput: GetLocationsInput = {
    search,
    page,
    orderBy: "asc:locationName",
    custom: { withRoomsOnly: true, defaultLocationReservationType: "room" },
  };

  useEffect(() => {
    triggerGetLocationsQuery(getLocationsInput, true);
  }, [search]);

  useEffect(() => {
    triggerGetLocationsQuery(getLocationsInput, true);
  }, [page]);

  const handleScroll = useLazyLoadScrollHandler(viewRef, () => {
    if (hasMore && !locationsAreFetching && !locationsAreLoading) {
      setPage(page + 1);
    }
  }, 300);

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={2}>
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
          }}
          onChange={(event) => debouncedSetSearch(event.currentTarget.value)}
          placeholder={t`Search by location, address`}
          size="small"
          sx={{ marginBottom: 1 }}
          variant="filled"
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        maxHeight={710}
        mb={2}
        onScroll={handleScroll}
        paddingRight={2}
        ref={viewRef}
        sx={{ overflowX: "hidden", overflowY: "auto" }}
      >
        {locationsAreLoading || locationsAreFetching ? <Box mb={2}><Skeleton height={85} variant="rectangular" /></Box> : undefined}
        {locationsToList.map(({ id, name, region, timeZone, address, isDefault }) => (
            <Box
              bgcolor={theme.palette.grey[100]}
              borderRadius={2}
              component={Link}
              display="flex"
              flexDirection="column"
              key={id}
              marginBottom={2}
              padding={2}
              sx={{
                cursor: "pointer",
                transition: "background-color 0.15s",
                ":hover": { bgcolor: theme.palette.grey[200] },
              }}
              to={`/reservations/locations/${id}/floors`}
            >
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography fontSize={14} fontWeight="600">{name}</Typography>
                <Box alignItems="center" display="flex">
                  <Typography color={theme.palette.grey[600]} fontSize={14}>
                    {`${region}`}{timeZone ? ` (${resolveGMTOffsetLabel(timeZone)})` : undefined}
                  </Typography>
                  {isDefault ? <DefaultChip ml={1.5} /> : undefined}
                </Box>
              </Box>
              <Box alignItems="center" display="flex">
                <TogetherLocation fill={theme.palette.grey[600]} sx={{ width: 16, marginRight: 1 }} />
                <Typography color={theme.palette.grey[600]} fontSize={14}>{address}</Typography>
              </Box>
            </Box>
          ))}
        {hasMore ? <Box><Skeleton height={85} variant="rectangular" /></Box> : undefined}
      </Box>
    </Box>
  );
};
