interface Props {
  color?: string;
  size?: number;
}

export default function TurnOn({ color = '#4A4A4A', size = 25 }: React.PropsWithChildren<Props>) {
  return (
    <svg
      height={size}
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g fill="none" fillRule="evenodd" id="Symbols" stroke="none" strokeWidth="1">
        <g id="delete-white-copy-5">
          <rect height="10" id="Rectangle" rx="2" stroke={color} strokeWidth="2" width="16" x="4" y="7"></rect>
          <rect fill={color} height="6" id="Rectangle" rx="1" width="6" x="12" y="9"></rect>
        </g>
      </g>
    </svg>
  );
}
