import Alert from '@material-ui/lab/Alert';
import Api from 'Store/Services/Api';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Box from 'Components/Box';
import Button from 'Components/Button';
import CloseIcon from '@material-ui/icons/Close';
import Container from 'Components/Container';
import Divider from 'Components/Divider';
import getDisplayedResourceName from 'Functions/resourseNameParsing';
import Heading from 'Components/Heading';
import IconButton from '@material-ui/core/IconButton';
import isBefore from 'date-fns/isBefore';
import Map from 'App/Pages/CreateNewBooking/Desk/Map';
import ParkingIcon from 'Components/Icons/Parking';
import Popup from 'reactjs-popup';
import ProfileLink from 'Components/Profile';
import Snackbar from '@material-ui/core/Snackbar';
import styles from './styles.module.scss';
import Text from 'Components/Text';
import VisitDetails from 'App/Pages/CreateNewVisit/VisitDetails';
import { addDeskToSavedList, isDeskSaved, removeDeskFromSavedList } from 'App/Store/Desk/deskDuck';
import { BookingType } from 'App/Store/Bookings/bookingDuck/models';
import { formatBookingDateInLocalTime, getVisits, setBookingsData } from 'App/Store/Bookings/bookingDuck';
import { getFloorSchema } from 'Admin/Store/floorMapApiRequestsDuck';
import { Link, useHistory } from 'react-router-dom';
import { LocationOutlinedIcon } from '../../../Components/Icons';
import { setCreateNewBookingData } from 'App/Store/Bookings/createNewBookingDuck';
import { setCreateNewVisitData } from 'App/Store/Bookings/createNewVisitDuck';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'Store/Redux/store';
import {
  cancelBooking,
  getBookingById,
  getBookingDate,
} from 'App/Store/Bookings/bookingDuck';
import {
  getUserIdForBooking,
  isAnotherUserSelectedByExecutiveAssistant,
} from "../../../Store/Users/executiveAssistant/helpers";
import { DeskStatusEnum } from "../../../../Admin/Store/floorMapDuck/desk/models";
import { ApiTag, togetherApi } from 'store';

interface RouteParams {
  bookingId: string;
  bookingType: BookingType;
}

function ErrorSnackbar() {
  const dispatch = useDispatch();
  const { error } = useTypedSelector(state => state.booking);

  const handleClose = () => {
    dispatch(setBookingsData({ error: '' }));
  };

  return error ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="error">
        {error}
      </Alert>
    </Snackbar>
  ) : null;
}

function SuccessSnackbar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { bookingCanceled } = useTypedSelector(state => state.booking);

  const handleClose = () => {
    dispatch(setBookingsData({ bookingCanceled: false }));
    history.push('/');
  };

  useEffect(() => {
    return () => {
      dispatch(setBookingsData({ bookingCanceled: false }));
    };
  }, []);

  return bookingCanceled ? (
    <Snackbar autoHideDuration={6000} onClose={handleClose} open>
      <Alert onClose={handleClose} severity="success">
        <Trans>Booking canceled</Trans>
      </Alert>
    </Snackbar>
  ) : null;
}

interface Props {
  isVisit?: boolean;
}

/**
 * Booking Details, when navigating to an existing booking.
 * Here the user will see all the details and will be able to cancel the booking.
 * 
 * route: /booking/:bookingId/:bookingType/details
 */
export default function BookingDetails({ isVisit }: Props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { bookingId, bookingType } = useParams<RouteParams>();

  const [popupOpened, setPopupOpened] = useState(false);
  const [bookingCancelling, setBookingCancelling] = useState(false);

  const { adminFloorMap, booking: bookingDuck, config, desk: deskDuck, profile, executiveAssistant } = useTypedSelector(state => state);
  const { mapSize, name, previewUrl, mapImageBounds } = adminFloorMap;
  const { activeBooking: booking, bookingCanceled, limit } = bookingDuck;

  const anotherUserSelectedByExecutiveAssistant = isAnotherUserSelectedByExecutiveAssistant(profile, executiveAssistant);
  const selectedUserId = getUserIdForBooking(profile, executiveAssistant);

  const showSaveDesk = booking?.desk?.status !== DeskStatusEnum.DeleteInProgress && !anotherUserSelectedByExecutiveAssistant;
  const isFavorite = booking ? isDeskSaved(deskDuck, booking.desk.id) : false;
  const visit = bookingDuck.visits[bookingId];

  useEffect(() => {
    if (isVisit) {
      // ----- Get all visits -----
      const date = new Date();

      dispatch(getVisits({
        data: {
          limit,
          page: 1,
          dateFrom: date,
          order: 'ASC',
          selectedUserId,
        },
      }));
      dispatch(getVisits({
        data: {
          limit,
          page: 1,
          dateFrom: date,
          order: 'DESC',
          selectedUserId,
        },
      }));
    } else {
      dispatch(setBookingsData({ activeBooking: undefined }));
      dispatch(getBookingById({ bookingId, bookingType }));
    }
  }, [selectedUserId]);

  useEffect(() => {
    if (visit) {
      dispatch(setCreateNewVisitData({
        dateFrom: visit.dateFrom ? new Date(visit.dateFrom) : undefined,
        dateTo: visit.dateTo ? new Date(visit.dateTo) : undefined,
        floorId: visit.floor?.id,
        locationId: visit.location?.id,
        parkingSpotId: visit.parkingSpot?.id,
        timeFrom: visit.timeFrom ?? undefined,
        timeTo: visit.timeTo ?? undefined,
      }));
    }
  }, [visit]);

  useEffect(() => {
    if (booking) {
      const floorId = booking.floor.id;

      if (floorId) {
        dispatch(getFloorSchema({ floorId }));
      }
    }
  }, [booking, bookingId]);

  useEffect(() => {
    if (booking) {
      dispatch(setCreateNewBookingData({
        deskId: typeof booking.desk.id === 'string' ? booking.desk.id : '',
        floorId: booking.floor.id,
        locationId: booking.location.id,
      }));
    }
  }, [booking]);

  const handleBookmarkClick = () => {
    if (booking) {
      if (isFavorite) {
        dispatch(removeDeskFromSavedList(booking.desk.id, profile.id));
      } else {
        dispatch(addDeskToSavedList({
          deskId: booking.desk.id,
          floorId: booking.floor.id,
          locationId: booking.location.id,
        }));
      }
    }
  };

  const handleCancelBooking = async () => {
    if (booking) {
      try {
        setBookingCancelling(true);
        const action = dispatch(cancelBooking({
          bookingId: booking.id,
          bookingType: booking.type,
        }));
        const payload = await Api(action);

        dispatch(togetherApi.util.invalidateTags([{ type: ApiTag.RESERVATION }, { type: ApiTag.USER_HOME_COUNTS }]));
        setBookingCancelling(false);

        if (payload.status === 200 || payload.status === 201) {
          setPopupOpened(false);
          history.push('/');
        }
      } catch (e) {
        console.log('error with Cancel Booking');
      }
    }
  };

  const handleClosePopup = () => {
    setPopupOpened(false);
  };

  let today = new Date();
  today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);

  const isPreviousBooking = booking ? isBefore(getBookingDate(booking), today) : false;

  return (
    <>
      <ErrorSnackbar />
      <SuccessSnackbar />

      <Popup
        className="modal"
        contentStyle={{
          maxWidth: 570,
          width: '100%',
        }}
        modal
        onClose={handleClosePopup}
        open={popupOpened}
      >
        <Box padding="2px 1px">
          <Box alignItems="center" display="flex" justifyContent="between">
            <Text size="xlg" weight="semi-bold">
              <Trans>Do you want to cancel booking?</Trans>
            </Text>

            <IconButton
              onClick={handleClosePopup}
              size="small"
              style={{
                backgroundColor: config.theme.primaryLight,
                borderRadius: 8,
                height: 30,
                width: 30,
              }}
            >
              <CloseIcon style={{ color: config.theme.primary, fontSize: 21 }} />
            </IconButton>
          </Box>

          <Box margin="9px 0 18px">
            <Text color="gray" size="md">
              <Trans>Please make sure that you cancel the correct reservation. When you cancel it the reservation can&apos;t be restored.</Trans>
            </Text>
          </Box>

          <Box alignItems="center" display="flex" justifyContent="end">
            <Button
              onClick={handleClosePopup}
              size="sm"
              type="clear"
              withShadow={false}
            >
              <Trans>Cancel</Trans>
            </Button>

            <Button
              disabled={bookingCancelling}
              onClick={() => handleCancelBooking()}
              size="sm"
            >
              <Trans>Confirm</Trans>
            </Button>
          </Box>
        </Box>
      </Popup>

      <Container>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="between"
          marginBottom={27}
          marginTop={30}
        >
          <Box position="relative">
            {!isVisit &&
              <Heading size="sm">
                {booking?.desk.name}
              </Heading>
            }

            <Box className={styles.backButtonContainer}>
              <Link to="/">
                <IconButton classes={{ root: styles.root }}>
                  <svg className={`MuiSvgIcon-root ${styles.arrowBackIcon}`} height="30px" viewBox="0 0 30 30" width="30px">
                    <g fill="none" fillRule="evenodd" id="Booking" stroke="none" strokeWidth="1">
                      <g id="Createbooking" transform="translate(-60.000000, -31.000000)">
                        <g id="icons/arrow-left" transform="translate(60.000000, 31.000000)">
                          <path className={styles.arrowBackIconFill} d="M13.7071068,10.0502525 C14.0976311,10.4407768 14.0976311,11.0739418 13.7071068,11.4644661 L11.1713593,13.9993593 L21,14 C21.5522847,14 22,14.4477153 22,15 C22,15.5522847 21.5522847,16 21,16 L11.1713593,15.9993593 L13.7071068,18.5355339 C14.0976311,18.9260582 14.0976311,19.5592232 13.7071068,19.9497475 C13.3165825,20.3402718 12.6834175,20.3402718 12.2928932,19.9497475 L8.05025253,15.7071068 C7.65972824,15.3165825 7.65972824,14.6834175 8.05025253,14.2928932 L12.2928932,10.0502525 C12.6834175,9.65972824 13.3165825,9.65972824 13.7071068,10.0502525 Z" id="Combined-Shape"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </IconButton>
              </Link>
            </Box>
          </Box>

          <Box>
            <ProfileLink greeting="home" />
          </Box>
        </Box>

        <Box display="grid" gap={30} gridTemplateColumns="8fr 4fr" paddingBottom={50}>
          {isVisit ? (
            <>
              <div />

              <Box>
                <Box marginBottom={15}>
                  <Text size="xlg" weight="semi-bold">
                    <Trans>Visit details</Trans>
                  </Text>
                </Box>

                <Box>
                  <Text>
                    Location: {visit?.location.locationName}
                  </Text>
                  <Text>
                    Floor: {visit?.floor?.floorName}
                  </Text>

                  {visit?.parkingSpot &&
                    <Text>
                      Parking: {visit?.parkingSpot?.floor ? `Floor ${visit?.parkingSpot?.floor}, ` : ''}{visit?.parkingSpot?.name}
                    </Text>
                  }

                  <Box marginTop={20}>
                    <VisitDetails isBooking={false} visit={visit} />
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Box marginBottom={24}>
                  <Text weight="semi-bold">
                    <Trans>Floor map</Trans>
                  </Text>
                </Box>

            <Box display="grid" gap={15}>
              <Text weight="semi-bold">
                {(previewUrl) && (
                  <Map
                    mapImageBounds={mapImageBounds}
                    mapSize={mapSize}
                    name={name}
                    previewUrl={previewUrl}
                    showOnlyBookedDesk
                  />
                )}
              </Text>
            </Box>
          </Box>

              <Box>
                <Box marginBottom={10}>
                  <Box alignItems="center" display="flex" justifyContent="between" marginTop={7}>
                    <Text weight="semi-bold">
                      <Trans>Details</Trans>
                    </Text>

                    {/* hide for delete in progress desks */}
                    {showSaveDesk &&
                      <Box alignItems="center" display="flex" marginTop={8}>
                        <Text color="blue" size="md" weight="semi-bold">
                          <Trans>Save</Trans>
                        </Text>

                        <IconButton onClick={handleBookmarkClick} size="small">
                          {isFavorite ? (
                            <BookmarkIcon style={{ fontSize: 20, color: config.theme.primary }} />
                          ) : (
                            <BookmarkBorderOutlinedIcon style={{ fontSize: 20, color: config.theme.primary }} />
                          )}
                        </IconButton>
                      </Box>
                    }
                  </Box>

                  <Box className={styles.items}>
                    {booking?.desk &&
                      <Box className={styles.item}>
                        <Text color="gray" size="md">
                          <Trans>Section {booking.desk.section}</Trans>
                        </Text>
                      </Box>
                    }

                    {booking?.floor &&
                      <Box className={styles.item}>
                        <Text color="gray" size="md">
                          {booking.floor.floorName}
                        </Text>
                      </Box>
                    }
                  </Box>

                  <Box
                    display="flex"
                    left={-1}
                    marginTop={11}
                    position="relative"
                    style={{ whiteSpace: 'initial' }}
                  >
                    <LocationOutlinedIcon />

                    <Text color='gray' size="md">
                      {booking?.location.locationAddress} | {booking?.location.region}
                    </Text>
                  </Box>

                  <Box marginTop={27}>
                    <Text weight="semi-bold">
                      <Trans>Amenities</Trans>
                    </Text>

                    <Box className={styles.items}>
                      {booking?.desk.resources.length ? (
                        booking.desk.resources.map((resource, index) => {
                          return (
                            <Box className={styles.item} key={resource.name + index}>
                              <Text color="gray" size="md">
                                {getDisplayedResourceName(resource.name)}
                              </Text>
                            </Box>
                          );
                        })
                      ) : (
                        <Box margin={5}>
                          <Text>
                            -
                          </Text>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  {booking?.parkingSpot &&
                    <Box marginTop={27}>
                      <Text weight="semi-bold">
                        <Trans>
                          Parking spot
                        </Trans>
                      </Text>

                      <Box className={styles.items}>
                        <Box className={styles.item}>
                          <ParkingIcon />

                          <Text color="gray" size="md">
                            <Trans>
                              {booking.parkingSpot.floor ? `Floor ${booking.parkingSpot.floor}, ` : ''}{booking.parkingSpot.name}
                            </Trans>
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  }
                </Box>

                <Divider />

                <Box marginTop={20}>
                  <Box
                    alignItems="center"
                    backgroundColor={config.theme.primary}
                    borderRadius={20}
                    display="flex"
                    justifyContent="between"
                    padding={22}
                  >
                    <Box>
                      {booking &&
                        <Box marginEnd={20}>
                          <Text color="white" size="md" weight="semi-bold">
                            <Trans>
                              Date and time
                            </Trans>

                            <br />

                            {formatBookingDateInLocalTime(booking)}
                          </Text>
                        </Box>
                      }
                    </Box>

                    <Button
                      disabled={!booking || isPreviousBooking || booking.isCancelled || bookingCanceled}
                      onClick={() => setPopupOpened(true)}
                      size="sm"
                      type="white"
                    >
                      {booking?.isCancelled ? t`Booking Canceled` : t`Cancel Booking`}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </>
  );
}
